<ng-template #cancelDeleteModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click'); notifyCloseModal()"
    >
      ×
    </button>
  </div>

  <div class="modal-body confirmation-modal-body">
    <div class="modal-text" [ngSwitch]="type">
      <h3 class="confirm-text" *ngSwitchCase="'delete-animal'">Sunteți sigur că doriți să ștergeți acest animal?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-birth'">Sunteți sigur că doriți să ștergeți această fătare?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-repr'">Sunteți sigur că doriți să ștergeți această reproducție?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-move'">Sunteți sigur că doriți să ștergeți această mișcare?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-buletin'">Sunteți sigur că doriți să ștergeți acest buletin de cântărire?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-holding'">Sunteți sigur că doriți să ștergeți această exploatație?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-contract'">Sunteți sigur că doriți să ștergeți acest contract?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-certificat'">Sunteți sigur că doriți să ștergeți acest certificat zootehnic?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-solicitare-certificat'">Sunteți sigur că doriți să ștergeți această solicitare?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'valideaza-adeverinta'">Sunteți sigur că doriți să validați această adeverință de apartenență?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'invalideaza-adeverinta'">Sunteți sigur că doriți să invalidați această adeverință de apartenență?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'anuleaza-adeverinta'">Sunteți sigur că doriți să anulați această adeverință de apartenență?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'sterge-adeverinta'">Ștergeți adeverința de apartenență?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-user'">Sunteți sigur că doriți să ștergeți acest utilizator?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-activitate'">Sunteți sigur că doriți să ștergeți această activitate?</h3>
      <h3 class="confirm-text" *ngSwitchCase="'delete-activities'">Sunteți sigur că doriți să ștergeți toate activitățile?</h3>

      <h3 class="confirm-text" *ngIf="text">{{ text }}</h3>
    </div>

    <div class="separator"></div>

    <div class="container-fluid confirm-modal">
      <button
        class="btn btn-warning"
        (click)="modal('Cross click'); notifyCloseModal()"
      >
        Nu
      </button>
      <button
        class="btn btn-blue"
        (click)="modal('Cross click'); notifyCloseModal(true)"
      >
        Da
      </button>
    </div>
  </div>
</ng-template>
