<div class="container-fluid mt-3">
  <div class="col-12">
    <div class="card settings-card">
      <form [formGroup]="incasareForm">
        <div class="row">
          <div class="col-12">
            <p class="settings-type">Încasare</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="exploatatie">
                Exploatație*
              </label>
              <ng-select
                [items]="holdingsNames"
                bindLabel="expl_name"
                bindValue="expl_code"
                placeholder="Caută după exploatație"
                formControlName="exploatatie"
                (search)="searchHoldings($event)"
                (clear)="clearHoldings()"
                (change)="selectExploatatie($event)"
                [ngClass]="{
                  'is-invalid': submitted && f.exploatatie.errors
                }"
              >
                <ng-template ng-label-tmp let-item="item">
                  {{ item.expl_name }} - {{ item.expl_code }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  {{ item.expl_name }} - {{ item.expl_code }}
                </ng-template>
              </ng-select>
              <small
                *ngIf="showHint"
                id="emailHelp"
                class="form-text text-muted"
              >
                <div class="mb-2">
                  <b>Introdu minim 4 caractere pentru a căuta</b>
                </div>
              </small>
              <div
                *ngIf="submitted && f.exploatatie.errors"
                class="invalid-feedback"
                [ngClass]="{
                  'd-block': submitted && f.exploatatie.errors
                }"
              >
                <div *ngIf="f.exploatatie.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-5 ml-auto">
            <div class="exploatatie-info">
              <div class="info-row">
                <div class="info-label">
                  Denumire Exploatație:
                </div>
                <div class="info-data">
                  {{ detalii ? detalii.name : "" }}
                </div>
              </div>
              <div class="info-row">
                <div class="info-label">
                  Județ:
                </div>
                <div class="info-data">
                  {{ detalii ? detalii.judet : "" }}
                </div>
              </div>
              <div class="info-row">
                <div class="info-label">
                  CUI/CNP:
                </div>
                <div class="info-data">
                  {{ detalii ? detalii.cif : "" }}
                </div>
              </div>
              <div class="info-row">
                <div class="info-label">
                  Beneficiază subvenție:
                </div>
                <div class="info-data">
                  <span [ngSwitch]="detalii?.subventionat">
                    <span *ngSwitchCase="0">Nu</span>
                    <span *ngSwitchCase="1">Da</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="table-responsive col-sm-12 col-md-10 mx-auto"
            #incasareTable
          >
            <div class="header-info">
              <label for="incasari-datatable">
                Facturi neachitate
              </label>
              <div class="total-restant" *ngIf="totalRestant">
                Total {{ totalRestant }}
              </div>
            </div>
            <table
              id="facturi-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th>
                    Seria și numărul
                  </th>
                  <th>
                    Data
                  </th>
                  <th>
                    Valoare Factură
                  </th>
                  <th>
                    Valoare de achitat
                  </th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="facturiNeachitate.length === 0"
                [dismissible]="false"
              >
                Nu a fost găsită nici o factură neachitată.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let factura of facturiNeachitate">
                  <td>
                    <div class="select-buttons">
                      <input
                        type="checkbox"
                        id="singleCheckbox1"
                        value="option1"
                        (click)="statusFactura(factura)"
                        [checked]="factura.isChecked"
                      />
                      <label>{{ factura.seria }}/{{ factura.numar }}</label>
                    </div>
                  </td>
                  <td>
                    {{ factura.data }}
                  </td>
                  <td>
                    {{ factura.valFactura }}
                  </td>
                  <td>
                    {{ factura.val_de_achitat }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="tip_incasare">
                Tip Încasare*
              </label>
              <ng-select
                (change)="selectTipIncasare($event)"
                formControlName="tip_incasare"
                [clearable]="false"
                placeholder="Alege tip încasare"
                [ngClass]="{
                  'is-invalid': submitted && f.tip_incasare.errors
                }"
              >
                <ng-option [value]="0">OP</ng-option>
                <ng-option [value]="1">Cash</ng-option>
                <ng-option [value]="2">Mandat poștal</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && f.tip_incasare.errors"
                class="invalid-feedback"
                [ngClass]="{
                  'd-block': submitted && f.tip_incasare.errors
                }"
              >
                <div *ngIf="f.tip_incasare.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="data">
              Data încasării*
            </label>
            <app-date-picker
              (dateChanged)="onDataIncasariiChanged($event)"
              [required]="true"
              [submitted]="submitted"
              [selectedDate]="f.data.value"
            ></app-date-picker>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="detalii">
              Detalii plată*
            </label>
            <input
              type="detalii"
              id="detalii"
              placeholder="Adaugă detaliile plății"
              class="form-control"
              formControlName="detalii"
              [ngClass]="{
                'is-invalid': submitted && f.detalii.errors
              }"
            />
            <div
              *ngIf="submitted && f.detalii.errors"
              class="invalid-feedback"
              [ngClass]="{
                'd-block': submitted && f.detalii.errors
              }"
            >
              <div *ngIf="f.detalii.errors.required">
                Completarea acestui câmp este obligatorie.
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="valoare">
              Valoare*
            </label>
            <input
              type="number"
              min="0"
              id="valoare"
              class="form-control"
              formControlName="valoare"
              (change)="valoareChanged($event)"
              [ngClass]="{
                'is-invalid': submitted && f.valoare.errors,
                disabled: sumOfValues === 0
              }"
            />
            <div
              *ngIf="submitted && f.valoare.errors && sumOfValues === 0"
              class="invalid-feedback"
              [ngClass]="{
                'd-block': submitted && f.valoare.errors && sumOfValues === 0
              }"
            >
              <div *ngIf="f.valoare.errors.required">
                Completarea acestui câmp este obligatorie.
              </div>
              <div *ngIf="f.facturi_list.length === 0">
                Nu a fost selectată nici o factură
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-12 text-right">
          <div class="btn-group my-2">
            <button
              class="btn btn-md btn-success py-1 px-3"
              (click)="onSubmittedIncasare()"
              [ngClass]="{
                'is-invalid': submitted,
                disabled: sumOfValues === 0
              }"
            >
              <i class="fe-save"></i>
              Plătește
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
