import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { RapoarteJurnalTableService } from 'src/app/modules/acbcr/common/services/rapoarte-jurnal.service';

@Component({
  selector: 'app-raport-jurnal',
  templateUrl: './raport-jurnal.component.html',
  styleUrls: ['./raport-jurnal.component.scss']
})
export class RaportJurnalComponent implements OnInit {

  tables$: Observable<any[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public rapoarteJurnalService: RapoarteJurnalTableService,
    public helperDataService: HelperDataService
  ) {
    this.tables$ = rapoarteJurnalService.tables$;
    this.total$ = rapoarteJurnalService.total$;
  }

  ngOnInit(): void {}

  getTableData() {
    this.loadingDataSpinner = true;
    this.rapoarteJurnalService
      .getRapoarteJurnalAPI(this.objToAPI).subscribe((response) => {
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    if (!event.data_start) {
      this.rapoarteJurnalService.clearRapoarteTable();
      this.loadingDataSpinner = false;
      return;
    }

    this.getTableData();
  }

  exportData(event) {
    this.rapoarteJurnalService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, `Rapoarte-jurnal.xls`);
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

}
