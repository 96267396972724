import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from '../models/common.models';
import { SortableTable } from './services-table-helper';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root'
})
export class UsersTableService extends SortableTable<User> {
  private baseUrl = environment.API_URL;

  users: User[];
  usersLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.users, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getUsersAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/users/listusers/`,
      {
        ...obj,
      },
      auth.options
    ).pipe(map((response: any) => {
      this.users = response.result;
      this.usersLength = response.count;

      this.paginate();
      this.sortEvent();
      this._total$.next(this.usersLength);

      return response;
    }));
  }

  addUtilizator(utilizator: User) {
    return this.http.post(
      `${this.baseUrl}/users/add/`,
      utilizator,
      auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  updateUtilizator(utilizator: User) {
    return this.http.post(
      `${this.baseUrl}/users/edit/`,
      utilizator,
      auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }


  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/users/export-xls/`,
      {
        ...obj
      },
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage['access-token']}`,
        })
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  deleteUtilizator(username: string) {
    return this.http.post(
      `${this.baseUrl}/users/delete/`,
      { username },
      auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.usersLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.usersLength) {
      this._state.endIndex = this.usersLength;
    }
  }

}
