import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AdvancedSortableDirective,
  SortEvent
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { User } from 'src/app/modules/acbcr/common/models/common.models';
import { UsersTableService } from 'src/app/modules/acbcr/common/services/users-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<AdvancedSortableDirective>;
  @ViewChild('utilizatoriTable', { static: false }) utilizatoriTable: ElementRef;
  @ViewChild('searchModal') searchModal: ElementRef;

  @Input() utilizatori: User[];
  @Input() utilizatoriLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() tableCallback = new EventEmitter();

  openSearchModal = false;
  openUtilizatorModal = false;
  modalSearchType = 'utilizator';
  openConfirmModal = false;
  deleteUsername = null;
  counties;
  isAdmin = false;
  isOperator = false;

  utilizator: User = null;
  filteredUsers;
  userSearchForm: UntypedFormGroup;

  constructor(
    public usersTableService: UsersTableService,
    private helperDataService: HelperDataService,
    public modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();

    this.userSearchForm = new UntypedFormGroup({
      search_text: new UntypedFormControl(),
      status: new UntypedFormControl(),
      user_type: new UntypedFormControl(),
      county_id: new UntypedFormControl(),
    })

    this.helperDataService.getStoreCounties()
      .subscribe((response: any) => {
        this.counties = response;
      });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.usersTableService.sortColumn = column;
    this.usersTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal = true;
  }

  filterSearch(event) {
    this.filterData.emit(event);
    this.filteredUsers = event;
  }

  exportTableXLSX() {
    let now = UIHelper.now();
    let fisier = `Utilizatori_${now}.xls`;

    this.usersTableService
      .exportXLS(this.filteredUsers)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, fisier);
      });
  }

  openAddEditUtilizatorModal(utilizator: User) {
    this.openUtilizatorModal = true;
    this.utilizator = utilizator;
  }

  closeUtilizatorModal(event) {
    this.openUtilizatorModal = event;
    this.utilizator = null;
  }

  getModalResult(event) {
    this.tableCallback.emit(event);
  }

  confirmUserDelete(username) {
    this.openConfirmModal = true;
    this.deleteUsername = username;
  }

  closeConfirmModal(event) {
    if (event === true) {
      this.usersTableService.deleteUtilizator(this.deleteUsername)
        .subscribe(() => {
          this.tableCallback.emit(event);
        });
    }

    this.openConfirmModal = false;
    this.deleteUsername = null;
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  searchUsers() {
    this.filterSearch(this.userSearchForm.value)
  }

  resetFields() {
    if (this.userSearchForm) {
      this.userSearchForm.reset();
    }
  }

  resetSearch() {
    this.resetFields();
    this.searchUsers();
  }

  changetipUtilizator() {
    if (this.userSearchForm.value.user_type != 5) {
      this.userSearchForm.controls.county_id.reset();
    }
  }
}
