import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { RaportFactura } from 'src/app/modules/acbcr/common/models/rapoarte-facturi.model';
import { RapoarteFacturiTableService } from 'src/app/modules/acbcr/common/services/rapoarte-facturi.service';

@Component({
  selector: 'app-rapoarte-facturi',
  templateUrl: './rapoarte-facturi.component.html',
  styleUrls: ['./rapoarte-facturi.component.scss']
})
export class RapoarteFacturiComponent implements OnInit {

  tables$: Observable<RaportFactura[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    company_id: null,
    luna: null,
    an: null,
    tip_factura: null,
    subventionata: null,
    page_nr: '1',
    page_size: '10'
  };

  constructor(
    public rapoarteFacturiTableService: RapoarteFacturiTableService,
    public helperDataService: HelperDataService
  ) {
    this.tables$ = rapoarteFacturiTableService.tables$;
    this.total$ = rapoarteFacturiTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.rapoarteFacturiTableService
      .getRapoarteFacturiAPI(this.objToAPI).subscribe((response) => {
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  exportData(event) {
    this.rapoarteFacturiTableService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, `Rapoarte Facturi.xls`);
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_nr = event;
    this.getTableData();
  }

}
