import { Component, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ContabilitateSettingsService } from 'src/app/modules/acbcr/common/services/contabilitate-settings.service';

@Component({
  selector: 'app-facturi-chitante-settings',
  templateUrl: './facturi-chitante-settings.component.html',
  styleUrls: ['./facturi-chitante-settings.component.scss']
})
export class FacturiChitanteSettingsComponent implements OnInit {
  facturaSettingsForm;
  chitantaSettingsForm;
  facturaSubmitted = false;
  chitantaSubmitted = false;
  facturaEditSuccess = false;
  chitantaEditSuccess = false;

  constructor(
    private contabilitateSettingsService: ContabilitateSettingsService,
  ) {}

  ngOnInit(): void {
    // factura
    this.buildFacturaForm();

    // chitanta
    this.buildChitantaForm();
    this.getChitantaSetttings();
  }

  getFacturaSetttings(tip) {
    this.contabilitateSettingsService.getFacturiSettings(tip).subscribe(settings => {
      if (settings) {
        this.buildFacturaForm(settings);
      } else {
        this.buildFacturaForm();
      }
    });
    this.facturaSubmitted = false;
  }

  getChitantaSetttings() {
    this.contabilitateSettingsService.getChitanteSettings().subscribe(settings => {
      if (settings) {
        this.buildChitantaForm(settings);
      } else {
        this.buildChitantaForm();
      }
    });
    this.chitantaSubmitted = false;
  }

  selectTipFactura(tip) {
    this.getFacturaSetttings(tip);
  }

  buildFacturaForm(formData?) {
    this.facturaSettingsForm = new UntypedFormGroup({
      numar: new UntypedFormControl(
        formData ? formData.numar : null, Validators.compose([
          Validators.required, Validators.min(0)
        ])),
      proc_tva: new UntypedFormControl(
        formData ? formData.proc_tva : null, Validators.compose([
          Validators.required, Validators.min(0), Validators.max(99.99)
        ])),
      seria: new UntypedFormControl(formData ? formData.seria : null, Validators.required),
      tip: new UntypedFormControl(formData ? formData.tip : null, Validators.required)
    });
  }

  buildChitantaForm(formData?) {
    this.chitantaSettingsForm = new UntypedFormGroup({
      numar: new UntypedFormControl(
        formData ? formData.numar : null, Validators.compose([
          Validators.required, Validators.min(0)
        ])),
      seria: new UntypedFormControl(formData ? formData.seria : null, Validators.required)
    });
  }

  get f() { return this.facturaSettingsForm.controls; }
  get fch() { return this.chitantaSettingsForm.controls; }

  onSubmittedFactura() {
    this.facturaSubmitted = true;
    if (this.facturaSettingsForm.valid) {
      this.saveFacturaSettingsDetails();
    }
  }

  saveFacturaSettingsDetails() {
    this.contabilitateSettingsService.updateFacturi(this.facturaSettingsForm.value).subscribe(response => {
      if (response) {
        this.buildFacturaForm(response.result);
      }
    });

    this.facturaEditSuccess = true;
    setTimeout(() => {
      this.facturaEditSuccess = false;
    }, 3000);
  }

  onSubmittedChitanta() {
    this.chitantaSubmitted = true;
    if (this.chitantaSettingsForm.valid) {
      this.saveChitantaSettingsDetails();
    }
  }

  saveChitantaSettingsDetails() {
    this.contabilitateSettingsService.updateChitante(this.chitantaSettingsForm.value).subscribe(response => {
      if (response) {
        this.buildChitantaForm(response.result);
      }
    });

    this.chitantaEditSuccess = true;
    setTimeout(() => {
      this.chitantaEditSuccess = false;
    }, 3000);
  }

}
