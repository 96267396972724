<ng-template #proceseazaCertificatModal let-modal="close">
    <div class="modal-header">
      <h3 class="modal-title">
        Procesează solicitare certificat zootehnic
        <span class="ml-3 badge badge-light border p-1">
          Nr. {{ solicitare ? solicitare.numar : '' }} / {{ solicitare ? solicitare.data_dmy : '' }}
        </span>
      </h3>
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-secondary"
          (click)="modal('Cross click')"
          autofocus="false"
        >
          Închide
        </button>
      </div>
    </div>

    <div class="modal-body d-flex">
      <div
        *ngIf="solicitare && solicitare.animale.length > 0; else noAnimals"
        class="overflow-auto flex-shrink-0 pr-1 mr-1"
      >
        <div
          *ngFor="let animal of solicitare.animale; let index=index"
          class="item-row"
          [ngClass]="{ active: index === currentIndex}"
          (click)="getAnimalData(index)"
        >
          <div class="fs-4 font-weight-bold d-flex">
            <ng-container [ngSwitch]="animal.sex">
              <span *ngSwitchCase="1" class="box-mascul">M</span>
              <span *ngSwitchCase="2" class="box-femela">F</span>
            </ng-container>

            <span
              [ngClass]="{
                'color-mascul': animal.sex === 1,
                'color-femela': animal.sex === 2
              }"
            >{{ animal.numarmatricol }}</span>
          </div>

          <div class="fs-5 font-weight-bold d-flex justify-content-between">
            <div class="ml-4">
              {{ animal.rasa }}, vârsta {{ animal.varsta }} zile
            </div>

            <div
              [ngSwitch]="animal.status_solicitare_cz"
              class="ml-2"
            >
              <span class="badge badge-warning" *ngSwitchCase="3">Procesare</span>
              <span class="badge badge-secondary" *ngSwitchCase="4">Admis, așteaptă semnătura</span>
              <span class="badge badge-danger" *ngSwitchCase="5">Respins</span>
              <span class="badge badge-success" *ngSwitchCase="6">Eliberat</span>
            </div>
          </div>
        </div>
      </div>

      <ng-template #noAnimals>
        <h4>Nu a fost găsit niciun animal eligibil</h4>
      </ng-template>

      <div *ngIf="loadingDataSpinner">
        <div
          class="spinner-border text-info table-spinner avatar-xxl"
          role="status"
        >
        </div>
      </div>

      <div
        *ngIf="animalProcesat"
        class="overflow-auto container-fluid"
      >

        <div class="row mr-2 font-weight-bold">
          <div class="col-auto mr-3">
            <div class="fs-4 d-flex">
              <span
                [ngClass]="{
                  'color-mascul': animalProcesat.sex === 1,
                  'color-femela': animalProcesat.sex === 2
                }"
              >{{ animalProcesat.numarmatricol }}</span>
            </div>

            <div class="fs-5 d-flex">
              {{ animalProcesat.rasa }}
              , varsta {{ animalProcesat.varsta }} zile
              , {{ animalProcesat.sex === 1 ? 'mascul' : 'femelă'}}
            </div>
          </div>

          <div class="col-auto mr-1">
            Greutate la naștere:
            <span
              [ngClass]="animalProcesat.greutate_nastere > 0 ? 'text-success': 'text-danger'"
            >{{ animalProcesat.greutate_nastere }} kg</span>
          </div>

          <div class="col-auto mr-1">
            Greutate la 200 zile:
            <span
              [ngClass]="animalProcesat.gvr200 > 0 ? 'text-success': 'text-danger'"
            >{{ animalProcesat.gvr200 }} kg</span>

            <br>
            SMZ la 200 zile:
            <span
              [ngClass]="animalProcesat.smz200 > 0 ? 'text-success': 'text-danger'"
            >{{ animalProcesat.smz200 }} g</span>
          </div>

          <div class="col-auto">
            Greutate la 365 zile:
            <span
              [ngClass]="animalProcesat.gvr365 > 0 ? 'text-success': 'text-danger'"
            >{{ animalProcesat.gvr365 }} kg</span>

            <br>
            SMZ la 365 zile:
            <span
              [ngClass]="animalProcesat.smz365 > 0 ? 'text-success': 'text-danger'"
            >{{ animalProcesat.smz365 }} g</span>
          </div>
        </div>

        <div class="row fs-5 mt-2">
          <div class="col-12">
            <div>Exploatație: <b>{{ animalProcesat.nume_exploatatie }}</b></div>
            <div>Crescător: <b>{{ animalProcesat.nume_crescator }}</b></div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <h5>Pedigree</h5>

            <div class="row mt-1">
              <div class="col-12 chart-col">
                <p-organizationChart
                  [value]="animalProcesatFamilyTree"
                  [preserveSpace]="false"
                  styleClass="company"
                  >
                  <ng-template let-node pTemplate="animal">
                    <div>
                      <div class="node-header ui-corner-top">
                        <span>{{ node.numarmatricol }}</span>
                        <div *ngIf="node.sex == 'M'">Mascul</div>
                        <div *ngIf="node.sex == 'F'">Femelă</div>
                      </div>
                      <div class="node-content">
                        <div>{{ node.rasa }}</div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template let-node pTemplate="parent">
                    <div>
                      <div class="node-header">
                        {{ node.numarmatricol }}
                        <div *ngIf="node.sex == 'M'">Mascul</div>
                        <div *ngIf="node.sex == 'F'">Femelă</div>
                      </div>
                      <div class="node-content" *ngIf="node.father">
                        {{ node.father }},
                        {{ node.rasa }}
                      </div>
                    </div>
                  </ng-template>
                </p-organizationChart>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12">
            <h5>Stare solicitare</h5>

            <div class="row">
              <div class="col-12 input-col">
                <div class="form-group mb-3">
                  <div class="radio mt-1 radio-group">
                    <input
                      type="radio"
                      [(ngModel)]="animalProcesatStatus"
                      [value]="3"
                      id="status_solicitare_cz_1"
                      class="mr-3"
                      [disabled]="animalProcesatStatus === 6"
                    />
                    <label for="status_solicitare_cz_1">Procesare</label>

                    <input
                      type="radio"
                      [(ngModel)]="animalProcesatStatus"
                      [value]="5"
                      id="status_solicitare_cz_2"
                      class="mr-3"
                      [disabled]="animalProcesatStatus === 6"
                    />
                    <label for="status_solicitare_cz_2">Respins</label>

                    <input
                      type="radio"
                      [(ngModel)]="animalProcesatStatus"
                      [value]="4"
                      id="status_solicitare_cz_3"
                      class="mr-3"
                      [disabled]="animalProcesatStatus === 6"
                    />
                    <label for="status_solicitare_cz_3">Admis, așteaptă semnătura</label>

                    <input
                      type="radio"
                      [(ngModel)]="animalProcesatStatus"
                      [value]="6"
                      id="status_solicitare_cz_4"
                      class="mr-3"
                      disabled
                    />
                    <label for="status_solicitare_cz_4">Eliberat</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12 text-right">
            <button
              *ngIf="!isLast && (animalProcesatStatus !== 6)"
              class="btn btn-blue btn-sm mr-1"
              type="button"
              (click)="salveazaSolicitare()"
              >
              <i class="fe-save mr-1"></i>
              <span>Salvează și mergi la următorul</span>
            </button>

            <button
              *ngIf="animalProcesatStatus !== 6"
              class="btn btn-success btn-sm"
              (click)="salveazaSolicitare(true)"
              autofocus="false"
              >
              <i class="fe-save mr-1"></i>
              <span>Salvează și închide</span>
            </button>
          </div>
        </div>

      </div>


    </div>
  </ng-template>
