<app-births-table
  [births]="tables$ | async"
  [birthsLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (birthTableCallback)="getCallbackResult($event)"
></app-births-table >
