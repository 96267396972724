import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistruGenealogicTableService } from '../../../common/services/registru-genealogic-table.service';

@Component({
  selector: 'app-registru-genealogic',
  templateUrl: './registru-genealogic.component.html',
  styleUrls: ['./registru-genealogic.component.scss'],
  providers: [RegistruGenealogicTableService]
})
export class RegistruGenealogicComponent implements OnInit {
  tables$: Observable<any[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;
  dataChanged;

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public registruGenealogicTableService: RegistruGenealogicTableService,
  ) {
    this.tables$ = registruGenealogicTableService.tables$;
    this.total$ = registruGenealogicTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.registruGenealogicTableService
      .getAnimalsAPI(this.objToAPI).subscribe((response) => {
        // Hide spinner, after 1 second
        setTimeout(() => {
          this.dataChanged = Math.random();
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    // Compose the object what will be sended to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

}
