<app-adeverinte-apartenenta-table
  [adeverinteApartenenta]="tables$ | async"
  [adeverinteApartenentaLength]="total$ | async"
  [currentHolding]="currentHolding"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (callbackResult)="getCallBackResult($event)"
></app-adeverinte-apartenenta-table>
