import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { OperatorsTableService } from 'src/app/modules/acbcr/common/services/operators-table.service';

@Component({
  selector: 'app-reproduction-search',
  templateUrl: './reproduction-search.component.html',
  styleUrls: ['./reproduction-search.component.scss']
})
export class ReproductionSearchComponent implements OnInit, OnDestroy {
  @Input() isFermier;
  @Output() searchResult = new EventEmitter();
  reproductionSearchForm;
  operatoriInsamantare = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentRoute: string;
  exploatationCode;
  selectedHoldingId;

  femele = [];
  tauri = [];

  constructor(
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>,
    private operatorsTableService: OperatorsTableService,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });

    this.reproductionSearchForm = new UntypedFormGroup({
      company_id: new UntypedFormControl(),
      matricol_femela: new UntypedFormControl(),
      matricol_taur: new UntypedFormControl(),
      tip_reproductie: new UntypedFormControl(),
      id_operator: new UntypedFormControl(),
    });

    this.store.pipe(
      select(getLoadingState),
      filter(isLoading => !isLoading),
      switchMap(() => this.store.pipe(select(getSelectedHolding))),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
      takeUntil(this.destroy$),
    ).subscribe((response: any) => {
      if (this.currentRoute.includes('/exploatatie')) {
        this.selectedHoldingId = response?.id;
      } else {
        this.selectedHoldingId = null;
      }

      this.reproductionSearchForm.controls.company_id.setValue(this.selectedHoldingId);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  searchMatricolFemela(event) {
    if (event.term.length < 4) {
      this.femele = [];
      return;
    }

    let req_params = {
      numarmatricol: event.term,
      sex: 2,
      companyid: this.selectedHoldingId,
    };

    this.helperDataService.getAnimals(req_params)
      .subscribe((response: any) => {
        this.femele = response.result;
      });
  }

  searchMatricolTaur(event) {
    if (event.term.length < 4) {
      this.tauri = [];
      return;
    }

    let req_params = {
      numarmatricol: event.term,
      sex: 1,
      companyid: this.selectedHoldingId,
    };

    this.helperDataService.getAnimals(req_params)
      .subscribe((response: any) => {
        this.tauri = response.result;
      });
  }

  searchOperator(event) {
    if (event.term.length < 4) {
      this.operatoriInsamantare = [];
      return;
    }

    this.operatorsTableService.getOperatorsAPI({nume: event.term, order_by: 'nume'})
      .subscribe((response: any) => {
        if (response && response.result) {
          this.operatoriInsamantare = response.result;
        }
      });
  }

  onSubmitted() {
    this.reproductionSearchForm.controls.company_id.setValue(this.selectedHoldingId);
    this.searchResult.emit(this.reproductionSearchForm.value);
  }

  resetSearch() {
    this.reproductionSearchForm.reset();
    this.onSubmitted();
  }
}
