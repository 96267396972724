import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './../../common/services/auth.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import packageJson from 'package.json';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  loginForm: UntypedFormGroup;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: NotificationErrorService,
  ) { }

  ngOnInit() {
    const versiune_interfata = packageJson.version

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
      versiune_interfata: versiune_interfata,
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterViewInit() {
    // Force refresh to fix bad caching set in the past
    this.forceReloadInterfaceFiles();

    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  forceReloadInterfaceFiles() {
    let forceReload = localStorage.getItem('force_reload');

    if (!forceReload) {
      localStorage.setItem('force_reload', 'yes');
      window.location.reload();
    } else {
      localStorage.removeItem('force_reload');
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.authService
      .login(this.loginForm.value)
      .pipe()
      .subscribe({
        next: (response) => {
          if (response.atentionari) {
            this.errorService.processErrorMsg(response.atentionari);
          }
        },
        error: (errors) => {
          this.error = errors.error.error;

          if (!this.error) {
            this.error = 'A apărut o eroare'
          }

          setTimeout(() => {
            this.error = '';
          }, 8000);
        }
      });
  }
}
