import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AdeverintaApiaService } from 'src/app/modules/acbcr/common/services/adeverinta-apia.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-adeverinta-apia',
  templateUrl: './adeverinta-apia.component.html',
  styleUrls: ['./adeverinta-apia.component.scss']
})
export class AdeverintaApiaComponent implements OnInit {
  @ViewChild('searchModal') searchModal: ElementRef;
  @ViewChild('cancelDeleteModal') cancelDeleteModal: ElementRef;
  @ViewChild('finalizeazaModal') finalizeazaModal: ElementRef;
  @ViewChild('anuleazaModal') anuleazaModal: ElementRef;
  @Input() numarAdeverinte: number;

  listaAdeverinte;
  loadingDataSpinner = false;
  addEditModalType: string;
  addEditModalData = {
    data: null,
    exploatatie_id: null,
    rasa_id: null,
    centru_apia: null,
    animale: {
      vaci: [],
      vaciMetise: [],
      tauri: [],
    }
  };
  idStergeAdeverinta = null;
  idFinalizeazaAdeverinta = null;
  idAnuleazaAdeverinta = null;
  openConfirmModal = false;
  confirmType: string;
  AdeverintaAPIAForm: UntypedFormGroup;
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  userId;

  req_params = {
    page_no: 1,
    page_size: 10,
  };

  constructor(
    public adeverintaApiaService: AdeverintaApiaService,
    public errorService: NotificationErrorService,
    public modalService: NgbModal,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.userId = UIHelper.userId();

    let startDate = null;
    if (this.isAdmin || this.isOperator) {
      const year = new Date().getFullYear();
      startDate = `01.01.${year}`
    }

    this.AdeverintaAPIAForm = new UntypedFormGroup({
      start_date: new UntypedFormControl(startDate),
      end_date: new UntypedFormControl(),
      company_name: new UntypedFormControl(),
      company_code: new UntypedFormControl(),
      rasa: new UntypedFormControl(),
      status: new UntypedFormControl(),
    });

    this.getTableData()
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.listaAdeverinte = [];
    let list_params = {
      ...this.req_params,
      ...this.AdeverintaAPIAForm.value
    };

    this.adeverintaApiaService.listAPI(list_params)
      .subscribe({
        next: (response) => {
          this.loadingDataSpinner = false;
          this.listaAdeverinte = response.result;
          this.numarAdeverinte = response.count;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  changePageSize(event) {
    this.req_params.page_no = 1;
    this.req_params.page_size = event.target.value;
    this.adeverintaApiaService.page = 1;
    this.getTableData();
  }

  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  openAddAdeverintaModal(type, id?) {
    this.addEditModalType = type;
    this.addEditModalData = {
      data: null,
      exploatatie_id: null,
      rasa_id: null,
      centru_apia: null,
      animale: {
        vaci: [],
        vaciMetise: [],
        tauri: [],
      }
    };
  }

  salveazaNumarData(adeverinta) {
    let params = {
      id: adeverinta.id,
      numar: adeverinta.numar,
      data: adeverinta.data,
    }

    this.adeverintaApiaService.salveazaNrDataAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  clickStergeAdeverinta(event, id) {
    this.idStergeAdeverinta = id;
    this.modalService.open(this.cancelDeleteModal, {
      scrollable: false,
    });
  }

  confirmareStergeAdeverinta(confirmare?: boolean) {
    if (confirmare && this.idStergeAdeverinta) {
      this.stergeAdeverinta(this.idStergeAdeverinta);
    } else {
      this.ngOnInit();
    }
  }

  stergeAdeverinta(id) {
    let params = {
      id: id,
    }

    let buton = document.getElementsByClassName('sterge-id-' + id)[0];
    buton?.setAttribute('disabled', 'true');

    this.adeverintaApiaService.stergeAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
          return response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  clickFinalizeazaAdeverinta(event, id) {
    this.idFinalizeazaAdeverinta = id;
    this.modalService.open(this.finalizeazaModal, {
      scrollable: false,
    });
  }

  confirmareFinalizeazaAdeverinta(confirmare?: boolean) {
    if (confirmare && this.idFinalizeazaAdeverinta) {
      this.finalizeazaAdeverinta(this.idFinalizeazaAdeverinta);
    } else {
      this.ngOnInit();
    }
  }

  finalizeazaAdeverinta(id) {
    let params = {
      id: id,
    }

    let buton = document.getElementsByClassName('finalizeaza-id-' + id)[0];
    buton?.setAttribute('disabled', 'true');

    this.adeverintaApiaService.finalizeazaAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
          return response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  clickAnuleazaAdeverinta(event, id) {
    this.idAnuleazaAdeverinta = id;
    this.modalService.open(this.anuleazaModal, {
      scrollable: false,
    });
  }

  confirmareAnuleazaAdeverinta(confirmare?: boolean) {
    if (confirmare && this.idAnuleazaAdeverinta) {
      this.anuleazaAdeverinta(this.idAnuleazaAdeverinta);
    } else {
      this.ngOnInit();
    }
  }

  anuleazaAdeverinta(id) {
    let params = {
      id: id,
    }

    let buton = document.getElementsByClassName('anuleaza-id-' + id)[0];
    buton?.setAttribute('disabled', 'true');

    this.adeverintaApiaService.anuleazaAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
          return response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  closeAddEditModal(event) {
    this.ngOnInit();
  }

  onSort(event) {
    // to do
  }

  downloadAdeverinta(event, adeverinta) {
    event.target.disabled = true;
    let fisier = `AdeverintaAPIA_${adeverinta.cod_exploatatie}.pdf`;

    this.adeverintaApiaService.descarcaAPI(adeverinta.id)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;

        },
        error: (errors) => {
          event.target.disabled = false;
          let blobToTextPromise = errors.error.text();
          blobToTextPromise.then((text: string) => {
            let obj;
            try {
              obj = JSON.parse(text);
            } catch (e) {
              return;
            }

            this.errorService.processErrorMsg(obj.error);
          })
        }
      });
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  onStartDateChanged(event) {
    if (this.AdeverintaAPIAForm) {
      this.AdeverintaAPIAForm.controls.start_date.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.AdeverintaAPIAForm) {
      this.AdeverintaAPIAForm.controls.end_date.setValue(event);
    }
  }

  cauta() {
    this.req_params.page_no = 1;
    this.adeverintaApiaService.page = 1;
    this.getTableData();
  }

  export() {
    let export_params = this.AdeverintaAPIAForm.value;
    let now = UIHelper.now();
    let fisier = `Adeverinta_APIA_${now}.xls`;

    this.adeverintaApiaService.exportAPI(export_params)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);

        },
        error: (errors) => {
          let blobToTextPromise = errors.error.text();
          blobToTextPromise.then((text: string) => {
            let obj;
            try {
              obj = JSON.parse(text);
            } catch (e) {
              return;
            }

            this.errorService.processErrorMsg(obj.error);
          })
        }
      });
  }

  resetFields() {
    if (this.AdeverintaAPIAForm) {
      this.AdeverintaAPIAForm.reset();
    }
  }

  resetSearch() {
    this.resetFields();
    this.cauta();
  }

  editeaza(adeverinta) {
    this.adeverintaApiaService.dateAdeverintaAPI(adeverinta.id)
      .subscribe({
        next: (response) => {
          this.addEditModalType = 'edit';
          this.addEditModalData = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

}
