<div id="wrapper">

  <app-topbar
    (settingsButtonClicked)="onSettingsButtonClicked()"
    (mobileMenuButtonClicked)="onToggleMobileMenu()"
  >
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <div class="content-page">
    <div class="content">
        <!-- content -->
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>
