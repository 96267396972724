import { AuthGuard } from './common/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AnimalsComponent } from './components/pages/animals/animals.component';
import { HoldingsComponent } from './components/pages/holdings/holdings.component';
import { ContractsComponent } from './components/pages/contracts/contracts.component';
import { AllowancesComponent } from './components/pages/allowances/allowances.component';
import { AdeverintaApiaComponent } from './components/pages/adeverinta-apia/adeverinta-apia.component';
import { AdeverintaAjutorComponent } from './components/pages/adeverinta-ajutor/adeverinta-ajutor.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HoldingComponent } from './components/pages/holdings/components/holding/holding.component';
import { CantaririComponent } from './components/pages/holdings/components/holding/menu-tabs/cantariri/cantariri.component';
import { DocumenteComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente.component';
import { UsersComponent } from './components/pages/users/users.component';

import { AnimalComponent } from './components/pages/animals/components/animal/animal.component';
import { AnimalHistoryComponent } from './components/pages/animals/components/animal/menu-tabs/animal-history/animal-history.component';
import { AnimalDetailsComponent } from './components/pages/animals/components/animal/menu-tabs/animal-details/animal-details.component';
import { HoldingHistoryComponent } from './components/pages/holdings/components/holding/menu-tabs/holding-history/holding-history.component';
import { AnimalPedigreeRgComponent } from './components/pages/animals/components/animal/menu-tabs/animal-pedigree-rg/animal-pedigree-rg.component';
import { AnimalPerformanceComponent } from './components/pages/animals/components/animal/menu-tabs/animal-performance/animal-performance.component';
import { ReproductionComponent } from './components/pages/reproduction/reproduction.component';
import { BirthsComponent } from './components/pages/births/births.component';
import { MovesComponent } from './components/pages/moves/moves.component';
import { OperatorsComponent } from './components/pages/operators/operators.component';
import { ContractTabComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/contract-tab/contract-tab.component';
import { Anexa3TabComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/anexa3-tab/anexa3-tab.component';
import { Anexa8TabComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/anexa8-tab/anexa8-tab.component';
import { IntrebariFrecventeComponent } from './components/dashboard/staticpages/intrebari-frecvente/intrebari-frecvente.component';
import { ContactComponent } from './components/dashboard/staticpages/contact/contact.component';
import { TermeniConditiiComponent } from './components/dashboard/staticpages/termeni-conditii/termeni-conditii.component';
import { RegistruGenealogicComponent } from './components/pages/registru-genealogic/registru-genealogic.component';
import { AdeverinteApartenentaComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/adeverinte-apartenenta-tab/adeverinte-apartenenta-tab.component';
import { SettlementsComponent } from './components/pages/settlements/settlements.component';
import { PerformanceComponent } from './components/pages/performance/performance.component';
import { SettingsPageComponent } from './components/pages/settings-page/settings-page.component';
import { SetariActivitatiPageComponent } from './components/pages/contabilitate/setari/setari-activitati-page/setari-activitati-page.component';
import { UmCriteriiSettingsComponent } from './components/pages/contabilitate/setari/um-criterii-settings/um-criterii-settings.component';
import { FacturiChitanteSettingsComponent } from './components/pages/contabilitate/setari/facturi-chitante-settings/facturi-chitante-settings.component';
import { ServiciiSettingsComponent } from './components/pages/contabilitate/setari/servicii-settings/servicii-settings.component';
import { FacturiComponent } from './components/pages/contabilitate/facturi/facturi.component';
import { ActivitatiComponent } from './components/pages/contabilitate/activitati/activitati.component';
import { CriteriiCantitativeSettingsComponent } from './components/pages/contabilitate/setari/criterii-cantitative-settings/criterii-cantitative-settings.component';
import { IncasariComponent } from './components/pages/contabilitate/incasari/incasari.component';
import { IncasareComponent } from './components/pages/contabilitate/incasari/incasare/incasare.component';
import { RapoarteIncasariComponent } from './components/pages/contabilitate/incasari/rapoarte-incasari/rapoarte-incasari.component';
import { RapoarteFacturiComponent } from './components/pages/contabilitate/facturi/rapoarte-facturi/rapoarte-facturi.component';
import { RaportIncasariComponent } from './components/pages/contabilitate/rapoarte/raport-incasari/raport-incasari.component';
import { RaportFacturiEmiseComponent } from './components/pages/contabilitate/rapoarte/raport-facturi-emise/raport-facturi-emise.component';
import { RaportFacturiNeachitateComponent } from './components/pages/contabilitate/rapoarte/raport-facturi-neachitate/raport-facturi-neachitate.component';
import { RaportClientComponent } from './components/pages/contabilitate/rapoarte/raport-client/raport-client.component';
import { RaportJurnalComponent } from './components/pages/contabilitate/rapoarte/raport-jurnal/raport-jurnal.component';
import { RaportGeneralComponent } from './components/pages/raport-general/raport-general.component';
import { EvaluareGeneticaComponent } from './components/pages/evaluare-genetica/evaluare-genetica.component';
import { IncadrareSectiuneComponent } from './components/pages/incadrare-sectiune/incadrare-sectiune.component';
import { RegistrulMonteFatariComponent } from './components/pages/registrul-monte-fatari/registrul-monte-fatari.component';
import { RegistrulMonteInHaremComponent } from './components/pages/registrul-monte-in-harem/registrul-monte-in-harem.component';
import { AnimaleRneComponent } from './components/pages/managementul-fermei/animale-rne/animale-rne.component';
import { ManagementFurajareComponent } from './components/pages/managementul-fermei/management-furajare/management-furajare.component';
import { ActiuniSanitarVeterinareComponent } from './components/pages/managementul-fermei/actiuni-sanitar-veterinare/actiuni-sanitar-veterinare.component';
import { PerformanteValorificareComponent } from './components/pages/managementul-fermei/performante-valorificare/performante-valorificare.component';
import { EvidenteFermaComponent } from './components/pages/managementul-fermei/evidente-ferma/evidente-ferma.component';
import { CalculeComponent } from './components/pages/managementul-fermei/calcule/calcule.component';
import { VenituriSubventiiComponent } from './components/pages/managementul-fermei/venituri-subventii/venituri-subventii.component';
import { DocumentIstoricComponent } from './components/pages/document-istoric/document-istoric.component';
import { AvizareTauriComponent } from './components/pages/avizare-tauri/avizare-tauri.component';
import { CertificatSanitarComponent } from './components/pages/certificat-sanitar/certificat-sanitar.component';
import { SolicitariCertificateZootehniceComponent } from './components/pages/solicitari-certificate-zootehnice/solicitari-certificate-zootehnice.component';
import { CertificateZootehniceComponent } from './components/pages/certificate-zootehnice/certificate-zootehnice.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'contabilitate', component: DashboardComponent, canActivate: [AuthGuard], children: [
      { path: 'setari-activitati', component: SetariActivitatiPageComponent },
      { path: 'setari-servicii', component: ServiciiSettingsComponent },
      { path: 'setari-um', component: UmCriteriiSettingsComponent },
      { path: 'setari-chitante-facturi', component: FacturiChitanteSettingsComponent },
      { path: 'activitati', component: ActivitatiComponent },
      { path: 'criterii-cantitative', component: CriteriiCantitativeSettingsComponent },
      { path: 'vizualizare-facturi', component: FacturiComponent },
      { path: 'activitati', component: ActivitatiComponent },
      { path: 'chitante', component: IncasariComponent },
      { path: 'incasare', component: IncasareComponent },
      { path: 'raport-incasari', component: RaportIncasariComponent },
      { path: 'raport-facturi-emise', component: RaportFacturiEmiseComponent },
      { path: 'raport-facturi', component: RapoarteFacturiComponent },
      { path: 'raport-facturi-neachitate', component: RaportFacturiNeachitateComponent },
      { path: 'raport-client', component: RaportClientComponent },
      { path: 'raport-jurnal', component: RaportJurnalComponent },
    ] },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
      { path: 'intrebari-frecvente', component: IntrebariFrecventeComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'terms', component: TermeniConditiiComponent },
      { path: 'exploatatii', component: HoldingsComponent },
      { path: 'exploatatie/:code', component: HoldingComponent, children: [
        { path: 'animale-exploatatie', component: AnimalsComponent },
        { path: 'reproductie', component: ReproductionComponent },
        { path: 'fatari', component: BirthsComponent },
        { path: 'miscari', component: MovesComponent },
        { path: 'cantariri', component: CantaririComponent },
        { path: 'documente', component: DocumenteComponent, children: [
          { path: 'contracte', component: ContractTabComponent },
          { path: 'anexe-3', component: Anexa3TabComponent },
          { path: 'anexe-8', component: Anexa8TabComponent },
          { path: 'solicitari-certificate-zootehnice', component: SolicitariCertificateZootehniceComponent },
          { path: 'certificate-zootehnice', component: CertificateZootehniceComponent },
          { path: 'adeverinte-apartenenta', component: AdeverinteApartenentaComponent },
        ] },
        { path: 'istoric', component: HoldingHistoryComponent }
      ] },
      { path: 'animale', component: AnimalsComponent },
      { path: 'animal/:id', component: AnimalComponent, children: [
        { path: 'istoric-animal', component: AnimalHistoryComponent },
        { path: 'pedigree-rg', component: AnimalPedigreeRgComponent },
        { path: 'performante', component: AnimalPerformanceComponent },
        { path: 'detalii', component: AnimalDetailsComponent },
      ] },
      { path: 'solicitari-certificate-zootehnice', component: SolicitariCertificateZootehniceComponent },
      { path: 'activitate-documente', component: DocumentIstoricComponent },
      { path: 'certificate-zootehnice', component: CertificateZootehniceComponent },
      { path: 'adeverinte', component: AllowancesComponent },
      { path: 'adeverinta-apia', component: AdeverintaApiaComponent },
      { path: 'adeverinta-ajutor', component: AdeverintaAjutorComponent },
      { path: 'contracte', component: ContractsComponent },
      { path: 'registru-genealogic', component: RegistruGenealogicComponent },
      { path: 'utilizatori', component: UsersComponent },
      { path: 'setari', component: SettingsPageComponent },
      { path: 'reproductie', component: ReproductionComponent },
      { path: 'fatari', component: BirthsComponent },
      { path: 'miscari', component: MovesComponent },
      { path: 'testarea-performantelor', component: PerformanceComponent },
      { path: 'operatori-inseminare', component: OperatorsComponent },
      { path: 'localitati', component: SettlementsComponent },
      { path: 'raport_indicatori', component: RaportGeneralComponent },
      { path: 'raport_femele', component: RaportGeneralComponent },
      { path: 'raport_descendenti', component: RaportGeneralComponent },
      { path: 'raport_animale', component: RaportGeneralComponent },
      { path: 'raport_teste_adn', component: RaportGeneralComponent },
      { path: 'raport_informare_lunara_cppc', component: RaportGeneralComponent },
      { path: 'raport_atentionari_fermier', component: RaportGeneralComponent },
      { path: 'raport_grafic_cppc', component: RaportGeneralComponent },
      { path: 'raport_anz', component: RaportGeneralComponent },
      { path: 'raport_coeficientul_de_izolare_reproductiva', component: RaportGeneralComponent },
      { path: 'raport_consangvinizare', component: RaportGeneralComponent },
      { path: 'raport_durata_medie_de_exploatare', component: RaportGeneralComponent },
      { path: 'raport_exploatatii', component: RaportGeneralComponent },
      { path: 'raport_autorizatii_monta', component: RaportGeneralComponent },
      { path: 'raport_date_colectate', component: RaportGeneralComponent },
      { path: 'evaluare-genetica', component: EvaluareGeneticaComponent },
      { path: 'incadrare-sectiune', component: IncadrareSectiuneComponent },
      { path: 'registrul_de_monte_si_fatari', component: RegistrulMonteFatariComponent },
      { path: 'registrul_de_monte_in_harem', component: RegistrulMonteInHaremComponent },
      { path: 'avizare-tauri', component: AvizareTauriComponent },
      { path: 'certificat-sanitar', component: CertificatSanitarComponent },
      { path: 'managementul-fermei', children: [
        { path: 'animale-rne', component: AnimaleRneComponent },
        { path: 'management-furajare', component: ManagementFurajareComponent },
        { path: 'actiuni-sanitar-veterinare', component: ActiuniSanitarVeterinareComponent, children: [
          { path: 'registrul-tratamente-vaccinari', component: ActiuniSanitarVeterinareComponent },
          { path: 'registrul-mortalitati', component: ActiuniSanitarVeterinareComponent },
        ] },
        { path: 'performante-valorificare', component: PerformanteValorificareComponent },
        { path: 'evidente-ferma', component: EvidenteFermaComponent, children: [
          { path: 'registrul-exploatatiei', component: EvidenteFermaComponent },
          { path: 'terenuri', component: EvidenteFermaComponent, children: [
            { path: 'pasuni', component: EvidenteFermaComponent },
            { path: 'fanete', component: EvidenteFermaComponent },
            { path: 'arabil', component: EvidenteFermaComponent },
          ] },
        ] },
        { path: 'calcule', component: CalculeComponent, children: [
          { path: 'capacitatea-de-pasunat', component: CalculeComponent },
          { path: 'incarcatura-uvm-ha-apia', component: CalculeComponent },
          { path: 'pasunat-rational', component: CalculeComponent },
          { path: 'productie-furaj', component: CalculeComponent },
        ] },
        { path: 'venituri-subventii', component: VenituriSubventiiComponent },
      ] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class ACBCRRoutingModule { }
