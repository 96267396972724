import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../state/app.state';
import { SetAppError } from '../state/actions/error.actions';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Injectable({
  providedIn: 'root'
})
export class NotificationErrorService {
  constructor(
    private store: Store<ApplicationState>
  ) { }

  processErrorMsg(msg) {
    let text = '';
    switch (msg) {
      case 'company with this code already exists.':
        text = 'Există o companie care are setat același cod de exploatație.';
        break;
      case 'This code already exist':
        text = 'Codul operatorului este setat unui alt operator existent.';
        break;
      case 'duplicate_numarmatricol':
        text = 'Numărul matricol este setat unui alt animal existent.';
        break;
      case 'Enter a valid email address.':
        text = 'Adresa de email introdusă nu este validă.';
        break;
      case 'duplicate_username':
        text = 'Numele utilizatorului introdus este setat unui alt utilizator existent.';
        break;
      case 'status update error':
        text = 'Eroare actualizare stare exploatatie';
        break;
      case 'procent rasa mai mare decat 100':
        text = 'Procentajul total al raselor nu trebuie să depășească 100';
        break;
      case 'rase similare':
        text = 'Nu putem avea două sau mai multe rase de același fel';
        break;
      case 'Intervalul autorizatiei monta se suprapune cu o autorizatie existenta.':
        text = 'Intervalul autorizatiei monta se suprapune cu o autorizatie existenta.';
        break;
      case 'Cont fermier fata exploatatie':
        text = 'Cont fermier fără exploatație';
        break;
      case 'Nu are permisiuni':
        text = 'Nu aveți permisiunile necesare pentru a efectua această operațiune';
        break;
      case 'cannot_delete_animal':
        text = 'Acest animal nu poate fi șters din motiv că este părinte.';
        break;
      case 'data_monta_indainte_de_nastere_taur':
        text = 'Data montei nu poate fi înaintea datei de naștere a taurului.';
        break;
      case 'data_monta_indainte_de_nastere_femela':
        text = 'Data montei nu poate fi înaintea datei de naștere a femelei.';
        break;
      case 'reg_genealogic_not_found':
        text = 'Animalul nu are registru genealogic.';
        break;
      case 'Unauthorized':
        text = 'Nu aveți permisiunile necesare pentru a accesa această pagină.';
        break;
      case 'numar and data should be provided':
        text = 'Adeverința de apartenență nu are număr/dată.';
        break;
      case 'invalid_um_code':
        text = 'Nu ai introdus nici o unitate de măsură.';
        break;
      case 'Toate câmpurile sunt obligatorii':
        text = 'Toate câmpurile sunt obligatorii';
        break;
      case 'could_not_send_email':
        text = 'Trimitere email eșuată';
        break;
      case 'Anularea este posibilă în maxim 30 de zile de la emitere':
        text = 'Anularea este posibilă în maxim 30 de zile de la emitere';
        break;
      case 'cant_delete_item':
        text = 'Această unitate de măsură nu se poate șterge.';
        break;
      default:
        text = msg;
    }

    if (msg instanceof Array) {
      text = msg.join(' | ')
    }

    this.store.dispatch(new SetAppError(text));
  }

}
