<ng-template
  #addModal
  let-modal="close"
>
  <div class="modal-header row">
    <div class="col-sm-12 col-md-6 custom-col">
      <h3 class="modal-title" *ngIf="modalType === 'add'; else editTitle">
        Exploatație nouă
      </h3>

      <ng-template #editTitle>
        <h3 class="modal-title">
          {{modalType === 'view' ? 'Vizualizează  exploatația' : 'Editează exploatația'}}
        </h3>
      </ng-template>
    </div>

    <div class="col-sm-12 col-md-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          autofocus="false"
          class="btn btn-sm btn-dark"
          [disabled]="statusChanged"
          (click)="resetModal()"
        >
          {{modalType === 'view' ? 'Închide' : 'Anulează'}}
        </button>
        <button
          *ngIf="modalType !== 'view'"
          class="btn btn-sm btn-success"
          (click)="onSubmitted()"
        >

          <span *ngIf="modalType === 'add'; else btnTxt">
            Salvează noua exploatație
          </span>
          <ng-template #btnTxt>
            <span>
              Actualizează exploatația
            </span>
          </ng-template>

        </button>
      </div>
    </div>
  </div>

  <div class="d-flex overflow-auto">
    <div class="modal-sidebar d-none d-xl-block">
      <ul class="modal-menu">
        <li
          (click)="scrollToElement('#identificare')"
          [ngClass]="{'active': elemName.includes('identificare')}"
        >
          Identificare
        </li>
        <li
          (click)="scrollToElement('#ad-exploatatie')"
          [ngClass]="{'active': elemName.includes('ad-exploatatie')}"
        >
          Adresa
        </li>
          <li
          (click)="scrollToElement('#administrator')"
          [ngClass]="{'active': elemName.includes('administrator')}"
        >
          Administrator exploatație
        </li>
        <li
          (click)="scrollToElement('#contact')"
          [ngClass]="{'active': elemName.includes('contact')}"
        >
          Contact
        </li>
        <li
          (click)="scrollToElement('#date-bancare')"
          [ngClass]="{'active': elemName.includes('date-bancare')}"
        >
          Date bancare
        </li>
        <li
          (click)="scrollToElement('#locatii')"
          [ngClass]="{'active': elemName.includes('locatii')}"
        >
          Locații
        </li>
      </ul>
    </div>

    <div class="modal-body">
      <div class="container-fluid">
        <div class="">
          <form [formGroup]="holdingForm" [ngClass]="{'disabled' : modalType === 'view'}">

            <app-identificare-form-block
              [holdingForm]="holdingForm"
              [submitted]="submitted"
              [CAENCodes]="CAENCodes"
              [modalType]="modalType"
              (changeStatus)="changeStatus($event)"
            >
            </app-identificare-form-block>

            <app-adresa-exp-form-block
              [holdingForm]="holdingForm"
              [submitted]="submitted"
            >
            </app-adresa-exp-form-block>

            <app-admin-exp-form-block
              [holdingForm]="holdingForm"
              [submitted]="submitted"
              [admins]="admins"
            >
            </app-admin-exp-form-block>

            <app-contact-form-block
              [holdingForm]="holdingForm"
              [submitted]="submitted"
            >
            </app-contact-form-block>

            <app-date-bancare-form-block
              [holdingForm]="holdingForm"
              [submitted]="submitted"
              [modalType]="modalType"
            >
            </app-date-bancare-form-block>

            <app-holding-locations
              [holdingForm]="holdingForm"
              [submitted]="submitted"
            >
            </app-holding-locations>

          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
