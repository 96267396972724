import {
  Component, OnInit, Input, OnDestroy, OnChanges, Output, EventEmitter,
  ViewChild, ElementRef
} from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { HoldingsNames } from 'src/app/modules/acbcr/common/models/holdings.models';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppHoldingsNames, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { takeUntil } from 'rxjs/operators';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { AddNewHoldingData } from 'src/app/modules/acbcr/common/state/actions/holdings.actions';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { AnimalHelper } from '../../../../../../common/helper/animal-helper';
import { convertDateToBackendFormat } from 'src/app/modules/acbcr/common/services/services-table-helper';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-identificare-animal-form-block',
  templateUrl: './identificare-form-block.component.html',
  styleUrls: ['./identificare-form-block.component.scss']
})
export class IdentificareAnimalFormBlockComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('stergeTestADNModal') stergeTestADNModal: ElementRef;
  @ViewChild('stergeFisierTestADNModal') stergeFisierTestADNModal: ElementRef;
  @ViewChild('corespundeStandardModal') corespundeStandardModal: ElementRef;

  @Input() animalForm: UntypedFormGroup;
  @Input() evaluareGenetica;
  @Input() areFatare;
  @Input() submitted: boolean;
  @Input() modalType: string;
  @Input() errorCodTaur: any;
  @Input() disableAllFields: boolean;
  @Input() invalidAnimalSex: boolean;
  @Output() selectedParent = new EventEmitter();
  @Output() updateFields = new EventEmitter();
  @Output() closeUpdateModal = new EventEmitter();

  holdingsNames: HoldingsNames[];
  holdingsNameForOwners: HoldingsNames[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  matricoleAnimal = [];
  status: boolean;
  exploatationId: number;
  currentRoute: string;
  observatie: string;
  dateError = false;
  isAdmin = false;
  isSuperAdmin = false;
  isOperator = false;
  isFermier = false;

  searchingAnimals;
  openAddEditModal = false;
  holdingData: any;
  indexStergeTestADN = null;
  indexStergeFisierTestADN = null;
  corespundeStandardSwitch;
  arePoze = false;

  uploadConfig = UIHelper.uploadConfig;

  constructor(
    private store: Store<ApplicationState>,
    private animalsTableService: AnimalsTableService,
    private helperDataService: HelperDataService,
    public modalService: NgbModal,
    public errorService: NotificationErrorService,
  ) { }


  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.store.pipe(select(getAppHoldingsNames))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HoldingsNames[]) => {
        this.holdingsNames = response;
        this.holdingsNameForOwners = response;
      });

    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });

    if (this.currentRoute.includes('/animale-exploatatie') &&
        !AnimalHelper.isEditingParents(this.modalType)) {

      // Read from the store selected holding data
      // For add animals from a specific holding display only the current holding
      this.store.pipe(select(getSelectedHolding))
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.exploatationId = response.id;
          this.holdingsNames.map(holding => {
            if (holding.id === this.exploatationId) {
              this.holdingsNames = [holding];
              setTimeout(() => {
                this.animalForm.controls.exploatatieid.setValue(holding.id);
              }, 0);
            }
          });
        });
    }

    if (this.animalForm.value.mamapurtatoarematricol) {
      this.matricoleAnimal = [{id: this.animalForm.value.mamapurtatoareid, numarmatricol: this.animalForm.value.mamapurtatoarematricol}];
    }

    let poze = this.animalForm.value.poze;
    this.arePoze = false;

    if (poze && poze.length > 0) {
      this.arePoze = true;
    }
  }

  ngOnChanges() {
    if (this.animalForm.value.status == 1) {
      this.status = true;
    } else {
      this.status = false;
    }
  }

  changeStatus(event) {
    switch (event) {
      case true:
        this.animalForm.controls.status.setValue(1);
        break;
      case false:
        this.animalForm.controls.status.setValue(-1);
        break;
    }
  }

  // Get forms controls state
  get f() { return this.animalForm.controls; }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  numarmatricolChange(event) {
    this.updateFields.emit(event);
  }

  sexChange(event) {
    this.invalidAnimalSex = false;
    this.updateFields.emit(event);
  }

  searchAnimal(event) {
    if (event.term.length >= 3) {
      this.animalsTableService
        .searchAnimal(event.term, this.animalForm.value.sex)
        .subscribe(response => {
          this.searchingAnimals = response.result;

          for (let i = 0; i < this.searchingAnimals.length; i++) {
            if (this.searchingAnimals[i].nume_exploatatie === null) {
              this.searchingAnimals[i].fullDescription = `${this.searchingAnimals[i].numarmatricol}`;
            } else {
              this.searchingAnimals[i].fullDescription =
                `${this.searchingAnimals[i].numarmatricol} - ${this.searchingAnimals[i].nume_exploatatie}`;
            }
          }

          // If the searching term doesn't exist in the API, that means the user want to add a new animal
          if (this.searchingAnimals.length < 1) {
            this.searchingAnimals.push(
              { id: -1, fullDescription: event.term.toUpperCase(), numarmatricol: event.term.toUpperCase() }
            );
          }

        });
    }

  }

  selectedAnimal(event) {
    this.selectedParent.emit(
      event !== undefined ? event : undefined
    );
  }

  searchMatricol(event, sex) {
    if (event.term.length >= 3 && this.matricoleAnimal.length === 0) {
      this.helperDataService
        .getAnimalInfo(event.term, sex)
        .subscribe((response: any) => {
          this.matricoleAnimal = response.result;
        });
    } else if (event.term.length < 3) {
      this.matricoleAnimal = [];
    }
  }

  clearMatricoleAnimal() {
    this.matricoleAnimal = [];
  }

  dataNasteriiChanged(event) {
    this.animalForm.controls.datanastere.setValue(event);
    this.updateFields.emit(event);
    this.compareDates(event, this.f.dataintrareinexploatatie.value);
  }

  dataIntrareExploatatieChanged(event) {
    this.animalForm.controls.dataintrareinexploatatie.setValue(event);
    this.compareDates(event, this.f.datanastere.value);
  }

  compareDates(selectedDate, birthDate) {
    if (Date.parse(convertDateToBackendFormat(selectedDate)) < Date.parse(convertDateToBackendFormat(birthDate))) {
      this.dateError = true;
      this.f.dataintrareinexploatatie.setErrors({incorrect: true});
    } else {
      this.dateError = false;
      this.f.dataintrareinexploatatie.setErrors(null);
    }
  }

  openAddHoldingModal() {
    this.openAddEditModal = true;
  }

  closeAddUpdateModal(event) {
    this.openAddEditModal = event;
  }

  updateHoldingsList(event) {
    this.store.dispatch(
      new AddNewHoldingData(
        { id: event.id, name: event.name }
      )
    );
  }

  onKeyUp() {
    if (this.animalForm.value.codtaur.length) {
      this.errorCodTaur = false;
    }
  }

  get test_adn(): UntypedFormArray {
    return this.animalForm.get('test_adn') as UntypedFormArray;
  }

  get test_adn_files(): UntypedFormArray {
    return this.animalForm.get('test_adn_files') as UntypedFormArray;
  }

  adaugaTestADN() {
    let cz_disabled = false;
    if (this.test_adn.controls.length > 0) {
      let fg = this.test_adn.controls[0] as UntypedFormGroup;

      if (! this.isSuperAdmin) {
        cz_disabled = fg.controls.readonly.value;
      }
    }

    let testADNForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      metoda: new UntypedFormControl(),
      tip: new UntypedFormControl(),
      id_rg: new UntypedFormControl(),
      numar_adn: new UntypedFormControl(),
      afiseaza_in_cz: new UntypedFormControl({value: false, disabled: cz_disabled}),
      readonly: new UntypedFormControl(false),
    });

    this.test_adn.push(testADNForm);
  }

  clickStergeTestADN(index) {
    this.indexStergeTestADN = index;
    this.modalService.open(this.stergeTestADNModal, {
      scrollable: false,
    });
  }

  confirmareStergeTestADN(confirmare?: boolean) {
    if (confirmare) {
      this.test_adn.removeAt(this.indexStergeTestADN);
    }
  }

  uploadServerResponse(event) {
    const file = event.body.files[0];

    let testADNFileForm = new UntypedFormGroup({
      fileid: new UntypedFormControl(file.file_id),
      uploaded_file_name: new UntypedFormControl(file.file_uploaded_name),
    });
    this.test_adn_files.push(testADNFileForm);
  }

  downloadFisierTestADN(event, testADNFileForm: UntypedFormGroup) {
    event.target.disabled = true;
    let fileName = testADNFileForm.value.uploaded_file_name;
    let fileid = testADNFileForm.value.fileid;

    this.helperDataService.downloadFile(fileid).subscribe((response: any) => {
      const blob = new Blob([response]);
      this.helperDataService.simulateDownload(blob, fileName);
      event.target.disabled = false;

    }, errors => {
      let blobToTextPromise = errors.error.text();
      blobToTextPromise.then((text: string) => {
        let obj;
        try {
          obj = JSON.parse(text);
        } catch (e) {
          return;
        }

        this.errorService.processErrorMsg(obj.error);
      })
    });
  }

  clickStergeFisierTestADNModal(index) {
    this.indexStergeFisierTestADN = index;
    this.modalService.open(this.stergeFisierTestADNModal, {
      scrollable: false,
    });
  }

  confirmareStergeFisierTestADNModal(confirmare?: boolean) {
    if (confirmare) {
      this.test_adn_files.removeAt(this.indexStergeFisierTestADN);
    }
  }

  changeAfiseazaInCZ(testADNForm: UntypedFormGroup) {
    for (let i = 0; i < this.test_adn.controls.length; i++) {
      let arrayFormGroup = this.test_adn.controls[i] as UntypedFormGroup;

      if (arrayFormGroup == testADNForm) {
        arrayFormGroup.controls.afiseaza_in_cz.setValue(true);
      } else {
        arrayFormGroup.controls.afiseaza_in_cz.setValue(false);
      }
    }
  }

  clickCorespundeStandard() {
    this.corespundeStandardSwitch = ! this.animalForm.value.corespunde_standard;

    this.modalService.open(this.corespundeStandardModal, {
      centered: true,
      scrollable: false,
    });
  }

  confirmareCorespundeStandard(confirmare?: boolean) {
    if (confirmare) {
      let req_params = {
        id: this.animalForm.value.id,
        observatie: this.observatie,
        corespunde_standard: this.corespundeStandardSwitch,
      };

      this.animalsTableService.corespundeStandardAPI(req_params)
        .subscribe({
          next: (response) => {
            this.closeUpdateModal.emit();
          },
          error: (errors) => {
            this.errorService.processErrorMsg(errors.error.error);
          }
        });
    }
  }
}
