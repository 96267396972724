import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { takeUntil } from 'rxjs/operators';
import { getAppHoldingsNames } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { Subject } from 'rxjs';
import { HoldingsNames } from 'src/app/modules/acbcr/common/models/holdings.models';

@Component({
  selector: 'app-rapoarte-facturi-search',
  templateUrl: './rapoarte-facturi-search.component.html',
  styleUrls: ['./rapoarte-facturi-search.component.scss'],
})
export class RapoarteFacturiSearchComponent implements OnInit, OnDestroy {
  @Output() searchResult = new EventEmitter();

  raportFacturiSearchForm;
  holdingsNames: HoldingsNames[];
  showHint = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  anList = [];
  lunaList = [];
  subventionatList = [{name: 'Toate', value: null}, {name: 'Da', value: '0'}, {name: 'Nu', value: '1'}];

  constructor(
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(getAppHoldingsNames))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HoldingsNames[]) => {
        this.holdingsNames = response;
      });

    for (let i = 1; i < 13; i++) {
      this.lunaList.push(i);
    }

    for (let i = 2010; i < 2030; i++) {
      this.anList.push(i);
    }

    this.raportFacturiSearchForm = new UntypedFormGroup({
      company_id: new UntypedFormControl(null),
      tip_factura: new UntypedFormControl(null),
      luna: new UntypedFormControl(null),
      an: new UntypedFormControl(null),
      subventionata: new UntypedFormControl(null),
    });
  }

  onSubmitted() {
    if (!this.raportFacturiSearchForm.value.an || !this.raportFacturiSearchForm.value.luna) {
      this.raportFacturiSearchForm.controls.an.setValue(null);
      this.raportFacturiSearchForm.controls.luna.setValue(null);
    }

    this.searchResult.emit(this.raportFacturiSearchForm.value);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  searchHoldings(event) {
    if (event.term.length >= 4) {
      this.showHint = false;
    } else {
      this.showHint = true;
    }
  }

  clearHoldings() {
    this.showHint = true;
  }

  resetSearch() {
    this.raportFacturiSearchForm.reset();
    this.onSubmitted();
  }
}
