import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnChanges,
  Input,
  HostListener,
  EventEmitter,
  Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
  UntypedFormControl} from '@angular/forms';
import { ContabilitateSettingsService } from 'src/app/modules/acbcr/common/services/contabilitate-settings.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { getAppHoldingsNames } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { Subject } from 'rxjs';
import { HoldingsNames } from 'src/app/modules/acbcr/common/models/holdings.models';
import { FacturiTableService } from 'src/app/modules/acbcr/common/services/facturi-table.service';

@Component({
  selector: 'app-factura-manuala',
  templateUrl: './factura-manuala.component.html',
  styleUrls: ['./factura-manuala.component.scss']
})

export class FacturaManualaComponent implements OnInit, OnChanges {
  @ViewChild('facturaManualaModal') facturaManualaModal: ElementRef;
  @Input() open = false;
  @Input() modalType;
  @Input() facturaData;
  @Input() preserieFactura;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();
  activities;
  submitted = false;
  editSuccess = false;
  activeFields = [];
  isChecked = false;
  holdingsNames: HoldingsNames[];
  umList;
  raseList;
  dataFacturii: null;

  modalReference: NgbModalRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  facturaManuala: UntypedFormGroup;

  constructor(
    public modalService: NgbModal,
    private contabilitateSettingsService: ContabilitateSettingsService,
    private facturiTableService: FacturiTableService,
    private store: Store<ApplicationState>,
    private errorService: NotificationErrorService,
  ) { }

  ngOnInit(): void {
    this.store
      .pipe(select(getAppHoldingsNames))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HoldingsNames[]) => {
        this.holdingsNames = response;
      });

    this.contabilitateSettingsService.getUMSettings().subscribe(ums => {
      this.umList = ums;
    });

    this.facturiTableService.getAcbcrRacesAPI().subscribe(races => {
      this.raseList = races;
    });
  }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.facturaManualaModal,
        { windowClass: 'modal-half-full', scrollable: true, backdrop: 'static' });
    }

    if (this.facturaData) {
      this.facturaManuala = new UntypedFormGroup({
        sections: new UntypedFormArray([
          this.buildForm(this.facturaData),
        ]),
      });
    } else {
      this.facturaManuala = new UntypedFormGroup({
        sections: new UntypedFormArray([
          this.buildForm(),
        ]),
      });
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  truncate(num) {
    if (num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    }
  }

  buildForm(formData?) {
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(formData ? formData.id : null, Validators.required),
      companyid: new UntypedFormControl(formData ? formData.companyid : null, Validators.required),
      data: new UntypedFormControl(formData ? formData.data : null, Validators.required),
      tip: new UntypedFormControl(3),
      status: new UntypedFormControl(0),
      incasare: new UntypedFormControl(0),
      subventie_refuzata: new UntypedFormControl(0),
      data_activitati: new UntypedFormControl(formData ? formData.data_activitati : null, Validators.required),
      tva: new UntypedFormControl(formData ? formData.tva : this.preserieFactura?.proc_tva, Validators.required),
      seria: new UntypedFormControl(formData ? formData.seria : null, Validators.required),
      numar: new UntypedFormControl(formData ? formData.numar : null, Validators.required),
      subventie: new UntypedFormControl(formData ? formData.subventie : null, Validators.required),
      factura_val_fara_tva: new UntypedFormControl(formData ? formData.factura_val_fara_tva : 0, Validators.required),
      factura_val_tva: new UntypedFormControl(formData ? formData.factura_val_tva : 0, Validators.required),
      factura_val_cu_tva: new UntypedFormControl(formData ? parseFloat(formData.factura_val_fara_tva) +
        parseFloat(formData.factura_val_tva) : 0, Validators.required),
      activities: new UntypedFormArray([])
    });

    formGroup?.controls.data.setValue(formData?.data);

    const control = formGroup.get('activities') as UntypedFormArray;

    formData?.activities.map(activity => {
      control.push(this.initActivity(activity));
    });

    if (this.modalType === 'add') {
      control.push(this.initActivity());
    }

    return formGroup;
  }

  initActivity(activities?) {
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(activities ? activities.id : null, Validators.required),
      activitateaid: new UntypedFormControl(activities ? activities.activitateaid : 1, Validators.required),
      descriere: new UntypedFormControl(activities ? activities.descriere : '', Validators.required),
      descriere_subventie: new UntypedFormControl(activities ? activities.descriere_subventie : ''),
      subventie: new UntypedFormControl(activities ? activities.subventie : 0),
      contributia_clientului: new UntypedFormControl(activities ? activities.contributia_clientului : ''),
      proc_subventie: new UntypedFormControl(activities ? activities.proc_subventie : ''),
      proc_tva_activitate: new UntypedFormControl(activities ? activities.proc_tva_activitate : ''),
      total_pu: new UntypedFormControl(0, Validators.required),
      total_val_fara_tva: new UntypedFormControl(0, Validators.required),
      total_val_tva: new UntypedFormControl(0, Validators.required),
      total_val_cu_tva: new UntypedFormControl(0, Validators.required),
      services: new UntypedFormArray([ ])
    });

    const control = formGroup.get('services') as UntypedFormArray;
    const TVA = formGroup.value.proc_tva_activitate ? parseFloat(formGroup.value.proc_tva_activitate) : parseFloat(this.facturaData?.tva);


    let sumPU = 0;
    let sumTotalFaraTva = 0;
    let sumTotalValTva = 0;

    activities?.services.map(service => {
      sumPU += parseFloat(service.pu);
      sumTotalFaraTva += (parseFloat(service.pu) * parseFloat(service.cantitate));
      sumTotalValTva += ((parseFloat(service.pu) * parseFloat(service.cantitate)) * (TVA / 100));
      control.push(this.initServices(service, formGroup.value.proc_tva_activitate));
    });

    if (this.modalType === 'add' || control.length === 0) {
      control.push(this.initServices());
    }

    formGroup.controls.total_pu.setValue(sumPU.toFixed(4));
    formGroup.controls.total_val_fara_tva.setValue(sumTotalFaraTva.toFixed(2));
    formGroup.controls.total_val_tva.setValue(sumTotalValTva.toFixed(2));
    formGroup.controls.total_val_cu_tva.setValue((parseFloat(sumTotalFaraTva.toFixed(2)) + parseFloat(sumTotalValTva.toFixed(2))).toFixed(2));

    return formGroup;
  }

  initServices(services?, procTva?) {
    const formGroup =  new UntypedFormGroup({
      id: new UntypedFormControl(services ? services.id : null, Validators.required),
      descriere: new UntypedFormControl(services ? services.descriere : null, Validators.required),
      um: new UntypedFormControl(services ? services.um : null, Validators.required),
      cantitate: new UntypedFormControl(services ? services.cantitate : null, Validators.required),
      pu: new UntypedFormControl(services ? parseFloat(services.pu).toFixed(4) : null, Validators.required),
      tva: new UntypedFormControl(services ? services.val_tva : null, Validators.required),
      rasa: new UntypedFormControl(services ? services.rasa : null, Validators.required),
      val_fara_tva: new UntypedFormControl(0, Validators.required),
      val_tva: new UntypedFormControl(0, Validators.required),
      val_cu_tva: new UntypedFormControl(0, Validators.required),
    });

    if (services) {
      const PU = formGroup.controls.pu.value;
      const Q = formGroup.controls.cantitate.value;
      const TVA = procTva ? parseFloat(procTva) : parseFloat(this.facturaData?.tva);

      const valFaraTva = (PU * Q).toFixed(2);
      const valTva = (PU * Q * (TVA / 100)).toFixed(2);
      const valCuTva = (parseFloat(valFaraTva) + parseFloat(valTva)).toFixed(2);

      formGroup.controls.val_fara_tva.setValue(valFaraTva);
      formGroup.controls.val_tva.setValue(valTva);
      formGroup.controls.tva.setValue(valTva);
      formGroup.controls.val_cu_tva.setValue(valCuTva);
    }

    return formGroup;
  }

  addActivity(i) {
    const control = this.facturaManuala.get('sections') as UntypedFormArray;
    const control2 = control.controls[i].get('activities') as UntypedFormArray;
    control2.push(this.initActivity());
  }

  calculateActivityTotal(section, i) {
    const control = this.facturaManuala.get('sections') as UntypedFormArray;
    const control2 = control.controls[i]?.get('activities') as UntypedFormArray;
    let sumTotalFaraTva = 0;
    let sumTotalValTva = 0;
    let sumTotalSubventie = 0;

    control2?.controls.map(c => {
      sumTotalFaraTva += parseFloat(c.value.total_val_fara_tva);
      sumTotalValTva += parseFloat(c.value.total_val_tva);
      sumTotalSubventie += parseFloat(c.value.subventie);
    });

    section.controls.factura_val_fara_tva.setValue(sumTotalFaraTva.toFixed(2));
    section.controls.factura_val_tva.setValue(sumTotalValTva.toFixed(2));
    section.controls.factura_val_cu_tva.setValue(parseFloat(sumTotalValTva.toFixed(2)) + parseFloat(sumTotalFaraTva.toFixed(2)));
    section.controls.subventie.setValue(sumTotalSubventie.toFixed(2));

  }

  calculateServiceTotal(activity, i, j) {
    const control = this.facturaManuala.get('sections') as UntypedFormArray;
    const control2 = control.controls[i].get('activities') as UntypedFormArray;
    const control3 = control2.controls[j].get('services') as UntypedFormArray;

    let sumPU = 0;
    let sumTotalFaraTva = 0;
    let sumTotalValTva = 0;
    let sumTotalValCuTva = 0;

    control3.controls.map(c => {
      c.value.tva = c.value.val_tva;
      sumPU += parseFloat(c.value.pu);
      sumTotalFaraTva += parseFloat(c.value.val_fara_tva);
      sumTotalValTva += parseFloat(c.value.val_tva);
      sumTotalValCuTva += parseFloat(c.value.val_cu_tva);
    });

    activity.controls.total_pu.setValue(sumPU.toFixed(4));
    activity.controls.total_val_fara_tva.setValue(sumTotalFaraTva.toFixed(2));
    activity.controls.total_val_tva.setValue(sumTotalValTva.toFixed(2));
    activity.controls.total_val_cu_tva.setValue(sumTotalValCuTva.toFixed(2));
    activity.controls.subventie.setValue((parseFloat(sumTotalFaraTva.toFixed(2)) * (activity.value.proc_subventie / 100)).toFixed(2));
  }

  addService(i, j) {
    const control = this.facturaManuala.get('sections') as UntypedFormArray;
    const control2 = control.controls[i].get('activities') as UntypedFormArray;
    const control3 = control2.controls[j].get('services') as UntypedFormArray;
    control3.push(this.initServices());
  }

  changedPU(activity, service, event, q, tva) {
    const PU = event.target.value;
    const Q = q;
    const TVA = activity.value.proc_tva_activitate ? parseFloat(activity.value.proc_tva_activitate) : parseFloat(tva);

    const valFaraTva = (PU * Q).toFixed(2);
    const valTva = (PU * Q * (TVA / 100)).toFixed(2);
    const valCuTva = (parseFloat(valFaraTva) + parseFloat(valTva)).toFixed(2);

    activity.controls.services.controls[service].controls.val_fara_tva.setValue(valFaraTva);
    activity.controls.services.controls[service].controls.val_tva.setValue(valTva);
    activity.controls.services.controls[service].controls.val_cu_tva.setValue(valCuTva);
  }

  changedQ(activity, service, event, pu, tva) {
    const PU = pu;
    const Q = event.target.value;
    const TVA = activity.value.proc_tva_activitate ? parseFloat(activity.value.proc_tva_activitate) : parseFloat(tva);

    const valFaraTva = (PU * Q).toFixed(2);
    const valTva = (PU * Q * (TVA / 100)).toFixed(2);
    const valCuTva = (parseFloat(valFaraTva) + parseFloat(valTva)).toFixed(2);

    activity.controls.services.controls[service].controls.val_fara_tva.setValue(valFaraTva);
    activity.controls.services.controls[service].controls.val_tva.setValue(valTva);
    activity.controls.services.controls[service].controls.val_cu_tva.setValue(valCuTva);
  }

  getSections(form) {
    return form.controls.sections.controls;
  }

  getActivities(form) {
    return form.controls.activities.controls;
  }

  getServices(form) {
    return form.controls.services.controls;

  }

  removeActivity(i, j) {
    const control = this.facturaManuala.get('sections') as UntypedFormArray;
    const control2 = control.controls[i].get('activities') as UntypedFormArray;

    control2.removeAt(j);
  }

  removeService(activity, i, j, k) {
    const control = this.facturaManuala.get(['sections', i, 'activities', j, 'services']) as UntypedFormArray;
    control.removeAt(k);
    this.calculateServiceTotal(activity, i, j);
  }

  onSubmit(form) {
    this.facturiTableService.saveFacturaAPI(form.value.sections[0]).subscribe(response => {
      this.preserieFactura = null;
      this.sendCallbackResult();
    }, errors => {
        this.errorService.processErrorMsg('Toate campurile serviciilor sunt obligatorii');
    });
  }

  changeStatusFacturi(factura) {
    this.facturiTableService.valideazaFacturaAPI(factura).subscribe(response => {
      this.sendCallbackResult();
    });
  }

  makeActive(k) {
    this.activeFields[k] = true;
  }

  deactivate(k) {
    this.activeFields[k] = false;
  }

  notifyCloseModal() {
    this.open = false;
    this.notifyClose.emit(false);
  }

  oDateChanged(event, section) {
    this.dataFacturii = event;
    section.controls.data.setValue(event);
    section.controls.data_activitati.setValue(event);
  }

  private sendCallbackResult() {
    this.modalReference.close();
    this.callbackResult.emit('');
    this.notifyClose.emit(false);
  }

  checkSubventie(tipFactura, subventionat): boolean {
    if (tipFactura === 3 && subventionat === true) {
      return false;
    } else if (subventionat === false) {
      return false;
    } else if (subventionat === true) {
      return true;
    }
  }

}
