import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApplicationState } from './../app.state';
import { AppUserActions, AppUserActionType } from './../actions/user.actions';

export interface AppUser {
  current_user: any;
}

export const initialState = {
  current_user: {}
};

export const getApplicationFeature =
  createFeatureSelector<ApplicationState>('appData');

export const getCurrentUser =
  createSelector(
    getApplicationFeature,
    state => state.user.current_user
  );

export function appUserReducers(
  state: AppUser = initialState,
  action: AppUserActions
) {
  switch (action.type) {
    case AppUserActionType.SET_USER_DATA:
      return {
        ...state,
        current_user: action.payload
      };

    default:
      return state;
  }
}
