<app-reproduction-table
  [reproductions]="tables$ | async"
  [reproductionsLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (reprTableCallback)="getCallbackResult($event)"
></app-reproduction-table >
