<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-pattern">
          <div class="card-body p-4">
            <!-- Logo -->
            <div class="text-center w-75 m-auto">
              <a routerLink="/account/login">
                <img src="assets/images/logo.png" />
              </a>
              <p class="text-muted mb-4 mt-3">
                Introduceți de două ori noua dumneavoastră parolă.
              </p>
            </div>


            <form
              class="needs-validation"
              name="resetPassword"
              [formGroup]="resetPassword"
              (ngSubmit)="onSubmit()"
            >


              <div class="form-group mb-3">
                <label for="parola">Parola nouă</label>

                <input
                  type="password"
                  formControlName="new_password1"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.new_password1.errors }"
                  id="parola"
                  placeholder="Parola nouă"
                />

                 <div
                  *ngIf="submitted && f.new_password1.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.new_password1.errors.required">
                    Completarea acestui câmp este obligatorie.
                  </div>
                </div>

              </div>

              <div class="form-group mb-3">
                <label for="new_password2">Repetați parola nouă</label>

                <input
                  type="password"
                  formControlName="new_password2"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.new_password2.errors }"
                  id="new_password2"
                  placeholder="Repetați parola nouă"
                />

                 <div
                  *ngIf="submitted && f.new_password1.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.new_password1.errors.required">
                    Completarea acestui câmp este obligatorie.
                  </div>
                </div>

                <input type="hidden"
                  formControlName="uid"
                />

                <input type="hidden"
                  formControlName="token"
                />

              </div>

                <!-- Short password -->
                <div
                  *ngIf="shortPassword"
                  class="alert alert-danger text-center"
                >
                  Parola trebuie să conțină minim 8 caractere
                </div>

                <!-- Same password -->
                <div
                  *ngIf="samePassword"
                  class="alert alert-danger text-center"
                >
                  Parola introdusă în al doilea câmp nu este aceeași ca cea din primul câmp.
                </div>

                <!-- Common password -->
                <div
                  *ngIf="commonPassword"
                  class="alert alert-danger text-center"
                >
                  Parola este prea des folosită și ușor de ghicit. Încercați o parolă mai complexă.
                </div>

                <!-- Numeric password -->
                <div
                  *ngIf="numericPassword"
                  class="alert alert-danger text-center"
                >
                  Parola conține doar cifre, trebuie să conțină și alte caractere.
                </div>

                <div
                  *ngIf="usernamePassword"
                  class="alert alert-danger text-center"
                >
                  Parola este prea asemănătoare cu username.
                </div>

                <div
                  *ngIf="passwordError"
                  class="alert alert-danger text-center"
                >
                  A apărut o eroare
                </div>

                <div *ngFor="let error of errors" class="alert alert-danger text-center">
                  {{ error }}
                </div>

                <div
                  *ngIf="tokenError"
                  class="alert alert-danger text-center"
                >
                  Acest URL nu mai este valid.<br>
                  Retrimite-ți email-ul:<br>
                  <a routerLink="/forgot-password">Obținere/Recuperare cont fermier A.C.B.C.R.</a>
                </div>

                <!-- Success change password -->
                 <div
                  *ngIf="successChange"
                  class="alert alert-success text-center"
                >
                  Parola a fost schimbată cu success. <br /> Veți fi redirecționat către pagina de autentificare
                </div>


              <div class="form-group mb-0 text-center">
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                >
                  Setează
                </button>
              </div>

            </form>

          </div> <!-- end card-body-->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-white-50">
              Înapoi la
              <a routerLink="/login" class="text-white ml-1">
                <b>Autentificare</b>
              </a>
            </p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
  Asociația Crescătorilor de Bovine pentru Carne din România
  <a href="https://acbcr.ro" class="footer-link">acbcr.ro</a>
</footer>
