import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from './../../common/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {

  forgotPassword: UntypedFormGroup;
  submitted = false;
  error = '';
  success;
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit() {

    this.forgotPassword = this.formBuilder.group({
      cod_exploatatie: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotPassword.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = null;
    this.error = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPassword.invalid) {
      return;
    }

    this.loading = true;

    this.authService.forgotPassword(this.forgotPassword.value.cod_exploatatie)
      .subscribe({
        next: (response) => {
          setTimeout(() => {
            this.loading = false;
            this.success = response;
          }, 500);
        },
        error: (errors) => {
          this.error = errors.error.error;

          if (!this.error) {
            this.error = 'A apărut o eroare'
          }

          this.loading = false;
        }
      });
  }
}
