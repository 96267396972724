<ng-template #generareFacturiModal let-modal="close('Cross click')">
  <div class="modal-header">
    <h3 class="modal-title">
      Generare factură
    </h3>

    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="(modal); notifyCloseModal()"
        autofocus="false"
        [disabled]="loadingDataSpinner"
      >
        Anulează
      </button>
      <button class="btn btn-sm btn-success" (click)="onSubmitted()" [disabled]="loadingDataSpinner">
        <span>
          Generează
        </span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="generareFacturaForm">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <label for="data_facturilor">
              Data facturilor
            </label>
            <app-date-picker
              (dateChanged)="onDataFacturilorChanged($event)"
              [required]="true"
              [submitted]="submitted"
            ></app-date-picker>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="data_facturilor">
              Dată sfârșit interval
            </label>
            <app-date-picker
              (dateChanged)="onDataSfarsitChanged($event)"
              [required]="true"
              [submitted]="submitted"
            ></app-date-picker>
          </div>
          <div class="col-sm-12 col-md-6 mt-3">
            <div class="form-group mb-3">
              <label for="tip_factura">
                Tip factură
              </label>

              <ng-select
                formControlName="tip_factura"
                placeholder="Alege tip factură"
                [ngClass]="{ 'is-invalid': submitted && f.tip_factura.errors }"
              >
                <ng-option [value]="1">RG</ng-option>
                <ng-option [value]="2">CPP</ng-option>
                <ng-option [value]="3">ACBCR</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && f.tip_factura.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.tip_factura.errors }"
              >
                <div *ngIf="f.tip_factura.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 mt-3">
            <div class="form-group mb-3">
              <label for="subventie">
                Subvenționat
              </label>

              <ng-select
                formControlName="subventie"
                placeholder="Alege tip subvenție"
                [clearable]="false"
                [ngClass]="{
                  'is-invalid': submitted && f.subventie.errors,
                  disabled: f.tip_factura.value === 3
                }"
              >
                <ng-option [value]="true">Subvenționat</ng-option>
                <ng-option [value]="false">Nu beneficiază</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && f.subventie.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.subventie.errors }"
              >
                <div *ngIf="f.subventie.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div
              class="spinner-border text-info table-spinner avatar-lg"
              role="status"
              *ngIf="loadingDataSpinner"
            ></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
