<div class="container-fluid mt-3">
  <div class="col-12">
    <div class="card settings-card">
      <form [formGroup]="facturaSettingsForm">
        <div class="row">
          <div class="col-12">
            <p class="settings-type">Setarile facturii</p>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="tip">
                Tip factură
              </label>
              <ng-select
                bindLabel="tip"
                bindValue="tip"
                formControlName="tip"
                placeholder="Alegeți tipul facturii"
                (change)="selectTipFactura($event)"
              >
                <ng-option [value]="1">RG</ng-option>
                <ng-option [value]="2">CPP</ng-option>
                <ng-option [value]="3">ACBCR</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="proc_tva">
                Procent TVA
              </label>
              <input
                type="number"
                min="0"
                max="99.99"
                step="0.1"
                id="proc_tva"
                formControlName="proc_tva"
                placeholder="Introduceți procentajul TVA"
                [ngClass]="{ 'is-invalid': facturaSubmitted && f.proc_tva.errors }"
                class="form-control"
              />
              <div
                *ngIf="facturaSubmitted && f.proc_tva.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.proc_tva.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
                <div *ngIf="f.proc_tva.errors.min">
                  Valoarea nu trebuie să fie mai mică decât 0.
                </div>
                <div *ngIf="f.proc_tva.errors.max">
                  Valoarea nu trebuie să fie mai mare decât 99.99.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="seria">
                Seria
              </label>
              <input
                type="text"
                id="seria"
                formControlName="seria"
                placeholder="Introduceți seria facturii"
                [ngClass]="{ 'is-invalid': facturaSubmitted && f.seria.errors }"
                class="form-control" />
                <div
                  *ngIf="facturaSubmitted && f.seria.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="f.seria.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="numar">
                Număr factură
              </label>
              <input
                type="number"
                min="0"
                step="1"
                id="numar"
                formControlName="numar"
                placeholder="Introduceți numărul facturii"
                [ngClass]="{ 'is-invalid': facturaSubmitted && f.numar.errors }"
                class="form-control"
              />
              <div
                *ngIf="facturaSubmitted && f.numar.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.numar.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
                <div *ngIf="f.numar.errors.min">
                  Valoarea nu trebuie să fie mai mică decât 0.
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <ngb-alert
        type="success"
        class="text-center search-error mt-2"
        *ngIf="facturaEditSuccess"
        [dismissible]="false"
        >
          Datele au fost actualizate cu succes.
        </ngb-alert>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <div class="btn-group my-2">
            <button
              class="btn btn-md btn-success py-1 px-3"
              (click)="onSubmittedFactura()"
            >
              <i class="fe-save"></i>
              Salvează
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card settings-card">
      <form [formGroup]="chitantaSettingsForm">
        <div class="row">
          <div class="col-12">
            <p class="settings-type">Setarile chitanței</p>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="seria">
                Seria
              </label>
              <input
                type="text"
                id="seria"
                formControlName="seria"
                placeholder="Introduceți seria chitanței"
                [ngClass]="{ 'is-invalid': chitantaSubmitted && fch.seria.errors }"
                class="form-control" />
                <div
                  *ngIf="chitantaSubmitted && fch.seria.errors"
                  class="invalid-feedback"
                >
                <div *ngIf="fch.seria.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label for="numar">
                Număr chitanță
              </label>
              <input
                type="number"
                min="0"
                step="1"
                id="numar"
                formControlName="numar"
                placeholder="Introduceți numărul chitanței"
                [ngClass]="{ 'is-invalid': chitantaSubmitted && fch.numar.errors }"
                class="form-control"
              />
              <div
                *ngIf="chitantaSubmitted && fch.numar.errors"
                class="invalid-feedback"
              >
                <div *ngIf="fch.numar.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
                <div *ngIf="fch.numar.errors.min">
                  Valoarea nu trebuie să fie mai mică decât 0.
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <ngb-alert
        type="success"
        class="text-center search-error mt-2"
        *ngIf="chitantaEditSuccess"
        [dismissible]="false"
        >
          Datele au fost actualizate cu succes.
        </ngb-alert>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <div class="btn-group my-2">
            <button
              class="btn btn-md btn-success py-1 px-3"
              (click)="onSubmittedChitanta()"
            >
              <i class="fe-save"></i>
              Salvează
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
