import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  AdvancedSortableDirective,
  SortEvent
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { PerformanceTableService } from 'src/app/modules/acbcr/common/services/performance-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-performance-table',
  templateUrl: './performance-table.component.html',
  styleUrls: ['./performance-table.component.scss']
})
export class PerformanceTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<AdvancedSortableDirective>;
  @ViewChild('performanceTable', { static: false }) performanceTable: ElementRef;

  @Input() performances: any[];
  @Input() performancesLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() tableCallback = new EventEmitter();

  isAdmin = false;
  isOperator = false;
  openSearchModal = new Subject<boolean>();
  filteredPerformances;

  constructor(
    public performanceTableService: PerformanceTableService,
    public helperDataService: HelperDataService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.performanceTableService.sortColumn = column;
    this.performanceTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
    this.filteredPerformances = event;
  }

  getModalResult(event) {
    this.tableCallback.emit(event);
  }

  exportXls() {
    let now = UIHelper.now();
    let fisier = `Testarea_performantelor_${now}.xls`;

    this.performanceTableService
      .exportXLS(this.filteredPerformances)
      .subscribe((response) => {
        const blob = new Blob([response]);
        this.helperDataService.simulateDownload( blob, fisier);
      });
  }

}
