<div
    *ngIf="animalForm.value.sex === '1'"
    id="reproductie"
    class="card"
>
    <div class="card-header">
        <h3>Reproducție</h3>
    </div>
    <div class="card-body">

        <div
            class="row"
            [formGroup]="animalForm"
        >
            <div class="col-sm-12 col-lg-6 d-flex form-group mb-3">
                <h4 class="mr-4">Însămânțare artificială</h4>

                <label
                    for="codtaur"
                    class="d-inline-flex align-self-center m-0"
                >
                    Cod Taur
                </label>

                <div>
                    <input
                        type="text"
                        pattern="\d*"
                        minlength="5"
                        maxlength="5"
                        id="codtaur"
                        placeholder="Cod Taur"
                        class="form-control ml-2"
                        formControlName="codtaur"
                        [ngClass]="{
                            'is-invalid':
                                (submitted && animalForm.controls.codtaur.errors),
                                disabled: disableAllFields
                        }"
                    />

                    <div
                        *ngIf="submitted && animalForm.controls.codtaur.errors"
                        class="invalid-feedback align-self-start"
                    >
                        <div *ngIf="animalForm.controls.codtaur.errors.minlength">
                            Codul taurului trebuie să conțină 5 cifre
                        </div>
                        <div *ngIf="animalForm.controls.codtaur.errors.required">
                            Completarea acestui câmp este obligatorie
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-lg-6">
                <label>Are Avizare Tauri:</label>

                <span
                    *ngIf="animalForm.value.are_avizare_tauri"
                    class="font-weight-bold ml-1"
                >Da</span>

                <span
                    *ngIf="!animalForm.value.are_avizare_tauri"
                    class="font-weight-bold ml-1"
                >Nu</span>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <h4 class="mt-0">Autorizații Montă</h4>
            </div>
        </div>

        <ng-container *ngFor="let autorizatieMontaForm of autorizatiiMonta.controls; let index = index">
            <div class="row mb-3" [formGroup]="autorizatieMontaForm">
                <div class="col-sm-12 col-md-auto">
                    <label for="nr_document">
                        Număr document
                    </label>
                    <input
                        type="text"
                        placeholder="Număr document"
                        class="form-control nr_document"
                        formControlName="nr_document"
                        [ngClass]="{
                            'is-invalid': submitted && autorizatieMontaForm.controls.nr_document.errors,
                            'disabled': disableAllFields
                        }"
                    />

                    <div
                        *ngIf="submitted && autorizatieMontaForm.controls.nr_document.errors?.required"
                        class="error-message invalid-feedback d-block"
                    >
                        Completarea acestui câmp este obligatorie.
                    </div>
                </div>

                <div class="col-sm-12 col-md-auto">
                    <label for="data_document">
                        Dată document
                    </label>
                    <app-date-picker
                        [selectedDate]="autorizatieMontaForm.controls.data_document.value"
                        (dateChanged)="autorizatieMontaForm.controls.data_document.setValue($event)"
                        [disabled]="disableAllFields"
                    >
                    </app-date-picker>

                    <div
                        *ngIf="submitted && autorizatieMontaForm.controls.data_document.errors?.required"
                        class="error-message invalid-feedback d-block"
                    >
                        Completarea acestui câmp este obligatorie.
                    </div>
                </div>

                <div class="col-sm-12 col-md-auto">
                    <label for="emitent">
                        Emitent
                    </label>
                    <input
                        type="text"
                        placeholder="Emitent"
                        class="form-control emitent"
                        formControlName="emitent"
                        [ngClass]="{
                            'is-invalid': submitted && autorizatieMontaForm.controls.emitent.errors,
                            'disabled': disableAllFields,
                        }"
                    />

                    <div
                        *ngIf="submitted && autorizatieMontaForm.controls.emitent.errors?.required"
                        class="error-message invalid-feedback d-block"
                    >
                        Completarea acestui câmp este obligatorie.
                    </div>
                </div>

                <div class="col-sm-12 col-md-auto">
                    <label for="data_inceput">
                        Dată început
                    </label>
                    <app-date-picker
                        [selectedDate]="autorizatieMontaForm.controls.data_inceput.value"
                        (dateChanged)="autorizatieMontaForm.controls.data_inceput.setValue($event)"
                        [disabled]="disableAllFields"
                    >
                    </app-date-picker>

                    <div
                        *ngIf="submitted && autorizatieMontaForm.controls.data_inceput.errors?.required"
                        class="error-message invalid-feedback d-block"
                    >
                        Completarea acestui câmp este obligatorie.
                    </div>
                </div>

                <div class="col-sm-12 col-md-auto">
                    <label for="data_sfarsit">
                        Dată sfârșit
                    </label>
                    <app-date-picker
                        [selectedDate]="autorizatieMontaForm.controls.data_sfarsit.value"
                        (dateChanged)="autorizatieMontaForm.controls.data_sfarsit.setValue($event)"
                        [disabled]="disableAllFields"
                    >
                    </app-date-picker>

                    <div
                        *ngIf="submitted && autorizatieMontaForm.controls.data_sfarsit.errors?.required"
                        class="error-message invalid-feedback d-block"
                    >
                        Completarea acestui câmp este obligatorie.
                    </div>
                </div>

                <div class="col-sm-12 col-md-auto">
                    <label>
                        Fișier
                    </label>

                    <div class="upload-fisier">
                        <app-upload-fisier
                            *ngIf="! autorizatieMontaForm.value.file_id"
                            (ApiResponse)="uploadServerResponse(autorizatieMontaForm, $event)"
                            [ngClass]="{ disabled: disableAllFields }"
                        ></app-upload-fisier>
                    </div>

                    <div
                        *ngIf="autorizatieMontaForm.value.file_name"
                    >
                        <button
                            type="button"
                            class="btn p-0 text-blue font-weight-bold"
                            (click)="downloadAutorizatieFile(autorizatieMontaForm)"
                        >
                            <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                            {{ autorizatieMontaForm.value.file_name }}
                        </button>
                        <button
                            *ngIf="isAdmin || isOperator || isFermier"
                            type="button"
                            class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
                            ngbTooltip="Șterge fișier"
                            placement="right"
                            (click)="removeAutorizatieFile(autorizatieMontaForm)"
                        >
                        </button>
                    </div>

                    <div
                        *ngIf="submitted && autorizatieMontaForm.controls.file_id.errors?.required"
                        class="error-message invalid-feedback d-block"
                    >
                        Completarea acestui câmp este obligatorie.
                    </div>
                </div>

                <div class="col-sm-12 col-md-auto d-flex align-items-end">
                    <i
                        *ngIf="isAdmin || isOperator || (isFermier && !autorizatieMontaForm.value.id)"
                        class="mdi mdi-close-circle text-danger fs-3 cursor-pointer"
                        ngbTooltip="Șterge"
                        placement="bottom"
                        (click)="clickStergeAutorizatieMonta(index)"
                    ></i>
                </div>
            </div>
        </ng-container>

        <div class="row">
            <div
                *ngIf="isAdmin || isOperator || isFermier"
                class="col-sm-12 mb-3"
            >
                <button
                    class="btn btn-outline-success btn-sm"
                    type="button"
                    (click)="adaugaAutorizatieMonta()"
                >
                    <i class="mdi mdi-plus-circle fs-4 align-middle"></i>
                    Adaugă Autorizație Montă
                </button>
            </div>
        </div>
    </div>
</div>
