import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';

import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { BirthsTableService } from 'src/app/modules/acbcr/common/services/births-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-anexa8-tab',
  templateUrl: './anexa8-tab.component.html',
  styleUrls: ['./anexa8-tab.component.scss']
})
export class Anexa8TabComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  isAdmin = false;
  isOperator = false;
  anexe8 = [];
  selectedHoldingId;
  modalData = null;

  constructor(
    private helperDataService: HelperDataService,
    private birthsTableService: BirthsTableService,
    private store: Store<ApplicationState>
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();

    this.store.pipe(
      select(getLoadingState),
      filter(isLoading => !isLoading),
      switchMap(() => this.store.pipe(select(getSelectedHolding))),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
      takeUntil(this.destroy$),
    ).subscribe((response: any) => {
      this.selectedHoldingId = response ? response.id : null;
      if (this.selectedHoldingId !== undefined && this.selectedHoldingId > 0) {
        this.getTableData();
      }
    });

  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  downloadAnexa8(anexa) {
    this.birthsTableService.getAnexa8Pdf(this.selectedHoldingId, anexa.id)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, `Anexa8_${anexa.id}.pdf`);
      });
  }

  openAnexa8(anexa8Id) {
    this.modalData = {
      'anexa_id': anexa8Id,
      'modal_type': 'fatari',
    };
  }

  getModalResult(event) {
    this.getTableData();
  }

  private getTableData() {
    if (this.selectedHoldingId) {
      this.birthsTableService.getAnexa8Data({company_id: this.selectedHoldingId})
      .subscribe(response =>
        this.anexe8 = response
      );
    }
  }

}
