import { Exploatatie } from 'src/app/modules/acbcr/common/models/holdings.models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  AppHoldingActions,
  AppHoldingsActionType,
} from '../actions/holdings.actions';
import { HoldingsNames } from '../../models/holdings.models';
import { ApplicationState } from '../app.state';

export interface AppHoldings {
  holdings_names: HoldingsNames[];
  holding: Exploatatie;
  loading: boolean;
}

export const initialState = {
  holdings_names: [],
  holding: undefined,
  loading: false,
};

export const getApplicationFeature =
  createFeatureSelector<ApplicationState>('appData');

export const getAppHoldingsNames = createSelector(
  getApplicationFeature,
  (state) => state.holdings.holdings_names
);

export const getSelectedHolding = createSelector(
  getApplicationFeature,
  (state) => state.holdings.holding
);

export const getLoadingState = createSelector(
  getApplicationFeature,
  (state) => state.holdings.loading
);

export function holdingsReducers(
  state: AppHoldings = initialState,
  action: AppHoldingActions
) {
  switch (action.type) {
    case AppHoldingsActionType.SET_HOLDINGS_NAME_DATA:
      return {
        ...state,
        holdings_names: action.payload,
      };

    case AppHoldingsActionType.SET_SELECTED_HOLDING_DATA:
      return {
        ...state,
        holding: action.payload,
      };

    case AppHoldingsActionType.ADD_NEW_HOLDING_DATA:
      return {
        ...state,
        holdings_names: [...state.holdings_names, { ...action.payload }],
      };

    case AppHoldingsActionType.DELETE_HOLDING_NAME_DATA:
      const newHoldings = state.holdings_names.filter(
        (holding) => holding.id !== action.payload
      );
      return {
        ...state,
        holdings_names: newHoldings,
      };

    case AppHoldingsActionType.LOAD_HOLDING_START:
      return {
        ...state,
        loading: true,
      };

    case AppHoldingsActionType.LOAD_HOLDING_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
