import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CnpCuiValidatorService {

  constructor() { }

  validateCNP(cnpValue) {

    if (cnpValue.length !== 13) {
      return false;
    }

    let year = 0;
    let hashResult = 0;
    const cnp = [];
    const hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    const fullYears: any = new Date().getFullYear() - 1900;

    for (let i = 0; i < 13; i++) {
      cnp[i] = parseInt(cnpValue.charAt(i), 10);
      if (isNaN(cnp[i])) {
        return false;
      }
      if (i < 12) { hashResult = hashResult + (cnp[i] * hashTable[i]); }
    }
    hashResult = hashResult % 11;
    if (hashResult === 10) {
      hashResult = 1;
    }
    year = (cnp[1] * 10) + cnp[2];
    switch (cnp[0]) {
      case 1: case 2: { year += 1900; } break;
      case 3: case 4: { year += 1800; } break;
      case 5: case 6: { year += 2000; } break;
      case 7: case 8: case 9: { year += 2000; if (year > (parseInt(fullYears, 10) - 14)) { year -= 100; } } break;
      default: {
        return false;
      }
    }
    if (year < 1800 || year > 2099) {
      return false;
    }
    return (cnp[12] === hashResult);
  }

  validateCUI(cuiValue) {
    let cif = cuiValue;

    if (isNaN(parseFloat(cuiValue)) || !isFinite(cuiValue)) {
      cif = cif.toUpperCase();
      if (cif.indexOf('RO') === 0) {
        cif = cif.replace('RO', '');
      } else {
        return false;
      }
      cif = Math.floor(cif.trim());
    } else {

      cif = Math.floor(cif);
    }

    if (cif.toString().length > 10 || cif.toString().length < 6) {
      return false;
    }

    let v: any = 753217532;
    const c1: any = Math.floor(cif % 10);
    cif = Math.floor(cif / 10);

    let t: any = 0;
    while (cif > 0) {
      t += (cif % 10) * (v % 10);

      cif = Math.floor(cif / 10);

      v = Math.floor(v / 10);
    }

    let c2: any = Math.floor((t * 10) % 11);

    if (c2 === 10) {
      c2 = 0;
    }

    return c1 === c2;
  }

}
