import { SortDirection } from '../../directives/advanced-sortable.directive';
import { BehaviorSubject, Subject } from 'rxjs';

interface State {
  page: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: SortDirection;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

// Convert from YYYY-MM-DD to DD.MM.YYYY
export function convertDateToDisplayFormat(date: string) {
  if (date) {
    const dateArray = date.split('-');
    return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
  }
  return null;
}

// Convert from DD.MM.YYYY to YYYY-MM-DD
export function convertDateToBackendFormat(date: string) {
  if (date) {
    const dateArray = date.split('.');
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  }
  return null;
}

export abstract class SortableTable<T> {
  _tables$ = new BehaviorSubject<T[]>([]);
  _total$ = new BehaviorSubject<number>(0);

  _state: State = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortDirection: '',
    startIndex: 1,
    endIndex: 10,
    totalRecords: 0
  };

  // Override this method to get sort event
  sortEvent(): void { }

  /**
   * Sort the table data
   * @param table Table field value
   * @param column Fetch the column
   * @param direction Sort direction Ascending or Descending
   */
  sort(table: T[], column: string, direction: string): T[] {
    if (direction === '') {
      return table;
    } else {
      return [...table].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  /**
   * Returns the value
   */
  get tables$() { return this._tables$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get startIndex() { return this._state.startIndex; }
  get endIndex() { return this._state.endIndex; }
  get totalRecords() { return this._state.totalRecords; }

  /**
   * Set the value
   */
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set startIndex(startIndex: number) { this._set({ startIndex }); }
  set endIndex(endIndex: number) { this._set({ endIndex }); }
  set totalRecords(totalRecords: number) { this._set({ totalRecords }); }
  set sortColumn(sortColumn: string) { this._set({ sortColumn }); this.sortEvent(); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); this.sortEvent(); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
  }

  private compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }
}
