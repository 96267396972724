<div class="card">
  <div class="card-header">
    <div class="float-left card-title">
      <span
        (click)="toggleInfoTable()"
        class="click-title"
        ngbTooltip="Afișează/ascunde informațiile despre exploatație"
        placement="bottom"
      >
        {{exploatatie !== undefined ? exploatatie.name : ''}}
        <i [class]="iconClass"></i>
      </span>
    </div>
    <div class="float-right">
      <a
        class="btn btn-success"
        [routerLink]="previousRoute"
        ngbTooltip="Pagina anterioară"
        placement="left"
      >
        <i class="fe-arrow-left"></i>
        Înapoi
      </a>
    </div>
  </div>
  <div class="card-body">
    <div class="top_buttons">
      <button
        class="btn btn-blue btn-sm"
        (click)="openReproductiiModal()"
      >
        Adaugă reproducție (Anexa 3)
      </button>

      <button
        class="btn btn-blue btn-sm"
        (click)="openFatariModalMethod()"
      >
        Adaugă fătare (Anexa 8)
      </button>

      <button
        class="btn btn-blue btn-sm"
        (click)="openMiscariModal()"
      >
        Adaugă mișcare (Anexa 10)
      </button>

      <button
        class="btn btn-blue btn-sm"
        (click)="openSolicitareModal()"
      >
        Solicita certificat
      </button>

      <button
        class="btn btn-danger btn-sm"
        (click)="openAtentionariFermierModal()"
      >
        Atenționări pentru fermier
      </button>

      <button
        class="btn btn-blue btn-sm"
        (click)="openVerificareAnimale()"
      >
        Verificare animale RNE
      </button>
    </div>

    <table class="table table-bordered table-striped" *ngIf="displayInfoTable">
      <thead>
        <tr>
          <th scope="column" colspan="2" class="table-header">
            <div class="row custom-holding-row">
              <div class="col-xs-12 col-sm-6 col-md-6 custom-holding-col">
                <h3>Informații Exploatație</h3>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 text-right custom-holding-col">
                <div class="btn-group">
                  <button
                    *ngIf="!isFermier && !isAutoritateJudeteana && !isContabil; else view"
                    type="button"
                    class="btn btn-success"
                    (click)="openEditHoldingModal('edit')"
                  >
                    <span class="btn-label"><i class="mdi mdi-square-edit-outline"></i></span>Editează
                  </button>
                  <ng-template #view>
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="openEditHoldingModal('view')"
                    >
                      <span class="btn-label"><i class="mdi mdi-eye-outline"></i></span>Vizualizează
                    </button>
                  </ng-template>

                  <button
                      type="button"
                      class="btn btn-info"
                      (click)="downloadHoldingHistory(exploatatie.id, exploatatie.code)"
                      *ngIf="!isFermier"
                    >
                      Descarca istoric
                  </button>

                </div>
              </div>

            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">CUI/CNP</th>
          <td>{{exploatatie !== undefined ? exploatatie.cif : ''}}</td>
        </tr>
        <tr>
          <th scope="row">Număr APIA</th>
          <td>{{exploatatie !== undefined ? exploatatie.codeapia : ''}}</td>
        </tr>
        <tr>
          <th scope="row">Rase</th>
          <td>{{exploatatie !== undefined ? exploatatie.racecontract : ''}}</td>
        </tr>
        <tr>
          <th scope="row">Angajați</th>
          <td>{{exploatatie !== undefined ? exploatatie.employees : ''}}</td>
        </tr>
        <tr>
          <th scope="row">C.P.P. realizat de:</th>
          <td>ACBCR</td>
        </tr>
        <tr>
          <th scope="row">Cod Exploatație:</th>
          <td>{{exploatatie !== undefined ? exploatatie.code : ''}}</td>
        </tr>
      </tbody>
    </table>

    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let holdingTab of holdingTabs">
        <a
          class="nav-link"
          routerLinkActive ="active"
          [routerLink]="['./', holdingTab.path]"
        >
          {{holdingTab.name}}
        </a>
      </li>
    </ul>

    <router-outlet></router-outlet>

  </div>
</div>

<app-add-update-holding
  [open]="openAddEditModal"
  (notifyClose)="closeAddUpdateModal($event)"
  (callbackResult)="getUpdatedResponse($event)"
  [modalType]="modalType"
  [holdingData]="holdingData"
>
</app-add-update-holding>

<app-rep-fat-modal
  [data]="repFatModalData"
>
</app-rep-fat-modal>

<app-add-edit-solicitari-certificate-zootehnice
  [addEditModalData]="modalDataSolicitare"
>
</app-add-edit-solicitari-certificate-zootehnice>

<app-add-buletin-control
  [open]="openAtentionariFermier"
  (notifyClose)="closeAtentionariFermierModal()"
  [modalType]="'atentionari'"
>
</app-add-buletin-control>

<ng-template #verificareAnimaleRNE let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Verificare animale RNE
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">

      <input
        #fileUpload
        type="file"
        accept=".pdf"
        class="file-input"
        style="display: none"
        (change)="selectatFisierVerificareAnimale($event)"
      />

      <div class="row mb-2">
        <button
          class="btn btn-info"
          (click)="fileUpload.click()"
          [disabled]="verificareAnimale"
        >
          Alege fișierul pdf
        </button>
      </div>

      <div *ngIf="fisierVerificareAnimale?.name" class="row mb-2">
        {{ fisierVerificareAnimale?.name }}
      </div>

      <div class="row mt-3">
        <button
          class="btn btn-blue"
          (click)="trimiteFisierVerificareAnimale()"
          [disabled]="verificareAnimale"
        >
          Verifică
        </button>

        <span *ngIf="verificareAnimale" class="btn text-info">Verificare animale RNE ...</span>
        <span *ngIf="verificareAnimaleError" class="btn text-danger">{{ verificareAnimaleError }}</span>
      </div>

      <div *ngIf="animale_lipsa_rne">
        <div class="animale_lipsa_titlu">Animale active din RG care lipsesc în RNE</div>
        <span *ngFor="let nr_matricol of animale_lipsa_rne" class="animale_lipsa_matricol">
          {{ nr_matricol }}
        </span>
      </div>

      <div *ngIf="animale_lipsa_rg">
        <div class="animale_lipsa_titlu">Animale din RNE care lipsesc în RG</div>
        <span *ngFor="let nr_matricol of animale_lipsa_rg" class="animale_lipsa_matricol">
          {{ nr_matricol }}
        </span>
      </div>

      <div *ngIf="animale_rne_inactive_rg">
        <div class="animale_lipsa_titlu">Animale din RNE care sunt inactive în RG</div>
        <span *ngFor="let nr_matricol of animale_rne_inactive_rg" class="animale_lipsa_matricol">
          {{ nr_matricol }}
        </span>
      </div>

    </div>
  </div>
</ng-template>
