import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { Store, select } from '@ngrx/store';

import { MenuTab, UserType, User } from 'src/app/modules/acbcr/common/models/common.models';
import { Animals } from 'src/app/modules/acbcr/common/models/animal.models';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppHoldingsNames } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HoldingsNames } from 'src/app/modules/acbcr/common/models/holdings.models';
import { Subject } from 'rxjs';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-animal',
  templateUrl: './animal.component.html',
  styleUrls: ['./animal.component.scss'],
  providers: [AnimalsTableService, DecimalPipe]
})
export class AnimalComponent implements OnInit, OnDestroy {

  public readonly animalTabs: MenuTab[] = [
    { path: 'pedigree-rg', name: 'Pedigree / RG' },
    { path: 'istoric-animal', name: 'Istoric Animal' },
    { path: 'performante', name: 'Performanțe' },
    { path: 'detalii', name: 'Detalii' },
  ];

  destroy$: Subject<boolean> = new Subject<boolean>();

  openAddEditModal = false;
  modalType: string;
  animalData: Animals;

  loadingDataSpinner = false;
  displayInfoTable = true;
  iconClass = 'fe-chevron-up';
  AnimalId: any;

  imageCount;
  defaultAnimalImage;
  otherAnimalImages = [];

  insamantareTypes = [
    { id: 0, name: 'Nespecificat' },
    { id: 1, name: 'Însămânțare artificială' },
    { id: 2, name: 'Montă naturală autorizată' },
    { id: 3, name: 'Embriotransferuri' }
  ];
  insamantareLabel: string;

  colorsType = [
    { id: 0, name: 'Alb' },
    { id: 1, name: 'Roșu' },
    { id: 2, name: 'Negru' }
  ];
  colorLabel: string;

  femeleCategory = [
    { id: 0, name: 'Nespecificat' },
    { id: 1, name: 'Vițea' },
    { id: 2, name: 'Primipară' },
    { id: 3, name: 'Multipară' },
    { id: 4, name: 'Junincă' },
  ];
  femeleCategoryLabel: string;

  holdingsNames;
  ownerLabel: string;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  currentRoute = '';


  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private helperDataService: HelperDataService,
    private animalsTableService: AnimalsTableService,
    private route: ActivatedRoute,
    private store: Store<ApplicationState>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.route.params
      .pipe(distinctUntilChanged((prev, curr) => prev['id'] === curr['id']))
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.AnimalId = params['id'];
        this._processServerData();
      });
  }

  get previousRoute() {return localStorage['previous-route']}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  toggleInfoTable() {
    this.displayInfoTable = !this.displayInfoTable;
    this.iconClass =
      this.displayInfoTable ? 'fe-chevron-up' : 'fe-chevron-down';
  }

  closeAddUpdateModal(event) {
    this.openAddEditModal = event;
    this.otherAnimalImages = [];
  }

  openEditAnimalModal(type: string) {

    this.animalsTableService
      .getAnimalAPI(Number(this.route.snapshot.paramMap.get('id')))
      .subscribe(response => {
        this.animalData = response;
        this.openAddEditModal = true;
        this.modalType = type;
      });
  }

  getUpdatedResponse(event) {
    if (event) {
      this._processServerData();
    }
  }

  generateFisaAnimal(animalId) {
    this.animalsTableService.generateFisaAnimal(animalId)
    .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, `Fisa_animal_${animalId}.pdf`);
      });
  }

  descarcaIstoric(animalId) {
    this.loadingDataSpinner = true;
    this.animalsTableService.downloadAnimalHistoryApi(animalId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(blob, this.animalData.numarmatricol + `_istoric.xls`);
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  _processServerData() {
    this.animalsTableService
      .getAnimalAPI(this.AnimalId)
      .subscribe(response => {
        this.animalData = response;

        this.animalsTableService.getPozeAnimal(this.animalData.id).subscribe(res => {
          this.imageCount = res.count;
          let defaultImage;
          const otherImages = [];
          res.result.map(img => {
            if (img.filetype === 'default') {
              defaultImage = img.fileid;
            } else {
              otherImages.push(img.fileid);
            }
          });

          this.animalsTableService.getPozaUrl(defaultImage).subscribe(image => {
            this.defaultAnimalImage = image.url;
          });

          otherImages.map(other => {
            this.animalsTableService.getPozaUrl(other).subscribe(otherImg => {
              this.otherAnimalImages.push(otherImg.url);
            });
          });
        });

        this.insamantareTypes.map(type => {
          if ((type.id === this.animalData.sistemreproductie)) {
            this.insamantareLabel = type.name;
          }
        });

        this.colorsType.map(color => {
          if (color.id === this.animalData.color) {
            this.colorLabel = color.name;
          }
        });

        this.femeleCategory.map(category => {
          if (category.id === this.animalData.categoriefemela) {
            this.femeleCategoryLabel = category.name;
          }
        });

        this.store.pipe(select(getAppHoldingsNames))
          .pipe(takeUntil(this.destroy$))
          .subscribe((holdings: HoldingsNames[]) => {
            this.holdingsNames = holdings;
            this.holdingsNames.map(holding => {
              if (holding.id === this.animalData.exploatatieid) {
                this.ownerLabel = holding.name;
              }
            });
          });


      }, error => {
        if (error.error.error === 'Nu are permisiuni') {
          this.router.navigateByUrl('/dashboard/utilizatori');
        }
      });
  }

}
