<ng-template #facturaManualaModal let-modal="close('Cross click')">
  <div class="modal-header row" style="background-color: #e7e7e7;">
    <div class="col-sm-12 col-md-12 col-lg-6 custom-col">
      <h3 class="modal-title">
        <span *ngIf="modalType === 'add', else editareFactura">Factură manuală ACBCR</span>
        <ng-template #editareFactura>
          <span>Editare factură</span>
        </ng-template>
        <br />
        <span class="seria-number" *ngIf="modalType === 'edit'; else adaugareFactura">Seria: {{facturaData?.seria}} numarul: {{facturaData?.numar}}</span>
        <ng-template #adaugareFactura>
          <span class="seria-number">Seria: {{preserieFactura?.seria}} numarul: {{preserieFactura?.numar}}</span>
        </ng-template>
      </h3>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-dark"
          (click)="(modal); notifyCloseModal()"
          autofocus="false"
        >
        Închide
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <form [formGroup]="facturaManuala" novalidate>
        <div formArrayName="sections">
          <div class="section-container" *ngFor="let section of getSections(facturaManuala); let i = index">
            <div class="ui raised segments" [formGroupName]="i">
              <div class="row">
                <div class="col-6">
                  <div class="form-group mb-3">
                    <label for="companyid">
                      Exploatație
                    </label>
                    <ng-select
                      [items]="holdingsNames"
                      bindLabel="name"
                      bindValue="id"
                      placeholder="Caută după exploatație"
                      formControlName="companyid"
                    >
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }} - {{ item.code }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        {{ item.name }} - {{ item.code }}
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="data_sfarsit">
                      Data facturii
                    </label>
                    <app-date-picker
                      (dateChanged)="oDateChanged($event, section)"
                      [selectedDate]="section.controls.data.value"
                    ></app-date-picker>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group mb-3">
                    <label for="tva">
                      TVA
                    </label>
                    <input
                      min="0.00"
                      max="100"
                      step="0.05"
                      type="number"
                      formControlName="tva"
                      class="form-control read-only-form"
                      name="tva"
                      placeholder="% TVA"
                  />
                  </div>
                </div>

              </div>

              <div class="activity-container" formArrayName="activities">
                <div [formGroupName]="j" *ngFor="let activity of getActivities(section); let j = index; let jlast = last">
                  <hr>

                  <input
                    type="text"
                    placeholder="Introduceti denumirea activitatii"
                    formControlName="descriere"
                    class="form-control read-only-form mt-4"
                  >
                  <hr>

                  <div *ngIf="facturaManuala.errors" class="alert alert-danger">
                    {{ facturaManuala.errors }}
                  </div>

                  <div
                    class="table-responsive"
                  >
                    <table
                      class="table table-bordered dt-responsive nowrap table-hover"
                      formArrayName="services"
                    >
                      <thead style="background-color: #e7e7e7;">
                        <tr>
                          <th>
                            DENUMIREA SERVICIULUI
                          </th>
                          <th>
                            RASA
                          </th>
                          <th>
                            UM
                          </th>
                          <th>
                            CANTITATE
                          </th>
                          <th>
                            PRETUL UNITAR
                          </th>
                          <th>
                           VAL FARA TVA
                          </th>
                          <th>
                          VAL TVA
                          </th>
                          <th>
                            VALOARE CU TVA
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          [formGroupName]="k"
                          *ngFor="let service of getServices(activity); let k = index; let last = last"
                        >
                          <td>
                            <input
                              type="text"
                              class="form-control read-only-form"
                              name="descriere"
                              formControlName="descriere"
                              placeholder="Adaugă serviciul"
                              (click)="makeActive(k)"
                              (blur)="deactivate(k)"
                              [readonly]="!activeFields[k]"
                            />
                          </td>
                          <td>
                            <ng-select
                              [items]="raseList"
                              bindLabel="shortname"
                              bindValue="idacbcr_race"
                              placeholder="Alege RASA"
                              formControlName="rasa"
                            >
                            </ng-select>
                          </td>
                          <td>
                            <ng-select
                              [items]="umList"
                              bindLabel="um_code"
                              bindValue="id"
                              placeholder="Alege UM"
                              formControlName="um"
                            >
                            </ng-select>
                          </td>
                          <td>
                            <input
                              type="number"
                              min="0"
                              step="0.0005"
                              class="form-control read-only-form"
                              name="cantitate"
                              formControlName="cantitate"
                              placeholder="Adaugă cantitatea"
                              (click)="makeActive(k)"
                              (blur)="deactivate(k)"
                              (input)="changedQ(activity, k, $event, service.value.pu, section.value.tva)"
                              [readonly]="!activeFields[k]"
                            />
                          </td>
                          <td>
                            <input
                              min="0"
                              step="0.05"
                              type="number"
                              class="form-control read-only-form"
                              name="pu"
                              (input)="changedPU(activity, k, $event, service.value.cantitate, section.value.tva)"
                              formControlName="pu"
                              placeholder="Adaugă PU"
                              (click)="makeActive(k)"
                              (blur)="deactivate(k)"
                              [readonly]="!activeFields[k]"
                            />
                          </td>
                          <td>
                            {{ service.controls.val_fara_tva.value ? service.controls.val_fara_tva.value : 0 }}
                          </td>
                          <td>
                            {{ service.controls.val_tva.value ? service.controls.val_tva.value : 0 }}
                          </td>
                          <td>
                            {{ service.controls.val_cu_tva.value ? service.controls.val_cu_tva.value : 0 }}
                          </td>
                          <td style="border: none;">
                            <button
                            *ngIf="(!last && getServices(activity).length > 1) || (last && getServices(activity).length > 1) "
                            class="btn btn-sm btn-icon btn-danger"
                            ngbTooltip="Șterge serviciu"
                            placement="bottom"
                            (click)="removeService(activity, i, j, k)"
                            >
                            <i class="fe-minus"></i>
                          </button>
                          <button
                            *ngIf="last"
                            class="btn btn-sm btn-icon btn-success"
                            ngbTooltip="Adaugă serviciu"
                            placement="bottom"
                            (click)="addService(i, j)"
                            [disabled]="!service.controls.descriere.value || !service.controls.pu.value || !service.controls.cantitate.value"
                          >
                            <i class="fe-plus"></i>
                          </button>
                          </td>
                        </tr>
                        <tr style="background-color: #e7e7e7;">
                          <th colspan="4">
                            TOTAL ACTIVITATE
                          </th>
                          <th>
                            {{ activity.value.total_pu ? activity.value.total_pu : 0 }}
                          </th>
                          <th>
                            {{ activity.value.total_val_fara_tva ? activity.value.total_val_fara_tva : 0 }}
                          </th>
                          <th>
                            {{ activity.value.total_val_tva ? activity.value.total_val_tva : 0 }}
                          </th>
                          <th>
                            {{ activity.value.total_val_cu_tva ? activity.value.total_val_cu_tva : 0 }}
                          </th>
                        </tr>
                        <tr
                          *ngIf="modalType === 'edit' && checkSubventie(facturaData?.tip, facturaData?.expl_sub)"
                          style="background-color: #d6d6d6;"
                        >
                          <td colspan="5">
                            {{ activity.value.descriere_subventie }} {{ activity.value.proc_subventie }}%
                          </td>
                          <td>
                            -{{ activity.value.subventie ? activity.value.subventie : '0.00' }}
                          </td>
                          <td>
                            0.00
                          </td>
                          <td>
                            -{{ activity.value.subventie ? activity.value.subventie : '0.00' }}
                          </td>
                        </tr>
                        <tr
                          *ngIf="modalType === 'edit'"
                          style="background-color: #d6d6d6;"
                        >
                          <td colspan="5">
                            Contributia clientului {{ 100 - activity.value.proc_subventie }}% {{ activity.value.contributia_clientului }}
                          </td>
                          <td>
                            {{ (activity.value.total_val_fara_tva - activity.value.subventie).toFixed(2) }}
                          </td>
                          <td>
                            {{ activity.value.total_val_tva ? activity.value.total_val_tva : '0.00' }}
                          </td>
                          <td>
                            {{ (activity.value.total_val_cu_tva - activity.value.subventie).toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div [ngClass]="{'col-6 text-nowrap': jlast, 'col-12 text-nowrap text-right': !jlast}">
                      <button
                        *ngIf="getActivities(section).length > 1"
                        class="btn btn-sm btn-icon btn-danger"
                        ngbTooltip="Șterge activitate"
                        placement="bottom"
                        (click)="removeActivity(i,j)"
                      >
                        Sterge activitate
                        <i class="mdi mdi-delete-forever"></i>
                      </button>
                    </div>

                    <div
                      class="col-6 text-nowrap text-right"
                    >
                      <button
                        *ngIf="jlast"
                        class="btn btn-sm btn-icon btn-success"
                        ngbTooltip="Adaugă activitate"
                        placement="bottom"
                        (click)="addActivity(i)"
                        [disabled]="!activity.controls.descriere.value"
                      >
                        Adaugă activitate
                        <i class="fe-save"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mt-3">
              <table
              style="width: 50%; float: right;"
              id="detalii-factura"
              class="table table-bordered dt-responsive nowrap table-hover"
              >
                <thead style="background-color: #e7e7e7;">
                  <tr>
                    <th>
                      VAL FARA TVA
                    </th>
                    <th>
                      VAL SUBVENTIE
                    </th>
                    <th>
                      VAL TVA
                    </th>
                    <th>
                      VALOARE CU TVA
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ section.value.factura_val_fara_tva ? section.value.factura_val_fara_tva : '0.00' }}
                    </td>
                    <td>
                      {{ section.value.subventie ? section.value.subventie : '0.00' }}
                    </td>
                    <td>
                      {{ section.value.factura_val_tva ? section.value.factura_val_tva : '0.00' }}
                    </td>
                    <td>
                      {{ section.value.factura_val_cu_tva ? section.value.factura_val_cu_tva.toFixed(2) : '0.00' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive">
              <table
              style="width: 20%; float: right;"
              id="detalii-factura"
              class="table table-bordered dt-responsive nowrap table-hover"
              >
                <thead>
                  <tr>
                    <th style="background-color: #e7e7e7;">
                      Total de plata
                    </th>
                    <th>
                      {{ (section.value.factura_val_cu_tva - section.value.subventie).toFixed(2) }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
            <button
              class="btn btn-sm btn-danger"
              (click)="(modal); notifyCloseModal()"
              autofocus="false"
              *ngIf="modalType === 'add'"
              >
              Abandonează
            </button>
          </div>

          <div
            class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right"
          >
          <div class="btn-group">
            <button
              class="btn btn-sm btn-success"
              (click)="onSubmit(facturaManuala)"
            >
              Salvează
            </button>
            <button
              class="btn btn-sm btn-blue"
              (click)="changeStatusFacturi(facturaData)"
              *ngIf="modalType === 'edit'"
            >
              Validează
            </button>
          </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
