import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-performante-valorificare',
  templateUrl: './performante-valorificare.component.html',
  styleUrls: ['./performante-valorificare.component.scss']
})
export class PerformanteValorificareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
