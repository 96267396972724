<form [formGroup]="settlementSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="county_id">
          Județul
        </label>

         <ng-select
            [items]="cities"
            bindLabel="name"
            bindValue="id"
            placeholder="Selectează județul"
            formControlName="county_id"
            (change)="getSelectedCity($event)"
          >
          </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="settlement_id">
          Localitatea
        </label>

        <ng-select
          [items]="smallCities"
          bindLabel="name"
          bindValue="name"
          placeholder="Selectează localitatea"
          formControlName="settlement_id"
          notFoundText="Selectați un județ"
          #smallCitiesDropDown
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
