import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Exploatatie } from 'src/app/modules/acbcr/common/models/holdings.models';
import { HoldingsTableService } from './../../../../../common/services/holdings-table.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { MenuTab } from 'src/app/modules/acbcr/common/models/common.models';
import {
  LoadHoldingStart,
  LoadHoldingSuccess,
  SetSelectedHoldingData,
} from 'src/app/modules/acbcr/common/state/actions/holdings.actions';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-holding',
  templateUrl: './holding.component.html',
  styleUrls: ['./holding.component.scss'],
  providers: [HoldingsTableService, DecimalPipe],
})
export class HoldingComponent implements OnInit, OnDestroy {
  @ViewChild('verificareAnimaleRNE') verificareAnimaleRNE: ElementRef;

  public exploatatie: Exploatatie;
  public readonly holdingTabs: MenuTab[] = [
    { path: 'animale-exploatatie', name: 'Animale Exploatație' },
    { path: 'reproductie', name: 'Reproducție' },
    { path: 'fatari', name: 'Fătări' },
    { path: 'miscari', name: 'Mișcări' },
    { path: 'cantariri', name: 'Cântăriri' },
    { path: 'documente', name: 'Documente' },
  ];

  displayInfoTable = false;
  iconClass = 'fe-chevron-up';

  holdingCode;
  openAddEditModal = false;
  modalType: string;
  holdingData: any;
  repFatModalData;
  isAdmin = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  isFermier = false;
  modalDataSolicitare;
  openAtentionariFermier = false;
  fisierVerificareAnimale;
  verificareAnimale = false;
  verificareAnimaleError;
  animale_lipsa_rne;
  animale_lipsa_rg;
  animale_rne_inactive_rg;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private holdingTableService: HoldingsTableService,
    private route: ActivatedRoute,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
    private router: Router,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
    this.holdingCode = this.route.snapshot.paramMap.get('code');

    this.route.params
      .pipe(distinctUntilChanged((prev, curr) => prev['code'] === curr['code']))
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.holdingCode = params['code'];
        this.store.dispatch(new LoadHoldingStart());
        this.loadHoldingData();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private loadHoldingData() {
    this.holdingTableService
      .getHoldingAPI({ comp_code: this.holdingCode })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.exploatatie = response.result[0];
        this.store.dispatch(new SetSelectedHoldingData(this.exploatatie));
        this.store.dispatch(new LoadHoldingSuccess());
        if (response.result.length === 0) {
          this.router.navigateByUrl('/dashboard/exploatatii');
        }
      });
  }

  get previousRoute() {
    return localStorage['previous-route'];
  }

  toggleInfoTable() {
    this.displayInfoTable = !this.displayInfoTable;
    this.iconClass = this.displayInfoTable
      ? 'fe-chevron-up'
      : 'fe-chevron-down';
  }

  closeAddUpdateModal(event) {
    this.openAddEditModal = event;
  }

  openEditHoldingModal(type) {
    this.holdingData = this.exploatatie;
    this.openAddEditModal = true;
    this.modalType = type;
  }

  getUpdatedResponse(event) {
    if (event) {
      this.holdingTableService
        .getHoldingAPI({ comp_code: this.holdingCode })
        .subscribe((response) => {
          this.exploatatie = response.result[0];
          this.store.dispatch(new SetSelectedHoldingData(this.exploatatie));
        });
    }
  }

  downloadHoldingHistory(holdingId, holdingCode) {
    this.holdingTableService
      .downloadHoldingHistoryApi(holdingId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(
          blob,
          holdingCode + `_istoric.xls`
        );
      });
  }

  openReproductiiModal() {
    this.repFatModalData = {
      'anexa_id': null,
      'modal_type': 'reproductii',
    };
  }

  openFatariModalMethod() {
    this.repFatModalData = {
      'anexa_id': null,
      'modal_type': 'fatari',
    };
  }

  openMiscariModal() {
    this.repFatModalData = {
      'anexa_id': null,
      'modal_type': 'miscari',
    };
  }

  openSolicitareModal() {
    this.modalDataSolicitare = {id: null};
  }

  openAtentionariFermierModal() {
    this.openAtentionariFermier = true;
  }

  closeAtentionariFermierModal() {
    this.openAtentionariFermier = false;
  }

  openVerificareAnimale() {
    this.verificareAnimale = false;
    this.verificareAnimaleError = null;
    this.fisierVerificareAnimale = null;

    this.modalService.open(this.verificareAnimaleRNE, {
      scrollable: false,
    });
  }

  selectatFisierVerificareAnimale(event) {
    this.verificareAnimaleError = null;
    const file: File = event.target.files[0];

    if (file) {
      this.fisierVerificareAnimale = file;
    }
  }

  trimiteFisierVerificareAnimale() {
    if (!this.fisierVerificareAnimale) {
      this.verificareAnimaleError = 'Lipsește fișierul pentru verificare';

      return;
    }

    this.verificareAnimale = true;
    this.verificareAnimaleError = null;
    const formData = new FormData();
    formData.append('exploatatie_id', String(this.exploatatie.id));
    formData.append('fisier', this.fisierVerificareAnimale);

    this.holdingTableService.verificareAnimaleAPI(formData)
      .subscribe({
        next: (response) => {
          this.verificareAnimale = false;
          this.animale_lipsa_rne = response.animale_lipsa_rne;
          this.animale_lipsa_rg = response.animale_lipsa_rg;
          this.animale_rne_inactive_rg = response.animale_rne_inactive_rg;
        },
        error: (errors) => {
          this.verificareAnimale = false;

          if (errors.error.error) {
            this.verificareAnimaleError = errors.error.error;
          } else {
            this.verificareAnimaleError = `Eroare ${errors.status}`;
          }
        }
      });
  }
}
