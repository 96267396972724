<div class="modal">
  <div class="modal-content">

    <div class="modal-header">
      <h3 class="text-left"> Motiv modificare stare </h3>
      <span
        class="close text-right"
        (click)="notifyCloseModal(false)"
      >
        &times;</span>
    </div>

    <div class="modal-body">
      <form [formGroup]="reasonStatusForm">
        <div class="row">

          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="date">
                Dată modificare*
              </label>
              <app-date-picker
                [required]="true"
                [submitted]="submitted"
                [selectedDate]="reasonStatusForm.value.date"
                (dateChanged) ="onDateChanged($event)"
              ></app-date-picker>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="reason">
                Motiv
              </label>

              <div *ngFor="let motiv of motive">
                <input
                  type="checkbox"
                  [checked]="reasonStatusForm.value.reason == motiv"
                  (click)="selectMotiv(motiv)"
                />
                <span>{{motiv}}</span>
              </div>
            </div>

            <div class="form-group mb-3">
              <input
                type="text"
                class="form-control"
                formControlName="reason"
              />

              <div
                *ngIf="submitted && f.reason.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.reason.errors }"
              >
                <div *ngIf="f.reason.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>

    <div class="row p-2">
      <div class="col-sm-12 text-right">
        <button
          class="btn btn-dark mr-2"
          type="button"
          (click)="notifyCloseModal(false)"
        >
          Anulează
        </button>
        <button
          class="btn btn-success"
          type="button"
          (click)="onSubmitted()"
        >
          Actualizează status
        </button>
      </div>
    </div>

  </div>
</div>
