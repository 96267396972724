import {
  Component, ElementRef, ViewChild, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import {
  UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { AdeverintaAjutorService } from 'src/app/modules/acbcr/common/services/adeverinta-ajutor.service'

@Component({
  selector: 'app-add-edit-adeverinta-ajutor',
  templateUrl: './add-edit-adeverinta-ajutor.component.html',
  styleUrls: ['./add-edit-adeverinta-ajutor.component.scss']
})
export class AddEditAdeverintaAjutorComponent implements OnInit {
  @ViewChild('addEditTemplate') addEditTemplate: ElementRef;
  @Input() addEditModalData: any;
  @Output() notifyClose = new EventEmitter();
  dataList = ['31.03.2023', '08.02.2024'];
  loadingDataSpinner = false;
  loadingAnimale = false;
  isSuperAdmin = false;
  isAdmin = false;
  isFermier = false;
  addEditModalType: string;
  adeverintaForm: UntypedFormGroup;
  modalReference: NgbModalRef;
  submitted = false;
  animaleSelectItems = [];
  exploatatiiSelectItems = [];
  animalDeAdaugat;
  errorFinalizata = false;
  readonly = false;

  constructor(
    public adeverintaAjutorService: AdeverintaAjutorService,
    private store: Store<ApplicationState>,
    public modalService: NgbModal,
    public errorService: NotificationErrorService,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isFermier = UIHelper.isFermier();
    this.buildForm();
  }

  ngOnChanges(changes) {
    if (changes.addEditModalData && ! changes.addEditModalData.firstChange) {
      this.modalReference = this.modalService.open(this.addEditTemplate, {
        windowClass: 'modal-half-full',
        modalDialogClass: 'h-100',
        scrollable: true,
      });

      this.submitted = false;
      this.errorFinalizata = false;
      this.readonly = false;
      this.animaleSelectItems = [];
      this.exploatatiiSelectItems = [];
      let adeverinta_id = null;

      if (this.addEditModalData && this.addEditModalData.id) {
        adeverinta_id = this.addEditModalData.id;
      }

      if (adeverinta_id) {
        this.loadingDataSpinner = true;
        this.addEditModalType = 'edit';
        this.adeverintaAjutorService.getAPI({id: adeverinta_id})
          .subscribe({
            next: (response) => {
              this.loadingDataSpinner = false;

              if (!response) {
                return;
              }

              this.readonly = response.readonly;
              this.exploatatiiSelectItems = response.exploatatii || [];
              this.buildForm(response);

              if (this.adeverintaForm.value.status === 1) {
                this.findAnimals(true);
              }
            },
            error: (errors) => {
              this.loadingDataSpinner = false;
              this.errorService.processErrorMsg(errors.error.error);
            }
          });
      } else {
        this.addEditModalType = 'add';
        this.buildForm();

        if (this.isFermier) {
          this.store.pipe(select(getSelectedHolding))
          .subscribe((response: any) => {
            if (response && response.id) {
              this.adeverintaForm.controls.exploatatie_id.setValue(response.id);
              this.exploatatiiSelectItems = [{
                id: response.id,
                name: response.name,
                code: response.code,
                viewLabel: `${response.code} - ${response.name}`,
              }];
            }
          });
        }
      }
    }
  }

  buildForm(mData?) {
    let data_adev = mData?.data_dmy || UIHelper.getCurrentDate();
    let status = mData?.status || 1;
    this.adeverintaForm = new UntypedFormGroup({
      id: new UntypedFormControl(mData?.id),
      data: new UntypedFormControl(data_adev, Validators.required),
      numar: new UntypedFormControl(mData?.numar),
      rasa_id: new UntypedFormControl(mData?.rasa_id, Validators.required),
      centru_apia: new UntypedFormControl(mData?.centru_apia),
      exploatatie_id: new UntypedFormControl(mData?.exploatatie_id, Validators.required),
      status: new UntypedFormControl(status, Validators.required),
      animale: new UntypedFormArray([]),
    });

    if (!mData?.animale) {
      return;
    }

    for (let animal of mData.animale) {
      this.adaugaAnimalForm(animal);
    }
  }

  get animaleArrayForm(): UntypedFormArray{
    return this.adeverintaForm.get('animale') as UntypedFormArray;
  }

  onDateChanged(event) {
    this.adeverintaForm.controls.data.setValue(event);
    this.verificareStatus();
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.exploatatiiSelectItems = [];

      return;
    }

    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatiiSelectItems = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  findAnimals(load?) {
    if (!load) {
      this.animaleArrayForm.clear();
    }

    this.animaleSelectItems = [];
    const exploatatie_id = this.adeverintaForm.value.exploatatie_id;
    const rasa_id = this.adeverintaForm.value.rasa_id;
    this.verificareStatus();

    if (!exploatatie_id || !rasa_id) {
      return;
    }

    this.loadingAnimale = true;
    const req_params = {
      exploatatie_id: exploatatie_id,
      rasa_id: rasa_id,
    };

    this.adeverintaAjutorService.animaleAPI(req_params)
    .subscribe({
      next: (response) => {
        this.loadingAnimale = false;
        this.animaleSelectItems = response;

        if (load) {
          this.disableAnimaleSelectItems();
        }
      },
      error: (errors) => {
        this.loadingAnimale = false;
        this.errorService.processErrorMsg(errors.error.error);
      }
    });
  }

  adaugaAnimal(element, event) {
    const animal_id = event?.id;

    if (animal_id) {
      const animal = this.animaleSelectItems.find(
        (animal) => animal.id === animal_id
      );

      this.adaugaAnimalForm(animal);
      this.animaleSelectItems = this.animaleSelectItems.map(
        (animal) => {
          if (animal.id === animal_id) {
            animal.disabled = true;
          }

          return animal;
        }
      );
    }

    this.verificareStatus();

    if (element && element.hasValue) {
      element.clearModel();
    }
  }

  adaugaAnimalForm(animal) {
    let animalForm = new UntypedFormGroup({
      id: new UntypedFormControl(animal.id),
      numarmatricol: new UntypedFormControl(animal.numarmatricol),
      sex_text: new UntypedFormControl(animal.sex_text),
      rasa: new UntypedFormControl(animal.rasa),
      existent: new UntypedFormGroup({}),
    })

    if (animal.existent) {
      let existentForm = animalForm.controls.existent as UntypedFormGroup;

      for (let key of Object.keys(animal.existent)) {
        existentForm.addControl(
          key, new UntypedFormControl(animal.existent[key])
        )
      }
    }

    this.animaleArrayForm.push(animalForm);
  }

  deleteAnimal(animalForm, index) {
    const animal_id = animalForm.value.id;
    this.animaleArrayForm.removeAt(index);
    this.animaleSelectItems = this.animaleSelectItems.map(
      (animal) => {
        if (animal.id === animal_id) {
          animal.disabled = false;
        }

        return animal;
      }
    );
    this.verificareStatus();
  }

  disableAnimaleSelectItems() {
    let selectedList = this.animaleArrayForm.value.map(
      (animal) => {
        return animal.id;
      }
    )

    this.animaleSelectItems = this.animaleSelectItems.map(
      (animal) => {
        if (selectedList.includes(animal.id)) {
          animal.disabled = true;
        }

        return animal;
      }
    );
  }

  verificareStatus() {
    if (this.adeverintaForm.value.status === 2) {
      if (!this.adeverintaForm.value.data
        || !this.adeverintaForm.value.numar
        || !this.adeverintaForm.value.rasa_id
        || !this.adeverintaForm.value.centru_apia
        || !this.adeverintaForm.value.exploatatie_id
        || this.animaleArrayForm.length === 0
      ) {
        this.errorFinalizata = true;
        return;
      }
    }

    this.errorFinalizata = false;
  }

  verificaProcent() {
    const PERCENT_LIMIT = 50;
    let countExistent = [];

    for (var i = 0; i < this.dataList.length; i++) {
      countExistent[i] = 0;
    }

    for (let animalObj of this.animaleArrayForm.value) {
      if (!animalObj.existent) {
        continue;
      }

      for (var i = 0; i < this.dataList.length; i++) {
        let data = this.dataList[i]
        let val = animalObj.existent[data];

        if (val) {
          countExistent[i]++;
        }
      }
    }

    let percent = countExistent[1] / countExistent[0] * 100;

    if (percent < PERCENT_LIMIT) {
      let err = `Procentul este mai mic de ${PERCENT_LIMIT}%`
      + ` a animalelor existente la ${this.dataList[1]}`
      + ` față de ${this.dataList[0]}`
      this.errorService.processErrorMsg(err);
    }
  }

  onSubmitted() {
    this.submitted = true;
    this.verificareStatus();

    if (!this.adeverintaForm.valid || this.errorFinalizata) {
      return;
    }

    this.verificaProcent();

    this.adeverintaAjutorService.salveazaAPI(this.adeverintaForm.value)
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.adeverintaForm.reset();
          this.modalReference.close();
          this.notifyClose.emit(true);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }
}
