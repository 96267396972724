<div
  *ngIf="isAdmin || isOperator"
  class="container-fluid mt-3"
>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="usersTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6 mb-1">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="clickSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>

                <button
                  class="btn btn-blue btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openAddEditUtilizatorModal(null)"
                >
                  <i class="fe-save"></i>
                  Adaugă utilizator
                </button>

                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta toți utilizatorii"
                  placement="bottom"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
          </div>

          <!-- Table -->
          <div class="table-responsive" #utilizatoriTable>
            <table
              id="users-datatable"
              class="table datatables dt-responsive nowrap"
            >
              <thead>
                <tr>
                  <th sortable="username" (sort)="onSort($event)">
                    Username
                  </th>
                  <th sortable="last_name" (sort)="onSort($event)">
                    Nume
                  </th>
                  <th sortable="first_name" (sort)="onSort($event)">
                    Prenume
                  </th>
                  <th sortable="email" (sort)="onSort($event)">
                    Adresă email
                  </th>
                  <th sortable="user_type" (sort)="onSort($event)">
                    Tip utilizator
                  </th>
                  <th sortable="is_active" (sort)="onSort($event)">
                    Status utilizator
                  </th>
                  <th scope="col">
                    Acțiuni
                  </th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="usersTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsit niciun utilizator.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let user of utilizatori">
                  <td>{{user.username}}</td>
                  <td>{{user.last_name}}</td>
                  <td>{{user.first_name}}</td>
                  <td>{{user.email}}</td>
                  <td>{{user.tip_utilizator}}</td>
                  <td>{{user.status_utilizator}}</td>
                  <td class="action-buttons">
                    <div class="btn-group">
                      <button
                        class="btn btn-sm btn-icon btn-success"
                        title="Editează"
                        (click)="openAddEditUtilizatorModal(user)"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </button>

                      <button
                        class="btn btn-sm btn-icon btn-danger"
                        title="Șterge"
                        ngbTooltip="Șterge utilizatorul"
                        (click)="confirmUserDelete(user.username)"
                      >
                        <i class="mdi mdi-delete-forever"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="usersTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ usersTableService.startIndex }} la
                {{ usersTableService.endIndex }} din
                {{ usersTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="utilizatoriLength"
                  [(page)]="usersTableService.page"
                  [pageSize]="usersTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-edit-user
  [open]="openUtilizatorModal"
  (notifyClose)="closeUtilizatorModal($event)"
  (callbackResult)="getModalResult($event)"
  [utilizator]="utilizator"
>
</app-add-edit-user>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-user'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>

<ng-template #searchModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Caută utilizatori
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="userSearchForm">

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="search_text">
                Căutare după nume, username sau email
              </label>

              <input
                type="text"
                id="search_text"
                formControlName="search_text"
                class="form-control" />
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
                <label for="status">
                    Status
                </label>
                <ng-select formControlName="status" placeholder="Toate">
                    <ng-option value='1'>Activ</ng-option>
                    <ng-option value='0'>Inactiv</ng-option>
                </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
                <label for="user_type">
                  Tip utilizator
                </label>
                <ng-select
                  formControlName="user_type"
                  placeholder="Toate"
                  (change)="changetipUtilizator()"
                >
                  <ng-option [value]="1">Super Admin</ng-option>
                  <ng-option [value]="2">Admin ACBCR</ng-option>
                  <ng-option [value]="3">Operator</ng-option>
                  <ng-option [value]="4">Fermier</ng-option>
                  <ng-option [value]="5">OJZ</ng-option>
                  <ng-option [value]="6">ANZ</ng-option>
                  <ng-option [value]="7">Contabil</ng-option>
                </ng-select>
            </div>
          </div>

          <div
            *ngIf="userSearchForm.value.user_type == 5"
            class="col-sm-12 col-md-6"
          >
            <div class="form-group mb-3">
                <label for="county_id">
                  Județ
                </label>
                <ng-select
                  placeholder="Toate"
                  formControlName="county_id"
                  [items]="counties"
                  bindValue="id"
                  bindLabel="name"
                >
                </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
            <div class="btn-group">
              <button class="btn btn-sm btn-blue" (click)="searchUsers(); modal('Cross click')">
                <i class="fe-search"></i>
                Caută
              </button>
              <button class="btn btn-sm btn-warning" (click)="resetFields()">
                <i class="fe-delete"></i>
                Resetează câmpurile
              </button>
            </div>
          </div>

          <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
            <button class="btn btn-sm btn-danger" (click)="resetSearch(); modal('Cross click')">
              Resetează căutarea
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>
</ng-template>
