import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AdvancedSortableDirective,
  SortEvent,
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { Subject } from 'rxjs';
import { IncasariTableService } from 'src/app/modules/acbcr/common/services/incasari-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-incasari-table',
  templateUrl: './incasari-table.component.html',
  styleUrls: ['./incasari-table.component.scss'],
})
export class IncasariTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('incasariTable', { static: false }) birthsTable: ElementRef;

  @Input() incasari: any[];
  @Input() incasariLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() tableCallback = new EventEmitter();

  readonly modalType = 'incasari';
  openSearchModal = new Subject<boolean>();
  modalData;

  destroy$: Subject<boolean> = new Subject<boolean>();
  currentRoute: string;

  constructor(
    public incasariTableService: IncasariTableService,
    public helperDataService: HelperDataService
  ) {}

  ngOnInit(): void {}

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.incasariTableService.sortColumn = column;
    this.incasariTableService.sortDirection = direction;
  }

  vizualizeazaIncasare(incasareId) {
    this.incasariTableService
      .descarcaViewIncasare(incasareId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        window.open(window.URL.createObjectURL(blob));
      });
  }

  descarcaIncasare(incasareId) {
    this.incasariTableService
      .descarcaViewIncasare(incasareId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, `Incasare.pdf`);
      });
  }

  anuleazaIncasare(incasareId) {
    this.incasariTableService
      .anuleazaIncasare(incasareId)
      .subscribe();
    this.tableCallback.emit(null);
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  exportXls() {
    this.exportData.emit(null);
  }
}
