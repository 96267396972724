import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettlementsTableService } from 'src/app/modules/acbcr/common/services/settlements-table.service';

@Component({
  selector: 'app-edit-settlement',
  templateUrl: './edit-settlement.component.html',
  styleUrls: ['./edit-settlement.component.scss']
})
export class EditSettlementComponent implements OnInit, OnChanges {
  @ViewChild('editSettlementModal') editSettlementModal: ElementRef;

  @Input() settlement: any;
  @Input() open = false;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  modalReference: NgbModalRef;

  modalType: string;
  settlementForm: UntypedFormGroup;
  submitted = false;

  constructor(
    public modalService: NgbModal,
    private settlementTableService: SettlementsTableService,
  ) {}

  ngOnInit(): void { }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.editSettlementModal, {
        windowClass: 'table-search-modal',
        scrollable: true,
        backdrop: 'static'
      });
    }

    if (this.settlement) {
      this.modalType = 'edit';
      this.buildForm(this.settlement);
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  buildForm(formData?) {
    this.settlementForm = new UntypedFormGroup({
      localitate: new UntypedFormControl(
        formData ? formData.name : ''),
      environment: new UntypedFormControl(
        formData ? formData.environment.toString() : '0'),
      judet: new UntypedFormControl(
        formData ? formData.county.name : ''),
      id : new UntypedFormControl(formData ? formData.id : '')
    });

    this.submitted = false;
  }

  onSubmitted() {
    this.submitted = true;
    if (!this.settlementForm.valid) {
      return;
    }

    this.settlementTableService.updateSettlement(this.settlementForm.value)
      .subscribe(result => {
        this.sendCallbackResult();
      });
  }

  notifyCloseModal() {
    this.notifyClose.emit(false);
  }

  private sendCallbackResult() {
    this.modalReference.close();
    this.callbackResult.emit('');
    this.notifyClose.emit(false);
  }

}


