import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-activitati-search',
  templateUrl: './activitati-search.component.html',
  styleUrls: ['./activitati-search.component.scss']
})
export class ActivitatiSearchComponent implements OnInit, OnDestroy {
  @Output() searchResult = new EventEmitter();
  activitateSearchForm;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor( ) { }

  ngOnInit(): void {
    this.activitateSearchForm = new UntypedFormGroup({
      exploatatia: new UntypedFormControl(null),
      subventie: new UntypedFormControl(null),
      tip_factura: new UntypedFormControl(null)
    });
  }

  onSubmitted() {
    this.searchResult.emit(this.activitateSearchForm.value);
  }

  resetSearch() {
    this.activitateSearchForm.reset();
    this.onSubmitted();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }
}
