<ng-template #addEditOperatorModal let-modal="close('Cross click')">
  <div class="modal-header" [ngSwitch]="modalType">
    <h3 class="modal-title" *ngSwitchCase="'add'">
      Operator nou
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'edit'">
      Editează operator
    </h3>

    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="(modal); notifyCloseModal()"
        autofocus="false"
      >
        Anulează
      </button>
      <button class="btn btn-sm btn-success" (click)="onSubmitted()" [ngSwitch]="modalType">
        <span *ngSwitchCase="'edit'">
          Actualizează operator
        </span>
        <span *ngSwitchCase="'add'">
          Salvează noul operator
        </span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="operatorForm">
        <div class="row">

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="oi_nume">
                Nume*
              </label>

              <input
                type="text"
                id="oi_nume"
                formControlName="oi_nume"
                placeholder="Adaugă numele operatorului"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.oi_nume.errors }"
              />
              <div
                *ngIf="submitted && f.oi_nume.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.oi_nume.errors }"
              >
                <div *ngIf="f.oi_nume.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="oi_prenume">
                Prenume*
              </label>

              <input
                type="text"
                id="oi_prenume"
                formControlName="oi_prenume"
                placeholder="Adaugă prenumele operatorului"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.oi_prenume.errors }"
              />
              <div
                *ngIf="submitted && f.oi_prenume.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.oi_prenume.errors }"
              >
                <div *ngIf="f.oi_prenume.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="oi_cnp">
                CNP
              </label>

              <input
                type="text"
                id="oi_cnp"
                formControlName="oi_cnp"
                placeholder="Adaugă cnp-ul operatorului"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.oi_cnp.errors || !validCnp}"
                (blur)="validateCNP($event)"
                (input)="
                  operatorForm.patchValue({
                    oi_cnp: $event.target.value.toUpperCase()
                  })
                "
              />
              <div
                *ngIf="!validCnp"
                class="invalid-feedback"
              >
                <div>
                  CNP-ul introdus nu este valid.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="oi_code">
                Codul operatorului*
              </label>

              <input
                type="text"
                id="oi_code"
                formControlName="oi_code"
                placeholder="Adaugă codul operatorului"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.oi_code.errors, 'disabled': modalType === 'edit' }"
                (input)="
                  operatorForm.patchValue({
                    oi_code: $event.target.value.toUpperCase()
                  })
                "
              />
                <div
                *ngIf="submitted && f.oi_code.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.oi_code.errors }"
              >
                <div *ngIf="f.oi_code.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="oi_nraut">
                Numărul autorizației*
              </label>

              <input
                type="text"
                id="oi_nraut"
                formControlName="oi_nraut"
                placeholder="Adaugă numărul autorizației"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.oi_nraut.errors }"
                (input)="
                  operatorForm.patchValue({
                    oi_nraut: $event.target.value.toUpperCase()
                  })
                "
              />
              <div
                *ngIf="submitted && f.oi_nraut.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.oi_nraut.errors }"
              >
                <div *ngIf="f.oi_nraut.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="separator mb-3"></div>

          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="oi_status">
                Stare operator
              </label>
              <ng-select
                formControlName="oi_status"
                [clearable]="false"
                [dropdownPosition]="'top'"
              >
                <ng-option [value]="0">Inactiv</ng-option>
                <ng-option [value]="1">Activ</ng-option>
              </ng-select>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</ng-template>
