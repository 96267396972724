<ng-template #editSettlementModal let-modal="close('Cross click')">
  <div class="modal-header">
    <h3 class="modal-title">
      Editează localitatea
    </h3>

    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="(modal); notifyCloseModal()"
        autofocus="false"
      >
        Anulează
      </button>
      <button class="btn btn-sm btn-success" (click)="onSubmitted()">
        <span>
          Actualizează localitatea
        </span>
      </button>
    </div>
  </div>
  <div class="modal-body">

    <div class="container-fluid">
      <form [formGroup]="settlementForm">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="form-group mb-3">
              <label for="judet">
                Județul
              </label>

              <input
                type="text"
                class="form-control read-only-form"
                name="judet"
                formControlName="judet"
                readonly
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="form-group mb-3">
              <label for="localitate">
                Localitatea
              </label>

              <input
                type="text"
                class="form-control read-only-form"
                name="localitate"
                formControlName="localitate"
                readonly
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="form-group mb-3">
              <label for="environment">
                Mediul
              </label>

              <div class="radio mb-2 ml-2 custom-radio-group">
                <input
                  type="radio"
                  name="environment"
                  id="environment1"
                  value="0"
                  formControlName="environment"
                />
                <label for="environment">
                  Rural
                </label>

                <input
                  type="radio"
                  name="environment"
                  id="environment2"
                  value="1"
                  class="ml-3"
                  formControlName="environment"
                />
                <label for="environment">
                  Urban
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
