import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToDisplayFormat, convertDateToBackendFormat } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})

export class RapoarteClientTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  rapoarteClient: any[];
  numarRapoarteClient: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.rapoarteClient, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getRapoarteClientAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/contabilitate/rapoarte/raportclient/`,
      {
        ...obj,
        data_start: convertDateToBackendFormat(obj.data_start),
        data_end: convertDateToBackendFormat(obj.data_end)
      }
    ).pipe(map((response: any) => {
      this.rapoarteClient = response.result;
      this.numarRapoarteClient = response.count;

      this.rapoarteClient.map((raport: any) => {
        raport.data = convertDateToDisplayFormat(raport.data);
      });

      this.paginate();
      this.sortEvent();
      this._total$.next(this.numarRapoarteClient);

      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/rapoarte/raportclientxlsx/`,
      {
        ...obj,
        data_start: convertDateToBackendFormat(obj.data_start),
        data_end: convertDateToBackendFormat(obj.data_end)
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  clearRapoarteTable() {
    this.rapoarteClient = [];
    this.numarRapoarteClient = 0;
    this.paginate();
    this._total$.next(0);
    this._tables$.next([]);
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarRapoarteClient;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarRapoarteClient) {
      this._state.endIndex = this.numarRapoarteClient;
    }
  }
}
