<div class="container-fluid mt-3">

  <div class="row terms-row">
    <div class="col-lg-10">
      <div class="text-center">
        <i class="h1 mdi mdi-alert-circle text-muted"></i>
        <h3 class="mb-3">Termeni si Conditii</h3>
        <p class="text-muted text-justify"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled 
          it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
          It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like 
          Aldus PageMaker including versions of Lorem Ipsum.</p>
      </div>
    </div>
  </div>
</div>
 