export class DocumentHelper {
    public static link_documente(item) {
        if (item['animal_id']) {
            return '/dashboard/animal/' + item['animal_id'] + '/pedigree-rg'
        }

        if (item['tip_document'] === 'anexa3') {
            return '/dashboard/exploatatie/' + item['cod_exploatatie'] + '/documente/anexe-3'
        } else if (item['tip_document'] === 'anexa8') {
            return '/dashboard/exploatatie/' + item['cod_exploatatie'] + '/documente/anexe-8'
        } else if (item['tip_document'] === 'anexa10') {
            return '/dashboard/exploatatie/' + item['cod_exploatatie'] + '/miscari'
        } else if (item['tip_document'] === 'solicitare_cz') {
            return '/dashboard/exploatatie/' + item['cod_exploatatie'] + '/documente/solicitari-certificate-zootehnice'
        } else if (item['tip_document'] === 'adeverinta_apia') {
            return '/dashboard/adeverinta-apia'
        } else if (item['tip_document'] === 'avizare_taur') {
            return '/dashboard/avizare-tauri'
        } else if (item['tip_document'] === 'certificat_sanitar') {
            return '/dashboard/certificat-sanitar'
        } else if (item['tip_document'] === 'autorizatie_monta') {
            return '/dashboard/exploatatie/' + item['cod_exploatatie'] + '/animale-exploatatie'
        } else if (item['tip_document'] === 'certificat_zootehnic') {
            return '/dashboard/exploatatie/' + item['cod_exploatatie'] + '/documente/certificate-zootehnice'
        } else if (item['tip_document'] === 'adeverinta_ajutor') {
            return '/dashboard/adeverinta-ajutor'
        }
    }
}
