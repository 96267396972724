import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import { HelperDataService } from './../../../../../../../common/services/helper-data.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppCities } from './../../../../../../../common/state/reducers/countries-cities.reducers';
import { HoldingsTableService } from './../../../../../../../common/services/holdings-table.service';
import { City } from 'src/app/modules/acbcr/common/models/city.model';
import { SetCompanyAdminsData } from './../../../../../../../common/state/actions/company-admins.actions';


@Component({
  selector: 'app-adresa-exp-form-block',
  templateUrl: './adresa-exp-form-block.component.html',
  styleUrls: ['./adresa-exp-form-block.component.scss']
})
export class AdresaExpFormBlockComponent implements OnInit, OnDestroy {

  @Input() holdingForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @ViewChild('smallCitiesDropDown') smallCitiesDropDown: NgSelectComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  cities: City[];
  smallCities: City[];
  mediul;

  constructor(
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
    private holdingsTableService: HoldingsTableService
  ) {
    this.smallCities = [];
  }

  ngOnInit(): void {
    this.store.pipe(select(getAppCities))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.cities = response;
      });

    // Populate small cities dropdowns
    if (this.holdingForm.value.localitate !== null) {

      this.cities.map(elem => {
        if (elem.name === this.holdingForm.value.judet) {
          this.helperDataService.getSmallCities(elem.code)
            .subscribe(response => {
              this.smallCities = response;
            });
        }
      });

      // Get environment from the form
      this.mediul = this.holdingForm.value.mediul;

    }

    this._getAdmins();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  getSelectedCity(city) {
    if (city !== undefined) {
      this.helperDataService.getSmallCities(city.code)
        .subscribe(response => {
          this.smallCities = response;
        });
    }

    this.smallCitiesDropDown.handleClearClick();
    this.smallCities = [];
    this.holdingForm.value.localitatea = '';
  }

  // Change settlement to set environment
  changeSettlement(settlement) {

    this.mediul = undefined;
    if (settlement !== undefined) {

      this._getAdmins(settlement.name);

      this.holdingForm.value.mediul = settlement.environment;
      this.mediul = settlement.environment;
    }

  }

  get f() { return this.holdingForm.controls; }

  _getAdmins(settlement?) {

    // Get holdings admins, after the user select a settlement
    this.holdingsTableService
      .getHoldingAdmins(
        { admin_settl: '' }
      )
      .subscribe((response => {
        // Write admins in the app store
        this.store.dispatch(new SetCompanyAdminsData(response));
      }));
  }

}
