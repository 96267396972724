export const juridicForms = [
  { id: 0, name: 'Persoană fizică' },
  { id: 1, name: 'Societate în nume colectiv (SNC)' },
  { id: 2, name: 'Societate în comandită simplă (SCS)' },
  { id: 3, name: 'Societate pe acțiuni (SA)' },
  { id: 4, name: 'Societatea în comandită pe acțiuni (SCA)' },
  { id: 5, name: 'Societatea cu răspundere limitată (SRL)' },
  { id: 6, name: 'Persoană fizică autorizată (PFA)' },
  { id: 7, name: 'Întreprindere individuală (II)' },
  { id: 8, name: 'Întreprindere familială (IF)' },
];
