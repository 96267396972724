import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import {
  SortableTable,
} from './services-table-helper';
import { Activitate } from '../models/activitate.model';

@Injectable({
  providedIn: 'root'
})
export class ActivitatiService extends SortableTable<Activitate> {
  private baseUrl = environment.API_URL;

  activitatiList: Activitate[];
  activitatiListLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.activitatiList, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getActivitatiAPI(obj) {
    return this.http.post(`${this.baseUrl}/contabilitate/activitatiExploatatiiList/`,
      { ...obj }
    ).pipe(
      map((response: any) => {
        this.activitatiList = response.result;
        this.activitatiListLength = response.count;

        if (this.activitatiList.length > 0) {
          this.activitatiList.map(activitate => {

              // get avaible types
            const types = activitate.tip_factura?.split(', ');
            const perioadaTypes = activitate.perioada_facturare?.split(', ');
            const rg = types?.includes('1');
            const cpp = types?.includes('2');
            let perioadaRg;
            let perioadaCpp;
            perioadaTypes?.map(type => {
              type.charAt(0) === '1' ? perioadaRg = type : perioadaCpp = type;
            });
            activitate.perioadaRg = perioadaRg?.length > 0 ? parseInt(perioadaRg?.split(':')[1], 10) : null;
            activitate.perioadaCpp = perioadaCpp?.length > 0 ? parseInt(perioadaCpp?.split(':')[1], 10) : null;

            const rgActivities = [];
            const cppActivities = [];
            const acbcrActivities = [];
            activitate.activitati_facturabile = [];
            activitate.activitati_existente = [];

            // get avaible activities
            const holdingActivities = activitate.activitate.split(', ');
            holdingActivities.map(hActivitate => {
              if (hActivitate.split(':')[0] === '1') {
                rgActivities.push({id: parseInt(hActivitate.split(':')[1], 10), name: hActivitate.split(':')[2], type: parseInt(hActivitate.split(':')[0], 10)});
              } else if (hActivitate.split(':')[0] === '2') {
                cppActivities.push({id: parseInt(hActivitate.split(':')[1], 10), name: hActivitate.split(':')[2], type: parseInt(hActivitate.split(':')[0], 10)});
              } else if (hActivitate.split(':')[0] === '3') {
                acbcrActivities.push({id: parseInt(hActivitate.split(':')[1], 10), name: hActivitate.split(':')[2], type: parseInt(hActivitate.split(':')[0], 10)});
              }
            });

            if (rg) {
              activitate.activitati_facturabile.push({name: 'RG', type: 1, activities: rgActivities});
            }

            if (cpp) {
              activitate.activitati_facturabile.push({name: 'CPP', type: 2, activities: cppActivities});
            }

            const actExistente = [];

            rgActivities.map(rAct => {
              actExistente.push({name: rAct.name, type: rAct.type});
            });

            cppActivities.map(cAct => {
              actExistente.push({name: cAct.name, type: cAct.type});
            });

            acbcrActivities.map(aAct => {
              actExistente.push({name: aAct.name, type: aAct.type});
            });

            activitate.activitati_facturabile.push({name: 'ACBCR', type: 3, activities: acbcrActivities});
            activitate.activitati_existente = actExistente;
          });
        }

        this.paginate();
        this.sortEvent();
        this._total$.next(this.activitatiListLength);

        return this.activitatiList;
      })
    );
  }

  addActivitateApi(holdingId, facturaType, activitateId) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/activitatiExploatatiiAdd/`,
        { exploatatia_id: holdingId, tip_factura: facturaType, activitatea_id: activitateId }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateActivitateApi(holdingId, subventie, perioada?) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/updateModFacturareExploatatie/`,
        { exploatatia_id: holdingId, subventie, perioada }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteActivitateApi(holdingId, facturaType, activitateId) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/activitatiExploatatiiDel/`,
        { exploatatia_id: holdingId, tip_factura: facturaType, activitatea_id: activitateId }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/activitatiExploatatiiExport/`,
      {
        ...obj
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.activitatiListLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.activitatiListLength) {
      this._state.endIndex = this.activitatiListLength;
    }
  }
}
