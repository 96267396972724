import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppCountriesCitiesActions, AppCountriesCitiesActionType } from './../actions/countries-cities.actions';
import { ApplicationState } from '../app.state';
import { Country } from '../../models/country.model';
import { City } from '../../models/city.model';

export interface AppCountriesCities {
  countries: Country[];
  cities: City[];
}

export const initialState = {
  countries: [],
  cities: []
};

export const getApplicationFeature =
  createFeatureSelector<ApplicationState>('appData');

export const getAppCountries =
  createSelector(
    getApplicationFeature,
    state => state.countries_cities.countries
  );

export const getAppCities =
  createSelector(
    getApplicationFeature,
    state => state.countries_cities.cities
  );

export function countriesCitiesReducers(
  state: AppCountriesCities = initialState,
  action: AppCountriesCitiesActions
) {

  switch (action.type) {
    case AppCountriesCitiesActionType.SET_COUNTRIES_DATA:
      return {
        ...state,
        countries: action.payload
      };

    case AppCountriesCitiesActionType.SET_CITIES_DATA:
      return {
        ...state,
        cities: action.payload
      };

    default:
      return state;
  }

}
