<div class="card" id="ad-exploatatie" [formGroup]="holdingForm">
  <h3 class="card-header">Adresa exploatației</h3>
  <div class="card-body">
    <div class="row">

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="judet">
            Județ{{f.status.value !== 5 ? '*' : ''}}
          </label>

          <ng-select
            [items]="cities"
            bindLabel="name"
            formControlName="judet"
            [ngClass]="{ 'is-invalid': submitted && f.judet.errors }"
            placeholder="Selectează județul"
            bindValue="name"
            (change)="getSelectedCity($event)"
          >
          </ng-select>

          <div
            *ngIf="submitted && f.judet.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.judet.errors }"
          >
            <div *ngIf="f.judet.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>

          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="localitatea">
            Localitatea{{f.status.value !== 5 ? '*' : ''}}
          </label>

          <ng-select
            [items]="smallCities"
            bindLabel="name"
            formControlName="localitate"
            [ngClass]="{ 'is-invalid': submitted && f.localitate.errors }"
            placeholder="Selectează localitatea"
            notFoundText="Selectați un județ"
            #smallCitiesDropDown
            (change)="changeSettlement($event)"
          >
          </ng-select>
          <small
            id="emailHelp"
            class="form-text text-muted"
            *ngIf="mediul !== undefined"
          >
            <div class="mb-2">
              <b>Mediul:</b>
              <input
                type="hidden"
                name="mediul"
                id="mediul"
                formControlName="mediul"
              />
              <span *ngIf="mediul == 0">
                Rural
              </span>
              <span *ngIf="mediul == 1">
                Urban
              </span>
            </div>
          </small>

          <div
            *ngIf="submitted && f.localitate.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.localitate.errors }"
          >
            <div *ngIf="f.localitate.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>

          </div>
        </div>
      </div>

      <div class="col-sm-8">
        <div class="form-group mb-3">
          <label for="street">
            Strada{{f.status.value !== 5 ? '*' : ''}}
          </label>

          <input
            type="text"
            id="street"
            formControlName="street"
            [ngClass]="{ 'is-invalid': submitted && f.street.errors }"
            placeholder="Adaugă strada"
            class="form-control"
          />

          <div
            *ngIf="submitted && f.street.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.street.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>

          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group mb-3">
          <label for="stno">
            Număr{{f.status.value !== 5 ? '*' : ''}}
          </label>

          <input
            type="text"
            id="stno"
            formControlName="stno"
            [ngClass]="{ 'is-invalid': submitted && f.stno.errors }"
            placeholder="Adaugă numărul străzi"
            class="form-control"
          />

          <div
            *ngIf="submitted && f.stno.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.stno.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>

          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group mb-3">
          <label for="zip">
            Cod poștal
          </label>

          <input
            type="text"
            id="zip"
            formControlName="zip"
            placeholder="Adaugă codul poștal"
            class="form-control" />
        </div>
      </div>

    </div>
  </div>
</div>