<form [formGroup]="birthSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_start">
          Dată început
        </label>
        <app-date-picker
          [selectedDate]="birthSearchForm.value.data_start"
          (dateChanged) ="onStartDateChanged($event)"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_end">
          Dată sfârșit
        </label>
        <app-date-picker
          (dateChanged) ="onEndDateChanged($event)"
          [selectedDate]="birthSearchForm.value.data_end"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="matricolvitel">
          Matricol vițel
        </label>

        <input
          id="matricolvitel"
          type="text"
          class="form-control"
          placeholder="Caută după matricol vițel"
          formControlName="matricolvitel"
          (input)="
            birthSearchForm.patchValue({
              matricolvitel: $event.target.value.toUpperCase()
            });
          "
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="sex">
          Sex
        </label>

        <div class="radio mb-2 custom-radio-group">
          <input
            type="radio"
            name="sex"
            id="sex1"
            formControlName="sex"
            value="1"
          />
          <label for="sex1">
            Mascul
          </label>

          <input
            type="radio"
            name="sex"
            id="sex2"
            formControlName="sex"
            value="2"
            class="ml-4"
          />
          <label for="sex2">
            Femelă
          </label>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="mama">
          Matricol mamă
        </label>

        <ng-select
          [items]="mamaItems"
          placeholder="Caută după matricol mamă"
          bindLabel="numarmatricol"
          bindValue="id"
          formControlName="mama"
          (search)="searchMatricolMama($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="tata">
          Matricol tată
        </label>

        <ng-select
          [items]="tataItems"
          placeholder="Caută după matricol tată"
          bindLabel="numarmatricol"
          bindValue="id"
          formControlName="tata"
          (search)="searchMatricolTata($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="rang_fatare">
          Rang fătare
        </label>

        <input
          class="form-control"
          id="rang_fatare"
          type="number"
          min="1"
          max="40"
          placeholder="Caută după rangul fătării"
          formControlName="rang_fatare"
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="usurinta_fatare">
          Ușurință fătare
        </label>

        <ng-select
          [items]="usurintaFatari"
          bindLabel="name"
          bindValue="value"
          placeholder="Caută dupa ușurința fătării"
          formControlName="usurinta_fatare"
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
