import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivitatiService } from 'src/app/modules/acbcr/common/services/activitati.service';
import { Activitate } from 'src/app/modules/acbcr/common/models/activitate.model';

@Component({
  selector: 'app-activitati',
  templateUrl: './activitati.component.html',
  styleUrls: ['./activitati.component.scss']
})
export class ActivitatiComponent implements OnInit, OnDestroy {
  tables$: Observable<Activitate[]>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    page_no: 1,
    page_size: 10
  };

  constructor(
    public activitatiService: ActivitatiService
  ) {
    this.tables$ = activitatiService.tables$;
    this.total$ = activitatiService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.activitatiService
      .getActivitatiAPI(this.objToAPI).subscribe((response) => {
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
    });
}

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

  getCallBackResult(event) {
    this.getTableData();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

}

