import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import {
  SortableTable,
  convertDateToDisplayFormat,
  convertDateToBackendFormat,
} from './services-table-helper';

@Injectable({
  providedIn: 'root',
})
export class IncasariTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  incasari: any[];
  incasariLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(
      this.incasari,
      this._state.sortColumn,
      this._state.sortDirection
    );
    this._tables$.next(tables);
  }

  getIncasariAPI(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/listChitante/`,
        {
          ...obj,
          data_inceput: convertDateToBackendFormat(obj.data_inceput),
          data_sfarsit: convertDateToBackendFormat(obj.data_sfarsit),
        }
      )
      .pipe(
        map((response: any) => {
          this.incasari = response.result;
          this.incasariLength = response.count;
          this.incasari.map((incasare: any) => {
            incasare.data_inceput = convertDateToDisplayFormat(
              incasare.data_inceput
            );
            incasare.data_sfarsit = convertDateToDisplayFormat(
              incasare.data_sfarsit
            );
            incasare.data = convertDateToDisplayFormat(incasare.data);
          });

          this.paginate();
          this.sortEvent();
          this._total$.next(this.incasariLength);

          return response;
        })
      );
  }

  getExplFacturiNeachitate() {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/exploatatiiFacturiNeachitateList/`,
        null
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  platesteIncasare(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/incasare/`,
        { ...obj, data: convertDateToBackendFormat(obj.data) }
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (errors) => {
            return errors;
          }
        )
      );
  }

  getFacturiNeachitate(expCode) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/facturiNeachitateList/`,
        { expl_code: expCode }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  anuleazaIncasare(incasareid) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/anulareChitanta/`,
        { incasareid }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  exportXLS(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/incasari/export-xls/`,
        {
          ...obj,
          data_start: convertDateToBackendFormat(obj.data_start),
        },
        {
          responseType: 'blob',
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  descarcaViewIncasare(incasareid) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/pdfChitanta/`,
        { incasareid },
        {
          responseType: 'blob',
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.incasariLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex =
      Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.incasariLength) {
      this._state.endIndex = this.incasariLength;
    }
  }
}
