import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-animal-details',
  templateUrl: './animal-details.component.html',
  styleUrls: ['./animal-details.component.scss']
})
export class AnimalDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
