<app-raport-facturi-emise-table
  [rapoarteFacturiEmise]="tables$ | async"
  [rapoarteFacturiEmiseLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (rapoarteFacturiEmiseTableCallback)="getTableData()"
></app-raport-facturi-emise-table>
