import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Breed } from '../../../common/models/animal.models';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

export class AnimalHelper {
  private static fb = new UntypedFormBuilder();

  public static getRaseSplit(raseStr: string): Array<Breed> {
    const rase = [];

    if (raseStr) {
      raseStr.split(',').forEach(rasa => {
        let shortname = '';
        let val = '';
        [...rasa].forEach(c => {
          if (UIHelper.isLetter(c)) {
            shortname += c;
          } else { val += c; }
        });

        rase.push({shortname, val: parseFloat(val)});
      });
    }

    return rase;
  }

  public static getRaseSum(rase: Array<Breed>) {
    let sum = 0;
    for (const rasa of rase) { sum += rasa.val; }

    return sum;
  }

  public static getRaseMap(rase: Array<Breed>) {
    const raseMap = new Map();

    for (const rasa of rase) {
      const val = raseMap.get(rasa.shortname);
      if (val) {
        raseMap.set(rasa.shortname, val + rasa.val);
      } else {
        raseMap.set(rasa.shortname, rasa.val);
      }
    }

    return raseMap;
  }

  public static isEditingParents(modalType): boolean {
    return (modalType === 'add-mother' || modalType === 'add-father' ||
    modalType === 'edit-mother' || modalType === 'edit-father');
  }

  public static createItem(): UntypedFormGroup {
    return this.fb.group({
      raceid: [null, Validators.required],
      val: ['', Validators.required]
    });
  }

  public static createItems(count: number): UntypedFormGroup[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(this.createItem());
    }
    return arr;
  }
}
