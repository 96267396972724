<form [formGroup]="rapoarteIncasariSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_start"> Dată început* </label>
        <app-date-picker
          (dateChanged)="onStartDateChanged($event)"
          [required]="true"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_end"> Dată sfârșit* </label>
        <app-date-picker
          (dateChanged)="onEndDateChanged($event)"
          [required]="true"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="tip">
          Tip încasare
        </label>

        <ng-select
          formControlName="tip"
          placeholder="Alege tip încasare"
        >
          <ng-option [value]="0">OP</ng-option>
          <ng-option [value]="1">Cash</ng-option>
          <ng-option [value]="2">Mandat poștal</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
