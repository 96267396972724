import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IncadrareSectiuneService } from 'src/app/modules/acbcr/common/services/incadrare-sectiune.service'
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-incadrare-sectiune',
  templateUrl: './incadrare-sectiune.component.html',
  styleUrls: ['./incadrare-sectiune.component.scss']
})
export class IncadrareSectiuneComponent implements OnInit {
  baseUrl = environment.API_URL;
  intervalID: number;
  REFRESH_INTERVAL = 5000;
  statsInCurs = null;
  uniqueId = UIHelper.generateUniqueId();
  isAdmin = false;
  isOperator = false;

  constructor(
    public incadrareSectiuneService: IncadrareSectiuneService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.intervalID = window.setInterval(this.updateStatsInCurs.bind(this), this.REFRESH_INTERVAL);
  }

  onFileSelected(event) {
    const fisier:File = event.target.files[0];
    const formData = new FormData();
    formData.append('fisier', fisier);
    formData.append('unique_id', this.uniqueId);

    this.incadrareSectiuneService.uploadCSVFile(formData)
      .subscribe({
        next: (response) => {
          return response;
        }
      });
  }

  updateStatsInCurs() {
    this.incadrareSectiuneService.inCursAPI(this.uniqueId).subscribe((response) => {
      if (response) {
        this.statsInCurs = response;
        this.ngOnDestroy();
      }
    });
  }

  ngOnDestroy() {
    if (this.intervalID) {
      window.clearInterval(this.intervalID)
    }
  }
}
