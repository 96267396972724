import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IncasariTableService } from 'src/app/modules/acbcr/common/services/incasari-table.service';
import { HoldingsNames } from 'src/app/modules/acbcr/common/models/holdings.models';
import { Subject } from 'rxjs';
import { convertDateToDisplayFormat } from 'src/app/modules/acbcr/common/services/services-table-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-incasare',
  templateUrl: './incasare.component.html',
  styleUrls: ['./incasare.component.scss'],
})
export class IncasareComponent implements OnInit, OnDestroy {
  incasareForm;
  submitted = false;
  facturaEditSuccess = false;
  holdingsNames: HoldingsNames[];
  reset: number;
  showHint = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  facturiNeachitate = [];
  loadingDataSpinner = false;
  totalRestant = '';
  detalii;
  facturiListIds = [];
  facturiListValues = [];
  sumOfValues = 0;
  choosenExpl;

  constructor(
    private incasariTableService: IncasariTableService,
    private errorService: NotificationErrorService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getExplFacturiNeachitate();
    this.buildIncasareForm();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  buildIncasareForm() {
    this.incasareForm = new UntypedFormGroup({
      exploatatie: new UntypedFormControl(null, Validators.required),
      tip_incasare: new UntypedFormControl(null, Validators.required),
      data: new UntypedFormControl(null, Validators.required),
      detalii: new UntypedFormControl(null, Validators.required),
      valoare: new UntypedFormControl(0, Validators.required),
      facturi_list: new UntypedFormControl(null, Validators.required),
    });
    this.submitted = false;
  }

  selectExploatatie(event) {
    this.sumOfValues = 0;
    this.f.tip_incasare.setValue(null);
    this.f.data.setValue(null);
    this.f.detalii.setValue(null);
    this.f.facturi_list.setValue(null);
    this.f.valoare.setValue(0);
    if (event) {
      this.choosenExpl = event.expl_code;
      this.getFacturiNeachitate(this.choosenExpl);
    }
  }

  getExplFacturiNeachitate() {
    this.incasariTableService
      .getExplFacturiNeachitate()
      .subscribe((response) => {
        this.holdingsNames = response.result;
      });
  }

  statusFactura(factura) {
    factura.isChecked = !factura.isChecked;
    if (factura.isChecked) {
      this.facturiListIds.push(factura.id);
      this.sumOfValues += parseFloat(factura.val_de_achitat);
    } else {
      // remove id from idList []
      this.facturiListIds = this.facturiListIds.filter(
        (facturaId) => facturaId !== factura.id
      );
      this.sumOfValues = this.sumOfValues - factura.val_de_achitat;
    }
    this.incasareForm.controls.facturi_list.setValue(this.facturiListIds);
    this.incasareForm.controls.valoare.setValue((this.sumOfValues).toFixed(2));
  }

  getFacturiNeachitate(expCode) {
    this.loadingDataSpinner = true;
    this.incasariTableService.getFacturiNeachitate(expCode).subscribe(
      (response) => {
        this.loadingDataSpinner = false;
        this.totalRestant = response.restant;
        this.detalii = response.detalii;
        this.facturiNeachitate = response.result;
        this.facturiNeachitate.map((factura: any) => {
          factura.data = convertDateToDisplayFormat(factura.data);
          factura.valFactura =
            parseFloat(factura.val_fara_tva) + parseFloat(factura.TVA);
          factura.val_de_achitat = factura.valFactura - parseFloat(factura.incasare) -
            (parseFloat(factura.subventie) ? parseFloat(factura.subventie) : 0);
          factura.val_de_achitat = factura.val_de_achitat.toFixed(2);
          factura.isChecked = false;
        });
      },
      (errors) => {
        this.loadingDataSpinner = false;
      }
    );
  }

  searchHoldings(event) {
    if (event.term.length >= 4) {
      this.showHint = false;
    } else {
      this.showHint = true;
    }
  }

  clearHoldings() {
    this.showHint = true;
    this.sumOfValues = 0;
    this.f.tip_incasare.setValue(null);
    this.f.data.setValue(null);
    this.f.detalii.setValue(null);
    this.detalii = null;
    this.f.facturi_list.setValue(null);
    this.f.valoare.setValue(0);
    this.facturiNeachitate = [];
    this.totalRestant = '';
  }

  valoareChanged(event) {
    if (event.target.value > this.sumOfValues) {
      this.errorService.processErrorMsg('Valoarea încasării nu poate fi mai mare decât totalul de plată');
      this.incasareForm.controls.valoare.setValue(this.sumOfValues.toFixed(2));
    }
  }

  get f() {
    return this.incasareForm.controls;
  }

  selectTipIncasare(event) {
    this.f.tip_incasare.setValue(event);
  }

  onDataIncasariiChanged(event) {
    if (this.incasareForm) {
      this.incasareForm.controls.data.setValue(event);
    }
  }

  onSubmittedIncasare() {
    this.submitted = true;
    if (!this.incasareForm.valid) {
      return;
    }
    this.incasariTableService.platesteIncasare(this.incasareForm.value).subscribe(response => {
      this.sumOfValues = 0;
      this.f.valoare.setValue(0);
      this.submitted = false;
      if (this.f.tip_incasare.value === 1) {
        this.router.navigateByUrl('/contabilitate/chitante');
        this.incasareForm.reset();
        this.detalii = null;
        this.choosenExpl = null;
        this.facturiNeachitate = [];
      } else {
        this.getFacturiNeachitate(this.choosenExpl);
      }
      this.facturiListIds = [];
      this.f.facturi_list.setValue(null);
    });
  }
}
