import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import {
  SortEvent,
  AdvancedSortableDirective
} from '../../../directives/advanced-sortable.directive';
import { Exploatatie, SearchHolding } from '../../../common/models/holdings.models';
import { HoldingsTableService } from '../../../common/services/holdings-table.service';
import { Router } from '@angular/router';
import { HelperDataService } from '../../../common/services/helper-data.service';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ApplicationState } from '../../../common/state/app.state';
import { NotificationErrorService } from '../../../common/services/notification-error.service';
import { SetHoldingsFilter } from '../../../common/state/actions/filters.actions';
import { getHoldingsFilter } from '../../../common/state/reducers/filters.reducers';
import { AddNewHoldingData, DeleteHoldingNameData } from 'src/app/modules/acbcr/common/state/actions/holdings.actions';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-holdings',
  templateUrl: './holdings.component.html',
  styleUrls: ['./holdings.component.scss'],
  providers: [HoldingsTableService, DecimalPipe]
})
export class HoldingsComponent implements OnInit, OnDestroy {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('holdingsTable', { static: false }) holdingsTable: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  tables$: Observable<Exploatatie[]>;
  total$: Observable<number>;
  filter = new UntypedFormControl('');

  openAddEditModal = false;
  modalType: string;
  holdingData: any;

  openSearchModal = new Subject<boolean>();
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  onlySearch = false;
  loadingDataSpinner = true;
  holdingTableData;
  holdingTableDataLength;
  openConfirmModal = false;
  holdingCode: number;
  holdingId: number;
  isFiltered = false;

  // Default values for search holdings filter
  objToAPI: SearchHolding = {
    comp_code: '',
    comp_name: '',
    comp_cui: '',
    comp_settl: '',
    comp_status: '1',
    page_no: '1',
    page_size: '10'
  };
  user;

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private store: Store<ApplicationState>,
    public holdingTableService: HoldingsTableService,
    private helperDataService: HelperDataService,
    private errorService: NotificationErrorService,
  ) {
    this.tables$ = holdingTableService.tables$;
    this.total$ = holdingTableService.total$;

    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    if (this.router.url.includes('?filter')) {
      this.store.pipe(select(getHoldingsFilter))
      .pipe(take(1)).subscribe((filter: SearchHolding) => {
        if (filter) {
          this.objToAPI = filter;
          this.checkHoldingsFilter(this.objToAPI);
          this.holdingTableService.page = parseInt(filter.page_no, 10);
          this.holdingTableService.pageSize = parseInt(filter.page_size, 10);
        }
      });
    }
  }

  ngOnInit() {
    this.changeDetector.detectChanges();

    if (this.objToAPI.order_by) {
      this.setTableHeaders(this.objToAPI.order_by);
    } else {
      this.resetSortTable();
    }
    this._getTableData();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === 'asc') {
      this.objToAPI.order_by = column;
    } else if (direction === 'desc') {
      this.objToAPI.order_by = '-' + column;
    } else {
      delete this.objToAPI.order_by;
      this.resetSortTable();
    }

    this._getTableData();
  }

  exportTableXLSX() {
    let now = UIHelper.now();
    let fisier = `Exploatatii_${now}.xls`;

    this.holdingTableService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, fisier);
      });
  }

  openAddEditHoldingModal(type: string, holdingCode?: string) {
    this.openAddEditModal = false;

    switch (type) {
      case 'add':
        this.holdingData = '';

        this.openAddEditModal = true;
        this.modalType = type;
        break;
      case 'view':
      case 'edit':
        this.holdingTableService
          .getHoldingAPI({ comp_code: holdingCode })
          .subscribe(response => {
            this.holdingData = response.result[0];

            this.openAddEditModal = true;
            this.modalType = type;
          });
        break;
    }
  }

  openTableSearchModal(type: string) {
    this.openAddEditModal = false;
    this.openSearchModal.next(true);
    this.modalType = type;
  }

  closeAddUpdateModal(event) {
    this.openAddEditModal = event;
  }

  // Get data from the back-end when the user change the table page
  changeTablePage(event) {
    this.objToAPI.page_no = event;
    this._getTableData();
  }

  // Get data from the back-end when the user change the number of rows are dispalyed on the table
  changeTableRows(event) {
    this.objToAPI.page_size =  event.target.value;
    this._getTableData();
  }

  // Filtered search
  filterSearch(searchingData) {
    this.holdingTableService.page = 1;
    this.objToAPI = {
      ...searchingData,
      ...{ comp_settl: searchingData.comp_settl !== null ? searchingData.comp_settl.name : '' },
      ...{ comp_county: searchingData.comp_county !== null ? searchingData.comp_county.name : '' },
      ... {
        comp_races:
          (searchingData.comp_races && Array.isArray(searchingData.comp_races))
            ? searchingData.comp_races.join()
            : []
      },
      page_no: this.holdingTableService.page,
      page_size: this.holdingTableService.pageSize
    };

    this.checkHoldingsFilter(searchingData);
    if (Object.values(this.objToAPI) !== null) {
      this.onlySearch = true;
    }

    this.resetSortTable();
    this._getTableData();
  }

  _getTableData() {
    this.loadingDataSpinner = true;
    this.holdingTableService
      .getHoldingsAPI(this.objToAPI).subscribe((response) => {
        this.holdingTableData = response.result;
        this.holdingTableDataLength = response.count;

        // Hide spinner, after 1 second
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  downloadHoldingHistory(holdingId, holdingCode) {
    this.holdingTableService.downloadHoldingHistoryApi(holdingId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(blob, holdingCode + `_istoric.xls`);
      });
  }

  openConfirmActionModal(holdingCode, holdingId) {
    this.openConfirmModal = true;
    this.holdingCode = holdingCode;
    this.holdingId = holdingId;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.deleteHolding(this.holdingCode);
    }
    this.holdingCode = null;
  }

  private deleteHolding(holdingId) {
    this.loadingDataSpinner = true;
    this.holdingTableService.deleteHolding(holdingId).subscribe(response => {
      this.store.dispatch(new DeleteHoldingNameData(this.holdingId));
      this._getTableData();
      this.holdingId = null;
      setTimeout(() => {
        this.loadingDataSpinner = false;
      }, 500);
    }, error => {
      this.errorService.processErrorMsg('Nu se poate șterge această exploatație');
      this.loadingDataSpinner = false;
    });
  }

  onHoldingNameClick(exploatatie: Exploatatie) {
    this.store.dispatch(new SetHoldingsFilter(this.objToAPI));
  }

  updateHoldingsList(event) {
    this.store.dispatch(
      new AddNewHoldingData(
        { id: event.id, name: event.name }
      )
    );

    this.ngOnInit();
  }

  private resetSortTable() {
    this.headers.forEach(header => {
      if (header.sortable === 'name') {
        header.direction = 'asc';
      }
    });
  }

  private checkHoldingsFilter(filter: SearchHolding) {
    if (filter.comp_code || filter.comp_cui || filter.comp_name || filter.comp_settl ||
      filter.comp_county || (filter.comp_races && filter.comp_races.length) || filter.comp_status) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }
  }

  private setTableHeaders(order: string) {
    let desc = false;
    if (order.includes('-')) {
      desc = true;
      order = order.slice(order.indexOf('-') + 1 , order.length);
    }

    this.headers.forEach(header => {
      if (header.sortable === order) {
        if (desc) {
          header.direction = 'desc';
        } else {
          header.direction = 'asc';
        }
      }
    });
  }

}
