<div class="card" id="registruGenealogic" [formGroup]="animalForm">
  <h3 class="card-header">Registru Genealogic</h3>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12"><h4>REGISTRU GENERAL</h4></div>
      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="country">
            Țară origine
          </label>
          <ng-select
            [items]="countries"
            bindLabel="name"
            bindValue="id"
            placeholder="Alege țara de origine"
            formControlName="taraorigineid"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="taraprovenientaid">
            Țară proveniență
          </label>
          <ng-select
            [items]="countries"
            bindLabel="name"
            bindValue="id"
            placeholder="Alege țara de proveniență"
            formControlName="taraprovenientaid"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="nrregoriginal">
            Număr registru
          </label>
          <input
            type="text"
            id="nrregoriginal"
            placeholder="Număr registru"
            class="form-control"
            formControlName="nrregoriginal"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
            (input)="$event.target.value = $event.target.value.toUpperCase()"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="sectreggeneral">
            Secțiune registru general
          </label>
          <ng-select
            bindLabel="sectreggeneral"
            bindValue="sectreggeneral"
            placeholder="Alege registru general"
            formControlName="sectreggeneral"
            [clearable]="false"
            [ngClass]="{ disabled: isFermier || disableAllFields || sectionChanged}"
            (change)="rgGenSectionChanged($event)"
          >
            <ng-option value="0">Neîncadrat</ng-option>
            <ng-option value="1">A</ng-option>
            <ng-option value="2">B</ng-option>
            <ng-option value="3">C</ng-option>
            <ng-option value="4">D</ng-option>
          </ng-select>
        </div>
      </div>

      <ng-template #popContent>
        <div class="popover-content">
          <div [innerHtml]="fitMessage.message" class="text"></div>
        </div>

        <div class="separator mt-1 mb-2"></div>

        <div class="col-sm-12 mb-1">
          <button
            type="button"
            class="btn btn-sm btn-dark"
            ngbTooltip="Revenire la secțiunea anterioară"
            (click)="cancelUpdateDescendants()"
          >
            Anulează
          </button>
          <button
            type="button"
            ngbTooltip="Actualizează animalul și descendenții săi"
            class="btn btn-sm btn-success float-right"
            (click)="updateDescendants()"
          >
            Actualizează
          </button>
        </div>
      </ng-template>

      <ng-template #popTitle>
        <div class="text-center">
          <h3 class="popover-title">{{ fitMessage.AfectedChildrens }}</h3>
        </div>
      </ng-template>

      <div class="col-sm-6" *ngIf="generalSectionChanged">
        <div class="form-group">
          <button
            type="button"
            class="btn btn-info"
            [ngbPopover]="popContent"
            [popoverTitle]="popTitle"
            [placement]="'end'"
            [autoClose]="false"
            popoverClass="popover-style"
            triggers="manual"
            #popover="ngbPopover"
            (click)="viewAfectedDescendants()"
          >
            <span class="btn-label"><i class="fe-help-circle"></i></span
            >Verifică descendenți
          </button>
        </div>
      </div>

      <div class="col-sm-4"><h4>REGISTRU GENEALOGIC ROMÂN</h4></div>
      <div class="col-sm-6 evaluare-incadrare">
        <a
          *ngIf="!isFermier && !disableAllFields && animalForm.value.id"
          href="javascript: void(0);"
          (click)="viewReasons(animalForm.value.id)"
        >
          Evaluare încadrare
        </a>
      </div>

      <div *ngIf="animalForm.value.id; else rgMessage" class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group mb-1">
              <label for="sectregro">
                Secțiune registru
              </label>
              <ng-select
                bindLabel="sectregro"
                formControlName="sectregro"
                [clearable]="false"
                (change)="rgSectionChanged($event)"
                [ngClass]="{
                  'is-invalid': errorMessage,
                  disabled: disableAllFields || generalSectionChanged
                    || !(isSuperAdmin || (isAdmin && animalForm.value.corespunde_standard == true))
                }"
              >
                <ng-option value="0">Neîncadrat</ng-option>
                <ng-option value="1">Principală</ng-option>
                <ng-option value="2">Suplimentară A</ng-option>
                <ng-option value="3">Suplimentară B</ng-option>
                <ng-option value="4">Suplimentară C</ng-option>
                <ng-option value="5">Suplimentară D</ng-option>
              </ng-select>
            </div>
            <div
              *ngIf="errorMessage"
              class="invalid-feedback mb-2 ml-1"
              [ngClass]="{
                'd-block': errorMessage
              }"
            >
              {{ errorMessage }}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="nrregro">
                Număr registru<span class="ml-0" *ngIf="rgRequired">*</span>
              </label>
              <input
                type="text"
                id="nrregro"
                placeholder="Număr registru"
                class="form-control"
                formControlName="nrregro"
                [ngClass]="{
                  'is-invalid': submitted && f.nrregro.errors
                }"
                [readonly]="!isAdmin || disableAllFields || rgNrDisabled"
                (input)="
                  $event.target.value = $event.target.value.toUpperCase()
                "
              />
              <div
                *ngIf="submitted && f.nrregro.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.nrregro.errors }"
              >
                <div *ngIf="f.nrregro.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="data_rg">
                Data intrării în RG<span class="ml-0" *ngIf="rgRequired"
                  >*</span
                >
              </label>

              <app-date-picker
                [selectedDate]="animalForm.controls.data_rg.value"
                [required]="rgRequired"
                [submitted]="submitted"
                [disabled]="isFermier || disableAllFields || rgDataDisabled"
                (dateChanged)="dataRgChanged($event)"
              >
              </app-date-picker>
            </div>
          </div>

          <div class="col-sm-6" *ngIf="sectionChanged">
            <div class="form-group mt-3">
              <button
                type="button"
                class="btn btn-info"
                [ngbPopover]="popContent"
                [popoverTitle]="popTitle"
                [placement]="'top'"
                [autoClose]="false"
                popoverClass="popover-style"
                triggers="manual"
                #popover="ngbPopover"
                (click)="viewAfectedDescendants()"
              >
                <span class="btn-label"><i class="fe-help-circle"></i></span>Verifică descendenți
              </button>
            </div>
          </div>
        </div>
      </div>

      <ng-template #rgMessage>
        <div class="col-sm-12 m-3 text-dark text-center">
          <h5>Acest registru va fi disponibil după ce noul animal va fi creat</h5>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<app-view-reason-modal
  [reasonModalData]="reasonModalData"
>
</app-view-reason-modal>
