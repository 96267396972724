import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Animals } from '../models/animal.models';
import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToDisplayFormat, convertDateToBackendFormat } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})

export class AnimalsTableService extends SortableTable<Animals> {
  private baseUrl = environment.API_URL;

  animale: Animals[];
  numarAnimale: number;

  constructor(private http: HttpClient) {
    super();
  }

  getAnimalsAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/animals/list/`,
      obj
    ).pipe(map((response: any) => {

      this.animale = response.result;
      this.numarAnimale = response.count;

      this.animale.map((animal: Animals) => {
        animal.datanastere = convertDateToDisplayFormat(animal.datanastere);
        animal.dataintrareinexploatatie = convertDateToDisplayFormat(animal.dataintrareinexploatatie);
      });

      this.paginate();
      this._tables$.next(this.animale);
      this._total$.next(this.numarAnimale);

      return response;
    }));
  }

  getAnimalAPI(animalId: number) {
    return this.http
      .get(
        `${this.baseUrl}/animals/${animalId}/`
      ).pipe(map((response: any) => {
        response.datanastere = convertDateToDisplayFormat(response.datanastere);
        response.dataintrareinexploatatie = convertDateToDisplayFormat(response.dataintrareinexploatatie);
        response.data_apr_feno = convertDateToDisplayFormat(response.data_apr_feno);

        return response;
      }));
  }

  getAnimalPerformance(animalId: number) {
    return this.http
      .post(
        `${this.baseUrl}/animals/performante/`,
        { id: animalId }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getPozaUrl(fileId: number) {
    return this.http
      .post(
        `${this.baseUrl}/files/geturl/`,
        { file_id: fileId }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getPozeAnimal(animalId) {
    return this.http
      .post(
        `${this.baseUrl}/animals/animal-photo/`,
        { animalId }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  deletePozaAnimal(fileId) {
    return this.http
      .post(
        `${this.baseUrl}/files/delete-animal-picture/`,
        { file_id: fileId }
      ).pipe(map(response => {
        return response;
      }));
  }

  getAnimalRegGen(animalId: number) {
    return this.http
      .post(
        `${this.baseUrl}/animals/rg/`,
        { id: animalId }
      ).pipe(map((response: any) => {
        response.data = convertDateToDisplayFormat(response.data);
        return response;
      }, errors => {
          return errors;
        }));
  }

  addAnimal(animalObj: Animals) {
    return this.http.put(
      `${this.baseUrl}/animals/save/`,
      {
        ...animalObj,
        datanastere: convertDateToBackendFormat(animalObj.datanastere),
        dataintrareinexploatatie: convertDateToBackendFormat(animalObj.dataintrareinexploatatie),
        data_apr_feno: convertDateToBackendFormat(animalObj.data_apr_feno),
        data_rg: convertDateToBackendFormat(animalObj.data_rg),
      }
    ).pipe(map((response: any) => {

      if (!this.animale) {
        return response;
      }

      if (this.animale.length > 9) {
        // Add new animal on first place in the array
        this.animale.unshift(response);
        // Remove last element form the array
        this.animale.pop();
      } else {
        this.animale.push(response);
      }

      this.animale.map(animal => {
        if (animal.id === response.id) {
          animal.family_tree = response.family_tree;
          animal.datanastere = convertDateToDisplayFormat(animal.datanastere);
        }
      });

      // Remove duplicates from array
      this.animale = this.animale.filter(animal => Object.keys(animal).length > 2);

      // Increase total number of animals
      this.numarAnimale += 1;
      return response;
    }));
  }

  updateAnimal(animalObj: Animals) {
    return this.http.put(
      `${this.baseUrl}/animals/save/${animalObj.id}/`,
      {
        ...animalObj,
        datanastere: convertDateToBackendFormat(animalObj.datanastere),
        dataintrareinexploatatie: convertDateToBackendFormat(animalObj.dataintrareinexploatatie),
        data_apr_feno: convertDateToBackendFormat(animalObj.data_apr_feno),
        data_rg: convertDateToBackendFormat(animalObj.data_rg),
      }
    ).pipe(map((response: any) => {
      if (this.animale !== undefined) {
        for (let i = 0; i < this.animale.length; i++) {
          if (this.animale[i].id === response.id) {
            this.animale[i].nume = response.nume;
            this.animale[i].status = response.status;
            this.animale[i].numarmatricol = response.numarmatricol;
            this.animale[i].datanastere = convertDateToDisplayFormat(response.datanastere);
            this.animale[i].sex = response.sex;
            this.animale[i].rase = response.rase;
            this.animale[i].codtaur = response.codtaur;
            this.animale[i].rasa_shortname = response.rasa_shortname;
          }
        }
      }
      return response;
    }));
  }

  deleteAnimal(animalId: number) {
    return this.http.post(
      `${this.baseUrl}/animals/delete/`,
      { animalid: animalId }
    ).pipe(map(response => {
      return response;
    }, errors => {
      return errors;
    }));
  }

  deleteAprFenotipicaFile(animalId) {
    return this.http
    .post(
      `${this.baseUrl}/animals/delfileaprfenotipica/`,
      { animalid: animalId }
    ).pipe(map(response => {
      return response;
    }));
  }

  deleteFisaBonitareFile(animalId) {
    return this.http
    .post(
      `${this.baseUrl}/animals/delfilefisabonitare/`,
      { animalid: animalId }
    ).pipe(map(response => {
      return response;
    }));
  }

  searchAnimal(matricol: string, sex: number) {
    return this.http.post(
      `${this.baseUrl}/animals/search/`,
      { numarmatricol: matricol, sex }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  generateFisaAnimal(animalid) {
    return this.http
    .post(
      `${this.baseUrl}/animals/fisa-animal/`,
        { animalid },
        {
          responseType: 'blob'
        }
      ).pipe((response: any) => {
      return response;
    });
  }

  downloadAnimalHistoryApi(animalid) {
    return this.http
      .post(
        `${this.baseUrl}/animals/istoric-animal/`,
        { animalid },
        {
          responseType: 'blob'
        }
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/animals/export/`,
      {
        ...obj
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  corespundeStandardAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/animals/corespunde_standard/`,
      req_params
    ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarAnimale;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarAnimale) {
      this._state.endIndex = this.numarAnimale;
    }
  }
}
