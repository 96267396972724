import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  EventEmitter,
  ViewChild,
  Output,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-add-update-fisa-bonitare',
  templateUrl: './add-update-fisa-bonitare.component.html',
  styleUrls: ['./add-update-fisa-bonitare.component.scss'],
})
export class AddUpdateFisaBonitareComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('bonitareModal') bonitareModal: ElementRef;
  modalReference: NgbModalRef;

  @Input() openBonitare = false;
  @Input() bonitareType: string;

  @Output() notifyBonClose = new EventEmitter();
  @Output() dmValue = new EventEmitter();
  @Output() dsValue = new EventEmitter();
  @Output() afValue = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();
  dmForm;
  dmControls;
  dsForm;
  dsControls;
  afForm;
  afControls;
  crForm;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes) {
    if (changes.openBonitare && this.openBonitare) {
      this.modalReference = this.modalService
        .open(
          this.bonitareModal,
          { windowClass: 'modal-half-full', scrollable: true, backdrop: 'static' }
        );
    }

    if (this.bonitareType === 'dm') {
      this.buildDMForm();
    }

    if (this.bonitareType === 'ds') {
      this.buildDSForm();
    }

    if (this.bonitareType === 'af') {
      this.buildAFForm();
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.openBonitare) {
      this.notifyCloseModal();
    }
  }

  notifyCloseModal() {
    this.openBonitare = false;
    this.notifyBonClose.emit(false);
  }

  // dezvoltare musculara form
  buildDMForm() {
    this.dmForm = new UntypedFormGroup({
      lsu: new UntypedFormControl(1),
      lsc: new UntypedFormControl(1),
      dc: new UntypedFormControl(1),
      lac: new UntypedFormControl(1),
      gs: new UntypedFormControl(1),
      totalDm: new UntypedFormControl(null)
    });
    this.dmControls = this.dmForm.controls;
    this.calculateDMValue();
  }

  // dezvoltare scheletica form
  buildDSForm() {
    this.dsForm = new UntypedFormGroup({
      pf: new UntypedFormControl(1),
      ls: new UntypedFormControl(1),
      lc: new UntypedFormControl(1),
      lcs: new UntypedFormControl(1),
      hg: new UntypedFormControl(1),
      totalDs: new UntypedFormControl(null)
    });
    this.dsControls = this.dsForm.controls;
    this.calculateDSValue();
  }

  // aptitudini functionale form
  buildAFForm() {
    this.afForm = new UntypedFormGroup({
      ec: new UntypedFormControl(1),
      ama: new UntypedFormControl(1),
      amp: new UntypedFormControl(1),
      lps: new UntypedFormControl(1),
      totalAf: new UntypedFormControl(null)
    });
    this.afControls = this.afForm.controls;
    this.calculateAFValue();
  }

  calculateDMValue(measure?) {
    switch (measure) {
      case 'lsu':
        this.dmControls.lsu.value = parseInt(this.dmControls.lsu.value, 10);
        break;
      case 'lsc':
        this.dmControls.lsc.value = parseInt(this.dmControls.lsc.value, 10);
        break;
      case 'dc':
        this.dmControls.dc.value = parseInt(this.dmControls.dc.value, 10);
        break;
      case 'lac':
        this.dmControls.lac.value = parseInt(this.dmControls.lac.value, 10);
        break;
      case 'gs':
        this.dmControls.gs.value = parseInt(this.dmControls.gs.value, 10);
        break;
    }
    if (this.dmControls) {
      const dmValue = (this.dmControls.lsu.value + this.dmControls.lsc.value +
        this.dmControls.dc.value + this.dmControls.lac.value + (this.dmControls.gs.value * 2)) * 100 / 60;
      this.dmControls.totalDm.setValue(parseFloat(dmValue.toFixed(3)));
    }
  }

  calculateDSValue(measure?) {
    switch (measure) {
      case 'pf':
        this.dsControls.pf.value = parseInt(this.dsControls.pf.value, 10);
        break;
      case 'ls':
        this.dsControls.ls.value = parseInt(this.dsControls.ls.value, 10);
        break;
      case 'lc':
        this.dsControls.lc.value = parseInt(this.dsControls.lc.value, 10);
        break;
      case 'lcs':
        this.dsControls.lcs.value = parseInt(this.dsControls.lcs.value, 10);
        break;
      case 'hg':
        this.dsControls.hg.value = parseInt(this.dsControls.hg.value, 10);
        break;
    }
    if (this.dsControls) {
      const dsValue = (this.dsControls.pf.value + this.dsControls.ls.value +
        this.dsControls.lc.value + this.dsControls.lcs.value + (this.dsControls.hg.value * 2)) * 100 / 60;
      this.dsControls.totalDs.setValue(parseFloat(dsValue.toFixed(3)));
    }
  }

  calculateAFValue(measure?) {
    switch (measure) {
      case 'ec':
        this.afControls.ec.value = parseInt(this.afControls.ec.value, 10);
        break;
      case 'ama':
        this.afControls.ama.value = parseInt(this.afControls.ama.value, 10);
        break;
      case 'amp':
        this.afControls.amp.value = parseInt(this.afControls.amp.value, 10);
        break;
      case 'lps':
        this.afControls.lps.value = parseInt(this.afControls.lps.value, 10);
        break;
    }
    if (this.afControls) {
      const afValue = (this.afControls.ec.value + this.afControls.ama.value +
        this.afControls.amp.value + this.afControls.lps.value) * 100 / 40;
      this.afControls.totalAf.setValue(parseFloat(afValue.toFixed(3)));
    }
  }

  onDMSubmitted() {
    this.notifyBonClose.emit(false);
    this.dmValue.emit(this.dmControls.totalDm.value);
    this.modalReference.close();
  }

  onDSSubmitted() {
    this.notifyBonClose.emit(false);
    this.dsValue.emit(this.dsControls.totalDs.value);
    this.modalReference.close();
  }

  onAFSubmitted() {
    this.notifyBonClose.emit(false);
    this.afValue.emit(this.afControls.totalAf.value);
    this.modalReference.close();
  }

}
