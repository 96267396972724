import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  HostListener
} from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { BuletineTableService } from 'src/app/modules/acbcr/common/services/buletine-table.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { takeUntil } from 'rxjs/operators';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-add-buletin-control',
  templateUrl: './add-buletin-control.component.html',
  styleUrls: ['./add-buletin-control.component.scss']
})
export class AddBuletinControlComponent implements OnInit, OnChanges {
  @ViewChild('addBuletinModal') addBuletinModal: ElementRef;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();
  @Input() open = false;
  @Input() modalType: string;

  modalReference: NgbModalRef;
  buletinForm: UntypedFormGroup;
  submitted = false;
  isFermier = false;
  selectedHolding;
  destroy$: Subject<boolean> = new Subject<boolean>();
  operatori;

  constructor(
    public modalService: NgbModal,
    private buletinTabelService: BuletineTableService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getSelectedHolding))
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: any) => {
      this.selectedHolding = response;
    });
    this.isFermier = UIHelper.isFermier();
  }

  ngOnChanges(changes): void {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.addBuletinModal, {
        windowClass: 'table-search-modal',
        scrollable: false,
        backdrop: 'static'
      });

      if (! this.operatori) {
        this.buletinTabelService.operatoriAPI().subscribe(
          (response) => {
            this.operatori = response;
          });
      }

      this.buildForm();
    }
  }

  buildForm() {
    this.buletinForm = new UntypedFormGroup({
      companyid: new UntypedFormControl(null),
      data_cantarire: new UntypedFormControl(null, Validators.required),
    });

    if (! this.isFermier) {
      this.buletinForm.addControl('operator_id', new UntypedFormControl(UIHelper.userId(), Validators.required))
    }

    if (this.modalType === 'add') {
      this.buletinForm.addControl('tip_buletin', new UntypedFormControl(null, Validators.required))
    } else if (this.modalType === 'atentionari' || this.modalType === 'nota-control-cppc') {
      const today = UIHelper.getCurrentDate();
      this.buletinForm.controls.data_cantarire.setValue(today);
    }

    this.submitted = false;
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  onSubmitted() {
    this.submitted = true;
    if (!this.buletinForm.valid) {
      return;
    }

    if (this.selectedHolding) {
      this.buletinForm.controls.companyid.setValue(this.selectedHolding.id);
    }

    if (this.modalType == 'add') {
      this.buletinTabelService.addBuletin(this.buletinForm.value)
        .subscribe(result => {
        this.sendCallbackResult();
        });
    } else if (this.modalType == 'atentionari') {
      let fisier = `Atentionari_fermier.pdf`;
      if (this.selectedHolding && this.selectedHolding.code) {
        fisier = `Atentionari_fermier_${this.selectedHolding.code}.pdf`;
      }

      this.buletinTabelService.downloadAtentionari(this.buletinForm.value)
        .subscribe((response: any) => {
          this.sendCallbackResult();
          const blob = new Blob([response], { type: 'application/pdf' });
          this.helperDataService.simulateDownload(blob, fisier);
        });
    } else if (this.modalType == 'nota-control-cppc') {
      let fisier = `Nota_control_CPPC.pdf`;
      if (this.selectedHolding && this.selectedHolding.code) {
        fisier = `Nota_control_CPPC_${this.selectedHolding.code}.pdf`;
      }

      this.buletinTabelService.downloadNotaControlCPPC(this.buletinForm.value)
        .subscribe((response: any) => {
          this.sendCallbackResult();
          const blob = new Blob([response], { type: 'application/pdf' });
          this.helperDataService.simulateDownload(blob, fisier);
        });
    }
  }

  get f() { return this.buletinForm.controls; }

  onDataCantarireChanged(event) {
    if (this.buletinForm) {
      this.buletinForm.controls.data_cantarire.setValue(event);
    }
  }

  notifyCloseModal() {
    this.notifyClose.emit(false);
  }

  private sendCallbackResult() {
    this.modalReference.close();
    this.callbackResult.emit('');
    this.notifyClose.emit(false);
  }
}
