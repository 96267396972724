import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-furajare',
  templateUrl: './management-furajare.component.html',
  styleUrls: ['./management-furajare.component.scss']
})
export class ManagementFurajareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
