<form [formGroup]="reproductionForm" (ngSubmit)="onSubmitted()">
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12">
        <div class="card">
          <h3 class="card-header" *ngIf="reprModalType === addReproduction; else editTitle">Reproducție nouă</h3>
          <ng-template #editTitle>
            <h3 class="card-header">{{isAutoritateJudeteana || isContabil ? 'Vizualizează reproducție' : 'Editează reproducție'}}</h3>
          </ng-template>

          <div class="card-body" [ngClass]="{'disabled': isAutoritateJudeteana || isContabil}">
            <div class="row">

              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="system">
                    Sistem de reproducție*
                  </label>

                  <ng-select
                    (change)="changeRepSystem($event)"
                    formControlName="system_rep"
                    [clearable]="false"
                  >
                    <ng-option value="1">Însămânțare artificială</ng-option>
                    <ng-option value="2">Montă naturală autorizată</ng-option>
                    <ng-option value="3">Embriotransferuri</ng-option>
                  </ng-select>

                </div>
              </div>

              <div class="col-sm-12" *ngIf="artificialSystemFields">
                <div class="form-group mb-3">
                  <label for="system">
                    Operator însămânțare*
                  </label>

                  <ng-select
                    [items]="operatoriInsamantare"
                    bindLabel="viewLabel"
                    bindValue="cod"
                    (search)="searchOperator($event)"
                    formControlName="op_ins"
                    placeholder="Selectează operator"
                    [ngClass]="{'is-invalid': submitted && f.op_ins.errors}"
                  >
                  </ng-select>
                  <div
                    *ngIf="submitted && f.op_ins.errors"
                    class="invalid-feedback"
                    [ngClass]="{ 'd-block': submitted && f.op_ins.errors }"
                  >
                    <div *ngIf="f.op_ins.errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-sm-12" *ngIf="naturalSystemFields">
                <div class="form-group mb-3">
                  <label for="period">
                    Dată montă*
                  </label>

                  <div class="d-flex flex-wrap">
                    <div class="mr-4">
                      <!-- formControlName = date -->
                      <app-date-picker
                        [required]="true"
                        [submitted]="submitted"
                        [selectedDate]="f.date.value"
                        (dateChanged)="dataMontaChange($event)"
                      >
                      </app-date-picker>
                      <small
                        class="form-text text-muted"
                      >
                        <div class="mb-2">
                          <span *ngIf="!showDateErrors; else dateError">Data de start a montei</span>

                          <ng-template #dateError>
                            <span class="red">Data de start a montei este mai mare decât data de final</span>
                          </ng-template>
                        </div>
                      </small>
                    </div>

                    <div class="">
                      <!-- formControlName = date_end -->
                      <app-date-picker
                        [required]="true"
                        [submitted]="submitted"
                        [selectedDate]="f.date_end.value"
                        (dateChanged)="dataEndMontaChange($event)"
                      >
                      </app-date-picker>
                       <small
                        class="form-text text-muted"
                      >
                        <div class="mb-2">
                          <span>Data de final a montei</span>
                        </div>
                      </small>
                    </div>
                  </div>

                </div>
              </div>

              <div
                *ngIf="showIntervalWarning"
                class="col-sm-12"
              >
                <div class="alert alert-warning">
                  Intervalul nu este acoperit în totalitate de autorizația de montă.
                </div>
              </div>

              <div class="col-sm-6 col-lg-6" *ngIf="!naturalSystemFields">
                <div class="form-group mb-3">
                  <label for="period">
                    Dată inseminare*
                  </label>
                  <!-- formControlName = date -->
                  <app-date-picker
                    [required]="true"
                    [submitted]="submitted"
                    [selectedDate]="f.date.value"
                    (dateChanged)="dataMontaChange($event)"
                  >
                  </app-date-picker>

                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label for="taur">
                    Taur - număr matricol*
                  </label>

                  <ng-select
                    [items]="bulls"
                    bindLabel="view_label"
                    placeholder="Introduceți matricol taur"
                    formControlName="taur"
                    bindValue="numarmatricol"
                    [searchFn]="searchBull"
                    [ngClass]="{
                      disabled: !isSuperAdmin && f.are_evaluare_genetica.value,
                      'is-invalid': submitted && f.taur.errors
                    }"
                    (change)="selectTaur()"
                  >
                  </ng-select>
                  <small
                    class="form-text text-muted"
                  >
                    <div class="mb-2">
                      <span>
                        Cautarea taurului se poate face după cod sau numărul matricol
                      </span>
                    </div>
                  </small>

                  <div
                    *ngIf="submitted && f.taur.errors"
                    class="invalid-feedback"
                    [ngClass]="{ 'd-block': submitted && f.taur.errors }"
                  >
                    <div *ngIf="f.taur.errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div *ngFor="let error of taurErrors" class="alert alert-danger" role="alert">
                  {{ error }}
                </div>
                <div *ngFor="let warning of taurWarnings" class="alert alert-warning" role="alert">
                  {{ warning }}
                </div>
              </div>

              <div
                *ngIf="reproductionForm.controls.system_rep.value === '2'"
                class="col-12 mb-3"
              >
                <label class="mb-1">Autorizație Montă</label>

                <table
                  *ngIf="autorizatiiMonta && autorizatiiMonta.length > 0"
                  class="autorizatii"
                >
                  <thead>
                    <th></th>
                    <th>Număr / Dată</th>
                    <th>Emitent</th>
                    <th>Perioada</th>
                    <th>Fișier</th>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let autorizatie of autorizatiiMonta; let index=index"
                    >
                      <td>
                        <input
                          type="checkbox"
                          [checked]="reproductionForm.controls.autorizatie_id.value === autorizatie.id"
                          (click)="selectAutorizatie($event, autorizatie.id)"
                        />
                      </td>
                      <td>{{ autorizatie.nr_document }} / {{ autorizatie.data_document_dmy }}</td>
                      <td>{{ autorizatie.emitent }}</td>
                      <td>{{ autorizatie.data_inceput_dmy }} - {{ autorizatie.data_sfarsit_dmy }}</td>
                      <td>
                        <div *ngIf="autorizatie.file_name">
                          <button
                            type="button"
                            class="btn p-0 text-blue font-weight-bold"
                            (click)="downloadAutorizatieFile(autorizatie)"
                          >
                            <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                            {{ autorizatie.file_name }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  *ngIf="submitted && f.autorizatie_id.errors?.required"
                  class="invalid-feedback d-block"
                >
                  Alegerea unei autorizații este obligatorie.
                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="femela" *ngIf="embrioSystemFields; else montaNormala">
                    Femelă donatoare - număr matricol*
                  </label>

                  <ng-template #montaNormala>
                    <label>Femelă - număr matricol*</label>
                  </ng-template>

                  <ng-select
                    *ngIf="naturalSystemFields"
                    bindLabel="numarmatricol"
                    bindValue="numarmatricol"
                    placeholder="Introduceți matricol femelă"
                    formControlName="femela"
                    [items]="cows"
                    [ngClass]="{
                      'disabled': !isSuperAdmin && f.are_evaluare_genetica.value,
                      'is-invalid': submitted && f.femela.errors
                    }"
                    [multiple]="naturalSystemFields"
                    [closeOnSelect]="!naturalSystemFields"
                    [hideSelected]="naturalSystemFields"
                  >
                  </ng-select>

                  <ng-select
                    *ngIf="!naturalSystemFields"
                    placeholder="Introduceți matricol femelă"
                    formControlName="femela"
                    bindLabel="numarmatricol"
                    bindValue="numarmatricol"
                    [items]="embrioSystemFields ? donorCows : cows"
                    [ngClass]="{
                      'disabled': !isSuperAdmin && f.are_evaluare_genetica.value,
                      'is-invalid': submitted && f.femela.errors
                    }"
                  >
                  </ng-select>

                  <div
                    *ngIf="submitted && f.femela.errors"
                    class="invalid-feedback"
                    [ngClass]="{ 'd-block': submitted && f.femela.errors }"
                  >
                    <div *ngIf="f.femela.errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12" *ngIf="embrioSystemFields">
                <div class="form-group mb-3">
                  <label for="taur">
                    Femelă purtătoare - număr matricol*
                  </label>

                  <ng-select
                    [items]="cows"
                    [ngClass]="{
                      'disabled': reproductionForm.controls.date.value?.length < 1 && embrioSystemFields,
                      'is-invalid': submitted && f.femela_purtatoare.errors
                    }"
                    bindLabel="numarmatricol"
                    bindValue="numarmatricol"
                    placeholder="Introduceți matricol femelă purtătoare"
                    formControlName="femela_purtatoare"
                  >
                  </ng-select>
                  <div
                    *ngIf="submitted && f.femela_purtatoare.errors"
                    class="invalid-feedback"
                    [ngClass]="{ 'd-block': submitted && f.femela_purtatoare.errors }"
                  >
                    <div *ngIf="f.femela_purtatoare.errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                </div>

                </div>
              </div>

              <div class="separator mb-2"></div>

              <div class="col-sm-12 mb-3" [ngClass]="{'col-md-6': fileId}" *ngIf="!disableAutReproductie">
                <div
                  *ngIf="naturalSystemFields"
                  class="form-group"
                >
                  <label for="autorization">
                    Autorizație pentru montă
                  </label>

                  <app-upload-fisier
                    class="ml-3"
                    (ApiResponse)="uploadMontaNaturala($event)">
                  </app-upload-fisier>
                </div>

                <div
                  *ngIf="artificialSystemFields || embrioSystemFields"
                  class="form-group"
                >
                  <label for="doc-date">
                    Buletin de însămânțare
                  </label>

                  <app-upload-fisier
                    class="ml-3"
                    (ApiResponse)="uploadInsamantareArtificiala($event)">
                  </app-upload-fisier>
                </div>

                <div
                  *ngIf="errorFileBuletin"
                  class="text-danger mt-2"
                >
                  Lipsește fișierul.
                </div>
              </div>

              <div class="col-sm-6 col-lg-6" *ngIf="fileId">
                <div class="form-group mb-3">
                  <label for="stampila">
                    Document
                  </label>
                  <ul class="images-list">
                    <li>
                      <span (click)="downloadDocument()">
                        <i
                          class="mdi mdi-file-download"
                          ngbTooltip="Descarcă document"
                          placement="left"
                        >
                        </i>
                        <span class="file-name">
                          {{fileName}}
                        </span>

                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6 col-lg-6" *ngIf="!naturalSystemFields">
                <div class="form-group mb-3">
                  <label for="doc_no">
                    Document Nr.*
                  </label>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Introduceți numărul documentului"
                    formControlName="doc_no"
                    [ngClass]="{'is-invalid': submitted && f.doc_no.errors}"
                  />

                  <div
                    *ngIf="submitted && f.doc_no.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.doc_no.errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

              <div class="separator mb-3"></div>

              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="state">
                    Starea înregistrării*
                  </label>

                  <ng-select
                    formControlName="stare_anexa"
                    class="starea_inregistrarii"
                    [clearable]="false"
                    [ngClass]="{'is-invalid': submitted && f.stare_anexa.errors, 'disabled': isFermier }"
                  >
                    <ng-option value="0">În așteptare</ng-option>
                    <ng-option value="1">Verificare</ng-option>
                    <ng-option value="2">Operare</ng-option>
                    <ng-option value="3">Validat</ng-option>
                    <ng-option value="4">Refuzat</ng-option>
                  </ng-select>

                  <div
                    *ngIf="submitted && f.stare_anexa.errors"
                    class="invalid-feedback"
                    [ngClass]="{ 'd-block': submitted && f.stare_anexa.errors }"
                  >
                    <div *ngIf="f.stare_anexa.errors.required">
                      Completarea acestui câmp este obligatorie.
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </div>

        <div class="col-sm-12 ml-2">
          <button
            class="btn btn-info"
            type="button"
            (click)="closeFormMethod()"
          >
            {{isAutoritateJudeteana || isContabil ? 'Închide' : 'Anulează'}}
          </button>

          <button
            *ngIf="reprModalType == editReproduction && (isAdmin || isOperator || (isFermier && f.stare_anexa.value == '0'))"
            class="btn btn-danger"
            type="button"
            (click)="openConfirmActionModal()"
          >
            Șterge
          </button>

          <button
            *ngIf="isAdmin || isOperator || (isFermier && f.stare_anexa.value == '0')"
            class="btn btn-success float-right"
            [disabled]="taurErrors?.length > 0"
            type="submit"
          >
            <span *ngIf="reprModalType === addReproduction; else editBtn">
              Salvează
            </span>

            <ng-template #editBtn>
              <span>Actualizează</span>
            </ng-template>
          </button>
        </div>

      </div>
    </div>
  </div>
  </div>
</form>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-repr'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
