import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { AdeverintaAjutorService } from 'src/app/modules/acbcr/common/services/adeverinta-ajutor.service'

@Component({
  selector: 'app-adeverinta-ajutor',
  templateUrl: './adeverinta-ajutor.component.html',
  styleUrls: ['./adeverinta-ajutor.component.scss']
})
export class AdeverintaAjutorComponent implements OnInit {
  @ViewChild('searchModal') searchModal: ElementRef;
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  loadingDataSpinner = false;
  addEditModalData;
  searchForm: UntypedFormGroup;
  totalCount: number;
  itemsList = [];
  exploatatii = [];
  openDelete = false;
  showHint = true;
  idDelete;
  req_params = {
    page_no: 1,
    page_size: 10,
  };

  constructor(
    public adeverintaAjutorService: AdeverintaAjutorService,
    private modalService: NgbModal,
    private helperDataService: HelperDataService,
  ) {}

  ngOnInit() {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.searchForm = new UntypedFormGroup({
      start_date: new UntypedFormControl(),
      end_date: new UntypedFormControl(),
      exploatatie_id: new UntypedFormControl(),
      rasa_id: new UntypedFormControl(),
      status: new UntypedFormControl(),
    });

    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.itemsList = [];
    let list_params = {
      ...this.req_params,
      ...this.searchForm.value
    };

    this.adeverintaAjutorService.listAPI(list_params)
      .subscribe({
        next: (response) => {
          this.loadingDataSpinner = false;
          this.itemsList = response.result;
          this.totalCount = response.count;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
        }
      });
  }

  changePageSize(event) {
    this.req_params.page_no = 1;
    this.req_params.page_size = event.target.value;
    this.adeverintaAjutorService.page = 1;
    this.getTableData();
  }

  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  openAddModal() {
    this.addEditModalData = {id: null};
  }

  openEditModal(item) {
    this.addEditModalData = {id: item?.id};
  }

  openDeleteModal(id) {
    this.openDelete = true;
    this.idDelete = id;
  }

  closeAddEditModal() {
    this.ngOnInit();
  }

  closeDeleteModal(event) {
    this.openDelete = false;

    if (event != true) {
      return;
    }

    let buton = document.getElementsByClassName('sterge-id-' + this.idDelete)[0];
    buton?.setAttribute('disabled', 'true');
    let params = {
      id: this.idDelete,
    }

    this.adeverintaAjutorService.stergeAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
        }
      });
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.showHint = true;
      this.exploatatii = [];

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        }
      });
  }

  cauta() {
    this.req_params.page_no = 1;
    this.adeverintaAjutorService.page = 1;
    this.getTableData();
  }

  resetFields() {
    if (this.searchForm) {
      this.searchForm.reset();
    }
  }

  resetSearch() {
    this.resetFields();
    this.cauta();
  }

  download(event, item) {
    event.target.disabled = true;
    let nr = item.numar || '';
    let fisier = `Adeverinta_Ajutor_Ucraina_${nr}_${item.data_dmy}.pdf`;

    this.adeverintaAjutorService.downloadAPI({id: item.id})
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }

  export(event) {
    event.target.disabled = true;
    let export_params = this.searchForm.value;
    let now = UIHelper.now();
    let fisier = `Adeverinta_Ajutor_Ucraina_${now}.xls`;

    this.adeverintaAjutorService.exportAPI(export_params)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }
}
