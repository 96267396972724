<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="adeverintaApiaService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changePageSize($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  *ngIf="!isFermier"
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="clickSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>

                <button
                  class="btn btn-blue btn-sm"
                  type="button"
                  (click)="openAddAdeverintaModal('add')"
                  [disabled]="loadingDataSpinner"
                >
                  <i class="fe-save"></i>
                  Crează adeverință
                </button>

                <button
                  *ngIf="isAdmin || isOperator"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="export()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <div
            class="spinner-border text-info table-spinner avatar-lg"
            role="status"
            *ngIf="loadingDataSpinner"
          ></div>

          <!-- Table -->
          <div class="table-responsive" #adeverinteApartenentaTable>
            <table
              id="adeverinte-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="cod_exploatatie" (sort)="onSort($event)">
                    Cod exploatație
                  </th>
                  <th sortable="nume_exploatatie" (sort)="onSort($event)">
                    Nume exploatație
                  </th>
                  <th sortable="numar" (sort)="onSort($event)" class="numar">
                    Număr
                  </th>
                  <th sortable="data" (sort)="onSort($event)" class="data">
                    Data
                  </th>
                  <th sortable="status" (sort)="onSort($event)">
                    Status
                  </th>
                  <th sortable="autor" (sort)="onSort($event)">
                    Autor
                  </th>
                  <th scope="col">
                    Acțiuni
                  </th>
                </tr>
              </thead>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let adeverinta of listaAdeverinte">
                  <td>{{ adeverinta.cod_exploatatie }}</td>
                  <td>
                    <a
                      [routerLink]="'/dashboard/exploatatie/' + adeverinta.cod_exploatatie +'/animale-exploatatie'"
                      target="_blank"
                    > {{ adeverinta.nume_exploatatie }}
                    </a>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control read-only-form"
                      [readonly]="isFermier || (!isSuperAdmin && adeverinta.status != 1)"
                      (change)="adeverinta.numar = $event.target.value"
                      [value]="adeverinta.numar"
                    />
                  </td>
                  <td>
                    <div>
                      <app-date-picker
                        [selectedDate]="adeverinta.data"
                        (dateChanged)="adeverinta.data = $event"
                        [disabled]="isFermier || (!isSuperAdmin && adeverinta.status != 1)"
                      ></app-date-picker>
                    </div>
                  </td>
                  <td>{{ adeverinta.status_text }}</td>
                  <td>{{ adeverinta.nume_autor }}</td>

                  <td class="action-buttons">
                    <div class="btn-group">

                      <button
                        class="btn btn-sm btn-success"
                        (click)="downloadAdeverinta($event, adeverinta)"
                      >
                        Descarcă
                      </button>

                      <button
                        *ngIf="isSuperAdmin || ((isAdmin || isOperator) && adeverinta.status == 1)"
                        class="btn btn-sm btn-info"
                        (click)="salveazaNumarData(adeverinta)"
                      >
                        Salvează
                      </button>

                      <button
                        *ngIf="isSuperAdmin || ((isAdmin || isOperator) && adeverinta.status == 1)
                          || (isFermier && adeverinta.status == 1 && userId == adeverinta.autor_id)
                        "
                        class="btn btn-sm btn-blue"
                        (click)="editeaza(adeverinta)"
                      >
                        Editează
                      </button>

                      <button
                        *ngIf="(isAdmin || isOperator) && adeverinta.status == 1"
                        class="btn btn-sm btn-primary"
                        [ngClass]="'finalizeaza-id-' + adeverinta.id"
                        (click)="clickFinalizeazaAdeverinta($event, adeverinta.id)"
                      >
                        Finalizează
                      </button>

                      <button
                        *ngIf="(isAdmin || isOperator) && adeverinta.status == 2"
                        class="btn btn-sm btn-danger"
                        [ngClass]="'anuleaza-id-' + adeverinta.id"
                        (click)="clickAnuleazaAdeverinta($event, adeverinta.id)"
                      >
                        Anulează
                      </button>

                      <button
                        *ngIf="isSuperAdmin || ((isAdmin || isOperator) && adeverinta.status == 1)
                          || (isFermier && adeverinta.status == 1 && userId == adeverinta.autor_id)
                        "
                        class="btn btn-sm btn-danger"
                        [ngClass]="'sterge-id-' + adeverinta.id"
                        (click)="clickStergeAdeverinta($event, adeverinta.id)"
                      >
                        Șterge
                      </button>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <ngb-alert
              type="danger"
              class="text-center search-error"
              *ngIf="!loadingDataSpinner && adeverintaApiaService.totalRecords === 0"
              [dismissible]="false"
            >
              Nu există înregistrări
            </ngb-alert>

          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="adeverintaApiaService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ adeverintaApiaService.startIndex }} la
                {{ adeverintaApiaService.endIndex }} din
                {{ adeverintaApiaService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="numarAdeverinte"
                  [(page)]="adeverintaApiaService.page"
                  [pageSize]="adeverintaApiaService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changePageNumber($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-edit-adeverinta-apia
  [addEditModalType]="addEditModalType"
  [addEditModalData]="addEditModalData"
  (notifyClose)="closeAddEditModal($event)"
>
</app-add-edit-adeverinta-apia>


<ng-template #searchModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Caută Adeverințe APIA
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="AdeverintaAPIAForm">

        <div class="row">
          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="start_date">
                      Dată început
                  </label>
                  <app-date-picker
                    [selectedDate]="AdeverintaAPIAForm.controls.start_date.value"
                    (dateChanged)="onStartDateChanged($event)">
                  </app-date-picker>
              </div>
          </div>

          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="end_date">
                      Dată sfârșit
                  </label>
                  <app-date-picker
                    [selectedDate]="AdeverintaAPIAForm.controls.end_date.value"
                    (dateChanged)="onEndDateChanged($event)">
                  </app-date-picker>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="company_name">
                Denumirea exploatației
              </label>

              <input
                type="text"
                id="company_name"
                formControlName="company_name"
                placeholder="Adaugă denumirea exploatației"
                class="form-control" />
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="company_code">
                Codul exploatației
              </label>

              <input
                type="text"
                id="company_code"
                formControlName="company_code"
                placeholder="Adaugă codul exploatației"
                class="form-control" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="rasa">
                      Rasă
                  </label>
                  <ng-select formControlName="rasa" placeholder="Toate">
                      <ng-option value='Charolaise'>Charolaise</ng-option>
                      <ng-option value='Limousine'>Limousine</ng-option>
                  </ng-select>
              </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
                <label for="status">
                    Status
                </label>
                <ng-select formControlName="status" placeholder="Toate">
                    <ng-option value='1'>Salvată</ng-option>
                    <ng-option value='2'>Finalizată</ng-option>
                    <ng-option value='3'>Anulată</ng-option>
                </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
            <div class="btn-group">
              <button class="btn btn-sm btn-blue" (click)="cauta(); modal('Cross click')">
                <i class="fe-search"></i>
                Caută
              </button>
              <button class="btn btn-sm btn-warning" (click)="resetFields()">
                <i class="fe-delete"></i>
                Resetează câmpurile
              </button>
            </div>
          </div>

          <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
            <button class="btn btn-sm btn-danger" (click)="resetSearch(); modal('Cross click')">
              Resetează căutarea
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>
</ng-template>


<ng-template #cancelDeleteModal let-modal="close">
  <div class="modal-header">

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click'); confirmareStergeAdeverinta()"
    >
      ×
    </button>
  </div>

  <div class="modal-body confirmation-modal-body">
    <div class="modal-text">
      <h3 class="confirm-text">Sunteți sigur că doriți să ștergeți adeverința?</h3>
    </div>

    <div class="separator"></div>

    <div class="container-fluid confirm-modal text-center">
      <button
        class="btn btn-warning mr-4"
        (click)="modal('Cross click'); confirmareStergeAdeverinta()"
      >
        Nu
      </button>
      <button
        class="btn btn-blue"
        (click)="modal('Cross click'); confirmareStergeAdeverinta(true)"
      >
        Da
      </button>
    </div>
  </div>
</ng-template>


<ng-template #finalizeazaModal let-modal="close">
  <div class="modal-header">

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click'); confirmareFinalizeazaAdeverinta()"
    >
      ×
    </button>
  </div>

  <div class="modal-body confirmation-modal-body">
    <div class="modal-text">
      <h3 class="confirm-text">Sunteți sigur că doriți să finalizați adeverința?</h3>
      <h4 class="text-danger">După ce adeverința a fost finalizată, ea nu mai poate fi modificată!</h4>
    </div>

    <div class="separator"></div>

    <div class="container-fluid confirm-modal text-center">
      <button
        class="btn btn-warning mr-4"
        (click)="modal('Cross click'); confirmareFinalizeazaAdeverinta()"
      >
        Nu
      </button>
      <button
        class="btn btn-blue"
        (click)="modal('Cross click'); confirmareFinalizeazaAdeverinta(true)"
      >
        Da
      </button>
    </div>
  </div>
</ng-template>


<ng-template #anuleazaModal let-modal="close">
  <div class="modal-header">

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click'); confirmareAnuleazaAdeverinta()"
    >
      ×
    </button>
  </div>

  <div class="modal-body confirmation-modal-body">
    <div class="modal-text">
      <h3 class="confirm-text">Anulează adeverința?</h3>
      <br>
    </div>

    <div class="separator"></div>

    <div class="container-fluid confirm-modal text-center">
      <button
        class="btn btn-warning mr-4"
        (click)="modal('Cross click'); confirmareAnuleazaAdeverinta()"
      >
        Nu
      </button>
      <button
        class="btn btn-blue"
        (click)="modal('Cross click'); confirmareAnuleazaAdeverinta(true)"
      >
        Da
      </button>
    </div>
  </div>
</ng-template>
