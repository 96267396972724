import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import {
  AnimalRase,
  TreeNode,
  Breed
} from 'src/app/modules/acbcr/common/models/animal.models';
import { getAppAnimalsRases } from 'src/app/modules/acbcr/common/state/reducers/animals.reducers';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { TreeHelper } from '../../../../../../common/helper/tree-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { AnimalHelper } from 'src/app/modules/acbcr/components/common/helper/animal-helper';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-pedigree-form-block',
  templateUrl: './pedigree-form-block.component.html',
  styleUrls: ['./pedigree-form-block.component.scss']
})
export class PedigreeFormBlockComponent implements OnInit, OnChanges, OnDestroy {
  @Input() animalForm: UntypedFormGroup;
  @Input() evaluareGenetica: any;
  @Input() areFatare: boolean;
  @Input() submitted: boolean;
  @Input() updatePedigreeTree: number;
  @Input() disableAllFields: boolean;
  @Output() adaugaRasa = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();
  animalFamilyTree: TreeNode[];
  raseAnimal: AnimalRase[];

  openAddEditModal = false;
  animalData = null;
  modalType: string;

  selectedNode = null;

  isAdmin = false;
  isSuperAdmin = false;
  isFermier = false;
  isNumarMatricol = false;
  eligibilitateMessage = [];
  disableRasa = false;
  autoRaseDisabled = false;

  constructor(
    private store: Store<ApplicationState>,
    private animalsTableService: AnimalsTableService,
    private errorService: NotificationErrorService
  ) {
    this.animalFamilyTree = [];
  }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isFermier = UIHelper.isFermier();

    this.store.pipe(select(getAppAnimalsRases))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: AnimalRase[]) => {
        this.raseAnimal = response;
        this.calculateParentsBreed(this.animalFamilyTree[0]);
      });

    if (this.animalForm.value.numarmatricol !== '') {
      this.isNumarMatricol = true;
    }

    if (this.animalForm.value.eligibilitatecz?.length > 0) {
      this.eligibilitateMessage = this.animalForm.value.eligibilitatecz;
    }
  }

  ngOnChanges(changes) {
    if (changes.updatePedigreeTree && this.animalFamilyTree.length > 0) {
        this.animalFamilyTree[0].numarmatricol = this.animalForm.value.numarmatricol.toUpperCase();
        this.animalFamilyTree[0].sex = this.animalForm.value.sex === '1' ? 'M' : 'F';
        this.animalFamilyTree[0].datanastere = this.animalForm.value.datanastere;
        this.animalFamilyTree[0].nume = this.animalForm.value.nume;
        this.animalFamilyTree[0].codtaur = this.animalForm.value.codtaur;

        let sectiuneRG = '';
        switch (this.animalForm.value.sectregro) {
          case '0': sectiuneRG = '-';
                    break;
          case '1': sectiuneRG = 'Principală';
                    break;
          case '2': sectiuneRG = 'Suplimentară A';
                    break;
          case '3': sectiuneRG = 'Suplimentară B';
                    break;
          case '4': sectiuneRG = 'Suplimentară C';
                    break;
          case '5': sectiuneRG = 'Suplimentară D';
                    break;
          default: sectiuneRG = '-';
        }
        this.animalFamilyTree[0].sectiune_rg = sectiuneRG;
    }

    if (changes.animalForm) {
      this.updateFamilyTree();

      if (this.raseAnimal) {
        this.calculateParentsBreed(this.animalFamilyTree[0]);
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  get formData(): UntypedFormArray {
    return (this.animalForm.get('rase') as UntypedFormArray);
  }

  get f() {
    return this.animalForm.controls;
  }

  rasaVal(event?, index?) {
    let sum = 0;
    this.formData.value.map(rasa => sum += parseInt(rasa.val, 10));
    if (sum > 100) {
      event.target.value = 100 - (sum - event.target.value);
      const formGroup = this.formData.controls[index] as UntypedFormGroup;
      formGroup.controls.val.setValue(parseInt(event.target.value, 10));
      this.disableRasa = true;
      this.errorService.processErrorMsg('procent rasa mai mare decat 100');
    } else {
      this.disableRasa = false;
    }
  }

  changeRasaAnimal() {
    this.animalFamilyTree[0].rasa = this.getRaseString(this.formData.value);
  }

  addAnimalBreed() {
    this.adaugaRasa.emit('add');
  }

  removeAnimalBreed(index) {
    this.adaugaRasa.emit({ action: 'delete', fields: index });
    this.changeRasaAnimal();
    this.rasaVal();
  }

  deleteParent(event: MouseEvent, node) {
    event.stopPropagation();

    node.numarmatricol = node.father === 'T' ? 'Adaugă tată' : 'Adaugă mamă';
    node.children = undefined;
    node.id = 0;

    delete node.sex;
    delete node.rasa;
    delete node.nume;
    delete node.sectiune_rg;
    delete node.datanastere;
    delete node.codtaur;

    this.calculateParentsBreed(this.animalFamilyTree[0]);
  }

  onNodeSelect(event) {
    if (this.isFermier || this.disableAllFields || this.areFatare) {
      return;
    }

    this.selectedNode = null;
    // If user doesn't click on his parents, don't open the modal.
    if (this.animalForm.value.numarmatricol === event.node.numarmatricol ||
      TreeHelper.isParentEditable(this.animalFamilyTree[0], event.node.numarmatricol, event.node.father) !== true
    ) { return; }

    this.selectedNode = event.node;
    if (event.node.numarmatricol.includes('mamă')) {
      this.modalType = 'add-mother';
      this.openAddEditModal = true;
    } else if (event.node.numarmatricol.includes('tată')) {
      this.modalType = 'add-father';
      this.openAddEditModal = true;
    }

    if (event.node.sex === 'F') {
      this.modalType = 'edit-mother';
      this.openEditParentModal(event.node.id);
    } else if (event.node.sex === 'M') {
      this.modalType = 'edit-father';
      this.openEditParentModal(event.node.id);
    }
  }

  closeAddUpdateModal(event) {
    this.openAddEditModal = false;
    if (event && event !== false && this.selectedNode) {
      this.selectedNode.type = 'parent';
      this.selectedNode.id = event.id;
      this.selectedNode.numarmatricol = event.numarmatricol;
      this.selectedNode.datanastere = event.datanastere;
      this.selectedNode.sectiune_rg = event.sectiune_rg;
      this.selectedNode.nume = event.nume;
      this.selectedNode.rasa = this.getRaseString(event.rase);
      this.selectedNode.children = event.family_tree.children;

      if (event.sex === '1') {
        this.selectedNode.sex = 'M';
        this.selectedNode.father = 'T';
        this.selectedNode.codtaur = event.codtaur;
      } else {
        this.selectedNode.sex = 'F';
        this.selectedNode.father = 'M';
      }

      TreeHelper.createTree(this.animalFamilyTree[0]);
      this.f.family_tree.setValue(this.animalFamilyTree[0]);
      this.calculateParentsBreed(this.animalFamilyTree[0]);
      this.changeRasaAnimal();
    }
  }

  private updateFamilyTree() {
    this.animalFamilyTree = [];

    if (this.animalForm.value.family_tree &&
      Object.keys(this.animalForm.value.family_tree).length > 0
    ) {
      this.animalFamilyTree.push(this.animalForm.value.family_tree);
      this.animalFamilyTree.map(tree => {
        TreeHelper.createTree(tree);
      });
    } else {
      this.animalFamilyTree.push({
        numarmatricol: '',
        sex: this.animalForm.value.sex === '1' ? 'M' : 'F',
        type: 'animal',
        rasa: '',
        sectiune_rg: '-',
        expanded: true,
        children: [
          {
            numarmatricol: 'Adaugă tată',
            type: 'parent',
            father: 'T',
          },
          {
            numarmatricol: 'Adaugă mamă',
            type: 'parent',
            father: 'M',
          }
        ]
      });
    }
  }

  private openEditParentModal(id) {
    this.animalsTableService.getAnimalAPI(id)
      .subscribe(response => {
        this.animalData = response;
        this.openAddEditModal = true;
      });
  }

  private calculateParentsBreed(tree) {
    const rasaTata = AnimalHelper.getRaseSplit(tree.children[0].rasa);
    const rasaMama = AnimalHelper.getRaseSplit(tree.children[1].rasa);

    const totalRaseSum = AnimalHelper.getRaseSum(rasaTata) +  AnimalHelper.getRaseSum(rasaMama);
    if (totalRaseSum > 199.900) {
      const raseMap = AnimalHelper.getRaseMap(rasaTata.concat(rasaMama));
      const raseArr: Breed[] = [];

      // Override map iterator to get entries in descendig order by value
      raseMap[Symbol.iterator] = function*() {
        yield* [...this.entries()].sort((a, b) => b[1] - a[1]);
      };

      for (const [key, val] of raseMap) {
        const half = val / 2;
        raseArr.push({raceid: this.getRasaId(key), val: half });
      }

      const fb = new UntypedFormBuilder();
      this.f.rase = fb.array([...AnimalHelper.createItems(raseArr.length)]);
      this.f.rase.setValue(raseArr);
      this.animalForm.updateValueAndValidity();

      this.autoRaseDisabled = true;
    } else {
      this.autoRaseDisabled = false;
    }
  }

  private getRasaId(rasaShortname: string): number {
    return this.raseAnimal.find(rasa => rasa.shortname === rasaShortname)?.id;
  }

  private getRaseString(arr): string {
    let raseStr = '';
    arr.forEach((rasa, index) =>
      raseStr += (this.getRasaShortname(rasa.raceid) +
        (rasa.val !== null ? rasa.val : '') +
          (index < arr.length - 1 ? ',' : '')));

    return raseStr;
  }

  private getRasaShortname(rasaId): string {
    if (this.raseAnimal.length > 0 && rasaId) {
      return this.raseAnimal.find(rasa => rasa.id === rasaId)?.shortname;
    } else {
      return '';
    }
  }

}
