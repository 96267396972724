import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, OnChanges, OnDestroy, HostListener } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppCountries, getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { HelperDataService } from './../../../../../common/services/helper-data.service';
import { HoldingsTableService } from './../../../../../common/services/holdings-table.service';
import { AddCompanyAdminData, UpdateCompanyAdminData } from './../../../../../common/state/actions/company-admins.actions';
import { CnpCuiValidatorService } from './../../../../../common/services/cnp-cui-validator.service';

@Component({
  selector: 'app-add-update-holding-admin',
  templateUrl: './add-update-holding-admin.component.html',
  styleUrls: ['./add-update-holding-admin.component.scss']
})
export class AddUpdateHoldingAdminComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('holdingAdminModal') holdingAdminModal: ElementRef;
  modalReference: NgbModalRef;

  @ViewChild('smallCitiesDropDown') smallCitiesDropDown: NgSelectComponent;
  @Output() notifyClose = new EventEmitter();

  @Input() open = false;
  @Input() modalType: string;
  @Input() selectedAdmin;
  adminForm;
  submitted = false;
  juridicForm = false;

  nationalities;
  countries;
  cities;
  smallCities;

  destroy$: Subject<boolean> = new Subject<boolean>();

  displayCUI = false;
  invalidCnp = true;
  invalidCUI = true;

  constructor(
    private modalService: NgbModal,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
    private holdingsTableService: HoldingsTableService,
    private CNPCUIValidatorService: CnpCuiValidatorService
  ) { }

  ngOnInit(): void {

    this.helperDataService.getNationality()
      .subscribe(response => {
        this.nationalities = response;
      });

    this.store.pipe(select(getAppCountries))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.countries = response;
      });

    this.store.pipe(select(getAppCities))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.cities = response;
      });

    this.buildForm();
  }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.holdingAdminModal, {
        windowClass: 'holding-admin-modal',
        scrollable: true,
        backdrop: 'static'
      });
    }

    if (this.selectedAdmin && this.modalType === 'edit') {
      this.selectedAdmin.organizationtype.toString() === '1' ? this.juridicForm = true : this.juridicForm = false;
      this.buildForm(this.selectedAdmin);
    } else {
      this.juridicForm = false;
      this.submitted = false;
      this.buildForm();
    }

  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  // Get forms controls state
  get f() { return this.adminForm.controls; }

  buildForm(formData?) {
    this.adminForm = new UntypedFormGroup({
      organizationtype: new UntypedFormControl(
        formData !== undefined ? formData.organizationtype.toString() : '0'
      ),
      cnp: new UntypedFormControl(
        formData !== undefined ? formData.cnp : '', this.juridicForm === false ? [Validators.required] : []
      ),
      name: new UntypedFormControl(
        formData !== undefined ? formData.name : '', Validators.required
      ),
      nationality: new UntypedFormControl(
        formData !== undefined ? formData.nationality : null
      ),
      cif: new UntypedFormControl(
        formData !== undefined ? formData.cif : '', this.juridicForm !== false ? [Validators.required] : []
      ),
      country: new UntypedFormControl(
        formData !== undefined ? formData.country : null, [Validators.required]
      ),
      judet: new UntypedFormControl(
        formData !== undefined ? formData.judet : null, Validators.required
      ),
      localitate: new UntypedFormControl(
        formData !== undefined ? formData.localitate : null, Validators.required
      ),
      phone: new UntypedFormControl(
        formData !== undefined ? formData.phone : ''
      ),
      email: new UntypedFormControl(
        formData !== undefined ? formData.email : '', Validators.email
      ),
    });
  }

  validateCNP(event) {
    this.invalidCnp =
      this.CNPCUIValidatorService.validateCNP(event.target.value);

    !this.invalidCnp ?
      this.adminForm.controls.cnp.setErrors({ invalid: true }) :
      this.adminForm.controls.cnp.setErrors(null);

    this.adminForm.value.cnp = event.target.value;
  }

  validateCUI(event) {
    this.invalidCUI =
      this.CNPCUIValidatorService.validateCUI(event.target.value);


    !this.invalidCUI ?
      this.adminForm.controls.cif.setErrors({ invalid: true }) :
      this.adminForm.controls.cif.setErrors(null);

    this.adminForm.value.cif = event.target.value;
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  notifyCloseModal() {
    this.open = false;
    this.notifyClose.emit(null);
  }

  changePersFizica(event) {
    this.juridicForm = false;
    this.submitted = false;
    this.buildForm({ organizationtype: '0' });
  }

  changePersJuridica(event) {
    this.juridicForm = true;
    this.adminForm.value.nationality = { name: 'Român' };
    this.submitted = false;
    this.buildForm({ organizationtype: '1' });
  }

  getSelectedCity(city) {
    if (city !== undefined) {
      this.helperDataService.getSmallCities(city.code)
        .subscribe(response => {
          this.smallCities = response;
        });
    }

    if (this.smallCitiesDropDown !== undefined) {
      this.smallCitiesDropDown.handleClearClick();
    }
    this.smallCities = [];
    this.adminForm.value.localitate = '';
  }

  onSubmitted() {
    this.submitted = true;
    if (this.adminForm.valid) {

      const objToSent = {
        country_code: this.adminForm.value.country !== null ? this.adminForm.value.country.code : 'RO',
        settlementid: this.adminForm.value.localitate.id,
        nationality: this.adminForm.value.nationality !== null ? this.adminForm.value.nationality.name : 'Român',
        name: this.adminForm.value.name,
        cif: this.adminForm.value.cif,
        organizationtype: this.adminForm.value.organizationtype,
        countryid: this.adminForm.value.country.id,
        phone: this.adminForm.value.phone,
        email: this.adminForm.value.email,
        cnp: this.adminForm.value.cnp,
        status: 1,
        admin_id: this.selectedAdmin !== undefined ? this.selectedAdmin.id : ''
      };

      switch (this.modalType) {
        case 'add':
          this._saveAdmin(objToSent);
          break;
        case 'edit':
          this._updateAdmin(objToSent);
          break;
      }

      this.open = false;
      this.notifyClose.emit(null);
      this.modalReference.close();
      this.adminForm.reset();
    }

  }

  _saveAdmin(objToSent) {
    if (this.juridicForm) {
      objToSent.nationality = 'Român';
    }

    this.holdingsTableService.addHoldingAdmin(objToSent)
      .subscribe((response: any) => {
        const newAdmin = response[0];
        this.store.dispatch(new AddCompanyAdminData(newAdmin));
      });
  }

  _updateAdmin(objToSent) {
    const formValues = this.adminForm.value;

    this.nationalities.map(nationality => {
      if (nationality.name === this.adminForm.value.nationality) {
        this.adminForm.value.nationality = nationality;
        objToSent.nationality = this.adminForm.value.nationality.name;
      }
    });

    this.countries.map(country => {
      if (country.name === this.adminForm.value.country) {
        this.adminForm.value.country = country;
        objToSent.country_code = this.adminForm.value.country.code;
        objToSent.countryid = this.adminForm.value.country.id;
      }
    });

    this.cities.map(city => {
      if (city.name === this.adminForm.value.judet) {
        this.adminForm.value.judet = city;
      }
    });

    this.helperDataService.getSmallCities(this.adminForm.value.judet.code)
      .subscribe(settlements => {

        settlements.map(settlement => {
          if (settlement.name === formValues.localitate) {
            this.adminForm.value.localitate = settlement;
            objToSent.settlementid = this.adminForm.value.localitate.id;
          }
        });

        this.holdingsTableService.updateHoldingAdmin(objToSent)
          .subscribe((response: any) => {
            const updatedAdmin = response[0];
            this.store.dispatch(new UpdateCompanyAdminData(updatedAdmin));
          });

      });
  }

  formatPhoneNumber(event) {
    event.target.value = event.target.value.replace(/^(\+)|\D/g, '$1');
  }

}
