import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Contract } from '../models/contract.model';
import { environment } from 'src/environments/environment';
import {
  SortableTable,
  convertDateToDisplayFormat,
  convertDateToBackendFormat
} from './services-table-helper';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root'
})
export class ContractTableService extends SortableTable<Contract> {
  private baseUrl = environment.API_URL;

  contracte: Contract[];
  contractsLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.contracte, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getContractsAPI(obj) {
    return this.http.post(`${this.baseUrl}/contracte/`,
      {
        ...obj,
        contr_date_start: convertDateToBackendFormat(obj.contr_date_start),
        contr_date_end: convertDateToBackendFormat(obj.contr_date_end)
      },
      auth.options
    ).pipe(
      map((response: any) => {
        this.contracte = response.result;
        this.contractsLength = response.count;

        this.contracte.map((contract: Contract) => {
          contract.data = convertDateToDisplayFormat(contract.data);
        });

        this.paginate();
        this.sortEvent();
        this._total$.next(this.contractsLength);

        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  getContractAPI(contractId: number) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/detail/`,
        { contr_id: contractId },
        auth.options
      )
      .pipe(
        map((response: any) => {
          response.contract[0].data = convertDateToDisplayFormat(
            response.contract[0].data
          );

          return response;
        })
      );
  }

  getAcbcrRacesAPI() {
    return this.http
      .get(
        `${this.baseUrl}/acbcr-race/`,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  saveContractApi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/add/`,
        obj, auth.options
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateContractApi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/update/`,
        obj, auth.options
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteContractApi(contractId) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/delete/`,
        { contr_id: contractId },
        auth.options
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadContractsApi(obj, companyId?) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/export/`,
        { ...obj,
          contr_date_end: convertDateToBackendFormat(obj.contr_date_end),
          contr_date_start: convertDateToBackendFormat(obj.contr_date_start),
          companyId
        },
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  creazaActAditionalApi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/act-aditional/creaza/`,
        obj, auth.options
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadActAditionalApi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/act-aditional/download/`,
        obj,
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  trimiteEmailApi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contracte/act-aditional/email/`,
        obj, auth.options
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.contractsLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.contractsLength) {
      this._state.endIndex = this.contractsLength;
    }
  }
}
