<div class="card" id="contact" [formGroup]="holdingForm">
  <h3 class="card-header">Contact</h3>
  <div class="card-body">
    <div class="row">

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="mobilephone">
            Telefon mobil{{f.status.value !== 5 ? '*' : ''}}
          </label>

          <input
            type="text"
            id="mobilephone"
            formControlName="mobilephone"
            [ngClass]="{ 'is-invalid': submitted && f.mobilephone.errors }"
            placeholder="Adaugă numărul de telefon mobil"
            class="form-control"
            (input)="formatPhoneNumber($event)"
          />

          <div
            *ngIf="submitted && f.mobilephone.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.mobilephone.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>

          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="fixedphone">
            Telefon fix
          </label>

          <input
            type="text"
            id="fixedphone"
            formControlName="fixedphone"
            placeholder="Adaugă numărul de telefon fix"
            class="form-control"
            (input)="formatPhoneNumber($event)"
          />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="email">
            E-mail{{f.status.value !== 5 ? '*' : ''}}
          </label>

          <input
            type="text"
            id="email"
            formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            placeholder="Adaugă adresa de email"
            class="form-control"
          />
          <div
            *ngIf="submitted && f.email.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.email.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
            <div *ngIf="f.email.errors.email">
              Adresă email invalidă.
            </div>
          </div>

        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="emailaccountant">
            E-mail contabil
          </label>

          <input
            type="text"
            id="emailaccountant"
            formControlName="emailaccountant"
            placeholder="Adaugă adresa de email a contabilului"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.emailaccountant.errors }"
          />
          <div
            *ngIf="submitted && f.emailaccountant.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.emailaccountant.errors.email">
              Adresă email invalidă.
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>