import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { ReproductionsTableService } from 'src/app/modules/acbcr/common/services/reproductions-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-anexa3-tab',
  templateUrl: './anexa3-tab.component.html',
  styleUrls: ['./anexa3-tab.component.scss'],
})
export class Anexa3TabComponent implements OnInit, OnDestroy {
  @ViewChild('stergeAnexaModal') stergeAnexaModal: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  isAdmin = false;
  isOperator = false;
  anexe3 = [];
  selectedHolding;
  modalData = null;
  idStergeAnexa = null;

  constructor(
    private helperDataService: HelperDataService,
    private reproductionsTableService: ReproductionsTableService,
    private store: Store<ApplicationState>,
    public modalService: NgbModal,
    public errorService: NotificationErrorService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();

    this.store.pipe(
      select(getLoadingState),
      filter(isLoading => !isLoading),
      switchMap(() => this.store.pipe(select(getSelectedHolding))),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
      takeUntil(this.destroy$),
    ).subscribe((response: any) => {
        this.selectedHolding = response;
        if (this.selectedHolding !== undefined && this.selectedHolding.id > 0) {
          this.getTableData();
        }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  downloadAnexa3(anexa) {
    this.reproductionsTableService
      .getAnexa3Pdf(this.selectedHolding.id, anexa.id)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, `Anexa3_${anexa.id}.pdf`);
      });
  }

  openAnexa3(anexa3Id) {
    this.modalData = {
      'anexa_id': anexa3Id,
      'modal_type': 'reproductii',
    };
  }

  getModalResult(event) {
    this.getTableData();
  }

  clickStergeAnexa(anexaId) {
    this.idStergeAnexa = anexaId;
    this.modalService.open(this.stergeAnexaModal, {
      scrollable: false,
    });
  }

  confirmareStergeAnexa(confirmare?: boolean) {
    if (confirmare) {
      this.reproductionsTableService
        .anexa3Delete(this.idStergeAnexa)
        .subscribe({
          next: (response) => {
            this.getTableData();
          },
          error: (errors) => {
            this.errorService.processErrorMsg(errors.error.error);
          },
        });
    }
  }

  private getTableData() {
    if (this.selectedHolding) {
      this.reproductionsTableService
        .getAnexa3Data({ company_id: this.selectedHolding.id })
        .subscribe((response) => (this.anexe3 = response));
    }
  }
}
