import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-operator-search',
  templateUrl: './operator-search.component.html',
  styleUrls: ['./operator-search.component.scss']
})
export class OperatorSearchComponent implements OnInit {
  @Output() searchResult = new EventEmitter();

  operatorSearchForm;

  constructor() {}

  ngOnInit(): void {
    this.operatorSearchForm = new UntypedFormGroup({
      status: new UntypedFormControl(null),
      nume: new UntypedFormControl(null),
      cod: new UntypedFormControl(null),
      cnp: new UntypedFormControl(null),
      nraut: new UntypedFormControl(null)
    });
  }

  onSubmitted() {
    this.searchResult.emit(this.operatorSearchForm.value);
  }

  resetSearch() {
    this.operatorSearchForm.reset();
    this.onSubmitted();
  }
}
