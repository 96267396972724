import { Component, OnInit, Input,
  ViewChildren, QueryList, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AdvancedSortableDirective, SortEvent } from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { Subject } from 'rxjs';
import { Activitate } from 'src/app/modules/acbcr/common/models/activitate.model';
import { ActivitatiService } from 'src/app/modules/acbcr/common/services/activitati.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ContabilitateSettingsService } from 'src/app/modules/acbcr/common/services/contabilitate-settings.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-activitati-table',
  templateUrl: './activitati-table.component.html',
  styleUrls: ['./activitati-table.component.scss']
})
export class ActivitatiTableComponent implements OnInit, OnDestroy {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective>;
  @ViewChild('activitatiTable', { static: false }) activitatiTable: ElementRef;

  @Input() activitatiList: Activitate[];
  @Input() activitatiListLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  addActivitateForm: UntypedFormGroup;

  readonly modalType = 'activitati';
  openSearchModal = new Subject<boolean>();

  openConfirmModal = false;
  openAddModal = false;
  submitted = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  holdingId;
  facturaType;
  activitiesList;
  activitatiExistente;
  sameTypeOfActivitites = [];
  activitiesToDelete = [];
  deleteAll = false;

  objToAPI = {
    page_no: 1,
    page_size: 10
  };

  constructor(
    public activitatiService: ActivitatiService,
    private contabilitateSettingsService: ContabilitateSettingsService,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.addActivitateForm = new UntypedFormGroup({
      activitatea_id: new UntypedFormControl('', Validators.required)
    });
  }

  get f() { return this.addActivitateForm.controls; }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.activitatiService.sortColumn = column;
    this.activitatiService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openAddActivitateModal(hId, fType, addAll?) {
    this.contabilitateSettingsService.getActivitiesSettings(fType).subscribe(activitati => {
      this.activitiesList = activitati;
      this.activitiesList?.map(act2 => {
        act2.disabled = false;
      });

      this.activitatiExistente = this.activitatiList?.filter(act => act.id === hId)[0].activitati_existente;

      const acList = [];
      this.activitiesList?.map(act => acList.push(act.descriere_scurta));

      this.sameTypeOfActivitites = [];
      this.sameTypeOfActivitites = this.activitatiExistente.filter(activ => activ.type === fType);

      let activitiesIdList = [];
      activitiesIdList = this.activitiesList.map(activ => activ.id);
      if (this.sameTypeOfActivitites.length === 0 && addAll) {
          this.activitatiService.addActivitateApi(hId, fType, activitiesIdList)
            .subscribe(response => {
              this.callbackResult.emit('');
              return;
            });
      } else {
        if (this.activitatiExistente.length > 0) {
          this.activitatiExistente.map(act => {
            if (acList.includes(act.name) && act.type === fType) {
              this.activitiesList?.map(act2 => {
                if (act2.descriere_scurta === act.name) {
                  act2.disabled = true;
                }
              });
            }
          });
        } else {
          this.activitiesList?.map(act2 => {
            act2.disabled = false;
          });
        }

        this.holdingId = hId;
        this.facturaType = fType;
        this.openAddModal = true;
      }
    });
  }

  closeAddActivitateModal() {
    this.holdingId = null;
    this.facturaType = null;
    this.openAddModal = false;
    this.addActivitateForm.reset();
    this.submitted = false;
    this.activitatiExistente = [];
  }

  onSubmitted() {
    this.submitted = true;
    if (this.addActivitateForm.valid) {
      const aId = this.addActivitateForm.value.activitatea_id;
      this.activitatiService.addActivitateApi(this.holdingId, this.facturaType, [aId])
        .subscribe(response => {
        this.callbackResult.emit('');
        this.openAddModal = false;
        this.submitted = false;
        this.addActivitateForm.reset();
      });
    }
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
    this.objToAPI = {
      ...this.objToAPI,
      ...event
    };
  }

  exportTableXLSX() {
    this.objToAPI = {
      ...this.objToAPI,
    };

    let now = UIHelper.now();
    let fisier = `Activitati_${now}.xls`;

    this.activitatiService
    .exportXLS(this.objToAPI)
    .subscribe((response) => {
      const blob = new Blob([response]);
      this.helperDataService.simulateDownload(blob, fisier);
    });
  }

  getCallbackResult(event) {
    this.callbackResult.emit(event);
  }

  openConfirmDeleteModal(hId, fType, aId, allActivities?) {
    this.holdingId = hId;
    this.facturaType = fType;
    this.openConfirmModal = true;

    if (allActivities) {
      this.deleteAll = true;
      this.activitiesToDelete = aId.map(activ => activ.id);
    } else {
      this.deleteAll = false;
      this.activitiesToDelete.push(aId);
    }
  }

  openConfirmDeleteModal2() {}

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.deleteActivitate(this.holdingId, this.facturaType, this.activitiesToDelete);
    }

    this.holdingId = null;
    this.facturaType = null;
    this.activitiesToDelete = [];
  }

  toggleParent(item: any) {
    item.isOpen = !item.isOpen;
  }

  toggleChild(child: any) {

    child.isOpen = !child.isOpen;
    child.servicesList = [];
    if (child.isOpen) {
      this.contabilitateSettingsService.getServicesSettings(child.id).subscribe(response => {
        child.servicesList = response;
      });
    } else {
      child.servicesList = [];
    }
  }

  subventionatChange(holdingId, subventie) {
    this.activitatiService.updateActivitateApi(holdingId, subventie.target.checked)
      .subscribe(response => {
      this.callbackResult.emit('');
    });
  }

  perioadaChange(holdingId, subventie, perioada, tipFactura) {
    let perioadaList = [];
    perioadaList = [tipFactura, perioada];
    this.activitatiService.updateActivitateApi(holdingId, subventie, perioadaList)
      .subscribe(response => {
        perioadaList = [];
        this.callbackResult.emit('');
    });
  }

  private deleteActivitate(holdingId, facturaType, activityIds) {
    this.activitatiService.deleteActivitateApi(holdingId, facturaType, activityIds)
      .subscribe(response => {
      this.callbackResult.emit('');
    });
  }
}
