import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationInfoService {
  private baseUrl = environment.API_URL;

  constructor(private http: HttpClient) { }

  marcheazaCitit(obj) {
    return this.http.post(
      `${this.baseUrl}/notificari/marcheaza-citit/`,
      obj

    ).pipe(map((response: any) => {
      return response;
    }));
  }

  clopotel() {
    return this.http.post(
      `${this.baseUrl}/notificari/clopotel/`,
      {}

    ).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadAPI() {
    return this.http.post(
      `${this.baseUrl}/notificari/download/`,
        {}, {
          responseType: 'blob'
        }
      );
  }
}
