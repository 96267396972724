import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  SortEvent,
  AdvancedSortableDirective
} from '../../../../directives/advanced-sortable.directive';
import { MovesTableService } from 'src/app/modules/acbcr/common/services/moves-table.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-moves-table',
  templateUrl: './moves-table.component.html',
  styleUrls: ['./moves-table.component.scss']
})
export class MovesTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
  AdvancedSortableDirective>;
  @ViewChild('movesTable', { static: false }) movesTable: ElementRef;

  @Input() moves = [];
  @Input() movesLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() movesTableCallback = new EventEmitter();

  readonly modalType = 'move';
  openSearchModal = new Subject<boolean>();

  modalData;
  currentRoute: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isAdmin = false;
  isOperator = false;
  isFermier = false;

  constructor(
    public movesTableService: MovesTableService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    // Read current app route from the store
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.movesTableService.sortColumn = column;
    this.movesTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  openMiscariModal() {
    this.modalData = {
      'anexa_id': null,
      'modal_type': 'miscari',
    };
  }

  openAnexa10(anexa10Id) {
    this.modalData = {
      'anexa_id': anexa10Id,
      'modal_type': 'miscari',
    };
  }

  getModalResult(event) {
    this.movesTableCallback.emit(event);
  }

  downloadAnexa10(companyid, anexa10id) {
    this.movesTableService.downloadAnexa10Api(companyid, anexa10id)
    .subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      this.helperDataService.simulateDownload(blob, 'Anexa10_' + anexa10id + '.pdf');
    });
  }

}
