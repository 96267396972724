import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  SortableTable,
  convertDateToDisplayFormat,
  convertDateToBackendFormat
} from './services-table-helper';

@Injectable({
  providedIn: 'root'
})
export class RegistruGenealogicTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  animale: any[];
  numarAnimale: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.animale, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getAnimalsAPI(obj) {
    return this.http
    .post(`
      ${this.baseUrl}/registru_genealogic/list/`,
      obj

    ).pipe(
      map((response: any) => {
        this.animale = response.result;
        this.numarAnimale = response.count;

        this.animale.map(animal => {
          animal.dataNastere = convertDateToDisplayFormat(animal.dataNastere);
          animal.isChecked = false;
        });

        this.paginate();
        this.sortEvent();
        this._total$.next(this.numarAnimale);

        return response;
      })
    );
  }

  getReason(animalId) {
    return this.http.post(`${this.baseUrl}/registru_genealogic/evaluare-animal/`,
    { id: animalId }

    ).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getAnimalsRegGen(obj) {
    return this.http
    .post(
      `${this.baseUrl}/registru_genealogic/list/`,
      {
        ...obj, data: convertDateToBackendFormat(obj.data),
      }
    ).pipe(map((response: any) => {
      return response.result;
    }));
  }

  exportAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/registru_genealogic/exportxls/`,
        req_params,
        {
          responseType: 'blob'
        }
      ).pipe(
        map((response: any) => {
          return response;
      }));
  }

  exportXLS(data, numarmatricolList) {
    return this.http
    .post(
      `${this.baseUrl}/registru_genealogic/exportxls/`,
      {
        data: convertDateToBackendFormat(data),
        numarmatricol_list : numarmatricolList
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  getAcbcrRacesAPI() {
    return this.http
      .get(
        `${this.baseUrl}/acbcr-race/`
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  checkRgSection(animalId: number, section: number) {
    return this.http
      .post(
        `${this.baseUrl}/animals/animal-chkRGSection/`,
        { animalId, section }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  simulateFitDescendants(numarMatricol, sectRGeneral, sectRGRoman) {
    return this.http
      .post(
        `${this.baseUrl}/animals/animal-fit-all-descendants/`,
        { numarMatricol, update: false, sectRGeneral, sectRGRoman }
      ).pipe(map((response: any) => {
        return response;
    }));
  }

  fitDescendants(numarMatricol, sectRGeneral, sectRGRoman) {
    return this.http
      .post(
        `${this.baseUrl}/animals/animal-fit-all-descendants/`,
        { numarMatricol, update: true, sectRGeneral, sectRGRoman },
        {
          responseType: 'blob',
          observe: 'response'
        }
      ).pipe(map((response: any) => {
        return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarAnimale;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarAnimale) {
      this._state.endIndex = this.numarAnimale;
    }
  }
}
