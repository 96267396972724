import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { SortableTable } from './services-table-helper';
import { environment } from 'src/environments/environment';
import { Operator } from '../models/operator.models';

@Injectable({
  providedIn: 'root'
})
export class OperatorsTableService extends SortableTable<Operator> {
  private baseUrl = environment.API_URL;

  operatori: Operator[];
  numerOperatori: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.operatori, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getOperatorsAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/operatori-insamantare/`,
      obj
    ).pipe(map((response: any) => {
      this.operatori = response.result;
      this.numerOperatori = response.count;

      if (this.operatori) {
        this.operatori.map((operator: Operator) => {
          operator.numeComplet = `${operator.nume} ${operator.prenume}`;
          operator.viewLabel = `${operator.nume} ${operator.prenume} ( ${operator.cod} )`;
          return operator;
        });
      }

      this.paginate();
      this.sortEvent();
      this._total$.next(this.numerOperatori);

      return response;
    }));
  }

  addOperator(operator: Operator) {
    return this.http.post(
      `${this.baseUrl}/reproductii/opinsemadd/`,
      operator
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  updateOperator(operator: Operator) {
    return this.http.post(
      `${this.baseUrl}/reproductii/opinsemupdate/`,
      operator
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/operatori-insamantare-export/`,
      {
        ...obj
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numerOperatori;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numerOperatori) {
      this._state.endIndex = this.numerOperatori;
    }
  }
}
