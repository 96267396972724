<ng-template
  #tableSearchModal
  let-modal="close"
>
  <div class="modal-header" [ngSwitch]="modalType">
    <h3 class="modal-title" *ngSwitchCase="'holding'">
      Caută în exploatații
    </h3>
    <h3 class="modal-title" *ngSwitchCase="'animal'">
      Caută în animale
    </h3>
    <h3 class="modal-title" *ngSwitchCase="'reproduction'">
      Caută în reproducție
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'birth'">
      Caută în fătări
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'move'">
      Caută în mișcări
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'operator'">
      Caută în operatori
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'contract'">
      Caută în contracte
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'certificat'">
      Caută în solicitari certificate zootehnice
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'certificatlist'">
      Caută în certificate zootehnice
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'adeverinta'">
      Caută în adeverinte de apartenenta
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'settlement'">
      Caută în localități
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'performance'">
      Caută în testarea performanțelor
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'facturi'">
      Caută în facturi
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'activitati'">
      Caută în activități
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'chitante'">
      Caută în chitanțe
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'rapoarte-incasari'">
      Caută în rapoarte încasări
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'rapoarte-facturi'">
      Caută în rapoarte facturi
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'rapoarte-conta-incasari'">
      Caută în rapoarte încasări
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'rapoarte-facturi-emise'">
      Caută în rapoarte facturi emise
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'rapoarte-facturi-neachitate'">
      Caută în rapoarte facturi neachitate
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'rapoarte-client'">
      Caută în rapoarte client
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'rapoarte-jurnal'">
      Caută în jurnal
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid" [ngSwitch]="modalType">
      <app-holding-search
        *ngSwitchCase="'holding'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-holding-search>

      <app-animal-search
        *ngSwitchCase="'animal'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-animal-search>

      <app-reproduction-search
        *ngSwitchCase="'reproduction'"
        [isFermier]="isFermier"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-reproduction-search>

      <app-birth-search
        *ngSwitchCase="'birth'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-birth-search>

      <app-move-search
        *ngSwitchCase="'move'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-move-search>

      <app-operator-search
        *ngSwitchCase="'operator'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-operator-search>

      <app-contract-search
        *ngSwitchCase="'contract'"
        [isFermier]="isFermier"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-contract-search>

      <app-certificat-search
        *ngSwitchCase="'certificat'"
        [isFermier]="isFermier"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-certificat-search>

      <app-certificat-list-search
        *ngSwitchCase="'certificatlist'"
        [isFermier]="isFermier"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-certificat-list-search>

      <app-adeverinta-search
        *ngSwitchCase="'adeverinta'"
        [isFermier]="isFermier"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-adeverinta-search>

      <app-settlement-search
        *ngSwitchCase="'settlement'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-settlement-search>

      <app-performance-search
        *ngSwitchCase="'performance'"
        [isFermier]="isFermier"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-performance-search>

      <app-facturi-search
        *ngSwitchCase="'facturi'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-facturi-search>

      <app-activitati-search
        *ngSwitchCase="'activitati'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-activitati-search>

      <app-incasari-search
        *ngSwitchCase="'chitante'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-incasari-search>

      <app-rapoarte-incasari-search
        *ngSwitchCase="'rapoarte-incasari'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-rapoarte-incasari-search>

      <app-rapoarte-facturi-search
        *ngSwitchCase="'rapoarte-facturi'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-rapoarte-facturi-search>

      <app-rapoarte-conta-incasari-search
        *ngSwitchCase="'rapoarte-conta-incasari'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-rapoarte-conta-incasari-search>

      <app-rapoarte-facturi-emise-search
        *ngSwitchCase="'rapoarte-facturi-emise'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-rapoarte-facturi-emise-search>

      <app-rapoarte-facturi-neachitate-search
        *ngSwitchCase="'rapoarte-facturi-neachitate'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-rapoarte-facturi-neachitate-search>

      <app-rapoarte-client-search
        *ngSwitchCase="'rapoarte-client'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-rapoarte-client-search>

      <app-rapoarte-jurnal-search
        *ngSwitchCase="'rapoarte-jurnal'"
        (searchResult)="getSearchingData($event); modal('Cross click')"
      >
      </app-rapoarte-jurnal-search>
    </div>
  </div>
</ng-template>
