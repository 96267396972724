<ng-template #addEditTemplate let-modal="close">
    <div class="modal-header row">
      <div class="col-sm-12 col-md-12 col-lg-6 custom-col">
        <h3 class="modal-title">Solicitare Certificat Zootehnic</h3>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-6 text-right custom-col">
        <div class="btn-group custom-modal-btn">
          <button
            class="btn btn-sm btn-secondary"
            (click)="modal('Cross click')"
            autofocus="false"
          >
            Închide
          </button>
          <button
            *ngIf="!readonly"
            class="btn btn-sm btn-success"
            (click)="onSubmitted()"
          >
            Salvează
          </button>
        </div>
      </div>
    </div>

    <div class="modal-body">
      <div
        *ngIf="loadingDataSpinner"
        class="spinner-border text-info table-spinner avatar-xxl"
        role="status"
      ></div>

      <form *ngIf="!loadingDataSpinner" [formGroup]="solicitareForm" class="h-100">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-9">
              <div class="form-group mb-3">
                <label for="exploatatie_id">
                  Exploatație
                </label>
                <ng-select
                  [items]="exploatatiiSelectItems"
                  bindLabel="viewLabel"
                  bindValue="id"
                  formControlName="exploatatie_id"
                  placeholder="Alege exploatația"
                  (search)="searchExploatatii($event)"
                  (change)="findAnimals()"
                  [ngClass]="{ disabled: readonly || isFermier }"
                >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <div class="row">
                    <div class="col-md-12 col-lg-auto">
                      {{item.name}} - {{ item.code }}
                    </div>

                    <div class="col-md-12 col-lg-auto px-0">
                      <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                        {{ error }}
                      </span>
                    </div>
                  </div>
                </ng-template>
                </ng-select>

                <small
                  *ngIf="!solicitareForm.value.exploatatie_id"
                  class="form-text text-muted ml-2"
                >
                  <b>Introdu minim 5 caractere pentru a căuta</b>
                </small>
                <small
                  *ngIf="submitted && solicitareForm.controls.exploatatie_id.errors?.required"
                  class="form-text text-danger ml-2"
                >
                  Alege exploatația
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label class="d-inline-flex align-self-center mb-0 mr-3">
                Fișier Solicitare CZ
              </label>

              <app-upload-fisier
                *ngIf="!readonly && !solicitareForm.value.file_id"
                class="mr-3"
                (ApiResponse)="uploadServerResponse($event)"
              >
              </app-upload-fisier>

              <span *ngIf="fileName">
                <button
                  type="button"
                  class="btn p-0 text-blue font-weight-bold"
                  (click)="downloadFile($event)"
                >
                  <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                  {{ fileName }}
                </button>

                <button
                  *ngIf="!readonly && (isAdmin || isOperator || isFermier)"
                  type="button"
                  class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
                  ngbTooltip="Șterge fișier"
                  placement="right"
                  (click)="deleteFile()"
                >
                </button>
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-auto">
              <div class="form-group mb-3 mr-2">
                <label for="numar">
                  Număr
                </label>
                <input
                  type="text"
                  formControlName="numar"
                  pattern="[0-9]+"
                  class="numar form-control"
                  [readonly]="readonly || isOperator || isFermier"
                />

                <div
                  *ngIf="submitted && solicitareForm.controls.numar.errors?.pattern"
                  class="invalid-feedback d-block"
                >
                  Cămpul trebuie să fie un număr
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group mb-3 mr-2">
                <label for="data">
                  Data
                </label>
                <app-date-picker
                  (dateChanged)="onDateChanged($event)"
                  [selectedDate]="solicitareForm.controls.data.value"
                  [disabled]="readonly || isOperator || isFermier"
                >
                </app-date-picker>

                <div
                  *ngIf="submitted && solicitareForm.controls.data.errors?.required"
                  class="error-message invalid-feedback d-block"
                >
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="type-title">
                Animale
                <span
                  *ngIf="loadingAnimale"
                  class="spinner-border text-info small avatar-xs"
                  role="status"
                >
                </span>
              </h3>
            </div>
          </div>

          <ng-container
            *ngFor="let animalForm of animaleArrayForm.controls; let index = index;"
          >
            <div [formGroup]="animalForm" class="row mb-2">
              <div class="col-auto d-flex align-items-center justify-content-center">
                <ng-container [ngSwitch]="animalForm.value.sex">
                  <span *ngSwitchCase="1" class="box-mascul">M</span>
                  <span *ngSwitchCase="2" class="box-femela">F</span>
                </ng-container>

                <span
                  [ngClass]="{
                    'color-mascul': animalForm.value.sex === 1,
                    'color-femela': animalForm.value.sex === 2
                  }"
                  class="mr-2"
                >{{ animalForm.value.numarmatricol }}</span>

                <span>{{ animalForm.value.rasa }}</span>
              </div>

              <div
                *ngIf="!readonly && animalForm.value.status_solicitare_cz !== 6"
                class="col-auto d-flex align-items-center justify-content-center"
              >
                <i
                  class="mdi mdi-close-circle text-danger fs-3 cursor-pointer"
                  title="Șterge"
                  (click)="deleteAnimal(animalForm, index)"
                >
                </i>
              </div>

              <span
                *ngIf="animalForm.value.status_solicitare_cz === 6"
                class="text-success"
              >Eliberat</span>
            </div>
          </ng-container>

          <div class="row mt-1">
            <div class="col-sm-12 col-lg-9">
              <div class="form-group mb-3">
                <ng-select
                  #elementAdaugaAnimal
                  *ngIf="!readonly"
                  [items]="animaleSelectItems"
                  bindLabel="numarmatricol"
                  bindValue="id"
                  placeholder="Alege animal"
                  (change)="adaugaAnimal(elementAdaugaAnimal, $event)"
                >
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <div class="row">
                      <div class="col-auto d-flex align-items-center justify-content-center">
                        <ng-container [ngSwitch]="item.sex">
                          <span *ngSwitchCase="1" class="box-mascul">M</span>
                          <span *ngSwitchCase="2" class="box-femela">F</span>
                        </ng-container>

                        <span
                          [ngClass]="{
                            'color-mascul': item.sex === 1,
                            'color-femela': item.sex === 2
                          }"
                          class="mr-2"
                        >{{ item.numarmatricol }}</span>

                        <span>{{ item.rasa }}</span>
                      </div>

                      <div class="col-sm-12 col-md-auto px-0">
                        <span *ngIf="item.eligibil" class="mr-2 text-success">Eligibil</span>
                        <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                          {{ error }}
                        </span>
                        <span *ngFor="let warning of item.warnings" class="badge badge-warning text-dark p-1 mr-2">
                          {{ warning }}
                        </span>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>

                <small
                  *ngIf="!solicitareForm.value.exploatatie_id"
                  class="form-text text-muted ml-2"
                >
                  <b>Animalul se poate alege după ce s-a ales exploatația</b>
                </small>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
   </ng-template>
