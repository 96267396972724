import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-miscari-list, [app-miscari-list]',
  templateUrl: './miscari-list.component.html',
  styleUrls: ['./miscari-list.component.scss']
})
export class MiscariListComponent implements OnInit {
  @Input() anexe = [];
  @Input() selectedItem: number;
  @Output() openEditMiscare = new EventEmitter();
  @Output() selectedItemChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  openEditModal(anexa, index) {
    this.openEditMiscare.emit(anexa);
    this.selectedItemChanged.emit(index);
  }

}
