<div class="card">
  <div class="card-body" style="height: 600px;">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./registrul-exploatatiei"
        >
          REGISTRUL EXPLOATAȚIEI
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./terenuri"
        >
          TERENURI
        </a>
      </li>
    </ul>

    <br>

    <ul *ngIf="isTerenuri" class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./terenuri/pasuni"
        >
          PASUNI
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./terenuri/fanete"
        >
          FANETE
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./terenuri/arabil"
        >
          ARABIL
        </a>
      </li>
    </ul>

    <div class="h-100 d-flex align-items-center justify-content-center">
      <h1 class="text-center">ÎN LUCRU</h1>
    </div>

  </div>
</div>
