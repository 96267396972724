<div class="container-fluid mt-3">
  <div class="col-12">
    <div class="card settings-card">
      <div class="row">
        <div class="col-12">
          <p class="settings-type">Setează activitățile</p>
        </div>
      </div>
      <form name="settingsForm" [formGroup]="settingsForm">
        <div class="col-sm-3 col-lg-3">
          <label for="acitivity"> Tip factură* </label>
          <ng-select
            class="read-only-form tip-factura"
            placeholder="Alege tipul de factură"
            (change)="selectFactura($event)"
            [appendTo]="'body'"
          >
            <ng-option [value]="1">RG</ng-option>
            <ng-option [value]="2">CPP</ng-option>
            <ng-option [value]="3">ACBCR</ng-option>
          </ng-select>
        </div>
        <div class="table-responsive" #activitiesTable>
          <table
            id="um-datatable"
            class="table table-bordered dt-responsive nowrap table-hover"
            formArrayName="activities"
          >
            <thead>
              <tr>
                <th>DESCRIERE SCURTĂ</th>
                <th>DESCRIERE</th>
                <th>PROC. TVA</th>
                <th>PROC. SUBVENTIE</th>
                <th>DESCRIERE SUBVENTIE</th>
                <th>PERIODIC</th>
                <th style="width: 10%; text-align: center">ACȚIUNI</th>
              </tr>
            </thead>
            <ngb-alert
              type="danger"
              class="text-center search-error"
              *ngIf="!tipFactura"
              [dismissible]="false"
            >
              Nu a fost selectat nici un tip de factură.
            </ngb-alert>
            <tbody *ngIf="tipFactura">
              <tr
                [formGroupName]="i"
                *ngFor="
                  let item of settingsForm['controls'].activities['controls'];
                  let i = index;
                  let last = last
                "
              >
                <td>
                  <input
                    type="text"
                    class="form-control read-only-form"
                    name="descriere_scurta"
                    formControlName="descriere_scurta"
                    placeholder="Adaugă descrierea scurtă"
                    (click)="makeActive(i)"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control read-only-form"
                    name="descriere"
                    formControlName="descriere"
                    (click)="makeActive(i)"
                    [readonly]="!activeFields[i]"
                    placeholder="Adaugă descriere"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control read-only-form"
                    name="proc_tva"
                    formControlName="proc_tva"
                    (click)="makeActive(i)"
                    placeholder="Adaugă procent TVA"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control read-only-form"
                    name="proc_subventie"
                    placeholder="Adaugă procent subvenție"
                    formControlName="proc_subventie"
                    (click)="makeActive(i)"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control read-only-form"
                    name="descriere_subventie"
                    formControlName="descriere_subventie"
                    placeholder="Adaugă descriere subvenție"
                    (click)="makeActive(i)"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td>
                  <div class="select-buttons">
                    <input
                      type="checkbox"
                      class="form-control read-only-form"
                      name="periodic"
                      formControlName="periodic"
                      (click)="makePeriodic(i, $event)"
                      [checked]="item.value.periodic"
                      id="singleCheckbox1"
                    />
                    <label></label>
                  </div>
                </td>
                <td style="text-align: center">
                  <button
                    *ngIf="last"
                    class="btn btn-sm btn-icon btn-success"
                    ngbTooltip="Adaugă activitatea"
                    placement="bottom"
                    (click)="saveLineItem(i)"
                  >
                    <i class="fe-plus"></i>
                  </button>
                  <div class="select-buttons">
                    <button
                      *ngIf="!last"
                      class="btn btn-sm btn-icon btn-warning mr-1"
                      ngbTooltip="Salvează activitatea"
                      placement="bottom"
                      (click)="editLineItem(i)"
                    >
                      <i class="fe-save"></i>
                    </button>
                    <button
                      *ngIf="!last"
                      class="btn btn-sm btn-icon btn-danger"
                      ngbTooltip="Șterge activitatea"
                      placement="bottom"
                      (click)="deleteLineItem(i, item.value.id)"
                    >
                      <i class="mdi mdi-delete-forever"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
