import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-registru-genealogic-search',
  templateUrl: './registru-genealogic-search.component.html',
  styleUrls: ['./registru-genealogic-search.component.scss']
})
export class RegistruGenealogicSearchComponent implements OnInit {
  @ViewChild('searchModal') searchModal: ElementRef;
  @Input() open: Subject<boolean>;
  @Output() searchingData = new EventEmitter();
  regGenSearchForm: UntypedFormGroup;
  exploatatii = [];
  animale = [];
  showHint = true;

  constructor(
    private helperDataService: HelperDataService,
    private errorService: NotificationErrorService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.regGenSearchForm = new UntypedFormGroup({
      exploatatie_id: new UntypedFormControl(),
      rasa: new UntypedFormControl(),
      sex: new UntypedFormControl(),
      numarmatricol: new UntypedFormControl(),
      sectiune: new UntypedFormControl(),
      eligibil: new UntypedFormControl()
    });

    this.open.subscribe({
      next: (response) => {
        if (response) {
          this.modalService.open(this.searchModal, {
            scrollable: false
          });
        }
      },
      error: (errors) => {
      }
    });
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.showHint = true;
      this.exploatatii = [];

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  onSubmitted() {
    this.searchingData.emit(this.regGenSearchForm.value);
  }

  searchHoldings(event) {
    if (event.term.length >= 4) {
      this.showHint = false;
    } else {
      this.showHint = true;
    }
  }

  clearHoldings() {
    this.showHint = true;
  }

  resetSearch() {
    this.regGenSearchForm.reset();
    this.onSubmitted();
  }
}
