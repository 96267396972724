import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EvaluareGeneticaService } from 'src/app/modules/acbcr/common/services/evaluare-genetica.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

export const evaluareGeneticaColumns = [
  'matricol', 'exploatatie', 'greutate_nastere', 'usurinta_fatare', 'gvr_200',
  'gvr_365', 'parinti', 'consangvinizare', 'va_absolut_greutate_nastere',
  'va_relativ_greutate_nastere', 'acc_greutate_nastere',
  'va_absolut_greutate_intarcare', 'va_relativ_greutate_intarcare',
  'acc_greutate_intarcare', 'va_absolut_greutate_smz',
  'va_relativ_greutate_smz', 'acc_smz', 'ebv_carne_absolut', 'ebv_carne_relativ',
  'acc_carne', 'va_absolut_greutate_nota_fatare',
  'va_relativ_greutate_nota_fatare', 'acc_nota_fatare', 'ebv_reproductie_absolut',
  'ebv_reproductie_relativ', 'acc_reproductie', 'ebv_total_absolut',
  'ebv_total_relativ', 'acc_total', 'data',
];
export const evaluareGeneticaNumber = [
  'greutate_nastere', 'usurinta_fatare', 'gvr_200', 'gvr_365',
  'va_absolut_greutate_nastere', 'acc_greutate_nastere',
  'va_absolut_greutate_intarcare', 'acc_greutate_intarcare',
  'va_absolut_greutate_smz', 'acc_smz', 'ebv_carne_absolut', 'acc_carne',
  'va_absolut_greutate_nota_fatare', 'acc_nota_fatare',
  'ebv_reproductie_absolut', 'acc_reproductie', 'ebv_total_absolut',
  'acc_total', 'data',
]
export const evaluareGeneticaPercent = [
  'consangvinizare', 'va_relativ_greutate_nastere', 'va_relativ_greutate_intarcare',
  'va_relativ_greutate_smz', 'ebv_carne_relativ', 'va_relativ_greutate_nota_fatare',
  'ebv_reproductie_relativ', 'ebv_total_relativ',
]

export const evaluareGeneticaColumnsDesc = {
  'matricol': {abr: 'Matricol, Sex, Data naștere', desc: 'Matricol, Sex, Data naștere'},
  'sex': {abr: 'Sex', desc: 'Sex'},
  'exploatatie': {abr: 'Exploatatie', desc: 'Exploatatie'},
  'data_nastere': {abr: 'Data nastere', desc: 'Data nastere'},
  'greutate_nastere': {abr: 'GN (kg)', desc: 'Greutate nastere (kg)'},
  'usurinta_fatare': {abr: 'U.F.', desc: 'Usurinta fatare'},
  'gvr_200': {abr: 'GVR 200 (kg)', desc: 'Greutate intarcare referinta (corectata)(kg)'},
  'gvr_365': {abr: 'GVR 365 (kg)', desc: 'Greutate referinta cantarire 12 luni (corectata) (kg)'},
  'parinti': {abr: 'Părinți', desc: 'Părinți'},
  'consangvinizare': {abr: 'Fx (%)', desc: '%Consangvinizare'},
  'va_absolut_greutate_nastere': {abr: 'V.A. GN (kg)', desc: 'VA absolut greutate nastere'},
  'va_relativ_greutate_nastere': {abr: 'V.A. GN (%)', desc: 'VA relativ greutate nastere'},
  'acc_greutate_nastere': {abr: 'ACC', desc: 'ACC greutate nastere'},
  'va_absolut_greutate_intarcare': {abr: 'V.A. 200 (kg)', desc: 'VA absolut greutate intarcare'},
  'va_relativ_greutate_intarcare': {abr: 'V.A. 200 (%)', desc: 'VA relativ greutate intarcare'},
  'acc_greutate_intarcare': {abr: 'ACC', desc: 'ACC greutate intarcare'},
  'va_absolut_greutate_smz': {abr: 'V.A. 365 (kg)', desc: 'VA absolut greutate SMZ'},
  'va_relativ_greutate_smz': {abr: 'V.A. 365 (%)', desc: 'VA relativ greutate SMZ'},
  'acc_smz': {abr: 'ACC', desc: 'ACC SMZ'},
  'ebv_carne_absolut': {abr: 'EBV Carne (kg)', desc: 'EBV carne (absolut)'},
  'ebv_carne_relativ': {abr: 'EBV Carne (%)', desc: 'EBV carne (relativ)'},
  'acc_carne': {abr: 'ACC', desc: 'ACC Carne'},
  'va_absolut_greutate_nota_fatare': {abr: 'V.A. UF (kg)', desc: 'VA absolut greutate nota fatare'},
  'va_relativ_greutate_nota_fatare': {abr: 'V.A. U.F. (%)', desc: 'VA relativ greutate nota fatare'},
  'acc_nota_fatare': {abr: 'ACC', desc: 'ACC nota fatare'},
  'ebv_reproductie_absolut': {abr: 'EBV Reprod. (kg)', desc: 'EBV reproductie (absolut)'},
  'ebv_reproductie_relativ': {abr: 'EBV Reprod. (%)', desc: 'EBV reproductie (relativ)'},
  'acc_reproductie': {abr: 'ACC', desc: 'ACC reproductie'},
  'ebv_total_absolut': {abr: 'EBV Total (kg)', desc: 'EBV total (absolut)'},
  'ebv_total_relativ': {abr: 'EBV Total (%)', desc: 'EBV total (relativ)'},
  'acc_total': {abr: 'ACC', desc: 'ACC total'},
  'data': {abr: 'Data', desc: 'Data evaluării'},
}

@Component({
  selector: 'app-evaluare-genetica',
  templateUrl: './evaluare-genetica.component.html',
  styleUrls: ['./evaluare-genetica.component.scss']
})
export class EvaluareGeneticaComponent implements OnInit {
  @ViewChild('importFisierModal') importFisierModal: ElementRef;
  @ViewChild('cautareGenerareModal') cautareGenerareModal: ElementRef;
  loadingDataSpinner = false;
  req_params = {
    page_no: 1,
    page_size: 10,
  };
  listaDate;
  dataEvaluarii;
  fileToImport;
  importError = '';
  importLoading = false;
  rescriere = 'NU';
  importStats;
  importIntervalID: number;
  fileIntervalID: number;
  REFRESH_INTERVAL = 10000;
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  fisierIBNAForm: UntypedFormGroup;
  exploatatii = [];
  showHint = true;
  selectedHoldingIdError = false;
  modalFisierIBNA = false;
  modalCautare = false;
  listaDataEvaluarii;
  fisiereInCurs = [];
  fisiereFinale = [];

  columns = evaluareGeneticaColumns;
  number = evaluareGeneticaNumber;
  percent = evaluareGeneticaPercent;
  columnsDesc = evaluareGeneticaColumnsDesc;

  constructor(
    public evaluareGeneticaService: EvaluareGeneticaService,
    public modalService: NgbModal,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();

    this.fisierIBNAForm = new UntypedFormGroup({
      data_evaluarii: new UntypedFormControl(),
      exploatatie_id: new UntypedFormControl(),
      data_nastere_inceput: new UntypedFormControl(),
      data_nastere_sfarsit: new UntypedFormControl(),
      status: new UntypedFormControl(),
      rasa: new UntypedFormControl(),
      sex: new UntypedFormControl(),
      numarmatricol: new UntypedFormControl(),
      sectiune: new UntypedFormControl(),
    });

    this.fileIntervalID = window.setInterval(this.updateListaFisiere.bind(this), this.REFRESH_INTERVAL);

    this.updateListaFisiere();
    this.getTableData();
  }

  updateImportInCurs() {
    this.evaluareGeneticaService.inCursAPI().subscribe((response) => {
      if (response) {
        this.importLoading = false;
        this.importStats = response;

        if (this.importIntervalID) {
          window.clearInterval(this.importIntervalID);
        }
      }
    });
  }

  updateListaFisiere() {
    this.evaluareGeneticaService.listaFisiereAPI().subscribe(
      (response) => {
        this.fisiereInCurs = response.in_curs;
        this.fisiereFinale = response.final;
      });
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.listaDate = [];
    let list_params = {
      ...this.req_params,
      ...this.fisierIBNAForm.value
    };

    this.evaluareGeneticaService.listAPI(list_params).subscribe((response) => {
      this.loadingDataSpinner = false;
      this.listaDate = response.result;
      this.listaDate = this.listaDate.map(
        (item) => {
          item.animal_link = item.animal_id ? '/dashboard/animal/' + item.animal_id + '/pedigree-rg' : '';
          item.exploatatie_link = item.cod_exploatatie ? '/dashboard/exploatatie/' + item.cod_exploatatie + '/animale-exploatatie' : '';
          item.mama_link = item.mama_id ? '/dashboard/animal/' + item.mama_id + '/pedigree-rg' : '';
          item.tata_link = item.tata_id ? '/dashboard/animal/' + item.tata_id + '/pedigree-rg' : '';
          return item;
        }
      )
    });
  }

  changePageSize(event) {
    this.req_params.page_no = 1;
    this.req_params.page_size = event.target.value;
    this.evaluareGeneticaService.page = 1;
    this.getTableData();
  }
  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  importFisier() {
    this.modalService.open(this.importFisierModal, {
      scrollable: false,
    });
  }

  onFileSelected(event) {
    const file:File = event.target.files[0];

    if (file) {
        this.fileToImport = file;
    }
  }

  sendImportFiles() {
    this.importError = '';
    this.importStats = null;

    if (! this.dataEvaluarii) {
      this.importError = 'Lipsește Data evaluării';

      return;
    }

    if (! this.fileToImport) {
      this.importError = 'Lipsește fișierul pentru import';

      return;
    }

    this.importLoading = true;
    const formData = new FormData();
    formData.append('data_evaluarii', this.dataEvaluarii);
    formData.append('fisier', this.fileToImport);
    formData.append('rescriere', this.rescriere);

    this.evaluareGeneticaService.importAPI(formData).subscribe(
      (response) => {
        this.importIntervalID = window.setInterval(this.updateImportInCurs.bind(this), this.REFRESH_INTERVAL);
      },
      (errors) => {
        this.importError = errors.error.error;
      });
  }

  ngOnDestroy() {
    if (this.importIntervalID) {
      window.clearInterval(this.importIntervalID);
    }

    if (this.fileIntervalID) {
      window.clearInterval(this.fileIntervalID);
    }
  }

  openModalFisierIBNA() {
    this.modalFisierIBNA = true;
    this.modalCautare = false;
    this.modalService.open(this.cautareGenerareModal, {
      scrollable: false,
    });
  }

  openModalCautare() {
    this.modalCautare = true;
    this.modalFisierIBNA = false;
    this.modalService.open(this.cautareGenerareModal, {
      scrollable: false,
    });

    if (! this.listaDataEvaluarii) {
      this.evaluareGeneticaService.listaDataEvaluariiAPI().subscribe(
        (response) => {
          this.listaDataEvaluarii = response.result;
        });
    }
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.showHint = true;
      this.exploatatii = [];

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        }
      });
  }

  clearHoldings() {
    this.showHint = true;
  }

  cautareEvaluareGenetica() {
    this.req_params.page_no = 1;
    this.evaluareGeneticaService.page = 1;
    this.getTableData();
  }

  genereazaFisierIBNA(event) {
    event.target.disabled = true;
    let gen_params = this.fisierIBNAForm.value;

    this.evaluareGeneticaService.genereazaFisierAPI(gen_params).subscribe(
      (response) => {
        event.target.disabled = false;
        this.updateListaFisiere();
        return response;
      });
  }

  exportCautare(event) {
    event.target.disabled = true;
    let export_params = this.fisierIBNAForm.value;

    this.evaluareGeneticaService.exportAPI(export_params).subscribe(
      (response) => {
        event.target.disabled = false;
        this.updateListaFisiere();
        return response;
      });
  }

  stergeFisier(fisier) {
    let sterge_params = {fisier: fisier}
    this.evaluareGeneticaService.stergeAPI(sterge_params)
      .subscribe(() => {
        this.updateListaFisiere();
      });
  }

  downloadFisier(fisier) {
    this.evaluareGeneticaService.downloadAPI(fisier)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/xls' });
        this.helperDataService.simulateDownload(blob, fisier);
        this.stergeFisier(fisier);
      });
  }

  resetFields() {
    if (this.fisierIBNAForm) {
      this.fisierIBNAForm.reset();
    }
  }

  resetSearch() {
    this.resetFields();
    this.cautareEvaluareGenetica();
  }
}
