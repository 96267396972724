<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="d-flex justify-content-between col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="documentIstoricService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changePageSize($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>

              <div class="align-self-end">
                <span
                  *ngIf="showResetList"
                  role="button"
                  class="h2 pointer"
                  ngbTooltip="Reîncarcă"
                  (click)="cauta()"
                >&#10226;</span>
              </div>
            </div>

            <!-- Search -->
            <div
              *ngIf="isAdmin || isOperator"
              class="col-sm-12 col-lg-6"
            >
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="clickSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>

                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="export($event)"
                  [disabled]="loadingDataSpinner"
                  placement="bottom"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <div
            class="spinner-border text-info table-spinner avatar-lg"
            role="status"
            *ngIf="loadingDataSpinner"
          ></div>

          <!-- Table -->
          <div class="table-responsive">
            <table
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th class="data">
                    Data
                  </th>
                  <th class="nume_exploatatie">
                    Nume exploatație
                  </th>
                  <th class="tip">
                    Tip
                  </th>
                  <th class="tip_document">
                    Tip document
                  </th>
                  <th class="numar_document">
                    Nr/Data document
                  </th>
                  <th class="nr_inregistrari">
                    Nr înreg.
                  </th>
                  <th class="status_vechi">
                    Status vechi
                  </th>
                  <th class="status_nou">
                    Status nou
                  </th>
                  <th class="user">
                    Numele și username
                  </th>
                </tr>
              </thead>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let item of listaIstoric">
                  <td>{{ item.data_text }}</td>
                  <td>{{ item.nume_exploatatie }}</td>
                  <td>{{ item.tip }}</td>
                  <td>
                    <a
                      [routerLink]="item.link_documente"
                      target="_blank"
                    >
                      {{ item.tip_document_text }}
                    </a>
                  </td>
                  <td>
                    <a
                      class="link-document"
                      (click)="clickDocumentNr(item)"
                    >
                      {{ item.numar_document }} / {{ item.data_document }}
                    </a>
                  </td>
                  <td>{{ item.nr_inregistrari }}</td>
                  <td>{{ item.status_vechi }}</td>
                  <td>{{ item.status_nou }}</td>
                  <td>{{ item.user_full_name }} ({{ item.username }})</td>
                </tr>
              </tbody>
            </table>

            <ngb-alert
              type="danger"
              class="text-center search-error"
              *ngIf="!loadingDataSpinner && documentIstoricService.totalRecords === 0"
              [dismissible]="false"
            >
              Nu există înregistrări
            </ngb-alert>

          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="documentIstoricService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ documentIstoricService.startIndex }} la
                {{ documentIstoricService.endIndex }} din
                {{ documentIstoricService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="documentIstoricService.totalRecords"
                  [(page)]="documentIstoricService.page"
                  [pageSize]="documentIstoricService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changePageNumber($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #searchModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Caută activități
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="searchForm">

        <div class="row">
          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="start_date">
                      Dată început
                  </label>
                  <app-date-picker
                    [selectedDate]="searchForm.controls.start_date.value"
                    (dateChanged)="onStartDateChanged($event)">
                  </app-date-picker>
              </div>
          </div>

          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="end_date">
                      Dată sfârșit
                  </label>
                  <app-date-picker
                    [selectedDate]="searchForm.controls.end_date.value"
                    (dateChanged)="onEndDateChanged($event)">
                  </app-date-picker>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="exploatatie_id">
                Exploatație
              </label>
              <ng-select
                [items]="exploatatii"
                bindLabel="viewLabel"
                bindValue="id"
                formControlName="exploatatie_id"
                [required]="true"
                placeholder="Alege exploatația"
                (search)="searchExploatatii($event)"
              >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <div class="row">
                    <div class="col-md-12 col-lg-auto">
                      {{item.name}}
                      <span *ngIf="item.code" class="small mr-1"> - {{ item.code }}</span>
                      <span *ngIf="item.status != 1" class="badge bg-secondary">Inactiv</span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <small
                *ngIf="showHint"
                class="form-text text-muted ml-2"
              >
                <b>Introdu minim 5 caractere pentru a căuta</b>
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="search_text">
                Căutare utilizator după nume, username sau email
              </label>

              <ng-select
                [items]="listaUseri"
                bindLabel="text"
                bindValue="id"
                formControlName="user_id"
                [required]="true"
                placeholder="Alege utilizator"
                (search)="searchUser($event.term)"
              >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <div class="row">
                    <div class="col-md-12 col-lg-auto">
                      {{ item.first_name }} {{ item.last_name }}
                      ( {{ item.username }} )
                      <span *ngIf="!item.is_active" class="badge bg-secondary">Inactiv</span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <small
                class="form-text text-muted ml-2"
              >
                <b>Introdu minim 5 caractere pentru a căuta</b>
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="tip_utilizator">
                Tip utilizator
              </label>

              <ng-select formControlName="tip_utilizator" placeholder="Toate">
                <ng-option value='admin'>Admin</ng-option>
                <ng-option value='operator'>Operator</ng-option>
                <ng-option value='fermier'>Fermier</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
                <label for="tip_document">
                  Tip document
                </label>
                <ng-select formControlName="tip_document" placeholder="Toate">
                  <ng-option value='adeverinta_ajutor'>Adeverință Ajutor</ng-option>
                  <ng-option value='adeverinta_apia'>Adeverință APIA</ng-option>
                  <ng-option value='anexa3'>Anexa 3</ng-option>
                  <ng-option value='anexa8'>Anexa 8</ng-option>
                  <ng-option value='anexa10'>Anexa 10</ng-option>
                  <ng-option value='autorizatie_monta'>Autorizație Montă</ng-option>
                  <ng-option value='avizare_taur'>Avizare Taur</ng-option>
                  <ng-option value='certificat_sanitar'>Certificat Sanitar</ng-option>
                  <ng-option value='certificat_zootehnic'>Certificat Zootehnic</ng-option>
                  <ng-option value='solicitare_cz'>Solicitare CZ</ng-option>
                </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3 d-flex">
              <input
                type="checkbox"
                formControlName="filtreaza_doc_create_fara_modificare"
                (change)="changeCreate($event)"
              />

              <span class="ml-2 font-weight-bold">
                Afișează documentele care au fost doar create, fără altă modificare
              </span>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="tip">
                    Tip
                  </label>
                  <ng-select formControlName="tip" placeholder="Toate">
                      <ng-option value='creare'>Creare</ng-option>
                      <ng-option value='modificare'>Modificare</ng-option>
                  </ng-select>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
            <div class="btn-group">
              <button class="btn btn-sm btn-blue" (click)="cauta(); modal('Cross click')">
                <i class="fe-search"></i>
                Caută
              </button>
              <button class="btn btn-sm btn-warning" (click)="resetFields()">
                <i class="fe-delete"></i>
                Resetează câmpurile
              </button>
            </div>
          </div>

          <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
            <button class="btn btn-sm btn-danger" (click)="resetSearch(); modal('Cross click')">
              Resetează căutarea
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>
</ng-template>
