import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IncadrareSectiuneService {
  private baseUrl = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  uploadCSVFile(formData) {
    return this.http
      .post(
        `${this.baseUrl}/animals/fit-csv-file/`,
        formData
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  inCursAPI(unique_id) {
    return this.http
      .post(
        `${this.baseUrl}/animals/fit-csv-file/in-curs/`,
        { unique_id }
      ).pipe(map((response: any) => {
        return response;
      }));
  }
}
