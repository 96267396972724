import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Operator } from '../../../common/models/operator.models';
import { OperatorsTableService } from '../../../common/services/operators-table.service';
import { HelperDataService } from '../../../common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnInit {

  tables$: Observable<Operator[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;
  isAdmin = false;
  isOperator = false;

  objToAPI = {
    page_nr: '1',
    page_size: '10'
  };

  constructor(
    public operatorsTableService: OperatorsTableService,
    public helperDataService: HelperDataService
  ) {
    this.tables$ = operatorsTableService.tables$;
    this.total$ = operatorsTableService.total$;
  }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();

    if (this.isAdmin || this.isOperator) {
      this.getTableData();
    }
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.operatorsTableService
      .getOperatorsAPI(this.objToAPI).subscribe().add(() => {
        this.loadingDataSpinner = false;
      });
  }

  filterSearchData(event) {
    // Compose the object what will be sent to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  exportData(event) {
    let now = UIHelper.now();
    let fisier = `Operatori_${now}.xls`;

    this.operatorsTableService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response]);
        this.helperDataService.simulateDownload( blob, fisier);
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_nr = event;
    this.getTableData();
  }

  getTableCallback(event) {
    this.getTableData();
  }

}
