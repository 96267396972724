<form [formGroup]="certificatListSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6" *ngIf="!isFermier">
      <div class="form-group mb-3">
        <label for="company_name">
          Denumirea exploatației
        </label>

        <input
          type="text"
          id="company_name"
          formControlName="company_name"
          placeholder="Adaugă denumirea exploatației"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isFermier">
      <div class="form-group mb-3">
        <label for="company_code">
          Codul exploatației
        </label>

        <input
          type="text"
          id="company_code"
          formControlName="company_code"
          placeholder="Adaugă codul exploatației"
          class="form-control" />
      </div>
    </div>

    <div class="col-12">
      <div class="form-group mb-3">
        <label for="numarmatricol">
          Număr matricol
        </label>

        <input
          type="text"
          id="numarmatricol"
          formControlName="numarmatricol"
          placeholder="Adaugă numărul matricol"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
