<div class="container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-lg-6 mb-1">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center">
                    Afișează
                    <select
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="solicitariCertificateZootehniceService.pageSize"
                      [disabled]="loadingDataSpinner"
                      (change)="changePageSize($event.target.value)"
                      class="custom-select custom-select-sm form-control form-control-sm mx-2"
                    >
                      <option value="10">10</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    intrări
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-lg-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
                >
                  <button
                    class="btn btn-info btn-sm"
                    type="button"
                    placement="left"
                    [disabled]="loadingDataSpinner"
                    (click)="clickSearchModal()"
                  >
                    <i class="fe-search"></i>
                    Căutare
                  </button>

                  <button
                    *ngIf="isAdmin || isOperator || isFermier"
                    class="btn btn-blue btn-sm"
                    type="button"
                    (click)="openAddModal()"
                    [disabled]="loadingDataSpinner"
                  >
                    <i class="fe-save"></i>
                    Solicitare Certificat Zootehnic
                  </button>

                  <button
                    *ngIf="isAdmin || isOperator"
                    class="btn btn-success btn-sm"
                    type="button"
                    (click)="export($event)"
                    [disabled]="loadingDataSpinner"
                    ngbTooltip="Se vor exporta doar datele afișate"
                    placement="bottom"
                  >
                    <i class="mdi mdi-file-excel"></i>
                    Exportă XLS
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngIf="loadingDataSpinner"
              class="spinner-border text-info table-spinner avatar-lg"
              role="status"
            ></div>

            <div class="table-responsive">
              <table
                class="table datatables dt-responsive nowrap table-hover"
              >
                <thead>
                  <tr>
                    <th *ngIf="!hideFields">Exploatație</th>
                    <th>Solicitare CZ</th>
                    <th>Status</th>
                    <th>Înregistrări</th>
                    <th>Acțiuni</th>
                  </tr>
                </thead>

                <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                  <tr *ngFor="let item of itemsList">
                    <td *ngIf="!hideFields">
                      <a
                        [routerLink]="'/dashboard/exploatatie/' + item.cod_exploatatie +'/documente/solicitari-certificate-zootehnice'"
                        target="_blank"
                      > {{ item.nume_exploatatie }}
                      </a>
                    </td>
                    <td>{{ item.numar }} / {{ item.data_dmy }}</td>
                    <td>{{ item.status_text }}</td>
                    <td>{{ item.nr_animale_procesate }} / {{ item.nr_animale }}</td>
                    <td class="action-buttons">
                      <div class="btn-group">

                        <button
                          class="btn btn-sm btn-success"
                          (click)="download($event, item)"
                        >
                          Descarcă
                        </button>

                        <button
                          *ngIf="isAdmin || isOperator || isFermier"
                          class="btn btn-sm btn-blue"
                          (click)="openEditModal(item)"
                        >
                          Editează
                        </button>

                        <button
                          *ngIf="isAdmin"
                          class="btn btn-sm btn-warning"
                          title="Proceseaza"
                          (click)="openProceseazaModal(item.id)"
                        >
                          Procesează
                        </button>

                        <button
                          *ngIf="item.status !== 6 && (isAdmin || isOperator || isFermier)"
                          class="btn btn-sm btn-danger"
                          [ngClass]="'sterge-id-' + item.id"
                          (click)="openDeleteModal(item.id)"
                        >
                          Șterge
                        </button>

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="!loadingDataSpinner && solicitariCertificateZootehniceService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu există înregistrări
              </ngb-alert>

            </div>

            <div
              class="row justify-content-lg-between align-items-lg-center mt-2"
              *ngIf="solicitariCertificateZootehniceService.totalRecords > 0"
            >
              <div class="col-sm-12 col-lg-6">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Afișează de la
                  {{ solicitariCertificateZootehniceService.startIndex }} la
                  {{ solicitariCertificateZootehniceService.endIndex }} din
                  {{ solicitariCertificateZootehniceService.totalRecords }}
                  intrări
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-lg-6">
                <div class="text-lg-right float-lg-right pagination-rounded">
                  <ngb-pagination
                    [disabled]="loadingDataSpinner"
                    [collectionSize]="solicitariCertificateZootehniceService.totalRecords"
                    [(page)]="solicitariCertificateZootehniceService.page"
                    [pageSize]="solicitariCertificateZootehniceService.pageSize"
                    [maxSize]="5"
                    (pageChange)="changePageNumber($event)"
                  >
                  </ngb-pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-add-edit-solicitari-certificate-zootehnice
    [addEditModalData]="addEditModalData"
    (notifyClose)="closeAddEditModal()"
  >
  </app-add-edit-solicitari-certificate-zootehnice>

  <app-confirm-action-modal
    [open]="openDelete"
    [text]="'Doriți să ștergeți Solicitarea CZ?'"
    (confirm)="closeDeleteModal($event)"
  ></app-confirm-action-modal>

  <app-proceseaza-solicitari-certificate-zootehnice
    [proceseazaModalData]="proceseazaModalData"
    (notifyClose)="closeAddEditModal()"
  >
  </app-proceseaza-solicitari-certificate-zootehnice>


  <ng-template #searchModal let-modal="close">
    <div class="modal-header">
      <h3 class="modal-title">
        Caută Solicitare CZ
      </h3>

      <button
        type="button"
        class="close"
        aria-hidden="true"
        (click)="modal('Cross click')"
      >
        ×
      </button>
    </div>

    <div class="modal-body">
      <div class="container-fluid">
        <form [formGroup]="searchForm">
          <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="start_date">
                        Dată început
                    </label>
                    <app-date-picker
                      [selectedDate]="searchForm.controls.start_date.value"
                      (dateChanged)="searchForm.controls.start_date.setValue($event)">
                    </app-date-picker>
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="end_date">
                        Dată sfârșit
                    </label>
                    <app-date-picker
                      [selectedDate]="searchForm.controls.end_date.value"
                      (dateChanged)="searchForm.controls.end_date.setValue($event)">
                    </app-date-picker>
                </div>
            </div>
          </div>

          <div
            *ngIf="!hideFields"
            class="row"
          >
            <div class="col-sm-12">
              <div class="form-group mb-3">
                <label for="exploatatie_id">
                  Exploatație
                </label>
                <ng-select
                  [items]="exploatatii"
                  bindLabel="viewLabel"
                  bindValue="id"
                  formControlName="exploatatie_id"
                  [required]="true"
                  placeholder="Alege exploatația"
                  (search)="searchExploatatii($event)"
                >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    <div class="row">
                      <div class="col-md-12 col-lg-auto">
                        {{item.name}}
                        <span *ngIf="item.code" class="small mr-1"> - {{ item.code }}</span>
                        <span *ngIf="item.status != 1" class="badge bg-secondary">Inactiv</span>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>

                <small
                  *ngIf="showHint"
                  class="form-text text-muted ml-2"
                >
                  <b>Introdu minim 5 caractere pentru a căuta</b>
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group mb-3">
                <label for="animal_id">
                  Număr matricol
                </label>

                <ng-select
                  [items]="animale"
                  (search)="searchMatricol($event)"
                  bindLabel="numarmatricol"
                  bindValue="id"
                  formControlName="animal_id"
                  placeholder="Număr matricol"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="status">
                      Status
                  </label>
                  <ng-select formControlName="status" placeholder="Toate">
                      <ng-option [value]='3'>Procesare</ng-option>
                      <ng-option [value]='4'>Admis, așteaptă semnătura</ng-option>
                      <ng-option [value]='6'>Eliberat</ng-option>
                  </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
              <div class="btn-group">
                <button class="btn btn-sm btn-blue" (click)="cauta(); modal('Cross click')">
                  <i class="fe-search"></i>
                  Caută
                </button>
                <button class="btn btn-sm btn-warning" (click)="resetFields()">
                  <i class="fe-delete"></i>
                  Resetează câmpurile
                </button>
              </div>
            </div>

            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
              <button class="btn btn-sm btn-danger" (click)="resetSearch(); modal('Cross click')">
                Resetează căutarea
              </button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </ng-template>
