import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { BirthsTableService } from 'src/app/modules/acbcr/common/services/births-table.service';
import { ReproductionsTableService } from 'src/app/modules/acbcr/common/services/reproductions-table.service';
import { MovesTableService } from 'src/app/modules/acbcr/common/services/moves-table.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-rep-fat-modal',
  templateUrl: './rep-fat-modal.component.html',
  styleUrls: ['./rep-fat-modal.component.scss']
})
export class RepFatModalComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('repFatModal') repFatModal: ElementRef;
  @Input() data = {};
  @Output() callbackResult = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();

  anexaList = [];
  anexa = null;
  anexaId;
  modalType;
  selectedItem: number;
  modalReference: NgbModalRef;

  showAddRep = false;
  showAddFat = false;
  showAddMove = false;
  showAddButton = true;
  creatDeFermier = false;

  fatareModalType: string;
  reprModalType: string;
  moveModalType: string;

  currentRoute = '';
  isAdmin = false;
  isAutoritateJudeteana = false;
  isFermier = false;
  isContabil = false;

  constructor(
    private reproductionsTableService: ReproductionsTableService,
    private birthsTableService: BirthsTableService,
    private movesTableService: MovesTableService,
    private modalService: NgbModal,
    private errorService: NotificationErrorService,
    private store: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });

    this.isAdmin = UIHelper.isAdmin();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
  }

  ngOnChanges(changes) {
    if (changes.data && this.data) {
      this.anexaId = this.data['anexa_id'];
      this.modalType = this.data['modal_type'];
      this.anexaList = [];
      this.anexa = null;
      this.showAddRep = false;
      this.showAddFat = false;
      this.showAddMove = false;
      this.getModalData();

      this.modalReference = this.modalService.open(this.repFatModal, {
        windowClass: 'modal-full',
        scrollable: true,
      });
    }
  }

  getModalData() {
    if (!this.anexaId) {
      return;
    }

    if (this.modalType === 'reproductii') {
      this.reproductionsTableService.getAnexa3Data({anexa3id: this.anexaId})
        .subscribe(response => {
          this.anexaList = response;
          this.getCreatDeFermier();
          this.closeModalWhenEmpty();
        });
    } else if (this.modalType === 'fatari') {
      this.birthsTableService.getAnexa8Data({anexa8id: this.anexaId})
        .subscribe(response => {
          this.anexaList = response;
          this.getCreatDeFermier();
          this.closeModalWhenEmpty();
        });
    } else if (this.modalType === 'miscari') {
      this.movesTableService.getAnexa10Data({anexa10id: this.anexaId})
        .subscribe(response => {
          this.anexaList = response;
          this.getCreatDeFermier();
          this.closeModalWhenEmpty();
        });
    }
  }

  getCreatDeFermier() {
    if (this.anexaList && this.anexaList[0]) {
      this.creatDeFermier = this.anexaList[0].creat_de_fermier;
    }
  }

  closeModalWhenEmpty() {
    if (this.modalReference && this.anexaList && (this.anexaList.length === 0)) {
      this.modalReference.close();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addReproductie(type) {
    this.reprModalType = type;
    this.showAddRep = true;
    this.selectedItem = -1;
  }

  addFatare(type) {
    this.showAddFat = true;
    this.fatareModalType = type;
    this.selectedItem = -1;
  }

  addMiscare(type) {
    this.showAddMove = true;
    this.moveModalType = type;
    this.selectedItem = -1;
  }

  editReproductie(event) {
    this.reprModalType = 'edit-reproductie';

    this.reproductionsTableService
      .getAnexa3(event.montaid)
      .subscribe(response => {
        this.anexa = response;
        this.showAddRep = true;
      });
  }

  editFatare(event) {
    this.fatareModalType = 'edit-fatare';

    this.birthsTableService.getFatareAPI(event.fatareid)
      .subscribe(response => {
        this.anexa = response;
        this.showAddFat = true;
      });
  }

  editMiscare(event) {
    this.moveModalType = 'edit-miscare';

    this.anexa = event;
    this.anexaId = event.anexa10id;
    this.showAddMove = true;
  }

  verificat() {
    let params = {
      'modal_type': this.modalType,
      'anexa_id': this.anexaId,
    }

    this.reproductionsTableService.verificatAPI(params)
      .subscribe({
        next: (response) => {
          this.callbackResult.emit(null);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  makeCloseForm(event) {
    this.showAddRep = false;
    this.showAddFat = false;
    this.showAddMove = false;
    this.anexa = null;
    this.selectedItem = -1;
  }

  onSelectedItemChanged(event) {
    this.selectedItem = event;
  }

  makeCallbackResult(event) {
    if (event['anexa3id']) {
      this.anexaId = event['anexa3id'];
    } else if (event['anexa8id']) {
      this.anexaId = event['anexa8id'];
    } else if (event['anexa10id']) {
      this.anexaId = event['anexa10id'];
    }

    this.getModalData();
    this.callbackResult.emit(event);
  }
}
