import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { AvizareTauriService } from 'src/app/modules/acbcr/common/services/avizare-tauri.service';


@Component({
  selector: 'app-avizare-tauri',
  templateUrl: './avizare-tauri.component.html',
  styleUrls: ['./avizare-tauri.component.scss']
})
export class AvizareTauriComponent implements OnInit {
  @ViewChild('searchModal') searchModal: ElementRef;

  loadingDataSpinner = false;
  itemsList = [];
  totalCount: number;
  addEditModalData;
  openDelete = false;
  idDelete;
  openFinalizeaza = false;
  idFinalizeaza;
  openAnuleaza = false;
  idAnuleaza;
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  avizareTauriForm: UntypedFormGroup;

  req_params = {
    page_no: 1,
    page_size: 10,
  };

  constructor(
    public avizareTauriService: AvizareTauriService,
    public modalService: NgbModal,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.avizareTauriForm = new UntypedFormGroup({
      start_date: new UntypedFormControl(),
      end_date: new UntypedFormControl(),
      company_name: new UntypedFormControl(),
      company_code: new UntypedFormControl(),
      numarmatricol: new UntypedFormControl(),
      rasa: new UntypedFormControl(),
      status: new UntypedFormControl(),
      status_animal: new UntypedFormControl(),
    });

    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.itemsList = [];
    let list_params = {
      ...this.req_params,
      ...this.avizareTauriForm.value
    };

    this.avizareTauriService.listAPI(list_params)
      .subscribe({
        next: (response) => {
          this.loadingDataSpinner = false;
          this.itemsList = response.result;
          this.totalCount = response.count;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
        }
      });
  }

  changePageSize(event) {
    this.req_params.page_no = 1;
    this.req_params.page_size = event.target.value;
    this.avizareTauriService.page = 1;
    this.getTableData();
  }

  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  openAddModal() {
    this.addEditModalData = {id: null};
  }

  openEditModal(item) {
    this.addEditModalData = {id: item?.id};
  }

  closeAddEditModal() {
    this.ngOnInit();
  }

  search() {
    this.req_params.page_no = 1;
    this.avizareTauriService.page = 1;
    this.getTableData();
  }

  resetFields() {
    if (this.avizareTauriForm) {
      this.avizareTauriForm.reset();
    }
  }

  resetSearch() {
    this.resetFields();
    this.search();
  }

  openDeleteModal(id) {
    this.openDelete = true;
    this.idDelete = id;
  }

  closeDeleteModal(event) {
    this.openDelete = false;

    if (event != true) {
      return;
    }

    let buton = document.getElementsByClassName('sterge-id-' + this.idDelete)[0];
    buton?.setAttribute('disabled', 'true');
    let params = {
      id: this.idDelete,
    }

    this.avizareTauriService.stergeAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
        }
      });
  }

  openFinalizeazaModal(id) {
    this.openFinalizeaza = true;
    this.idFinalizeaza = id;
  }

  closeFinalizeazaModal(event) {
    this.openFinalizeaza = false;

    if (event != true) {
      return;
    }

    let buton = document.getElementsByClassName('finalizeaza-id-' + this.idFinalizeaza)[0];
    buton?.setAttribute('disabled', 'true');
    let params = {
      id: this.idFinalizeaza,
    }

    this.avizareTauriService.finalizeazaAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
        }
      });
  }

  openAnuleazaModal(id) {
    this.openAnuleaza = true;
    this.idAnuleaza = id;
  }

  closeAnuleazaModal(event) {
    this.openAnuleaza = false;

    if (event != true) {
      return;
    }

    let buton = document.getElementsByClassName('anuleaza-id-' + this.idAnuleaza)[0];
    buton?.setAttribute('disabled', 'true');
    let params = {
      id: this.idAnuleaza,
    }

    this.avizareTauriService.anuleazaAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
        }
      });
  }

  download(event, item) {
    event.target.disabled = true;
    let fisier = `Avizare_taur_${item.matricol_taur}.pdf`;

    this.avizareTauriService.downloadAPI(item.id)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }

  export(event) {
    event.target.disabled = true;
    let export_params = this.avizareTauriForm.value;
    let now = UIHelper.now();
    let fisier = `Avizare_tauri_${now}.xls`;

    this.avizareTauriService.exportAPI(export_params)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }

}
