import { UserType } from '../../../common/models/common.models';
import { OptionsProvider as auth } from '../../../common/services/options-provider';
import { environment } from 'src/environments/environment';

export class UIHelper {
  public static get uploadConfig() {
    const baseUrl = environment.API_URL;

    return {
      multiple: false,
      maxSize: '100',
      uploadAPI: {
        url: `${baseUrl}/files/upload/`,
        headers: {
          Authorization: `Bearer ${localStorage['access-token']}`
        }
      },
      autoUpload: true,
      hideProgressBar: false,
      hideResetBtn: true,
      hideSelectBtn: false,
      replaceTexts: {
        selectFileBtn: 'Selectați fișierul',
        resetBtn: 'Resetează',
        uploadBtn: 'Încarcă fișierul',
        afterUploadMsg_success: 'Încărcare reușită! Pasul următor e salvarea formularului.',
        afterUploadMsg_error: 'Încărcare eșuată!'
      }
    }
  };

  public static getCurrentDate(): string {
    const date = new Date();
    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
  }

  public static getCurrentTime(): string {
    const currentTime = new Date();
    const minutes = currentTime.getMinutes();
    const strMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${currentTime.getHours()}:${strMinutes}`;
  }

  public static now(): string {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year}_${hours}-${minutes}`;
  }

  public static generateUniqueId(): string {
    return Math.random().toString(36).substring(2, 11);
  }

  public static isLetter(c: string): boolean {
    if (c.length === 1 && c.match(/^[a-zA-Z]+$/)) {
      return true;
    }

    return false;
  }

  public static hasPermission(url): boolean {
    let userType = auth.user.user_type;

    if ((
       (url === '/dashboard/setari' || url === '/dashboard/operatori-inseminare' ||
        url === '/dashboard/localitati' || url === '/dashboard/utilizatori') &&
       (userType === UserType.Operator || userType === UserType.Fermier ||
        userType === UserType.Ojz || userType === UserType.Anz || userType === UserType.Contabil)) ||
       (url.includes('/contracte') && (userType === UserType.Ojz || userType === UserType.Anz))
    ) {
      return false;
    } else {
      return true;
    }
  }

  public static isSuperAdmin(): boolean {
    return auth.user.user_type == UserType.SuperAdmin;
  }

  public static isAdmin(): boolean {
    return auth.user.user_type == UserType.SuperAdmin || auth.user.user_type == UserType.AdminACBCR;
  }

  public static isOperator(): boolean {
    return auth.user.user_type == UserType.Operator;
  }

  public static isFermier(): boolean {
    return auth.user.user_type == UserType.Fermier;
  }

  public static isAutoritateJudeteana(): boolean {
    return auth.user.user_type === UserType.Ojz || auth.user.user_type === UserType.Anz;
  }

  public static isOjz(): boolean {
    return auth.user.user_type === UserType.Ojz;
  }

  public static isContabil(): boolean {
    return auth.user.user_type === UserType.Contabil;
  }

  public static userEmail() {
    return auth.user.email;
  }

  public static username() {
    return auth.user.username;
  }

  public static userId() {
    return auth.user.user_id;
  }
}
