<div class="container-fluid mt-3">
  <div class="col-12">
    <div class="card settings-card">
      <div class="row">
        <div class="col-12">
          <p class="settings-type">Criteriile cantitative</p>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="table table-bordered dt-responsive nowrap table-hover"
        >
          <thead>
            <tr>
              <th>
                UNITATEA DE MĂSURĂ
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of criteriiList;
                let i = index;
              "
            >
              <td>
                {{item.descriere}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>