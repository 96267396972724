import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { AdvancedSortableDirective } from '../../../../directives/advanced-sortable.directive';
import { ReproductionsTableService } from '../../../../common/services/reproductions-table.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { takeUntil } from 'rxjs/operators';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-reproduction-table',
  templateUrl: './reproduction-table.component.html',
  styleUrls: ['./reproduction-table.component.scss']
})
export class ReproductionTableComponent implements OnInit, OnDestroy {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('reproductionsTable', { static: false }) reproductionsTable: ElementRef;

  @Input() reproductions = [];
  @Input() reproductionsLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() reprTableCallback = new EventEmitter();

  openSearchModal = new Subject<boolean>();
  readonly modalType = 'reproduction';
  destroy$: Subject<boolean> = new Subject<boolean>();

  currentRoute: string;
  modalData;
  isAdmin = false;
  isOperator = false;
  isFermier = false;

  constructor(
    public reproductionsTableService: ReproductionsTableService,
    private store: Store<ApplicationState>,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    // Read current app route from the store
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  openReproductiiModal() {
    this.modalData = {
      'anexa_id': null,
      'modal_type': 'reproductii',
    };
  }

  openAnexa3(anexa3Id) {
    this.modalData = {
      'anexa_id': anexa3Id,
      'modal_type': 'reproductii',
    };
  }

  getModalResult(event) {
    this.reprTableCallback.emit(event);
  }
}
