import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { AdvancedSortableDirective, SortEvent } from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { RaportFactura } from 'src/app/modules/acbcr/common/models/rapoarte-facturi.model';
import { RapoarteFacturiTableService } from 'src/app/modules/acbcr/common/services/rapoarte-facturi.service';

@Component({
  selector: 'app-rapoarte-facturi-table',
  templateUrl: './rapoarte-facturi-table.component.html',
  styleUrls: ['./rapoarte-facturi-table.component.scss']
})
export class RapoarteFacturiTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
  AdvancedSortableDirective>;
  @ViewChild('rapoarteFacturiTable', { static: false }) rapoarteFacturiTable: ElementRef;

  @Input() rapoarteFacturi: RaportFactura[];
  @Input() rapoarteFacturiLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() rapoarteFacturiTableCallback = new EventEmitter();

  readonly modalType = 'rapoarte-facturi';
  openSearchModal = new Subject<boolean>();

  currentRoute: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public rapoarteFacturiTableService: RapoarteFacturiTableService,
    private store: Store<ApplicationState>,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.rapoarteFacturiTableService.sortColumn = column;
    this.rapoarteFacturiTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  getModalResult(event) {
    this.rapoarteFacturiTableCallback.emit(event);
  }

}
