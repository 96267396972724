import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';
import { RegistruGenealogicTableService } from 'src/app/modules/acbcr/common/services/registru-genealogic-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-registru-genealogic-table',
  templateUrl: './registru-genealogic-table.component.html',
  styleUrls: ['./registru-genealogic-table.component.scss'],
})
export class RegistruGenealogicTableComponent implements OnInit {
  @ViewChild('registreTable', { static: false }) registreTable: ElementRef;

  @Input() registre: any[];
  @Input() registreLength: number;
  @Input() loadingDataSpinner: boolean;
  @Input() dataChanged;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  reasonModalData = {};
  openSearchModal = new Subject<boolean>();
  openAddEditModal = false;
  selectAllCheckbox = false;
  matricole;
  resetUpload = false;
  isAdmin = false;
  isOperator = false;
  searchParams = {};

  constructor(
    public registruGenealogicTableService: RegistruGenealogicTableService,
    public helperDataService: HelperDataService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.searchParams = event;
    this.filterData.emit(event);
  }

  viewReasons(animalId) {
    this.reasonModalData = {'animal_id': animalId};
  }

  exportXls() {
    this.loadingDataSpinner = true;
    let now = UIHelper.now();
    let fisier = `Registru_genealogic_${now}.xls`;

    this.registruGenealogicTableService.exportAPI(this.searchParams)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          this.loadingDataSpinner = false;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
        }
      });
  }
}
