import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApplicationState } from '../app.state';
import { AppCompanyAdminsActions, AppCompanyAdminsActionType } from '../actions/company-admins.actions';

export interface AppCompanyAdmins {
  company_admins: any[];
}

export const initialState = {
  company_admins: []
};

export const getApplicationFeature =
  createFeatureSelector<ApplicationState>('appData');

export const getAppCompanyAdmins =
  createSelector(
    getApplicationFeature,
    state => state.company_admins.company_admins
  );

export function companyAdminsReducers(
  state: AppCompanyAdmins = initialState,
  action: AppCompanyAdminsActions
) {
  switch (action.type) {
    case AppCompanyAdminsActionType.SET_ADMIN_COMPANY_DATA:

      return {
        ...state,
        company_admins: action.payload
      };

    case AppCompanyAdminsActionType.ADD_ADMIN_COMPANY_DATA:

      return {
        ...state,
        company_admins: [
          ...state.company_admins,
          { ...action.payload }
        ]
      };

    case AppCompanyAdminsActionType.UPDATE_ADMIN_COMPANY_DATA:

      return {
        ...state,
        company_admins: state.company_admins.map(
          admin => admin.id === action.payload.id ? { ...action.payload } : admin
        )
      };


    default:
      return state;
  }
}
