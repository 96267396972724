<div class="input-group date-picker" [formGroup]="formGroup">
  <input
    type="text"
    class="form-control border-gray picker-input"
    ngbDatepicker
    formControlName="datePicker"
    #datePicker="ngbDatepicker"
    placeholder="ZZ.LL.AAAA"
    (dateSelect)="onDateChanged($event)"
    (blur)="onDateInputChange($event)"
    [ngClass]="{ 'is-invalid': (submitted && picker.errors) || !validDate || invalid }"
    [readonly]="disabled"
  />
  <span
    *ngIf="formGroup.touched && !disabled"
    class="btn-clear-date mdi mdi-close"
    (click)="resetDatePicker()"
  ></span>
  <div
    class="input-group-append"
    (click)="datePicker.toggle()"
    [ngClass]="{ 'disabled': disabled }"
  >
    <span class="input-group-text bg-blue border-blue text-white">
      <i class="mdi mdi-calendar-range font-13"></i>
    </span>
  </div>
</div>
<div
  *ngIf="submitted && picker.errors"
  class="invalid-feedback"
  [ngClass]="{ 'd-block': submitted && picker.errors }"
>
  <div *ngIf="picker.errors.required">
    Completarea acestui câmp este obligatorie.
  </div>
</div>
<div
  *ngIf="!validDate"
  class="invalid-feedback"
  [ngClass]="{ 'd-block': !validDate }"
>
  <div *ngIf="!validDate">
    Data introdusă nu este validă.
  </div>
</div>
