<div class="container-fluid mt-3">
  <div class="row contact-row">
    <div class="col-lg-6" *ngIf="settings">
      <div class="card-box text-center">
        <div class="text-left mt-3">
          <h4 class="font-13 text-uppercase">Despre noi :</h4>
          <p class="text-muted font-13 mb-3">
            {{ settings.asociatie_nume }}
          </p>

          <p class="text-muted mb-2 font-13"><strong><i class="mdi mdi-cellphone text-muted mr-2"></i>Mobil :</strong>
            <span class="ml-2">{{ settings.asociatie_tel }}</span>
          </p>

          <p class="text-muted mb-2 font-13"><strong><i class="mdi mdi-email text-muted mr-2"></i>Email :</strong>
            <span class="ml-2 ">{{ settings.asociatie_email }}</span>
          </p>

          <p class="text-muted mb-1 font-13"><strong><i class="mdi mdi-map text-muted mr-2"></i>Adresa :</strong>
            <span class="ml-2">{{ settings.asociatie_adresa }}</span>
          </p>
        </div>

        <ul class="social-list list-inline mt-3 mb-0">
          <li class="list-inline-item">
              <a href="javascript: void(0);" class="social-list-item border-purple text-purple"><i
                      class="mdi mdi-facebook"></i></a>
          </li>
          <li class="list-inline-item">
              <a href="javascript: void(0);" class="social-list-item border-danger text-danger"><i
                      class="mdi mdi-google"></i></a>
          </li>
          <li class="list-inline-item">
              <a href="javascript: void(0);" class="social-list-item border-info text-info"><i
                      class="mdi mdi-twitter"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
