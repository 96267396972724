<div class="card" id="identificare" [formGroup]="holdingForm">
  <h3 class="card-header">
    Identificare
    <div class="btn-group btn-group-toggle float-right custom-table-btn-group">
      <span *ngIf="holdingForm.value.are_contract_activ" class="small mr-2 align-self-center text-success">
        Are contract activ
      </span>

      <input
        type="radio"
        formControlName="status"
        value="0"
        id="status-inactiv"
        class="d-none"
        (change)="onStatusChange($event)"
      />
      <label for="status-inactiv" class="btn btn-sm btn-outline-secondary"
        [ngClass]="{
          'disabled': holdingForm.value.are_contract_activ == true,
          'active': holdingForm.value.status === '0',
        }"
      >Inactiv</label>

      <input
        type="radio"
        formControlName="status"
        value="-1"
        id="status-in-asteptare"
        class="d-none"
        (change)="onStatusChange($event)"
      />
      <label for="status-in-asteptare" class="btn btn-sm btn-outline-warning"
        [ngClass]="{
          'disabled': holdingForm.value.are_contract_activ == true,
          'active': holdingForm.value.status === '-1',
        }"
      >În așteptare</label>

      <input
        type="radio"
        formControlName="status"
        value="5"
        id="status-import"
        class="d-none"
        (change)="onStatusChange($event)"
      />
      <label for="status-import" class="btn btn-sm btn-outline-info"
        [ngClass]="{
          'disabled': holdingForm.value.are_contract_activ == true,
          'active': holdingForm.value.status === '5',
        }"
      >Import</label>

      <input
        type="radio"
        formControlName="status"
        value="1"
        id="status-activ"
        class="d-none"
        (change)="onStatusChange($event)"
      />
      <label for="status-activ" class="btn btn-sm btn-outline-success"
        [ngClass]="{
          'active': holdingForm.value.status === '1',
        }"
      >Activ</label>
    </div>
  </h3>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group mb-3">
          <label for="name">
            Denumire exploatație*
          </label>
          <input
            type="text"
            id="name"
            formControlName="name"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            placeholder="Adaugă denumirea exploatației"
            class="form-control input-lg"
            (input)="
              holdingForm.patchValue({
                name: $event.target.value.toUpperCase()
              })
            "
          />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="code">
            Cod exploatație{{f.status.value !== '5' ? '*' : ''}}
          </label>
          <input
            type="text"
            id="code"
            formControlName="code"
            [ngClass]="{
              'is-invalid': submitted && f.code.errors,
              'disabled': ! isSuperAdmin && modalType === 'edit'
            }"
            placeholder="Adaugă codul exploatației"
            class="form-control"
            (input)="
              holdingForm.patchValue({
                code: $event.target.value.toUpperCase()
              })
            "
          />

          <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
            <div *ngIf="f.code.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>

            <div *ngIf="f.code.errors.bad_format">
              Codul trebuie să înceapă cu RO și să conțină 10 cifre
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="organizationtype">
            Forma juridică de organizare{{f.status.value !== '5' ? '*' : ''}}
          </label>

          <ng-select
            [items]="juridicForms"
            formControlName="organizationtype"
            [ngClass]="{
              'is-invalid': submitted && f.organizationtype.errors
            }"
            bindLabel="name"
            bindValue="id"
            (change)="changeJuridicForms($event)"
            placeholder="Selectează forma juridică"
          >
          </ng-select>

          <div
            *ngIf="submitted && f.organizationtype.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.organizationtype.errors }"
          >
            <div *ngIf="f.organizationtype.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="cif">
            <span *ngIf="!displayCUI; else showCUI">CNP{{f.status.value !== '5' ? '*' : ''}}</span>
            <ng-template #showCUI>
              <span>CUI{{f.status.value !== '5' ? '*' : ''}}</span>
            </ng-template>
          </label>

          <input
            *ngIf="displayCUI"
            type="text"
            id="cif"
            formControlName="cif"
            [ngClass]="{
              'is-invalid': (submitted && f.cif.errors) || !invalidCUI
            }"
            placeholder="Adaugă CUI"
            (blur)="validateCUI($event)"
            class="form-control"
            (input)="
              holdingForm.patchValue({
                cif: $event.target.value.toUpperCase()
              })
            "
          />

          <div *ngIf="!invalidCUI" class="invalid-feedback">
            <div>
              CUI-ul/CIF-ul introdus nu este valid.
            </div>
          </div>

          <input
            *ngIf="!displayCUI"
            type="text"
            id="cif"
            formControlName="cif"
            [ngClass]="{
              'is-invalid': (submitted && f.cif.errors) || !invalidCnp
            }"
            placeholder="Adaugă CNP"
            class="form-control"
            (blur)="validateCNP($event)"
            (input)="
              holdingForm.patchValue({
                cif: $event.target.value.toUpperCase()
              })
            "
          />

          <div *ngIf="!invalidCnp" class="invalid-feedback">
            <div>
              CNP-ul introdus nu este valid.
            </div>
          </div>

          <div *ngIf="submitted && f.cif.errors" class="invalid-feedback">
            <div *ngIf="f.cif.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="codeapia">
            Cod APIA
          </label>

          <input
            type="text"
            id="codeapia"
            formControlName="codeapia"
            placeholder="Adaugă cod APIA"
            class="form-control"
            (input)="$event.target.value = $event.target.value.toUpperCase()"
          />
        </div>
      </div>

      <div class="separator mb-3"></div>

      <div class="col-sm-4">
        <div class="form-group mb-3">
          <label>
            Plătitor TVA{{f.status.value !== '5' ? '*' : ''}}
          </label>

          <div class="radio mb-2 custom-radio-group">
            <input
              type="radio"
              name="vattype"
              id="vattype1"
              formControlName="vattype"
              [ngClass]="{ 'is-invalid': submitted && f.vattype.errors }"
              value="1"
            />
            <label for="vattype">
              Da
            </label>

            <input
              type="radio"
              name="vattype"
              id="vattype0"
              formControlName="vattype"
              [ngClass]="{ 'is-invalid': submitted && f.vattype.errors }"
              value="0"
              class="ml-5"
            />
            <label for="vattype">
              Nu
            </label>
          </div>

          <div
            *ngIf="submitted && f.vattype.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.vattype.errors }"
          >
            <div *ngIf="f.vattype.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-8">
        <div class="form-group mb-3">
          <label for="regCom">
            Registrul Comerțului
          </label>

          <input
            type="text"
            id="nrc"
            formControlName="nrc"
            placeholder="Adaugă registrul comerțului"
            class="form-control"
            (input)="$event.target.value = $event.target.value.toUpperCase()"
          />
        </div>
      </div>

      <div class="col-sm-4" *ngIf="displayCAEN">
        <div class="form-group mb-3">
          <label for="caen">
            Cod CAEN{{f.status.value !== '5' ? '*' : ''}}
          </label>

          <ng-select
            [items]="CAENCodes"
            bindLabel="fullDescription"
            bindValue="code"
            formControlName="caen"
            [ngClass]="{ 'is-invalid': submitted && f.caen.errors }"
            placeholder="Selectează codul CAEN"
          >
          </ng-select>

          <div
            *ngIf="submitted && f.caen.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.caen.errors }"
          >
            <div *ngIf="f.caen.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div [ngClass]="[ displayCAEN ? 'col-sm-4' : 'col-sm-6' ]">
        <div class="form-group mb-3">
          <label for="turnover">
            Cifra de afaceri
          </label>

          <input
            type="number"
            id="turnover"
            formControlName="turnover"
            placeholder="Adaugă cifra de afaceri"
            class="form-control"
            (input)="$event.target.value = $event.target.value.toUpperCase()"
          />
        </div>
      </div>

      <div [ngClass]="[ displayCAEN ? 'col-sm-4' : 'col-sm-6' ]">
        <div class="form-group mb-3">
          <label for="employees">
            Număr de angajați
          </label>

          <input
            type="number"
            id="employees"
            formControlName="employees"
            placeholder="Adaugă numărul de angajați"
            class="form-control"
            (input)="$event.target.value = $event.target.value.toUpperCase()"
          />
        </div>
      </div>

      <div class="separator mb-3"></div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="asociatia">
            Asociația din care face parte CPP{{f.status.value !== '5' ? '*' : ''}}
          </label>
          <ng-select
            [items]="asociatii"
            bindLabel="name"
            id="asociatia"
            class="disabled"
            formControlName="asociatia"
            [ngModel]="defaultAsociation"
            [ngClass]="{ 'is-invalid': submitted && f.asociatia.errors }"
            placeholder="Selectează numele asociției"
          >
          </ng-select>

          <div
            *ngIf="submitted && f.asociatia.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.asociatia.errors }"
          >
            <div *ngIf="f.asociatia.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="rase">
            Rasele exploatației{{f.status.value !== '5' ? '*' : ''}}
          </label>

          <ng-select
            [items]="rase"
            bindLabel="name"
            [multiple]="true"
            formControlName="racecontract"
            [closeOnSelect]="false"
            [hideSelected]="true"
            placeholder="Selectează rasele exploatației"
            bindValue="shortname"
            [ngClass]="{ 'is-invalid': submitted && f.racecontract.errors }"
          >
          </ng-select>

          <div *ngIf="submitted && f.racecontract.errors" class="invalid-feedback d-block">
            <div *ngIf="f.racecontract.errors.required">
              Completarea acestui câmp este obligatorie.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <div class="form-group mb-3">
          <label for="subventionat">
            Subvenție
          </label>

          <ng-select
            formControlName="subventionat"
            placeholder="Alege tip subvenție"
            [clearable]="false"
          >
          <ng-option [value]="0">Nu</ng-option>
          <ng-option [value]="1">Da</ng-option>
          </ng-select>
        </div>
      </div>

    </div>
  </div>
</div>

<app-change-status-reason
  *ngIf="openStatusReasonModal"
  [companyCode]="f.code.value"
  [status]="status"
  (notifyClose)="closeStatusReasonModal($event)"
>
</app-change-status-reason>
