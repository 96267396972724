import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { BuletineTableService } from 'src/app/modules/acbcr/common/services/buletine-table.service';
import { Cantarire } from 'src/app/modules/acbcr/common/models/cantarire.model';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';

@Component({
  selector: 'app-cantariri',
  templateUrl: './cantariri.component.html',
  styleUrls: ['./cantariri.component.scss'],
  providers: [ BuletineTableService, DecimalPipe ]
})
export class CantaririComponent implements OnInit, OnDestroy {
  tables$: Observable<Cantarire[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;
  selectedHolding;
  destroy$: Subject<boolean> = new Subject<boolean>();
  objToAPI = {
    companyid: null,
    page_nr: '1',
    page_size: '10'
  };

  constructor(
    public cantaririTableService: BuletineTableService,
    private store: Store<ApplicationState>
  ) {
    this.tables$ = this.cantaririTableService.tables$;
    this.total$ = this.cantaririTableService.total$;
  }

  ngOnInit(): void {
    this.store.pipe(
      select(getLoadingState),
      filter(isLoading => !isLoading),
      switchMap(() => this.store.pipe(select(getSelectedHolding))),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
      takeUntil(this.destroy$),
    ).subscribe((response: any) => {
        this.selectedHolding = response;
        if (this.selectedHolding !== undefined && this.selectedHolding.id > 0) {
          this.getTableData();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getTableData() {
    this.objToAPI.companyid = this.selectedHolding !== undefined ? this.selectedHolding.id : '';
    this.loadingDataSpinner = true;
    this.cantaririTableService
      .getBuletineAPI(this.objToAPI).subscribe((response) => {
        // Hide spinner, after 1 second
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    // Compose the object what will be sended to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_nr = event;
    this.getTableData();
  }

  getTableCallback(event) {
    this.getTableData();
  }

}


