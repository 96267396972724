import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AdeverinteApartenentaTableService } from 'src/app/modules/acbcr/common/services/adeverinte-apartenenta-table.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { HoldingsTableService } from 'src/app/modules/acbcr/common/services/holdings-table.service';

@Component({
  selector: 'app-add-update-allowance',
  templateUrl: './add-update-allowance.component.html',
  styleUrls: ['./add-update-allowance.component.scss'],
})
export class AddUpdateAllowanceComponent implements OnChanges {
  @ViewChild('addEditAllowanceModal') addEditAllowanceModal: ElementRef;
  @Input() addEditModalType: string;
  @Input() open = false;
  @Input() currentHolding: any;

  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();
  modalReference: NgbModalRef;
  closeResult: string;
  selectedHoldingName;
  selectedHoldingId;
  selectedDate;
  listaExploatatii;
  vaciRaseCarne;
  vaciRaseCarneSalvate = [];
  vaciMetise;
  vaciMetiseSalvate = [];
  tauriCarne;
  tauriCarneSalvate = [];
  tineretRaseCarne;
  tineretRaseCarneSalvate = [];
  tineretMetis;
  tineretMetisSalvate = [];
  disableDropDown = true;
  isDuplicate = false;
  currentRoute;
  vacaCarneDeAdaugat;
  vacaMetisDeAdaugat;
  taurDeAdaugat;
  tineretCarneDeAdaugat;
  tineretMetisDeAdaugat;
  showError = false;
  showHint = true;
  obj;

  submitted = false;
  count: number;
  baseUrl = environment.API_URL;

  constructor(
    public modalService: NgbModal,
    public adeverintaApartenentaTableService: AdeverinteApartenentaTableService,
    private store: Store<ApplicationState>,
    private holdingService: HoldingsTableService
  ) {}

  ngOnChanges(changes) {
    const date = new Date();
    this.selectedDate =
      date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    this.listaExploatatii = [];

    this.store
      .pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
        if (this.currentRoute.includes('/exploatatie')) {
          if (this.currentHolding) {
            this.selectedHoldingId = this.currentHolding.id;
            this.listaExploatatii.push(this.currentHolding);
            this.disableDropDown = true;
            this.showHint = false;
          }
        } else {
          this.disableDropDown = false;
          this.showHint = true;
        }
      });

    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.addEditAllowanceModal, {
        windowClass: 'modal-half-full',
        scrollable: true,
        backdrop: 'static',
      });
    }

    if (
      this.currentRoute &&
      this.currentRoute.includes('/exploatatie') &&
      this.open
    ) {
      this.populateByHolding();
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  onSubmitted() {
    let vaciRaseCarneSave = [];
    let vaciMetiseSave = [];
    let tauriCarneSave = [];
    let tineretCarneSave = [];
    let tineretMetisSave = [];
    this.submitted = true;
    this.open = false;
    vaciRaseCarneSave = [
      ...vaciRaseCarneSave,
      this.vaciRaseCarneSalvate.map((animal) => {
        return [animal.rep_type, animal.animalid];
      }),
    ];
    vaciMetiseSave = [
      ...vaciMetiseSave,
      this.vaciMetiseSalvate.map((animal) => {
        return [animal.rep_type, animal.animalid];
      }),
    ];
    tauriCarneSave = [
      ...tauriCarneSave,
      this.tauriCarneSalvate.map((animal) => {
        return [animal.rep_type, animal.animalid];
      }),
    ];
    tineretCarneSave = [
      ...tineretCarneSave,
      this.tineretRaseCarneSalvate.map((animal) => {
        return [animal.rep_type, animal.animalid];
      }),
    ];
    tineretMetisSave = [
      ...tineretMetisSave,
      this.tineretMetisSalvate.map((animal) => {
        return [animal.rep_type, animal.animalid];
      }),
    ];

    const anm = [];
    let i;
    if (vaciRaseCarneSave[0].length > 0) {
      for (i = 0; i < vaciRaseCarneSave[0].length; i++) {
        anm.push(vaciRaseCarneSave[0][i]);
      }
    }
    if (vaciMetiseSave[0].length > 0) {
      for (i = 0; i < vaciMetiseSave[0].length; i++) {
        anm.push(vaciMetiseSave[0][i]);
      }
    }
    if (tauriCarneSave[0].length > 0) {
      for (i = 0; i < tauriCarneSave[0].length; i++) {
        anm.push(tauriCarneSave[0][i]);
      }
    }
    if (tineretCarneSave[0].length > 0) {
      for (i = 0; i < tineretCarneSave[0].length; i++) {
        anm.push(tineretCarneSave[0][i]);
      }
    }
    if (tineretMetisSave[0].length > 0) {
      for (i = 0; i < tineretMetisSave[0].length; i++) {
        anm.push(tineretMetisSave[0][i]);
      }
    }

    this.obj = {
      items: anm,
      data: this.selectedDate,
      companyid: this.selectedHoldingId,
    };
    if (this.addEditModalType === 'add') {
      if (
        this.selectedDate.length > 0 &&
        this.selectedHoldingId !== '' &&
        anm.length > 0
      ) {
        this.adeverintaApartenentaTableService
          .saveAdeverintaApi(this.obj)
          .subscribe((response) => {
            if (response.error && response.error.includes('Duplicate')) {
              this.isDuplicate = true;
            } else {
              this.isDuplicate = false;
              this.modalReference.close();
              this.notifyClose.emit(false);
              this.callbackResult.emit('');
              this.emptyLists();
            }
          });
      }
    }
  }

  dataAllowanceChanged(event) {
    if (event === '') {
      this.selectedDate = '';
    } else {
      this.emptyLists();
      this.selectedDate = event;
      this.populateByHolding();
    }
  }

  searchHoldings(event) {
    const obj = {
      comp_name: event.term,
      page_size: 100000,
    };

    if (event.term.length >= 5 && this.listaExploatatii.length === 0) {
      this.holdingService.getHoldingsAPI({ ...obj }).subscribe((response) => {
        this.listaExploatatii = response.result;
      });
    }

    if (event.term.length >= 5) {
      this.showHint = false;
    } else {
      this.showHint = true;
      this.listaExploatatii = [];
    }
  }

  clearHoldings() {
    this.listaExploatatii = [];
    this.showHint = true;
  }

  populateByHolding() {
    this.adeverintaApartenentaTableService
      .getAnimalsByTypeAPI(this.selectedHoldingId, 0, this.selectedDate)
      .subscribe((response) => {
        this.vaciRaseCarne = response.type_0;
      });

    this.adeverintaApartenentaTableService
      .getAnimalsByTypeAPI(this.selectedHoldingId, 1, this.selectedDate)
      .subscribe((response) => {
        this.vaciMetise = response.type_1;
      });

    this.adeverintaApartenentaTableService
      .getAnimalsByTypeAPI(this.selectedHoldingId, 2, this.selectedDate)
      .subscribe((response) => {
        this.tauriCarne = response.type_2;
      });

    this.adeverintaApartenentaTableService
      .getAnimalsByTypeAPI(this.selectedHoldingId, 3, this.selectedDate)
      .subscribe((response) => {
        this.tineretRaseCarne = response.type_3;
      });

    this.adeverintaApartenentaTableService
      .getAnimalsByTypeAPI(this.selectedHoldingId, 4, this.selectedDate)
      .subscribe((response) => {
        this.tineretMetis = response.type_4;
      });
  }

  onHoldingSelect(event) {
    this.emptyLists();
    this.selectedHoldingId = event.id;
    this.populateByHolding();
    this.showError = false;
  }

  populateLists() {
    if (!this.selectedHoldingId) {
      this.showError = true;
    } else {
      this.vaciRaseCarne.filter((animal) => {
        if (animal.eligibil === 1) {
          this.vaciRaseCarneSalvate.push(animal);
        }
      });
      this.vaciRaseCarne = this.vaciRaseCarne.filter(
        (animal) => this.vaciRaseCarneSalvate.indexOf(animal) < 0
      );

      this.vaciMetise.filter((animal) => {
        if (animal.eligibil === 1) {
          this.vaciMetiseSalvate.push(animal);
        }
      });
      this.vaciMetise = this.vaciMetise.filter(
        (animal) => this.vaciMetiseSalvate.indexOf(animal) < 0
      );

      this.tauriCarne.filter((animal) => {
        if (animal.eligibil === 1) {
          this.tauriCarneSalvate.push(animal);
        }
      });
      this.tauriCarne = this.tauriCarne.filter(
        (animal) => this.tauriCarneSalvate.indexOf(animal) < 0
      );

      this.tineretRaseCarne.filter((animal) => {
        if (animal.eligibil === 1) {
          this.tineretRaseCarneSalvate.push(animal);
        }
      });
      this.tineretRaseCarne = this.tineretRaseCarne.filter(
        (animal) => this.tineretRaseCarneSalvate.indexOf(animal) < 0
      );

      this.tineretMetis.filter((animal) => {
        if (animal.eligibil === 1) {
          this.tineretMetisSalvate.push(animal);
        }
      });
      this.tineretMetis = this.tineretMetis.filter(
        (animal) => this.tineretMetisSalvate.indexOf(animal) < 0
      );
    }
  }

  addVaciCarne() {
    if (this.vaciRaseCarne && this.vaciRaseCarne.length > 0) {
      const deAdaugat = this.vaciRaseCarne.filter(
        (animal) =>
          animal.animalid === this.vacaCarneDeAdaugat && animal.eligibil === 1
      );
      if (deAdaugat.length > 0) {
        this.vaciRaseCarneSalvate = [
          ...this.vaciRaseCarneSalvate,
          deAdaugat[0],
        ];
        this.vaciRaseCarne = this.vaciRaseCarne.filter(
          (animal) => this.vaciRaseCarneSalvate.indexOf(animal) < 0
        );
        this.vacaCarneDeAdaugat = '';
      }
    }
  }

  deleteVaciCarne(an) {
    this.vaciRaseCarne = [...this.vaciRaseCarne, an];
    this.vaciRaseCarneSalvate = this.vaciRaseCarneSalvate.filter((animal) => {
      if (animal.animalid !== an.animalid) {
        return animal;
      }
    });
  }

  addVaciMetise() {
    if (this.vaciMetise && this.vaciMetise.length > 0) {
      const deAdaugat = this.vaciMetise.filter(
        (animal) =>
          animal.animalid === this.vacaMetisDeAdaugat && animal.eligibil === 1
      );
      if (deAdaugat.length > 0) {
        this.vaciMetiseSalvate = [...this.vaciMetiseSalvate, deAdaugat[0]];
        this.vaciMetise = this.vaciMetise.filter(
          (animal) => this.vaciMetiseSalvate.indexOf(animal) < 0
        );
        this.vacaMetisDeAdaugat = '';
      }
    }
  }

  deleteVaciMetise(an) {
    this.vaciMetise = [...this.vaciMetise, an];
    this.vaciMetiseSalvate = this.vaciMetiseSalvate.filter((animal) => {
      if (animal.animalid !== an.animalid) {
        return animal;
      }
    });
  }

  addTauri() {
    if (this.tauriCarne && this.tauriCarne.length > 0) {
      const deAdaugat = this.tauriCarne.filter(
        (animal) =>
          animal.animalid === this.taurDeAdaugat && animal.eligibil === 1
      );
      if (deAdaugat.length > 0) {
        this.tauriCarneSalvate = [...this.tauriCarneSalvate, deAdaugat[0]];
        this.tauriCarne = this.tauriCarne.filter(
          (animal) => this.tauriCarneSalvate.indexOf(animal) < 0
        );
        this.taurDeAdaugat = '';
      }
    }
  }

  deleteTauri(an) {
    this.tauriCarne = [...this.tauriCarne, an];
    this.tauriCarneSalvate = this.tauriCarneSalvate.filter((animal) => {
      if (animal.animalid !== an.animalid) {
        return animal;
      }
    });
  }

  addTineretCarne() {
    if (this.tineretRaseCarne && this.tineretRaseCarne.length > 0) {
      const deAdaugat = this.tineretRaseCarne.filter(
        (animal) =>
          animal.animalid === this.tineretCarneDeAdaugat &&
          animal.eligibil === 1
      );
      if (deAdaugat.length > 0) {
        this.tineretRaseCarneSalvate = [
          ...this.tineretRaseCarneSalvate,
          deAdaugat[0],
        ];
        this.tineretRaseCarne = this.tineretRaseCarne.filter(
          (animal) => this.tineretRaseCarneSalvate.indexOf(animal) < 0
        );
        this.tineretCarneDeAdaugat = '';
      }
    }
  }

  deleteTineretCarne(an) {
    this.tineretRaseCarne = [...this.tineretRaseCarne, an];
    this.tineretRaseCarneSalvate = this.tineretRaseCarneSalvate.filter(
      (animal) => {
        if (animal.animalid !== an.animalid) {
          return animal;
        }
      }
    );
  }

  addTineretMetisi() {
    if (this.tineretMetis && this.tineretMetis.length > 0) {
      const deAdaugat = this.tineretMetis.filter(
        (animal) =>
          animal.animalid === this.tineretMetisDeAdaugat &&
          animal.eligibil === 1
      );
      if (deAdaugat.length > 0) {
        this.tineretMetisSalvate = [...this.tineretMetisSalvate, deAdaugat[0]];
        this.tineretMetis = this.tineretMetis.filter(
          (animal) => this.tineretMetisSalvate.indexOf(animal) < 0
        );
        this.tineretMetisDeAdaugat = '';
      }
    }
  }

  deleteTineretMetisi(an) {
    this.tineretMetis = [...this.tineretMetis, an];
    this.tineretMetisSalvate = this.tineretMetisSalvate.filter((animal) => {
      if (animal.animalid !== an.animalid) {
        return animal;
      }
    });
  }

  notifyCloseModal() {
    this.isDuplicate = false;
    this.open = false;
    this.notifyClose.emit(false);
    this.submitted = false;
    this.emptyLists();
  }

  emptyLists() {
    this.vaciRaseCarne = [];
    this.vaciRaseCarneSalvate = [];
    this.vaciMetise = [];
    this.tauriCarne = [];
    this.tauriCarneSalvate = [];
    this.tineretRaseCarne = [];
    this.tineretRaseCarneSalvate = [];
    this.tineretMetis = [];
    this.tineretMetisSalvate = [];
    this.listaExploatatii = [];
  }
}
