<ng-template #addEditTemplate let-modal="close('Cross click')">
  <div class="modal-header row" [ngSwitch]="addEditModalType">
    <div class="col-sm-12 col-md-12 col-lg-6 custom-col">
      <h3 class="modal-title" *ngSwitchCase="'add'">
        Creare Certificat Sanitar
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit'">
        Editează Certificat Sanitar
      </h3>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-secondary"
          (click)="closeModal()"
          autofocus="false"
        >
          Închide
        </button>
        <button
          class="btn btn-sm btn-success"
          (click)="onSubmitted()"
        >
          <span *ngIf="addEditModalType === 'add'; else btnTxt">
            Crează Certificat Sanitar
          </span>
          <ng-template #btnTxt>
            <span>
              Salvează Certificat Sanitar
            </span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="compForm" class="h-100">
    <div class="modal-body h-100">
      <div class="container-fluid">

        <div class="row">
          <div class="col-12 d-flex">
            <div class="form-group mb-3 mr-5">
              <label for="data">
                Data eliberării
              </label>
              <app-date-picker
                (dateChanged)="onDateChanged($event)"
                [submitted]="submitted"
                [required]="true"
                [selectedDate]="compForm.controls.data.value"
                [disabled]="!(isAdmin || isOperator)"
              >
              </app-date-picker>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12">
            <h3 class="type-title">Taur</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-9">
            <div class="form-group mb-3">
              <ng-select
                [items]="animale"
                formControlName="animal_id"
                bindLabel="numarmatricol"
                bindValue="id"
                placeholder="Alege animal"
                (search)="searchAnimal($event)"
                [ngClass]="{ disabled: !(isAdmin || isOperator) }"
              >
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="row">
                    <div class="col-sm-12 col-md-auto">
                      <span>
                        {{ item.numarmatricol }} - {{ item.nume_exploatatie }}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <small
                *ngIf="showHint"
                class="form-text text-muted ml-2"
              >
                <b>Introdu minim 5 caractere pentru a căuta</b>
              </small>

              <div
                *ngIf="submitted && compForm.controls.animal_id.errors?.required"
                class="invalid-feedback d-block"
              >
                Completarea acestui câmp este obligatorie.
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12">
            <h3 class="type-title">Fișierul</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div *ngIf="!fileName" class="d-flex">
              <app-upload-fisier
                class="flex-shrink-0"
                (ApiResponse)="uploadServerResponse($event)"
              >
              </app-upload-fisier>

              <div
                *ngIf="submitted && compForm.controls.file_id.errors?.required"
                class="invalid-feedback d-block"
              >
                Fișierul nu a fost adăugat.
              </div>
            </div>

            <div *ngIf="fileName">
              <button
                type="button"
                class="btn p-0 text-blue font-weight-bold"
                (click)="downloadFile($event)"
              >
                <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                {{ fileName }}
              </button>

              <button
                type="button"
                class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
                ngbTooltip="Șterge fișier"
                placement="right"
                (click)="deleteFile()"
              >
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
 </ng-template>
