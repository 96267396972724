import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToDisplayFormat, convertDateToBackendFormat } from './services-table-helper';
import { RaportFactura } from '../models/rapoarte-facturi.model';

@Injectable({
  providedIn: 'root'
})

export class RapoarteFacturiTableService extends SortableTable<RaportFactura> {
  private baseUrl = environment.API_URL;

  rapoarteFacturi: RaportFactura[];
  numarRapoarteFacturi: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.rapoarteFacturi, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getRapoarteFacturiAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/contabilitate/raportFacturi/`,
      obj
    ).pipe(map((response: any) => {
      this.rapoarteFacturi = response.result;
      this.numarRapoarteFacturi = response.count;

      this.rapoarteFacturi.map((raport: RaportFactura) => {
        raport.data = convertDateToDisplayFormat(raport.data);
      });

      this.paginate();
      this.sortEvent();
      this._total$.next(this.numarRapoarteFacturi);

      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/exportRaportFacturi/`,
      obj,
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarRapoarteFacturi;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarRapoarteFacturi) {
      this._state.endIndex = this.numarRapoarteFacturi;
    }
  }
}
