import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators
} from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HoldingsTableService } from './../../../../../common/services/holdings-table.service';
import { Subscription, Subject } from 'rxjs';
import { SharingHelperDataService } from 'src/app/modules/acbcr/common/services/sharing-helper-data.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppCompanyAdmins } from 'src/app/modules/acbcr/common/state/reducers/company-admins.reducers';
import { takeUntil } from 'rxjs/operators';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';


@Component({
  selector: 'app-add-update-holding',
  templateUrl: './add-update-holding.component.html',
  styleUrls: ['./add-update-holding.component.scss']
})

export class AddUpdateHoldingComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('addModal') addModal: ElementRef;
  modalReference: NgbModalRef;

  @Input() open = false;
  @Input() modalType: string;
  @Input() holdingData: any;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  isSuperAdmin = false;
  elemName = 'identificare';

  holdingForm;
  submitted = false;
  statusChanged = false;

  CAENCodesSubscription = new Subscription();
  CAENCodes;

  admins;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public modalService: NgbModal,
    private holdingsTableService: HoldingsTableService,
    private sharingHelperDataService: SharingHelperDataService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.buildForm();

    // Get CAEN codes from sharing data (Dashboard component)
    this.CAENCodesSubscription =
      this.sharingHelperDataService.currentCAENCodes.subscribe(response => {
        this.CAENCodes = response;
      });
  }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService
        .open(
          this.addModal,
          { windowClass: 'modal-full', scrollable: true }
        );
    }

    // Get admins from the store
    this.store.pipe(select(getAppCompanyAdmins))
      .pipe(takeUntil(this.destroy$))
      .subscribe((admins: any) => {
        this.admins = admins;
      });

    if (this.holdingData) {
      this.buildForm(this.holdingData);
    } else {
      this.holdingForm !== undefined ? this.holdingForm.reset() : this.buildForm();
      this.submitted = false;
    }
  }

  ngOnDestroy() {
    this.CAENCodesSubscription.unsubscribe();

    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  buildForm(formData?) {

    this.statusChanged = false;
    let validateRace = true;
    if (formData?.status == 5) {
      // Race is not required for status 'IMPORT'
      validateRace = false;
    }

    this.holdingForm = new UntypedFormGroup({
      id: new UntypedFormControl(
        formData ? formData.id : ''
      ),
      documents: new UntypedFormControl(
        formData ? formData.documents : ''
      ),
      documents_ids: new UntypedFormControl(
        formData ? formData.documents_ids : ''
      ),
      // Identificare panel fields
      status: new UntypedFormControl(
        formData ? formData.status?.toString() : '1'
      ),
      name: new UntypedFormControl(
        formData ? formData.name : '', Validators.required
      ),
      code: new UntypedFormControl(
        formData ? formData.code : '', Validators.required,
      ),
      organizationtype: new UntypedFormControl(
        formData ? formData.organizationtype : '', Validators.required,
      ),
      cif: new UntypedFormControl(
        formData ? formData.cif : '', Validators.required,
      ),
      codeapia: new UntypedFormControl(
        formData ? formData.codeapia : ''
      ),
      subventionat: new UntypedFormControl(
        formData ? formData.subventionat : ''
      ),
      vattype: new UntypedFormControl(
        formData ? formData.vattype?.toString() : '', Validators.required,
      ),
      nrc: new UntypedFormControl(
        formData ? formData.nrc : ''
      ),
      caen: new UntypedFormControl(
        formData ? formData.caen : '', Validators.required,
      ),
      turnover: new UntypedFormControl(
        formData ? formData.turnover : ''
      ),
      employees: new UntypedFormControl(
        formData ? formData.employees : ''
      ),
      asociatia: new UntypedFormControl('ACBCR', Validators.required),
      racecontract: new UntypedFormControl(
        (formData && formData.racecontract)
          ? formData.racecontract.split(',').map(a => a.replace(/\s+/g, ''))
          : '', validateRace ? Validators.required : null
      ),
      // Administrator Exploatatie panel fields
      administrator: new UntypedFormControl(
        formData ? formData.administrator : ''
      ),
      // Adresa Exploatatiei panel fields
      judet: new UntypedFormControl(
        formData ? formData.judet : '', Validators.required,
      ),
      localitate: new UntypedFormControl(
        formData ? formData.localitate : '', Validators.required,
      ),
      mediul: new UntypedFormControl(
        formData ? formData.mediul?.toString() : ''
      ),
      street: new UntypedFormControl(
        formData ? formData.street : '', Validators.required
      ),
      stno: new UntypedFormControl(
        formData ? formData.stno : '', Validators.required
      ),
      zip: new UntypedFormControl(
        formData ? formData.zip : ''
      ),
      // Contact panel fields
      mobilephone: new UntypedFormControl(
        formData ? formData.mobilephone : '', Validators.required
      ),
      fixedphone: new UntypedFormControl(
        formData ? formData.fixedphone : ''
      ),
      email: new UntypedFormControl(
        formData ? formData.email : '', [Validators.required, Validators.email]
      ),
      emailaccountant: new UntypedFormControl(
        formData ? formData.emailaccountant : '', Validators.email
      ),
      // Date Bancare panel fields
      bank: new UntypedFormControl(
        formData ? formData.bank : ''
      ),
      bankaccount: new UntypedFormControl(
        formData ? formData.bankaccount : ''
      ),
      are_contract_activ: new UntypedFormControl(
        formData ? formData.are_contract_activ : false
      ),
      locatii: new UntypedFormArray([]),
    });

    for (var i = 0; i < formData?.locatii?.length; i++) {
      let item = formData.locatii[i];

      let locatieForm = new UntypedFormGroup({
        id: new UntypedFormControl(item.id),
        nume: new UntypedFormControl(item.nume, Validators.required),
        lat: new UntypedFormControl(item.lat, Validators.required),
        lng: new UntypedFormControl(item.lng, Validators.required),
      }, this.locatieFormValidator);

      this.holdingForm.controls.locatii.push(locatieForm);
    }

    this.checkStatusValidators(this.holdingForm.value.status);
  }

  // Notify the parent component (Holding page) when the add/update modal is closed
  notifyCloseModal() {
    this.notifyClose.emit(false);
  }

  // Scroll to a specific element (left-side navbar)
  scrollToElement(elem) {
    let domElem = document.querySelector(elem);

    if (!domElem) {
      return;
    }

    this.elemName = elem;
    domElem.scrollIntoView({behavior: 'smooth'});
  }

  // Submit the form
  onSubmitted() {
    this.submitted = true;
    if (this.holdingForm.controls.organizationtype.value === 0) {
      this.holdingForm.controls.cif.setErrors(null);
      this.holdingForm.controls.caen.setErrors(null);
    }

    if (this.holdingForm.valid) {
      switch (this.modalType) {
        case 'add':
          this._saveNewHolding();
          break;
        case 'edit':
          this._updateExistingHolding();
          break;
      }
    }

  }

  resetModal() {
    this.open = false;
    this.notifyClose.emit(false);
    this.elemName = 'identificare';
    this.modalReference.close();
  }

  // Save new holding into the app
  _saveNewHolding() {
    // Sent localitate id to the back-end
    if (this.holdingForm.value.localitate) {
      this.holdingForm.value.settlementid = this.holdingForm.value.localitate.id;
      this.holdingForm.value.localitate = this.holdingForm.value.localitate.id;
    }

    // Sent admin id to the back-end
    if (this.holdingForm.value.administrator ) {
      this.holdingForm.value.administrator =
        this.holdingForm.value.administrator !== null ? this.holdingForm.value.administrator.id : '';
    }

    this.holdingsTableService.addHolding(this.holdingForm.value)
      .subscribe(response => {
        this.callbackResult.emit(response);
        this.resetModal();
        // TODO: write in app store
      });
  }

  // Update an existing holding from the app
  _updateExistingHolding() {
    this.checkStatusValidators(this.holdingForm.value.status);

    // Sent localitate id to the back-end
    if (this.holdingForm.value.localitate) {
      this.holdingForm.value.settlementid = this.holdingForm.value.localitate.id;
      this.holdingForm.value.localitate = this.holdingForm.value.localitate.id;
    }

    // Sent admin id to the back-end
    if (this.holdingForm.value.administrator && typeof (this.holdingForm.value.administrator) === 'object') {
      this.holdingForm.value.administrator = this.holdingForm.value.administrator !== null ? this.holdingForm.value.administrator.id : '';
    }

    this.holdingsTableService.updateHolding(this.holdingForm.value)
      .subscribe(response => {
        this.callbackResult.emit(response);
        this.helperDataService.getHoldingsNames();
      });

    this.resetModal();
  }

  private get f() { return this.holdingForm.controls; }

  changeStatus(event) {
    if (this.modalType === 'edit') {
      this.statusChanged = true;
    }
    this.checkStatusValidators(event);
  }

  private checkStatusValidators(status) {
    const mandatoryFields = status === '5' ? false : true;
    this.setValidators(mandatoryFields);
  }

  private setValidators(required: boolean) {
    required ? (
      this.f.organizationtype.setValidators(Validators.required),
      this.f.cif.setValidators(Validators.required),
      this.f.vattype.setValidators(Validators.required),
      this.f.caen.setValidators(Validators.required),
      this.f.racecontract.setValidators(Validators.required),
      this.f.judet.setValidators(Validators.required),
      this.f.localitate.setValidators(Validators.required),
      this.f.street.setValidators(Validators.required),
      this.f.stno.setValidators(Validators.required),
      this.f.mobilephone.setValidators(Validators.required),
      this.f.email.setValidators([Validators.required, Validators.email])
    ) : (
      this.f.organizationtype.clearValidators(),
      this.f.cif.clearValidators(),
      this.f.vattype.clearValidators(),
      this.f.caen.clearValidators(),
      this.f.racecontract.clearValidators(),
      this.f.judet.clearValidators(),
      this.f.judet.clearValidators(),
      this.f.localitate.clearValidators(),
      this.f.street.clearValidators(),
      this.f.stno.clearValidators(),
      this.f.mobilephone.clearValidators(),
      this.f.email.clearValidators()
    );

    if (this.isSuperAdmin && required) {
      this.f.code.setValidators([Validators.required]);
    } else if (!this.isSuperAdmin && required) {
      this.f.code.setValidators([Validators.required, this.codeValidator]);
    } else {
      this.f.code.clearValidators();
    }

    Object.keys(this.holdingForm.controls).forEach(key => {
      this.holdingForm.get(key).updateValueAndValidity();
    });
  }

  codeValidator(control) {
    let code = String(control.value);

    if (code.match(/^RO\d{10}$/)) {
      return null;
    }

    return {'bad_format': true}
  }

  locatieFormValidator(locatieForm) {
    let lat = locatieForm.value.lat;
    let lng = locatieForm.value.lng;

    if (isNaN(lat) || isNaN(lng)) {
      return {'bad_format': true}
    }

    return null;
  }
}
