import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Exploatatie } from '../models/holdings.models';
import { environment } from 'src/environments/environment';
import { SortableTable } from './services-table-helper';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root'
})
export class HoldingsTableService extends SortableTable<Exploatatie> {
  private baseUrl = environment.API_URL;

  holdings: Exploatatie[];
  holdingsNumber: number;

  constructor(private http: HttpClient) {
    super();
  }

  getHoldingsAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/companies/`,
      obj,
      auth.options
    ).pipe(map((response: any) => {
      this.holdings = response.result;
      this.holdingsNumber = response.count;

      this.paginate();
      this._tables$.next(this.holdings);
      this._total$.next(this.holdingsNumber);

      return response;
    }));
  }

  getHoldingAPI(code: any) {
    return this.http.post(
      `${this.baseUrl}/companies/getCompany/`,
      code,
      auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  addHolding(holdingObj: Exploatatie) {
    return this.http.post(
      `${this.baseUrl}/companies/add/`,
      holdingObj,
      auth.options
    ).pipe(map((response: any) => {

      if (this.holdings !== undefined) {
        if (this.holdings.length >= 10) {
          // Add new holding on first place in the array
          this.holdings.unshift(response);
          // Remove last element form the array
          this.holdings.pop();
        } else {
          this.holdings.push(response);
        }

        // Increase total number of holdings
        this.holdingsNumber += 1;
      }
      return response;
    }));
  }

  updateHolding(holdingObj: Exploatatie) {
    return this.http.post(
      `${this.baseUrl}/companies/update/`,
      holdingObj,
      auth.options
    ).pipe(map((response: any) => {
      if (this.holdings !== undefined) {
        for (let i = 0; i < this.holdings.length; i++) {
          if (this.holdings[i].code === response.code) {
            this.holdings[i].name = response.name;
            this.holdings[i].status = response.status;
            this.holdings[i].racecontract = response.racecontract;
          }
        }
      }
      return response;
    }));
  }

  downloadHoldingHistoryApi(companyId) {
    return this.http
      .post(
        `${this.baseUrl}/companies/export-istoric/`,
        { comp_id: companyId },
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteHolding(holdingId) {
    return this.http.post(
      `${this.baseUrl}/companies/delete/`,
      { code: holdingId },
      auth.options
    ).pipe(map((response: any) => {

      return response;
    }));
  }

  getHoldingAdmins(obj?) {
    return this.http.post(
      `${this.baseUrl}/company_admin/`,
      obj,
      auth.options
    ).pipe(map((response: any) => {

      return response;
    }));
  }

  addHoldingAdmin(obj) {
    return this.http.post(
      `${this.baseUrl}/company_admin/add/`,
      obj,
      auth.options
    ).pipe(map(response => {
      return response;
    }));
  }

  updateHoldingAdmin(obj) {
    return this.http.post(
      `${this.baseUrl}/company_admin/update/`,
      obj,
      auth.options
    ).pipe(map(response => {
      return response;
    }));
  }

  updateHoldingStatus(obj) {
    return this.http.post(
      `${this.baseUrl}/companies/change-status/`,
      obj,
      auth.options
    ).pipe(map(response => {
      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/companies/export-xls/`,
      {
        ...obj
      },
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage['access-token']}`,
        })
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  verificareAnimaleAPI(formData) {
    return this.http.post(
      `${this.baseUrl}/animals/verificare-animale-rne/`,
      formData, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${localStorage['access-token']}`,
        })
      }).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.holdingsNumber;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.holdingsNumber) {
      this._state.endIndex = this.holdingsNumber;
    }
  }

}
