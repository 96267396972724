<form [formGroup]="adeverintaSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-12">
      <div class="form-group mb-3">
        <label for="status_doc">
          Status adeverință
        </label>
        <ng-select
          bindLabel="status_doc"
          bindValue="status_doc"
          formControlName="status_doc"
          placeholder="Adaugă status"
        >
          <ng-option [value]="1">Ciornă</ng-option>
          <ng-option [value]="2">Validată</ng-option>
          <ng-option [value]="3">Nevalidată</ng-option>
          <ng-option [value]="4">Printată</ng-option>
          <ng-option [value]="5">Anulată</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isFermier">
      <div class="form-group mb-3">
        <label for="company_name">
          Denumirea exploatației
        </label>

        <input
          type="text"
          id="company_name"
          formControlName="company_name"
          placeholder="Adaugă denumirea exploatației"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isFermier">
      <div class="form-group mb-3">
        <label for="company_code">
          Codul exploatației
        </label>

        <input
          type="text"
          id="company_code"
          formControlName="company_code"
          placeholder="Adaugă codul exploatației"
          class="form-control" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
