<form [formGroup]="reproductionSearchForm" (ngSubmit)="onSubmitted()">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="matricol_femela">
          Matricol femelă
        </label>

        <ng-select
          [items]="femele"
          (search)="searchMatricolFemela($event)"
          bindLabel="numarmatricol"
          bindValue="numarmatricol"
          placeholder="Introduceți matricol femelă"
          formControlName="matricol_femela"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="matricol_taur">
          Matricol taur
        </label>

        <ng-select
          [items]="tauri"
          (search)="searchMatricolTaur($event)"
          bindLabel="numarmatricol"
          bindValue="numarmatricol"
          placeholder="Introduceți matricol taur"
          formControlName="matricol_taur"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="form-group mb-3">
        <label for="tip_reproductie">
          Tip reproducție
        </label>

        <ng-select
          formControlName="tip_reproductie"
          placeholder="Selectează tipul de reproducție"
        >
          <ng-option value="1">Însămânțare Artificială</ng-option>
          <ng-option value="2">Montă Naturală Autorizată</ng-option>
          <ng-option value="3">Embriotransferuri</ng-option>
        </ng-select>
      </div>
    </div>

    <div
      class="col-sm-12"
      *ngIf="reproductionSearchForm.value.tip_reproductie == '1'"
    >
      <div class="form-group mb-3">
        <label for="id_operator">
          Operator însămânțare
        </label>
        <ng-select
          [items]="operatoriInsamantare"
          bindLabel="viewLabel"
          bindValue="id"
          (search)="searchOperator($event)"
          formControlName="id_operator"
          placeholder="Selectează operator"
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
