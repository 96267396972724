import { Action } from '@ngrx/store';
import { SearchHolding } from '../../models/holdings.models';
import { SearchAnimal } from '../../models/animal.models';

export enum AppFiltersActionType {
  SET_HOLDINGS_FILTER = 'SET_HOLDINGS_FILTER',
  SET_ANIMALS_FILTER = 'SET_ANIMALS_FILTER'
}

export class SetHoldingsFilter implements Action {
  readonly type = AppFiltersActionType.SET_HOLDINGS_FILTER;
  constructor(public payload: SearchHolding) { }
}

export class SetAnimalsFilter implements Action {
  readonly type = AppFiltersActionType.SET_ANIMALS_FILTER;
  constructor(public payload: SearchAnimal) { }
}

export type AppFiltersActions = SetHoldingsFilter | SetAnimalsFilter;
