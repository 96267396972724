import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { takeUntil } from 'rxjs/operators';
import { getAppHoldingsNames } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { HoldingsNames } from 'src/app/modules/acbcr/common/models/holdings.models';

@Component({
  selector: 'app-facturi-search',
  templateUrl: './facturi-search.component.html',
  styleUrls: ['./facturi-search.component.scss'],
})
export class FacturiSearchComponent implements OnInit, OnDestroy {
  @Output() searchResult = new EventEmitter();
  facturiSearchForm;
  holdingsNames: HoldingsNames[];
  showHint = true;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor( private store: Store<ApplicationState> ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(getAppHoldingsNames))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HoldingsNames[]) => {
        this.holdingsNames = response;
      });

    this.facturiSearchForm = new UntypedFormGroup({
      data_inceput: new UntypedFormControl(null),
      data_sfarsit: new UntypedFormControl(null),
      stare: new UntypedFormControl(null),
      tip_factura: new UntypedFormControl(null),
      exploatatie: new UntypedFormControl(null),
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onSubmitted() {
    this.searchResult.emit(this.facturiSearchForm.value);
  }

  resetSearch() {
    this.facturiSearchForm.reset();
    this.onSubmitted();
  }

  searchHoldings(event) {
    if (event.term.length >= 4) {
      this.showHint = false;
    } else {
      this.showHint = true;
    }
  }

  clearHoldings() {
    this.showHint = true;
  }

  onStartDateChanged(event) {
    if (this.facturiSearchForm) {
      this.facturiSearchForm.controls.data_inceput.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.facturiSearchForm) {
      this.facturiSearchForm.controls.data_sfarsit.setValue(event);
    }
  }
}
