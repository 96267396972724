<ng-template ngFor let-anexa [ngForOf]="anexe" let-i="index">
  <tr
    class="cursor-pointer"
    [ngClass]="{ active: selectedItem === i }"
    (click)="openEditModal(anexa, i)"
  >
    <td
      width="70%"
      class="text-primary font-size-16"
    >
      <p class="mb-0">
        <span class="font-weight-bold">{{ anexa.numarmatricol }}</span>
        <span *ngIf="anexa.intrat == 2; else intrare">, ieșit în </span>
        <ng-template #intrare>
          <span>, intrat în </span>
        </ng-template>
        <span class="font-weight-bold">{{ anexa.data }}</span>
      </p>
      <p class="mb-0">
        prin
        <span class="font-weight-bold" [ngSwitch]="anexa?.motiv">
          <span *ngSwitchCase="1">abatorizare</span>
          <span *ngSwitchCase="2">vânzare</span>
          <span *ngSwitchCase="3">cumpărare</span>
          <span *ngSwitchCase="4">transfer</span>
          <span *ngSwitchCase="5">pierdere</span>
          <span *ngSwitchCase="6">moarte</span>
          <span *ngSwitchCase="7">vânzare exploatație neafiliată</span>
          <span *ngSwitchCase="8">sacrificare consum propriu</span>
        </span>

        <span
          *ngIf="anexa?.motiv == 3 || (anexa?.motiv == 4 && anexa?.intrat == 1)"
          > de la {{ anexa.exploatatie_sursa_name }}</span
        >
        <span
          *ngIf="anexa?.motiv == 2 || (anexa?.motiv == 4 && anexa?.intrat == 2)"
          > către {{ anexa.exploatatie_destinatie_name }}</span
        >
      </p>
    </td>
    <td
      width="30%"
      class="align-middle font-weight-bold"
    >
      <span [ngSwitch]="anexa?.status">
        <span *ngSwitchCase="0">În așteptare</span>
        <span *ngSwitchCase="1">Verificare</span>
        <span *ngSwitchCase="2">Operare</span>
        <span *ngSwitchCase="3">Validat</span>
        <span *ngSwitchCase="4">Refuzat</span>
        <span *ngSwitchCase="5">Anulat</span>
      </span>
    </td>
  </tr>
</ng-template>
