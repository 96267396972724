import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output, OnDestroy
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import {
  AdvancedSortableDirective,
  SortEvent,
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { RapoarteClientTableService } from 'src/app/modules/acbcr/common/services/rapoarte-client.service';

@Component({
  selector: 'app-raport-client-table',
  templateUrl: './raport-client-table.component.html',
  styleUrls: ['./raport-client-table.component.scss']
})
export class RaportClientTableComponent implements OnInit, OnDestroy {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('rapoarteClientTable', { static: false })
  rapoarteClientTable: ElementRef;

  @Input() rapoarteClient: any[];
  @Input() rapoarteClientLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() rapoarteClientTableCallback = new EventEmitter();

  readonly modalType = 'rapoarte-client';
  openSearchModal = new Subject<boolean>();

  currentRoute: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  filteredRapoarte;
  isDataFiltered = false;

  constructor(
    public rapoarteClientService: RapoarteClientTableService,
  ) {}

  ngOnInit(): void {}

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.rapoarteClientService.sortColumn = column;
    this.rapoarteClientService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
    this.filteredRapoarte = event;
    // check if a filter was made
    this.isDataFiltered = !Object.values(this.filteredRapoarte).every(
      (o) => o === null
    );
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  getModalResult(event) {
    this.rapoarteClientTableCallback.emit(event);
  }

  ngOnDestroy(): void {
    this.rapoarteClientService.clearRapoarteTable();
  }
}
