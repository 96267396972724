import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener
} from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { AdeverinteApartenentaTableService } from 'src/app/modules/acbcr/common/services/adeverinte-apartenenta-table.service';

@Component({
  selector: 'app-view-allowance',
  templateUrl: './view-allowance.component.html',
  styleUrls: ['./view-allowance.component.scss']
})
export class ViewAllowanceComponent implements OnInit, OnChanges {
  @ViewChild('viewAllowanceModal') viewAllowanceModal: ElementRef;
  @Input() vaciRaseCarne: any;
  @Input() vaciMetise: any;
  @Input() tauriCarne: any;
  @Input() tineretRaseCarne: any;
  @Input() tineretMetis: any;
  @Input() aboutAdeverintaData: any;
  @Input() open = false;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  modalReference: NgbModalRef;
  openConfirmModal = false;

  constructor(
    public adeverintaApartenentaTableService: AdeverinteApartenentaTableService,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void { }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.viewAllowanceModal, {
        windowClass: 'modal-half-full',
        scrollable: true,
        backdrop: 'static'
      });
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  notifyCloseModal() {
    this.open = false;
    this.notifyClose.emit(false);
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.validateAdeverinta(this.aboutAdeverintaData.companyid, this.aboutAdeverintaData.id);
    }
  }

  private validateAdeverinta(companyid, documentid) {
    let vaciRaseCarneSave = [];
    let vaciMetiseSave = [];
    let tauriCarneSave = [];
    let tineretCarneSave = [];
    let tineretMetisSave = [];
    vaciRaseCarneSave = [...vaciRaseCarneSave, this.vaciRaseCarne.map(animal => {
      return [animal.tip, animal.animalid];
    })];
    vaciMetiseSave = [...vaciMetiseSave, this.vaciMetise.map(animal => {
      return [animal.tip, animal.animalid];
    })];
    tauriCarneSave = [...tauriCarneSave, this.tauriCarne.map(animal => {
      return [animal.tip, animal.animalid];
    })];
    tineretCarneSave = [...tineretCarneSave, this.tineretRaseCarne.map(animal => {
      return [animal.tip, animal.animalid];
    })];
    tineretMetisSave = [...tineretMetisSave, this.tineretMetis.map(animal => {
      return [animal.tip, animal.animalid];
    })];

    const anm = [];
    let i;
    if (vaciRaseCarneSave[0].length > 0) {
      for (i = 0; i < vaciRaseCarneSave[0].length; i++) {
        anm.push(vaciRaseCarneSave[0][i]);
      }
    }
    if (vaciMetiseSave[0].length > 0) {
      for (i = 0; i < vaciMetiseSave[0].length; i++) {
        anm.push(vaciMetiseSave[0][i]);
      }
    }
    if (tauriCarneSave[0].length > 0) {
      for (i = 0; i < tauriCarneSave[0].length; i++) {
        anm.push(tauriCarneSave[0][i]);
      }
    }
    if (tineretCarneSave[0].length > 0) {
      for (i = 0; i < tineretCarneSave[0].length; i++) {
        anm.push(tineretCarneSave[0][i]);
      }
    }
    if (tineretMetisSave[0].length > 0) {
      for (i = 0; i < tineretMetisSave[0].length; i++) {
        anm.push(tineretMetisSave[0][i]);
      }
    }

    const obj = { companyid, documentid, status: 2, items: anm };
    this.adeverintaApartenentaTableService.saveAdeverintaApi(obj).subscribe(() => {
      this.notifyCloseModal();
      this.modalReference.close();
      this.callbackResult.emit('');
    });
  }
}
