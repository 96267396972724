import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-holding-locations',
  templateUrl: './holding-locations.component.html',
  styleUrls: ['./holding-locations.component.scss']
})
export class HoldingLocationsComponent implements OnInit {
  @Input() holdingForm: UntypedFormGroup;
  @Input() submitted: boolean;

  numeLocatii = [];
  numeList = ['Sediu', 'Animale 1', 'Animale 2', 'Alta'];

  constructor() {}

  ngOnInit() {
    this.initNumeLocatii();
  }

  get locatii(): UntypedFormArray {
    return this.holdingForm.get('locatii') as UntypedFormArray;
  }

  initNumeLocatii() {
    for (let i in this.locatii.controls) {
      let locatieForm = this.locatii.controls[i];
      let nume = locatieForm.value.nume;

      let afiseaza = true;
      if (this.numeList.includes(nume)) {
        afiseaza = false;
      }

      if (nume && ! this.numeList.includes(nume)) {
        nume = 'Alta';
      }

      this.numeLocatii[i] = {
        value: nume,
        items: this.numeList,
        afiseaza: afiseaza,
      }
    }
  }

  adaugaLocatie() {
    let locatieForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      nume: new UntypedFormControl(null, Validators.required),
      lat: new UntypedFormControl(null, Validators.required),
      lng: new UntypedFormControl(null, Validators.required),
    }, this.locatieFormValidator);

    this.locatii.push(locatieForm);
    this.initNumeLocatii();
  }

  stergeLocatie(index) {
    this.locatii.removeAt(index);
  }

  locatieFormValidator(locatieForm) {
    let lat = locatieForm.value.lat;
    let lng = locatieForm.value.lng;

    if (isNaN(lat) || isNaN(lng)) {
      return {'bad_format': true}
    }

    return null;
  }

  setCurrentLocation(locatieForm) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        locatieForm.controls.lat.setValue(position.coords.latitude);
        locatieForm.controls.lng.setValue(position.coords.longitude);
      });
    }
  }

  changedNume(event, locatieForm) {
    if (event == 'Alta') {
      locatieForm.controls.nume.setValue(null);
    } else {
      locatieForm.controls.nume.setValue(event);
    }

    this.initNumeLocatii();
  }
}
