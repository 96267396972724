import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root'
})
export class RaportGeneralService {
  private baseUrl = environment.API_URL;
  urlCreaza: string;
  urlListaFisiere: string;
  urlInCurs: string;
  urlDownload: string;
  urlSterge: string;

  constructor(private http: HttpClient) { }

  setupUrl(stubUrl: string) {
    this.urlCreaza = `${this.baseUrl}/rapoarte/${stubUrl}/`;
    this.urlListaFisiere = `${this.baseUrl}/rapoarte/${stubUrl}/list/`;
    this.urlInCurs = `${this.baseUrl}/rapoarte/${stubUrl}/in_curs/`;
    this.urlDownload = `${this.baseUrl}/rapoarte/${stubUrl}/download/`;
    this.urlSterge = `${this.baseUrl}/rapoarte/${stubUrl}/delete/`;
  }

  creazaRaportAPI(obj: any) {
    return this.http.post(this.urlCreaza, obj, auth.options).pipe(map((response: any) => {
      return response;
    }));
  }

  listaFisiereAPI() {
    return this.http.get(this.urlListaFisiere, auth.options).pipe(map((response: any) => {
      return response;
    }));
  }

  inCursAPI() {
    return this.http.get(this.urlInCurs, auth.options).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadRaportAPI(fisier: string) {
    return this.http
      .post(this.urlDownload, { fisier: fisier },
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  stergeRaportAPI(fisier: string) {
    return this.http.post(this.urlSterge, { fisier: fisier }, auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }
}
