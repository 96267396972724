import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { CertificatSanitarService } from 'src/app/modules/acbcr/common/services/certificat-sanitar.service';


@Component({
  selector: 'app-certificat-sanitar',
  templateUrl: './certificat-sanitar.component.html',
  styleUrls: ['./certificat-sanitar.component.scss']
})
export class CertificatSanitarComponent implements OnInit {
  @ViewChild('searchModal') searchModal: ElementRef;

  loadingDataSpinner = false;
  itemsList = [];
  totalCount: number;
  addEditModalData;
  openDelete = false;
  idDelete;
  certificatSanitarForm: UntypedFormGroup;
  isAdmin = false;
  isOperator = false;
  isFermier = false;

  req_params = {
    page_no: 1,
    page_size: 10,
  };

  constructor(
    public certificatSanitarService: CertificatSanitarService,
    public modalService: NgbModal,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.certificatSanitarForm = new UntypedFormGroup({
      start_date: new UntypedFormControl(),
      end_date: new UntypedFormControl(),
      company_name: new UntypedFormControl(),
      company_code: new UntypedFormControl(),
      numarmatricol: new UntypedFormControl(),
      rasa: new UntypedFormControl(),
    });

    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.itemsList = [];
    let list_params = {
      ...this.req_params,
      ...this.certificatSanitarForm.value
    };

    this.certificatSanitarService.listAPI(list_params)
      .subscribe({
        next: (response) => {
          this.loadingDataSpinner = false;
          this.itemsList = response.result;
          this.totalCount = response.count;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
        }
      });
  }


  changePageSize(event) {
    this.req_params.page_no = 1;
    this.req_params.page_size = event.target.value;
    this.certificatSanitarService.page = 1;
    this.getTableData();
  }

  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  openAddModal() {
    this.addEditModalData = {id: null};
  }

  openEditModal(item) {
    this.addEditModalData = {id: item?.id};
  }

  closeAddEditModal() {
    this.ngOnInit();
  }

  search() {
    this.req_params.page_no = 1;
    this.certificatSanitarService.page = 1;
    this.getTableData();
  }

  resetFields() {
    if (this.certificatSanitarForm) {
      this.certificatSanitarForm.reset();
    }
  }

  resetSearch() {
    this.resetFields();
    this.search();
  }

  openDeleteModal(id) {
    this.openDelete = true;
    this.idDelete = id;
  }

  closeDeleteModal(event) {
    this.openDelete = false;

    if (event != true) {
      return;
    }

    let buton = document.getElementsByClassName('sterge-id-' + this.idDelete)[0];
    buton?.setAttribute('disabled', 'true');
    let params = {
      id: this.idDelete,
    }

    this.certificatSanitarService.stergeAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
        }
      });
  }

  export(event) {
    event.target.disabled = true;
    let now = UIHelper.now();
    let fisier = `Certificat_Sanitar_${now}.xls`;
    let export_params = this.certificatSanitarForm.value;

    this.certificatSanitarService.exportAPI(export_params)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }

}
