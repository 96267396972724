 <ng-template #addEditAllowanceModal let-modal="close('Cross click')">
  <div class="modal-header row" [ngSwitch]="addEditModalType">
    <div class="col-sm-12 col-md-12 col-lg-6 custom-col">
      <h3 class="modal-title" *ngSwitchCase="'add'">
        Solicitare adeverință de apartenență nouă
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit'">
        Editează adeverința de apartenență
      </h3>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-dark"
          (click)="(modal); notifyCloseModal()"
          autofocus="false"
        >
          Anulează
        </button>
        <button class="btn btn-sm btn-success" (click)="onSubmitted()">
          <span *ngIf="addEditModalType === 'add'; else btnTxt">
            Salvează solicitare adeverință
          </span>
          <ng-template #btnTxt>
            <span>
              Actualizează adeverința de apartenență
            </span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-3">
            <label for="data">
              Data solicitării*
            </label>
            <app-date-picker
              (dateChanged)="dataAllowanceChanged($event)"
              [required]="true"
              [submitted]="submitted"
              [selectedDate]="selectedDate"
            >
            </app-date-picker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-3">
            <label for="exploatatieid">
              Exploatație
            </label>
            <ng-select
              [items]="listaExploatatii"
              bindLabel="name"
              bindValue="id"
              [ngModel]="disableDropDown === false ? null : selectedHoldingId"
              [required]="true"
              placeholder="Alege exploatatie"
              [ngClass]="{disabled: addEditModalType === 'edit' || disableDropDown}"
              (change)="onHoldingSelect($event)"
              (search)="searchHoldings($event)"
              (clear)="clearHoldings()"
              (blur)="clearHoldings()"
            >
            </ng-select>
            <small
              *ngIf="showHint && disableDropDown"
              id="emailHelp"
              class="form-text text-muted"
            >
              <div class="mb-2">
                <b>Introdu minim 5 caractere pentru a căuta</b>
              </div>
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <button
        class="btn btn-md btn-info ml-2"
        (click)="populateLists()"
        autofocus="false"
      >
        Autopopulează listele
      </button>
      </div>
      <ngb-alert
        type="danger"
        class="text-center search-error mt-2"
        *ngIf="showError === true"
        [dismissible]="false"
        >
        Alegeți exploatația mai intâi.
      </ngb-alert>
      <ngb-alert
          type="danger"
          class="text-center search-error mt-2"
          *ngIf="isDuplicate"
          [dismissible]="false"
          >
            Număr certificat deja existent.
        </ngb-alert>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Vaci din rase de carne</h3>
        </div>
      </div>
      <div *ngFor="let animal of vaciRaseCarneSalvate" class="mb-2">
        <div>
          <div class="row matricol-row">
            <div class="col-">
              <button
                type="button"
                class="btn btn-link animal-numar-matricol"
                > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
              </button>
            </div>
          </div>
          <div class="row details-row">
            <div class=" ml-2 col-">
              <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
              <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
            </div>
            <div class="col-3 rasa-col">
              <h4 class="about-animal">
                {{ animal.rasa }}, varsta {{ animal.varsta }} zile
              </h4>
            </div>
            <div class="col-2">
              <h4 class="about-animal">Eligibil</h4>
            </div>
            <div class="col-">
              <button
                class="btn btn-md btn-icon delete-animal-icon"
                title="Șterge"
                (click)="deleteVaciCarne(animal)"
                >
                <i class="mdi mdi-delete-forever"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <div class="form-group mb-3">
            <ng-select
              [items]="vaciRaseCarne"
              [(ngModel)]="vacaCarneDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <button
            class="btn btn-sm btn-dark"
            (click)="addVaciCarne()"
            autofocus="false"
            >
            Adaugă
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Vaci metise cu rase de carne</h3>
        </div>
      </div>
      <div>
        <div *ngFor="let animal of vaciMetiseSalvate" class="mb-2">
          <div class="row matricol-row">
            <div class="col-">
              <button
                type="button"
                class="btn btn-link animal-numar-matricol"
                > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
              </button>
            </div>
          </div>
          <div class="row details-row">
            <div class="ml-2 col-">
              <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
              <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
            </div>
            <div class="col-3 rasa-col">
              <h4 class="about-animal">
                {{ animal.rasa }}, varsta {{ animal.varsta }} zile
              </h4>
            </div>
            <div class="col-2">
              <h4 class="about-animal">Eligibil</h4>
            </div>
            <div class="col-">
              <button
                class="btn btn-md btn-icon delete-animal-icon"
                title="Șterge"
                (click)="deleteVaciMetise(animal)"
                >
                <i class="mdi mdi-delete-forever"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <div class="form-group mb-3">
            <ng-select
              [items]="vaciMetise"
              [(ngModel)]="vacaMetisDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <button
            class="btn btn-sm btn-dark"
            (click)="addVaciMetise()"
            autofocus="false"
            >
            Adaugă
          </button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Tauri din rase de carne</h3>
        </div>
      </div>
      <div>
        <div *ngFor="let animal of tauriCarneSalvate" class="mb-2">
          <div class="row matricol-row">
            <div class="col-">
              <button
                type="button"
                class="btn btn-link animal-numar-matricol"
                > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
              </button>
            </div>
          </div>
          <div class="row details-row">
            <div class=" ml-2 col-">
              <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
              <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
            </div>
            <div class="col-3 rasa-col">
              <h4 class="about-animal">
                {{ animal.rasa }}, varsta {{ animal.varsta }} zile
              </h4>
            </div>
            <div class="col-2">
              <h4 class="about-animal">Eligibil</h4>
            </div>
            <div class="col-">
              <button
                class="btn btn-md btn-icon delete-animal-icon"
                title="Șterge"
                (click)="deleteTauri(animal)"
                >
                <i class="mdi mdi-delete-forever"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <div class="form-group mb-3">
            <ng-select
              [items]="tauriCarne"
              [(ngModel)]="taurDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <button
            class="btn btn-sm btn-dark"
            (click)="addTauri()"
            autofocus="false"
            >
            Adaugă
          </button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Tineret mascul și/sau femele din rase de carne</h3>
        </div>
      </div>
      <div>
        <div *ngFor="let animal of tineretRaseCarneSalvate" class="mb-2">
          <div class="row matricol-row">
            <div class="col-">
              <button
                type="button"
                class="btn btn-link animal-numar-matricol"
                > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
              </button>
            </div>
          </div>
          <div class="row details-row">
            <div class=" ml-2 col-">
              <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
              <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
            </div>
            <div class="col-3 rasa-col">
              <h4 class="about-animal">
                {{ animal.rasa }}, varsta {{ animal.varsta }} zile
              </h4>
            </div>
            <div class="col-2">
              <h4 class="about-animal">Eligibil</h4>
            </div>
            <div class="col-">
              <button
                class="btn btn-md btn-icon delete-animal-icon"
                title="Șterge"
                (click)="deleteTineretCarne(animal)"
                >
                <i class="mdi mdi-delete-forever"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <div class="form-group mb-3">
            <ng-select
              [items]="tineretRaseCarne"
              [(ngModel)]="tineretCarneDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <button
            class="btn btn-sm btn-dark"
            (click)="addTineretCarne()"
            autofocus="false"
            >
            Adaugă
          </button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Tineret mascul și/sau femele metis cu rase de carne</h3>
        </div>
      </div>
      <div>
        <div *ngFor="let animal of tineretMetisSalvate" class="mb-2">
          <div class="row matricol-row">
            <div class="col-">
              <button
                type="button"
                class="btn btn-link animal-numar-matricol"
                > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
              </button>
            </div>
          </div>
          <div class="row details-row">
            <div class=" ml-2 col-">
              <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
              <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
            </div>
            <div class="col-3 rasa-col">
              <h4 class="about-animal">
                {{ animal.rasa }}, varsta {{ animal.varsta }} zile
              </h4>
            </div>
            <div class="col-2">
              <h4 class="about-animal">Eligibil</h4>
            </div>
            <div class="col-">
              <button
                class="btn btn-md btn-icon delete-animal-icon"
                title="Șterge"
                (click)="deleteTineretMetisi(animal)"
                >
                <i class="mdi mdi-delete-forever"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <div class="form-group mb-3">
            <ng-select
              [items]="tineretMetis"
              [(ngModel)]="tineretMetisDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-3">
          <button
            class="btn btn-sm btn-dark"
            (click)="addTineretMetisi()"
            autofocus="false"
            >
            Adaugă
          </button>
        </div>
      </div>

    </div>
  </div>
 </ng-template>
