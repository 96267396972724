import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { City } from 'src/app/modules/acbcr/common/models/city.model';
import { getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { AnimalRase } from 'src/app/modules/acbcr/common/models/animal.models';
import { getAppAnimalsRases } from 'src/app/modules/acbcr/common/state/reducers/animals.reducers';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-animal-search',
  templateUrl: './animal-search.component.html',
  styleUrls: ['./animal-search.component.scss']
})
export class AnimalSearchComponent implements OnInit, OnDestroy {
  @Output() searchResult = new EventEmitter();
  @ViewChild('smallCitiesDropDown') smallCitiesDropDown: NgSelectComponent;
  @ViewChild('citiesDropdown') citiesDropdown: NgSelectComponent;

  animalSearchForm;
  destroy$: Subject<boolean> = new Subject<boolean>();
  cities: City[];
  smallCities: City[];
  exploatatii = [];
  raseAnimal: AnimalRase[];
  hideFields = false;
  showHint = true;
  currentRoute: string;
  isAdmin = false;
  isOjz = false;

  constructor(
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>,
    private errorService: NotificationErrorService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOjz = UIHelper.isOjz();

    // Read current app route from the store and hide judet and localitate fields
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
        if (this.currentRoute.includes('/animale-exploatatie')) {
          this.hideFields = true;
        }
      });

    this.store
      .pipe(select(getAppCities))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.cities = response;
      });

    this.store.pipe(select(getAppAnimalsRases))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: AnimalRase[]) => {
        this.raseAnimal = response;
      });

    this.animalSearchForm = new UntypedFormGroup({
      exploatatieid: new UntypedFormControl(),
      status: new UntypedFormControl(),
      sex: new UntypedFormControl(),
      rasa: new UntypedFormControl(),
      sectiune: new UntypedFormControl(),
      categorie_femela: new UntypedFormControl(),
      are_avizare_taur: new UntypedFormControl(),
      are_autorizatie_monta: new UntypedFormControl(),
      numarmatricol: new UntypedFormControl(),
      codtaur: new UntypedFormControl(),
      judetid: new UntypedFormControl(),
      localitateid: new UntypedFormControl()
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  getSelectedCity(city) {
    if (city !== undefined) {
      this.helperDataService.getSmallCities(city.code).subscribe(response => {
        this.smallCities = response;
      });
    }

    this.smallCitiesDropDown.handleClearClick();
    this.smallCities = [];
  }

  onSubmitted() {
    // Reset hidden fields when the search is made from a specific holding
    if (this.currentRoute.includes('/animale-exploatatie')) {
      this.animalSearchForm.controls.judetid.reset();
      this.animalSearchForm.controls.localitateid.reset();
    }

    this.searchResult.emit(this.animalSearchForm.value);
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.showHint = true;
      this.exploatatii = [];

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  clearHoldings() {
    this.showHint = true;
  }

  selectCategorieFemela(event) {
    if (event === undefined) {
      this.animalSearchForm.controls.sex.setValue(null);
    } else {
      this.animalSearchForm.controls.sex.setValue('2');
      this.animalSearchForm.controls.are_avizare_taur.setValue(null);
      this.animalSearchForm.controls.are_autorizatie_monta.setValue(null);
    }
  }

  selectiePentruTaur(event) {
    if (event === undefined) {
      this.animalSearchForm.controls.sex.setValue(null);
    } else {
      this.animalSearchForm.controls.sex.setValue('1');
      this.animalSearchForm.controls.categorie_femela.setValue(null);
    }
  }

  resetSearch() {
    this.animalSearchForm.reset();
    this.onSubmitted();
  }
}
