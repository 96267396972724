import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  SortableTable,
  convertDateToDisplayFormat,
  convertDateToBackendFormat
} from './services-table-helper';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root'
})
export class RegistruGenealogicTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  animale: any[];
  numarAnimale: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.animale, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getAnimalsAPI(obj) {
    return this.http
    .post(`
      ${this.baseUrl}/registru_genealogic/list/`,
      obj, auth.options
    ).pipe(
      map((response: any) => {
        this.animale = response.result;
        this.numarAnimale = response.count;

        this.animale.map(animal => {
          animal.dataNastere = convertDateToDisplayFormat(animal.dataNastere);
          animal.isChecked = false;
        });

        this.paginate();
        this.sortEvent();
        this._total$.next(this.numarAnimale);

        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  getReason(animalId) {
    return this.http.post(`${this.baseUrl}/registru_genealogic/evaluare-animal/`,
    { id: animalId }, auth.options
    ).pipe(
      map((response: any) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  getAnimalsRegGen(obj) {
    return this.http
    .post(
      `${this.baseUrl}/registru_genealogic/list/`,
      {
        ...obj, data: convertDateToBackendFormat(obj.data),
      },
      auth.options
    ).pipe(map((response: any) => {
      return response.result;
    }));
  }

  exportAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/registru_genealogic/exportxls/`,
        req_params,
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      ).pipe(
        map((response: any) => {
          return response;
      }));
  }

  exportXLS(data, numarmatricolList) {
    return this.http
    .post(
      `${this.baseUrl}/registru_genealogic/exportxls/`,
      {
        data: convertDateToBackendFormat(data),
        numarmatricol_list : numarmatricolList
      },
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage['access-token']}`,
        })
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  getAcbcrRacesAPI() {
    return this.http
      .get(
        `${this.baseUrl}/acbcr-race/`,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  checkRgSection(animalId: number, section: number) {
    return this.http
      .post(
        `${this.baseUrl}/animals/animal-chkRGSection/`,
        { animalId, section },
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  simulateFitDescendants(numarMatricol, email, sectRGeneral, sectRGRoman) {
    return this.http
      .post(
        `${this.baseUrl}/animals/animal-fit-all-descendants/`,
        { numarMatricol, email, update: false, sectRGeneral, sectRGRoman },
        auth.options
      ).pipe(map((response: any) => {
        return response;
    }));
  }

  fitDescendants(numarMatricol, email, sectRGeneral, sectRGRoman) {
    return this.http
      .post(
        `${this.baseUrl}/animals/animal-fit-all-descendants/`,
        { numarMatricol, email, update: true, sectRGeneral, sectRGRoman },
        {
          responseType: 'blob',
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      ).pipe(map((response: any) => {
        return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarAnimale;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarAnimale) {
      this._state.endIndex = this.numarAnimale;
    }
  }
}
