<div class="container-fluid mt-3">
  <div class="col-12">
    <div class="card settings-card">
      <div class="row">
        <div class="col-12">
          <p class="settings-type">Setează serviciile</p>
        </div>
      </div>
      <form name="settingsForm" [formGroup]="settingsForm">
        <div class="col-sm-3 col-lg-3">
          <label for="acitivity"> Tip factură* </label>
          <ng-select
            class="read-only-form tip-factura"
            placeholder="Alege tipul de factură"
            (change)="selectFactura($event)"
            [appendTo]="'body'"
          >
            <ng-option [value]="1">RG</ng-option>
            <ng-option [value]="2">CPP</ng-option>
            <ng-option [value]="3">ACBCR</ng-option>
          </ng-select>
        </div>
        <br>
        <div class="col-sm-12 col-lg-12">
          <label for="activities">
            Activitate*
          </label>
          <ng-select
            [items]="activities"
            class="read-only-form activities"
            bindLabel="descriere"
            bindValue="id"
            placeholder="Alege activitatea"
            (change)="selectActivity($event)"
            [appendTo]="'body'"
          >
          </ng-select>
        </div>
        <div class="table-responsive" #servicesTable>
          <table
            id="um-datatable"
            class="table table-bordered dt-responsive nowrap table-hover"
            formArrayName="services"
          >
            <thead>
              <tr>
                <th>
                  UM
                </th>
                <th style="width: 20%;">
                  CRITERIU
                </th>
                <th>
                  PU
                </th>
                <th>
                  RASA
                </th>
                <th>
                  DESCRIERE SCURTĂ
                </th>
                <th>
                  DESCRIERE
                </th>
                <th style="width: 10%; text-align: center;">
                  ACȚIUNI
                </th>
              </tr>
            </thead>

            <ngb-alert
              type="danger"
              class="text-center search-error"
              *ngIf="!activitateId"
              [dismissible]="false"
            >
              Nu a fost selectată nici o activitate.
            </ngb-alert>
            <tbody *ngIf="activitateId">
              <tr
                [formGroupName]="i"
                *ngFor="
                  let item of settingsForm['controls'].services['controls'];
                  let i = index;
                  let last = last
                "
              >
                <td>
                  <ng-select
                    [items]="umSettings"
                    class="read-only-form"
                    bindLabel="um_code"
                    bindValue="um_code"
                    formControlName="um"
                    placeholder="Alege UM"
                    [appendTo]="'body'"
                    (change)="umSelect($event, i)"
                  >
                  </ng-select>
                </td>
                <td>
                  <ng-select
                    [items]="criteriiToShow[i]"
                    class="read-only-form"
                    bindLabel="descriere"
                    bindValue="id"
                    formControlName="criteriu"
                    placeholder="Alege Criteriul"
                    [appendTo]="'body'"
                  >
                  </ng-select>
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control read-only-form"
                    name="pu"
                    formControlName="pu"
                    (click)="makeActive(i, $event)"
                    placeholder="Adaugă PU"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td>
                  <ng-select
                    [items]="raseAnimal"
                    class="read-only-form"
                    bindLabel="name"
                    bindValue="idacbcr_race"
                    [placeholder]="last ? 'Alege rasa' : 'Nu contează'"
                    formControlName="rasa"
                    [appendTo]="'body'"
                  >
                    <ng-option value="null">Nu contează</ng-option>
                  </ng-select>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control read-only-form"
                    name="descriere_scurta"
                    formControlName="descriere_scurta"
                    placeholder="Adaugă descrierea scurtă"
                    (click)="makeActive(i, $event)"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control read-only-form"
                    name="descriere"
                    formControlName="descriere"
                    placeholder="Adaugă descriere"
                    (click)="makeActive(i, $event)"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td style="text-align: center;">
                  <button
                    *ngIf="last"
                    class="btn btn-sm btn-icon btn-success"
                    ngbTooltip="Adaugă activitatea"
                    placement="bottom"
                    (click)="saveLineItem(i)"
                  >
                    <i class="fe-save"></i>
                  </button>
                  <div class="actions-buttons">
                    <button
                      *ngIf="!last"
                      class="btn btn-sm btn-icon btn-warning mr-1"
                      ngbTooltip="Salvează activitatea"
                      placement="bottom"
                      (click)="editLineItem(i)"
                    >
                      <i class="mdi mdi-square-edit-outline"></i>
                    </button>
                    <button
                      *ngIf="!last"
                      class="btn btn-sm btn-icon btn-danger"
                      ngbTooltip="Șterge activitatea"
                      placement="bottom"
                      (click)="deleteLineItem(i, item)"
                    >
                      <i class="mdi mdi-delete-forever"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
