import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {


  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private router: Router,
    private errorService: NotificationErrorService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage['access-token']) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${localStorage['access-token']}`)
      });
    }

    return next.handle(request).pipe(
      tap({
        next: (response) => {
        },
        error: (errors) => {
          if (errors.status === 403) {
            this.router.navigateByUrl('/login');
            localStorage.removeItem('access-token');

            return;
          }

          if (errors.error instanceof Blob) {
            let blobToTextPromise = errors.error.text();
            blobToTextPromise.then((text: string) => {
              let jsonObj;

              try {
                jsonObj = JSON.parse(text);
              } catch (e) {
                return;
              }

              if (jsonObj['error']) {
                this.errorService.processErrorMsg(jsonObj['error']);

                return;
              }
            })
          }

          if (errors.error) {
            let error = errors.error.error || 'A apărut o eroare';
            this.errorService.processErrorMsg(error);
          }
        }
      })
    )
  }
}
