import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharingHelperDataService {

  // Sharing CAEN codes data
  private CAENCodesSource = new BehaviorSubject('');
  currentCAENCodes = this.CAENCodesSource.asObservable();

  constructor() {
  }

  shareCAENCodes(codes) {
    this.CAENCodesSource.next(codes);
  }
}
