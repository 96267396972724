import { Component, OnInit, AfterViewInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import { HelperDataService } from './../../../../../common/services/helper-data.service';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { City } from 'src/app/modules/acbcr/common/models/city.model';
import { getAppAnimalsRases } from 'src/app/modules/acbcr/common/state/reducers/animals.reducers';
import { AnimalRase } from 'src/app/modules/acbcr/common/models/animal.models';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-holding-search',
  templateUrl: './holding-search.component.html',
  styleUrls: ['./holding-search.component.scss']
})
export class HoldingSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('smallCitiesDropDown') smallCitiesDropDown: NgSelectComponent;
  @ViewChild('citiesDropdown') citiesDropdown: NgSelectComponent;
  @ViewChild('holdingCodeInput') holdingCodeInput;

  @Output() searchResult = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  cities: City[];
  smallCities: City[];
  isOjz = false;
  isAutoritateJudeteana = false;
  isContabil = false;

  holdingSearchForm: UntypedFormGroup;
  rase: AnimalRase[];

  constructor(
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
  ) {
    this.smallCities = [];
  }

  ngOnInit(): void {
    this.store.pipe(select(getAppCities))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.cities = response;
      });

    this.isOjz = UIHelper.isOjz();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();

    //  Read animals rases from the store
    this.store.pipe(select(getAppAnimalsRases))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: AnimalRase[]) => {
        this.rase = response?.filter(rasa => {
          return rasa.shortname != 'NEC'
        });
      });

    this.holdingSearchForm = new UntypedFormGroup({
      comp_name: new UntypedFormControl(null),
      comp_status: new UntypedFormControl('1'),
      comp_code: new UntypedFormControl(null),
      comp_cui: new UntypedFormControl(null),
      comp_races: new UntypedFormControl(null),
      comp_settl: new UntypedFormControl(null),
      comp_county: new UntypedFormControl(null),
    });
  }

  ngAfterViewInit() {
    this.holdingCodeInput.nativeElement.focus();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  getSelectedCity(city) {
    if (city !== undefined) {
      this.helperDataService.getSmallCities(city.code)
        .subscribe(response => {
          this.smallCities = response;
        });
    }

    this.smallCitiesDropDown.handleClearClick();
    this.smallCities = [];
  }

  onSubmitted() {
    this.searchResult.emit(this.holdingSearchForm.value);
  }

  resetSearch() {
    this.holdingSearchForm.reset();
    this.onSubmitted();
  }
}
