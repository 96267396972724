import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators } from '@angular/forms';
import { ContabilitateSettingsService } from 'src/app/modules/acbcr/common/services/contabilitate-settings.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-um-criterii-settings',
  templateUrl: './um-criterii-settings.component.html',
  styleUrls: ['./um-criterii-settings.component.scss']
})
export class UmCriteriiSettingsComponent implements OnInit {
  settingsForm;
  unitatiMasura;
  submitted = false;
  editSuccess = false;
  activeFields = [];

  constructor(
    private contabilitateSettingsService: ContabilitateSettingsService,
    private formBuilder: UntypedFormBuilder,
    private errorService: NotificationErrorService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.getSettings();
  }

  getSettings() {
    this.contabilitateSettingsService.getUMSettings().subscribe(settings => {
      if (settings) {
        this.unitatiMasura = settings;
        this.buildForm(settings);
      } else {
        this.buildForm();
      }
    });
    this.submitted = false;
  }

  buildForm(formData?) {
    this.settingsForm = this.formBuilder.group({
      ums: this.formBuilder.array([])
    });

    formData?.push({id: null, um_code: ''});
    this.createItems(formData);
  }

  get f() {
    return this.settingsForm.controls.ums as UntypedFormArray;
  }

  createItems(data) {
    if (data && data.length > 0) {
      this.unitatiMasura.forEach(x => {
        this.f.push(this.createItem(x));
      });
    } else {
      this.f.push(this.createItem());
    }
  }

  createItem(unitateMasura?): UntypedFormGroup {
    return this.formBuilder.group({
      id: [unitateMasura ? unitateMasura.id : ''],
      um_code: [unitateMasura ? unitateMasura.um_code : '', Validators.required]
    });
  }

  makeActive(i) {
    this.activeFields[i] = true;
  }

  saveLineItem(i) {
    this.submitted = true;
    this.contabilitateSettingsService.addUM(this.settingsForm.value.ums[i]).subscribe(response => {
      this.getSettings();
      this.activeFields[i] = false;
      this.createItems([]);
    }, errors => this.errorService.processErrorMsg(errors.error.error));
  }

  editLineItem(i) {
    this.contabilitateSettingsService.updateUM(this.settingsForm.value.ums[i]).subscribe(response => {
      this.activeFields[i] = false;
    }, errors => this.errorService.processErrorMsg(errors.error.error));
  }

  deleteLineItem(i, id) {
    this.contabilitateSettingsService.deleteUM(id).subscribe(response => {
      this.getSettings();
      this.activeFields.splice(i, 1);
    }, errors => this.errorService.processErrorMsg(errors.error.error));
  }

}
