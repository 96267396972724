import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { SettlementsTableService } from '../../../common/services/settlements-table.service';

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss'],
  providers: [SettlementsTableService, DecimalPipe]
})
export class SettlementsComponent implements OnInit {

  tables$: Observable<any[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    page_number: '1',
    page_size: '10'
  };

  constructor(
    public settlementsTableService: SettlementsTableService,
  ) {
    this.tables$ = settlementsTableService.tables$;
    this.total$ = settlementsTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.settlementsTableService
      .getSettlementsAPI(this.objToAPI).subscribe((response) => {
        // Hide spinner, after 1 second
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    // Compose the object what will be sent to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_number = event;
    this.getTableData();
  }

  getTableCallback(event) {
    this.getTableData();
  }

}
