<ng-template #viewAllowanceModal let-modal="close('Cross click')">
  <div class="modal-header" *ngIf="aboutAdeverintaData">
    <h3 class="modal-title">
      Adeverință de apartenență<br/>
      <span class="allowance-data">
        Solicitat de <b>{{ aboutAdeverintaData.autor }}</b><br> pentru exploatația: <b>{{ aboutAdeverintaData.compname }}</b>
      </span>
    </h3>
    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="(modal); notifyCloseModal()"
        autofocus="false"
      >
        Închide
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row" *ngIf="vaciRaseCarne && vaciRaseCarne.length > 0">
        <div class="row">
          <div class="col-12">
            <h3 class="modal-title">Vaci din rase de carne</h3>
          </div>
        </div>
        <div class="col-12">
          <div *ngFor="let animal of vaciRaseCarne" class="mb-2">
            <div class="row matricol-row">
              <div class="col-">
                <button
                  type="button"
                  class="btn btn-link animal-numar-matricol"
                  > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
                </button>
              </div>
            </div>
            <div class="row details-row">
              <div class=" ml-2 col-">
                <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
                <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
              </div>
              <div class="col-3 rasa-col">
                <h4 class="about-animal">
                  {{ animal.rasa }}, varsta {{ animal.varsta }} zile
                </h4>
              </div>
              <div class="col-">
                <h4 class="about-animal">Eligibil</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="vaciMetise && vaciMetise.length > 0">
        <div class="row">
          <div class="col-12">
            <h3 class="modal-title">Vaci metise cu rase de carne</h3>
          </div>
        </div>
        <div class="col-12">
          <div *ngFor="let animal of vaciMetise" class="mb-2">
            <div class="row matricol-row">
              <div class="col-">
                <button
                  type="button"
                  class="btn btn-link animal-numar-matricol"
                  > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
                </button>
              </div>
            </div>
            <div class="row details-row">
              <div class="col-">
                <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
                <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
              </div>
              <div class="col-3 rasa-col">
                <h4 class="about-animal">
                  {{ animal.rasa }}, varsta {{ animal.varsta }} zile
                </h4>
              </div>
              <div class="col-">
                <h4 class="about-animal">Eligibil</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tauriCarne && tauriCarne.length > 0">
        <div class="row">
          <div class="col-12">
            <h3 class="modal-title">Tauri din rase de carne</h3>
          </div>
        </div>
        <div class="col-12">
          <div *ngFor="let animal of tauriCarne" class="mb-2">
            <div class="row matricol-row">
              <div class="col-">
                <button
                  type="button"
                  class="btn btn-link animal-numar-matricol"
                  > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
                </button>
              </div>
            </div>
            <div class="row details-row">
              <div class="col-">
                <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
                <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
              </div>
              <div class="col-3 rasa-col">
                <h4 class="about-animal">
                  {{ animal.rasa }}, varsta {{ animal.varsta }} zile
                </h4>
              </div>
              <div class="col-">
                <h4 class="about-animal">Eligibil</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tineretRaseCarne && tineretRaseCarne.length > 0">
        <div class="row">
          <div class="col-12">
            <h3 class="modal-title">Tineret mascul și/sau femele din rase de carne</h3>
          </div>
        </div>
        <div class="col-12">
          <div *ngFor="let animal of tineretRaseCarne" class="mb-2">
            <div class="row matricol-row">
              <div class="col-">
                <button
                  type="button"
                  class="btn btn-link animal-numar-matricol"
                  > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
                </button>
              </div>
            </div>
            <div class="row details-row">
              <div class="col-">
                <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
                <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
              </div>
              <div class="col-3 rasa-col">
                <h4 class="about-animal">
                  {{ animal.rasa }}, varsta {{ animal.varsta }} zile
                </h4>
              </div>
              <div class="col-">
                <h4 class="about-animal">Eligibil</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tineretMetis && tineretMetis.length > 0">
        <div class="row">
          <div class="col-12">
            <h3 class="modal-title">Tineret mascul și/sau femele metis cu rase de carne</h3>
          </div>
        </div>
        <div class="col-12">
          <div *ngFor="let animal of tineretMetis" class="mb-2">
            <div class="row matricol-row">
              <div class="col-">
                <button
                  type="button"
                  class="btn btn-link animal-numar-matricol"
                  > {{ animal.numarmatricol }}, <span class="nume-animal">{{ animal.nume }}</span>
                </button>
              </div>
            </div>
            <div class="row details-row">
              <div class="col-">
                <i class="mdi mdi-gender-male mr-2" *ngIf="animal.sex === 1"></i>
                <i class="mdi mdi-gender-female mr-2" *ngIf="animal.sex === 2"></i>
              </div>
              <div class="col-3 rasa-col">
                <h4 class="about-animal">
                  {{ animal.rasa }}, varsta {{ animal.varsta }} zile
                </h4>
              </div>
              <div class="col-">
                <h4 class="about-animal">Eligibil</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'valideaza-adeverinta'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
