import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Operator } from 'src/app/modules/acbcr/common/models/operator.models';
import { OperatorsTableService } from 'src/app/modules/acbcr/common/services/operators-table.service';

@Component({
  selector: 'app-add-update-operator',
  templateUrl: './add-update-operator.component.html',
  styleUrls: ['./add-update-operator.component.scss']
})
export class AddUpdateOperatorComponent implements OnInit, OnChanges {
  @ViewChild('addEditOperatorModal') addEditOperatorModal: ElementRef;

  @Input() operator: Operator;
  @Input() open = false;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  modalReference: NgbModalRef;

  validCnp: boolean;
  modalType: string;
  operatorForm: UntypedFormGroup;
  submitted = false;

  constructor(
    public modalService: NgbModal,
    private operatorTabelService: OperatorsTableService,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.addEditOperatorModal, {
        windowClass: 'modal-half-full',
        scrollable: true,
        backdrop: 'static'
      });
    }

    if (this.operator) {
      this.modalType = 'edit';
      this.buildForm(this.operator);
    } else {
      this.modalType = 'add';
      this.buildForm();
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  buildForm(formData?) {

    this.operatorForm = new UntypedFormGroup({
      id: new UntypedFormControl(
        formData ? formData.id : null),
      oi_nume: new UntypedFormControl(
        formData ? formData.nume : '',
        Validators.required),
      oi_prenume: new UntypedFormControl(
        formData ? formData.prenume : '',
        Validators.required),
      oi_cnp: new UntypedFormControl(
        formData ? formData.cnp : ''),
      oi_code: new UntypedFormControl(
        formData ? formData.cod : '',
        Validators.required),
      oi_nraut: new UntypedFormControl(
        formData ? formData.nraut : '',
        Validators.required),
      oi_status: new UntypedFormControl(
        formData ? formData.status : 0,
        Validators.required),
    });

    this.validCnp = true;
    this.submitted = false;
  }

  onSubmitted() {

    this.submitted = true;
    if (!this.operatorForm.valid) {
      return;
    }

    if (this.modalType === 'add') {
      this.operatorTabelService.addOperator(this.operatorForm.value)
        .subscribe(result => {
          this.sendCallbackResult();
        });
    } else {
      this.operatorTabelService.updateOperator(this.operatorForm.value)
        .subscribe(result => {
          this.sendCallbackResult();
        });
    }
  }

  validateCNP(event) {
    this.validCnp = this.validateCnp(event.target.value);

    !this.validCnp ?
      this.operatorForm.controls.oi_cnp.setErrors({ invalid: true }) :
      this.operatorForm.controls.oi_cnp.setErrors(null);
  }

  get f() { return this.operatorForm.controls; }

  notifyCloseModal() {
    this.notifyClose.emit(false);
  }

  private sendCallbackResult() {
    this.modalReference.close();
    this.callbackResult.emit('');
    this.notifyClose.emit(false);
  }

  private validateCnp(cnp: string) {
    if (cnp.length === 0) {
      return true;
    } else if (cnp.length !== 13) {
      return false;
    }

    for (let i = 0; i < 13; ++i) {
      if (isNaN(parseInt(cnp.charAt(i), 10))) {
        return false;
      }
    }
    return true;
  }

}
