import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-animal-history',
  templateUrl: './animal-history.component.html',
  styleUrls: ['./animal-history.component.scss']
})
export class AnimalHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
