import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { DocumentIstoricService } from 'src/app/modules/acbcr/common/services/document-istoric.service';
import { DocumentHelper } from 'src/app/modules/acbcr/components/common/helper/document-helper';

@Component({
  selector: 'app-document-istoric',
  templateUrl: './document-istoric.component.html',
  styleUrls: ['./document-istoric.component.scss']
})
export class DocumentIstoricComponent implements OnInit {
  @ViewChild('searchModal') searchModal: ElementRef;
  loadingDataSpinner = false;
  isAdmin = false;
  isOperator = false;
  isFermier= false;
  showHint = true;
  showResetList = false;
  listaIstoric;
  searchForm: UntypedFormGroup;
  exploatatii = [];
  listaUseri = [];
  req_params = {
    page_no: 1,
    page_size: 10,
  };

  constructor(
    public modalService: NgbModal,
    private helperDataService: HelperDataService,
    public documentIstoricService: DocumentIstoricService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.searchForm = new UntypedFormGroup({
      start_date: new UntypedFormControl(),
      end_date: new UntypedFormControl(),
      exploatatie_id: new UntypedFormControl(),
      user_id: new UntypedFormControl(),
      tip_utilizator: new UntypedFormControl(),
      tip_document: new UntypedFormControl(),
      tip: new UntypedFormControl(),
      filtreaza_doc_create_fara_modificare: new UntypedFormControl(false),
    });

    this.getTableData();
  }

  getTableData(list_params?) {
    this.loadingDataSpinner = true;
    this.listaIstoric = [];

    if (!list_params) {
      list_params = {
        ...this.req_params,
        ...this.searchForm.value
      };
    }

    this.documentIstoricService.listAPI(list_params).subscribe({
      next: (response) => {
        this.loadingDataSpinner = false;
        this.listaIstoric = response.result.map((item) => {
          item.link_documente = DocumentHelper.link_documente(item);
          return item;
        });
      }
    });
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  onStartDateChanged(event) {
    if (this.searchForm) {
      this.searchForm.controls.start_date.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.searchForm) {
      this.searchForm.controls.end_date.setValue(event);
    }
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.showHint = true;
      this.exploatatii = [];

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        }
      });
  }

  searchUser(event) {
    let params = {
      search_text: event,
    };

    if (event.length < 5) {
      return;
    }

    this.documentIstoricService.listUsersAPI(params).subscribe(
      (response) => {
        this.listaUseri = response.result;
        this.listaUseri = this.listaUseri.map(
          (item) => {
            item.text = [item.first_name, item.last_name, item.username, item.email].filter(Boolean).join(' ');
            return item;
          }
        );
      });
  }

  cauta() {
    this.req_params.page_no = 1;
    this.documentIstoricService.page = 1;
    this.getTableData();
    this.showResetList = false;
  }

  resetFields() {
    if (this.searchForm) {
      this.searchForm.reset();
    }
  }

  resetSearch() {
    this.resetFields();
    this.cauta();
  }

  changePageSize(event) {
    this.req_params.page_no = 1;
    this.req_params.page_size = event.target.value;
    this.documentIstoricService.page = 1;
    this.showResetList = false;
    this.getTableData();
  }

  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  changeCreate(event) {
    this.searchForm.controls.tip.reset();
  }

  clickDocumentNr(item) {
    this.documentIstoricService.page = 1;
    this.showResetList = true;

    let params = {
      document_id: item.document_id,
      tip_document: item.tip_document
    };
    this.getTableData(params);
  }

  export(event) {
    event.target.disabled = true;
    let now = UIHelper.now();
    let fisier = `Activitate_${now}.xls`;
    let export_params = this.searchForm.value;

    this.documentIstoricService.exportAPI(export_params)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }
}
