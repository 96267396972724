<form [formGroup]="moveSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_start">
          Dată început mișcare
        </label>
        <app-date-picker
          (dateChanged)="onStartDateChanged($event)"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_end">
          Dată sfârșit mișcare
        </label>
        <app-date-picker
          (dateChanged)="onEndDateChanged($event)"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="form-group mb-3">
        <label for="animalid">
          Număr matricol animal
        </label>

        <ng-select
          [items]="animale"
          (search)="searchMatricol($event)"
          bindLabel="numarmatricol"
          bindValue="id"
          formControlName="animalid"
          placeholder="Introduceți număr matricol"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="form-group mb-3">
        <label for="motiv">
          Motivul mișcării
        </label>

        <ng-select
          bindLabel="motiv"
          bindValue="motiv"
          formControlName="motiv"
          class="motiv_anexa10"
          dropdownPosition="bottom"
          placeholder="Alege un motiv"
        >
          <ng-option value="1">Abatorizare</ng-option>
          <ng-option value="2">Vânzare</ng-option>
          <ng-option value="3">Cumpărare</ng-option>
          <ng-option value="4">Transfer</ng-option>
          <ng-option value="5">Pierdere</ng-option>
          <ng-option value="6">Moarte</ng-option>
          <ng-option value="7">Vânzare exploatație neafiliată</ng-option>
          <ng-option value="8">Sacrificare consum propriu</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
