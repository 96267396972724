<form [formGroup]="raportFacturiSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-12">
      <div class="form-group mb-3">
        <label for="company_id">
          Exploatație
        </label>
        <ng-select
          [items]="!showHint ? holdingsNames : ''"
          bindLabel="name"
          bindValue="id"
          placeholder="Caută după exploatație"
          formControlName="company_id"
          (search)="searchHoldings($event)"
          (clear)="clearHoldings()"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ item.name }} - {{ item.code }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            {{ item.name }} - {{ item.code }}
          </ng-template>
        </ng-select>
        <small *ngIf="showHint" id="emailHelp" class="form-text text-muted">
          <div class="mb-2">
            <b>Introdu minim 4 caractere pentru a cauta</b>
          </div>
        </small>
      </div>
    </div>

    <div class="col-sm-6 col-md-3">
      <div class="form-group mb-3">
        <label for="luna">
          Luna
        </label>

        <ng-select
          [items]="lunaList"
          bindLabel="luna"
          bindValue="luna"
          formControlName="luna"
          placeholder="Alege luna"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-6 col-md-3">
      <div class="form-group mb-3">
        <label for="an">
          An
        </label>

        <ng-select
          [items]="anList"
          bindLabel="an"
          bindValue="an"
          formControlName="an"
          placeholder="Alege anul"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="tip_factura">
          Tip factura
        </label>

        <ng-select
          bindLabel="tip_factura"
          bindValue="tip_factura"
          formControlName="tip_factura"
          placeholder="Alege tipul facturii"
        >
          <ng-option value="1">RG</ng-option>
          <ng-option value="2">CPP</ng-option>
          <ng-option value="3">ACBCR</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-12">
      <div class="form-group mb-3">
        <label for="subventionata">
          Subventionat
        </label>

        <ng-select
          [items]="subventionatList"
          bindLabel="name"
          bindValue="value"
          formControlName="subventionata"
          placeholder="Alege subventie"
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
