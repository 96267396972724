import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { SolicitariCertificateZootehniceService } from 'src/app/modules/acbcr/common/services/solicitari-certificate-zootehnice.service';

@Component({
  selector: 'app-solicitari-certificate-zootehnice',
  templateUrl: './solicitari-certificate-zootehnice.component.html',
  styleUrls: ['./solicitari-certificate-zootehnice.component.scss']
})
export class SolicitariCertificateZootehniceComponent implements OnInit {
  @ViewChild('searchModal') searchModal: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  loadingDataSpinner = false;
  addEditModalData;
  searchForm: UntypedFormGroup;
  totalCount: number;
  itemsList = [];
  exploatatii = [];
  animale = [];
  openDelete = false;
  showHint = true;
  idDelete;
  hideFields = false;
  exploatatiaCurenta;
  req_params = {
    page_no: 1,
    page_size: 10,
  };

  proceseazaModalData;

  constructor(
    private store: Store<ApplicationState>,
    private errorService: NotificationErrorService,
    private modalService: NgbModal,
    private helperDataService: HelperDataService,
    public solicitariCertificateZootehniceService: SolicitariCertificateZootehniceService,
  ) {}

  ngOnInit() {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.searchForm = new UntypedFormGroup({
      start_date: new UntypedFormControl(),
      end_date: new UntypedFormControl(),
      exploatatie_id: new UntypedFormControl(),
      animal_id: new UntypedFormControl(),
      status: new UntypedFormControl(),
    });

    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        if (response && response.includes('/exploatatie')) {
          this.store.pipe(
            select(getLoadingState),
            filter(isLoading => !isLoading),
            switchMap(() => this.store.pipe(select(getSelectedHolding))),
            distinctUntilChanged((prev, curr) => prev.id === curr.id),
            takeUntil(this.destroy$),
          ).subscribe((response: any) => {
            this.exploatatiaCurenta = response?.id;
            this.hideFields = true;
            this.searchForm.controls.exploatatie_id.setValue(this.exploatatiaCurenta);
            this.changePageSize('10');
          });
        } else {
          this.exploatatiaCurenta = null;
          this.hideFields = false;
          this.searchForm.controls.exploatatie_id.setValue(this.exploatatiaCurenta);
          this.changePageSize('10');
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.itemsList = [];
    let list_params = {
      ...this.req_params,
      ...this.searchForm.value
    };

    this.solicitariCertificateZootehniceService.listAPI(list_params)
      .subscribe({
        next: (response) => {
          this.loadingDataSpinner = false;
          this.itemsList = response.result;
          this.totalCount = response.count;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  changePageSize(page_size) {
    this.req_params.page_no = 1;
    this.req_params.page_size = page_size;
    this.solicitariCertificateZootehniceService.page = 1;
    this.solicitariCertificateZootehniceService.pageSize = page_size;
    this.getTableData();
  }

  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  openAddModal() {
    this.addEditModalData = {id: null};
  }

  openEditModal(item) {
    this.addEditModalData = {id: item?.id};
  }

  closeAddEditModal() {
    this.cauta();
  }

  openProceseazaModal(solicitare_id) {
    this.proceseazaModalData = {id: solicitare_id};
  }

  openDeleteModal(id) {
    this.openDelete = true;
    this.idDelete = id;
  }

  closeDeleteModal(event) {
    this.openDelete = false;

    if (event != true) {
      return;
    }

    let buton = document.getElementsByClassName('sterge-id-' + this.idDelete)[0];
    buton?.setAttribute('disabled', 'true');
    let params = {
      id: this.idDelete,
    }

    this.solicitariCertificateZootehniceService.stergeAPI(params)
      .subscribe({
        next: (response) => {
          this.ngOnInit();
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.showHint = true;
      this.exploatatii = [];

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  searchMatricol(event) {
    if (event.term.length < 4) {
      this.animale = [];
      return;
    }

    let req_params = {
      numarmatricol: event.term,
    };

    this.helperDataService.getAnimals(req_params)
      .subscribe((response: any) => {
        this.animale = response.result;
      });
  }

  cauta() {
    this.req_params.page_no = 1;
    this.solicitariCertificateZootehniceService.page = 1;
    this.getTableData();
  }

  resetFields() {
    if (this.searchForm) {
      this.searchForm.reset();
      this.searchForm.controls.exploatatie_id.setValue(this.exploatatiaCurenta);
    }
  }

  resetSearch() {
    this.resetFields();
    this.cauta();
  }

  download(event, item) {
    event.target.disabled = true;
    let nr = item.numar || '';
    let data = item.data_dmy || '';
    let fisier = `Solicitare_CZ_${nr}_${data}.pdf`;

    this.solicitariCertificateZootehniceService.downloadAPI({id: item.id})
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
          let blobToTextPromise = errors.error.text();
          blobToTextPromise.then((text: string) => {
            let obj;
            try {
              obj = JSON.parse(text);
            } catch (e) {
              return;
            }

            this.errorService.processErrorMsg(obj.error);
          })
        }
      });
  }

  export(event) {
    event.target.disabled = true;
    let export_params = this.searchForm.value;
    let now = UIHelper.now();
    let fisier = `Solicitari_CZ_${now}.xls`;

    this.solicitariCertificateZootehniceService.exportAPI(export_params)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
          let blobToTextPromise = errors.error.text();
          blobToTextPromise.then((text: string) => {
            let obj;
            try {
              obj = JSON.parse(text);
            } catch (e) {
              return;
            }

            this.errorService.processErrorMsg(obj.error);
          })
        }
      });
  }
}
