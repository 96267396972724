import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AdeverintaApiaService } from 'src/app/modules/acbcr/common/services/adeverinta-apia.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';

@Component({
  selector: 'app-add-edit-adeverinta-apia',
  templateUrl: './add-edit-adeverinta-apia.component.html',
  styleUrls: ['./add-edit-adeverinta-apia.component.scss']
})
export class AddEditAdeverintaApiaComponent implements OnChanges {
  @ViewChild('addEditAdeverintaApia') addEditAdeverintaApia: ElementRef;
  @Input() addEditModalType: string;
  @Input() addEditModalData: any;
  @Input() currentHolding: any;

  @Output() notifyClose = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();
  submitted = false;
  isFermier = false;
  selectedDate;
  selectedHoldingIdError = false;
  centruAPIAError = false;
  selectedRaceError = false;
  listaExploatatii = [];
  disableDropDown = true;
  showError = false;
  showHint = true;
  modalReference: NgbModalRef;
  currentRoute;
  animale = {
    vaci: [],
    vaciMetise: [],
    tauri: [],
  };
  vacaDeAdaugat;
  vacaMetisDeAdaugat;
  taurDeAdaugat;

  constructor(
    public modalService: NgbModal,
    private store: Store<ApplicationState>,
    public adeverintaApiaService: AdeverintaApiaService,
  ) { }

  ngOnInit(): void {
    this.isFermier = UIHelper.isFermier();
  }

  ngOnChanges(changes) {
    if (changes.addEditModalData && ! changes.addEditModalData.firstChange) {
      if (this.addEditModalType == 'edit') {
        this.searchHoldings(this.addEditModalData.nume_exploatatie);
        this.showHint = false;

        const req_params = {
          exploatatie_id: this.addEditModalData.exploatatie_id,
          rasa_id: this.addEditModalData.rasa_id,
          data: this.addEditModalData.data,
        };

        this.adeverintaApiaService.animaleAPI(req_params).subscribe(
          (response) => {
            this.animale = response;

            let vaci = this.addEditModalData.animale.vaci;
            this.addEditModalData.animale.vaci = [];

            for (let animal_id of vaci) {
              this.vacaDeAdaugat = animal_id;
              this.addVaca();
            }

            let vaciMetise = this.addEditModalData.animale.vaciMetise;
            this.addEditModalData.animale.vaciMetise = [];

            for (let animal_id of vaciMetise) {
              this.vacaMetisDeAdaugat = animal_id;
              this.addVacaMetise();
            }

            let tauri = this.addEditModalData.animale.tauri;
            this.addEditModalData.animale.tauri = [];

            for (let animal_id of tauri) {
              this.taurDeAdaugat = animal_id;
              this.addTaur();
            }
          });
      }

      if (this.addEditModalType == 'add') {
        if (this.isFermier) {
          this.store.pipe(select(getSelectedHolding))
          .subscribe((response: any) => {
            if (response && response.id) {
              const req_params = {
                comp_id: response.id,
              };
              this.adeverintaApiaService.exploatatiiAPI(req_params).subscribe(
                (response) => {
                  this.listaExploatatii = response.result;
                  let comp = this.listaExploatatii[0];
                  this.addEditModalData.exploatatie_id = comp.id;
                  this.showHint = false;
                  this.findAnimals(comp);
                });
            }
          });
        }
      }

      if (! this.addEditModalData.data) {
        this.addEditModalData.data = UIHelper.getCurrentDate();
      }

      this.modalReference = this.modalService.open(this.addEditAdeverintaApia, {
        windowClass: 'modal-half-full',
        scrollable: true,
      });
    }

    this.store
    .pipe(select(getCurrentRoute))
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: string) => {
      this.currentRoute = response;
      if (this.currentRoute.includes('/exploatatie')) {
        if (this.currentHolding) {
          this.addEditModalData.exploatatie_id = this.currentHolding.id;
          this.listaExploatatii.push(this.currentHolding);
          this.disableDropDown = true;
          this.showHint = false;
        }
      } else {
        this.disableDropDown = false;
      }
    });
  }

  onDateChanged(event) {
    this.addEditModalData.data = event;
  }

  searchHoldings(event) {
    const req_params = {
      comp_name: event,
    };

    if (event.length < 5) {
      return;
    }

    this.adeverintaApiaService.exploatatiiAPI(req_params).subscribe(
      (response) => {
        this.listaExploatatii = response.result;
      });
  }

  findAnimals(event) {
    if (event?.rasa_id) {
      this.addEditModalData.rasa_id = event.rasa_id;
    }

    this.checkErrors();

    if (this.selectedHoldingIdError || this.selectedRaceError) {
      return;
    }

    this.emptyLists();
    this.showError = false;
    this.vacaDeAdaugat = null;
    this.vacaMetisDeAdaugat = null;
    this.taurDeAdaugat = null;

    const req_params = {
      exploatatie_id: this.addEditModalData.exploatatie_id,
      rasa_id: this.addEditModalData.rasa_id,
      data: this.addEditModalData.data,
    };

    this.adeverintaApiaService.animaleAPI(req_params).subscribe(
      (response) => {
        this.animale = response;
      });
  }

  checkErrors() {
    if (! this.addEditModalData.exploatatie_id) {
      this.selectedHoldingIdError = true;
    } else {
      this.showHint = false;
      this.selectedHoldingIdError = false;
    }

    if (! this.addEditModalData.rasa_id) {
      this.selectedRaceError = true;
    } else {
      this.selectedRaceError = false;
    }

    if (! this.addEditModalData.centru_apia) {
      this.centruAPIAError = true;
    } else {
      this.centruAPIAError = false;
    }
  }

  clearHoldings() {
    this.showHint = true;
    this.emptyLists();
  }

  clearRasa() {
    this.emptyLists();
  }

  closeModal() {
    this.showError = false;
    this.selectedHoldingIdError = false;
    this.selectedRaceError = false;
    this.centruAPIAError = false;

    this.vacaDeAdaugat = null;
    this.vacaMetisDeAdaugat = null;
    this.taurDeAdaugat = null;
    this.listaExploatatii = [];

    this.modalReference.close();
    this.notifyClose.emit(false);
    this.emptyLists();
  }

  populateLists() {
    if (! this.addEditModalData.exploatatie_id || ! this.addEditModalData.rasa_id) {
      this.showError = true;
      return;
    }

    this.showError = false;

    this.animale.vaci.filter(
      (animal) => {
        if (animal.eligibil) {
          this.addEditModalData.animale.vaci.push(animal);
        }
      });
    this.animale.vaci = this.animale.vaci.filter(
      (animal) => this.addEditModalData.animale.vaci.indexOf(animal) < 0
    );

    this.animale.vaciMetise.filter(
      (animal) => {
        if (animal.eligibil) {
          this.addEditModalData.animale.vaciMetise.push(animal);
        }
      });
    this.animale.vaciMetise = this.animale.vaciMetise.filter(
      (animal) => this.addEditModalData.animale.vaciMetise.indexOf(animal) < 0
    );

    this.animale.tauri.filter(
      (animal) => {
        if (animal.eligibil) {
          this.addEditModalData.animale.tauri.push(animal);
        }
      });
    this.animale.tauri = this.animale.tauri.filter(
      (animal) => this.addEditModalData.animale.tauri.indexOf(animal) < 0
    );
  }

  deleteVaca(animal) {
    this.animale.vaci = [...this.animale.vaci, animal];
    this.addEditModalData.animale.vaci = this.addEditModalData.animale.vaci.filter(
      (temp_animal) => {
        if (temp_animal.animalid != animal.animalid) {
          return temp_animal;
        }
      });
  }

  deleteVacaMetise(animal) {
    this.animale.vaciMetise = [...this.animale.vaciMetise, animal];
    this.addEditModalData.animale.vaciMetise = this.addEditModalData.animale.vaciMetise.filter(
      (temp_animal) => {
        if (temp_animal.animalid != animal.animalid) {
          return temp_animal;
        }
      });
  }

  deleteTaur(animal) {
    this.animale.tauri = [...this.animale.tauri, animal];
    this.addEditModalData.animale.tauri = this.addEditModalData.animale.tauri.filter(
      (temp_animal) => {
        if (temp_animal.animalid != animal.animalid) {
          return temp_animal;
        }
      });
  }

  addVaca(selectElement?) {
    if (this.vacaDeAdaugat && this.animale.vaci && this.animale.vaci.length > 0) {
      const deAdaugat = this.animale.vaci.filter(
        (animal) =>
          animal.animalid == this.vacaDeAdaugat
      );
      if (deAdaugat.length > 0) {
        this.addEditModalData.animale.vaci = [
          ...this.addEditModalData.animale.vaci,
          deAdaugat[0],
        ];

        this.animale.vaci = this.animale.vaci.filter(
          (animal) => this.addEditModalData.animale.vaci.indexOf(animal) < 0
        );
        this.vacaDeAdaugat = null;
      }

      if (selectElement) {
        selectElement.clearModel();
      }
    }
  }

  addVacaMetise(selectElement?) {
    if (this.vacaMetisDeAdaugat && this.animale.vaciMetise && this.animale.vaciMetise.length > 0) {
      const deAdaugat = this.animale.vaciMetise.filter(
        (animal) =>
          animal.animalid == this.vacaMetisDeAdaugat
      );
      if (deAdaugat.length > 0) {
        this.addEditModalData.animale.vaciMetise = [
          ...this.addEditModalData.animale.vaciMetise,
          deAdaugat[0],
        ];

        this.animale.vaciMetise = this.animale.vaciMetise.filter(
          (animal) => this.addEditModalData.animale.vaciMetise.indexOf(animal) < 0
        );
        this.vacaMetisDeAdaugat = null;
      }

      if (selectElement) {
        selectElement.clearModel();
      }
    }
  }

  addTaur(selectElement?) {
    if (this.taurDeAdaugat && this.animale.tauri && this.animale.tauri.length > 0) {
      const deAdaugat = this.animale.tauri.filter(
        (animal) =>
          animal.animalid == this.taurDeAdaugat
      );
      if (deAdaugat.length > 0) {
        this.addEditModalData.animale.tauri = [
          ...this.addEditModalData.animale.tauri,
          deAdaugat[0],
        ];

        this.animale.tauri = this.animale.tauri.filter(
          (animal) => this.addEditModalData.animale.tauri.indexOf(animal) < 0
        );
        this.taurDeAdaugat = null;
      }

      if (selectElement) {
        selectElement.clearModel();
      }
    }
  }

  emptyLists() {
    this.animale = {
      vaci: [],
      vaciMetise: [],
      tauri: [],
    };
    this.addEditModalData.animale = {
      vaci: [],
      vaciMetise: [],
      tauri: [],
    };
  }

  onSubmitted() {
    this.submitted = true;
    this.checkErrors();

    if (this.selectedHoldingIdError || this.selectedRaceError || this.centruAPIAError) {
      return;
    }

    let req_params = JSON.parse(JSON.stringify(this.addEditModalData));

    for (let key in req_params.animale) {
      req_params.animale[key] = req_params.animale[key].map(
        (animal) => {
          return animal.animalid;
        }
      );
    }

    if (this.addEditModalType == 'edit') {
      this.adeverintaApiaService.salveazaAPI(req_params).subscribe(
        (response) => {
          this.closeModal();
        });
    } else if (this.addEditModalType == 'add') {
      this.adeverintaApiaService.creazaAPI(req_params).subscribe(
        (response) => {
          this.closeModal();
        });
    }
  }
}
