<ng-template ngFor let-anexa [ngForOf]="anexe" let-i="index">
  <tr
    class="cursor-pointer "
    (click)="openEditModal(anexa, i)"
    [ngClass]="{'active': selectedItem === i}"
  >
    <td
      width="70%"
      class="text-primary font-size-16"
    >
      <p class="mb-0" [ngSwitch]="anexa.sex">
          Vițel: {{anexa?.matricolvitel}},
          Sex:
          <span *ngSwitchCase="1">Mascul</span>
          <span *ngSwitchCase="2">Femelă</span>
      </p>
      <p class="mb-0">
          Data nașterii: {{anexa?.data_nasterii}},
          Tip fătare:
          <span [ngSwitch]="anexa?.tipfatare">
          <span *ngSwitchCase="1">Fătare normală</span>
          <span *ngSwitchCase="2">Produs mort</span>
          <span *ngSwitchCase="3">Avort</span>
          <span *ngSwitchCase="4">Pierdere gestație</span>
          <span *ngSwitchCase="5">Gemelară</span>
          </span>
      </p>
      <span
        class="mb-0 font-weight-bold"
        *ngFor="let participant of anexa?.participanti; let first = first"
      >
        <span *ngIf="participant.sex === 1">Taurul</span>
        <span *ngIf="participant.sex === 2">Femela</span>
        : {{participant?.numarmatricol}}
        <span *ngIf="first">și </span>
      </span>
    </td>
    <td 
      width="30%"
      class="align-middle font-weight-bold"
    >
      <span [ngSwitch]="anexa?.status">
          <span *ngSwitchCase="0">În așteptare</span>
          <span *ngSwitchCase="1">Verificare</span>
          <span *ngSwitchCase="2">Operare</span>
          <span *ngSwitchCase="3">Validat</span>
          <span *ngSwitchCase="4">Refuzat</span>
          <span *ngSwitchCase="5">Anulat</span>
      </span>
    </td>
  </tr>
</ng-template>
