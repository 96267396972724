import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortableTable } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})
export class EvaluareGeneticaService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  listAPI(req_params) {
    return this.http.post(
        `${this.baseUrl}/evaluare_genetica/list/`,
        req_params
      ).pipe(
        map((response: any) => {
          this.totalRecords = response.count;
          this.paginate();

          return response;
        })
      );
  }

  importAPI(formData) {
    return this.http.post(
        `${this.baseUrl}/evaluare_genetica/import/`,
        formData
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  inCursAPI() {
    return this.http.get(
        `${this.baseUrl}/evaluare_genetica/import/in_curs/`
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  listaDataEvaluariiAPI() {
    return this.http.get(
        `${this.baseUrl}/evaluare_genetica/lista_data/`
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  listaFisiereAPI() {
    return this.http.get(
        `${this.baseUrl}/evaluare_genetica/lista_fisiere/`
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  genereazaFisierAPI(req_params) {
    return this.http.post(
        `${this.baseUrl}/evaluare_genetica/genereaza_fisier/`,
        req_params
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportAPI(req_params) {
    return this.http.post(
        `${this.baseUrl}/evaluare_genetica/export/`,
        req_params
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  stergeAPI(req_params) {
    return this.http.post(
        `${this.baseUrl}/evaluare_genetica/sterge/`,
        req_params
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadAPI(fisier) {
    return this.http.post(
      `${this.baseUrl}/evaluare_genetica/download/`,
        { fisier: fisier },
        {
          responseType: 'blob'
        }
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  private paginate(): void {
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.totalRecords) {
      this._state.endIndex = this.totalRecords;
    }
  }
}
