import {
  Component, OnInit, ViewChild, ElementRef, Input,
  Output, EventEmitter
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { AvizareTauriService } from 'src/app/modules/acbcr/common/services/avizare-tauri.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-add-edit-avizare-tauri',
  templateUrl: './add-edit-avizare-tauri.component.html',
  styleUrls: ['./add-edit-avizare-tauri.component.scss']
})
export class AddEditAvizareTauriComponent implements OnInit {
  @ViewChild('addEditTemplate') addEditTemplate: ElementRef;
  @Input() addEditModalData: any;
  @Output() notifyClose = new EventEmitter();

  modalReference: NgbModalRef;
  addEditModalType;
  submitted = false;
  disableDropDown = false;
  showErrorExploatatii = false
  tauri = [];
  avizareForm;
  isSuperAdmin = false;
  isAdmin = false;
  selectItemsExploatatii = [];

  constructor(
    public modalService: NgbModal,
    public errorService: NotificationErrorService,
    private helperDataService: HelperDataService,
    public avizareTauriService: AvizareTauriService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
  }

  ngOnChanges(changes) {
    if (changes.addEditModalData && ! changes.addEditModalData.firstChange) {
      this.modalReference = this.modalService.open(this.addEditTemplate, {
        windowClass: 'modal-half-full',
        modalDialogClass: 'h-100',
        scrollable: true,
      });
      this.submitted = false;
    }

    this.tauri = [];
    this.selectItemsExploatatii = [];
    let avizare_id = null;

    if (this.addEditModalData && this.addEditModalData.id) {
      avizare_id = this.addEditModalData.id;
    }

    if (avizare_id) {
      this.addEditModalType = 'edit';
      this.avizareTauriService.getAPI({id: avizare_id})
        .subscribe({
          next: (response) => {
            for (var i = 0; i < response?.exploatatii?.length; i++) {
              let exploatatie = response.exploatatii[i];
              exploatatie.viewLabel = `${exploatatie.name} - ${exploatatie.code}`;
              this.selectItemsExploatatii[i] = [exploatatie];
            }

            this.tauri = response?.tauri;
            this.buildForm(response);
          },
          error: (errors) => {
            this.errorService.processErrorMsg(errors.error.error);
          }
        });
    } else {
      this.addEditModalType = 'add';
      this.buildForm();
      this.adaugaExloatatie();
    }
  }

  buildForm(mData?) {
    this.avizareForm = new UntypedFormGroup({
      id: new UntypedFormControl(mData?.id),
      data: new UntypedFormControl(mData?.data_dmy, Validators.required),
      status: new UntypedFormControl(mData?.status),
      numar: new UntypedFormControl(mData?.numar),
      exploatatii: new UntypedFormArray([]),
      taur_id: new UntypedFormControl(mData?.taur_id, Validators.required),
    });

    for (var i = 0; i < mData?.exploatatii?.length; i++) {
      this.avizareForm.controls.exploatatii.push(
        new UntypedFormGroup({
          id: new UntypedFormControl(mData.exploatatii[i].id),
          data_valabilitate: new UntypedFormControl(mData.exploatatii[i].data_valabilitate),
        })
      );
    }
  }

  adaugaExloatatie() {
    this.avizareForm.controls.exploatatii.push(
      new UntypedFormGroup({
        id: new UntypedFormControl(),
        data_valabilitate: new UntypedFormControl(),
      })
    );
  }

  clickStergeExploatatie(index) {
    this.avizareForm.controls.exploatatii.removeAt(index);
  }

  closeModal() {
    this.modalReference.close();
    this.notifyClose.emit(false);
  }

  onSubmitted() {
    this.submitted = true;

    if (Array.isArray(this.avizareForm.value.exploatatii)) {
      this.showErrorExploatatii = ! this.avizareForm.value.exploatatii.map((item) => {
        return item.id;
      }).some(Boolean);
    }

    if (this.showErrorExploatatii || !this.avizareForm.valid) {
      return;
    }

    if (this.addEditModalType == 'edit') {
      this.avizareTauriService.salveazaAPI(this.avizareForm.value)
        .subscribe({
          next: (response) => {
            this.closeModal();
            this.avizareForm.reset();
            this.submitted = false;
          },
          error: (errors) => {
            this.errorService.processErrorMsg(errors.error.error);
          }
        });
    } else if (this.addEditModalType == 'add') {
      this.avizareTauriService.creazaAPI(this.avizareForm.value)
        .subscribe({
          next: (response) => {
            this.closeModal();
            this.avizareForm.reset();
            this.submitted = false;
          },
          error: (errors) => {
            this.errorService.processErrorMsg(errors.error.error);
          }
        });
    }
  }

  onDateChanged(event) {
    this.avizareForm.controls.data.setValue(event);
    this.findAnimals();
  }

  findAnimals() {
    this.showErrorExploatatii = false;
    let expl = this.avizareForm.value.exploatatii;
    let data = this.avizareForm.value.data;

    if (! data || ! expl) {
      return;
    }

    expl = expl.map((item) => {
      return item.id;
    }).filter(Boolean);

    if (expl.length == 0) {
      return;
    }

    const req_params = {
      exploatatii: expl,
      data: data,
    };

    this.avizareTauriService.listaTauriAPI(req_params)
      .subscribe({
        next: (response) => {
          this.tauri = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  searchExploatatii(event, index) {
    if (event.term.length < 5) {
      this.selectItemsExploatatii[index] = [];

      return;
    }

    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.selectItemsExploatatii[index] = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  clearHoldings() {
  }
}
