<ng-template
  #holdingAdminModal
  let-modal="close"
>
  <div class="modal-header">
    <h3 class="modal-title" *ngIf="modalType === 'add'; else editTitle">
      Administrator nou
    </h3>

    <ng-template #editTitle>
      <h3 class="modal-title">
        Editează administratorul
      </h3>
    </ng-template>

     <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click'); notifyCloseModal()"
    >
      ×
    </button>

  </div>
  <div class="modal-body">

    <form novalidate [formGroup]="adminForm" (ngSubmit)="onSubmitted()">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="administrator">
                Tipul persoanei
              </label>

              <div
                class="radio mb-2 custom-radio-group"
                [ngClass]="{'disabled': modalType === 'edit'}"
              >
                <input
                  type="radio"
                  name="organizationtype"
                  id="organizationtype0"
                  formControlName="organizationtype"
                  value="0"
                  checked
                  (change)="changePersFizica($event)"
                />
                <label for="organizationtype0">
                  Persoană fizică
                </label>

                <input
                  type="radio"
                  name="organizationtype"
                  id="organizationtype1"
                  formControlName="organizationtype"
                  value="1"
                  class="ml-5"
                  (change)="changePersJuridica($event)"
                />
                <label for="organizationtype1">
                  Persoană juridică
                </label>

              </div>

            </div>
          </div>

          <div class="col-sm-6" *ngIf="!juridicForm">
            <div class="form-group mb-3">
              <label for="administrator">
                CNP*
              </label>

              <input
                type="text"
                formControlName="cnp"
                class="form-control"
                [ngClass]="{'is-invalid': submitted && f.cnp.errors || !invalidCnp}"
                (blur)="validateCNP($event)"
                placeholder="Introduceți CNP-ul"
              />

              <div
                *ngIf="!invalidCnp"
                class="invalid-feedback"
              >
                <div>
                  CNP-ul introdus nu este valid.
                </div>

              </div>

              <div
                *ngIf="submitted && f.cnp.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.cnp.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>

              </div>

            </div>
          </div>

          <div class="col-sm-6" *ngIf="juridicForm">
            <div class="form-group mb-3">
              <label for="cif">
                CUI/CIF*
              </label>

              <input
                type="text"
                formControlName="cif"
                class="form-control"
                [ngClass]="{'is-invalid': submitted && f.cif.errors || !invalidCUI}"
                (blur)="validateCUI($event)"
                placeholder="Introduceți CUI/CIF"
              />


              <div
                *ngIf="!invalidCUI"
                class="invalid-feedback"
              >
                <div>
                  CUI-ul/CIF-ul introdus nu este valid.
                </div>

              </div>

              <div
                *ngIf="submitted && f.cif.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.cif.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>

              </div>

            </div>
          </div>


          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="administrator" *ngIf="!juridicForm; else isJuridic">
                Nume Prenume*
              </label>

              <ng-template #isJuridic>
                <label for="administrator">
                  Denumire*
                </label>
              </ng-template>

              <input
                type="text"
                formControlName="name"
                class="form-control"
                [ngClass]="{'is-invalid': submitted && f.name.errors}"
                placeholder="Introduceți numele administratorului"
              />

              <div
                *ngIf="submitted && f.name.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.name.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>

              </div>

            </div>
          </div>

          <div class="col-sm-6" *ngIf="!juridicForm">
            <div class="form-group mb-3">
              <label for="nationality">
                Naționalitate
              </label>

              <ng-select
                [items]="nationalities"
                name="nationality"
                formControlName="nationality"
                bindLabel="name"
                placeholder="Selectează naționalitatea"
              >
              </ng-select>

            </div>
          </div>

          <div class="separator mb-3"></div>

          <div class="col-sm-4">
            <div class="form-group mb-3">
              <label for="countries">
                Țara*
              </label>

              <ng-select
                [items]="countries"
                name="country"
                formControlName="country"
                bindLabel="name"
                placeholder="Selectează țara"
                [ngClass]="{
                  'is-invalid': submitted && f.country.errors
                }"
              >
              </ng-select>

              <div
                *ngIf="submitted && f.country.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.country.errors }"
              >
                <div *ngIf="f.country.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>

              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="form-group mb-3">
              <label for="judet">
                Județ*
              </label>

              <ng-select
                [items]="cities"
                name="judet"
                formControlName="judet"
                bindLabel="name"
                placeholder="Selectează județul"
                [ngClass]="{
                  'is-invalid': submitted && f.judet.errors
                }"
                (change)="getSelectedCity($event)"
              >
              </ng-select>

              <div
                *ngIf="submitted && f.judet.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.judet.errors }"
              >
                <div *ngIf="f.judet.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>

              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="form-group mb-3">
              <label for="localitate">
                Localitatea*
              </label>

              <ng-select
                [items]="smallCities"
                name="localitate"
                formControlName="localitate"
                bindLabel="name"
                [ngClass]="{
                  'is-invalid': submitted && f.localitate.errors
                }"
                placeholder="Selectează localitatea"
                #smallCitiesDropDown
              >
              </ng-select>

               <div
                *ngIf="submitted && f.localitate.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.localitate.errors }"
              >
                <div *ngIf="f.localitate.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>

              </div>

            </div>
          </div>

          <div class="separator mb-3"></div>

          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="phone">
                Telefon
              </label>

              <input
                type="text"
                formControlName="phone"
                class="form-control"
                placeholder="Introduceți numărul de telefon"
                (input)="formatPhoneNumber($event)"
              />

            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="email">
                E-mail
              </label>

              <input
                type="text"
                formControlName="email"
                class="form-control"
                placeholder="Introduceți adresa de email"
                [ngClass]="{
                  'is-invalid': submitted && f.email.errors
                }"
              />
              <div
                *ngIf="submitted && f.email.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.email.errors }"
              >
                <div *ngIf="f.email.errors.email">
                  Adresă email invalidă.
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-12 text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-dark"
                (click)="modal('Cross click'); notifyCloseModal()"
              >
                Anulează
              </button>
              <button type="submit" class="btn btn-success">

                <span *ngIf="modalType === 'add'; else editTxt">
                  Adaugă administrator
                </span>
                <ng-template #editTxt>
                  <span>
                    Actualizează administrator
                  </span>
                </ng-template>

              </button>
           </div>
          </div>

        </div>
      </div>
    </form>

  </div>
</ng-template>
