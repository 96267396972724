import { HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../models/common.models';

export class OptionsProvider {
  public static readonly options = new HttpHeaderResponse({ headers: OptionsProvider.getCustomHttpHeader() });
  public static readonly user =
    (localStorage['access-token'] ? new JwtHelperService().decodeToken(localStorage['access-token']) : {});

  public static changeOptions() {
    Object.assign(OptionsProvider.options, new HttpHeaderResponse({ headers: OptionsProvider.getCustomHttpHeader() }));
    Object.assign(OptionsProvider.user, new JwtHelperService().decodeToken(localStorage['access-token']));
  }

  private static getCustomHttpHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage['access-token']}`,
    });
  }
}
