import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RaportIncasare } from 'src/app/modules/acbcr/common/models/rapoarte-incasari.model';
import { RapoarteIncasariTableService } from 'src/app/modules/acbcr/common/services/rapoarte-incasari.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-rapoarte-incasari',
  templateUrl: './rapoarte-incasari.component.html',
  styleUrls: ['./rapoarte-incasari.component.scss']
})
export class RapoarteIncasariComponent implements OnInit {

  tables$: Observable<RaportIncasare[]>;
  total$: Observable<number>;
  totalRestant;
  loadingDataSpinner = false;

  objToAPI = {
    company_id: null,
    luna: null,
    an: null,
    tip: null,
    subventie: null,
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public rapoarteIncasariTableService: RapoarteIncasariTableService,
    public helperDataService: HelperDataService
  ) {
    this.tables$ = rapoarteIncasariTableService.tables$;
    this.total$ = rapoarteIncasariTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.rapoarteIncasariTableService
      .getRapoarteIncasariAPI(this.objToAPI).subscribe((response) => {
        this.totalRestant = response.restant;
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  exportData(event) {
    this.rapoarteIncasariTableService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, `Rapoarte Incasari.xls`);
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

}
