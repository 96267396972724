import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { HelperDataService } from '../../../../common/services/helper-data.service';
import { IncasariTableService } from 'src/app/modules/acbcr/common/services/incasari-table.service';

@Component({
  selector: 'app-incasari',
  templateUrl: './incasari.component.html',
  styleUrls: ['./incasari.component.scss'],
  providers: [IncasariTableService]
})
export class IncasariComponent implements OnInit {
  tables$: Observable<any[]>;
  total$: Observable<number>;

  loadingDataSpinner = false;

  objToAPI = {
    page_nr: '1',
    page_size: '10'
  };

  constructor(
    public incasariTableService: IncasariTableService,
    public helperDataService: HelperDataService
  ) {
    this.tables$ = incasariTableService.tables$;
    this.total$ = incasariTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    setTimeout(() => {
      this.incasariTableService
      .getIncasariAPI(this.objToAPI).subscribe((response) => {
        // Hide spinner, after 1 second
        this.loadingDataSpinner = false;
        }, errors => {
          this.loadingDataSpinner = false;
        });
    }, 500);
  }

  filterSearchData(event) {
    // Compose the object what will be sended to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  exportData(event) {
    this.incasariTableService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, `Incasari.xls`);
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_nr = event;
    this.getTableData();
  }

  getTableCallback(event) {
    this.getTableData();
  }

}

