import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import {
  SortableTable,
  convertDateToDisplayFormat,
  convertDateToBackendFormat
} from './services-table-helper';
import { Cantarire } from '../models/cantarire.model';

@Injectable({
  providedIn: 'root'
})
export class BuletineTableService extends SortableTable<Cantarire> {
  private baseUrl = environment.API_URL;

  cantariri: [];
  cantaririLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.cantariri, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getBuletineAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/cantariri/list/`,
      obj
    ).pipe(
      map((response: any) => {
        this.cantariri = response.result;
        this.cantaririLength = response.count;

        this.cantariri.map((cantarire: Cantarire) => {
          cantarire.date = convertDateToDisplayFormat(cantarire.date);
        });

        this.paginate();
        this.sortEvent();
        this._total$.next(this.cantaririLength);

        return response;
      })
    );
  }

  addBuletin(buletin: Cantarire) {
    return this.http.post(
      `${this.baseUrl}/cantariri/save-buletin/`,
      { ...buletin }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadAtentionari(params) {
    return this.http
    .post(
      `${this.baseUrl}/cantariri/atentionari-fermier/`,
        { ...params },
        {
          responseType: 'blob'
        }
      ).pipe((response: any) => {
      return response;
    });
  }

  downloadNotaControlCPPC(params) {
    return this.http
    .post(
      `${this.baseUrl}/cantariri/nota-control-cppc/`,
        { ...params },
        {
          responseType: 'blob'
        }
      ).pipe((response: any) => {
      return response;
    });
  }

  deleteBuletin(weightingid) {
    return this.http
      .post(
        `${this.baseUrl}/cantariri/delete/`,
        { weightingid }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadBuletinControl(weightingid) {
    return this.http
    .post(
      `${this.baseUrl}/cantariri/buletin-control/`,
        { weightingid },
        {
          responseType: 'blob'
        }
      ).pipe((response: any) => {
      return response;
    });
  }

  exportXls(weightingid) {
    return this.http
    .post(
      `${this.baseUrl}/cantariri/export-xls/`,
        { id: weightingid },
        {
          responseType: 'blob'
        }
      ).pipe((response: any) => {
      return response;
    });
  }

  salveazaCantariri(params, buletin: Cantarire) {
    return this.http.post(
      `${this.baseUrl}/cantariri/save-cantariri/`,
      {
        ...params,
        ...buletin,
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  getCantarireFromBuletin(companyid, cantarireId) {
    return this.http.post(
      `${this.baseUrl}/cantariri/genereaza-buletin/`,
      { companyid, cantarire_id: cantarireId }
    ).pipe(map((response: any) => {
      response.weighting.date = convertDateToDisplayFormat(response.weighting.date);
      return response;
    }));
  }

  operatoriAPI() {
    return this.http
      .get(
        `${this.baseUrl}/users/operatori/`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.cantaririLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.cantaririLength) {
      this._state.endIndex = this.cantaririLength;
    }
  }
}
