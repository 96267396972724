import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from './../../common/services/auth.service';

import { ApplicationState } from '../../common/state/app.state';
import { SetAppCurrentRoute, GetAppPreviousRoute } from '../../common/state/actions/route.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  jwtHelper: JwtHelperService = new JwtHelperService();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private store: Store<ApplicationState>,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {

    if (this.jwtHelper.isTokenExpired(localStorage['access-token'])) {
      this.authService.logout();
    }

    if (!UIHelper.hasPermission(this.router.url)) {
      this.router.navigateByUrl('/dashboard/exploatatii');
    }

    this.store
      .dispatch(new SetAppCurrentRoute(window.location.hash.replace('#', '')));
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        let route = events[0].urlAfterRedirects;
        if (route.includes('?filter')) {
          route = route.slice(0, route.lastIndexOf('?'));
        }
        localStorage.setItem('previous-route', route);

        this.store.dispatch(new SetAppCurrentRoute(events[1].urlAfterRedirects));
        this.store.dispatch(new GetAppPreviousRoute(events[0].urlAfterRedirects));

        // Close modal if route is change and a modal is open
        this.modalService.dismissAll('Cross click');

        if (!UIHelper.hasPermission(events[1].urlAfterRedirects)) {
          this.router.navigateByUrl('/dashboard/exploatatii');
        }

      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

}
