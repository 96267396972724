import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-poze-animal-form-block',
  templateUrl: './poze-animal-form-block.component.html',
  styleUrls: ['./poze-animal-form-block.component.scss']
})
export class PozeAnimalFormBlockComponent implements OnInit, OnChanges {
  @Input() animalForm: UntypedFormGroup;
  @Input() modalType: string;
  @Input() submitted: boolean;
  @Input() disableAllFields: boolean;
  @Output() deletedImage = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();

  isFermier = false;
  imageUrls = [];
  arataButoanePoza = true;
  fileId = null;
  fileName = null;
  downloadInProgress: boolean;

  constructor(
    private helperDataService: HelperDataService,
    private animalsTableService: AnimalsTableService
  ) {}

  ngOnInit(): void {
    this.isFermier = UIHelper.isFermier();
    if (this.isFermier && this.formData.controls.length > 0) {
      this.arataButoanePoza = false;
    }
  }

  ngOnChanges(changes) {
    if (changes.animalForm && this.formData) {
      this.getImageUrls();
    }
  }

  getImageUrls() {
    this.imageUrls = [];

    if (this.formData && this.formData.value[0]?.fileid) {
      this.formData.value.forEach((pic, index) => {
        this.animalsTableService.getPozaUrl(pic.fileid).subscribe(response => {
          this.imageUrls[index] = response.url;
        });
      });
    }
  }

  uploadServerResponse(event) {
    const files = event.body.files;
    if (!files) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      this.formData.push(
        new UntypedFormGroup({
          fileid: new UntypedFormControl(files[i]['file_id']),
          uploaded_file_name: new UntypedFormControl(files[i]['file_uploaded_name']),
          filetype: new UntypedFormControl(),
        })
      );

      this.animalsTableService.getPozaUrl(files[i]['file_id']).subscribe(response => {
        this.imageUrls.push(response.url);
      });
    }

    let foundDefault = false;

    for (let i = 0; i < this.formData.controls.length; i++) {
      let arrayFormGroup = this.formData.controls[i] as UntypedFormGroup;

      if (arrayFormGroup.value.filetype == 'default') {
        foundDefault = true;
      }
    }

    if (!foundDefault) {
      this.setDefaultPicture(0);
    }
  }

  get formData() {
    return (this.animalForm.get('poze') as UntypedFormArray);
  }

  downloadImage(index) {
    const fileId = this.formData.controls[index].value.fileid;
    if (!fileId) {
      return;
    }

    this.downloadInProgress = true;
    const fileName = this.formData.controls[index].value.uploaded_file_name;

    this.helperDataService.downloadFile(fileId)
      .subscribe((response: any) => {
        this.downloadInProgress = false;
        const blob = new Blob([response]);
        this.helperDataService.simulateDownload(blob, fileName);
    });
  }

  setDefaultPicture(index) {
    for (let i = 0; i < this.formData.controls.length; i++) {
      let arrayFormGroup = this.formData.controls[i] as UntypedFormGroup;
      arrayFormGroup.controls.filetype.setValue(null);
    }

    let indexFormGroup = this.formData.controls[index] as UntypedFormGroup;
    indexFormGroup.controls.filetype.setValue('default');
  }

  deleteImage(index) {
    const fileId = this.formData.controls[index].value.fileid;

    if (!fileId) {
      return;
    }

    this.animalsTableService.deletePozaAnimal(fileId)
      .subscribe(response => {
        this.formData.removeAt(index);
        this.imageUrls.splice(index, 1);
        this.deletedImage.emit(true);
      });
  }
}
