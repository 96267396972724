import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reproductii-list, [app-reproductii-list]',
  templateUrl: './reproductii-list.component.html',
  styleUrls: ['./reproductii-list.component.scss']
})
export class ReproductiiListComponent implements OnInit {
  @Input() anexe = [];
  @Input() selectedItem: number;
  @Output() openEditReproductie = new EventEmitter();
  @Output() selectedItemChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  openEditModal(anexa, index) {
    this.openEditReproductie.emit(anexa);
    this.selectedItemChanged.emit(index);
  }
}
