import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Item } from 'src/app/modules/acbcr/common/models/tipuri-fatari-model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';

@Component({
  selector: 'app-birth-search',
  templateUrl: './birth-search.component.html',
  styleUrls: ['./birth-search.component.scss']
})
export class BirthSearchComponent implements OnInit {
  @Output() searchResult = new EventEmitter();

  birthSearchForm;
  selectedHoldingId;
  mamaItems = [];
  tataItems = [];

  readonly usurintaFatari: Item[] = [
    { name: '0 - Nu se știe', value: 0 },
    { name: '1 - Ușoară fără asistență', value: 1 },
    { name: '2 - Ușoară cu un anumit grad de asistență', value: 2 },
    { name: '3 - Fătare dificilă', value: 3 },
    { name: '4 - Operație cezariană', value: 4 },
    { name: '5 - Embriotomie', value: 5},
  ];

  constructor(
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>,
  ) { }

  ngOnInit(): void {
    this.birthSearchForm = new UntypedFormGroup({
      data_start: new UntypedFormControl(null),
      data_end: new UntypedFormControl(null),
      matricolvitel: new UntypedFormControl(null),
      sex: new UntypedFormControl(null),
      mama: new UntypedFormControl(null),
      tata: new UntypedFormControl(null),
      rang_fatare: new UntypedFormControl(null),
      usurinta_fatare: new UntypedFormControl(null)
    });

    this.store.pipe(select(getCurrentRoute))
      .subscribe((route: string) => {
        if (route && route.includes('/exploatatie')) {
          this.store.pipe(select(getSelectedHolding))
          .subscribe((response: any) => {
            if (response) {
              this.selectedHoldingId = response.id;
            }
          });
        }
      });
  }

  onSubmitted() {
    this.searchResult.emit(this.birthSearchForm.value);
  }

  resetSearch() {
    this.birthSearchForm.reset();
    this.onSubmitted();
  }

  searchMatricolMama(event) {
    if (event.term.length < 4) {
      this.mamaItems = [];
      return;
    }

    let req_params = {
      numarmatricol: event.term,
      sex: 2,
      companyid: this.selectedHoldingId,
    };

    this.helperDataService.getAnimals(req_params)
      .subscribe((response: any) => {
        this.mamaItems = response.result;
      });
  }

  searchMatricolTata(event) {
    if (event.term.length < 4) {
      this.tataItems = [];
      return;
    }

    let req_params = {
      numarmatricol: event.term,
      sex: 1,
      companyid: this.selectedHoldingId,
    };

    this.helperDataService.getAnimals(req_params)
      .subscribe((response: any) => {
        this.tataItems = response.result;
      });
  }

  onStartDateChanged(event) {
    if (this.birthSearchForm) {
      this.birthSearchForm.controls.data_start.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.birthSearchForm) {
      this.birthSearchForm.controls.data_end.setValue(event);
    }
  }

}
