<ng-template #bonitareModal let-modal="close">
  <div class="modal-header" [ngSwitch]="bonitareType">
    <h3 class="modal-title" *ngSwitchCase="'dm'">
      Dezvoltare musculară
    </h3>
    <h3 class="modal-title" *ngSwitchCase="'ds'">
      Dezvoltare scheletică
    </h3>
    <h3 class="modal-title" *ngSwitchCase="'af'">
      Aptitudini funcționale
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click'); notifyCloseModal()"
    >
      ×
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid" [ngSwitch]="bonitareType">
      <form
        novalidate
        *ngSwitchCase="'dm'"
        [formGroup]="dmForm"
        (ngSubmit)="onDMSubmitted()"
      >
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="lsu">
                Largimea spinarii la umeri (LSU)
              </label>

              <input
                type="number"
                formControlName="lsu"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="LSU"
                (change)="calculateDMValue('lsu')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="lsc">
                Largimea spinarii la coaste (LSC)
              </label>

              <input
                type="number"
                formControlName="lsc"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="LSC"
                (change)="calculateDMValue('lsc')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="dc">
                Dezvoltarea chiulotei (DC)
              </label>

              <input
                type="number"
                formControlName="dc"
                class="form-control"
                type="number"
                min="1"
                max="10"
                placeholder="DC"
                (change)="calculateDMValue('dc')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="lac">
                Largimea chiulotei (LaC)
              </label>

              <input
                type="number"
                formControlName="lac"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="LaC"
                (change)="calculateDMValue('lac')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="gs">
                Grosimea salelor (GS)
              </label>

              <input
                type="number"
                formControlName="gs"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="GS"
                (change)="calculateDMValue('gs')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="total">
                Total
              </label>

              <input
                type="number"
                class="form-control read-only-form"
                name="totalDm"
                formControlName="totalDm"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-12 text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-dark"
                (click)="modal('Cross click'); notifyCloseModal()"
              >
                Anulează
              </button>
              <button class="btn btn-success" type="submit">
                <span>
                  Adaugă bonitare
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <form
        novalidate
        *ngSwitchCase="'ds'"
        [formGroup]="dsForm"
        (ngSubmit)="onDSSubmitted()"
      >
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="pf">
                Perimetrul fluierului (PF)
              </label>

              <input
                type="number"
                formControlName="pf"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="PF"
                (change)="calculateDSValue('pf')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="ls">
                Lungimea spinarii (LS)
              </label>

              <input
                type="number"
                formControlName="ls"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="LS"
                (change)="calculateDSValue('ls')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="lc">
                Lungimea crupei (LC)
              </label>

              <input
                type="number"
                formControlName="lc"
                class="form-control"
                type="number"
                min="1"
                max="10"
                placeholder="LC"
                (change)="calculateDSValue('lc')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="lcs">
                Largimea crupei la solduri (LCS)
              </label>

              <input
                type="number"
                formControlName="lcs"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="LCS"
                (change)="calculateDSValue('lcs')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="hg">
                Inaltimea la greban
              </label>

              <input
                type="number"
                formControlName="hg"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="HG"
                (change)="calculateDSValue('hg')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="totalDs">
                Total
              </label>

              <input
                type="number"
                class="form-control read-only-form"
                name="totalDs"
                formControlName="totalDs"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-12 text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-dark"
                (click)="modal('Cross click'); notifyCloseModal()"
              >
                Anulează
              </button>
              <button class="btn btn-success" type="submit">
                <span>
                  Adaugă bonitare
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <form
        novalidate
        *ngSwitchCase="'af'"
        [formGroup]="afForm"
        (ngSubmit)="onAFSubmitted()"
      >
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="ec">
                Largimea botului (EC)
              </label>

              <input
                type="number"
                formControlName="ec"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="EC"
                (change)="calculateAFValue('ec')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="ama">
                Aplombul membrelor anterioare (AMA)
              </label>

              <input
                type="number"
                formControlName="ama"
                type="number"
                min="1"
                max="10"
                class="form-control"
                placeholder="AMA"
                (change)="calculateAFValue('ama')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="amp">
                Aplombul membrelor posterioare (AMP)
              </label>

              <input
                type="number"
                formControlName="amp"
                class="form-control"
                type="number"
                min="1"
                max="10"
                placeholder="AMP"
                (change)="calculateAFValue('amp')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="lps">
                Linia de profil a spinarii (LPS)
              </label>

              <input
                type="number"
                formControlName="lps"
                class="form-control"
                type="number"
                min="1"
                max="10"
                placeholder="LPS"
                (change)="calculateAFValue('lps')"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="totalAf">
                Total
              </label>

              <input
                type="number"
                class="form-control read-only-form"
                name="totalAf"
                formControlName="totalAf"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-12 text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-dark"
                (click)="modal('Cross click'); notifyCloseModal()"
              >
                Anulează
              </button>
              <button class="btn btn-success" type="submit">
                <span>
                  Adaugă bonitare
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="observatii">
        <span class="observatii-info">
          Observații: Pentru fiecare caracter se acordă un punctaj de la 1 la 10
          astfel:
        </span>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>NS</th>
                <th>S</th>
                <th>M</th>
                <th>B</th>
                <th>FB</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>1-2</td>
                <td>3-4</td>
                <td>5-6</td>
                <td>7-8</td>
                <td>9-10</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
