import { Component, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CnpCuiValidatorService } from '../../../common/services/cnp-cui-validator.service';
import { HelperDataService } from '../../../common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {
  settingsForm;
  settingsData;
  submitted = false;
  invalidCif = true;
  showError = false;
  showErrorMin = false;
  showErrorIA = false;
  showErrorIAMin = false;
  editSuccess = false;
  isSuperAdmin = false;
  isAdmin = false;
  templateData;
  templateArray = [
    {
      'key': 'template_email_creare_user',
      'label': 'Template email creare user',
    },
    {
      'key': 'template_email_schimbare_parola',
      'label': 'Template email schimbare parola',
    },
    {
      'key': 'template_acte_aditionale_cppc',
      'label': 'Template acte adiționale CPPC',
    },
    {
      'key': 'template_acte_aditionale_rg_charolaise',
      'label': 'Template acte adiționale RG Charolaise',
    },
    {
      'key': 'template_acte_aditionale_rg_limousine',
      'label': 'Template acte adiționale RG Limousine',
    },
    {
      'key': 'template_email_acte_aditionale',
      'label': 'Template email acte adiționale',
    },
    {
      'key': 'template_email_atentionari_documente',
      'label': 'Template email atenționări documente',
    },
    {
      'key': 'template_email_atentionari_fermier',
      'label': 'Template email atenționări pentru fermier',
    },
  ]

  constructor(
    private CNPCUIValidatorService: CnpCuiValidatorService,
    private helperDataService: HelperDataService,
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();

    if (this.isAdmin) {
      this.buildForm();
      this.getSetttings();
    }
  }

  getSetttings() {
    this.helperDataService.getSettings().subscribe(settings => {
      if (settings) {
        this.buildForm(settings);
      } else {
        this.buildForm();
      }
    });
    this.submitted = false;
  }

  buildForm(formData?) {
    this.settingsForm = new UntypedFormGroup({
      asociatie_nume: new UntypedFormControl(formData ? formData.asociatie_nume : null, Validators.required),
      asociatie_adresa: new UntypedFormControl(formData ? formData.asociatie_adresa : null, Validators.required),
      asociatie_localitate: new UntypedFormControl(formData ? formData.asociatie_localitate : null, Validators.required),
      asociatie_nr_reg: new UntypedFormControl(formData ? formData.asociatie_nr_reg : null, Validators.required),
      asociatie_cif: new UntypedFormControl(formData ? formData.asociatie_cif : null, Validators.required),
      asociatie_email: new UntypedFormControl(formData ? formData.asociatie_email : null, Validators.required),
      asociatie_tel: new UntypedFormControl(formData ? formData.asociatie_tel : null, Validators.required),
      contributia_clientului: new UntypedFormControl(formData ? formData.contributia_clientului : null, Validators.required),
      durata_intre_fatari: new UntypedFormControl(formData ? formData.durata_intre_fatari : null, Validators.required),
      durata_maxima_fatare: new UntypedFormControl(formData ? formData.durata_maxima_fatare : null, Validators.required),
      durata_minima_gestatie: new UntypedFormControl(formData ? formData.durata_minima_gestatie : null, Validators.required),
      durata_maxima_gestatie: new UntypedFormControl(formData ? formData.durata_maxima_gestatie : null, Validators.required),
      durata_minima_ia: new UntypedFormControl(formData ? formData.durata_minima_ia : null, Validators.required),
      durata_maxima_ia: new UntypedFormControl(formData ? formData.durata_maxima_ia : null, Validators.required),
      diferenta_varsta_vitel_mama: new UntypedFormControl(formData ? formData.diferenta_varsta_vitel_mama : null, Validators.required),
      diferenta_varsta_vitel_tata: new UntypedFormControl(formData ? formData.diferenta_varsta_vitel_tata : null, Validators.required),
      varsta_minima_monta: new UntypedFormControl(formData ? formData.varsta_minima_monta : null, Validators.required),
      varsta_maxima_junica: new UntypedFormControl(formData ? formData.varsta_maxima_junica : null, Validators.required),
      varsta_maxima_tineret: new UntypedFormControl(formData ? formData.varsta_maxima_tineret : null, Validators.required),
      varsta_minima_cantarire_200: new UntypedFormControl(formData ? formData.varsta_minima_cantarire_200 : null, Validators.required),
      varsta_maxima_cantarire_200: new UntypedFormControl(formData ? formData.varsta_maxima_cantarire_200 : null, Validators.required),
      varsta_minima_cantarire_365: new UntypedFormControl(formData ? formData.varsta_minima_cantarire_365 : null, Validators.required),
      varsta_maxima_cantarire_365: new UntypedFormControl(formData ? formData.varsta_maxima_cantarire_365 : null, Validators.required),
      varsta_minima_eliberare_cz: new UntypedFormControl(formData ? formData.varsta_minima_eliberare_cz : null, Validators.required),
      termen_depunere_cereri: new UntypedFormControl(formData ? formData.termen_depunere_cereri : null, Validators.required),
      data_nasterii_min_vaci_48: new UntypedFormControl(formData ? formData.data_nasterii_min_vaci_48 : null, Validators.required),
      varsta_minima_femela_luni_adev_apia: new UntypedFormControl(formData ? formData.varsta_minima_femela_luni_adev_apia : null, Validators.required),
      varsta_maxima_femela_luni_adev_apia: new UntypedFormControl(formData ? formData.varsta_maxima_femela_luni_adev_apia : null, Validators.required),
      varsta_minima_mascul_luni_adev_apia: new UntypedFormControl(formData ? formData.varsta_minima_mascul_luni_adev_apia : null, Validators.required),
      varsta_maxima_mascul_luni_adev_apia: new UntypedFormControl(formData ? formData.varsta_maxima_mascul_luni_adev_apia : null, Validators.required),
      operator_cpp: new UntypedFormControl(formData ? formData.operator_cpp : null),
      telefon_operator_cpp: new UntypedFormControl(formData ? formData.telefon_operator_cpp : null),
      responsabil_cpp: new UntypedFormControl(formData ? formData.responsabil_cpp : null),
      trimite_email_atentionari: new UntypedFormControl(formData ? formData.trimite_email_atentionari : 0, Validators.required),
      durata_incepere_atentionari: new UntypedFormControl(formData ? formData.durata_incepere_atentionari : null, Validators.required),
      durata_intre_atentionari: new UntypedFormControl(formData ? formData.durata_intre_atentionari : null, Validators.required),
      durata_pastrare_atentionari: new UntypedFormControl(formData ? formData.durata_pastrare_atentionari : null, Validators.required),
      titlu_email_creare_user: new UntypedFormControl(formData ? formData.titlu_email_creare_user : null, Validators.required),
      titlu_email_schimbare_parola: new UntypedFormControl(formData ? formData.titlu_email_schimbare_parola : null, Validators.required),
      titlu_email_acte_aditionale: new UntypedFormControl(formData ? formData.titlu_email_acte_aditionale : null, Validators.required),
      titlu_email_atentionari_documente: new UntypedFormControl(formData ? formData.titlu_email_atentionari_documente : null, Validators.required),
      titlu_email_atentionari_fermier: new UntypedFormControl(formData ? formData.titlu_email_atentionari_fermier : null, Validators.required),
    });
  }

  get f() { return this.settingsForm.controls; }

  onSubmitted() {
    this.submitted = true;
    if (this.settingsForm.valid) {
      this.saveSettingsDetails();
    }
  }

  validateCIF(event) {
    this.invalidCif = this.CNPCUIValidatorService.validateCUI(event.target.value);
    !this.invalidCif ?
      this.settingsForm.controls.asociatie_cif.setErrors({ invalid: true }) :
      this.settingsForm.controls.asociatie_cif.setErrors(null);
    this.settingsForm.value.asociatie_cif = event.target.value;
  }

  changeDurataMaximaGestatie(event) {
    const durataMinimaGestatie = this.f.durata_minima_gestatie.value;
    if (event.target.value < durataMinimaGestatie || !durataMinimaGestatie) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }

  changeDurataMinimaGestatie(event) {
    const durataMaximaGestatie = this.f.durata_maxima_gestatie.value;
    if (event.target.value > durataMaximaGestatie || !durataMaximaGestatie) {
      this.showErrorMin = true;
    } else {
      this.showErrorMin = false;
    }
  }

  changeDurataMaximaIA(event) {
    const durataMinimaIA = this.f.durata_minima_ia.value;
    if (event.target.value < durataMinimaIA || !durataMinimaIA) {
      this.showErrorIA = true;
    } else {
      this.showErrorIA = false;
    }
  }

  changeDurataMinimaIA(event) {
    const durataMaximaIA = this.f.durata_maxima_ia.value;
    if (event.target.value > durataMaximaIA || !durataMaximaIA) {
      this.showErrorIAMin = true;
    } else {
      this.showErrorIAMin = false;
    }
  }

  saveSettingsDetails() {
    this.helperDataService.setSettings(this.settingsForm.value).subscribe(response => {
      if (response) {
        this.buildForm(response.result);
      }
    });

    this.editSuccess = true;
    setTimeout(() => {
      this.editSuccess = false;
    }, 3000);
  }

  dataChangedTermenDepunere(event) {
    this.settingsForm.controls.termen_depunere_cereri.setValue(event);
  }

  dataChangedDNVaci48(event) {
    this.settingsForm.controls.data_nasterii_min_vaci_48.setValue(event);
  }

  openModalTemplate(index: number) {
    this.templateData = {
      ...this.templateArray[index],
      'open': true,
    };
  }
}
