import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToDisplayFormat, convertDateToBackendFormat } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})

export class RapoarteJurnalTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  rapoarteJurnal: any[];
  numarRapoarteJurnal: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.rapoarteJurnal, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getRapoarteJurnalAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/contabilitate/rapoarte/jurnal/`,
      {
        ...obj,
        data_start: convertDateToBackendFormat(obj.data_start),
        data_end: convertDateToBackendFormat(obj.data_end)
      }
    ).pipe(map((response: any) => {
      this.rapoarteJurnal = response.result;
      this.numarRapoarteJurnal = response.count;

      this.rapoarteJurnal.map((raport: any) => {
        raport.data = convertDateToDisplayFormat(raport.data);
      });

      this.paginate();
      this.sortEvent();
      this._total$.next(this.numarRapoarteJurnal);

      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/rapoarte/jurnalxlsx/`,
      {
        ...obj,
        data_start: convertDateToBackendFormat(obj.data_start),
        data_end: convertDateToBackendFormat(obj.data_end)
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  clearRapoarteTable() {
    this.rapoarteJurnal = [];
    this.numarRapoarteJurnal = 0;
    this.paginate();
    this._total$.next(0);
    this._tables$.next([]);
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarRapoarteJurnal;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarRapoarteJurnal) {
      this._state.endIndex = this.numarRapoarteJurnal;
    }
  }
}
