import {
  Component,
  OnInit
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Contract } from '../../../common/models/contract.model';
import { ContractTableService } from '../../../common/services/contracts-table.service';
import { NotificationErrorService } from '../../../common/services/notification-error.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
  providers: [ContractTableService, DecimalPipe]
})
export class ContractsComponent implements OnInit {
  tables$: Observable<Contract[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    numar: '',
    data: '',
    rasa: '',
    oper_insamantare: '',
    nrfemelecuratcertificat: null,
    nrmasculicuratcertificat: null,
    nrtineretrasapura: null,
    nrmetis: null,
    page_no: '1',
    page_size: '10',
    contr_status: 3,
  };

  constructor(
    public contractTableService: ContractTableService,
    private errorService: NotificationErrorService,
  ) {
    this.tables$ = contractTableService.tables$;
    this.total$ = contractTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.contractTableService
      .getContractsAPI(this.objToAPI).subscribe((response) => {
        // Hide spinner, after 1 second
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      }, error => {
        this.loadingDataSpinner = false;
        this.errorService.processErrorMsg(error.statusText);
      });
  }

  filterSearchData(event) {
    // Compose the object what will be sended to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

  getCallBackResult(event) {
    this.getTableData();
  }

}
