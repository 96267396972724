<app-raport-facturi-neachitate-table
  [rapoarteFacturiNeachitate]="tables$ | async"
  [rapoarteFacturiNeachitateLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (rapoarteFacturiNeachitateTableCallback)="getTableData()"
></app-raport-facturi-neachitate-table>
