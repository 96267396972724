<div class="card">
  <div class="card-body" style="height: 600px;">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./registrul-tratamente-vaccinari"
        >
          Registrul de tratamente/vaccinari
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./registrul-mortalitati"
        >
          Registrul de mortalitati
        </a>
      </li>
    </ul>

    <div class="h-100 d-flex align-items-center justify-content-center">
      <h1 class="text-center">ÎN LUCRU</h1>
    </div>

  </div>
</div>
