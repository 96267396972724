import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToDisplayFormat, convertDateToBackendFormat } from './services-table-helper';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root'
})

export class MovesTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  miscari = [];
  numarMiscari: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.miscari, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getMovesAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/miscari/`,
      {
        ...obj,
        data_start: convertDateToBackendFormat(obj.data_start),
        data_end: convertDateToBackendFormat(obj.data_end)
      },
      auth.options
    ).pipe(map((response: any) => {
      this.miscari = response.result;
      this.numarMiscari = response.count;

      this.miscari.map((miscare: any) => {
        miscare.data_miscare = convertDateToDisplayFormat(miscare.data_miscare);
        miscare.data_anexa10 = convertDateToDisplayFormat(miscare.data_anexa10);
      });

      this.paginate();
      this.sortEvent();
      this._total$.next(this.numarMiscari);

      return response;
    }));
  }

  getAnexa10Data(obj) {
    return this.http.post(
      `${this.baseUrl}/miscari/anexa10/`,
      obj, auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  updateAnexa10(obj: any) {
    return this.http.post(
      `${this.baseUrl}/miscari/save/`,
      {
        ...obj,
        data: obj.data ? convertDateToBackendFormat(obj.data) : null
      },
      auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  deleteMove(id: number) {
    return this.http.post(
      `${this.baseUrl}/miscari/delete/`,
      { id },
      auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadAnexa10Api(companyid, anexa10id) {
    return this.http
      .post(
        `${this.baseUrl}/miscari/anexa10-pdf/`,
        { companyid, anexa10id },
        {responseType: 'blob',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage['access-token']}`,
        })
      }
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/miscari/export/`,
      {
        ...obj,
        data_start: convertDateToBackendFormat(obj.data_start),
        data_end: convertDateToBackendFormat(obj.data_end)
      },
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage['access-token']}`,
        })
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarMiscari;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarMiscari) {
      this._state.endIndex = this.numarMiscari;
    }
  }
}
