import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToDisplayFormat, convertDateToBackendFormat } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})

export class ReproductionsTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  reproductii = [];
  numarReproductii: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.reproductii, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getReproductionsAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/reproductii/`,
      obj
    ).pipe(map((response: any) => {
      this.reproductii = response.result;
      this.numarReproductii = response.count;
      this.paginate();
      this.sortEvent();
      this._total$.next(this.numarReproductii);

      return response;
    }));
  }

  getTauri(obj) {
    return this.http
      .post(
        `${this.baseUrl}/reproductii/tauri/`,
        obj

      ).pipe(map((response: any) => {
        response.map(bull =>
          bull.view_label = bull.numarmatricol + (bull.codtaur ? ' - ' + bull.codtaur : '') +
          (bull.nume ? ' - ' + bull.nume : '') + (bull.exploatatie ? ' - ' + bull.exploatatie : '')
        );
        return response;
      }));
  }

  getVaci(obj) {
    return this.http
      .post(
        `${this.baseUrl}/reproductii/vaci/`,
        obj

      ).pipe(map((response: any) => {
        response.map(cow =>
          cow.view_label = cow.numarmatricol
        );

        return response;
      }));
  }

  getVaciDonatoare() {
    return this.http.post(
      `${this.baseUrl}/reproductii/vaci-donatoare/`,
      {}
    ).pipe(map((response: any) => {
      response.map(cow =>
        cow.view_label = cow.numarmatricol + (cow.exploatatie ? ' - ' + cow.exploatatie : '')
      );

      return response;
    }));
  }

  getAnexa3Data(obj) {
    return this.http.post(
      `${this.baseUrl}/reproductii/anexa3/`,
      obj
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  getAnexa3(montaId: number) {
    return this.http.post(
      `${this.baseUrl}/reproductii/monta/`,
      { montaid: montaId }
    ).pipe(map((response: any) => {
      response.montastart = convertDateToDisplayFormat(response.montastart);
      response.montaend = convertDateToDisplayFormat(response.montaend);
      response.datainsemintare = convertDateToDisplayFormat(response.datainsemintare);
      response.documentdata = convertDateToDisplayFormat(response.documentdata);
      response.autorizatiestart = convertDateToDisplayFormat(response.autorizatiestart);
      response.autorizatieend = convertDateToDisplayFormat(response.autorizatieend);

      return response;
    }));
  }

  getAnimalInfo(matricol) {
    return this.http
      .post(
        `${this.baseUrl}/reproductii/getanimalinfo/`,
        { numarmatricol: matricol }
      ).pipe(map(response => {
        return response;
      }));
  }

  getAutorizatii(obj) {
    return this.http.post(
      `${this.baseUrl}/reproductii/autorizatii/`,
      obj
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  getAnexa3Pdf(companyId: number, anexa3Id: number) {
    return this.http
    .post(
      `${this.baseUrl}/reproductii/anexa3-pdf/`,
        { companyid: companyId, anexa3id: anexa3Id },
        {
          responseType: 'blob'
        }
      ).pipe((response: any) => {
      return response;
    });
  }

  updateAnexa3(obj: any) {
    return this.http.post(
      `${this.baseUrl}/reproductii/montasave/`,
      {
        ...obj,
        date: obj.date ? convertDateToBackendFormat(obj.date) : null,
        date_end: obj.date_end ? convertDateToBackendFormat(obj.date_end) : null,
      }
    ).pipe(map((response: any) => {
      return response;
    }, errors => {
      return errors;
    }));
  }

  deleteReproductie(id: number) {
    return this.http.post(
      `${this.baseUrl}/reproductii/delete/`,
      { id }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  anexa3Delete(id: number) {
    return this.http.post(
      `${this.baseUrl}/reproductii/anexa3-delete/`,
      { id }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/reproductii/export/`,
      {
        ...obj
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  verificatAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/documente/verificat/`,
      obj
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.numarReproductii;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.numarReproductii) {
      this._state.endIndex = this.numarReproductii;
    }
  }
}
