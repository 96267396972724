import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators
} from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-reproductie-form-block',
  templateUrl: './reproductie-form-block.component.html',
  styleUrls: ['./reproductie-form-block.component.scss']
})
export class ReproductieFormBlockComponent implements OnInit {
  @Input() animalForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() disableAllFields: boolean;

  isAdmin = false;
  isOperator = false;
  isFermier = false;

  constructor(
    private helperDataService: HelperDataService
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
  }

  get autorizatiiMonta(): UntypedFormArray {
    return this.animalForm.get('autorizatii_monta') as UntypedFormArray;
  }

  uploadServerResponse(autorizatieMontaForm, event) {
    const file = event.body.files[0];
    if (!file) {
      return;
    }

    autorizatieMontaForm.controls.file_id.setValue(file.file_id);
    autorizatieMontaForm.controls.file_name.setValue(file.file_uploaded_name);
  }

  downloadAutorizatieFile(autorizatieMontaForm) {
    const fileId = autorizatieMontaForm.value.file_id;
    const fileName = autorizatieMontaForm.value.file_name;

    if (!fileId) {
      return;
    }

    this.helperDataService.downloadFile(fileId)
      .subscribe((response: any) => {
        const blob = new Blob([response]);
        this.helperDataService.simulateDownload(blob, fileName);
    });
  }

  removeAutorizatieFile(autorizatieMontaForm) {
    autorizatieMontaForm.controls.file_id.reset();
    autorizatieMontaForm.controls.file_name.reset();
  }

  clickStergeAutorizatieMonta(index) {
    this.autorizatiiMonta.removeAt(index);
  }

  adaugaAutorizatieMonta() {
    let autorizatieForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      nr_document: new UntypedFormControl(null, Validators.required),
      data_document: new UntypedFormControl(null, Validators.required),
      emitent: new UntypedFormControl(null, Validators.required),
      data_inceput: new UntypedFormControl(null, Validators.required),
      data_sfarsit: new UntypedFormControl(null, Validators.required),
      file_id: new UntypedFormControl(null, Validators.required),
      file_name: new UntypedFormControl(),
    });

    this.autorizatiiMonta.push(autorizatieForm);
  }
}
