import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { ApplicationState } from '../../../common/state/app.state';
import { getAppError } from '../../../common/state/reducers/error.reducers';
import { DeleteAppError } from '../../../common/state/actions/error.actions';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  message: string;
  timeoutID;

  constructor(
    private store: Store<ApplicationState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getAppError))
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: any) => {
        if (msg) {
          this.message = msg;

          // Auto hide the error message after 30 seconds.
          this.timeoutID = setTimeout(() => {
            this.hideMsg();
          }, 30000);
        }
      });
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutID);
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  closeErrorMsgDialog(event) {
    clearTimeout(this.timeoutID);
    this.hideMsg();
  }

  hideMsg() {
    this.message = '';
    this.store.dispatch(new DeleteAppError());
  }

}
