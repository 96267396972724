
import { Action } from '@ngrx/store';

export enum AppCompanyAdminsActionType {
  SET_ADMIN_COMPANY_DATA = 'SET_ADMIN_COMPANY_DATA',
  ADD_ADMIN_COMPANY_DATA = 'ADD_ADMIN_COMPANY_DATA',
  UPDATE_ADMIN_COMPANY_DATA = 'UPDATE_ADMIN_COMPANY_DATA',
}

export class SetCompanyAdminsData implements Action {
  readonly type = AppCompanyAdminsActionType.SET_ADMIN_COMPANY_DATA;
  constructor(public payload: any) { }
}

export class AddCompanyAdminData implements Action {
  readonly type = AppCompanyAdminsActionType.ADD_ADMIN_COMPANY_DATA;
  constructor(public payload: any) { }
}

export class UpdateCompanyAdminData implements Action {
  readonly type = AppCompanyAdminsActionType.UPDATE_ADMIN_COMPANY_DATA;
  constructor(public payload: any) { }
}

export type AppCompanyAdminsActions =
  SetCompanyAdminsData |
  AddCompanyAdminData |
  UpdateCompanyAdminData;
