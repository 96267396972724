import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';

@Component({
  selector: 'app-adeverinta-search',
  templateUrl: './adeverinta-search.component.html',
  styleUrls: ['./adeverinta-search.component.scss']
})
export class AdeverintaSearchComponent implements OnInit {
  @Input() isFermier;
  @Output() searchResult = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();
  adeverintaSearchForm;
  currentRoute;
  exploatatieName;
  exploatatieCode;
  isOnHolding = false;

  constructor(
    private store: Store<ApplicationState>
  ) { }

  ngOnInit(): void {

    this.store.pipe(select(getSelectedHolding))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.exploatatieName = response.name;
          this.exploatatieCode = response.code;
        }
      });

    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });

    this.isOnHolding = this.currentRoute.includes('/dashboard/adeverinte') ? false : true;

    this.adeverintaSearchForm = new UntypedFormGroup({
      company_name: new UntypedFormControl(this.isOnHolding ? this.exploatatieName : ''),
      company_code: new UntypedFormControl(this.isOnHolding ? this.exploatatieCode : ''),
      status_doc: new UntypedFormControl(null)
    });

    if (this.isOnHolding) {
      this.adeverintaSearchForm.controls.company_name.disable();
      this.adeverintaSearchForm.controls.company_code.disable();
    }
  }

  onSubmitted() {
    if (!this.adeverintaSearchForm.value.status_doc) {
      this.adeverintaSearchForm.value.status_doc = '';
    } else {
      this.adeverintaSearchForm.value.status_doc = this.adeverintaSearchForm.controls.status_doc.value.toString();
    }

    if (!this.adeverintaSearchForm.value.company_name) {
      this.adeverintaSearchForm.value.company_name = '';
    }

    if (!this.adeverintaSearchForm.value.company_code) {
      this.adeverintaSearchForm.value.company_code = '';
    }

    this.searchResult.emit(this.adeverintaSearchForm.value);
  }

  resetSearch() {
    this.adeverintaSearchForm.reset();
    this.onSubmitted();
  }
}
