import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { ContractTableService } from 'src/app/modules/acbcr/common/services/contracts-table.service';

@Component({
  selector: 'app-acte-aditionale',
  templateUrl: './acte-aditionale.component.html',
  styleUrls: ['./acte-aditionale.component.scss']
})
export class ActeAditionaleComponent implements OnInit {
  @ViewChild('trimiteEmailModal') trimiteEmailModal: ElementRef;
  @Input() acte_aditionale;
  @Input() email_cont_exploatatie;
  @Output() addActeAditionale = new EventEmitter();

  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  actAditionalIdTrimiteEmail;
  successTrimiteEmail = false;
  errorTrimiteEmail = false;
  permisiuneEditare = false;

  uploadConfig = UIHelper.uploadConfig;

  constructor(
    private helperDataService: HelperDataService,
    public modalService: NgbModal,
    private contractTableService: ContractTableService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();

    if (this.isAdmin || this.isOperator) {
      this.permisiuneEditare = true;
    }
  }

  dataActAditionalChanged(actAditional, event) {
    actAditional.controls.data.setValue(event);
  }

  deleteActAditional(index) {
    this.acte_aditionale.removeAt(index);
  }

  uploadActAditional(actAditional, event) {
    const file = event.body.files[0];
    actAditional.controls.file_name.setValue(file.file_uploaded_name);
    actAditional.controls.file_id.setValue(file.file_id);
  }

  selectatFisierActAditional(actAditional, event) {
    const file = event.target.files[0];
    if (file) {
      actAditional.fisier = file;
      actAditional.controls.file_name.setValue(file.name);
    }
  }

  downloadFileActAditional(actAditional) {
    let fileId = actAditional.controls.file_id.value;
    if (fileId) {
      this.helperDataService.downloadFile(fileId)
        .subscribe((response: any) => {
          const blob = new Blob([response]);
          const fileName = actAditional.controls.file_name.value;
          this.helperDataService.simulateDownload( blob, fileName);
        });
    }
  }

  downloadPDF(actAditionalId) {
    this.contractTableService.downloadActAditionalApi({act_aditional_id: actAditionalId})
      .subscribe((response: any) => {
        const blob = new Blob([response]);
        const fileName = 'act_aditional.pdf';
        this.helperDataService.simulateDownload( blob, fileName);
      });
  }

  openTrimiteEmailModal(actAditionalId) {
    this.actAditionalIdTrimiteEmail = actAditionalId;
    this.successTrimiteEmail = false;
    this.errorTrimiteEmail = false;

    this.modalService.open(this.trimiteEmailModal, {
      scrollable: false,
    });
  }

  trimiteEmail() {
    this.successTrimiteEmail = false;
    this.errorTrimiteEmail = false;
    let req_params = {
      act_aditional_id: this.actAditionalIdTrimiteEmail,
      email: this.email_cont_exploatatie,
    }

    this.contractTableService.trimiteEmailApi(req_params)
      .subscribe({
        next: (response) => {
          if (response && response.success) {
            this.successTrimiteEmail = true;
            this.errorTrimiteEmail = false;
          } else {
            this.successTrimiteEmail = false;
            this.errorTrimiteEmail = true;
          }
        },
        error: (errors) => {
          this.successTrimiteEmail = false;
          this.errorTrimiteEmail = true;
        }
      });
  }
}
