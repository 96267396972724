<app-rapoarte-facturi-table
  [rapoarteFacturi]="tables$ | async"
  [rapoarteFacturiLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (rapoarteFacturiTableCallback)="getTableData()"
></app-rapoarte-facturi-table>
