import { Action } from '@ngrx/store';
import { Country } from '../../models/country.model';
import { City } from '../../models/city.model';

export enum AppCountriesCitiesActionType {
  SET_COUNTRIES_DATA = 'SET_COUNTRIES_DATA',
  SET_CITIES_DATA = 'SET_CITIES_DATA'
}

export class SetCountriesData implements Action {
  readonly type = AppCountriesCitiesActionType.SET_COUNTRIES_DATA;
  constructor(public payload: Country) { }
}

export class SetCitiesData implements Action {
  readonly type = AppCountriesCitiesActionType.SET_CITIES_DATA;
  constructor(public payload: City) { }
}

export type AppCountriesCitiesActions = SetCountriesData | SetCitiesData;
