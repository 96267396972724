<form [formGroup]="activitateSearchForm">
  <div class="row">
    <div class="col-12">
      <div class="form-group mb-3">
        <label for="exploatatia">
          Denumirea exploatației
        </label>

        <input
          type="text"
          id="exploatatia"
          formControlName="exploatatia"
          placeholder="Adaugă denumirea exploatației"
          class="form-control" />
      </div>
    </div>

    <div class="col-12">
      <div class="form-group mb-3">
        <label for="subventie">
          Subvenționată
        </label>
        <ng-select
          bindLabel="subventie"
          bindValue="subventie"
          formControlName="subventie"
          placeholder="Alege subvenție"
        >
          <ng-option [value]="">Toate</ng-option>
          <ng-option [value]="0">Nesubvenționată</ng-option>
          <ng-option [value]="1">Subvenționată</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group mb-3">
        <label for="tip_factura">
          Activitate facturabilă
        </label>
        <ng-select
          bindLabel="tip_factura"
          bindValue="tip_factura"
          formControlName="tip_factura"
          placeholder="Alege activitatea facturabilă"
        >
          <ng-option [value]="1">RG</ng-option>
          <ng-option [value]="2">CPP</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
