<ng-template #addEditAdeverintaApia let-modal="close('Cross click')">
  <div class="modal-header row" [ngSwitch]="addEditModalType">
    <div class="col-sm-12 col-md-12 col-lg-6 custom-col">
      <h3 class="modal-title" *ngSwitchCase="'add'">
        Crează adeverință APIA
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit'">
        Editează adeverința APIA
      </h3>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-secondary"
          (click)="closeModal()"
          autofocus="false"
        >
          Închide
        </button>
        <button
          class="btn btn-sm btn-success"
          (click)="onSubmitted()"
        >
          <span *ngIf="addEditModalType === 'add'; else btnTxt">
            Crează adeverința
          </span>
          <ng-template #btnTxt>
            <span>
              Salvează adeverința
            </span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div class="container-fluid">

      <div class="row">
        <div class="col-xl-9">
          <div class="form-group mb-3">
            <label for="exploatatie_id">
              Exploatație
            </label>
            <ng-select
              [items]="listaExploatatii"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="addEditModalData.exploatatie_id"
              [required]="true"
              placeholder="Alege exploatația"
              [ngClass]="{disabled: addEditModalType === 'edit' || disableDropDown}"
              (change)="findAnimals($event)"
              (search)="searchHoldings($event.term)"
              (clear)="clearHoldings()"
              [ngClass]="{ 'disabled': isFermier }"
            >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <div class="row">
                <div class="col-md-12 col-lg-auto">
                  {{item.name}} - {{ item.code }}
                </div>

                <div class="col-md-12 col-lg-auto px-0">
                  <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                    {{ error }}
                  </span>
                </div>
              </div>
            </ng-template>
            </ng-select>

            <small
              *ngIf="showHint"
              class="form-text text-muted ml-2"
            >
              <b>Introdu minim 5 caractere pentru a căuta</b>
            </small>
            <small
              *ngIf="submitted && selectedHoldingIdError"
              class="form-text text-danger ml-2"
            >
              <b>Alege exploatația</b>
            </small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-lg-auto">
          <div class="form-group mb-3">
            <label for="data">
              Data adeverinței
            </label>
            <app-date-picker
              (dateChanged)="onDateChanged($event)"
              [submitted]="submitted"
              [selectedDate]="addEditModalData.data"
            >
            </app-date-picker>
          </div>
        </div>

        <div class="col-sm-12 col-lg-4">
          <div class="form-group mb-3">
            <label for="rasa_id">
              Rasa
            </label>
            <ng-select
              [items]="[{'id':1, 'name':'Charolaise'}, {'id':2, 'name':'Limousine'}]"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="addEditModalData.rasa_id"
              (change)="findAnimals($event)"
              (clear)="clearRasa()"
              placeholder="Alege rasa"
            >
            </ng-select>

            <small
              *ngIf="submitted && selectedRaceError"
              class="form-text text-danger ml-2"
            >
              <b>Alege rasa</b>
            </small>
          </div>
        </div>

        <div class="col-sm-12 col-lg-4">
          <div class="form-group mb-3">
            <label for="centru_apia">
              Centru APIA
            </label>

            <input
              type="text"
              [(ngModel)]="addEditModalData.centru_apia"
              (change)="checkErrors()"
              placeholder="Adaugă Centru APIA"
              class="form-control"/>

              <small
                *ngIf="submitted && centruAPIAError"
                class="form-text text-danger ml-2"
              >
                <b>Adaugă Centru APIA</b>
              </small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <button
            class="btn btn-md btn-info ml-2"
            (click)="populateLists()"
            autofocus="false"
          >
            Autopopulează listele
          </button>

          <small
            *ngIf="showError"
            class="form-text text-danger ml-2"
          >
            <b>Alege exploatația și rasa</b>
          </small>

        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Vaci de carne – înscrise în registrul genealogic, secțiunea principală</h3>
        </div>
      </div>
      <div *ngFor="let animal of addEditModalData.animale.vaci" class="mb-2">
        <div *ngIf="animal" class="row">
          <div class="col-auto date-animal">
            <div class="h4 row">
              <span class="bg-secondary text-white px-1 mr-1">{{ animal.sex }}</span>
              {{ animal.numarmatricol }}
              <span *ngIf="animal.nume">, {{ animal.nume }}</span>
            </div>

            <div class="h5 ml-4">
              {{ animal.rasa }}, varsta {{ animal.varsta }} zile
            </div>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <span *ngIf="animal.eligibil" class="text-success">Eligibil</span>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <span *ngFor="let error of animal.errors" class="badge badge-danger p-1 mr-2">
              {{ error }}
            </span>
            <span *ngFor="let warning of animal.warnings" class="badge badge-warning text-dark p-1 mr-2">
              {{ warning }}
            </span>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <i
              class="mdi mdi-close-circle text-danger fs-3 cursor-pointer"
              title="Șterge"
              (click)="deleteVaca(animal)"
            >
            </i>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-lg-9">
          <div class="form-group mb-3">
            <ng-select
              #selectElementVaca
              [items]="animale.vaci"
              [(ngModel)]="vacaDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal / Verificare animale neeligibile"
              (change)="addVaca(selectElementVaca)"
            >
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                <div class="row">
                  <div class="col-sm-12 col-md-auto">
                    <span class="bg-secondary text-white px-1 mr-1">{{ item.sex }}</span>
                    <span>
                      {{ item.numarmatricol }} - {{ item.rasa }}
                    </span>
                  </div>

                  <div class="col-sm-12 col-md-auto px-0">
                    <span *ngIf="item.eligibil" class="mr-2 text-success">Eligibil</span>
                    <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                      {{ error }}
                    </span>
                    <span *ngFor="let warning of item.warnings" class="badge badge-warning text-dark p-1 mr-2">
                      {{ warning }}
                    </span>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Vaci de carne – înregistrate în registrul genealogic, secțiunea suplimentară</h3>
        </div>
      </div>
      <div *ngFor="let animal of addEditModalData.animale.vaciMetise" class="mb-2">
        <div *ngIf="animal" class="row">
          <div class="col-auto date-animal">
            <div class="h4 row">
              <span class="bg-secondary text-white px-1 mr-1">{{ animal.sex }}</span>
              {{ animal.numarmatricol }}
              <span *ngIf="animal.nume">, {{ animal.nume }}</span>
            </div>

            <div class="h5 ml-4">
              {{ animal.rasa }}, varsta {{ animal.varsta }} zile
            </div>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <span *ngIf="animal.eligibil" class="text-success">Eligibil</span>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <span *ngFor="let error of animal.errors" class="badge badge-danger p-1 mr-2">
              {{ error }}
            </span>
            <span *ngFor="let warning of animal.warnings" class="badge badge-warning text-dark p-1 mr-2">
              {{ warning }}
            </span>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <i
              class="mdi mdi-close-circle text-danger fs-3 cursor-pointer"
              title="Șterge"
              (click)="deleteVacaMetise(animal)"
            >
            </i>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-lg-9">
          <div class="form-group mb-3">
            <ng-select
              #selectElementVacaMetise
              [items]="animale.vaciMetise"
              [(ngModel)]="vacaMetisDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal / Verificare animale neeligibile"
              (change)="addVacaMetise(selectElementVacaMetise)"
            >
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                <div class="row">
                  <div class="col-sm-12 col-md-auto">
                    <span class="bg-secondary text-white px-1 mr-1">{{ item.sex }}</span>
                    <span>
                      {{ item.numarmatricol }} - {{ item.rasa }}
                    </span>
                  </div>

                  <div class="col-sm-12 col-md-auto px-0">
                    <span *ngIf="item.eligibil" class="mr-2 text-success">Eligibil</span>
                    <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                      {{ error }}
                    </span>
                    <span *ngFor="let warning of item.warnings" class="badge badge-warning text-dark p-1 mr-2">
                      {{ warning }}
                    </span>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="type-title">Tauri de carne – înscriși în registrul genealogic, secțiunea principală</h3>
        </div>
      </div>
      <div *ngFor="let animal of addEditModalData.animale.tauri" class="mb-2">
        <div *ngIf="animal" class="row">
          <div class="col-auto date-animal">
            <div class="h4 row">
              <span class="bg-secondary text-white px-1 mr-1">{{ animal.sex }}</span>
              {{ animal.numarmatricol }}
              <span *ngIf="animal.nume">, {{ animal.nume }}</span>
            </div>

            <div class="h5 ml-4">
              {{ animal.rasa }}, varsta {{ animal.varsta }} zile
            </div>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <span *ngIf="animal.eligibil" class="text-success">Eligibil</span>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <span *ngFor="let error of animal.errors" class="badge badge-danger p-1 mr-2">
              {{ error }}
            </span>
            <span *ngFor="let warning of animal.warnings" class="badge badge-warning text-dark p-1 mr-2">
              {{ warning }}
            </span>
          </div>

          <div class="col-auto d-flex justify-content-center align-items-center">
            <i
              class="mdi mdi-close-circle text-danger fs-3 cursor-pointer"
              title="Șterge"
              (click)="deleteTaur(animal)"
            >
            </i>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-lg-9">
          <div class="form-group mb-3">
            <ng-select
              #selectElementTaur
              [items]="animale.tauri"
              [(ngModel)]="taurDeAdaugat"
              bindLabel="numarmatricol"
              bindValue="animalid"
              placeholder="Alege animal / Verificare animale neeligibile"
              dropdownPosition="top"
              (change)="addTaur(selectElementTaur)"
            >
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                <div class="row">
                  <div class="col-sm-12 col-md-auto">
                    <span class="bg-secondary text-white px-1 mr-1">{{ item.sex }}</span>
                    <span>
                      {{ item.numarmatricol }} - {{ item.rasa }}
                    </span>
                  </div>

                  <div class="col-sm-12 col-md-auto px-0">
                    <span *ngIf="item.eligibil" class="mr-2 text-success">Eligibil</span>
                    <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                      {{ error }}
                    </span>
                    <span *ngFor="let warning of item.warnings" class="badge badge-warning text-dark p-1 mr-2">
                      {{ warning }}
                    </span>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

    </div>
  </div>
 </ng-template>
