<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="facturiTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
                <div class="btn-group facturi-buttons">
                  <button
                    class="btn btn-sm btn-icon btn-success"
                    title="Generare facturi"
                    (click)="openGenerareFacturi()"
                  >
                    Generare facturi
                  </button>
                  <button
                    class="btn btn-sm btn-icon btn-blue"
                    title="Factură manuală"
                    (click)="openFacturaManModal('add')"
                  >
                    Factură manuală
                  </button>
                </div>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-lg-6 mb-1">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>
                <button
                  class="btn btn-blue btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="expediereEmailList()"
                  ngbTooltip="Expediere email către exploația cu factura selectată"
                  placement="bottom"
                >
                  <i class="fe-mail"></i>
                  Expediere email
                </button>
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner || !isDataFiltered"
                  (click)="memoriuTehnic()"
                >
                  Memoriu tehnic subvenții
                </button>
                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportXls()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele filtrate"
                  placement="bottom"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #facturiTable>
            <table
              id="facturi-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="exploatatie" (sort)="onSort($event)">
                    Exploatația
                  </th>
                  <th>
                    <div class="select-buttons">
                      <input
                        type="checkbox"
                        id="singleCheckbox1"
                        value="option1"
                        aria-label="Single checkbox One"
                        [(ngModel)]="allChecked"
                        (change)="checkAllOptions($event)"
                      />
                      <label>Factura</label>
                    </div>
                  </th>
                  <th sortable="subventionata" (sort)="onSort($event)">
                    Valoare/Subvenție
                  </th>
                  <th scope="col">
                    Acțiuni
                  </th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="facturiTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsită nici o factură.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let factura of facturi">
                  <td>{{ factura.company }}</td>
                  <td>
                    <div class="select-buttons">
                      <input
                        type="checkbox"
                        id="singleCheckbox1"
                        value="option1"
                        aria-labelledby=""
                        *ngIf="
                          factura.status === 1 ||
                          factura.status === 2 ||
                          factura.status === 4
                        "
                        [checked]="factura.isChecked || mySet.has(factura.id)"
                        (click)="statusFactura(factura)"
                      />
                      <label
                        >{{ factura.seria }}{{ factura.numar }}/{{
                          factura.data
                        }}</label
                      >
                    </div>
                  </td>
                  <td>
                    {{ factura.val_fara_tva }}/{{ factura.val_subventie }}
                  </td>
                  <td>
                    <div class="btn-group" class="action-buttons">
                      <button
                        class="btn btn-sm btn-icon btn-primary"
                        title="Editează factură"
                        (click)="openFacturaManModal('edit', factura.id)"
                        *ngIf="factura.status === 0"
                      >
                        Editează factură
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-success"
                        title="Validează"
                        (click)="changeStatusFacturi(factura.id, 1)"
                        *ngIf="factura.status === 0"
                      >
                        Validează
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-info"
                        title="Tipărește"
                        (click)="tiparesteFactura(factura.id, 2)"
                        *ngIf="
                          factura.status === 1 ||
                          factura.status === 3 ||
                          factura.status === 4
                        "
                      >
                        Tipărește
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-info"
                        title="Retipărește"
                        (click)="tiparesteFactura(factura.id, 2, false)"
                        *ngIf="factura.status === 2"
                      >
                        Retipărește
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-blue"
                        title="Trimite email"
                        (click)="expediazaEmail(factura.id)"
                        *ngIf="factura.status === 1"
                      >
                        Trimite email
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-blue"
                        title="Retrimite email"
                        *ngIf="factura.status === 2"
                        (click)="expediazaEmail(factura.id, false)"
                      >
                        Retrimite email
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-danger"
                        title="Anulează"
                        (click)="anuleazaFactura(factura.id, 3, factura)"
                        *ngIf="
                          factura.status === 2 && factura.diferentaZile <= 30
                        "
                      >
                        Anulează
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-danger"
                        title="Stornează"
                        (click)="changeStatusFacturi(factura.id, 4)"
                        *ngIf="factura.status === 2"
                      >
                        Stornează
                      </button>
                      <button
                        class="btn btn-sm btn-icon btn-dark"
                        title="Subvenție respinsă"
                        (click)="respingeSubventie(factura.id, 1)"
                        *ngIf="
                          factura.status === 2 &&
                          factura.subventie_refuzata === 0 &&
                          factura.val_subventie !== '0.00'
                        "
                      >
                        Subvenție respinsă
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="facturiTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ facturiTableService.startIndex }} la
                {{ facturiTableService.endIndex }} din
                {{ facturiTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="facturiLength"
                  [(page)]="facturiTableService.page"
                  [pageSize]="facturiTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="'facturi'"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-factura-manuala
  [open]="openFacturaManualaModal"
  [modalType]="modalType"
  [facturaData]="facturaData"
  [preserieFactura]="preserieFactura"
  (notifyClose)="closeFacturaManModal($event)"
  (callbackResult)="getModalResult($event)"
>
</app-factura-manuala>

<app-generare-facturi
  [open]="openGenerareFacturiModal"
  (notifyClose)="closeGenerareFacturiModal($event)"
  (callbackResult)="getModalResult($event)"
>
</app-generare-facturi>

<div class="modal" *ngIf="showConfirmationModal" #emailConfirmationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="text-left">Confirmare trimitere email</h3>
      <span class="close text-right" (click)="closeConfirmationEmailModal()">
        &times;</span
      >
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12 info-wrapper">
          <div class="avatar-lg rounded-circle bg-soft-success border-success border">
            <i class="fe-check font-22 avatar-title text-success"></i>
          </div>
          <h3 class="text-center">Email-ul a fost trimis cu succes!</h3>
        </div>
      </div>
    </div>
  </div>
</div>
