import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { AdvancedSortableDirective, SortEvent } from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { RaportIncasare } from 'src/app/modules/acbcr/common/models/rapoarte-incasari.model';
import { RapoarteIncasariTableService } from 'src/app/modules/acbcr/common/services/rapoarte-incasari.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-rapoarte-incasari-table',
  templateUrl: './rapoarte-incasari-table.component.html',
  styleUrls: ['./rapoarte-incasari-table.component.scss']
})
export class RapoarteIncasariTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
  AdvancedSortableDirective>;
  @ViewChild('rapoarteIncasariTable', { static: false }) rapoarteIncasariTable: ElementRef;

  @Input() rapoarteIncasari: RaportIncasare[];
  @Input() rapoarteIncasariLength: number;
  @Input() loadingDataSpinner: boolean;
  @Input() totalRestant;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() rapoarteIncasariTableCallback = new EventEmitter();

  readonly modalType = 'rapoarte-incasari';
  openSearchModal = new Subject<boolean>();

  currentRoute: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isAutoritateJudeteana = false;
  isContabil = false;

  constructor(
    public rapoarteIncasariTableService: RapoarteIncasariTableService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.rapoarteIncasariTableService.sortColumn = column;
    this.rapoarteIncasariTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  getModalResult(event) {
    this.rapoarteIncasariTableCallback.emit(event);
  }

}
