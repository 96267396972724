import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApplicationState } from '../app.state';
import { AppFiltersActions, AppFiltersActionType } from '../actions/filters.actions';
import { SearchHolding } from '../../models/holdings.models';
import { SearchAnimal } from '../../models/animal.models';

export interface AppFilters {
  animals_filter: SearchAnimal;
  holdings_filter: SearchHolding;
}

export const initialState = {
  animals_filter: undefined,
  holdings_filter: undefined
};

export const getApplicationFeature =
  createFeatureSelector<ApplicationState>('appData');

export const getAnimalsFilter =
  createSelector(getApplicationFeature, state => state.filters.animals_filter);

export const getHoldingsFilter =
  createSelector(getApplicationFeature, state => state.filters.holdings_filter);

export function filtersReducers(
  state: AppFilters = initialState,
  action: AppFiltersActions
) {
  switch (action.type) {
    case AppFiltersActionType.SET_ANIMALS_FILTER:
      return {
        ...state,
        animals_filter: action.payload
      };

    case AppFiltersActionType.SET_HOLDINGS_FILTER:
      return {
        ...state,
        holdings_filter: action.payload
      };

    default:
      return state;
  }
}
