import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { takeUntil } from 'rxjs/operators';
import { getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { NgSelectComponent } from '@ng-select/ng-select';
import { City } from 'src/app/modules/acbcr/common/models/city.model';
@Component({
  selector: 'app-settlement-search',
  templateUrl: './settlement-search.component.html',
  styleUrls: ['./settlement-search.component.scss']
})
export class SettlementSearchComponent implements OnInit, OnDestroy {

  @Output() searchResult = new EventEmitter();
  @ViewChild('smallCitiesDropDown') smallCitiesDropDown: NgSelectComponent;
  @ViewChild('citiesDropdown') citiesDropdown: NgSelectComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  cities: City[];
  smallCities: City[];
  settlementSearchForm;

  constructor(
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>
  ) {
    this.smallCities = [];
  }

  ngOnInit(): void {
    this.store.pipe(select(getAppCities))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.cities = response;
      });

    this.settlementSearchForm = new UntypedFormGroup({
      county_id: new UntypedFormControl(null),
      settlement_id: new UntypedFormControl(null),
    });
  }

  getSelectedCity(city) {
    if (city !== undefined) {
      this.helperDataService.getSmallCities(city.code)
        .subscribe(response => {
          this.smallCities = response;
        });
    }

    this.smallCitiesDropDown.handleClearClick();
    this.smallCities = [];
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onSubmitted() {
    let values = this.settlementSearchForm.value;
    values['county_id'] = values['county_id'] ? values['county_id'] : '';
    values['settlement_id'] = values['settlement_id'] ? values['settlement_id'] : '';
    this.searchResult.emit(values);
  }

  resetSearch() {
    this.settlementSearchForm.reset();
    this.onSubmitted();
  }
}
