import { Component, OnInit, Output, EventEmitter, OnDestroy, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';

import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { Subject } from 'rxjs';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { takeUntil } from 'rxjs/operators';
import { ReproductionBull } from 'src/app/modules/acbcr/common/models/reproduction.model';
import { ReproductionsTableService } from 'src/app/modules/acbcr/common/services/reproductions-table.service';
import { DateFormatter } from '../../../date-picker/date-format';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { OperatorsTableService } from 'src/app/modules/acbcr/common/services/operators-table.service';

@Component({
  selector: 'app-add-edit-reproductie-form',
  templateUrl: './add-edit-reproductie-form.component.html',
  styleUrls: ['./add-edit-reproductie-form.component.scss']
})
export class AddEditReproductieFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('autModal') autModal: ElementRef;

  @Input() anexa = undefined;
  @Input() anexaId = undefined;
  @Input() reprModalType: string;

  @Output() callbackResult = new EventEmitter();
  @Output() closeForm = new EventEmitter();

  readonly addReproduction = 'add-reproductie';
  readonly editReproduction = 'edit-reproductie';

  fileId = null;
  fileName = null;
  submitted = false;
  showDateErrors = false;
  showDocDateErrors = false;
  showIntervalWarning = false;
  openConfirmModal = false;
  naturalSystemFields = false;
  artificialSystemFields = false;
  embrioSystemFields = false;

  reproductionForm;
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  cows = [];
  donorCows = [];
  bulls: ReproductionBull[] = [];
  autorizatiiMonta = [];
  operatoriInsamantare;
  disableAutReproductie = false;
  errorFileBuletin = false;
  taurErrors = [];
  taurWarnings = [];
  exploatatieID;

  uploadConfig = UIHelper.uploadConfig;

  constructor(
    private helperDataService: HelperDataService,
    private reproductionTableService: ReproductionsTableService,
    private errorService: NotificationErrorService,
    private store: Store<ApplicationState>,
    public modalService: NgbModal,
    public operatorsTableService: OperatorsTableService,
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
  }

  ngOnChanges(changes) {
    this.showDateErrors = false;
    this.showDocDateErrors = false;

    if (this.reprModalType === this.addReproduction) {
      this.buildForm();
    } else {
      this.buildForm(this.anexa);
      this.getBulls();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  buildForm(formData?) {
    this.resetFileUploader();
    this.fileId = formData?.file_id;
    this.fileName = formData?.file_name;

    if (this.reproductionForm && formData) {
      this.reproductionForm.reset();
      this.checkReproductionSystem(formData.tipreproductie);
    }

    this.reproductionForm = new UntypedFormGroup({
      comp_id: new UntypedFormControl(''),
      anexa3id: new UntypedFormControl(formData?.anexa3id),
      montaid: new UntypedFormControl(formData?.montaid),
      file_id: new UntypedFormControl(formData?.file_id),
      system_rep: new UntypedFormControl(
        formData ? formData?.tipreproductie?.toString() : '2',
        Validators.required),
      op_ins: new UntypedFormControl(
        formData ? formData?.opinsamantare?.toString() : ''),
      date: new UntypedFormControl(
        formData ? ( formData?.tipreproductie === 2 ? formData?.montastart : formData?.datainsemintare) : ''),
      date_end: new UntypedFormControl(
        formData ? formData?.montaend : ''),
      taur: new UntypedFormControl(
        formData ? formData?.nrmascul?.toString() : null,
        Validators.required),
      femela: new UntypedFormControl(null, Validators.required),
      femela_purtatoare: new UntypedFormControl(null),
      doc_no: new UntypedFormControl(
        formData ? formData?.documentnr?.toString() : ''),
      stare_anexa: new UntypedFormControl(
        formData ? formData?.status.toString() : '0',
        Validators.required),
      autorizatie_id: new UntypedFormControl(formData?.autorizatie_id, Validators.required),
      are_evaluare_genetica: new UntypedFormControl(formData?.are_evaluare_genetica),
    });

    this.submitted = false;
    this.disableAutReproductie = false;
    this.autorizatiiMonta = [];

    if (formData) {
      this.exploatatieID = formData.exploatatie_id;
      this.operatoriInsamantare = this.viewLabelOperatori(formData.operatori_insamantare);
      this.autorizatiiMonta = formData.autorizatii_monta;
    } else {
      this.store.pipe(select(getSelectedHolding))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.exploatatieID = response.id;
        }
      });
    }

    this.changeFormFields(this.reproductionForm.controls.system_rep.value);

    if (this.naturalSystemFields && formData) {
      const matricole = formData.vaci?.split(',');

      if (matricole) {
        matricole.map( matricol =>
          this.cows.push({ numarmatricol: matricol, view_label: matricol })
        );
        this.reproductionForm.controls.femela.setValue(matricole);
      }
    } else {
      if (!this.artificialSystemFields) {
        this.donorCows.push({ numarmatricol: formData?.vaci, view_label: formData?.vaci });
        this.reproductionForm.controls.femela_purtatoare.setValue(formData?.femelapurtatoare);
      }

      this.reproductionForm.controls.femela.setValue(formData?.vaci);
    }
  }

  closeFormMethod() {
    this.closeForm.emit(false);
  }

  uploadMontaNaturala(event) {
    this.errorFileBuletin = false;

    if (event.body && event.body.files) {
      const file = event.body.files[0];
      this.fileId = file.file_id;
      this.fileName = file.file_uploaded_name;
      this.reproductionForm.controls.file_id.setValue(file.file_id);
    }
  }

  uploadInsamantareArtificiala(event) {
    this.errorFileBuletin = false;

    if (event.body && event.body.files) {
      const file = event.body.files[0];
      this.fileId = file.file_id;
      this.fileName = file.file_uploaded_name;
      this.reproductionForm.controls.file_id.setValue(file.file_id)
    }
  }

  downloadDocument() {
    if (this.fileId) {
      this.helperDataService.downloadFile(this.fileId)
        .subscribe((response: any) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, this.fileName);
      });
    }
  }

  openConfirmActionModal() {
    this.openConfirmModal = true;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event != true) {
      return;
    }

    this.reproductionTableService.deleteReproductie(this.anexa.montaid)
      .subscribe({
        next: (response) => {
          this.callbackResult.emit(response);
          this.closeForm.emit(false);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  onSubmitted() {
    this.submitted = true;
    this.errorFileBuletin = false;

    if ((this.artificialSystemFields || this.embrioSystemFields) && !this.fileId) {
      this.errorFileBuletin = true;
    }

    if (!this.reproductionForm.valid || this.errorFileBuletin) {
      return;
    }

    this.showDateErrors = false;
    this.showDocDateErrors = false;

    if (this.naturalSystemFields) {
      delete this.reproductionForm.value.doc_no;

      if (!this.checkDateInterval(this.reproductionForm.value.date, this.reproductionForm.value.date_end)) {
        this.showDateErrors = true;
      }

      if (this.showDateErrors || this.showDocDateErrors) {
        return;
      }
    }

    if (! this.reproductionForm.controls.anexa3id.value) {
      this.reproductionForm.controls.anexa3id.setValue(this.anexaId);
    }

    this.reproductionForm.controls.comp_id.setValue(this.exploatatieID);

    this.reproductionTableService.updateAnexa3(this.reproductionForm.value)
      .subscribe({
        next: (response) => {
          this.callbackResult.emit(response);
          this.closeForm.emit(false);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  changeRepSystem(event) {
    this.reproductionForm.reset();
    this.resetFileUploader();
    this.reproductionForm.controls.system_rep.setValue(event);
    this.reproductionForm.controls.stare_anexa.setValue('0');
    this.changeFormFields(event);
    this.bulls = [];
  }

  // Change form fields in function of reproduction system
  changeFormFields(event) {
    this.submitted = false;
    this.artificialSystemFields = event === '1' ? true : false;
    this.naturalSystemFields = event === '2' ? true : false;
    this.embrioSystemFields = event === '3' ? true : false;

    this.setValidatorsForReproduction(this.naturalSystemFields);
    this.setValidatorsForIAReproduction(this.artificialSystemFields);
    this.setValidatorsForEmbriotransfer(this.embrioSystemFields);

    if (!this.naturalSystemFields) {
      this.f.doc_no.setValidators(Validators.required);
      this.f.autorizatie_id.clearValidators();
    } else {
      this.f.doc_no.clearValidators();
      this.f.autorizatie_id.setValidators(Validators.required);
    }

    this.updateValueAndValidity(this.reproductionForm);
    this.disableAutReproductie = false;

    switch (event) {
      case '1':
        break;

      case '2':
        this.disableAutReproductie = true;
        break;

      case '3':
        this.getDonorCows();
        break;
    }

  }

  // Get forms controls state
  get f() { return this.reproductionForm.controls; }

  // Search method for bulls ng-select component
  searchBull = (term: string, item: ReproductionBull) => {
    if (item.view_label.toUpperCase().includes(term.toUpperCase())) {
      return item;
    }
  }

  dataMontaChange(event) {
    this.showDateErrors = false;
    this.reproductionForm.controls.date.setValue(event);

    if (this.embrioSystemFields) {
      this.reproductionForm.controls.femela_purtatoare.reset();
    }

    this.getBulls();
    this.selectTaur();
  }

  dataEndMontaChange(event) {
    this.showDateErrors = false;
    this.reproductionForm.controls.date_end.setValue(event);

    if (this.embrioSystemFields) {
      this.reproductionForm.controls.femela_purtatoare.reset();
    }

    this.selectTaur();
  }

  selectTaur() {
    this.resetFileUploader();
    this.getCows();

    if (this.naturalSystemFields) {
      this.disableAutReproductie = true;
    }

    this.showIntervalWarning = false;

    const taurId = this.getTaurId();
    const dataStart = this.reproductionForm.value.date;
    const dataSfarsit = this.reproductionForm.value.date_end;
    this.autorizatiiMonta = [];

    if (!this.naturalSystemFields || !taurId || !dataStart || !dataSfarsit) {
      return;
    }

    let req_params = {
      id: taurId,
      data_start_monta: dataStart,
      data_sfarsit_monta: dataSfarsit,
    };

    this.reproductionTableService.getAutorizatii(req_params)
      .subscribe({
        next: (response) => {
          this.taurErrors = response.errors;
          this.taurWarnings = response.warnings;
          this.autorizatiiMonta = response.autorizatii_monta;

          if (this.autorizatiiMonta && this.autorizatiiMonta.length === 1) {
            let autorizatieId = this.autorizatiiMonta[0].id;
            this.reproductionForm.controls.autorizatie_id.setValue(autorizatieId);
          } else {
            this.reproductionForm.controls.autorizatie_id.setValue(null);
          }
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  getTaurId(): string {
    let matricolTaur = this.reproductionForm.value.taur;
    let taurObj = this.bulls.find((obj) => {
      return obj.numarmatricol == matricolTaur;
    })

    return taurObj?.id.toString();
  }

  searchOperator(event) {
    if (event.term.length < 4) {
      this.operatoriInsamantare = [];
      return;
    }

    this.operatorsTableService.getOperatorsAPI({nume: event.term, order_by: 'nume'})
      .subscribe((response: any) => {
        if (response) {
          this.operatoriInsamantare = this.viewLabelOperatori(response.result);
        }
      });
  }

  viewLabelOperatori(op_list) {
    if (!op_list) {
      return;
    }

    return op_list.map(
      (op) => {
        op.viewLabel = `${op.nume} ${op.prenume} ( ${op.cod} )`;
        return op;
      }
    );
  }

  private getDonorCows() {
    if (this.donorCows.length < 2) {
      this.reproductionTableService.getVaciDonatoare()
      .subscribe(response => {
        this.donorCows = response;
      });
    }
  }

  private getCows() {
    if (!this.exploatatieID) {
      return;
    }

    let taur = this.reproductionForm.value.taur;
    if (!taur || !this.bulls) {
      return;
    }

    let findTaur = this.bulls.find(item => item.numarmatricol == taur);
    if (!findTaur) {
      return;
    }

    let req_params = {
      exploatatie_id: this.exploatatieID,
      rasa_id: findTaur.rasa_id,
    };

    this.reproductionTableService.getVaci(req_params)
      .subscribe(cows => {
        this.cows = cows;
      }
    );
  }

  private getBulls() {
    if (!this.exploatatieID) {
      return;
    }

    let req_params = {
      exploatatie_id: this.exploatatieID,
      tip: this.reproductionForm.value.system_rep,
      data_start_monta: this.reproductionForm.value.date,
    };

    this.reproductionTableService.getTauri(req_params)
      .subscribe(bulls => {
        this.bulls = bulls || [];

        if (this.anexa && this.anexa.nrmascul) {
          let findTaur = this.bulls.find(item => item.numarmatricol == this.anexa.nrmascul);

          if (!findTaur) {
            this.reproductionTableService.getAnimalInfo(this.anexa.nrmascul).subscribe(taur => {
              let bull: any = taur;
              bull.view_label = bull.numarmatricol + (bull.codtaur ? ' - ' + bull.codtaur : '') +
                (bull.nume ? ' - ' + bull.nume : '') + (bull.exploatatie ? ' - ' + bull.exploatatie : '')

              this.bulls = [...this.bulls, bull];
              this.getCows();
            });
          } else {
            this.getCows();
          }
        }
      }
    );
  }

  selectAutorizatie(event, autorizatieId) {
    this.reproductionForm.controls.autorizatie_id.setValue(autorizatieId);
    event.target.checked = true;
  }

  private setValidatorsForReproduction(required: boolean) {
    required ? (
      this.f.date_end.setValidators(Validators.required)
    ) : (
      this.f.date_end.clearValidators()
    );
  }

  private setValidatorsForIAReproduction(required: boolean) {
    required ? (
      this.f.op_ins.setValidators(Validators.required)
    ) : (
      this.f.op_ins.clearValidators()
    );
  }

  private setValidatorsForEmbriotransfer(required: boolean) {
    required ? (
      this.f.femela_purtatoare.setValidators(Validators.required)
    ) : (
      this.f.femela_purtatoare.clearValidators()
    );
  }

  private updateValueAndValidity(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).updateValueAndValidity();
    });
  }

  private checkReproductionSystem(systemRepr) {
    this.artificialSystemFields = systemRepr === 1 ? true : false;
    this.naturalSystemFields = systemRepr === 2 ? true : false;
    this.embrioSystemFields = systemRepr === 3 ? true : false;
  }

  private checkDateInterval(date1, date2): boolean {
    const data1 = new DateFormatter().parse(date1);
    const data2 = new DateFormatter().parse(date2);

    if (data1 !== null && data2 !== null) {
      return new NgbDate(data1.year, data1.month, data1.day).before(new NgbDate(data2.year, data2.month, data2.day)) ||
        new NgbDate(data1.year, data1.month, data1.day).equals(new NgbDate(data2.year, data2.month, data2.day));
    }
    return true;
  }

  private resetFileUploader() {
    this.fileId = null;
    this.fileName = null;
  }

  downloadAutorizatieFile(autorizatie) {
    const fileId = autorizatie['file_id'];
    const fileName = autorizatie['file_name'];

    if (!fileId) {
      return;
    }

    this.helperDataService.downloadFile(fileId)
      .subscribe((response: any) => {
        const blob = new Blob([response]);
        this.helperDataService.simulateDownload(blob, fileName);
    });
  }
}
