<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="evaluareGeneticaService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changePageSize($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >

                <button
                  *ngIf="isSuperAdmin"
                  class="btn btn-primary btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openModalFisierIBNA()"
                >
                  <i class="mdi mdi-dna"></i>
                  Generează fișier IBNA
                </button>

                <button
                  *ngIf="isSuperAdmin"
                  class="btn btn-blue btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="importFisier()"
                >
                  <i class="fe-file-text"></i>
                  Import fișier Excel
                </button>

                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openModalCautare()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>

                <button
                  *ngIf="isAdmin || isOperator"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportCautare($event)"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele căutate"
                  placement="bottom"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>

              </div>
            </div>

          </div>

          <div
            class="text-info table-spinner"
            role="status"
            *ngIf="loadingDataSpinner"
          ></div>

          <!-- Table -->
          <div class="table-responsive">
            <table
              id="evaluare-genetica-table"
              class="table dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <ng-container *ngFor="let key of columns; let index = index">
                    <th
                      [ngbTooltip]="columnsDesc[key]['desc']"
                      placement="top"
                      container="body"
                    >
                      {{ columnsDesc[key]['abr'] }}
                    </th>
                  </ng-container>
                </tr>
              </thead>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let data_row of listaDate">
                  <ng-container *ngFor="let key of columns">
                    <td>
                      <ng-container [ngSwitch]="key">

                        <div *ngSwitchCase="'matricol'">
                          <a
                            [ngClass]="key"
                            [routerLink]="data_row['animal_link']"
                            target="_blank"
                          >
                            {{ data_row[key] }}
                          </a>

                          <span
                            [ngClass]="{
                              'box-mascul': data_row['sex'] === 'M',
                              'box-femela': data_row['sex'] === 'F'
                            }"
                          >{{ data_row['sex'] }}</span>
                          <span class="data_nastere">{{ data_row['data_nastere'] }}</span>
                        </div>

                        <div *ngSwitchCase="'exploatatie'">
                          <a
                            [ngClass]="key"
                            [ngbTooltip]="data_row[key]"
                            [routerLink]="data_row['exploatatie_link']"
                            target="_blank"
                          >
                            {{ data_row[key] }}
                          </a>
                        </div>

                        <div *ngSwitchCase="'parinti'">
                          <a
                            class="parinte"
                            [routerLink]="data_row['mama_link']"
                            target="_blank"
                          >
                            <span class="box-femela">F</span>
                            <span class="mama">{{ data_row['mama'] }}</span>
                          </a>

                          <a
                            class="parinte"
                            [routerLink]="data_row['tata_link']"
                            target="_blank"
                          >
                            <span class="box-mascul">M</span>
                            <span class="tata">{{ data_row['tata'] }}</span>
                          </a>
                        </div>

                        <div *ngSwitchDefault>
                          <div [ngClass]="{
                            'number': number.indexOf(key) != -1,
                            'percent': percent.indexOf(key) != -1
                          }">{{ data_row[key] }}</div>
                        </div>

                      </ng-container>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>

            <ngb-alert
              type="danger"
              class="text-center search-error"
              *ngIf="!loadingDataSpinner && evaluareGeneticaService.totalRecords === 0"
              [dismissible]="false"
            >
              Nu există înregistrări
            </ngb-alert>

          </div>
          <!-- End table -->

          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="evaluareGeneticaService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ evaluareGeneticaService.startIndex }} la
                {{ evaluareGeneticaService.endIndex }} din
                {{ evaluareGeneticaService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="evaluareGeneticaService.totalRecords"
                  [(page)]="evaluareGeneticaService.page"
                  [pageSize]="evaluareGeneticaService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changePageNumber($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>

          <p *ngFor="let fisier of fisiereInCurs">
            <span class="alert alert-info">În curs: {{fisier}}</span>
          </p>

          <p *ngFor="let fisier of fisiereFinale">
            <button class="btn btn-sm btn-danger mr-1" (click)="stergeFisier(fisier)" title="Șterge">
              <i class="mdi mdi-delete"></i>
            </button>

            {{ fisier }}

            <button class="btn btn-sm btn-info ml-1" (click)="downloadFisier(fisier)">
              Descarcă
            </button>
          </p>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #importFisierModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Import fișier Excel
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <label for="data_evaluarii">
          Data evaluării
        </label>
      </div>
      <div class="row ">
        <app-date-picker
          [selectedDate]="dataEvaluarii"
          (dateChanged)="dataEvaluarii = $event"
        ></app-date-picker>
      </div>

      <div class="row">
        <input
          #fileUpload
          type="file"
          accept=".xls,.xlsx"
          class="file-input"
          style="display: none"
          (change)="onFileSelected($event)"
        />
      </div>

      <div class="row mt-3 mb-2">
        <button
          class="btn btn-info"
          (click)="fileUpload.click()"
        >
          Alege fișierul pentru import
        </button>
      </div>

      <div *ngIf="fileToImport?.name" class="row mb-2">
        {{ fileToImport?.name }}
      </div>

      <div class="row h4 mt-3">
        Rescrie înregistrările existente pentru data aleasă?
      </div>

      <div class="row radio">
        <input
          type="radio"
          name="rescriere"
          id="rescriere_nu"
          [(ngModel)]="rescriere"
          value='NU'
          [checked]="true"
        />
        <label for="rescriere_nu" class="desc_rescriere">
          <span class="text_rescriere">NU</span>
          - Se vor importa doar înregistrările noi
        </label>
      </div>

      <div class="row radio">
        <input
          type="radio"
          name="rescriere"
          id="rescriere_da"
          class="mt-1"
          [(ngModel)]="rescriere"
          value='DA'
        />
        <label for="rescriere_da" class="desc_rescriere">
          <span class="text_rescriere">DA</span>
          - Se vor importa toate înregistrările, cele vechi se vor rescrie
        </label>
      </div>

      <div class="row mt-3">
        <button
          class="btn btn-blue"
          (click)="sendImportFiles()"
          [disabled]="importLoading"
        >
          Import
        </button>

        <span *ngIf="importLoading" class="btn text-info">Importul a pornit ...</span>
        <span class="btn text-danger">{{ importError }}</span>


      </div>

      <div class="row mt-3" *ngIf="this.importStats">
        înregistrări salvate: {{ this.importStats?.nr_salvate }}
      </div>

      <div class="row mt-1" *ngIf="this.importStats">
        înregistrări vechi: {{ this.importStats?.nr_inreg_vechi }}
      </div>

      <ng-container *ngIf="this.importStats?.animale_negasite">
        <div class="row mt-1 text-bold">Animale negasite:</div>
        <div *ngFor="let nrMatricol of this.importStats?.animale_negasite">
        {{ nrMatricol }}
        </div>
      </ng-container>

    </div>
  </div>
</ng-template>

<ng-template #cautareGenerareModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      <ng-container *ngIf="modalFisierIBNA">
        Generează fișier IBNA
      </ng-container>
      <ng-container *ngIf="modalCautare">
        Căutare Evaluare Genetică
      </ng-container>
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="fisierIBNAForm">

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="exploatatie_id">
                Exploatație
              </label>
              <ng-select
                [items]="exploatatii"
                bindLabel="viewLabel"
                bindValue="id"
                formControlName="exploatatie_id"
                [required]="true"
                placeholder="Alege exploatația"
                (search)="searchExploatatii($event)"
                (clear)="clearHoldings()"
              >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <div class="row">
                    <div class="col-md-12 col-lg-auto">
                      {{item.name}}
                      <span *ngIf="item.code" class="small mr-1"> - {{ item.code }}</span>
                      <span *ngIf="item.status != 1" class="badge bg-secondary">Inactiv</span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <small
                *ngIf="showHint"
                class="form-text text-muted ml-2"
              >
                <b>Introdu minim 5 caractere pentru a căuta</b>
              </small>
              <small
                *ngIf="selectedHoldingIdError"
                class="form-text text-danger ml-2"
              >
                <b>Alege exploatația</b>
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="data_nastere_inceput">
                      Data naștere început
                  </label>
                  <app-date-picker
                    [selectedDate]="fisierIBNAForm.controls.data_nastere_inceput.value"
                    (dateChanged)="fisierIBNAForm.controls.data_nastere_inceput.setValue($event)"
                    (change)="fisierIBNAForm.controls.data_nastere_inceput.setValue($event.target.value)">
                  </app-date-picker>
              </div>
          </div>

          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="data_nastere_sfarsit">
                      Data naștere sfârșit
                  </label>
                  <app-date-picker
                    [selectedDate]="fisierIBNAForm.controls.data_nastere_sfarsit.value"
                    (dateChanged)="fisierIBNAForm.controls.data_nastere_sfarsit.setValue($event)"
                    (change)="fisierIBNAForm.controls.data_nastere_sfarsit.setValue($event.target.value)">
                  </app-date-picker>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="form-group mb-3">
              <label for="status">
                Status
              </label>
              <ng-select formControlName="status" placeholder="Toate">
                <ng-option value='1'>Activ</ng-option>
                <ng-option value='0'>Inactiv</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="form-group mb-3">
              <label for="rasa">
                Rasă
              </label>
              <ng-select formControlName="rasa" placeholder="Toate">
                <ng-option value='Charolaise'>Charolaise</ng-option>
                <ng-option value='Limousine'>Limousine</ng-option>
                </ng-select>
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="form-group mb-3">
              <label for="sex">
                Sex
              </label>
              <ng-select formControlName="sex" placeholder="Toate">
                <ng-option value='1'>Mascul</ng-option>
                <ng-option value='2'>Femelă</ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="numarmatricol">
                Număr matricol
              </label>

              <input
                type="text"
                id="numarmatricol"
                formControlName="numarmatricol"
                placeholder="Adaugă numărul matricol"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="sectiune">
                Secțiune
              </label>
              <ng-select formControlName="sectiune" placeholder="Toate">
                <ng-option value="0">Neîncadrat</ng-option>
                <ng-option value="1">Principală</ng-option>
                <ng-option value="2">Suplimentară A</ng-option>
                <ng-option value="3">Suplimentară B</ng-option>
                <ng-option value="4">Suplimentară C</ng-option>
                <ng-option value="5">Suplimentară D</ng-option>
              </ng-select>
            </div>
          </div>

        </div>

        <div *ngIf="modalCautare" class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="start_date">
                Data evaluării
              </label>
              <span
                class="spinner-border avatar-xs text-info ml-2"
                *ngIf="!listaDataEvaluarii"
              ></span>

              <ng-select
                tabindex="8"
                [items]="listaDataEvaluarii"
                placeholder="Alege Data evaluării"
                formControlName="data_evaluarii"
                >
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row justify-content-sm-around">
          <button
            *ngIf="modalFisierIBNA"
            class="btn btn-sm btn-blue"
            (click)="genereazaFisierIBNA($event); modal('hide')"
          >
            Generează fișier IBNA
          </button>

          <button
            *ngIf="modalCautare"
            class="btn btn-sm btn-blue"
            (click)="cautareEvaluareGenetica(); modal('hide')"
          >
            Caută
          </button>

          <button class="btn btn-sm btn-warning" (click)="resetFields()">
            <i class="fe-delete"></i>
            Resetează câmpurile
          </button>

          <button
            *ngIf="modalCautare"
            class="btn btn-sm btn-danger"
            (click)="resetSearch(); modal('Cross click')"
          >
            Resetează căutarea
          </button>
        </div>

      </form>

    </div>
  </div>
</ng-template>
