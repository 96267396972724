<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-pattern">
          <div class="card-body p-4">
            <!-- Logo -->
            <div class="text-center w-75 m-auto">
              <a routerLink="/account/login">
                <img src="assets/images/logo.png" />
              </a>
              <p class="mb-4 mt-3">
                Introduceți codul de exploatație iar noi vă trimitem un email cu instrucțiunile de resetare a parolei
              </p>
            </div>


            <form
              class="needs-validation"
              name="forgotPassword"
              [formGroup]="forgotPassword"
              (ngSubmit)="onSubmit()"
              novalidate
            >

              <app-ui-preloader [display]="loading"></app-ui-preloader>

              <ngb-alert
                type="danger"
                *ngIf="error"
                [dismissible]="false"
              >
                {{ error }}
              </ngb-alert>

              <ngb-alert
                type="success"
                *ngIf="success"
                [dismissible]="false"
              >
                V-am trimis un email pe adresa {{ success.user_email }}
                <br>Aici găsiți un link unde vă puteți schimba parola.
              </ngb-alert>

              <div class="form-group mb-3" *ngIf="!success">
                <label for="cod_exploatatie">Codul exploatației</label>

                <input
                  type="cod_exploatatie"
                  formControlName="cod_exploatatie"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.cod_exploatatie.errors }" id="cod_exploatatie"
                />

                <div
                  *ngIf="submitted && f.cod_exploatatie.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.cod_exploatatie.errors.required">
                    Codul exploatației este obligatorie
                  </div>
                  <div *ngIf="f.cod_exploatatie.errors.cod_exploatatie">
                    Codul exploatației introdus trebuie sa fie valid
                  </div>
                </div>
              </div>

              <div class="form-group mb-0 text-center" *ngIf="!success">
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                >
                  Trimite
                </button>
              </div>
            </form>

          </div> <!-- end card-body-->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-white-50">
              Înapoi la
              <a routerLink="/login" class="text-white ml-1">
                <b>Autentificare</b>
              </a>
            </p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
  Asociația Crescătorilor de Bovine pentru Carne din România
  <a href="https://acbcr.ro" class="footer-link">acbcr.ro</a>
</footer>
