<div class="card" id="administrator" [formGroup]="holdingForm">
  <h3 class="card-header">Administrator exploatație</h3>
  <div class="card-body">
    <div class="row">

      <div class="col-sm-12 col-md-6 col-lg-9">
        <div class="form-group mb-3">
          <label for="admin_id">
            Selecție administrator
          </label>

          <ng-select
            [items]="admins"
            bindLabel="name"
            bindValue="id"
            formControlName="administrator"
            placeholder="Selectează administratorul"
            (change)="changeHoldingAdmin($event)"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3 align-center">
        <button
          class="btn btn-success"
          type="button"
          (click)="openAdminModal('add')"
        >
          Creează
        </button>
        <button
          class="btn btn-secondary"
          type="button"
          (click)="openAdminModal('edit')"
          [disabled]="f.administrator.value === null"
        >
          Actualizează
        </button>
      </div>

    </div>
  </div>
</div>

<app-add-update-holding-admin
  [open]="open"
  [modalType]="modalType"
  (notifyClose)="closeHoldingAdminModal($event)"
  [selectedAdmin]="selectedAdmin"
>
</app-add-update-holding-admin>
