import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../common/services/auth.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from '../../../common/state/app.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebsocketService } from '../../../common/services/websocket.service';
import { HoldingsTableService } from '../../../common/services/holdings-table.service';
import { SetSelectedHoldingData } from '../../../common/state/actions/holdings.actions';
import { getCurrentRoute } from '../../../common/state/reducers/route.reducers';
import { NotificationInfoService } from '../../../common/services/notification-info.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { DocumentHelper } from 'src/app/modules/acbcr/components/common/helper/document-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  notificationItems = [];
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };

  openMobileMenu: boolean;
  connectionOpened = false;
  previousRoute = '';
  currentRoute = '';
  necitite = 0;
  username;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<ApplicationState>,
    private websocketService: WebsocketService,
    private notificationService: NotificationInfoService,
    private holdingService: HoldingsTableService,
    private helperDataService: HelperDataService,
    public errorService: NotificationErrorService,
  ) {}

  ngOnInit() {
    this.username = UIHelper.username();

    if (!this.connectionOpened) {
      this.connectionOpened = true;
      this.websocketService.openConnection();
    }

    this.websocketService.messagesReceived.subscribe(
      response => {
        if (response) {
          this.necitite = response['necitite'];
          this.notificationItems = response['notificari'].map((item) => {
            item.link_documente = DocumentHelper.link_documente(item);
            return item;
          });
        }
      }
    )

    // Set Farmer Holding (Exploatatie)
    if (UIHelper.isFermier()) {
      this.store.pipe(select(getSelectedHolding))
      .subscribe((response: any) => {
        if (! response) {
          this.holdingService.getHoldingAPI({})
            .subscribe(response => {
              if (response?.result) {
                let exploatatie = response.result[0];
                this.store.dispatch(new SetSelectedHoldingData(exploatatie));
              }
          });
        }
      });
    }

    this.store.pipe(select(getCurrentRoute))
    .pipe(takeUntil(this.destroy$))
    .subscribe(route => {
      this.previousRoute = this.currentRoute;
      this.currentRoute = route;
    });

    this.openMobileMenu = false;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onClickNotification(notification) {
    if (notification['citit']) {
      return;
    }

    let req_params = {
      id: notification['id']
    }

    this.notificationService.marcheazaCitit(req_params)
      .subscribe({
        next: () => {
          notification['citit'] = true;
          this.necitite -= 1;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  onClickClopotel() {
    if (0 == this.necitite) {
      return;
    }

    this.notificationService.clopotel()
      .subscribe({
        next: () => {
          this.necitite = 0;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  downloadNotificari() {
    this.notificationService.downloadAPI()
      .subscribe({
        next: (response) => {
          let now = UIHelper.now();
          let fisier = `Notificari_${now}.xls`;
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (this.connectionOpened) {
      this.websocketService.closeConnection();
      this.connectionOpened = false;
    }

    this.authService.logout();
    this.router.navigateByUrl('/login');
    this.notificationItems = [];
    localStorage.removeItem('link-exploatatie');
  }
}
