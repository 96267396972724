import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  ViewEncapsulation
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmActionModalComponent implements OnInit, OnChanges {
  @ViewChild('cancelDeleteModal') cancelDeleteModal: ElementRef;

  @Input() open = false;
  @Input() type: string;
  @Input() text: string;
  @Input() deleteAll: boolean;

  @Output() confirm = new EventEmitter();

  constructor(
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalService.open(this.cancelDeleteModal, { windowClass: 'table-search-modal', scrollable: false, backdrop: 'static' });
    }
  }

  notifyCloseModal(event?) {
    this.confirm.emit(event);
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

}


