<div class="card">
  <div class="card-header">
    <div class="float-left card-title">
      <span class="click-title">
        Număr matricol: {{animalData !== undefined ? animalData.numarmatricol : ''}}
        <!-- <i [class]="iconClass"></i> -->
      </span>
    </div>
    <div class="float-right">
      <a
        class="btn btn-success"
        [routerLink]="previousRoute"
        ngbTooltip="Pagina anterioară"
        placement="left"
      >
        <i class="fe-arrow-left"></i>
        Înapoi
      </a>
    </div>
  </div>
  <div class="card-body">

    <table
      class="table table-bordered table-striped"
      *ngIf="displayInfoTable"
      [ngClass]="{ 'disabled': loadingDataSpinner }"
    >
      <thead>
        <tr>
          <th scope="column" class="table-header"  colspan="2">
            <div class="row custom-animal-row">
              <div class="col-xs-12 col-sm-6 col-md-6 custom-animal-col">
                <h3>Informații Animal</h3>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 text-right custom-animal-col">
                <div class="btn-group">
                  <button
                    *ngIf="isAdmin || isOperator || isFermier; else view"
                    type="button"
                    class="btn btn-success"
                    (click)="openEditAnimalModal('edit')"
                  >
                    <span class="btn-label"><i class="mdi mdi-square-edit-outline"></i></span>Editează
                  </button>
                  <ng-template #view>
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="openEditAnimalModal('view')"
                    >
                      <span class="btn-label"><i class="mdi mdi-eye-outline"></i></span>Vizualizează
                    </button>
                  </ng-template>

                  <button
                    *ngIf="isAdmin || isOperator"
                    type="button"
                    class="btn btn-info"
                    (click)="generateFisaAnimal(animalData.id)"
                  >
                    Fișă animal
                  </button>

                  <button
                    *ngIf="isAdmin || isOperator"
                    type="button"
                    class="btn btn-warning"
                    (click)="descarcaIstoric(animalData.id)"
                  >
                    Descarcă istoric
                  </button>
                </div>
              </div>
            </div>

          </th>
        </tr>
      </thead>
      <div
        class="spinner-border text-info table-spinner avatar-lg"
        role="status"
        *ngIf="loadingDataSpinner">
      </div>
      <tbody>
        <tr>
          <th scope="row">Status</th>
          <td>
            <span>
              {{
                animalData !== undefined ?
                (animalData.status === 1 ? 'Activ' : 'Inactiv'): ''
              }}
            </span>
          </td>
        </tr>
        <tr>
          <th scope="row">Contractat</th>
          <td>
            <span>
              {{
                animalData !== undefined ?
                (animalData.contractat === 1 ? 'Contractat' : 'Necontractat'): ''
              }}
            </span>
          </td>
        </tr>
        <tr>
          <th scope="row">Sex</th>
          <td>
            <span>
              {{
                animalData !== undefined ?
                (animalData.sex === 1 ? 'Mascul' : 'Femelă') : ''
              }}
            </span>
          </td>
        </tr>
        <tr>
          <th scope="row">Data nașterii</th>
          <td>
            {{
              animalData !== undefined ?
              (animalData !== undefined ? animalData.datanastere : '') : ''
            }}
          </td>
        </tr>
        <tr>
          <th scope="row">Produs prin</th>
          <td>{{insamantareLabel}}</td>
        </tr>
        <tr *ngIf="animalData && animalData.sex != 1">
          <th scope="row">Categorie Femelă</th>
          <td>{{femeleCategoryLabel}}</td>
        </tr>
        <tr>
          <th scope="row">Culoare</th>
          <td>{{colorLabel}}</td>
        </tr>
        <tr>
          <th scope="row">Proprietar</th>
          <td>{{ownerLabel}}</td>
        </tr>
      </tbody>
    </table>

    <div class="row">
      <div class="col-8">
        <img src="{{ defaultAnimalImage }}" class="animalImage">
      </div>
      <div class="col-4">
        <div class="row" *ngFor="let image of otherAnimalImages">
          <img src="{{ image }}" class="animalImage">
        </div>
      </div>
    </div>

  </div>
</div>

<app-add-update-animal
  [open]="openAddEditModal"
  (notifyClose)="closeAddUpdateModal($event)"
  [modalType]="modalType"
  (callbackResult)="getUpdatedResponse($event)"
  [animalData]="animalData"
>
</app-add-update-animal>
