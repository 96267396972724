<app-incasari-table
  [incasari]="tables$ | async"
  [incasariLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (tableCallback)="getTableCallback($event)"
></app-incasari-table>
