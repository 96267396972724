<form [formGroup]="performanceSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6" *ngIf="!isFermier && !isOjz">
      <div class="form-group mb-3">
        <label for="countyid">
          Județul
        </label>

        <ng-select
          [items]="cities"
          bindLabel="name"
          bindValue="id"
          placeholder="Selectează județul"
          formControlName="countyid"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isFermier">
      <div class="form-group mb-3">
        <label for="comp_name">
          Denumirea exploatației
        </label>

        <input
          type="text"
          id="comp_name"
          formControlName="comp_name"
          placeholder="Adaugă denumirea exploatației"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="numar_matricol">
          Număr matricol
        </label>

        <input
          type="text"
          id="numar_matricol"
          formControlName="numar_matricol"
          placeholder="Adaugă numărul matricol"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <label for="rasa">
        Rasă
      </label>
      <ng-select
        [items]="raseAnimal"
        bindLabel="name"
        bindValue="shortname"
        placeholder="Alege rasa"
        placeholder="Caută după rasă"
        formControlName="rasa"
      >
      </ng-select>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="sectiune">
          Secțiune
        </label>
        <ng-select formControlName="sectiune" placeholder="Toate">
          <ng-option value="0">Neîncadrat</ng-option>
          <ng-option value="1">Principală</ng-option>
          <ng-option value="2">Suplimentară A</ng-option>
          <ng-option value="3">Suplimentară B</ng-option>
          <ng-option value="4">Suplimentară C</ng-option>
          <ng-option value="5">Suplimentară D</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="rg_clasa">
          Clasă
        </label>
        <ng-select
          formControlName="rg_clasa"
          placeholder="Selectează o secțiune"
        >
          <ng-option value='Mascul'>Mascul</ng-option>
          <ng-option value='Femela'>Femelă</ng-option>
          <ng-option value='Indiferent'>Indiferent</ng-option>
        </ng-select>

      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="apreciere_fenotipica">
          Apreciere fenotipică
        </label>

        <div
          class="radio mb-2 ml-2 custom-radio-group"
        >
          <input
            type="radio"
            name="apreciere_fenotipica"
            id="apreciere_fenotipica1"
            value="da"
            formControlName="apreciere_fenotipica"
          />
          <label for="apreciere_fenotipica">
            Da
          </label>

          <input
            type="radio"
            name="apreciere_fenotipica"
            id="apreciere_fenotipica2"
            value="nu"
            class="ml-5"
            formControlName="apreciere_fenotipica"
          />
          <label for="apreciere_fenotipica">
            Nu
          </label>
        </div>

      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="status">
          Status
        </label>

        <div
          class="radio mb-2 ml-2 custom-radio-group"
        >
          <input
            type="radio"
            name="status"
            id="status1"
            value="True"
            formControlName="status"
          />
          <label for="status">
            Activ
          </label>

          <input
            type="radio"
            name="status"
            id="status2"
            value="False"
            class="ml-5"
            formControlName="status"
          />
          <label for="status">
            Inactiv
          </label>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
