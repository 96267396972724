import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { FacturiTableService } from '../../../../common/services/facturi-table.service';

@Component({
  selector: 'app-facturi',
  templateUrl: './facturi.component.html',
  styleUrls: ['./facturi.component.scss'],
  providers: [FacturiTableService, DecimalPipe]
})
export class FacturiComponent implements OnInit {

  tables$: Observable<any[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  objToAPI = {
    page_nr: 1,
    page_size: 10
  };

  constructor(
    public facturiTableService: FacturiTableService,
  ) {
    this.tables$ = facturiTableService.tables$;
    this.total$ = facturiTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }


  getTableData() {
    this.loadingDataSpinner = true;
    setTimeout(() => {
      this.facturiTableService
        .getFacturiAPI(this.objToAPI).subscribe((response) => {
          // Hide spinner, after 1 second
          this.loadingDataSpinner = false;
        }, error => {
          this.loadingDataSpinner = false;
        });
    }, 1500);
  }

  filterSearchData(event) {
    // Compose the object what will be sent to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_nr = event;
    this.getTableData();
  }

  getTableCallback(event) {
    this.getTableData();
  }

}

