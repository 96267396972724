import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-form-block',
  templateUrl: './contact-form-block.component.html',
  styleUrls: ['./contact-form-block.component.scss']
})
export class ContactFormBlockComponent implements OnInit {

  @Input() holdingForm: UntypedFormGroup;
  @Input() submitted: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  get f() { return this.holdingForm.controls; }

  formatPhoneNumber(event) {
    event.target.value = event.target.value.replace(/^(\+)|\D/g, '$1');
  }

}
