<ng-template #searchModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Caută în registru genealogic
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="regGenSearchForm">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="exploatatie_id">
                Exploatație
              </label>
              <ng-select
                [items]="exploatatii"
                bindLabel="viewLabel"
                bindValue="id"
                formControlName="exploatatie_id"
                [required]="true"
                placeholder="Alege exploatația"
                (search)="searchExploatatii($event)"
              >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <div class="row">
                    <div class="col-md-12 col-lg-auto">
                      {{item.name}}
                      <span *ngIf="item.code" class="small mr-1"> - {{ item.code }}</span>
                      <span *ngIf="item.status != 1" class="badge bg-secondary">Inactiv</span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <small
                *ngIf="showHint"
                class="form-text text-muted ml-2"
              >
                <b>Introdu minim 5 caractere pentru a căuta</b>
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="rasa">
                Rasă
              </label>
              <ng-select formControlName="rasa" placeholder="Toate">
                <ng-option value='1'>Charolaise</ng-option>
                <ng-option value='2'>Limousine</ng-option>
                </ng-select>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="sex">
                Sex
              </label>
              <ng-select formControlName="sex" placeholder="Toate">
                <ng-option value='1'>Mascul</ng-option>
                <ng-option value='2'>Femelă</ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="numarmatricol">
                Număr matricol
              </label>

              <input
                type="text"
                id="numarmatricol"
                formControlName="numarmatricol"
                placeholder="Adaugă numărul matricol"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="sectiune">
                Secțiune
              </label>
              <ng-select formControlName="sectiune" placeholder="Toate">
                <ng-option value="0">Neîncadrat</ng-option>
                <ng-option value="1">Principală</ng-option>
                <ng-option value="2">Suplimentară A</ng-option>
                <ng-option value="3">Suplimentară B</ng-option>
                <ng-option value="4">Suplimentară C</ng-option>
                <ng-option value="5">Suplimentară D</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="eligibil">
                Eligibilitate
              </label>
              <ng-select
                formControlName="eligibil"
                placeholder="Toate"
              >
                <ng-option value="1">Eligibil</ng-option>
                <ng-option value="0">Neeligibil</ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
            <div class="btn-group">
              <button
                type="submit"
                class="btn btn-sm btn-blue"
                (click)="onSubmitted(); modal('Cross click')"
              >
                <i class="fe-search"></i>
                Caută
              </button>
              <button
                type="reset"
                class="btn btn-sm btn-warning"
              >
                <i class="fe-delete"></i>
                Resetează câmpurile
              </button>
            </div>
          </div>

          <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              (click)="resetSearch(); modal('Cross click')"
            >
              Resetează căutarea
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
