<form [formGroup]="holdingSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="comp_name">
          Denumirea exploatației
        </label>

        <input
          type="text"
          id="comp_name"
          formControlName="comp_name"
          placeholder="Adaugă denumirea exploatației"
          class="form-control" />
      </div>
    </div>

    <div
      *ngIf="!isAutoritateJudeteana"
      class="col-sm-12 col-md-6"
    >
      <div class="form-group mb-3">
        <label for="comp_status">
          Stare
        </label>
        <ng-select
          formControlName="comp_status"
          placeholder="Selectează starea"
        >
          <ng-option value=1>Activ</ng-option>
          <ng-option value=0>Inactiv</ng-option>
          <ng-option value=-1>În așteptare</ng-option>
          <ng-option value=5>Import</ng-option>
        </ng-select>

      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="comp_code">
          Codul exploatației
        </label>

        <input
          #holdingCodeInput
          type="text"
          id="comp_code"
          formControlName="comp_code"
          placeholder="Adaugă codul exploatației"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isAutoritateJudeteana && !isContabil">
      <div class="form-group mb-3">
        <label for="comp_cui">
          CUI/CNP
        </label>

        <input
          type="text"
          id="comp_cui"
          formControlName="comp_cui"
          placeholder="Adaugă CUI/CNP"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12">
      <div class="form-group mb-3">
        <label for="rase">
          Rase
        </label>

        <ng-select
          [items]="rase"
          bindLabel="name"
          [multiple]="true"
          formControlName="comp_races"
          [closeOnSelect]="false"
          [hideSelected]="true"
          placeholder="Selectează rasele"
          bindValue="shortname"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isOjz">
      <div class="form-group mb-3">
        <label for="comp_jud">
          Județul
        </label>

         <ng-select
            [items]="cities"
            bindLabel="name"
            placeholder="Selectează județul"
            formControlName="comp_county"
            (change)="getSelectedCity($event)"
          >
          </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isOjz">
      <div class="form-group mb-3">
        <label for="comp_settl">
          Localitatea
        </label>

        <ng-select
          [items]="smallCities"
          bindLabel="name"
          placeholder="Selectează localitatea"
          formControlName="comp_settl"
          notFoundText="Selectați un județ"
          #smallCitiesDropDown
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button class="btn btn-sm btn-warning" (click)="resetFields()">
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
