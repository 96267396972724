<app-rapoarte-incasari-table
  [rapoarteIncasari]="tables$ | async"
  [rapoarteIncasariLength]="total$ | async"
  [totalRestant]="totalRestant"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (rapoarteIncasariTableCallback)="getTableData()"
></app-rapoarte-incasari-table>
