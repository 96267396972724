<ng-template #addEditUtilizatorModal let-modal="close('Cross click')">
  <div class="modal-header" [ngSwitch]="modalType">
    <h3 class="modal-title" *ngSwitchCase="'add'">
      Utilizator nou
    </h3>

    <h3 class="modal-title" *ngSwitchCase="'edit'">
      Editează utilizator
    </h3>

    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="(modal); notifyCloseModal()"
        autofocus="false"
      >
        Anulează
      </button>
      <button
        class="btn btn-sm btn-success"
        (click)="onSubmitted()"
        [ngSwitch]="modalType"
      >
        <span *ngSwitchCase="'edit'">
          Actualizează utilizator
        </span>
        <span *ngSwitchCase="'add'">
          Salvează noul utilizator
        </span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="utilizatorForm" autocomplete="off">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="username">
                Username*
              </label>

              <input
                type="text"
                formControlName="username"
                placeholder="Adaugă username-ul utilizatorului"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    (submitted && f.username.errors) || duplicatedUser
                }"
                [readOnly]="!isSuperAdmin && modalType === 'edit'"
                autocomplete="off"
              />
              <div
                *ngIf="submitted && f.username.errors"
                class="invalid-feedback"
                [ngClass]="{
                  'd-block': submitted && f.username.errors
                }"
              >
                <div *ngIf="f.username.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
              <div *ngIf="duplicatedUser" class="invalid-feedback">
                Username deja existent
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="is_active">
                Stare utilizator
              </label>
              <ng-select
                formControlName="is_active"
                [clearable]="false"
                [dropdownPosition]="'bottom'"
              >
                <ng-option [value]="false">Inactiv</ng-option>
                <ng-option [value]="true">Activ</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="last_name">
                Nume
              </label>

              <input
                type="text"
                formControlName="last_name"
                placeholder="Adaugă numele utilizatorului"
                class="form-control"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="last_name">
                Prenume
              </label>

              <input
                type="text"
                formControlName="first_name"
                placeholder="Adaugă prenumele utilizatorului"
                class="form-control"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="email">
                Email*
              </label>

              <input
                type="text"
                formControlName="email"
                placeholder="Adaugă email-ul"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                autocomplete="off"
              />
              <div
                *ngIf="submitted && f.email.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.email.errors }"
              >
                <div *ngIf="f.email.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
                <div *ngIf="f.email.errors.email">
                  Adresa de email introdusă trebuie sa fie validă
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="phone_number">
                Număr de telefon
              </label>

              <input
                type="text"
                formControlName="phone_number"
                placeholder="Adaugă numărul de telefon"
                class="form-control"
                autocomplete="off"
              />
            </div>
          </div>

          <div
            class="col-sm-12 col-md-6"
            *ngIf="
              modalType === 'add' || utilizator && utilizator.user_type !== 1
            "
          >
            <div class="form-group mb-3">
              <label for="user_type">
                Tip utilizator*
              </label>
              <ng-select
                placeholder="Alege tipul utilizatorului"
                formControlName="user_type"
                [clearable]="false"
                [dropdownPosition]="'top'"
                [ngClass]="{ 'is-invalid': submitted && f.user_type.errors }"
                (change)="userTypeChanged($event)"
              >
                <ng-option [value]="2" *ngIf="isAdmin">Admin ACBCR</ng-option>
                <ng-option [value]="3">Operator</ng-option>
                <ng-option [value]="4">Fermier</ng-option>
                <ng-option [value]="5">OJZ</ng-option>
                <ng-option [value]="6">ANZ</ng-option>
                <ng-option [value]="7">Contabil</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && f.user_type.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.user_type.errors }"
              >
                <div *ngIf="f.user_type.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-sm-12 col-md-6"
            *ngIf="this.f.user_type.value === 5"
          >
            <div class="form-group mb-3">
              <label for="user_type">
                Județ*
              </label>
              <ng-select
                placeholder="Alege județul oficiului"
                formControlName="countyid"
                [clearable]="false"
                [items]="cities"
                bindValue="id"
                bindLabel="name"
                [dropdownPosition]="'top'"
                [ngClass]="{ 'is-invalid': submitted && f.countyid.errors }"
              >
              </ng-select>
              <div
                *ngIf="submitted && f.countyid.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.countyid.errors }"
              >
                <div *ngIf="f.countyid.errors.required">
                  Alegerea unei opțiuni este obligatorie.
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
