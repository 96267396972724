import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortableTable } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})
export class CertificateZootehniceService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-zootehnic/`,
      req_params
    ).pipe(
        map((response: any) => {
          this.paginate();

          return response;
        }));
  }

  listAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-zootehnic/list/`,
      req_params
    ).pipe(
        map((response: any) => {
          this.totalRecords = response.count;
          this.paginate();

          return response;
        }));
  }

  stergeAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-zootehnic/sterge/`,
      req_params
    ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  elibereazaAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-zootehnic/elibereaza/`,
      req_params
    ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  anuleazaAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-zootehnic/anuleaza/`,
      req_params
    ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-zootehnic/download/`,
        req_params,
        {
          responseType: 'blob'
        }
      );
  }

  exportAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-zootehnic/export/`,
        req_params,
        {
          responseType: 'blob'
        }
      );
  }

  private paginate(): void {
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.totalRecords) {
      this._state.endIndex = this.totalRecords;
    }
  }
}
