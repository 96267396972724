import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToBackendFormat, convertDateToDisplayFormat } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})
export class FacturiTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  facturi: any[];
  facturiLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.facturi, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getFacturiAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/contabilitate/listFacturi/`,
      {
        ...obj,
        data_inceput: convertDateToBackendFormat(obj.data_inceput),
        data_sfarsit: convertDateToBackendFormat(obj.data_sfarsit)
      }
    ).pipe(map((response: any) => {
      this.facturi = response.result;
      this.facturiLength = response.count;

      this.facturi.map((factura) => {
        factura.data = convertDateToDisplayFormat(factura.data);
        factura.data_activitati = convertDateToDisplayFormat(factura.data_activitati);
        factura.diferentaZile = this.checkDateInterval(convertDateToBackendFormat(factura.data));
      });

      this.paginate();
      this.sortEvent();
      this._total$.next(this.facturiLength);

      return response;
    }));
  }

  getFacturiForEmail(obj) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/listFacturi/`,
      {
        ...obj,
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  private checkDateInterval(data) {
    const date1 = new Date();
    const date2 = new Date(data);
    const daysDiff = Math.floor(
      (Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) -
        Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    return daysDiff;
  }

  getFacturaAPI(facturaId) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/vizualizareFactura/`,
      { id : facturaId }
      ).pipe(map((response: any) => {
        response.result.map(factura => {
          factura.data = convertDateToDisplayFormat(factura.data);
          factura.data_activitati = convertDateToDisplayFormat(factura.data_activitati);
        });

        return response;
      }));
  }

  getAcbcrRacesAPI() {
    return this.http
      .get(
        `${this.baseUrl}/acbcr-race/`
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  saveFacturaAPI(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/addFacturi/`,
        {
          ...obj,
          data: convertDateToBackendFormat(obj.data),
          data_activitati: convertDateToBackendFormat(obj.data_activitati)
         }
      ).pipe(map((response: any) => {
        return response;
      }, errors => {
        return errors;
      }));
  }

  expediazaEmail(id) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/sendEmailFacturi/`,
        { id }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  changeStatusFacturi(id, status) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/changeStatusFacturi/`,
        { factura_id: id, status }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  valideazaFacturaAPI(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/addFacturi/`,
        {
          ...obj,
          data: convertDateToBackendFormat(obj.data),
          data_activitati: convertDateToBackendFormat(obj.data_activitati),
          status: 1
         }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getFacturaSerieNumar() {
    return this.http
      .get(
        `${this.baseUrl}/contabilitate/preserieFactura/`
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  respingeSubventie(id, subventie) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/changeStatusFacturi/`,
        { factura_id: id, subventie_respinsa: subventie }
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  generareFacturi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/startFacturare/`,
        {
          ...obj,
          data: convertDateToBackendFormat(obj.data),
          data_activitate: convertDateToBackendFormat(obj.data_activitate)
        },
        {
          responseType: 'blob',
          observe: 'response'
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  tiparesteFactura(id) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/pdfFacturi/`,
      { id },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/exportXlsFacturi/`,
      {
        ...obj
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  memoriuTehnic(obj) {
    return this.http
    .post(
      `${this.baseUrl}/contabilitate/memoriuTehnic/`,
      {
        ...obj
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.facturiLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.facturiLength) {
      this._state.endIndex = this.facturiLength;
    }
  }

}
