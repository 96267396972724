import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root'
})
export class ContabilitateSettingsService {
  private baseUrl = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  getUMSettings() {
    return this.http
      .get(
        `${this.baseUrl}/contabilitate/UM/list/`,
        auth.options
      ).pipe(map((response: any) => {
        return response.result;
      }));
  }

  getCriteriiSettings() {
    return this.http
      .get(
        `${this.baseUrl}/contabilitate/listCriteriiCant/`,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getActivitiesSettings(tipFactura) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/listActivitati/`,
        { tip_factura : tipFactura },
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getServicesSettings(activitateId) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/listServicii/`, { activitate_id: activitateId },
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  addUM(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/UM/add/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  addActivitate(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/updateActivitati/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }, errors => {
        return errors;
      }));
  }

  addServiciu(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/updateServicii/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }, errors => {
        return errors;
      }));
  }

  updateUM(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/UM/add/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  updateActivitate(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/updateActivitati/`, { ...obj,  activitate_id: obj.id},
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  updateServiciu(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/updateServicii/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  deleteUM(umId) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/UM/delete/`, {id: umId},
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }, errors => {
        return errors;
      }));
  }

  deleteActivitate(id) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/delActivitati/`, {activitate_id: id},
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }, errors => {
        return errors;
      }));
  }

  deleteServiciu(id) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/delServicii/`, {serviciu_id: id},
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }, errors => {
        return errors;
      }));
  }

  getFacturiSettings(tipFactura) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/setari/facturi-get/`, {tip: tipFactura},
        auth.options
      ).pipe(map((response: any) => {
        return response.result[0];
      }));
  }

  updateFacturi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/setari/facturi-save/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getChitanteSettings() {
    return this.http
      .get(
        `${this.baseUrl}/contabilitate/setari/chitante/`,
        auth.options
      ).pipe(map((response: any) => {
        return response.result[0];
      }));
  }

  updateChitante(obj) {
    return this.http
      .post(
        `${this.baseUrl}/contabilitate/setari/chitante/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

}
