<div class="card">
  <h3 class="card-header" *ngIf="fatareModalType === addFatare; else editTitle">
    Fătare nouă
  </h3>

  <ng-template #editTitle>
    <h3 class="card-header">
      {{ isAutoritateJudeteana || isContabil ? "Vizualizează fătarea" : "Editează fătarea" }}
    </h3>
  </ng-template>

  <div
    class="card-body"
    [ngClass]="{ 'disabled': loadingSpinner }"
  >
    <div class="container-fluid">
      <form [formGroup]="birthForm">
        <div class="row">
          <div [ngClass]="{'col-sm-12 col-lg-12': !gemelara, 'col-sm-9 col-lg-9': gemelara}">
            <div class="form-group mb-3">
              <label for="tipfatare">
                Rezultat*
              </label>
              <ng-select
                (change)="selectTipFatare($event)"
                formControlName="tipfatare"
                [clearable]="false"
                [ngClass]="{
                  'is-invalid': submitted && form.tipfatare.errors,
                  disabled: fatareModalType === editFatare
                }"
              >
                <ng-option value="1">Fătare normală</ng-option>
                <ng-option value="2">Produs mort</ng-option>
                <ng-option value="3">Avort</ng-option>
                <ng-option value="4">Pierdere gestație</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && form.tipfatare.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && form.tipfatare.errors }"
              >
                <div *ngIf="form.tipfatare.errors.required">
                  Selectarea unei opțiuni este obligatorie.
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="gemelara"
            [ngClass]="{'col-sm-3 col-lg-3 mt-4': gemelara}"
          >
             <label for="gemelara" class="mr-2">
               Gemelara
              </label>
              <input
                type="checkbox"
                name="gemelara"
                [checked]="gemelara"
                [disabled]="gemelara"
                id="singleCheckbox1"
              />
          </div>


          <div class="col-sm-6 col-lg-6">
            <div class="form-group mb-3">
              <label for="data_nasterii">
                Dată fătare*
              </label>

              <app-date-picker
                [submitted]="submitted"
                [required]="true"
                [selectedDate]="birthForm?.controls.data_nasterii.value"
                (dateChanged)="onDataNasteriiChanged($event)"
                [disabled]="birthForm?.controls.data_nasterii.disabled"
              >
              </app-date-picker>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6" *ngIf="childInfoDisplay">
            <div class="form-group mb-3">
              <label for="data_intrarii_exploatatie">
                Dată intrare exploatație
              </label>

              <app-date-picker
                [submitted]="submitted"
                [selectedDate]="
                  birthForm?.controls.data_intrarii_exploatatie.value
                "
                (dateChanged)="onDataIntrareExploatatieChanged($event)"
                [disabled]="birthForm?.controls.data_intrarii_exploatatie.disabled"
              >
              </app-date-picker>
              <small class="form-text text-muted" *ngIf="showDateErrors">
                <div class="mb-2">
                  <span style="color: red;">Data de intrare în exploatație e mai mică decât data nașterii.</span>
                </div>
              </small>
            </div>
          </div>

          <div class="col-12 separator mb-3"></div>

          <div class="col-sm-6 col-lg-6">
            <div class="form-group">
              <label for="matricolfemela">
                Matricol mamă*
              </label>

              <ng-select
                placeholder="Introduceți matricol mamă"
                formControlName="matricolfemela"
                [items]="femele"
                [clearable]="false"
                bindLabel="numarmatricol"
                [ngClass]="{
                  disabled: !isSuperAdmin && form?.are_evaluare_genetica.value,
                  'is-invalid': submitted && form.matricolfemela.errors
                }"
                (change)="changeInfoMother($event)"
                (input)="searchMother($event)">
              </ng-select>
              <div
                *ngIf="submitted && form.matricolfemela.errors"
                class="invalid-feedback"
                [ngClass]="{
                  'd-block': submitted && form.matricolfemela.errors
                }"
              >
                <div *ngIf="form.matricolfemela.errors.required">
                  Selectarea unei opțiuni este obligatorie.
                </div>
              </div>

              <ngb-alert
                *ngIf="restrictii?.length > 0"
                type="danger"
                class="mt-2"
                [dismissible]="false"
              >
                <ul class="col-sm-12 mb-0">
                  <li *ngFor="let restrictie of restrictii">{{ restrictie }}</li>
                </ul>
              </ngb-alert>

            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="form-group">
              <label for="rang">
                Rang Fătare
              </label>

              <input
                type="number"
                min="1"
                max="40"
                class="form-control"
                placeholder="Exemplu: 1"
                formControlName="rangfatare"
                (change)="rangFatareChange($event.target.value)"
              />
              <small class="form-text text-muted">
                  <b>Rang Fătare:</b>
                  <span> - la a câta fătare este vaca</span>
              </small>

              <ngb-alert
                *ngIf="warningRangFatare.length > 0"
                type="warning"
                class="mt-2"
                [dismissible]="false"
              >
                <ul class="col-sm-12 mb-0">
                  <li *ngFor="let warning of warningRangFatare">{{ warning }}</li>
                </ul>
              </ngb-alert>

            </div>
          </div>

          <div class="col-12 mb-3">
            <label class="mb-1">Monta</label>

            <table class="monte">
              <thead>
                <th></th>
                <th>Data</th>
                <th>Taur</th>
                <th>Status</th>
                <th>Perioadă gestație</th>
                <th>Condiții nerespectate</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let monta of monte; let index=index"
                  [ngClass]="{
                    disabled: !(isSuperAdmin
                      || ((isAdmin || isOperator || isFermier) && monta.errors.length == 0))
                  }"
                >
                  <td>
                    <input
                      type="checkbox"
                      [checked]="birthForm?.controls.montaid.value == monta.id"
                      (click)="changeMonta($event, index)"
                    />
                  </td>
                  <td>
                    <ng-container *ngIf="monta.montastart_dmy">{{ monta.montastart_dmy }} - {{ monta.montaend_dmy }}
                    </ng-container>
                    <ng-container *ngIf="!monta.montastart_dmy && monta.datainsemintare_dmy">{{
                      monta.datainsemintare_dmy }}</ng-container>
                  </td>
                  <td>{{ monta.matricol_taur }}</td>
                  <td>{{ monta.status_text }}</td>
                  <td>{{ monta.gestatie_start_dmy }} - {{ monta.gestatie_end_dmy }}</td>
                  <td>
                    <span *ngFor="let error of monta.errors" class="badge badge-danger p-1 mr-2">
                      {{ error }}
                    </span>
                    <span *ngFor="let warning of monta.warnings" class="badge badge-warning text-dark p-1 mr-2">
                      {{ warning }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              *ngIf="submitted && form.montaid.errors?.required"
              class="invalid-feedback d-block"
            >
              Alegerea unei monte este obligatorie.
            </div>
          </div>

          <div *ngIf="childInfoDisplay" class="col-sm-6 col-lg-6">
            <div class="form-group mb-3">
              <label for="usurintafatare">
                Ușurință Fătare*
              </label>

              <ng-select
                formControlName="usurintafatare"
                [clearable]="false"
                placeholder="Alegeți Ușurință Fătare"
                [ngClass]="{
                  'is-invalid': submitted && form.usurintafatare.errors
                }"
              >
                <ng-option value="1">1 - Ușoară fără asistență</ng-option>
                <ng-option value="2">2 - Ușoară cu un anumit grad de asistență</ng-option>
                <ng-option value="3">3 - Fătare dificilă</ng-option>
                <ng-option value="4">4 - Operație cezariană</ng-option>
                <ng-option value="5">5 - Embriotomie</ng-option>
              </ng-select>

              <div
                *ngIf="submitted && form.usurintafatare.errors"
                class="invalid-feedback d-block"
              >
                <div *ngIf="form.usurintafatare.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="form-group mb-3">
              <label for="retentieplacentara">
                Retenție Placentară
              </label>

              <ng-select
                formControlName="retentieplacentara"
                [clearable]="false"
              >
                <ng-option value="0">Nu se știe</ng-option>
                <ng-option value="2">Da</ng-option>
                <ng-option value="3">Nu</ng-option>
              </ng-select>
            </div>
          </div>

          <ng-container *ngIf="childInfoDisplay">
            <div class="col-12 separator mb-3"></div>

            <div class="col-sm-6 col-lg-6">
              <div class="form-group mb-3">
                <label for="matricol">
                  Matricol Vițel*
                </label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="Exemplu: RO504004738005"
                  formControlName="matricolvitel"
                  [ngClass]="{
                    'is-invalid': submitted && form.matricolvitel.errors
                  }"
                  (input)="
                    birthForm.patchValue({
                      matricolvitel: $event.target.value.toUpperCase()
                    })
                  "
                  [readonly]="fatareModalType === editFatare"
                />
                <div
                  *ngIf="submitted && form.matricolvitel.errors"
                  class="invalid-feedback"
                  [ngClass]="{
                    'd-block': submitted && form.matricolvitel.errors
                  }"
                >
                  <div *ngIf="form.matricolvitel.errors?.required">
                    Completarea acestui câmp este obligatorie.
                  </div>
                  <div *ngIf="form.matricolvitel.errors?.bad_format">
                    Matricolul începe cu RO urmat de 12 cifre
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6">
              <div class="form-group mb-3">
                <label for="sex">
                  Sex*
                </label>

                <div class="radio mb-2 ml-2 custom-radio-group">
                  <input
                    type="radio"
                    name="sex"
                    id="sex1"
                    [value]="1"
                    formControlName="sex"
                  />
                  <label for="sex1">
                    Mascul
                  </label>

                  <input
                    type="radio"
                    name="sex"
                    id="sex2"
                    [value]="2"
                    class="ml-5"
                    formControlName="sex"
                  />
                  <label for="sex2">
                    Femelă
                  </label>
                </div>

                <div
                  *ngIf="submitted && form.sex.errors?.required"
                  class="invalid-feedback d-block"
                >
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-3">
                <label for="name">
                  Nume
                </label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="Exemplu: GELU"
                  formControlName="nume"
                  (input)="
                    birthForm.patchValue({
                      nume: $event.target.value.toUpperCase()
                    })
                  "
                />
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-3">
                <label for="color">
                  Culoare
                </label>

                <ng-select
                  placeholder="Alegeți culoarea"
                  formControlName="culoare"
                >
                  <ng-option [value]="0">Alb</ng-option>
                  <ng-option [value]="1">Roșu</ng-option>
                  <ng-option [value]="2">Negru</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-3">
                <label for="greutatenastere">
                  Greutate*
                </label>

                <input
                  type="number"
                  class="form-control"
                  placeholder="Exemplu: 72"
                  formControlName="greutatenastere"
                  [ngClass]="{
                    'is-invalid': submitted && form.greutatenastere.errors
                  }"
                  [readonly]="!isSuperAdmin && form?.are_evaluare_genetica.value"
                />
                <div
                  *ngIf="submitted && form.greutatenastere.errors"
                  class="invalid-feedback"
                  [ngClass]="{ 'd-block': submitted && form.greutatenastere.errors }"
                >
                  <div *ngIf="form.greutatenastere.errors?.required">
                    Completarea acestui câmp este obligatorie.
                  </div>
                  <div *ngIf="form.greutatenastere.errors?.min || form.greutatenastere.errors?.max">
                    Greutatea trebuie sa aibă valori in intervalul 10 - 99
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="col-12 separator mb-3"></div>

          <div class="col-sm-6">
            <div class="form-group mb-3">
              <label for="status">
                Starea înregistrării*
              </label>

              <ng-select
                formControlName="status"
                class="starea_inregistrarii"
                [clearable]="false"
                [ngClass]="{
                  'is-invalid': submitted && form.status.errors
                }"
                (click)="anexaStatusChanged()"
                [readonly]="isFermier"
              >
                <ng-option value="0">În așteptare</ng-option>
                <ng-option value="1">Verificare</ng-option>
                <ng-option value="2">Operare</ng-option>
                <ng-option value="3">Validat</ng-option>
                <ng-option value="4">Refuzat</ng-option>
              </ng-select>
              <div
                *ngIf="submitted && form.status.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && form.status.errors }"
              >
                <div *ngIf="form.status.errors.required">
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-sm-12">
          <div class="btn-group">
            <button
              class="btn btn-info"
              type="button"
              (click)="closeFormMethod()"
            >
              {{ isAutoritateJudeteana || isContabil ? "Închide" : "Anulează" }}
            </button>
            <button
              *ngIf="!loadingSpinner && fatareModalType == 'edit-fatare' && (isAdmin || isOperator || (isFermier && form.status.value == '0'))"
              class="btn btn-danger"
              type="button"
              (click)="openConfirmActionModal()"
              [disabled]="birthForm?.controls.data_nasterii.disabled"
            >
              Șterge
            </button>

            <span
              *ngIf="loadingSpinner"
              class="spinner-border text-info"
              role="status"
            >
            </span>
          </div>

          <span
            class="float-right"
            ngbTooltip="Femela are restricții"
            [disableTooltip]="restrictii?.length == 0"
          >
            <button
              *ngIf="isAdmin || isOperator || (isFermier && form.status.value == '0')"
              class="btn btn-success float-right"
              (click)="onSubmitted()"
              [disabled]="
                !showAddButton || birthForm?.controls.data_nasterii.disabled ||
                restrictii?.length > 0
              ">
              <span *ngIf="fatareModalType === 'add-fatare'; else editBtn">
                Salvează
              </span>

              <ng-template #editBtn>
                <span>Actualizează</span>
              </ng-template>
            </button>
          </span>
        </div>

      </form>
    </div>
  </div>
</div>
<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-birth'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
