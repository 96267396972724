import { Component, OnInit } from '@angular/core';

import { ContabilitateSettingsService } from 'src/app/modules/acbcr/common/services/contabilitate-settings.service';

@Component({
  selector: 'app-criterii-cantitative-settings',
  templateUrl: './criterii-cantitative-settings.component.html',
  styleUrls: ['./criterii-cantitative-settings.component.scss']
})
export class CriteriiCantitativeSettingsComponent implements OnInit {
  criteriiList;

  constructor(
    private contabilitateSettingsService: ContabilitateSettingsService
  ) {}

  ngOnInit(): void {
    this.contabilitateSettingsService.getCriteriiSettings().subscribe(settings => {
      this.criteriiList = settings;
    });
  }

}
