<form [formGroup]="incasariSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_inceput">
          Dată început
        </label>
        <app-date-picker
          (dateChanged)="onStartDateChanged($event)"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="data_sfarsit">
          Dată sfârșit
        </label>
        <app-date-picker
          (dateChanged)="onEndDateChanged($event)"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-12">
      <div class="form-group mb-3">
        <label for="exploatatie">
          Exploatație
        </label>
        <ng-select
          [items]="!showHint ? holdingsNames : ''"
          bindLabel="name"
          bindValue="name"
          placeholder="Caută după exploatație"
          formControlName="exploatatie"
          (search)="searchHoldings($event)"
          (clear)="clearHoldings()"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ item.name }} - {{ item.code }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            {{ item.name }} - {{ item.code }}
          </ng-template>
        </ng-select>
        <small *ngIf="showHint" id="emailHelp" class="form-text text-muted">
          <div class="mb-2">
            <b>Introdu minim 5 caractere pentru a cauta</b>
          </div>
        </small>
      </div>
    </div>

    <div class="col-sm-12 col-md-12">
      <div class="form-group mb-3">
        <label for="chitanta">
          Chitanță
        </label>

        <input
          id="chitanta"
          type="text"
          class="form-control"
          placeholder="Caută după chitanță, serie sau număr"
          formControlName="chitanta"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
