import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { AdeverinteApartenentaTableService } from 'src/app/modules/acbcr/common/services/adeverinte-apartenenta-table.service';
import { AdeverintaApartenenta } from 'src/app/modules/acbcr/common/models/adeverinte-apartenenta.model';
import { getCurrentRoute } from '../../../common/state/reducers/route.reducers';

@Component({
  selector: 'app-allowances',
  templateUrl: './allowances.component.html',
  styleUrls: ['./allowances.component.scss'],
  providers: [AdeverinteApartenentaTableService, DecimalPipe]
})
export class AllowancesComponent implements OnInit, OnDestroy {
  tables$: Observable<AdeverintaApartenenta[]>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };
  selectedHolding: any;
  currentHolding: any;
  currentRoute;

  constructor(
    public adeverintaApartenentaTableService: AdeverinteApartenentaTableService,
    private store: Store<ApplicationState>,
  ) {
    this.tables$ = adeverintaApartenentaTableService.tables$;
    this.total$ = adeverintaApartenentaTableService.total$;
  }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: string) => {
      this.currentRoute = response;
    });

    this.store.pipe(
      select(getLoadingState),
      filter(isLoading => !isLoading),
      switchMap(() => this.store.pipe(select(getSelectedHolding))),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
      takeUntil(this.destroy$),
    ).subscribe((holding: any) => {
      if (holding) {
        this.currentHolding = holding;
        this.selectedHolding = holding.id;
        this.getTableData();
      } else {
        this.getTableData();
      }
    });
  }

  getTableData() {
    this.loadingDataSpinner = true;
    if (this.currentRoute.includes('/exploatatie/')) {
      this.adeverintaApartenentaTableService
      .getAdeverinteAPI(this.objToAPI, this.selectedHolding).subscribe((response) => {
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
    } else {
      this.adeverintaApartenentaTableService
      .getAdeverinteAPI(this.objToAPI).subscribe((response) => {
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
    }
  }

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

  getCallBackResult(event) {
    this.getTableData();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

}
