import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { PerformanceTableService } from '../../../common/services/performance-table.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
  providers: [PerformanceTableService, DecimalPipe]
})
export class PerformanceComponent implements OnInit {

  tables$: Observable<any[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public performanceTableService: PerformanceTableService,
  ) {
    this.tables$ = performanceTableService.tables$;
    this.total$ = performanceTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.performanceTableService
      .getPerformanceAPI(this.objToAPI).subscribe((response) => {
        // Hide spinner, after 1 second
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    // Compose the object what will be sent to the back-end
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

  getTableCallback(event) {
    this.getTableData();
  }

}
