import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from './../../common/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  resetPassword: UntypedFormGroup;
  submitted = false;
  shortPassword = false;
  samePassword = false;
  commonPassword = false;
  numericPassword = false;
  usernamePassword = false;
  successChange = false;
  passwordError = false;
  tokenError = false;
  errors;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {

    const str = window.location.hash;
    const userId = str.split('id=').pop().split('&')[0];
    const token = str.split('token=').pop().split('&')[0];

    this.resetPassword = this.formBuilder.group({
      new_password1: ['', [Validators.required]],
      new_password2: ['', [Validators.required]],
      uid: [userId],
      token: [token]
    });

  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPassword.controls; }

  onSubmit() {
    this.submitted = true;
    this.shortPassword = false;
    this.samePassword = false;
    this.commonPassword = false;
    this.numericPassword = false;
    this.usernamePassword = false;
    this.passwordError = false;
    this.tokenError = false;

    if (! this.resetPassword.valid) {
      return;
    }

    this.authService.resetPassword(this.resetPassword.value)
      .subscribe({
        next: (response) => {
          this.successChange = true;
          setTimeout(() => {
            this.router.navigateByUrl('/login');
          }, 3000);
        },
        error: (errors) => {
          if (errors.error.errors?.token) {
            this.tokenError = true;
            return;
          }

          if (! errors.error.errors || ! errors.error.errors.new_password2) {
            this.passwordError = true;
            return;
          }

          if (errors.error.errors.new_password2[0].includes('8')) {
            this.shortPassword = true;
          } else if (errors.error.errors.new_password2[0].includes('The two password fields didn’t match.')) {
            this.samePassword = true;
          } else if (errors.error.errors.new_password2[0].includes('This password is too common.')) {
            this.commonPassword = true;
          } else if (errors.error.errors.new_password2[0].includes('This password is entirely numeric.')) {
            this.numericPassword = true;
          } else if (errors.error.errors.new_password2[0].includes('The password is too similar to the username')) {
            this.usernamePassword = true;
          } else {
            this.passwordError = true;
            this.errors = errors.error.errors.new_password2;
          }
        }
      });
  }
}
