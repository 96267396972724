<div
    *ngIf="isAdmin || isOperator"
    class="col-12"
>
    <h1 class="mb-3">{{titlu}}</h1>
</div>

<div
    *ngIf="isAdmin || isOperator"
    class="col-sm-12 col-xl-6"
>
    <form [formGroup]="RaportGeneralForm">
        <div class="row">
            <div *ngIf="arataPerioadaStart" class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="perioada_start">
                        Dată început
                    </label>
                    <app-date-picker [selectedDate]="RaportGeneralForm.value.perioada_start"
                        (dateChanged)="onStartDateChanged($event)">
                    </app-date-picker>
                </div>
            </div>

            <div *ngIf="arataPerioadaStop" class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="perioada_stop">
                        Dată sfârșit
                    </label>
                    <app-date-picker [selectedDate]="RaportGeneralForm.value.perioada_stop"
                        (dateChanged)="onEndDateChanged($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="arataRasa" class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="rasa">
                        Rasă
                    </label>
                    <ng-select formControlName="rasa" placeholder="Toate">
                        <ng-option value='Charolaise'>Charolaise</ng-option>
                        <ng-option value='Limousine'>Limousine</ng-option>
                    </ng-select>
                </div>
            </div>

            <div *ngIf="arataSectiune" class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="sectiune">
                        Secțiune
                    </label>
                    <ng-select formControlName="sectiune" placeholder="Toate">
                        <ng-option value='Principala'>Principală</ng-option>
                        <ng-option value='Suplimentara'>Suplimentară</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="arataSex" class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="sex">
                        Sex
                    </label>
                    <ng-select formControlName="sex" placeholder="Toate">
                        <ng-option value='Mascul'>Mascul</ng-option>
                        <ng-option value='Femelă'>Femelă</ng-option>
                    </ng-select>
                </div>
            </div>

            <div *ngIf="arataStare" class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="stare">
                        Stare
                    </label>
                    <ng-select formControlName="stare" placeholder="Toate">
                        <ng-option value='Activ'>Activ</ng-option>
                        <ng-option value='Inactiv'>Inactiv</ng-option>
                    </ng-select>
                </div>
            </div>

            <div *ngIf="arataStareExploatatie" class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="stare_exploatatie">
                        Stare Exploatație
                    </label>
                    <ng-select formControlName="stare_exploatatie" placeholder="Toate">
                        <ng-option value='1'>Activ</ng-option>
                        <ng-option value='0'>Inactiv</ng-option>
                        <ng-option value='-1'>În așteptare</ng-option>
                        <ng-option value='5'>Import</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>

        <div *ngIf="arataCautareExploatatie" class="row">
            <div class="col-12">
                <div class="form-group mb-3">
                    <label for="exploatatie_id">
                        Exploatație
                    </label>
                    <ng-select [items]="exploatatii" bindLabel="viewLabel" bindValue="id"
                        formControlName="exploatatie_id" [required]="true" placeholder="Alege exploatația"
                        (search)="searchExploatatii($event)">
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                            <div class="row">
                                <div class="col-md-12 col-lg-auto">
                                    {{item.name}}
                                    <span *ngIf="item.code" class="small mr-1"> - {{ item.code }}</span>
                                    <span *ngIf="item.status != 1" class="badge bg-secondary">Inactiv</span>
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>

                    <small class="form-text text-muted ml-2">
                        <b>Introdu minim 5 caractere pentru a căuta</b>
                    </small>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div *ngIf="arataLuna" class="form-group mb-3">
                    <label for="luna">
                        Luna
                    </label>
                    <ng-select formControlName="luna" dropdownPosition="bottom">
                        <ng-option value='1'>Ianuarie</ng-option>
                        <ng-option value='2'>Februarie</ng-option>
                        <ng-option value='3'>Martie</ng-option>
                        <ng-option value='4'>Aprilie</ng-option>
                        <ng-option value='5'>Mai</ng-option>
                        <ng-option value='6'>Iunie</ng-option>
                        <ng-option value='7'>Iulie</ng-option>
                        <ng-option value='8'>August</ng-option>
                        <ng-option value='9'>Septembrie</ng-option>
                        <ng-option value='10'>Octombrie</ng-option>
                        <ng-option value='11'>Noiembrie</ng-option>
                        <ng-option value='12'>Decembrie</ng-option>
                    </ng-select>
                </div>

                <div *ngIf="arataAnul" class="form-group mb-3">
                    <label for="anul">
                        Anul
                    </label>
                    <ng-select [items]="ani" formControlName="anul" dropdownPosition="bottom">
                    </ng-select>
                </div>

                <div *ngIf="arataMetodaCPPC" class="form-group mb-3">
                    <label for="luna">
                        Metoda CPPC
                    </label>
                    <ng-select formControlName="metoda_cppc" [clearable]="false">
                        <ng-option value='contract'>Act Adițional / Contract</ng-option>
                        <ng-option value='rg'>Registrul Genealogic</ng-option>
                    </ng-select>
                </div>
            </div>

            <div *ngIf="arataJudet" class="col-sm-12 col-md-6">
                <div class="form-group mb-3 ml-1">
                    <label class="mr-3">Județe</label>
                    <input
                        type="checkbox"
                        id="toateJudetele"
                        (click)="toateJudetele($event)"
                        [checked]="checkedToateJudetele"
                        class="mr-1"
                    />
                    <label for="toateJudetele">Toate</label>

                    <div *ngFor="let judet of judete">
                        <input
                            type="checkbox"
                            (click)="clickJudet($event, judet)"
                            [checked]="judet.checked"
                            class="mr-1"
                            [id]="judet.name"
                        />
                        <label [for]="judet.name">{{ judet.name }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="btn-group mb-3">
                <button class="btn btn-blue" (click)="creazaRaport()" [disabled]="buton_dezactivat">
                    Creaza raport
                </button>
            </div>
        </div>

    </form>

    <div *ngIf="error" class="alert alert-danger" [innerHTML]="error"></div>
    <p *ngFor="let raport_in_curs of in_curs" class="alert alert-info">
        Raport in curs: {{raport_in_curs}}
    </p>

    <p *ngFor="let fisier of fisiere">
        <button class="btn btn-sm btn-danger mr-1" (click)="stergeRaport(fisier)" title="Șterge">
            <i class="mdi mdi-delete"></i>
        </button>

        {{ fisier }}

        <button class="btn btn-sm btn-info ml-1" (click)="downloadRaport(fisier)">
            Descarcă
        </button>
    </p>

</div>
