import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener
} from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { RegistruGenealogicTableService } from 'src/app/modules/acbcr/common/services/registru-genealogic-table.service';
import { AnimalRase, TreeNode } from 'src/app/modules/acbcr/common/models/animal.models';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppAnimalsRases } from 'src/app/modules/acbcr/common/state/reducers/animals.reducers';
import { takeUntil } from 'rxjs/operators';
import { BirthsTableService } from 'src/app/modules/acbcr/common/services/births-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-view-reason-modal',
  templateUrl: './view-reason-modal.component.html',
  styleUrls: ['./view-reason-modal.component.scss']
})
export class ViewReasonModalComponent implements OnInit, OnChanges {
  @ViewChild('reasonModal') reasonModal: ElementRef;
  @Input() reasonModalData: any;

  destroy$: Subject<boolean> = new Subject<boolean>();
  reasonData:any = {};
  animalFamilyTree: TreeNode[];
  raseAnimal: AnimalRase[];
  modalReference: NgbModalRef;
  numeCrescator: string;
  numeProprietar: string;
  eligibilitateMessages = [];
  generalMessages = [];
  fatareMessages = [];
  anexa8Messages = [];
  apreciereFenotipicaMessages = [];
  holdingId;
  raseStr = [];
  loadingDataSpinner = false;

  constructor(
    public registruGenealogicTableService: RegistruGenealogicTableService,
    private helperDataService: HelperDataService,
    private birthsTableService: BirthsTableService,
    private store: Store<ApplicationState>,
    public modalService: NgbModal
  ) {
    this.animalFamilyTree = [];
  }

  ngOnInit(): void {
    this.store.pipe(select(getAppAnimalsRases))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: AnimalRase[]) => {
        this.raseAnimal = response;
      });
  }

  ngOnChanges(changes) {
    if (changes.reasonModalData && ! changes.reasonModalData.firstChange) {
      let animalId = this.reasonModalData['animal_id'];
      this.loadingDataSpinner = true;

      this.registruGenealogicTableService.getReason(animalId)
        .subscribe(response => {
          this.reasonData = response;
          this.raseStr = [];
          this.animalFamilyTree = [];
          this.generalMessages = [];
          this.anexa8Messages = [];
          this.fatareMessages = [];
          this.apreciereFenotipicaMessages = [];
          this.holdingId = this.reasonData.exploatatieid;
          this.getRaseString(this.reasonData.rase);
          this.animalFamilyTree.push(this.reasonData.family_tree);
          this.eligibilitateMessages = this.reasonData.eligibilitate_rg;
          this.generalMessages = [];
          this.eligibilitateMessages.map(message => {
            if (message.type === '') { this.generalMessages.push(message); }
            if (message.type === 'Fătare') { this.fatareMessages.push(message); }
            if (message.type === 'Anexa 8') { this.anexa8Messages.push(message); }
            if (message.type === 'Apreciere fenotipică') { this.apreciereFenotipicaMessages.push(message); }
          });

          this.loadingDataSpinner = false;
        });

      this.modalReference = this.modalService.open(this.reasonModal, {
        windowClass: 'modal-half-full',
        scrollable: true,
      });
    }
  }

  downloadAnexa8(anexaId) {
    this.birthsTableService.getAnexa8Pdf(this.holdingId, anexaId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, `Anexa8_${anexaId}.pdf`);
      });
  }

  viewAnexa8(anexaId) {
    this.birthsTableService.getAnexa8Pdf(this.holdingId, anexaId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        window.open(window.URL.createObjectURL(blob));
      });
  }

  getRaseString(arr) {
    arr.forEach((rasa, index) =>
      this.raseStr.push(this.getRasaShortname(rasa.raceid) +
        (rasa.val !== null ? rasa.val : '')));
  }

  getRasaShortname(rasaId): string {
    if (this.raseAnimal.length > 0 && rasaId) {
      return this.raseAnimal.find(rasa => rasa.id === rasaId)?.shortname;
    } else {
      return '';
    }
  }
}
