<ng-template ngFor let-anexa [ngForOf]="anexe" let-i="index">
  <tr
    class="cursor-pointer"
    (click)="openEditModal(anexa, i)"
    [ngClass]="{ active: selectedItem === i }"
  >
    <td
      width="70%"
      class="text-primary font-size-16"
    >
      <p class="mb-0">
        <span>{{ anexa.tip_reproductie_text }}</span>
        din
        <span>{{ anexa.interval_monta }}</span>
      </p>

      <p class="mb-0" [ngSwitch]="anexa.nr_femele">
          <span class="font-weight-bold" *ngSwitchCase="1">
            Femela {{ anexa.femela }}
          </span>
          <span class="font-weight-bold" *ngSwitchDefault>
            {{ anexa.nr_femele }} femele
          </span>
          și
          <span class="font-weight-bold">taurul {{ anexa.taur }}</span>
      </p>
    </td>
    <td
      width="30%"
      class="align-middle font-weight-bold"
    >
      <span [ngSwitch]="anexa?.status">
        <span *ngSwitchCase="0">În așteptare</span>
        <span *ngSwitchCase="1">Verificare</span>
        <span *ngSwitchCase="2">Operare</span>
        <span *ngSwitchCase="3">Validat</span>
        <span *ngSwitchCase="4">Refuzat</span>
        <span *ngSwitchCase="5">Anulat</span>
      </span>
    </td>
  </tr>
</ng-template>
