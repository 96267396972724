import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';

import { UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { getAppCompanyAdmins } from 'src/app/modules/acbcr/common/state/reducers/company-admins.reducers';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';

@Component({
  selector: 'app-admin-exp-form-block',
  templateUrl: './admin-exp-form-block.component.html',
  styleUrls: ['./admin-exp-form-block.component.scss']
})
export class AdminExpFormBlockComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() holdingForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() admins;
  modalType: string;
  open = null;
  selectedAdmin;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<ApplicationState>) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.store.pipe(select(getAppCompanyAdmins))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.admins = response;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  // Get forms controls state
  get f() { return this.holdingForm.controls; }

  changeHoldingAdmin(admin) {
    this.selectedAdmin = admin;
  }

  openAdminModal(type) {
    this.modalType = type;
    this.open = {open: true};

    if (type === 'edit') {
      this.admins.map(admin => {
        if (admin.id == this.holdingForm.value.administrator) {
          this.selectedAdmin = admin;
        }
      });
    }
  }

  closeHoldingAdminModal(event) {
    this.open = event;
  }


}
