<ng-template #addEditTemplate let-modal="close">
    <div class="modal-header row">
      <div class="col-sm-12 col-md-12 col-lg-6 custom-col">
        <h3 class="modal-title">Adeverință Ajutor Ucraina</h3>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-6 text-right custom-col">
        <div class="btn-group custom-modal-btn">
          <button
            class="btn btn-sm btn-secondary"
            (click)="modal('Cross click')"
            autofocus="false"
          >
            Închide
          </button>
          <button
            *ngIf="!readonly"
            class="btn btn-sm btn-success"
            (click)="onSubmitted()"
            [disabled]="errorFinalizata"
          >
            Salvează
          </button>
        </div>
      </div>
    </div>

    <div class="modal-body">
      <div
        *ngIf="loadingDataSpinner"
        class="spinner-border text-info table-spinner avatar-xxl"
        role="status"
      ></div>

      <form *ngIf="!loadingDataSpinner" [formGroup]="adeverintaForm" class="h-100">
        <div class="container-fluid">

          <div class="row">
            <div class="col-auto">
              <div class="form-group mb-3 mr-2">
                <label for="data">
                  Data
                </label>
                <app-date-picker
                  (dateChanged)="onDateChanged($event)"
                  [selectedDate]="adeverintaForm.controls.data.value"
                  [disabled]="readonly"
                >
                </app-date-picker>

                <div
                  *ngIf="submitted && adeverintaForm.controls.data.errors?.required"
                  class="error-message invalid-feedback d-block"
                >
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group mb-3 mr-2">
                <label for="numar">
                  Număr
                </label>
                <input
                  type="text"
                  formControlName="numar"
                  pattern="[0-9]+"
                  class="numar form-control"
                  [readonly]="readonly"
                  (change)="verificareStatus()"
                />

                <div
                  *ngIf="submitted && adeverintaForm.controls.numar.errors?.pattern"
                  class="invalid-feedback d-block"
                >
                  Cămpul trebuie să fie un număr
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group mb-3 mr-2">
                <label for="rasa">
                  Rasa
                </label>
                <ng-select
                  formControlName="rasa_id"
                  placeholder="Alege rasa"
                  class="rasa"
                  [ngClass]="{ disabled: readonly }"
                  (change)="findAnimals()"
                >
                  <ng-option [value]="1">Charolaise</ng-option>
                  <ng-option [value]="2">Limousine</ng-option>
                </ng-select>

                <div
                  *ngIf="submitted && adeverintaForm.controls.rasa_id.errors?.required"
                  class="error-message invalid-feedback d-block"
                >
                  Completarea acestui câmp este obligatorie.
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group mb-3">
                <label for="centru_apia">
                  Centru APIA
                </label>

                <input
                  type="text"
                  formControlName="centru_apia"
                  placeholder="Centru APIA"
                  class="form-control w26"
                  [readonly]="readonly"
                  (change)="verificareStatus()"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-9">
              <div class="form-group mb-3">
                <label for="exploatatie_id">
                  Exploatație
                </label>
                <ng-select
                  [items]="exploatatiiSelectItems"
                  bindLabel="viewLabel"
                  bindValue="id"
                  formControlName="exploatatie_id"
                  placeholder="Alege exploatația"
                  (search)="searchExploatatii($event)"
                  (change)="findAnimals()"
                  [ngClass]="{ disabled: readonly || isFermier }"
                >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <div class="row">
                    <div class="col-md-12 col-lg-auto">
                      {{item.name}} - {{ item.code }}
                    </div>

                    <div class="col-md-12 col-lg-auto px-0">
                      <span *ngFor="let error of item.errors" class="badge badge-danger p-1 mr-2">
                        {{ error }}
                      </span>
                    </div>
                  </div>
                </ng-template>
                </ng-select>

                <small
                  *ngIf="!adeverintaForm.value.exploatatie_id"
                  class="form-text text-muted ml-2"
                >
                  <b>Introdu minim 5 caractere pentru a căuta</b>
                </small>
                <small
                  *ngIf="submitted && adeverintaForm.controls.exploatatie_id.errors?.required"
                  class="form-text text-danger ml-2"
                >
                  Alege exploatația
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="type-title">
                Animale
                <span
                  *ngIf="loadingAnimale"
                  class="spinner-border text-info small avatar-xs"
                  role="status"
                >
                </span>
              </h3>
            </div>
          </div>

          <ng-container
            *ngFor="let animalForm of animaleArrayForm.controls; let index = index;"
          >
            <div [formGroup]="animalForm" class="row mb-2">
              <div class="col-auto d-flex align-items-center justify-content-center">
                <span class="sex px-1 mr-1">{{ animalForm.value.sex_text }}</span>
                <span class="matricol_rasa">
                  {{ animalForm.value.numarmatricol }} - {{ animalForm.value.rasa }}
                </span>
              </div>

              <ng-container
                *ngFor="let data of dataList"
                formGroupName="existent"
              >
                <div class="col-auto d-flex align-items-center justify-content-center">
                  <span class="mr-2">Existent la {{ data }}</span>
                  <ng-select
                    [formControlName]="data"
                    class="da_nu"
                    [ngClass]="{ disabled: readonly || isFermier }"
                    [clearable]="false"
                  >
                    <ng-option [value]="true">Da</ng-option>
                    <ng-option [value]="false">Nu</ng-option>
                  </ng-select>
                </div>
              </ng-container>

              <div
                *ngIf="!readonly"
                class="col-auto d-flex align-items-center justify-content-center"
              >
                <i
                  class="mdi mdi-close-circle text-danger fs-3 cursor-pointer"
                  title="Șterge"
                  (click)="deleteAnimal(animalForm, index)"
                >
                </i>
              </div>
            </div>
          </ng-container>

          <div class="row mt-1">
            <div class="col-sm-12 col-lg-9">
              <div class="form-group mb-3">
                <ng-select
                  #elementAdaugaAnimal
                  *ngIf="!readonly"
                  [items]="animaleSelectItems"
                  bindLabel="numarmatricol"
                  bindValue="id"
                  placeholder="Alege animal"
                  (change)="adaugaAnimal(elementAdaugaAnimal, $event)"
                >
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <div class="row">
                      <div class="col-auto d-flex align-items-center justify-content-center">
                        <span class="sex px-1 mr-1">{{ item.sex_text }}</span>
                        <span class="matricol_rasa">
                          {{ item.numarmatricol }} - {{ item.rasa }}
                        </span>
                      </div>

                      <div
                        *ngFor="let data of dataList"
                        class="col-auto d-flex align-items-center justify-content-center"
                      >
                        <span *ngIf="item.existent">{{ data }}:
                          <ng-container *ngIf="item.existent[data] === true">Da</ng-container>
                          <ng-container *ngIf="item.existent[data] === false">Nu</ng-container>
                        </span>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>

                <small
                  *ngIf="!adeverintaForm.value.rasa_id || !adeverintaForm.value.exploatatie_id"
                  class="form-text text-muted ml-2"
                >
                  <b>Animalul se poate alege după ce s-a ales rasa și exploatația</b>
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group mb-3">
                <label for="status">
                  Status
                </label>
                <ng-select
                  formControlName="status"
                  class="starea_inregistrarii"
                  [ngClass]="{ disabled: readonly || isFermier }"
                  [clearable]="false"
                  [dropdownPosition]="'top'"
                  (change)="verificareStatus()"
                >
                  <ng-option [value]="1">Salvată</ng-option>
                  <ng-option [value]="2">Finalizată</ng-option>
                </ng-select>

                <div
                  *ngIf="submitted && adeverintaForm.controls.status.errors?.required"
                  class="error-message invalid-feedback d-block"
                >
                  Completarea acestui câmp este obligatorie.
                </div>

                <div
                  *ngIf="errorFinalizata"
                  class="error-message invalid-feedback d-block"
                >
                  Adeverința finalizată trebuie să conțină toate datele
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
   </ng-template>
