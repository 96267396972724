import { Injectable, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  @Output() messagesReceived = new EventEmitter();

  private websocket: WebSocket;
  private url;
  private userId = null;
  private firstOpened = true;

  constructor() {
    this.url = `${environment.API_URL}/ws/notifications/`;
    this.url = this.url.replace('http', 'ws');
  }

  public openConnection() {
    this.websocket = new WebSocket(this.url);
    this.addEvents();
  }

  public closeConnection() {
    this.userId = null;
    this.firstOpened = true;
    this.websocket.close();
  }

  public getNotifications(page: number) {
    const message = {
      send_notifications: 1,
      user_id: this.userId,
      page_no: page,
    };
    this.sendMessage(message);
  }

  private sendMessage(message) {
    this.websocket.send(JSON.stringify(message));
  }

  private onOpen(): any {
    if (this.firstOpened) {
      this.firstOpened = false;
      const message = {
        token: localStorage['access-token'],
      };
      this.sendMessage(message);
    }
  }

  private onClose(event): any {
  }

  private onError(event): any {
    this.openConnection();
  }

  private onMessage(event): any {
    const data = JSON.parse(event.data);
    this.messagesReceived.emit(data);
  }

  private addEvents() {
    this.websocket.onopen = (event) => {
      this.onOpen();
    };
    this.websocket.onclose = (event) => {
      this.onClose(event);
    };
    this.websocket.onerror = (event) => {
      this.onError(event);
    };
    this.websocket.onmessage = (event) => {
      this.onMessage(event);
    };
  }
}
