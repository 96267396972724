import { Action } from '@ngrx/store';

export enum AppUserActionType {
  SET_USER_DATA = 'SET_USER_DATA'
}

export class SetUserData implements Action {
  readonly type = AppUserActionType.SET_USER_DATA;
  constructor(public payload: any) { }
}

export type AppUserActions = SetUserData;
