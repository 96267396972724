import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { CaietDePasunatService } from 'src/app/modules/acbcr/common/services/caiet-de-pasunat.service'

@Component({
  selector: 'app-caiet-de-pasunat',
  templateUrl: './caiet-de-pasunat.component.html',
  styleUrls: ['./caiet-de-pasunat.component.scss']
})
export class CaietDePasunatComponent {
  isAdmin = false;
  isOperator = false;
  fisierAnimale;
  disabledAlegeFisier = false;
  pasunatForm: UntypedFormGroup;
  stats;
  checkedToateAnimalele = true;

  constructor(
    private helperDataService: HelperDataService,
    public caietDePasunatService: CaietDePasunatService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.buildForm();
  }

  buildForm() {
    let currentDate = UIHelper.getCurrentDate();

    this.pasunatForm = new UntypedFormGroup({
      data_intrare: new UntypedFormControl(),
      data_iesire: new UntypedFormControl(currentDate),
      nr_ore_pasunat: new UntypedFormControl(),
      parcele: new UntypedFormArray([]),
    });
  }

  get parcele(): UntypedFormArray {
    return this.pasunatForm.get('parcele') as UntypedFormArray;
  }

  selectatFisierAnimale(event) {
    const file: File = event.target.files[0];

    if (file) {
      this.fisierAnimale = file;
      this.stats = null;
    }
  }

  uploadFisierAnimale(raspuns=null) {
    this.disabledAlegeFisier = true;

    const formData = new FormData();
    formData.append('fisier', this.fisierAnimale);
    formData.append('data_intrare', this.pasunatForm.value.data_intrare);
    formData.append('data_iesire', this.pasunatForm.value.data_iesire);
    formData.append('nr_ore_pasunat', this.pasunatForm.value.nr_ore_pasunat);
    formData.append('raspuns', raspuns);

    let parceleJSON = JSON.stringify(this.pasunatForm.value.parcele);
    formData.append('parcele', parceleJSON);

    let animale_eliminate = [];
    if (this.stats && this.stats['animale']) {
      for (let animal of this.stats['animale']) {
        if (! animal['checked']) {
          animale_eliminate.push(animal['matricol'])
        }
      }
    }

    formData.append('animale_eliminate', JSON.stringify(animale_eliminate));

    if (raspuns === 'fisier') {
      this.caietDePasunatService.CaietDePasunatDownload(formData)
      .subscribe({
        next: (response) => {
          this.disabledAlegeFisier = false;
          let now = UIHelper.now();
          let fisier = `Caiet_de_Pasunat_${this.stats['cod_exploatatie']}_${now}.xls`;
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
        },
        error: (errors) => {
          this.disabledAlegeFisier = false;
        }
      });
    } else {
      this.stats = null;
      this.caietDePasunatService.CaietDePasunatStats(formData)
      .subscribe({
        next: (response: any) => {
          this.disabledAlegeFisier = false;
          this.stats = response;

          if (!response) {
            return;
          }

          for (let animal of this.stats['animale']) {
            animal['checked'] = true;
          }
        },
        error: (errors) => {
          this.disabledAlegeFisier = false;
        }
      });
    }
  }

  adaugaParcela() {
    let parcelaForm = new UntypedFormGroup({
      nr_bloc: new UntypedFormControl(),
      nr_parcela: new UntypedFormControl(),
      suprafata_parcela: new UntypedFormControl(),
    });

    this.parcele.push(parcelaForm);
  }

  stergeParcela(index) {
    this.parcele.removeAt(index);
  }

  clickAnimal(checked, animal) {
    animal['checked'] = checked;
    let checkedToate = true;

    for (let animal of this.stats['animale']) {
      if (animal['checked'] !== true) {
        checkedToate = false;
      }
    }

    this.checkedToateAnimalele = checkedToate;
  }

  clickToateAnimalele(checked) {
    this.checkedToateAnimalele = checked;

    for (let animal of this.stats['animale']) {
      animal['checked'] = checked;
    }
  }
}
