import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-table-search-modal',
  templateUrl: './table-search-modal.component.html',
  styleUrls: ['./table-search-modal.component.scss'],
})
export class TableSearchModalComponent implements OnInit {
  @ViewChild('tableSearchModal') tableSearchModal: ElementRef;
  @Input() open: Subject<boolean>;
  @Input() modalType: string;
  @Output() notifyClose = new EventEmitter();
  @Output() searchingData = new EventEmitter();

  isFermier = false;

  constructor(
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.isFermier = UIHelper.isFermier();

    if (typeof this.open == 'object') {
      this.open.subscribe({
        next: (response) => {

          if (response) {
            this.modalService.open(this.tableSearchModal, {
              scrollable: false
            });
          }
        },
        error: (errors) => {
        }
      });
    }
  }

  // Get the data from the child component
  getSearchingData(searchingResult) {
    this.searchingData.emit(searchingResult);
  }

}
