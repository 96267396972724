<div class="card">
  <div class="card-body" style="height: 600px;">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./capacitatea-de-pasunat"
        >
          Capacitatea de pășunat
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./incarcatura-uvm-ha-apia"
        >
          Încărcătura UVM/HA APIA
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./pasunat-rational"
        >
          Pășunat rațional
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive ="active"
          routerLink="./productie-furaj"
        >
          Producție furaj
        </a>
      </li>
    </ul>

    <div class="h-100 d-flex align-items-center justify-content-center">
      <h1 class="text-center">ÎN LUCRU</h1>
    </div>

  </div>
</div>
