import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actiuni-sanitar-veterinare',
  templateUrl: './actiuni-sanitar-veterinare.component.html',
  styleUrls: ['./actiuni-sanitar-veterinare.component.scss']
})
export class ActiuniSanitarVeterinareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
