import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OptionsProvider as auth } from './options-provider';
import { SortableTable } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})
export class CertificatSanitarService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-sanitar/`,
      req_params, auth.options).pipe(
        map((response: any) => {
          this.totalRecords = response.count;
          this.paginate();

          return response;
        }));
  }

  listAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-sanitar/list/`,
      req_params, auth.options).pipe(
        map((response: any) => {
          this.totalRecords = response.count;
          this.paginate();

          return response;
        }));
  }

  animaleAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-sanitar/animale/`,
      req_params, auth.options).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  creazaAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-sanitar/creaza/`,
      req_params, auth.options).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  salveazaAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-sanitar/salveaza/`,
      req_params, auth.options).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  stergeAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-sanitar/sterge/`,
      req_params, auth.options).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportAPI(req_params) {
    return this.http.post(
      `${this.baseUrl}/documente/certificat-sanitar/export/`,
        req_params,
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      );
  }

  private paginate(): void {
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.totalRecords) {
      this._state.endIndex = this.totalRecords;
    }
  }
}
