import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppAnimalsActions, AppAnimalsActionType } from './../actions/animals.actions';
import { ApplicationState } from '../app.state';
import { AnimalRase } from '../../models/animal.models';

export interface AppAnimals {
  animals_rases: AnimalRase[];
}

export const initialState = {
  animals_rases: []
};

export const getApplicationFeature =
  createFeatureSelector<ApplicationState>('appData');

export const getAppAnimalsRases =
  createSelector(
    getApplicationFeature,
    state => state.animals.animals_rases
  );

export function animalsRacesReducers(
  state: AppAnimals = initialState,
  action: AppAnimalsActions
) {
  switch (action.type) {
    case AppAnimalsActionType.SET_ANIMALS_RASE_DATA:
      return {
        ...state,
        animals_rases: action.payload
      };

    default:
      return state;
  }
}

