import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { AppDocument } from 'src/app/modules/acbcr/common/models/document';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-date-bancare-form-block',
  templateUrl: './date-bancare-form-block.component.html',
  styleUrls: ['./date-bancare-form-block.component.scss']
})
export class DateBancareFormBlockComponent implements OnInit {

  @Input() holdingForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() modalType: string;

  documentsList: AppDocument[];

  constructor(private helperDataService: HelperDataService) { }

  ngOnInit(): void {
    this.documentsList =
      this.holdingForm.value.documents !== null ? this.holdingForm.value.documents : [];
  }


  uploadServerResponse(event) {
    const uploadedFiles = event.body.files;
    const arrIds = [];

    if (this.modalType === 'add') {
      uploadedFiles.map(file => {
        arrIds.push(file.file_id);
      });
      this.holdingForm.controls.documents_ids.setValue(arrIds);
    }


    if (this.holdingForm.controls.id.value !== null) {
      uploadedFiles.map(file => {
        this.helperDataService.mapUploadedFiles({
          company_id: this.holdingForm.value.id,
          file_id: file.file_id,
          file_name: file.file_name,
        }).subscribe((response: any) => {
          this.documentsList.push(response);
        });
      });
    }
  }

  deleteFile(documentId) {
    this.helperDataService.deleteCompanyDocument(documentId)
      .subscribe(response => {
        this.documentsList.forEach((document, index) => {
          if (document.id === documentId) {
            this.documentsList.splice(index, 1);
          }
        });
      });
  }

}
