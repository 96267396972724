import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output, OnDestroy
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import {
  AdvancedSortableDirective,
  SortEvent,
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { RapoarteJurnalTableService } from 'src/app/modules/acbcr/common/services/rapoarte-jurnal.service';

@Component({
  selector: 'app-raport-jurnal-table',
  templateUrl: './raport-jurnal-table.component.html',
  styleUrls: ['./raport-jurnal-table.component.scss']
})
export class RaportJurnalTableComponent implements OnInit, OnDestroy {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('rapoarteJurnalTable', { static: false })
  rapoarteJurnalTable: ElementRef;

  @Input() rapoarteJurnal: any[];
  @Input() rapoarteJurnalLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() rapoarteJurnalTableCallback = new EventEmitter();

  readonly modalType = 'rapoarte-jurnal';
  openSearchModal = new Subject<boolean>();

  currentRoute: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isAutoritateJudeteana = false;
  isContabil = false;
  filteredRapoarte;
  isDataFiltered = false;

  constructor(
    public rapoarteJurnalService: RapoarteJurnalTableService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.rapoarteJurnalService.sortColumn = column;
    this.rapoarteJurnalService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
    this.filteredRapoarte = event;
    // check if a filter was made
    this.isDataFiltered = !Object.values(this.filteredRapoarte).every(
      (o) => o === null
    );
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  getModalResult(event) {
    this.rapoarteJurnalTableCallback.emit(event);
  }

  ngOnDestroy(): void {
    this.rapoarteJurnalService.clearRapoarteTable();
  }
}
