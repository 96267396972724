import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { ContabilitateSettingsService } from 'src/app/modules/acbcr/common/services/contabilitate-settings.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { AnimalRase } from 'src/app/modules/acbcr/common/models/animal.models';
import { ContractTableService } from 'src/app/modules/acbcr/common/services/contracts-table.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-servicii-settings',
  templateUrl: './servicii-settings.component.html',
  styleUrls: ['./servicii-settings.component.scss'],
})
export class ServiciiSettingsComponent implements OnInit {
  // Access ng-select
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  settingsForm;
  activities;
  services;
  submitted = false;
  editSuccess = false;
  activeFields = [];
  isChecked = false;
  raseAnimal: AnimalRase[];
  umSettings: [];
  criteriiList;
  criteriiToShow = [];
  activitateId;
  eventType: any;

  constructor(
    private contabilitateService: ContabilitateSettingsService,
    private formBuilder: UntypedFormBuilder,
    private errorService: NotificationErrorService,
    private contractTableService: ContractTableService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.getHoldingRaces();
    this.getUMS();
    this.getCriterii();
  }

  getHoldingRaces() {
    this.contractTableService.getAcbcrRacesAPI().subscribe((response) => {
      this.raseAnimal = response;
      const rasaNespecificata = {
        id: null,
        name: 'Nu contează',
        shortname: '',
      };
      this.raseAnimal = [...this.raseAnimal, rasaNespecificata];
      return response;
    });
  }

  getUMS() {
    this.contabilitateService.getUMSettings().subscribe((response) => {
      this.umSettings = response;
      return response;
    });
  }

  getCriterii() {
    this.contabilitateService.getCriteriiSettings().subscribe((response) => {
      this.criteriiList = response;
    });
  }

  selectActivity(event) {
    if (event) {
      this.activitateId = event.id;
      this.getServices(this.activitateId);
    } else {
      this.activitateId = null;
    }
  }

  clearAll() {
    this.eventType = null;
  }

  selectFactura(event) {
    if (event) {
      this.getSetttings(event);
    } else {
      this.activities = [];
      this.activitateId = null;
    }
  }

  getSetttings(tipFactura) {
    this.contabilitateService
      .getActivitiesSettings(tipFactura)
      .subscribe((activities) => {
        this.activities = activities;
      });
    this.submitted = false;
  }

  getServices(activitateId) {
    this.contabilitateService
      .getServicesSettings(activitateId)
      .subscribe((services) => {
        this.services = services;
        if (services) {
          this.buildForm(services);
        } else {
          this.buildForm();
        }
      });
    this.submitted = false;
  }

  buildForm(formData?) {
    this.settingsForm = this.formBuilder.group({
      services: this.formBuilder.array([]),
    });

    formData?.push({
      activitate_id: null,
      id: null,
      um: null,
      pu: '',
      criteriu: null,
      rasa: null,
      descriere: '',
      descriere_scurta: '',
    });

    this.createItems(formData);
  }

  get f() {
    return this.settingsForm.controls.services as UntypedFormArray;
  }

  createItems(data) {
    if (data && data.length > 0) {
      this.services.forEach((x, index) => {
        this.f.push(this.createItem(x));
        if (x && x.um?.includes('buc')) {
          this.criteriiToShow[index] = this.criteriiList.filter(
            (cr) => cr.id === 10
          );
        } else if (x && x.um?.includes('cap')) {
          this.criteriiToShow[index] = this.criteriiList.filter(
            (cr) => cr.id !== 10
          );
        } else {
          this.criteriiToShow[index] = [];
        }
      });
    } else {
      this.f.push(this.createItem());
    }
  }

  createItem(serviciu?): UntypedFormGroup {
    return this.formBuilder.group({
      activitate_id: [serviciu ? serviciu.activitateid : ''],
      serviciu_id: [serviciu ? serviciu.id : null],
      um: [serviciu ? serviciu.um : '', Validators.required],
      pu: [serviciu ? serviciu.pu : null, Validators.required],
      criteriu: [serviciu ? serviciu.criteriu : null],
      rasa: [serviciu ? serviciu.rasa : '', Validators.required],
      descriere_scurta: [
        serviciu ? serviciu.descriere_scurta : '',
        Validators.required,
      ],
      descriere: [serviciu ? serviciu.descriere : '', Validators.required],
    });
  }

  makeActive(i, event) {
    this.activeFields[i] = true;
  }

  umSelect(um, index) {
    this.settingsForm.controls.services.controls[index].controls.criteriu.setValue(null);

    if (um && um.um_code === 'buc') {
      this.criteriiToShow[index] = this.criteriiList.filter(
        (cr) => cr.id === 10
      );
    } else if (um && um.um_code === 'cap') {
      this.criteriiToShow[index] = this.criteriiList.filter(
        (cr) => cr.id !== 10
      );
    } else {
      this.criteriiToShow[index] = [];
    }
  }

  saveLineItem(i) {
    this.f.value[i].activitate_id = this.activitateId;
    this.contabilitateService
      .addServiciu(this.settingsForm.value.services[i])
      .subscribe(
        (response) => {
          this.getServices(this.activitateId);
          this.activeFields[i] = false;
          this.createItems([]);
        },
        (errors) => {
          this.errorService.processErrorMsg('Toate câmpurile sunt obligatorii');
        }
      );
  }

  editLineItem(i) {
    this.contabilitateService
      .updateServiciu(this.settingsForm.value.services[i])
      .subscribe(
        (response) => {
          this.getServices(this.activitateId);
          this.activeFields[i] = false;
        },
        (errors) => {
          this.errorService.processErrorMsg('Toate câmpurile sunt obligatorii');
        }
      );
  }

  deleteLineItem(i, id) {
    this.contabilitateService
      .deleteServiciu(id.value.serviciu_id)
      .subscribe((response) => {
        this.getServices(this.activitateId);
        this.activeFields.splice(i, 1);
      });
  }
}
