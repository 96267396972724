import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getAppHoldingsNames } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { HoldingsNames } from 'src/app/modules/acbcr/common/models/holdings.models';

@Component({
  selector: 'app-rapoarte-client-search',
  templateUrl: './rapoarte-client-search.component.html',
  styleUrls: ['./rapoarte-client-search.component.scss']
})
export class RapoarteClientSearchComponent implements OnInit, OnDestroy {
  @Output() searchResult = new EventEmitter();

  rapoarteClientSearchForm;
  holdingsNames: HoldingsNames[];
  showHint = true;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    this.store
    .pipe(select(getAppHoldingsNames))
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: HoldingsNames[]) => {
      this.holdingsNames = response;
    });
    this.rapoarteClientSearchForm = new UntypedFormGroup({
      data_start: new UntypedFormControl(null, Validators.required),
      data_end: new UntypedFormControl(null, Validators.required),
      expl_id: new UntypedFormControl(null, Validators.required),
    });
  }

  onSubmitted() {
    if (!this.rapoarteClientSearchForm.valid) {
      return;
    }
    this.searchResult.emit(this.rapoarteClientSearchForm.value);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  searchHoldings(event) {
    if (event.term.length >= 4) {
      this.showHint = false;
    } else {
      this.showHint = true;
    }
  }

  clearHoldings() {
    this.showHint = true;
  }

  resetSearch() {
    this.rapoarteClientSearchForm.reset();
    this.onSubmitted();
  }

  onStartDateChanged(event) {
    if (this.rapoarteClientSearchForm) {
      this.rapoarteClientSearchForm.controls.data_start.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.rapoarteClientSearchForm) {
      this.rapoarteClientSearchForm.controls.data_end.setValue(event);
    }
  }
}
