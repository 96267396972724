import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rapoarte-facturi-neachitate-search',
  templateUrl: './rapoarte-facturi-neachitate-search.component.html',
  styleUrls: ['./rapoarte-facturi-neachitate-search.component.scss']
})
export class RapoarteFacturiNeachitateSearchComponent implements OnInit, OnDestroy {
  @Output() searchResult = new EventEmitter();

  rapoarteFacturiNeachitateSearchForm;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.rapoarteFacturiNeachitateSearchForm = new UntypedFormGroup({
      data_start: new UntypedFormControl(null, Validators.required),
      data_end: new UntypedFormControl(null, Validators.required),
    });
  }

  onSubmitted() {
    if (!this.rapoarteFacturiNeachitateSearchForm.valid) {
      return;
    }
    this.searchResult.emit(this.rapoarteFacturiNeachitateSearchForm.value);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  resetSearch() {
    this.rapoarteFacturiNeachitateSearchForm.reset();
    this.onSubmitted();
  }

  onStartDateChanged(event) {
    if (this.rapoarteFacturiNeachitateSearchForm) {
      this.rapoarteFacturiNeachitateSearchForm.controls.data_start.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.rapoarteFacturiNeachitateSearchForm) {
      this.rapoarteFacturiNeachitateSearchForm.controls.data_end.setValue(event);
    }
  }
}
