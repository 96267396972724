import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-template-acte-aditionale',
  templateUrl: './template-acte-aditionale.component.html',
  styleUrls: ['./template-acte-aditionale.component.scss']
})
export class TemplateActeAditionaleComponent {
  @ViewChild('modalTemplate') modalTemplate: ElementRef;
  @Input() data: {'open': false};

  label: string;
  html: string = '';
  modalReference: NgbModalRef;
  showEditor = true;

  public Editor = DecoupledEditor;

  editorConfig = {
    toolbar: [
      'undo', 'redo',
      '|', 'heading',
      '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
      '|', 'bold', 'italic', 'underline', 'strikethrough',
      '|', 'link', 'insertTable', 'blockQuote', 'mediaEmbed',
      '|', 'alignment', '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
    ]
  };

  public onReady( editor: DecoupledEditor ): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(
      editor.ui.view.toolbar.element!,
      element
    );
  }

  constructor(
    public modalService: NgbModal,
    private helperDataService: HelperDataService,
  ) { }

  ngOnChanges(changes) {
    if (changes.data && this.data && this.data['open']) {
      this.label = this.data['label'];

      this.modalReference = this.modalService.open(this.modalTemplate, {
        windowClass: 'modal-half-full',
        modalDialogClass: 'h-100',
        scrollable: true,
      });

      this.helperDataService.getTemplate({key: this.data['key']})
        .subscribe({
          next: (response) => {
            this.html = response;
          }
        });
    }
  }

  salveazaTemplate() {
    const key = this.data['key'];
    let req_params = {
      [key]: this.html,
    };

    this.helperDataService.salveazaTemplate(req_params)
    .subscribe({
      next: (response) => {
        this.modalReference.close();
      }
    });
  }

  toggleShowEditor() {
    this.showEditor = ! this.showEditor;
  }
}
