<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="registruGenealogicTableService.pageSize"
                    (change)="changeTableRows($event)"
                    [disabled]="loadingDataSpinner"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm ml-1"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>

                <button
                  *ngIf="isAdmin || isOperator"
                  class="btn btn-success btn-sm ml-1"
                  type="button"
                  ngbTooltip="Se vor exporta doar animalele selectate"
                  [disabled]="loadingDataSpinner"
                  placement="bottom"
                  (click)="exportXls()"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive" #registreTable>
            <table
              id="registre-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th>Exploatație</th>
                  <th>Număr matricol</th>
                  <th>Rasa</th>
                  <th>Clasa (sex)</th>
                  <th>Nume</th>
                  <th>Data nașterii</th>
                  <th>Număr registru</th>
                  <th>Data registru</th>
                  <th>Secțiune</th>
                  <th>Eligibilitate</th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="registruGenealogicTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsit nici un animal.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>
              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let registru of registre">
                  <td>{{ registru.nume_exploatatie }}</td>
                  <td>{{ registru.numar_matricol }}</td>
                  <td>{{ registru.rasa }}</td>
                  <td>{{ registru.sex_text }}</td>
                  <td>{{ registru.nume }}</td>
                  <td>{{ registru.data_nastere_dmy }}</td>
                  <td>{{ registru.nr_reg_ro }}</td>
                  <td>{{ registru.data_reg_ro_dmy }}</td>
                  <td>{{ registru.sectiune_reg_ro_text }}</td>
                  <td>
                    <span
                      *ngIf="registru.eligibil"
                      role="button"
                      class="text-success"
                      (click)="viewReasons(registru.animal_id)"
                    >
                      <u>Eligibil</u>
                    </span>
                    <span
                      *ngIf="!registru.eligibil"
                      role="button"
                      class="text-danger"
                      (click)="viewReasons(registru.animal_id)"
                    >
                      <u>Neeligibil</u>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ registruGenealogicTableService.startIndex }} la
                {{ registruGenealogicTableService.endIndex }} din
                {{ registruGenealogicTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="registreLength"
                  [(page)]="registruGenealogicTableService.page"
                  [pageSize]="registruGenealogicTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-registru-genealogic-search
  [open]="openSearchModal"
  (searchingData)="filterSearch($event)"
>
</app-registru-genealogic-search>

<app-view-reason-modal
  [reasonModalData]="reasonModalData"
>
</app-view-reason-modal>
