import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';

import { FacturiTableService } from 'src/app/modules/acbcr/common/services/facturi-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-generare-facturi',
  templateUrl: './generare-facturi.component.html',
  styleUrls: ['./generare-facturi.component.scss']
})
export class GenerareFacturiComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('generareFacturiModal') generareFacturiModal: ElementRef;

  @Input() open = false;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  modalReference: NgbModalRef;

  modalType: string;
  generareFacturaForm: UntypedFormGroup;
  submitted = false;
  loadingDataSpinner = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public modalService: NgbModal,
    private facturiService: FacturiTableService,
    private helperDataService: HelperDataService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.generareFacturiModal, {
        windowClass: 'table-search-modal',
        scrollable: false,
        backdrop: 'static'
      });
    }

    this.buildForm();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  buildForm(formData?) {
    this.generareFacturaForm = new UntypedFormGroup({
      data: new UntypedFormControl(null, Validators.required),
      data_activitate : new UntypedFormControl(null, Validators.required),
      tip_factura: new UntypedFormControl(null, Validators.required),
      subventie: new UntypedFormControl(null, Validators.required),
    });


    this.submitted = false;
    this.generareFacturaForm.controls.subventie.setValue(false);
  }

  onSubmitted() {
    this.submitted = true;
    if (!this.generareFacturaForm.valid) {
      return;
    }
    this.loadingDataSpinner = true;
    this.facturiService.generareFacturi(this.generareFacturaForm.value)
    .subscribe((response: any) => {
      if (response.status === 200) {
        const blob = new Blob([response.body], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(blob, 'FacturaProforma.xlsx');
        this.loadingDataSpinner = false;
      }
      this.sendCallbackResult();
      this.loadingDataSpinner = false;
    });
  }

  onDataFacturilorChanged(event) {
    if (this.generareFacturaForm) {
      this.generareFacturaForm.controls.data.setValue(event);
    }
  }

  onDataSfarsitChanged(event) {
    if (this.generareFacturaForm) {
      this.generareFacturaForm.controls.data_activitate.setValue(event);
    }
  }

  get f() { return this.generareFacturaForm.controls; }

  notifyCloseModal() {
    this.notifyClose.emit(false);
  }

  private sendCallbackResult() {
    this.modalReference.close();
    this.callbackResult.emit('');
    this.notifyClose.emit(false);
  }

}


