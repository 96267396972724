import { JwtHelperService } from '@auth0/angular-jwt';

export class OptionsProvider {
  public static readonly user =
    (localStorage['access-token'] ? new JwtHelperService().decodeToken(localStorage['access-token']) : {});

  public static changeOptions() {
    Object.assign(OptionsProvider.user, new JwtHelperService().decodeToken(localStorage['access-token']));
  }
}
