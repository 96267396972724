import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  Input,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { takeUntil } from 'rxjs/operators';
import { getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { NgSelectComponent } from '@ng-select/ng-select';
import { City } from 'src/app/modules/acbcr/common/models/city.model';
import { AnimalRase } from 'src/app/modules/acbcr/common/models/animal.models';
import { PerformanceTableService } from 'src/app/modules/acbcr/common/services/performance-table.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-performance-search',
  templateUrl: './performance-search.component.html',
  styleUrls: ['./performance-search.component.scss']
})
export class PerformanceSearchComponent implements OnInit, OnDestroy {
  @Input() isFermier;
  @Output() searchResult = new EventEmitter();
  @ViewChild('citiesDropdown') citiesDropdown: NgSelectComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  cities: City[];
  performanceSearchForm;
  raseAnimal: AnimalRase[];
  isOjz = false;

  constructor(
    private store: Store<ApplicationState>,
    private performanceService: PerformanceTableService
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getAppCities))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.cities = response;
      });

    this.isOjz = UIHelper.isOjz();
    this.getAcbcrAnimalsRaces();

    this.performanceSearchForm = new UntypedFormGroup({
      countyid: new UntypedFormControl(null),
      comp_name: new UntypedFormControl(null),
      numar_matricol: new UntypedFormControl(null),
      rasa: new UntypedFormControl(null),
      sectiune: new UntypedFormControl(null),
      rg_clasa: new UntypedFormControl(null),
      apreciere_fenotipica: new UntypedFormControl(null),
      status: new UntypedFormControl(null),
    });

  }

  getAcbcrAnimalsRaces() {
    this.performanceService.getAcbcrRacesAPI().subscribe(response => {
      this.raseAnimal = response;
      return response;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onSubmitted() {
    this.searchResult.emit(this.performanceSearchForm.value);
  }

  resetSearch() {
    this.performanceSearchForm.reset();
    this.onSubmitted();
  }
}
