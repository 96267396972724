import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import {
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { HostListener } from '@angular/core';
import { AnimalHelper } from '../../../../common/helper/animal-helper';

@Component({
  selector: 'app-add-update-animal',
  templateUrl: './add-update-animal.component.html',
  styleUrls: ['./add-update-animal.component.scss']
})

export class AddUpdateAnimalComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('addAnimalModal') addAnimalModal: ElementRef;
  @Input() modalType: string;
  @Input() animalData: any;
  @Input() open = false;
  @Input() matricolChild: string;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();
  @Output() animalAdded = new EventEmitter();

  modalReference: NgbModalRef;
  items: UntypedFormArray;
  pozaItems: UntypedFormArray;
  bonitareModalOpened = false;

  animalForm;
  evaluareGenetica;
  areFatare;
  elemName = 'identificareAnimal';
  submitted = false;

  count: number;
  pozaCount: number;
  warningModal: boolean;
  pageLoaded: boolean;
  errorCodTaur: any;

  disabledSubmit;
  changedButtonText;
  updatedPedigreeTree;
  areCertificatChange = false;
  disableAllFields = false;
  fatherId: number;
  motherId: number;
  invalidAnimalSex = false;

  constructor(
    public modalService: NgbModal,
    private animalsTableService: AnimalsTableService,
    private fb: UntypedFormBuilder,
    private errorService: NotificationErrorService,
  ) { }
  ngOnInit(): void { }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.addAnimalModal, {
        windowClass: 'modal-full',
        scrollable: true,
        backdrop: 'static'
      });
    }

    if (this.animalData && this.isFormValid) {
      this.buildForm(this.animalData);
    } else {
      this.buildForm();
    }


    // Reset all fields when add mother and father modal mode is opened
    if (this.modalType === 'add-mother') {
      this.buildForm();
      this.animalForm.controls.sex.setValue('2');
    } else if (this.modalType === 'add-father') {
      this.buildForm();
      this.animalForm.controls.sex.setValue('1');
    }
  }

  ngAfterViewInit() {
    this.pageLoaded = true;
  }

  isFormValid(): boolean {
    if (this.pageLoaded) {
      return this.animalForm.valid;
    } else {
      return false;
    }
  }

  buildForm(formData?) {
    this.submitted = false;
    this.count = formData ? formData.rase.length : 1;
    this.evaluareGenetica = formData?.evaluare_genetica;
    this.areFatare = formData?.are_fatare;

    let status_animal = formData ? formData.status : 1;
    if (this.modalType == 'add-father' || this.modalType == 'add-mother') {
      status_animal = -1;
    }

    this.animalForm = new UntypedFormGroup({
      id: new UntypedFormControl(formData ? formData.id : ''),
      numarmatricol: new UntypedFormControl(
        formData ? formData.numarmatricol : '',
        Validators.required),
      nume: new UntypedFormControl(formData ? formData.nume : ''),
      datanastere: new UntypedFormControl(
        formData ? formData.datanastere : ''),
      sex: new UntypedFormControl(
        formData ? formData.sex.toString() : this.modalType === 'add-mother' ? '2' : '1',
        Validators.required),
      color: new UntypedFormControl(
        formData ? (formData.color !== null ? formData.color.toString() : null) : null),
      codtaur: new UntypedFormControl(formData ? formData.codtaur : ''),
      categoriefemela: new UntypedFormControl(
        formData && formData.categoriefemela !== null
          ? formData.categoriefemela.toString() : '0'),
      sistemreproductie: new UntypedFormControl(
        formData && formData.sistemreproductie !== null
          ? formData.sistemreproductie.toString() : '0'),
      tipfatare: new UntypedFormControl(
        formData && formData.tipfatare !== null
          ? formData.tipfatare.toString() : '0'),
      numaradn: new UntypedFormControl(
        formData ? formData.numaradn : ''),
      exploatatieid: new UntypedFormControl(
        formData ? formData.exploatatieid : null),
      crescatorid: new UntypedFormControl(
        formData ? formData.crescatorid : null),
      dataintrareinexploatatie: new UntypedFormControl(
        formData ? formData.dataintrareinexploatatie : ''),
      contractat: new UntypedFormControl(
        formData ? formData.contractat : '0'),
      status: new UntypedFormControl(status_animal),
      eligibilitatecz: new UntypedFormControl(
        formData ? formData.eligibilitatecz : ''),
      are_certificat_zootehnic: new UntypedFormControl(
        formData && formData.are_certificat_zootehnic ? true: false),
      taraorigineid: new UntypedFormControl(
        formData ? formData.taraorigineid : null),
      taraprovenientaid: new UntypedFormControl(
        formData ? formData.taraprovenientaid : null),
      nrregoriginal: new UntypedFormControl(
        formData ? formData.nrregoriginal : ''),
      family_tree: new UntypedFormControl(
        formData ? formData.family_tree : ''),
      mamapurtatoareid: new UntypedFormControl(
        formData ? formData.mamapurtatoareid : null),
      mamapurtatoarematricol: new UntypedFormControl(
        formData ? formData.mamapurtatoarematricol : ''),
      sectreggeneral: new UntypedFormControl(
        formData ? (formData.sectreggeneral ? formData.sectreggeneral : '0') : '0'),
      sectregro: new UntypedFormControl(
        formData ? (formData.sectregro ? formData.sectregro : '0') : '0'),
      nrregro: new UntypedFormControl(formData ? formData.nrregro : ''),
      data_rg: new UntypedFormControl(''),
      greutate_nastere: new UntypedFormControl(),
      gvr120: new UntypedFormControl(),
      gvr200: new UntypedFormControl(),
      gvr365: new UntypedFormControl(),
      gvr200_source: new UntypedFormControl(),
      gvr365_source: new UntypedFormControl(),
      smz120: new UntypedFormControl(),
      smz200: new UntypedFormControl(),
      smz365: new UntypedFormControl(),
      dm: new UntypedFormControl(formData ? formData.dm : null),
      ds: new UntypedFormControl(formData ? formData.ds : null),
      af: new UntypedFormControl(formData ? formData.af : null),
      cr: new UntypedFormControl(formData ? formData.cr : null),
      ifnais: new UntypedFormControl(formData ? formData.ifnais : null),
      crsevr: new UntypedFormControl(formData ? formData.crsevr : null),
      dmsev: new UntypedFormControl(formData ? formData.dmsev : null),
      dssev: new UntypedFormControl(formData ? formData.dssev : null),
      isevr: new UntypedFormControl(formData ? formData.isevr : null),
      avel: new UntypedFormControl(formData ? formData.avel : null),
      alait: new UntypedFormControl(formData ? formData.alait : null),
      ivmat: new UntypedFormControl(formData ? formData.ivmat : null),
      fileidfisabonitare: new UntypedFormControl(
        formData ? formData.fileidfisabonitare : null),
      filename_fisabonitare: new UntypedFormControl(
        formData ? formData.filename_fisabonitare : ''),
      file_id_apr_feno: new UntypedFormControl(
        formData ? formData.file_id_apr_feno : null),
      file_name_apr_feno: new UntypedFormControl(
        formData ? formData.file_name_apr_feno : ''),
      docnr_apr_feno: new UntypedFormControl(
        formData ? formData.docnr_apr_feno : ''),
      data_apr_feno: new UntypedFormControl(
        formData ? formData.data_apr_feno : ''),
      rase: this.fb.array([...AnimalHelper.createItems(this.count)]),
      poze: new UntypedFormArray([]),
      autorizatii_monta: new UntypedFormArray([]),
      test_adn: new UntypedFormArray([]),
      test_adn_files: new UntypedFormArray([]),
      corespunde_standard: new UntypedFormControl(formData ? formData.corespunde_standard : true),
      hist_corespunde_standard: new UntypedFormArray([]),
      are_avizare_tauri: new UntypedFormControl(formData?.are_avizare_tauri),
    });

    const familyTree = formData?.family_tree?.children;
    if (familyTree && familyTree?.length > 0) {
      this.fatherId = familyTree[0]?.id;
      this.motherId = familyTree[1]?.id;
    } else {
      this.fatherId = null;
      this.motherId = null;
    }

    this.disableAllFields = false;
    if (this.modalType === 'view') {
      this.disableAllFields = true;
    }

    if (formData) {
      const pozaList = [];
      formData.poze.map(poza => {
        let pozaForm = new UntypedFormGroup({
          fileid: new UntypedFormControl(poza.fileid),
          uploaded_file_name: new UntypedFormControl(poza.uploaded_file_name),
          filetype: new UntypedFormControl(poza.filetype),
        });
        this.animalForm.controls.poze.push(pozaForm);
      });
      this.animalForm.controls.rase.setValue(formData.rase);

      if (AnimalHelper.isEditingParents(this.modalType)) {
          this.disableAllFields = true;
      }

      for (var i = 0; i < formData.autorizatii_monta?.length; i++) {
        let autorizatie = formData.autorizatii_monta[i];

        let autorizatieForm = new UntypedFormGroup({
          id: new UntypedFormControl(autorizatie['id']),
          nr_document: new UntypedFormControl(autorizatie['nr_document'], Validators.required),
          data_document: new UntypedFormControl(autorizatie['data_document_dmy'], Validators.required),
          emitent: new UntypedFormControl(autorizatie['emitent'], Validators.required),
          data_inceput: new UntypedFormControl(autorizatie['data_inceput_dmy'], Validators.required),
          data_sfarsit: new UntypedFormControl(autorizatie['data_sfarsit_dmy'], Validators.required),
          file_id: new UntypedFormControl(autorizatie['file_id'], Validators.required),
          file_name: new UntypedFormControl(autorizatie['file_name']),
        });

        this.animalForm.controls.autorizatii_monta.push(autorizatieForm);
      }

      for (var i = 0; i < formData.test_adn?.length; i++) {
        let testADN = formData.test_adn[i];

        let testADNForm = new UntypedFormGroup({
          id: new UntypedFormControl(testADN.id),
          metoda: new UntypedFormControl(testADN.metoda),
          tip: new UntypedFormControl(testADN.tip),
          id_rg: new UntypedFormControl(testADN.id_rg),
          numar_adn: new UntypedFormControl(testADN.numar_adn),
          afiseaza_in_cz: new UntypedFormControl(testADN.afiseaza_in_cz),
          readonly: new UntypedFormControl(testADN.readonly),
        });

        this.animalForm.controls.test_adn.push(testADNForm);
      }

      for (var i = 0; i < formData.test_adn_files?.length; i++) {
        let testADNFile = formData.test_adn_files[i];

        let testADNFileForm = new UntypedFormGroup({
          id: new UntypedFormControl(testADNFile.id),
          fileid: new UntypedFormControl(testADNFile.fileid),
          uploaded_file_name: new UntypedFormControl(testADNFile.uploaded_file_name),
        });

        this.animalForm.controls.test_adn_files.push(testADNFileForm);
      }

      for (var i = 0; i < formData.hist_corespunde_standard?.length; i++) {
        let item = formData.hist_corespunde_standard[i];

        let form = new UntypedFormGroup({
          data_dmy: new UntypedFormControl(item.data_dmy),
          status_vechi: new UntypedFormControl(item.status_vechi),
          status_nou: new UntypedFormControl(item.status_nou),
          observatie: new UntypedFormControl(item.observatie),
          username: new UntypedFormControl(item.username),
          user_full_name: new UntypedFormControl(item.user_full_name),
        });

        this.animalForm.controls.hist_corespunde_standard.push(form);
      }
    }

    // If data doesn't exist in the animal, add fields
    if (formData && formData.rase.length < 1) {
      this.items = this.animalForm.get('rase') as UntypedFormArray;
      this.items.push(AnimalHelper.createItem());
    }

  }

  updatePedigreeTree(event) {
    this.updatedPedigreeTree = Math.random();
  }

  disableSubmitButton(event) {
    if (event === true) {
      this.disabledSubmit = true;
    } else {
      this.disabledSubmit = false;
    }
  }

  get rase(): UntypedFormArray {
    return this.animalForm.get('rase') as UntypedFormArray;
  }

  get poze(): UntypedFormArray {
    return this.animalForm.get('poze') as UntypedFormArray;
  }

  addRemoveRase(event): void {
    this.items = this.animalForm.get('rase') as UntypedFormArray;

    if (event === 'add') {
      this.items.push(AnimalHelper.createItem());
    } else {
      const control = this.animalForm.controls.rase as UntypedFormArray;
      control.removeAt(event.fields);
    }
  }

  deletedImage(event) {
    if (event === true) {
      this.changedButtonText = true;
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open && !this.bonitareModalOpened) {
      this.notifyCloseModal();
    }
  }

  bonitareModalChanged(event) {
    this.bonitareModalOpened = event;
  }

  notifyCloseModal() {
    if (this.modalType === 'add' || this.modalType === 'add-mother' || this.modalType === 'add-father') {
      const pozeDelete = this.animalForm.value.poze;

      if (pozeDelete) {
        pozeDelete.map(poza => {
          if (poza && poza.fileid) {
            this.animalsTableService.deletePozaAnimal(poza.fileid)
              .subscribe();
          }
        });
      }
    }

    this.disabledSubmit = false;
    this.invalidAnimalSex = false;
    this.notifyClose.emit(null);
    this.elemName = 'identificareAnimal';
    this.animalForm.reset({});
    this.animalForm.controls.rase = new UntypedFormArray([
      this.fb.group({ raceid: '', val: '' })
    ]);
    this.animalForm.controls.poze = new UntypedFormArray([
      this.fb.group({ fileid: '', uploaded_file_name: '', filetype: ''})
    ]);
  }

  scrollToElement(elem) {
    let domElem = document.querySelector(elem);

    if (!domElem) {
      return;
    }

    this.elemName = elem;
    domElem.scrollIntoView({behavior: 'smooth'});
  }

  populateFields(event) {
    if (event) {

      if (event.id === -1) {
        this.updatedPedigreeTree = Math.random();
      } else {
        this.animalsTableService.getAnimalAPI(event.id).subscribe(response => {
          this.animalData = response;
          this.buildForm(this.animalData);
        });
      }

    } else {
      this.buildForm();
    }
  }

  updateAnimal(event) {
    this.onSubmitted(event);
  }

  onSubmitted(closeModal: boolean) {
    if (this.disableAllFields) {
      this.addExistentParent();
      return;
    }

    let formValid = true;
    const raseFinal = this.animalForm.value.rase;
    let totalValue = 0;
    const raseArray = [];
    for (const rasaFinal of raseFinal) {
      totalValue += parseInt(rasaFinal.val, 10);
      if (raseArray.find(element => element === rasaFinal.raceid)) {
        formValid = false;
        break;
      }
      raseArray.push(rasaFinal.raceid);
    }
    if (totalValue > 100) {
      this.warningModal = true;
      this.errorService.processErrorMsg('procent rasa mai mare decat 100');
    } else if (!formValid) {
      this.warningModal = true;
      this.errorService.processErrorMsg('rase similare');
    } else {
      if (this.animalForm.value.sex !== '1') {
        this.animalForm.controls.codtaur.setValue('');
        const codTaur = this.animalForm.get('codtaur');
        codTaur.clearValidators();
        codTaur.updateValueAndValidity();
      }

      this.warningModal = false;
      this.submitted = true;

      if (this.animalForm.value.sex === '1') {
        this.animalForm.value.categoriefemela = '0';
      }

      if (this.animalForm.value.sex !== '1' && this.animalForm.value.sex !== '2') {
        this.invalidAnimalSex = true;
        return;
      }

      if (!this.animalForm.invalid) {

        const familyTree = this.animalForm.value.family_tree;
        if (familyTree?.children) {
          const tataid = familyTree.children[0].id;
          const mamaid = familyTree.children[1].id;
          this.animalForm.value.mamaid = mamaid;
          this.animalForm.value.tataid = tataid;
        } else {
          this.animalForm.value.mamaid = null;
          this.animalForm.value.tataid = null;
        }

        this.addExistentParent();

        switch (this.modalType) {
          case 'add':
          case 'add-mother':
          case 'add-father':
            this._saveNewAnimal();
            break;
          case 'edit':
          case 'edit-mother':
          case 'edit-father':
            this._updateExistingAnimal(closeModal);
            break;
        }
      } else if (this.animalForm.invalid) {
        this.scrollToError();
      }
    }
  }

  closeUpdateModal() {
    this.modalReference.close();
    this.notifyClose.emit(false);
    this.callbackResult.emit(true);
  }

  private addExistentParent() {
    const existentParent = this.animalForm.value.id;
    if (AnimalHelper.isEditingParents(this.modalType) && existentParent) {

      this.notifyClose.emit(this.animalForm.value);
      this.modalReference.close();
      return;
    }
  }

  private _saveNewAnimal() {
    // Convert status from boolean to number
    if (this.animalForm.value.status === true) {
      this.animalForm.value.status = 1;
    }
    if (this.animalForm.value.status === false) {
      this.animalForm.value.status = 0;
    }

    const newtataid = this.animalForm.value.tataid;
    const newmamaid = this.animalForm.value.mamaid;

    this.animalsTableService
      .addAnimal(this.animalForm.value)
      .subscribe((response: any) => {

        if (!response.error) {
          this.animalForm.value.id = response.id;
          this.notifyClose.emit(this.animalForm.value);
        }

        this.notifyClose.emit(null);
        this.elemName = 'identificareAnimal';
        this.modalReference.close();
        this.animalForm.reset();
        this.errorCodTaur = false;
        this.invalidAnimalSex = false;
        this.animalAdded.emit(null);
      });
  }

  private _updateExistingAnimal(closeModal: boolean) {
    const newtataid = this.animalForm.value.tataid;
    const newmamaid = this.animalForm.value.mamaid;
    this.animalsTableService
      .updateAnimal(this.animalForm.value)
      .subscribe(response => {
        if (!closeModal) {
          return;
        }

        this.notifyClose.emit(false);
        this.elemName = 'identificareAnimal';
        this.modalReference.close();
        this.animalForm.reset();
        this.errorCodTaur = false;
        this.invalidAnimalSex = false;
        this.callbackResult.emit(response);
      });
  }

  private scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

}
