import { Component, OnInit } from '@angular/core';
import { MenuTab } from 'src/app/modules/acbcr/common/models/common.models';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-documente',
  templateUrl: './documente.component.html',
  styleUrls: ['./documente.component.scss']
})
export class DocumenteComponent implements OnInit {

  public  documentsTabs: MenuTab[] = [
    { path: 'anexe-3', name: 'Anexe 3' },
    { path: 'anexe-8', name: 'Anexe 8' },
    { path: 'solicitari-certificate-zootehnice', name: 'Solicitări certificate zootehnice' },
    { path: 'certificate-zootehnice', name: 'Certificate zootehnice' },
    { path: 'adeverinte-apartenenta', name: 'Adeverinte apartenenta' },
  ];

  constructor() { }

  ngOnInit(): void {
    if (!UIHelper.isAutoritateJudeteana()) {
      this.documentsTabs.unshift({ path: 'contracte', name: 'Contracte' });
    }
  }

}
