import {
  Component, ElementRef, ViewChild, OnInit, OnChanges, Input, Output, EventEmitter
} from '@angular/core';
import {
  UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { SolicitariCertificateZootehniceService } from 'src/app/modules/acbcr/common/services/solicitari-certificate-zootehnice.service';

@Component({
  selector: 'app-add-edit-solicitari-certificate-zootehnice',
  templateUrl: './add-edit-solicitari-certificate-zootehnice.component.html',
  styleUrls: ['./add-edit-solicitari-certificate-zootehnice.component.scss']
})
export class AddEditSolicitariCertificateZootehniceComponent implements OnInit, OnChanges {
  @ViewChild('addEditTemplate') addEditTemplate: ElementRef;
  @Input() addEditModalData: any;
  @Output() notifyClose = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  loadingDataSpinner = false;
  loadingAnimale = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  isAutoritateJudeteana = false;
  isContabil = false;

  addEditModalType: string;
  modalReference: NgbModalRef;
  submitted = false;
  animaleSelectItems = [];
  exploatatiiSelectItems = [];
  animalDeAdaugat;
  readonly = false;
  solicitareForm: UntypedFormGroup;
  fileName;

  constructor(
    public solicitariCertificateZootehniceService: SolicitariCertificateZootehniceService,
    private store: Store<ApplicationState>,
    public modalService: NgbModal,
    public errorService: NotificationErrorService,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
    this.buildForm();
  }

  ngOnChanges(changes) {
    if (changes.addEditModalData && ! changes.addEditModalData.firstChange) {
      this.modalReference = this.modalService.open(this.addEditTemplate, {
        windowClass: 'modal-half-full',
        modalDialogClass: 'h-100',
        scrollable: true,
      });

      this.submitted = false;
      this.readonly = false;
      this.animaleSelectItems = [];
      this.exploatatiiSelectItems = [];
      let adeverinta_id = null;

      if (this.addEditModalData && this.addEditModalData.id) {
        adeverinta_id = this.addEditModalData.id;
      }

      if (adeverinta_id) {
        this.loadingDataSpinner = true;
        this.addEditModalType = 'edit';
        this.solicitariCertificateZootehniceService.getAPI({id: adeverinta_id})
          .subscribe({
            next: (response) => {
              this.loadingDataSpinner = false;

              if (!response) {
                return;
              }

              this.readonly = response.readonly;
              this.exploatatiiSelectItems = response.exploatatii || [];
              this.buildForm(response);

              if (!this.readonly) {
                this.findAnimals(true);
              }
            },
            error: (errors) => {
              this.loadingDataSpinner = false;
              this.errorService.processErrorMsg(errors.error.error);
            }
          });
      } else {
        this.addEditModalType = 'add';
        this.buildForm();

        this.store.pipe(select(getCurrentRoute))
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: string) => {
          if ((response && response.includes('/exploatatie')) || this.isFermier) {
            this.store.pipe(
              select(getLoadingState),
              filter(isLoading => !isLoading),
              switchMap(() => this.store.pipe(select(getSelectedHolding))),
              distinctUntilChanged((prev, curr) => prev.id === curr.id),
              takeUntil(this.destroy$),
            ).subscribe((response: any) => {
              if (response && response.id) {
                this.solicitareForm.controls.exploatatie_id.setValue(response.id);
                this.exploatatiiSelectItems = [{
                  id: response.id,
                  name: response.name,
                  code: response.code,
                  viewLabel: `${response.code} - ${response.name}`,
                }];

                this.findAnimals(true);
              }
            });
          } else {
            this.solicitareForm.controls.exploatatie_id.setValue(null);
            this.exploatatiiSelectItems = [];
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  buildForm(formData?) {
    let data_adev = formData?.data_dmy || UIHelper.getCurrentDate();
    this.fileName = formData?.file_name;

    this.solicitareForm = new UntypedFormGroup({
      id: new UntypedFormControl(formData?.id),
      exploatatie_id: new UntypedFormControl(formData?.exploatatie_id, Validators.required),
      file_id: new UntypedFormControl(formData?.file_id),
      numar: new UntypedFormControl(formData?.numar),
      data: new UntypedFormControl(data_adev, Validators.required),
      animale: new UntypedFormArray([]),
    });

    if (!formData?.animale) {
      return;
    }

    for (let animal of formData.animale) {
      this.adaugaAnimalForm(animal);
    }
  }

  get animaleArrayForm(): UntypedFormArray{
    return this.solicitareForm.get('animale') as UntypedFormArray;
  }

  onDateChanged(event) {
    this.solicitareForm.controls.data.setValue(event);
    this.findAnimals(true);
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.exploatatiiSelectItems = [];

      return;
    }

    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatiiSelectItems = response;
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  findAnimals(load?) {
    if (!load) {
      this.animaleArrayForm.clear();
    }

    this.animaleSelectItems = [];
    const exploatatie_id = this.solicitareForm.value.exploatatie_id;
    const data = this.solicitareForm.value.data;

    if (!exploatatie_id || !data) {
      return;
    }

    this.loadingAnimale = true;
    const req_params = {
      exploatatie_id: exploatatie_id,
      data: data,
    };

    this.solicitariCertificateZootehniceService
      .animaleAPI(req_params)
      .subscribe({
        next: (response) => {
          this.loadingAnimale = false;
          this.animaleSelectItems = response;

          if (load) {
            this.disableAnimaleSelectItems();
          }
        },
        error: (errors) => {
          this.loadingAnimale = false;
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  adaugaAnimal(element, event) {
    const animal_id = event?.id;

    if (animal_id) {
      const animal = this.animaleSelectItems.find(
        (animal) => animal.id === animal_id
      );

      this.adaugaAnimalForm(animal);
      this.animaleSelectItems = this.animaleSelectItems.map(
        (animal) => {
          if (animal.id === animal_id) {
            animal.disabled = true;
          }

          return animal;
        }
      );
    }

    if (element && element.hasValue) {
      element.clearModel();
    }
  }

  adaugaAnimalForm(animal) {
    let animalForm = new UntypedFormGroup({
      id: new UntypedFormControl(animal.id),
      numarmatricol: new UntypedFormControl(animal.numarmatricol),
      sex: new UntypedFormControl(animal.sex),
      rasa: new UntypedFormControl(animal.rasa),
      status_solicitare_cz: new UntypedFormControl(animal.status_solicitare_cz),
    })

    this.animaleArrayForm.push(animalForm);
  }

  deleteAnimal(animalForm, index) {
    const animal_id = animalForm.value.id;
    this.animaleArrayForm.removeAt(index);
    this.animaleSelectItems = this.animaleSelectItems.map(
      (animal) => {
        if (animal.id === animal_id) {
          animal.disabled = false;
        }

        return animal;
      }
    );
  }

  disableAnimaleSelectItems() {
    let selectedList = this.animaleArrayForm.value.map(
      (animal) => {
        return animal.id;
      }
    )

    this.animaleSelectItems = this.animaleSelectItems.map(
      (animal) => {
        if (selectedList.includes(animal.id)) {
          animal.disabled = true;
        }

        return animal;
      }
    );
  }

  onSubmitted() {
    this.submitted = true;

    if (!this.solicitareForm.valid) {
      return;
    }

    this.solicitariCertificateZootehniceService.salveazaAPI(this.solicitareForm.value)
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.solicitareForm.reset();
          this.modalReference.close();
          this.notifyClose.emit(true);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  uploadServerResponse(event) {
    const file = event.body.files[0];
    this.fileName = file.file_uploaded_name;
    this.solicitareForm.controls.file_id.setValue(file.file_id);
  }

  downloadFile(event) {
    event.target.disabled = true;
    let fileId = this.solicitareForm.value.file_id;

    this.helperDataService.downloadFile(fileId)
      .subscribe({
        next: (response: any) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, this.fileName);
          event.target.disabled = false;

        },
        error: (errors) => {
          let blobToTextPromise = errors.error.text();
          blobToTextPromise.then((text: string) => {
            let obj;
            try {
              obj = JSON.parse(text);
            } catch (e) {
              return;
            }

            this.errorService.processErrorMsg(obj.error);
          })
        }
      });
  }

  deleteFile() {
    this.solicitareForm.controls.file_id.setValue(null);
    this.fileName = null;
  }
}
