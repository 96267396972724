import { Component, OnInit, Input, ViewChildren, QueryList,
  ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AdvancedSortableDirective, SortEvent } from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { Subject } from 'rxjs';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { AdeverintaApartenenta } from 'src/app/modules/acbcr/common/models/adeverinte-apartenenta.model';
import { AdeverinteApartenentaTableService } from 'src/app/modules/acbcr/common/services/adeverinte-apartenenta-table.service';
import { Animals } from 'src/app/modules/acbcr/common/models/animal.models';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-adeverinte-apartenenta-table',
  templateUrl: './adeverinte-apartenenta-table.component.html',
  styleUrls: ['./adeverinte-apartenenta-table.component.scss']
})
export class AdeverinteApartenentaTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective>;
  @ViewChild('adeverinteApartenentaTable', { static: false }) adeverinteApartenentaTable: ElementRef;

  @Input() adeverinteApartenenta: AdeverintaApartenenta[];
  @Input() adeverinteApartenentaLength: number;
  @Input() loadingDataSpinner: boolean;
  @Input() currentHolding: any;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  readonly modalType = 'adeverinta';
  openSearchModal = new Subject<boolean>();
  openAddEditModal = false;
  openViewModal = false;
  openAdeverintaApartenentaModal = false;
  adeverinteApartenentaData: any;
  aboutAdeverintaData: any;
  addEditModalType: string;
  vaciRaseCarne: Animals[];
  vaciMetise: Animals[];
  tauriCarne: Animals[];
  tineretRaseCarne: Animals[];
  tineretMetis: Animals[];

  destroy$: Subject<boolean> = new Subject<boolean>();
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  openConfirmModal = false;
  confirmType: string;
  currentAdeverinta = null;
  adeverintaNumber;

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public adeverintaApartenentaTableService: AdeverinteApartenentaTableService,
    private helperDataService: HelperDataService,
    private errorService: NotificationErrorService
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.adeverintaApartenentaTableService.sortColumn = column;
    this.adeverintaApartenentaTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  exportTableXLSX() {
  }

  openAdeverinteApartenentaModal() {
    this.openAdeverintaApartenentaModal = true;
  }

  openAddAdeverintaModal(type) {
    this.adeverinteApartenentaData = '';
    this.addEditModalType = type;
    this.openAddEditModal = true;
  }

  openViewAllowanceModal(adev) {
    this.aboutAdeverintaData = {
      numar: adev.numar,
      data: adev.data,
      compname: adev.comp_name,
      autor: adev.autor,
      companyid: adev.comp_id,
      id: adev.id,
      status: adev.status
    };

    this.adeverintaApartenentaTableService.getAdeverintaAPI(adev.comp_id, adev.id).subscribe(response => {
      this.vaciRaseCarne = response.filter(animal => animal.tip === 0 );
      this.vaciMetise = response.filter(animal => animal.tip === 1 );
      this.tauriCarne = response.filter(animal => animal.tip === 2 );
      this.tineretRaseCarne = response.filter(animal => animal.tip === 3 );
      this.tineretMetis = response.filter(animal => animal.tip === 4 );
      this.openViewModal = true;
    });
  }

  closeViewModal(event) {
    this.openViewModal = event;
  }

  closeAddEditModal(event) {
    this.openAddEditModal = event;
  }

  getCallbackResult(event) {
    this.callbackResult.emit(event);
  }

  downloadAdeverinta(adeverinta) {
    this.adeverintaApartenentaTableService.downloadAdeverintaApi(adeverinta.id)
      .subscribe((response: any) => {
        const fileName = adeverinta.comp_code + '_' + adeverinta.numar
          + '_' + adeverinta.data + '.pdf';
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, fileName);
        this.callbackResult.emit('');
      });
  }

  openConfirmActionModal(adeverinta, type: string) {
    this.openConfirmModal = true;
    this.currentAdeverinta = adeverinta;
    this.confirmType = type;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event !== true) {
      this.currentAdeverinta = null;
      return;
    }

    let obj: any = { companyid: this.currentAdeverinta.comp_id, documentid: this.currentAdeverinta.id, status: 0};

    switch (this.confirmType) {

      case 'valideaza-adeverinta':
        obj.status = 2;
        obj.numar = this.currentAdeverinta.numar;
        obj.data = this.currentAdeverinta.data;

        this.adeverintaApartenentaTableService.saveAdeverintaApi(obj)
          .subscribe({
            next: (response) => {
              this.callbackResult.emit('');
            },
            error: (errors) => {
              this.errorService.processErrorMsg(errors.error.error);
            }
          });

        break;
      case 'sterge-adeverinta':
        this.adeverintaApartenentaTableService.deleteAdeverintaAPI({doc_id: this.currentAdeverinta.id})
          .subscribe({
            next: (response) => {
              this.callbackResult.emit('');
            },
            error: (errors) => {
              this.errorService.processErrorMsg(errors.error.error);
            }
          });

        break;
      case 'anuleaza-adeverinta':
        obj.status = 5;
        this.adeverintaApartenentaTableService.saveAdeverintaApi(obj)
          .subscribe({
            next: (response) => {
              this.callbackResult.emit('');
            },
            error: (errors) => {
              this.errorService.processErrorMsg(errors.error.error);
            }
          });

        break;
    }

    this.currentAdeverinta = null;
  }

}
