import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import {
  SortableTable,
  convertDateToDisplayFormat,
  convertDateToBackendFormat
} from './services-table-helper';
import { AdeverintaApartenenta } from '../models/adeverinte-apartenenta.model';

@Injectable({
  providedIn: 'root'
})
export class AdeverinteApartenentaTableService extends SortableTable<AdeverintaApartenenta> {
  private baseUrl = environment.API_URL;

  adeverinteApartenenta: AdeverintaApartenenta[];
  adeverinteApartenentaLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.adeverinteApartenenta, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }

  getAdeverinteAPI(obj, holdingId?) {
    return this.http.post(`${this.baseUrl}/companies/list-adeverinta-apartenenta/`,
      { ...obj, companyid: holdingId }
    ).pipe(
      map((response: any) => {
        this.adeverinteApartenenta = response.result;
        this.adeverinteApartenentaLength = response.count;
        this.adeverinteApartenenta.map((adeverinta: AdeverintaApartenenta) => {
          adeverinta.data = convertDateToDisplayFormat(adeverinta.data);
        });

        this.paginate();
        this.sortEvent();
        this._total$.next(this.adeverinteApartenentaLength);

        return response;
      })
    );
  }

  getAdeverintaAPI(holdingId?, documentId?) {
    return this.http.post(`${this.baseUrl}/companies/list-adeverinta-apartenenta/`,
      { companyid: holdingId, documentid: documentId }
    ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAnimalsByTypeAPI(holdingId?, type?, data?) {
    return this.http.post(`${this.baseUrl}/companies/create-adeverinta-apartenenta/`,
      { companyid: holdingId, type, data: convertDateToBackendFormat(data) }
    ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveAdeverintaApi(obj) {
    return this.http
      .post(
        `${this.baseUrl}/companies/addupd-adeverinta-apartenenta/`,
        {
          ...obj, data: convertDateToBackendFormat(obj.data),
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        }, errors => {
          return errors;
        })
      );
  }

  deleteAdeverintaAPI(obj) {
    return this.http.post(`${this.baseUrl}/companies/delete-adeverinta-apartenenta/`,
      obj
    ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadAdeverintaApi(adeverintaId) {
    return this.http
      .post(
        `${this.baseUrl}/companies/generate-adeverinta-apartenenta/`,
        { documentid: adeverintaId },
        {
          responseType: 'blob'
        }
      ).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.adeverinteApartenentaLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.adeverinteApartenentaLength) {
      this._state.endIndex = this.adeverinteApartenentaLength;
    }
  }
}
