<app-raport-jurnal-table
  [rapoarteJurnal]="tables$ | async"
  [rapoarteJurnalLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (rrapoarteJurnalTableCallback)="getTableData()"
></app-raport-jurnal-table>
