import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil, take, filter, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AnimalsTableService } from '../../../common/services/animals-table.service';
import { HoldingsTableService } from './../../../common/services/holdings-table.service';

import {
  SortEvent,
  AdvancedSortableDirective
} from '../../../directives/advanced-sortable.directive';
import { Animals, SearchAnimal } from '../../../common/models/animal.models';
import { ApplicationState } from '../../../common/state/app.state';
import { getCurrentRoute } from './../../../common/state/reducers/route.reducers';
import { getLoadingState, getSelectedHolding } from '../../../common/state/reducers/holdings.reducers';
import { HelperDataService } from '../../../common/services/helper-data.service';
import { NotificationErrorService } from '../../../common/services/notification-error.service';
import { SetAnimalsFilter } from '../../../common/state/actions/filters.actions';
import { getAnimalsFilter } from '../../../common/state/reducers/filters.reducers';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-animals',
  templateUrl: './animals.component.html',
  styleUrls: ['./animals.component.scss'],
  providers: [AnimalsTableService, DecimalPipe, HoldingsTableService]
})
export class AnimalsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('animalsTable', { static: false }) holdingsTable: ElementRef;

  // Table data
  tables$: Observable<Animals[]>;
  animalEditData$: Observable<Animals[]>;
  total$: Observable<number>;
  modalType: string;
  animalData: any;
  openSearchModal = new Subject<boolean>();
  openConfirmModal = false;
  openAddEditModal = false;
  loadingDataSpinner = true;
  onlySearch = false;

  // Default values for search animals filter
  objToAPI: SearchAnimal = {
    numarmatricol: '',
    nume: '',
    sex: '',
    rasa: null,
    codtaur: '',
    status: 1,
    page_nr: '1',
    page_size: '10',
    judetid: null,
    exploatatieid: null,
    localitateid: null,
  };

  destroy$: Subject<boolean> = new Subject<boolean>();
  currentRoute;
  exploatationId: number;
  animalId: number;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  onExploatatie = false;
  isFiltered = false;

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private router: Router,
    public animalTableService: AnimalsTableService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
    private errorService: NotificationErrorService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.tables$ = animalTableService.tables$;
    this.total$ = animalTableService.total$;

    if (this.router.url.includes('?filter')) {
      this.store.pipe(select(getAnimalsFilter))
      .pipe(take(1)).subscribe((filter: SearchAnimal) => {
        if (filter) {
          this.objToAPI = filter;
          this.checkAnimalsFilter(this.objToAPI);
          this.animalTableService.page = parseInt(filter.page_nr, 10);
          this.animalTableService.pageSize = parseInt(filter.page_size, 10);
        }
      });
    }
  }

  ngOnInit() {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    // Read current app route from the store
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((route: string) => {
        this.currentRoute = route;
        if (this.currentRoute.includes('/animale-exploatatie')) {
          this.store.pipe(
            select(getLoadingState),
            filter(isLoading => !isLoading),
            switchMap(() => this.store.pipe(select(getSelectedHolding))),
            distinctUntilChanged((prev, curr) => prev.id === curr.id),
            takeUntil(this.destroy$),
          ).subscribe((response: any) => {
              if (response?.id) {
                this.onExploatatie = true;
                this.exploatationId = response.id;
                this.objToAPI = {
                  ...this.objToAPI,
                  exploatatieid: this.exploatationId
                };
                this._getAnimalTableData();
              }
            });
        } else if (!this.onExploatatie) {
          this._getAnimalTableData();
        }
    });
  }

  ngAfterViewInit() {
    if (this.objToAPI.order_by) {
      this.setTableHeaders(this.objToAPI.order_by);
    }

    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === 'asc') {
      this.objToAPI.order_by = column;
    } else if (direction === 'desc') {
      this.objToAPI.order_by = '-' + column;
    } else {
      delete this.objToAPI.order_by;
    }

    this._getAnimalTableData();
  }

  exportTableXLSX() {
    let now = UIHelper.now();
    let fisier = `Animale_${now}.xls`;

    this.animalTableService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, fisier);
      });
  }

  openAddEditAnimalModal(type: string, id?: number) {
    switch (type) {
      case 'add':
        this.animalData = null;
        this.openAddEditModal = true;
        this.modalType = type;
        break;
      case 'edit':
      case 'view':
        this.animalTableService.getAnimalAPI(id).subscribe(response => {
          this.animalData = response;
          this.openAddEditModal = true;
          this.modalType = type;
        });
        break;
    }
  }

  // Get data from the back-end when the user change the table page
  changeTablePage(event) {
    this.objToAPI.page_nr = event;
    this._getAnimalTableData();
  }

  // Get data from the back-end when the user change the number of rows are dispalyed on the table
  changeTableRows(event) {
    this.objToAPI.page_size = event.target.value;
    this.objToAPI.page_nr = '1';
    this.animalTableService.page = 1;
    this._getAnimalTableData();
  }

  openTableSearchModal(type: string) {
    this.openSearchModal.next(true);
    this.modalType = type;
  }

  closeAddUpdateModal(event) {
    this.openAddEditModal = false;
    this.animalData = null;
  }

  openConfirmActionModal(animalId) {
    this.openConfirmModal = true;
    this.animalId = animalId;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.deleteAnimal(this.animalId);
    }
    this.animalId = null;
  }

  _getAnimalTableData() {
    this.loadingDataSpinner = true;
    this.animalTableService.getAnimalsAPI(this.objToAPI).subscribe(response => {
      // Hide spinner, after 1 second
      setTimeout(() => {
        this.loadingDataSpinner = false;
      }, 500);
    });
  }

  // Filtered search
  filterSearch(searchingData) {
    this.animalTableService.page = 1;

    this.objToAPI = {
      ...searchingData,
      ...{ judetid: searchingData.judetid !== null ? searchingData.judetid.id : '' },
      ...{ localitateid: searchingData.localitateid !== null ? searchingData.localitateid.id : '' },
      page_nr: this.animalTableService.page,
      page_size: this.animalTableService.pageSize
    };

    if (this.exploatationId !== undefined) {
      this.objToAPI.exploatatieid = this.exploatationId;
    }

    this.checkAnimalsFilter(searchingData);
    if (Object.values(this.objToAPI) !== null) {
      this.onlySearch = true;
    }

    this._getAnimalTableData();
  }

  private deleteAnimal(animalId) {
    this.animalTableService.deleteAnimal(animalId)
      .subscribe(response => {
        if (!response) {
          this._getAnimalTableData();
        }
      }, errors => {
        this.errorService.processErrorMsg(errors.error.error);
      });
  }

  onAnimalAdded(event) {
    this._getAnimalTableData();
  }

  onAnimalMatricolClick(animal: Animals) {
    delete this.objToAPI.exploatatieid;
    this.store.dispatch(new SetAnimalsFilter(this.objToAPI));
  }

  generateFisaAnimal(animalId) {
    this.animalTableService.generateFisaAnimal(animalId)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, `Fisa_animal_${animalId}.pdf`);
      });
  }

  private checkAnimalsFilter(filter: SearchAnimal) {
    if (filter.numarmatricol || filter.exploatatieid || filter.status || filter.sex ||
        filter.rasa || filter.codtaur || filter.judetid || filter.localitateid) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }
  }

  private setTableHeaders(order: string) {
    let desc = false;
    if (order.includes('-')) {
      desc = true;
      order = order.slice(order.indexOf('-') + 1 , order.length);
    }

    this.headers.forEach(header => {
      if (header.sortable === order) {
        if (desc) {
          header.direction = 'desc';
        } else {
          header.direction = 'asc';
        }
      }
    });
  }
}
