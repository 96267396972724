import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-animale-rne',
  templateUrl: './animale-rne.component.html',
  styleUrls: ['./animale-rne.component.scss']
})
export class AnimaleRneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
