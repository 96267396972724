<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="movesTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>

                <button
                  class="btn btn-blue btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openMiscariModal()"
                  *ngIf="currentRoute.includes('/exploatatie/') && (isAdmin || isOperator || isFermier)"
                >
                <i class="fe-save"></i>
                  Adaugă mișcare (Anexa 10)
                </button>

                <button
                  *ngIf="isAdmin || isOperator"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                  [disabled]="loadingDataSpinner || movesLength === 0"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #movesTable>
            <table
              id="moves-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="numarmatricol" (sort)="onSort($event)">
                    Număr matricol
                  </th>
                  <th sortable="motiv" (sort)="onSort($event)">
                    Motiv
                  </th>
                  <th sortable="data_miscare" (sort)="onSort($event)">
                    Dată mișcare
                  </th>
                  <th sortable="data_anexa10" (sort)="onSort($event)">
                    Anexa 10
                  </th>
                  <th scope="col">Actiuni</th>
                </tr>
              </thead>

              <div
                *ngIf="loadingDataSpinner"
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
              ></div>

              <ngb-alert
                *ngIf="!loadingDataSpinner && movesTableService.totalRecords === 0"
                type="danger"
                class="text-center search-error"
                [dismissible]="false"
              >
                Nu a fost găsită nici o mișcare în urma căutării dvs.
              </ngb-alert>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let move of moves">
                  <td> {{move.numarmatricol}} </td>
                  <td [ngSwitch]="move.motiv">
                    <span *ngSwitchCase="0 || null">Nespecificat</span>
                    <span *ngSwitchCase="1">Abatorizare</span>
                    <span *ngSwitchCase="2">Vânzare</span>
                    <span *ngSwitchCase="3">Cumpărare</span>
                    <span *ngSwitchCase="4">Transfer</span>
                    <span *ngSwitchCase="5">Pierdere</span>
                    <span *ngSwitchCase="6">Moarte</span>
                    <span *ngSwitchCase="7">Vânzare exploatație neafiliată</span>
                    <span *ngSwitchCase="8">Sacrificare consum propriu</span>
                  </td>
                  <td> {{move.data_miscare}} </td>
                  <td>
                    <a
                      *ngIf="move.anexa10id !== null"
                      (click)="openAnexa10(move.anexa10id)"
                      href="javascript: void(0);">
                      {{ move.numar_anexa10 }} / {{ move.data_anexa10 }}
                    </a>
                  </td>
                  <td class="action-buttons">
                    <button
                      *ngIf="isAdmin || isOperator"
                      type="button"
                      class="btn btn-sm btn-info"
                      ngbTooltip="Descarca Anexa 10 PDF"
                      placement="bottom"
                      (click)="downloadAnexa10(move.companyid, move.anexa10id)"
                    >
                      <i class="fe-download"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="movesTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ movesTableService.startIndex }} la
                {{ movesTableService.endIndex }} din
                {{ movesTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="movesLength"
                  [(page)]="movesTableService.page"
                  [pageSize]="movesTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-rep-fat-modal
  [data]="modalData"
  (callbackResult)="getModalResult($event)"
>
</app-rep-fat-modal>
