import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';

@Component({
  selector: 'app-evidente-ferma',
  templateUrl: './evidente-ferma.component.html',
  styleUrls: ['./evidente-ferma.component.scss']
})
export class EvidenteFermaComponent implements OnInit {
  isTerenuri = false;

  constructor(
    private store: Store<ApplicationState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
    .subscribe((currentRoute: string) => {
        this.isTerenuri = false;

        if (currentRoute.includes('terenuri')) {
          this.isTerenuri = true;
        }
      });
  }
}
