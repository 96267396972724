import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { DateFormatter } from './date-format';
import { NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateFormatter }
  ],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() reset: number;
  @Input() selectedDate: string;
  @Input() submitted: boolean;
  @Input() required: boolean;
  @Input() invalid: boolean;
  @Input() disabled: boolean;
  @Output() dateChanged = new EventEmitter();

  formGroup;
  validDate = true;
  readonly regex = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;

  constructor() { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      datePicker: new UntypedFormControl(new DateFormatter().parse(this.selectedDate), this.required ? Validators.required : null)
    });
  }

  ngOnChanges(changes) {
    if (changes.reset && this.formGroup) {
      this.formGroup.reset();
    }

    if (changes.selectedDate && this.formGroup) {
      this.formGroup.controls.datePicker.setValue(new DateFormatter().parse(this.selectedDate));
    }

    if (changes.required && this.formGroup) {
      this.required ? this.picker.setValidators(Validators.required) : this.picker.clearValidators();
      this.picker.updateValueAndValidity();
    }
  }

  get picker() {
    return this.formGroup.controls.datePicker;
  }

  onDateChanged(event) {
    if (this.disabled) {
      return;
    }

    this.validDate = true;
    const date = this.toString(event).replace(/\s+/g, '');

    if (!date) {
      this.formGroup.reset();
      return;
    }

    if (!this.regex.test(date)) {
      this.validDate = false;
    } else {
      this.validDate = true;
      this.dateChanged.emit(date);
    }
  }

  onDateInputChange(event) {
    if (this.disabled) {
      return;
    }

    this.validDate = true;
    const date = event.target.value.replace(/\s+/g, '');

    if (!date) {
      this.formGroup.reset();
      this.dateChanged.emit(null);
      return;
    }

    if (!this.regex.test(date)) {
      this.validDate = false;
    } else {
      this.validDate = true;
      this.dateChanged.emit(date);
    }
  }

  resetDatePicker() {
    this.validDate = true;
    this.formGroup.reset();
    this.dateChanged.emit(null);
  }

  private toString(date: NgbDate): string {
    return date ? `${date.day < 10 ? 0 : ''}${date.day}.${date.month < 10 ? 0 : ''}${date.month}.${date.year}` : '';
  }

}
