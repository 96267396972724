import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';

@Component({
  selector: 'app-contract-search',
  templateUrl: './contract-search.component.html',
  styleUrls: ['./contract-search.component.scss']
})
export class ContractSearchComponent implements OnInit, OnDestroy {
  @Input() isFermier;
  @Output() searchResult = new EventEmitter();
  contractSearchForm;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentRoute;
  searchData;
  isOnHolding = false;

  constructor(
    private store: Store<ApplicationState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getSelectedHolding))
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: any) => {
      if (response) {
        this.searchData = { comp_name: response.name, comp_code: response.code };
      }
    });

    this.store.pipe(select(getCurrentRoute))
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: string) => {
      this.currentRoute = response;
      if (!this.currentRoute.includes('/dashboard/contracte')) {
        this.buildForm(this.searchData);
        this.contractSearchForm.controls.comp_name.disable();
        this.contractSearchForm.controls.comp_code.disable();
      } else {
        this.buildForm();
        this.contractSearchForm.controls.comp_name.enable();
        this.contractSearchForm.controls.comp_code.enable();
      }
    });
  }

  buildForm(search?) {
    this.contractSearchForm = new UntypedFormGroup({
      comp_name: new UntypedFormControl(search ? search.comp_name : ''),
      comp_code: new UntypedFormControl(search ? search.comp_code : ''),
      contr_date_start: new UntypedFormControl(null),
      contr_date_end: new UntypedFormControl(null),
      contr_no: new UntypedFormControl(null),
      contr_type: new UntypedFormControl(null),
      contr_status: new UntypedFormControl(null)
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onSubmitted() {
    this.searchResult.emit(this.contractSearchForm.value);
  }

  resetSearch() {
    this.contractSearchForm.reset();
    this.onSubmitted();
  }

  onStartDateChanged(event) {
    if (this.contractSearchForm) {
      this.contractSearchForm.controls.contr_date_start.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.contractSearchForm) {
      this.contractSearchForm.controls.contr_date_end.setValue(event);
    }
  }
}
