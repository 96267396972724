import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationState } from '../state/app.state';
import { SetUserData } from './../state/actions/user.actions';
import { environment } from './../../../../../environments/environment';
import { OptionsProvider as auth } from './options-provider';
import { SetAnimalsFilter, SetHoldingsFilter } from '../state/actions/filters.actions';
import { SearchHolding } from '../models/holdings.models';
import { HoldingsTableService } from './holdings-table.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.API_URL;

  objToAPI: SearchHolding = {
    comp_code: '',
    comp_name: '',
    comp_cui: '',
    comp_settl: '',
    comp_status: '',
    page_no: '1',
    page_size: '10'
  };

  jwtHelper: JwtHelperService = new JwtHelperService();

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient,
    private store: Store<ApplicationState>,
    private router: Router,
    private holdingService: HoldingsTableService
  ) {
    // Set current user data
    this.currentUserSubject =
      new BehaviorSubject<any>(this.jwtHelper.decodeToken(localStorage['access-token']));
    this.currentUser =
      this.currentUserSubject.asObservable();
  }

  // Get current user value
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(credentials) {
    return this.http.post(
      `${this.baseUrl}/users/login/`,
      credentials
    ).pipe(map((response: any) => {
      // Decode the jwt token and save the data into the app Store
      this.store.dispatch(
        new SetUserData(this.jwtHelper.decodeToken(response.token))
      );

      // Set the token to the local storage
      localStorage.setItem('access-token', response.token !== undefined ? response.token : '');
      auth.changeOptions();

      // Get current user data
      this.currentUserSubject.next(this.jwtHelper.decodeToken(response.token));

      // Enter to the application if auth success
      if (this.currentUserValue.is_super_admin) {
        this.router.navigateByUrl('/dashboard/utilizatori');
      } else if (this.currentUserValue.user_type === 4) {
        this.redirectToFermierExp();
      } else {
        this.router.navigateByUrl('/dashboard/exploatatii');
      }

      return response;
    }));
  }

  redirectToFermierExp() {
    this.holdingService.getHoldingsAPI(this.objToAPI)
      .subscribe(response => {
        if (response.result.length) {
          const companyCode = response.result[0].code;
          this.store.dispatch(new SetHoldingsFilter(this.objToAPI));
          this.router.navigate(
            ['/dashboard/exploatatie', companyCode, 'animale-exploatatie']
          );
        } else {
          this.router.navigateByUrl('/dashboard/exploatatii');
        }
      });
  }

  logout() {
    this.store.dispatch(new SetAnimalsFilter(undefined));
    this.store.dispatch(new SetHoldingsFilter(undefined));
    this.store.dispatch(new SetUserData({}));

    // Remove access-token from the local storage
    localStorage.removeItem('access-token');
    // Reset current user data
    this.currentUserSubject.next(null);
    // Remove previous route
    localStorage.removeItem('previous-route');
  }

  forgotPassword(cod_exploatatie: string) {
    return this.http.post(
      `${this.baseUrl}/users/forgot-password/`,
      { cod_exploatatie: cod_exploatatie }
    ).pipe(map((response) => {
      return response;
    }));
  }

  resetPassword(obj) {
    return this.http.post(
      `${this.baseUrl}/password-reset-confirm/`,
      obj
    ).pipe(map((response) => {
      return response;
    }));
  }
}
