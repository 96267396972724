import { Exploatatie } from 'src/app/modules/acbcr/common/models/holdings.models';
import { Action } from '@ngrx/store';
import { HoldingsNames } from '../../models/holdings.models';

export enum AppHoldingsActionType {
  SET_HOLDINGS_NAME_DATA = 'SET_HOLDINGS_NAME_DATA',
  SET_SELECTED_HOLDING_DATA = 'SET_SELECTED_HOLDING_DATA',
  ADD_NEW_HOLDING_DATA = 'ADD_NEW_HOLDING_DATA',
  DELETE_HOLDING_NAME_DATA = 'DELETE_HOLDING_NAME_DATA',
  LOAD_HOLDING_START = 'SET_LOAD_HOLDING_TRUE',
  LOAD_HOLDING_SUCCESS = 'SET_LOAD_HOLDING_FALSE',
}

export class SetHoldingsNameData implements Action {
  readonly type = AppHoldingsActionType.SET_HOLDINGS_NAME_DATA;
  constructor(public payload: HoldingsNames) { }
}

export class SetSelectedHoldingData implements Action {
  readonly type = AppHoldingsActionType.SET_SELECTED_HOLDING_DATA;

  constructor(public payload: Exploatatie) { }
}

export class AddNewHoldingData implements Action {
  readonly type = AppHoldingsActionType.ADD_NEW_HOLDING_DATA;
  constructor(public payload: HoldingsNames) { }
}

export class DeleteHoldingNameData implements Action {
  readonly type = AppHoldingsActionType.DELETE_HOLDING_NAME_DATA;
  constructor(public payload: number) { }
}

export class LoadHoldingStart implements Action {
  readonly type = AppHoldingsActionType.LOAD_HOLDING_START;
}

export class LoadHoldingSuccess implements Action {
  readonly type = AppHoldingsActionType.LOAD_HOLDING_SUCCESS;
}

export type AppHoldingActions =
  SetHoldingsNameData |
  SetSelectedHoldingData |
  AddNewHoldingData |
  DeleteHoldingNameData |
  LoadHoldingStart |
  LoadHoldingSuccess;
