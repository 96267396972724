<app-raport-client-table
  [rapoarteClient]="tables$ | async"
  [rapoarteClientLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (rapoarteClientTableCallback)="getTableData()"
></app-raport-client-table>
