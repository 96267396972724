<div class="card" id="date-bancare" [formGroup]="holdingForm">
  <h3 class="card-header">Date bancare</h3>
  <div class="card-body">
    <div class="row">

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="bank">
            Banca
          </label>

          <input
            type="text"
            id="bank"
            formControlName="bank"
            placeholder="Adaugă banca"
            class="form-control" />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="bankaccount">
            Cont bancar
          </label>

          <input
            type="text"
            id="bankaccount"
            formControlName="bankaccount"
            placeholder="Adaugă contul bancar"
            class="form-control"
            (input)="$event.target.value = $event.target.value.toUpperCase()"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <h3 class="card-header">Documente</h3>
  <div class="card-body">
    <div class="row">

      <div class="col-sm-6">
        <div class="form-group mb-3">
          <label for="stampila">
            Încarcă documente
          </label>

          <app-upload-fisier
            (ApiResponse)="uploadServerResponse($event)">
          </app-upload-fisier>

        </div>
      </div>

      <div class="col-sm-6" *ngIf="documentsList.length > 0">
        <div class="form-group mb-3">
          <label for="stampila">
            Documente
          </label>

          <ul class="images-list">

            <li *ngFor="let document of documentsList">
              <a
                [href]="document.amazon_url"
                [title]="document.uploaded_file_name"
              >
                <i
                  class="mdi mdi-file-download"
                  ngbTooltip="Descarcă document"
                  placement="left"
                >
                </i>
                {{document.uploaded_file_name}}
              </a>

              <i
                class="mdi mdi-delete delete-document"
                ngbTooltip="Șterge document"
                placement="right"
                (click)="deleteFile(document.id)"
              >
              </i>

            </li>
          </ul>

        </div>
      </div>

    </div>
  </div>
</div>
