// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-raport-incasari',
//   templateUrl: './raport-incasari.component.html',
//   styleUrls: ['./raport-incasari.component.scss']
// })
// export class RaportIncasariComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RaportIncasare } from 'src/app/modules/acbcr/common/models/rapoarte-incasari.model';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { RapoarteContabilitateTableService } from 'src/app/modules/acbcr/common/services/contabilitate-rapoarte.service';

@Component({
  selector: 'app-raport-incasari',
  templateUrl: './raport-incasari.component.html',
  styleUrls: ['./raport-incasari.component.scss']
})
export class RaportIncasariComponent implements OnInit {

  tables$: Observable<any[]>;
  total$: Observable<number>;
  totalRestant;
  loadingDataSpinner = false;

  objToAPI = {
    tip: null,
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public rapoarteContabilitateTableService: RapoarteContabilitateTableService,
    public helperDataService: HelperDataService
  ) {
    this.tables$ = rapoarteContabilitateTableService.tables$;
    this.total$ = rapoarteContabilitateTableService.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.rapoarteContabilitateTableService
      .getRapoarteIncasariAPI(this.objToAPI).subscribe((response) => {
        this.totalRestant = response.restant;
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  exportData(event) {
    this.rapoarteContabilitateTableService
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, `Rapoarte-Incasari.xls`);
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

}
