import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { HelperDataService } from './../../common/services/helper-data.service';
import { SharingHelperDataService } from '../../common/services/sharing-helper-data.service';

import { ApplicationState } from '../../common/state/app.state';
import { SetUserData } from '../../common/state/actions/user.actions';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  isCondensed = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>,
    private sharingHelperDataService: SharingHelperDataService,
  ) { }

  ngOnInit() {
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  ngAfterViewInit() {
    document.body.classList.remove('authentication-bg');
    document.body.classList.remove('authentication-bg-pattern');

    // Set user data in store on refresh app
    this.store.dispatch(
      new SetUserData(
        this.jwtHelper.decodeToken(localStorage['access-token'])
      )
    );

    if (!this.isMobile()) {
      document.body.classList.add('sidebar-enable');
    }

    // Get countries, cities, animals rases data after used to the login
    this.helperDataService.getCountries();
    this.helperDataService.getCities();
    this.helperDataService.getAnimalsRases();
    this.helperDataService.getHoldingsNames();

    // Get CAEN Codes from the database after login
    this.helperDataService.getCAENCodes().subscribe(response => {
      const CAENCodes = response;

      CAENCodes.map((el) => {
        el.fullDescription = `${el.code} - ${el.name}`;
        return el;
      });

      // Share data
      this.sharingHelperDataService.shareCAENCodes(CAENCodes);

    });
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    if (!this.isMobile()) {
      document.body.classList.toggle('enlarged');
      this.isCondensed = !this.isCondensed;
    }
  }

}
