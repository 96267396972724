import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() isCondensed = false;
  @ViewChild('sideMenu') sideMenu: ElementRef;

  destroy$: Subject<boolean> = new Subject<boolean>();
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  linkExploatatie = '';

  constructor(
    private store: Store<ApplicationState>,
  ) { }

  ngOnInit() {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();

    if (this.isFermier) {
      this.store.pipe(select(getSelectedHolding))
      .subscribe((response: any) => {
        if (response && response.code) {
          this.linkExploatatie = '/dashboard/exploatatie/' + response.code + '/animale-exploatatie';
          localStorage.setItem('link-exploatatie', this.linkExploatatie);
        } else {
          this.linkExploatatie = localStorage.getItem('link-exploatatie');
        }
      });
    }
  }

  ngAfterViewInit() {
    this.activateMenuDropdown();
  }

  ngOnChanges() {
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  /**
   * small sidebar
   */
  smallSidebar() {
    document.body.classList.add('left-side-menu-sm');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('topbar-light');
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('enlarged');
  }

  /**
   * Dark sidebar
   */
  darkSidebar() {
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.add('left-side-menu-dark');
    document.body.classList.remove('topbar-light');
    document.body.classList.remove('boxed-layout');
  }

  /**
   * Light Topbar
   */
  lightTopbar() {
    document.body.classList.add('topbar-light');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.remove('boxed-layout');

  }

  /**
   * Sidebar collapsed
   */
  sidebarCollapsed() {
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.toggle('enlarged');
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('topbar-light');
  }

  /**
   * Boxed Layout
   */
  boxedLayout() {
    document.body.classList.add('boxed-layout');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.add('enlarged');
    document.body.classList.remove('left-side-menu-sm');
  }

  activateMenuDropdown() {
    const ulLinks = this.sideMenu.nativeElement.querySelectorAll('.side-nav-link-ref');

    for (const entry of ulLinks.values()) {
      if (window.location.hash === entry['hash']) {
        let parentUl = entry.closest('ul');
        parentUl.classList.add('show');

        break;
      }
    }


    let elementList = this.sideMenu.nativeElement.querySelectorAll('li a');

    for (const element of elementList.values()) {
      element.addEventListener('click', event => {
        let ulElement = event.currentTarget.parentElement.querySelector('ul');
        if (ulElement) {
          ulElement.classList.toggle('show');
        }
      });
    }
  }
}
