<ul class="nav nav-tabs">
  <li class="nav-item" *ngFor="let documentsTab of documentsTabs">
    <a
      class="nav-link"
      routerLinkActive ="active"
      [routerLink]="['./', documentsTab.path]"
    >
      {{documentsTab.name}}
    </a>
  </li>
</ul>

<router-outlet></router-outlet>
