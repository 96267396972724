import { Component, OnInit, Input, OnDestroy, OnChanges, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { takeUntil, pairwise, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { juridicForms } from 'src/app/modules/acbcr/common/models/juridic-forms.models';
import { asociatii } from 'src/app/modules/acbcr/common/models/asociatii.models';
import { AnimalRase } from 'src/app/modules/acbcr/common/models/animal.models';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppAnimalsRases } from 'src/app/modules/acbcr/common/state/reducers/animals.reducers';
import { CnpCuiValidatorService } from './../../../../../../../common/services/cnp-cui-validator.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-identificare-form-block',
  templateUrl: './identificare-form-block.component.html',
  styleUrls: ['./identificare-form-block.component.scss']
})
export class IdentificareFormBlockComponent implements OnInit, OnChanges, OnDestroy {

  @Input() holdingForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() CAENCodes;
  @Input() modalType: string;
  @Output() changeStatus = new EventEmitter();

  openStatusReasonModal = false;
  previousStatusValue = null;
  status = null;

  juridicForms;
  asociatii;
  defaultAsociation;

  displayCUI = false;
  displayCAEN = true;
  invalidCnp = true;
  invalidCUI = true;
  isSuperAdmin = false;

  rase: AnimalRase[];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<ApplicationState>,
    private CNPCUIValidatorService: CnpCuiValidatorService
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.juridicForms = juridicForms;
    this.asociatii = asociatii;
    this.defaultAsociation = this.asociatii[0];

    //  Read animals rases from the store
    this.store.pipe(select(getAppAnimalsRases))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: AnimalRase[]) => {
        this.rase = response;
      });

    if (this.modalType === 'add') {
      this.f.status.setValue(1);
      this.f.subventionat.setValue(0);
      this.changeStatus.emit('1');
    }

  }

  ngOnChanges(changes) {
    this.displayCUI =
      this.holdingForm.value.organizationtype !== 0 ? true : false;

    if (this.CAENCodes) {
      this.CAENCodes.map(caen => {
        caen.fullDescription = `${caen.code} - ${caen.name}`;
      });
    }

    if (changes.holdingForm && this.modalType === 'edit') {
      if (this.holdingForm.value.organizationtype === 0) {
        this.displayCAEN = false;
      } else {
        this.displayCAEN = true;
      }

      this.f.status.valueChanges
        .pipe(startWith(this.holdingForm.value.status), pairwise())
        . subscribe(([prev, next]: [any, any]) => {
          this.holdingStatusChange(prev, next);
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  // Get forms controls state
  get f() { return this.holdingForm.controls; }

  onStatusChange(event) {
    if (this.modalType === 'add') {
      this.changeStatus.emit(event.target.value);
    }
  }

  changeJuridicForms(juridicForm) {
    this.displayCAEN = juridicForm.id !== 0 ? true : false;
    this.displayCUI = juridicForm.id !== 0 ? true : false;

    if (juridicForm.id === 0) {
      this.holdingForm.controls.cif.setValue(null);
      this.holdingForm.controls.caen.setValue(null);
    } else {
      this.holdingForm.controls.cif.setErrors({ required: true});
      this.holdingForm.controls.caen.setErrors({ required: true });
    }

    this.holdingForm.controls.vattype.setValue(juridicForm.id === 0 || !this.holdingForm.value.vattype ? '0' : '1');
    this.holdingForm.value.organizationtype = juridicForm.id;

    this.holdingForm.controls.cif.reset();
    this.holdingForm.controls.caen.reset();
  }

  validateCNP(event) {
    this.invalidCnp =
      this.CNPCUIValidatorService.validateCNP(event.target.value);

    !this.invalidCnp ?
      this.holdingForm.controls.cif.setErrors({ invalid: true }) :
      this.holdingForm.controls.cif.setErrors(null);

    this.holdingForm.value.cif = event.target.value;
  }

  validateCUI(event) {
    this.invalidCUI =
      this.CNPCUIValidatorService.validateCUI(event.target.value);


    !this.invalidCUI ?
      this.holdingForm.controls.cif.setErrors({ invalid: true }) :
      this.holdingForm.controls.cif.setErrors(null);

    this.holdingForm.value.cif = event.target.value;
  }

  holdingStatusChange(prev, next) {
    if (this.holdingForm.value.id && this.holdingForm.value.code && next !== null) {
      this.openStatusReasonModal = true;
      this.previousStatusValue = prev;
      this.status = next;
    } else {
      this.changeStatus.emit(next?.toString());
    }
  }

  closeStatusReasonModal(event: boolean) {
    if (event === false) {
      this.f.status.setValue(this.previousStatusValue);
    } else {
      this.changeStatus.emit(this.holdingForm.value.status.toString());
    }

    this.openStatusReasonModal = false;
  }

}
