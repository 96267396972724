import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-adeverinte-apartenenta-tab',
  templateUrl: './adeverinte-apartenenta-tab.component.html',
  styleUrls: ['./adeverinte-apartenenta-tab.component.scss']
})
export class AdeverinteApartenentaComponent implements OnInit {

  constructor(
  ) {}

  ngOnInit() {
  }
}
