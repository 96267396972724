import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HoldingsTableService } from 'src/app/modules/acbcr/common/services/holdings-table.service';
import { convertDateToBackendFormat } from 'src/app/modules/acbcr/common/services/services-table-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-change-status-reason',
  templateUrl: './change-status-reason.component.html',
  styleUrls: ['./change-status-reason.component.scss']
})
export class ChangeStatusReasonComponent implements OnInit {
  @Input() companyCode: string;
  @Input() status;
  @Output() notifyClose = new EventEmitter();

  reasonStatusForm: UntypedFormGroup;
  submitted = false;
  motive = ['Vânzare animale', 'Reziliere contract', 'Suspendare din Programul de Ameliorare',
    'Excludere din Programul de Ameliorare', 'Transfer animale'];

  constructor(
    private holdingService: HoldingsTableService,
    private errorService: NotificationErrorService
  ) { }

  ngOnInit(): void {
    this.reasonStatusForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl(UIHelper.getCurrentDate(), Validators.required),
    });
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    this.notifyCloseModal(false);
  }

  get f() { return this.reasonStatusForm.controls; }

  onDateChanged(event) {
    this.reasonStatusForm.controls.date.setValue(event);
  }

  onSubmitted() {
    this.submitted = true;
    if (this.reasonStatusForm.valid) {
      const obj = {
        code: this.companyCode,
        status: this.status,
        reason: this.reasonStatusForm.value.reason,
        data: convertDateToBackendFormat(this.reasonStatusForm.value.date)
      };

      this.holdingService.updateHoldingStatus(obj)
        .subscribe(() => {
            this.notifyCloseModal(true);
          }, error => {
            this.errorService.processErrorMsg('status update error');
            this.notifyCloseModal(false);
        });
    }
  }

  notifyCloseModal(success: boolean) {
    this.notifyClose.emit(success);
  }

  selectMotiv(text) {
    this.reasonStatusForm.controls.reason.setValue(text);
  }

}
