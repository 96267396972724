import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fatari-list, [app-fatari-list]',
  templateUrl: './fatari-list.component.html',
  styleUrls: ['./fatari-list.component.scss']
})
export class FatariListComponent implements OnInit {
  @Input() anexe = [];
  @Input() selectedItem: number;
  @Output() openEditFatare = new EventEmitter();
  @Output() selectedItemChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  openEditModal(anexa, index) {
    this.openEditFatare.emit(anexa);
    this.selectedItemChanged.emit(index);
  }

}
