export class TreeHelper {

  public static createTree(root): any {
    this.createFirstTreeLevel(root);

    const height = this.getTreeHeight(root);
    for ( let i = 1; i < height; ++i) {
      if (i === 1) {
        this.createExpandedTreeLevel(root, i);
      } else {
        this.createTreeLevel(root, i);
      }
    }
  }

  public static createSimplifiedTree(root, level) {
    const height = this.getTreeHeight(root);
    if (height > level) {
      this.simplifyTree(root, level);
    }
  }

  public static isParentEditable(root, matricol, father): boolean {
    if ((root.children[0].numarmatricol === matricol || root.children[1].numarmatricol === matricol) &&
      (root.children[0].father === father || root.children[1].father === father)
    ) {
        return true;
    } else {
      return false;
    }
  }

  private static simplifyTree(root, level) {
    if (!root || Object.keys(root).length  < 1) {
      return;
    }

    if (level === 0) {
      delete root.children;
    } else if (level > 0) {
      this.simplifyTree(root?.children?.length > 0 ? root.children[0] : null, level - 1);
      this.simplifyTree(root?.children?.length > 1 ? root.children[1] : null, level - 1);
    }
  }

  private static createFirstTreeLevel(root) {
    root.expanded = true;
    if (Object.keys(root.children[0]).length  < 1) {
        root.children[0].numarmatricol = 'Adaugă tată';
        root.children[0].type = 'parent';
        root.children[0].father = 'T';
    }
    if (Object.keys(root.children[1]).length  < 1) {
      root.children[1].numarmatricol = 'Adaugă mamă';
      root.children[1].type = 'parent';
      root.children[1].father = 'M';
    }
  }

  private static createExpandedTreeLevel(root, level) {
    if (!root || Object.keys(root).length  < 1) {
      return;
    }

    if (level === 0) {
      root.expanded = true;
      if (root?.children === undefined) {
        return;
      }

      this.deleteChildrenNodes(root);
    } else if (level > 0) {
      this.createExpandedTreeLevel(root.children[0], level - 1);
      this.createExpandedTreeLevel(root.children[1], level - 1);
    }
  }

  private static createTreeLevel(root, level) {
    if (!root || Object.keys(root).length  < 1) {
      return;
    }

    if (level === 0) {
      root.expanded = false;
      if (root?.children === undefined) {
        return;
      }

      this.deleteChildrenNodes(root);
    } else if (level > 0) {
      this.createTreeLevel(root?.children?.length > 0 ? root.children[0] : null, level - 1);
      this.createTreeLevel(root?.children?.length > 1 ? root.children[1] : null, level - 1);
    }
  }

  private static getTreeHeight(root): number {
    if (!root || Object.keys(root).length  < 1) {
      return 0;
    } else {
      const lHeight = this.getTreeHeight(root?.children?.length > 0 ? root.children[0] : null);
      const rHeight = this.getTreeHeight(root?.children?.length > 1 ? root.children[1] : null);

      if (lHeight > rHeight) {
        return lHeight + 1;
      } else {
        return rHeight + 1;
      }
    }
  }

  private static deleteChildrenNodes(root) {
    if (root.children[1]?.id === undefined) {
      root.children.splice(1);
    }
    if (root.children[0]?.id === undefined) {
      root.children.splice(0, 1);
    }
  }

}
