<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-pattern">
          <div class="card-body p-4">
            <!-- Logo -->
            <div class="text-center w-75 m-auto">
              <a routerLink="/">
                <img src="assets/images/logo.png" alt="">
              </a>
              <p class="text-muted mb-4 mt-3">Introduceți codul exploatației și parola pentru a accesa contul.</p>
            </div>

            <form
              class="needs-validation"
              name="loginForm"
              [formGroup]="loginForm"
              (ngSubmit)="onSubmit()" novalidate
            >

              <ngb-alert
                type="danger"
                *ngIf="error"
                [dismissible]="false"
              >
                {{ error }}
              </ngb-alert>

              <div class="form-group mb-3">
                <label for="username">Cod exploatație</label>

                <input
                  type="text"
                  formControlName="username"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                  id="username"
                  placeholder="COD Exploatație"
                />

                <div
                  *ngIf="submitted && f.username.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.username.errors.required">
                    Completarea acestui câmp este obligatorie
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="password">Parola</label>

                <input
                  type="password"
                  formControlName="password"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                  id="password"
                  placeholder="Parola"
                />

                <div
                  *ngIf="submitted && f.password.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.password.errors.required">
                    Completarea acestui câmp este obligatorie
                  </div>
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">Autentificare</button>
              </div>
            </form>
          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p>
              <a routerLink="/forgot-password" class="text-white font-weight-bold ml-1">Obținere/Recuperare cont fermier A.C.B.C.R.</a>
            </p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
  Asociația Crescătorilor de Bovine pentru Carne din România
  <a href="https://acbcr.ro" class="footer-link">acbcr.ro</a>
</footer>
