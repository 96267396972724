<div class="container-fluid mt-3">
  <div class="col-12">
    <div class="card settings-card">
      <div class="row">
        <div class="col-12">
          <p class="settings-type">Setează unitățile de măsură</p>
        </div>
      </div>
      <form
        name="settingsForm"
        [formGroup]="settingsForm"
      >
        <div class="table-responsive">
          <table
            id="um-datatable"
            class="table table-bordered dt-responsive nowrap table-hover"
            formArrayName="ums"
          >
            <thead>
              <tr>
                <th>
                  UNITATEA DE MĂSURĂ
                </th>
                <th style="width: 10%; text-align: center;">
                  ACȚIUNI
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                [formGroupName]="i"
                *ngFor="
                  let item of settingsForm['controls'].ums['controls'];
                  let i = index;
                  let last = last;
                "
              >
                <td>
                  <input
                    type="text"
                    class="form-control read-only-form"
                    placeholder="Adaugă unitatea de măsură"
                    name="um_code"
                    formControlName="um_code"
                    (click)="makeActive(i)"
                    [readonly]="!activeFields[i]"
                  />
                </td>
                <td style="text-align: center;">
                  <button
                    *ngIf="last"
                    class="btn btn-sm btn-icon btn-success"
                    ngbTooltip="Adaugă unitate de măsură"
                    placement="bottom"
                    (click)="saveLineItem(i)"
                  >
                    <i class="fe-save"></i>
                  </button>
                  <div class="action-buttons">
                    <button
                      *ngIf="!last"
                      class="btn btn-sm btn-icon btn-warning"
                      ngbTooltip="Editează unitate de măsură"
                      placement="bottom"
                      (click)="editLineItem(i)"
                    >
                      <i class="mdi mdi-square-edit-outline"></i>
                    </button>
                    <button
                      *ngIf="!last"
                      class="btn btn-sm btn-icon btn-danger"
                      ngbTooltip="Șterge unitatea de măsură"
                      placement="bottom"
                      (click)="deleteLineItem(i, item.value.id)"
                    >
                      <i class="mdi mdi-delete-forever"></i>
                    </button>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
