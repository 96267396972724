import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-animal-pedigree-rg',
  templateUrl: './animal-pedigree-rg.component.html',
  styleUrls: ['./animal-pedigree-rg.component.scss']
})
export class AnimalPedigreeRgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
