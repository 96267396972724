import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener
} from '@angular/core';
import {
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormBuilder
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { AnimalRase } from 'src/app/modules/acbcr/common/models/animal.models';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { ContractTableService } from 'src/app/modules/acbcr/common/services/contracts-table.service';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-add-update-contract',
  templateUrl: './add-update-contract.component.html',
  styleUrls: ['./add-update-contract.component.scss']
})
export class AddUpdateContractComponent implements OnInit, OnChanges {
  @ViewChild('addEditContractModal') addEditContractModal: ElementRef;
  @Input() addEditModalData: any;
  @Output() callbackResult = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();
  modalReference: NgbModalRef;
  contractId: any;
  selectedHoldingId: any;
  addEditModalType: string;
  contractForm: UntypedFormGroup;
  submitted = false;
  count: number;
  raseAnimal: AnimalRase[];
  cannotEdit = false;
  isAdmin = false;
  isSuperAdmin = false;
  isOperator = false;
  isFermier = false;
  fileNameContract = null;
  email_cont_exploatatie;
  contractTypeChanged;
  permisiuneEditare = false;
  permisiuneCreare = false;

  constructor(
    public modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private store: Store<ApplicationState>,
    private contractTableService: ContractTableService,
    private helperDataService: HelperDataService,
  ) {}

  ngOnInit(): void {
    this.cannotEdit = UIHelper.isFermier() || UIHelper.isAutoritateJudeteana();
    this.isAdmin = UIHelper.isAdmin();
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.getHoldingRaces();
    this.getDataAndBuildForm();

    if (this.isAdmin || this.isOperator || this.isFermier) {
      this.permisiuneEditare = true;
    }

    if (this.isAdmin || this.isOperator) {
      this.permisiuneCreare = true;
    }

    this.store
      .pipe(select(getSelectedHolding))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.selectedHoldingId = response.id;
        }
      });
  }

  ngOnChanges(changes) {
    if (changes.addEditModalData && this.addEditModalData) {
      this.contractId = this.addEditModalData['id'];

      if (this.contractId) {
        if (this.permisiuneEditare) {
          this.addEditModalType = 'edit';
        } else {
          this.addEditModalType = 'view';
        }
      } else {
        this.addEditModalType = 'add';
      }

      this.getDataAndBuildForm();

      this.modalReference = this.modalService.open(this.addEditContractModal, {
        windowClass: 'modal-half-full',
        scrollable: true,
      });
    }
  }

  getDataAndBuildForm() {
    this.submitted = false;

    if (this.contractId) {
      this.contractTableService.getContractAPI(this.contractId)
        .subscribe({
          next: (response) => {
            this.buildForm(response);
          },
          error: (errors) => {
            this.buildForm();
          }
        });
    } else {
      this.buildForm();
    }
  }

  changedContractType(event) {
    if (event === 1 && this.formData.length > 1) {
      this.contractForm.controls.contr_races.reset();
      this.formData.controls.pop();
    }
  }

  buildForm(formData?) {
    const contract = formData?.contract[0];
    const rase = formData?.doc_rase;
    this.count = formData ? rase.length : 1;
    this.fileNameContract = contract?.file_name_contract;
    this.email_cont_exploatatie = contract?.email_cont_exploatatie;

    this.contractForm = new UntypedFormGroup({
      contr_id: new UntypedFormControl(contract?.id),
      comp_id: new UntypedFormControl(this.selectedHoldingId),
      contr_nr: new UntypedFormControl(contract?.numar, Validators.required),
      contr_status: new UntypedFormControl(contract ? contract.status : 3, Validators.required),
      contr_date: new UntypedFormControl(contract?.data, Validators.required),
      contr_type: new UntypedFormControl(contract?.tipcontract, Validators.required),
      contr_races: this.fb.array([...this.createItems(this.count)]),
      acte_aditionale: new UntypedFormArray([]),
      file_id_contract: new UntypedFormControl(contract?.file_id_contract),
      atestat_producator: new UntypedFormGroup({
        id: new UntypedFormControl(contract?.atestat_producator?.id),
        file_id: new UntypedFormControl(contract?.atestat_producator?.file_id),
        file_name: new UntypedFormControl(contract?.atestat_producator?.file_name),
        data_valabilitate_inceput: new UntypedFormControl(contract?.atestat_producator?.data_valabilitate_inceput),
        data_valabilitate_sfarsit: new UntypedFormControl(contract?.atestat_producator?.data_valabilitate_sfarsit),
      }),
    });
    if (rase?.length > 0) {
      this.contractForm.controls.contr_races.setValue(rase);
    }

    for (var i = 0; i < contract?.acte_aditionale?.length; i++) {
      let act = contract.acte_aditionale[i];

      let actAditionalForm = new UntypedFormGroup({
        id: new UntypedFormControl(act.id),
        data: new UntypedFormControl(act.data),
        nr_femele_rasa_pura: new UntypedFormControl(act.nr_femele_rasa_pura),
        nr_masculi_rasa_pura: new UntypedFormControl(act.nr_masculi_rasa_pura),
        nr_femele_sect_suplim: new UntypedFormControl(act.nr_femele_sect_suplim),
        file_name: new UntypedFormControl(act.file_name),
        file_id: new UntypedFormControl(act.file_id),
      });

      this.acteAditionaleForm.push(actAditionalForm);
    }

    this.checkContractEdit();
  }

  onSubmitted() {
    this.submitted = true;

    if (this.contractForm.invalid) {
      return;
    }

    let formValid = true;
    const raseFinal = this.contractForm.value.contr_races;
    const raseArray = [];
    for (const rasaFinal of raseFinal) {
      if (raseArray.find(element => element === rasaFinal.rasaid)) {
        formValid = false;
        break;
      }
      raseArray.push(rasaFinal.rasaid);
    }
    if (!formValid) {
      alert('Rasele trebuie să fie diferite!');
    } else {
      switch (this.addEditModalType) {
        case 'add':
          this.contractForm.controls.comp_id.setValue(this.selectedHoldingId);
          if (this.contractForm.controls.comp_id.value === undefined) {
            this.contractForm.controls.comp_id.setValue(this.selectedHoldingId);
          }
          this.contractTableService.saveContractApi(this.contractForm.value)
            .subscribe({
              next: (response) => {
                this.modalReference.close();
                this.contractForm.reset();
                this.callbackResult.emit('');
              }
            });

          break;
        case 'edit':
          this.contractTableService.updateContractApi(this.contractForm.value)
            .subscribe({
              next: (response) => {
                this.modalReference.close();
                this.contractForm.reset();
                this.callbackResult.emit('');
              }
            });

          break;
      }
    }
  }

  uploadContract(event) {
    const file = event.body.files[0];
    this.fileNameContract = file.file_uploaded_name;
    this.contractForm.controls.file_id_contract.setValue(file.file_id);
  }

  uploadAtestatProducator(event) {
    const file = event.body.files[0];
    this.atestatProducatorControls.file_name.setValue(file.file_uploaded_name);
    this.atestatProducatorControls.file_id.setValue(file.file_id);
  }

  downloadFileContract() {
    let fileId = this.contractForm.controls.file_id_contract.value;
    if (fileId) {
      this.helperDataService.downloadFile(fileId)
        .subscribe((response: any) => {
          const blob = new Blob([response], { type: 'application/octet-stream' });
          this.helperDataService.simulateDownload( blob, this.fileNameContract);
      });
    }
  }

  downloadFileAtestatProducator() {
    let fileId = this.atestatProducatorControls.file_id.value;
    if (fileId) {
      this.helperDataService.downloadFile(fileId)
        .subscribe((response: any) => {
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const fileName = this.atestatProducatorControls.file_name.value;
          this.helperDataService.simulateDownload( blob, fileName);
      });
    }
  }

  deleteFileContract() {
    let fileId = this.contractForm.controls.file_id_contract.value;
    if (fileId) {
      this.helperDataService.deleteFile(fileId)
      .subscribe(response => {
        this.contractForm.controls.file_id_contract.setValue(null);
      });
    }
  }

  deleteFileAtestatProducator() {
    let fileId = this.atestatProducatorControls.file_id.value;
    if (fileId) {
      this.helperDataService.deleteFile(fileId)
      .subscribe(response => {
        this.atestatProducatorControls.file_id.setValue(null);
        this.atestatProducatorControls.data_valabilitate_inceput.setValue(null);
        this.atestatProducatorControls.data_valabilitate_sfarsit.setValue(null);
      });
    }
  }

  get formData() {
    return this.contractForm.get('contr_races') as UntypedFormArray;
  }

  get f() {
    return this.contractForm.controls;
  }

  get acteAditionaleForm() {
    return this.contractForm.controls.acte_aditionale as UntypedFormArray;
  }

  get atestatProducatorControls() {
    let atestat_producator = this.contractForm.controls.atestat_producator as UntypedFormGroup;
    return atestat_producator.controls;
  }

  getHoldingRaces() {
    this.contractTableService.getAcbcrRacesAPI().subscribe(response => {
      this.raseAnimal = response;
      return response;
    });
  }

  private createItem(): UntypedFormGroup {
    return this.fb.group({
      rasaid: new UntypedFormControl(null, Validators.required),
      nrmetis: new UntypedFormControl(null),
      nrfemelecuratcertificat: new UntypedFormControl(null),
      nrmasculicuratcertificat: new UntypedFormControl(null),
      nrtineretrasapura: new UntypedFormControl(null)
    });
  }

  private createItems(count: number): UntypedFormGroup[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(this.createItem());
    }
    return arr;
  }

  dateChanged(event, control) {
    control.setValue(event);
  }

  addAnimalBreed() {
    const items = this.contractForm.get('contr_races') as UntypedFormArray;
    items.push(this.createItem());
  }

  removeAnimalBreed(event) {
    const control = this.contractForm.controls.contr_races as UntypedFormArray;
    control.removeAt(event);
  }

  checkContractEdit() {
    this.cannotEdit = true;

    if (this.isSuperAdmin) {
      this.cannotEdit = false;
    }

    if (this.permisiuneCreare && this.acteAditionaleForm.length == 0) {
      this.cannotEdit = false;
    }
  }

  creazaActAditional() {
    const contr_id = this.contractForm.value.contr_id;

    this.contractTableService.creazaActAditionalApi({contract_id: contr_id})
      .subscribe({
        next: (response) => {
          this.getDataAndBuildForm();
        }
      });
  }
}
