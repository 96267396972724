<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="settlementsTableService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-lg-6 mb-1">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #settlementsTable>
            <table
              id="settlements-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th sortable="name" (sort)="onSort($event)">
                    Localitate
                  </th>
                  <th (sort)="onSort($event)">
                    Judet
                  </th>
                  <th scope="col">
                    Acțiuni
                  </th>
                </tr>
              </thead>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="settlementsTableService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsită nici o localitate.
              </ngb-alert>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let settlement of settlements">
                  <td> {{settlement.name}} </td>
                  <td> {{settlement.county.name}} </td>
                  <td class="action-buttons">
                    <div class="btn-group">
                      <button
                        class="btn btn-sm btn-icon btn-success"
                        title="Editează"
                        (click)="editSettlementModal(settlement)"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="settlementsTableService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ settlementsTableService.startIndex }} la
                {{ settlementsTableService.endIndex }} din
                {{ settlementsTableService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="settlementsLength"
                  [(page)]="settlementsTableService.page"
                  [pageSize]="settlementsTableService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="'settlement'"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-edit-settlement
  [open]="openSettlementModal"
  (notifyClose)="closeUtilizatorModal($event)"
  (callbackResult)="getModalResult($event)"
  [settlement]="settlement"
>
</app-edit-settlement>
