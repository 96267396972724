import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { CertificateZootehniceService } from 'src/app/modules/acbcr/common/services/certificate-zootehnice.service'

@Component({
  selector: 'app-certificate-zootehnice',
  templateUrl: './certificate-zootehnice.component.html',
  styleUrls: ['./certificate-zootehnice.component.scss']
})
export class CertificateZootehniceComponent {
  @ViewChild('searchModal') searchModal: ElementRef;
  @ViewChild('cancellationModal') cancellationModal: ElementRef;
  @ViewChild('elibereazaModal') elibereazaModal: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  loadingDataSpinner = false;
  loadingElibereazaModal = false;
  searchForm: UntypedFormGroup;
  totalCount: number;
  itemsList = [];
  exploatatii = [];
  animale = [];
  openDelete = false;
  showHint = true;
  idDelete;
  hideFields = false;
  exploatatiaCurenta;
  motiv = '';
  errorCancellation = false;
  idCancellation;
  idElibereaza;
  matricolCancellation;
  matricolElibereaza;
  elibereazaErrorCertificat;
  elibereazaErrorsAnimal;
  elibereazaWarningsAnimal;
  modalReference: NgbModalRef;
  modalReferenceElibereaza: NgbModalRef;
  req_params = {
    page_no: 1,
    page_size: 10,
  };

  constructor(
    private store: Store<ApplicationState>,
    private modalService: NgbModal,
    private helperDataService: HelperDataService,
    public certificateZootehniceService: CertificateZootehniceService,
  ) {}


  ngOnInit() {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();

    this.searchForm = new UntypedFormGroup({
      start_date: new UntypedFormControl(),
      end_date: new UntypedFormControl(),
      exploatatie_id: new UntypedFormControl(),
      animal_id: new UntypedFormControl(),
      status: new UntypedFormControl(),
    });

    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        if (response && response.includes('/exploatatie')) {
          this.store.pipe(
            select(getLoadingState),
            filter(isLoading => !isLoading),
            switchMap(() => this.store.pipe(select(getSelectedHolding))),
            distinctUntilChanged((prev, curr) => prev.id === curr.id),
            takeUntil(this.destroy$),
          ).subscribe((response: any) => {
            this.exploatatiaCurenta = response?.id;
            this.hideFields = true;
            this.searchForm.controls.exploatatie_id.setValue(this.exploatatiaCurenta);
            this.changePageSize('10');
          });
        } else {
          this.exploatatiaCurenta = null;
          this.hideFields = false;
          this.searchForm.controls.exploatatie_id.setValue(this.exploatatiaCurenta);
          this.changePageSize('10');
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.itemsList = [];
    let list_params = {
      ...this.req_params,
      ...this.searchForm.value
    };

    this.certificateZootehniceService.listAPI(list_params)
      .subscribe({
        next: (response) => {
          this.loadingDataSpinner = false;
          this.itemsList = response.result;
          this.totalCount = response.count;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
        }
      });
  }

  changePageSize(page_size) {
    this.req_params.page_no = 1;
    this.req_params.page_size = page_size;
    this.certificateZootehniceService.page = 1;
    this.certificateZootehniceService.pageSize = page_size;
    this.getTableData();
  }

  changePageNumber(event) {
    this.req_params.page_no = event;
    this.getTableData();
  }

  openDeleteModal(id) {
    this.openDelete = true;
    this.idDelete = id;
  }

  closeDeleteModal(event) {
    this.openDelete = false;

    if (event != true) {
      return;
    }

    let buton = document.getElementsByClassName('sterge-id-' + this.idDelete)[0];
    buton?.setAttribute('disabled', 'true');
    let req_params = {
      id: this.idDelete,
    }

    this.certificateZootehniceService.stergeAPI(req_params)
      .subscribe({
        next: (response) => {
          this.cauta();
        }
      });
  }

  openCancellationModal(item) {
    this.motiv = '';
    this.errorCancellation = false;
    this.idCancellation = item.id;
    this.matricolCancellation = item.numarmatricol;
    this.modalReference = this.modalService.open(this.cancellationModal, {
      scrollable: false,
    });
  }

  submitCancellation() {
    if (!this.motiv) {
      this.errorCancellation = true;
      return;
    }

    let req_params = {
      id: this.idCancellation,
      motiv: this.motiv,
    }

    this.certificateZootehniceService.anuleazaAPI(req_params)
      .subscribe({
        next: (response) => {
          this.modalReference.close();
          this.cauta();
        }
      });
  }

  openElibereazaModal(item) {
    this.idElibereaza = item.id;
    this.matricolElibereaza = item.numarmatricol;
    this.elibereazaErrorCertificat = null;
    this.elibereazaErrorsAnimal = null;
    this.elibereazaWarningsAnimal = null;
    this.loadingElibereazaModal = true;

    this.modalReferenceElibereaza = this.modalService.open(this.elibereazaModal, {
      scrollable: false,
    });

    let req_params = {
      id: this.idElibereaza,
    }

    this.certificateZootehniceService.elibereazaAPI(req_params)
      .subscribe({
        next: (response) => {
          this.elibereazaErrorCertificat = response.error_certificat;
          this.elibereazaErrorsAnimal = response.errors_animal;
          this.elibereazaWarningsAnimal = response.warnings_animal;
          this.loadingElibereazaModal = false;
        }
      });
  }

  submitElibereaza() {
    this.itemsList = [];
    this.loadingDataSpinner = true;
    this.modalReferenceElibereaza.close();

    let req_params = {
      id: this.idElibereaza,
      elibereaza: true,
    }

    this.certificateZootehniceService.elibereazaAPI(req_params)
      .subscribe({
        next: (response) => {
          this.cauta();
        }
      });
  }

  clickSearchModal() {
    this.modalService.open(this.searchModal, {
      scrollable: false,
    });
  }

  searchExploatatii(event) {
    if (event.term.length < 5) {
      this.showHint = true;
      this.exploatatii = [];

      return;
    }

    this.showHint = false;
    this.helperDataService.getCompaniesList({comp_name: event.term})
      .subscribe({
        next: (response) => {
          this.exploatatii = response;
        }
      });
  }

  searchMatricol(event) {
    if (event.term.length < 4) {
      this.animale = [];
      return;
    }

    let req_params = {
      numarmatricol: event.term,
    };

    this.helperDataService.getAnimals(req_params)
      .subscribe((response: any) => {
        this.animale = response.result;
      });
  }

  cauta() {
    this.req_params.page_no = 1;
    this.certificateZootehniceService.page = 1;
    this.getTableData();
  }

  resetFields() {
    if (this.searchForm) {
      this.searchForm.reset();
      this.searchForm.controls.exploatatie_id.setValue(this.exploatatiaCurenta);
    }
  }

  resetSearch() {
    this.resetFields();
    this.cauta();
  }

  download(event, item) {
    event.target.disabled = true;
    let numarmatricol = item.numarmatricol || '';
    let fisier = `Certificat_Zootehnic_${numarmatricol}.pdf`;

    this.certificateZootehniceService.downloadAPI({id: item.id})
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }

  export(event) {
    event.target.disabled = true;
    let export_params = this.searchForm.value;
    let now = UIHelper.now();
    let fisier = `Certificate_Zootehnice_${now}.xls`;

    this.certificateZootehniceService.exportAPI(export_params)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, fisier);
          event.target.disabled = false;
        },
        error: (errors) => {
          event.target.disabled = false;
        }
      });
  }
}
