import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SortableTable, convertDateToDisplayFormat, convertDateToBackendFormat } from './services-table-helper';

@Injectable({
  providedIn: 'root'
})
export class BirthsTableService extends SortableTable<any> {
  private baseUrl = environment.API_URL;

  births = [];
  birthsLength: number;

  constructor(private http: HttpClient) {
    super();
  }

  sortEvent() {
    const tables = this.sort(this.births, this._state.sortColumn, this._state.sortDirection);
    this._tables$.next(tables);
  }


  getBirthsAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/fatari/list/`,
      obj

    ).pipe(map((response: any) => {
      this.births = response.result;
      this.birthsLength = response.count;
      this.paginate();
      this.sortEvent();
      this._total$.next(this.birthsLength);

      return response;
    }));
  }

  getAnexa8Data(obj) {
    return this.http.post(
      `${this.baseUrl}/fatari/anexa8/`,
      obj

    ).pipe(map((response: any) => {
      response.map(anexa => {
        anexa.data_nasterii = convertDateToDisplayFormat(anexa.data_nasterii);
        anexa.datecreated = convertDateToDisplayFormat(anexa.datecreated);
        anexa.data_intrarii_exploatatie = convertDateToDisplayFormat(anexa.data_intrarii_exploatatie);
      });

      return response;
    }));
  }

  getFatareAPI(fatareID: number) {
    return this.http.get(
      `${this.baseUrl}/fatari/${fatareID}/`
    ).pipe(map((response: any) => {
      response.result.data_nasterii = convertDateToDisplayFormat(response.result.data_nasterii);
      response.result.data_intrarii_exploatatie = convertDateToDisplayFormat(response.result.data_intrarii_exploatatie);
      return response.result;
    }));
  }

  getAnexa8Pdf(companyId: number, anexa8Id: number) {
    return this.http
    .post(
      `${this.baseUrl}/fatari/anexa8-pdf/`,
        { companyid: companyId, anexa8id: anexa8Id },
        {
          responseType: 'blob'
        }
      ).pipe((response: any) => {
      return response;
    });
  }

  updateAnexa8(obj) {
    return this.http.post(
      `${this.baseUrl}/fatari/save/`,
      {
        ...obj,
        data_nasterii: convertDateToBackendFormat(obj.data_nasterii),
        data_intrarii_exploatatie: convertDateToBackendFormat(obj.data_intrarii_exploatatie)
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  getMatricoleFemela(data, compId) {
    return this.http.post(
      `${this.baseUrl}/fatari/lista-femele-fatare/`,
      { companyid: compId, date: convertDateToBackendFormat(data) }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  femelaAPI(obj) {
    return this.http.post(
      `${this.baseUrl}/fatari/femela/`,
      obj

    ).pipe(map((response: any) => {
      return response;
    }));
  }

  deleteFatare(id: number) {
    return this.http.post(
      `${this.baseUrl}/fatari/delete/`,
      { id }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  exportXLS(obj) {
    return this.http
    .post(
      `${this.baseUrl}/fatari/export-xls/`,
      {
        ...obj,
        data_start: convertDateToBackendFormat(obj.data_start),
        data_end: convertDateToBackendFormat(obj.data_end)
      },
      {
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Paginate Method
   */
  private paginate(): void {
    this.totalRecords = this.birthsLength;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex = Number(this.pageSize) + Number(this._state.startIndex - 1);

    if (this._state.endIndex > this.birthsLength) {
      this._state.endIndex = this.birthsLength;
    }
  }
}
