import {
  Component, ElementRef, ViewChild, OnInit, OnChanges, Input, Output, EventEmitter
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { TreeHelper } from 'src/app/modules/acbcr/components/common/helper/tree-helper';
import { SolicitariCertificateZootehniceService } from 'src/app/modules/acbcr/common/services/solicitari-certificate-zootehnice.service';

@Component({
  selector: 'app-proceseaza-solicitari-certificate-zootehnice',
  templateUrl: './proceseaza.component.html',
  styleUrls: ['./proceseaza.component.scss']
})
export class ProceseazaSolicitariCertificateZootehniceComponent implements OnInit, OnChanges {
  @ViewChild('proceseazaCertificatModal') proceseazaCertificatModal: ElementRef;
  @Input() proceseazaModalData: any;
  @Output() notifyClose = new EventEmitter();

  modalReference: NgbModalRef;
  loadingDataSpinner = false;
  solicitare = null;
  animalProcesat = null;
  animalProcesatStatus = null;
  animalProcesatFamilyTree = [];
  isLast = false;
  currentIndex = 0;

  constructor(
    public modalService: NgbModal,
    private errorService: NotificationErrorService,
    public solicitariCertificateZootehniceService: SolicitariCertificateZootehniceService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.proceseazaModalData && ! changes.proceseazaModalData.firstChange) {
      this.modalReference = this.modalService.open(this.proceseazaCertificatModal, {
        windowClass: 'modal-full',
        modalDialogClass: 'h-100',
        scrollable: true,
      });

      this.loadingDataSpinner = false;
      this.solicitare = null;
      this.animalProcesat = null;
      this.animalProcesatStatus = null;
      this.animalProcesatFamilyTree = [];
      this.isLast = false;
      this.currentIndex = 0;

      this.getData();
    }
  }

  getData() {
    this.solicitare = null;
    let solicitareId = this.proceseazaModalData?.id;

    if (!solicitareId) {
      return;
    }

    this.loadingDataSpinner = true;

    this.solicitariCertificateZootehniceService
      .getAPI({id: solicitareId})
      .subscribe(response => {
        this.solicitare = response;
        this.getAnimalData(this.currentIndex);
      });
  }

  getAnimalData(index) {
    if (!this.solicitare || !this.solicitare['animale']) {
      return;
    }

    this.currentIndex = index;
    let lastIndex = this.solicitare['animale'].length - 1;
    let animal = this.solicitare['animale'][this.currentIndex];

    if (!animal) {
      return;
    }

    this.loadingDataSpinner = true;
    this.isLast = (lastIndex === this.currentIndex);
    this.animalProcesat = null;
    this.animalProcesatStatus = null;
    this.animalProcesatFamilyTree = [];

    this.solicitariCertificateZootehniceService
      .detaliiAnimalAPI({animal_id: animal['id']})
      .subscribe({
        next: (response) => {
          this.animalProcesat = response;

          if (!this.animalProcesat) {
            return;
          }

          TreeHelper.createSimplifiedTree(this.animalProcesat.family_tree, 2);
          this.animalProcesatFamilyTree = [ this.animalProcesat.family_tree ];
          this.animalProcesatStatus = this.animalProcesat.status_solicitare_cz;
          this.loadingDataSpinner = false;
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  salveazaSolicitare(last=false) {
    this.loadingDataSpinner = true;
    const req_params = {
      solicitare_id: this.solicitare.id,
      animal_id: this.animalProcesat.id,
      status: this.animalProcesatStatus,
    };

    this.solicitariCertificateZootehniceService
      .salveazaAnimalAPI(req_params)
      .subscribe({
        next: (response) => {
          if (last) {
            this.notifyClose.emit(true);
            this.modalReference.close();
          } else {
            this.currentIndex++;
            this.getData();
          }
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

}
