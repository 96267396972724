import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';
import { AdvancedSortableDirective, SortEvent } from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { Operator } from 'src/app/modules/acbcr/common/models/operator.models';
import { OperatorsTableService } from 'src/app/modules/acbcr/common/services/operators-table.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-operators-table',
  templateUrl: './operators-table.component.html',
  styleUrls: ['./operators-table.component.scss']
})
export class OperatorsTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<AdvancedSortableDirective>;
  @ViewChild('operatorsTable', { static: false }) operatorsTable: ElementRef;

  @Input() operatori: Operator[];
  @Input() operatoriLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() exportData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() tableCallback = new EventEmitter();

  openSearchModal = new Subject<boolean>();
  openOperatorModal = false;
  isAdmin = false;
  isOperator = false;
  modalSearchType = 'operator';

  operator: Operator = null;

  constructor(
    public operatorsTableService: OperatorsTableService
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.operatorsTableService.sortColumn = column;
    this.operatorsTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  exportTableXLSX() {
    this.exportData.emit(null);
  }

  openAddEditOperatorModal(operator: Operator) {
    this.openOperatorModal = true;
    this.operator = operator;
  }

  closeOperatorModal(event) {
    this.openOperatorModal = event;
    this.operator = null;
  }

  getModalResult(event) {
    this.tableCallback.emit(event);
  }

}
