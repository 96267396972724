import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  AdvancedSortableDirective,
  SortEvent
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { SettlementsTableService } from 'src/app/modules/acbcr/common/services/settlements-table.service';

@Component({
  selector: 'app-settlements-table',
  templateUrl: './settlements-table.component.html',
  styleUrls: ['./settlements-table.component.scss']
})
export class SettlementsTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<AdvancedSortableDirective>;
  @ViewChild('settlementsTable', { static: false }) settlementsTable: ElementRef;

  @Input() settlements: any[];
  @Input() settlementsLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() tableCallback = new EventEmitter();

  openSearchModal = new Subject<boolean>();
  openSettlementModal = false;
  modalSearchType = 'settlement';

  settlement: any = null;

  constructor(
    public settlementsTableService: SettlementsTableService
  ) {}

  ngOnInit(): void {}

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.settlementsTableService.sortColumn = column;
    this.settlementsTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
  }

  editSettlementModal(settlement) {
    this.openSettlementModal = true;
    this.settlement = settlement;
  }

  closeUtilizatorModal(event) {
    this.openSettlementModal = event;
    this.settlement = null;
  }

  getModalResult(event) {
    this.tableCallback.emit(event);
  }

}
