<input
  #fileUpload
  type="file"
  [attr.multiple]="multiple"
  style="display: none"
  (change)="onFileSelected($event)"
/>

<button
  type="button"
  class="btn btn-primary"
  (click)="fileUpload.click()"
>
  <ng-container *ngIf="multiple">
    Alege fișierele
  </ng-container>
  <ng-container *ngIf="!multiple">
    Alege fișierul
  </ng-container>
</button>
