<form [formGroup]="operatorSearchForm">
  <div class="row">

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="status">
          Stare
        </label>

        <div class="radio ml-2 mb-2">
          <input
            type="radio"
            name="status"
            id="status1"
            formControlName="status"
            value="1"
          />
          <label for="status1">
            Activ
          </label>

          <input
            type="radio"
            name="status"
            id="status0"
            formControlName="status"
            value="0"
            class="ml-4"
          />
          <label for="status0">
            Inactiv
          </label>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="form-group mb-3">
        <label for="nume">
          Nume, Prenume sau Cod operator
        </label>
        <input
          type="text"
          id="nume"
          formControlName="nume"
          placeholder="Nume, Prenume sau Cod operator"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="cnp">
          CNP
        </label>
        <input
          type="text"
          id="cnp"
          formControlName="cnp"
          placeholder="Adaugă CNP"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="nraut">
          Numărul autorizației
        </label>

        <input
          type="text"
          id="nraut"
          formControlName="nraut"
          placeholder="Adaugă numărul autorizației"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
