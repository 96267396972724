<footer class="footer">
  <div class="container-fluid">

      <div class="d-flex flex-wrap justify-content-between">
        <div>
        Asociația Crescătorilor de Bovine pentru Carne din România
        </div>

        <div>
          Email: asociatia@acbcr.ro
        </div>
      </div>

  </div>
</footer>
