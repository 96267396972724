import {
  Component, Input, Output, EventEmitter
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-upload-fisier',
  templateUrl: './upload-fisier.component.html',
  styleUrls: ['./upload-fisier.component.scss']
})
export class UploadFisierComponent {
  baseUrl = environment.API_URL;

  @Input() url = `${this.baseUrl}/files/upload/`;
  @Input() multiple;
  @Output() ApiResponse = new EventEmitter();

  constructor(
    private http: HttpClient,
  ) { }

  onFileSelected(event) {
    const formData = new FormData();
    const files = event.target.files;

    if (!files) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i])
    }

    this.uploadFile(formData)
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.Response) {
            this.ApiResponse.emit(event);
          }
        }
      });
  }

  uploadFile(formData) {
    return this.http
      .post(
        this.url,
        formData, {
          observe: 'events',
        }
      );
  }
}
