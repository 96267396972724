export interface MenuTab {
  path: string;
  name: string;
}

export enum UserType {
  SuperAdmin = 1,
  AdminACBCR = 2,
  Operator = 3,
  Fermier = 4,
  Ojz = 5,
  Anz = 6,
  Contabil = 7,
}

export enum UserRole {
  Regular,
  Admin
}

export interface User {
  user_id: number;
  name: string;
  email: string;
  user: UserRole;
  username?: string;
  user_type?: number;
  is_active?: boolean;
  is_global_admin?: boolean;
  first_name?: string;
  last_name?: string;
}
