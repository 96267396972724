import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';

@Component({
  selector: 'app-move-search',
  templateUrl: './move-search.component.html',
  styleUrls: ['./move-search.component.scss']
})
export class MoveSearchComponent implements OnInit, OnDestroy {
  @Output() searchResult = new EventEmitter();
  moveSearchForm;
  animale = [];
  currentRoute: string;
  selectedHoldingId;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private helperDataService: HelperDataService,
    private store: Store<ApplicationState>
    ) {}

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
      .subscribe((route: string) => {
        if (route && route.includes('/exploatatie')) {
          this.store.pipe(select(getSelectedHolding))
            .subscribe((response: any) => {
              if (response) {
                this.selectedHoldingId = response.id;
              }
            });
        }
      });

    this.moveSearchForm = new UntypedFormGroup({
      data_start: new UntypedFormControl(null),
      data_end: new UntypedFormControl(null),
      animalid: new UntypedFormControl(null),
      motiv: new UntypedFormControl(null),
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onSubmitted() {
    this.searchResult.emit(this.moveSearchForm.value);
  }

  resetSearch() {
    this.moveSearchForm.reset();
    this.onSubmitted();
  }

  onStartDateChanged(event) {
    if (this.moveSearchForm) {
      this.moveSearchForm.controls.data_start.setValue(event);
    }
  }

  onEndDateChanged(event) {
    if (this.moveSearchForm) {
      this.moveSearchForm.controls.data_end.setValue(event);
    }
  }

  searchMatricol(event) {
    if (event.term.length < 4) {
      this.animale = [];
      return;
    }

    let req_params = {
      numarmatricol: event.term,
      companyid: this.selectedHoldingId,
    };

    this.helperDataService.getAnimals(req_params)
      .subscribe((response: any) => {
        this.animale = response.result;
      });
  }
}
