import { Component, OnInit, Input, ViewChildren, QueryList, ViewChild,
  ElementRef, Output, EventEmitter } from '@angular/core';
import { AdvancedSortableDirective, SortEvent } from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { takeUntil } from 'rxjs/operators';
import { Contract } from 'src/app/modules/acbcr/common/models/contract.model';
import { ContractTableService } from 'src/app/modules/acbcr/common/services/contracts-table.service';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-contract-table',
  templateUrl: './contract-table.component.html',
  styleUrls: ['./contract-table.component.scss']
})
export class ContractTableComponent implements OnInit {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective>;
  @ViewChild('contractsTable', { static: false }) contractsTable: ElementRef;

  @Input() contracts: Contract[];
  @Input() contractsLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  readonly modalType = 'contract';
  openSearchModal = new Subject<boolean>();
  addEditModalData;
  openContracteModal = false;
  contractRaseData: any;
  addEditModalType: string;
  currentRoute: string;
  selectedHolding;
  contractId: number;
  openConfirmModal = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  isContabil = false;
  permisiuneEditare = false;
  permisiuneCreare = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };

  constructor(
    private helperDataService: HelperDataService,
    public contractsTableService: ContractTableService,
    private store: Store<ApplicationState>
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.isContabil = UIHelper.isContabil();

    if (this.isAdmin || this.isOperator || this.isFermier) {
      this.permisiuneEditare = true;
    }

    if (this.isAdmin || this.isOperator) {
      this.permisiuneCreare = true;
    }

    this.store.pipe(select(getSelectedHolding))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.selectedHolding = response?.id;
      });

    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.contractsTableService.sortColumn = column;
    this.contractsTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.filterData.emit(event);
    this.objToAPI = event;
  }

  exportTableXLSX() {
    if (this.currentRoute.includes('/dashboard/contracte')) {
      this.contractsTableService.downloadContractsApi(this.objToAPI)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(blob, `contracte.xls`);
      });
    } else {
      this.contractsTableService.downloadContractsApi(this.objToAPI, this.selectedHolding)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(blob, `contracte.xls`);
      });
    }
  }

  openAddModal() {
    this.addEditModalData = {id: null};
  }

  openEditModal(contract_id) {
    this.addEditModalData = {id: contract_id};
  }

  getCallbackResult(event) {
    this.callbackResult.emit(event);
  }

  openConfirmActionModal(contractId) {
    this.openConfirmModal = true;
    this.contractId = contractId;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.deleteContract(this.contractId);
    }
    this.contractId = null;
  }

  private deleteContract(contractId) {
    this.contractsTableService.deleteContractApi(contractId)
      .subscribe(response => {
      this.callbackResult.emit('');
    });
  }
}
