import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ApplicationState } from '../state/app.state';
import { SetCountriesData, SetCitiesData } from './../state/actions/countries-cities.actions';
import { SetAnimalsRaseData } from '../state/actions/animals.actions';
import { SetHoldingsNameData } from '../state/actions/holdings.actions';
import { convertDateToBackendFormat } from './services-table-helper';
import { OptionsProvider as auth } from './options-provider';
import { getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';

@Injectable({
  providedIn: 'root'
})
export class HelperDataService {
  private baseUrl = environment.API_URL;

  constructor(
    private http: HttpClient,
    private store: Store<ApplicationState>,
  ) { }

  getCountries() {
    return this.http
      .get(`${this.baseUrl}/countries/`, auth.options)
      .subscribe((response: any) => {
        this.store.dispatch(new SetCountriesData(response));
      }, error => {
        console.log(error);
      });
  }

  getCities() {
    return this.http
      .get(`${this.baseUrl}/cities/`, auth.options)
      .subscribe((response: any) => {
        this.store.dispatch(new SetCitiesData(response));
      }, error => {
        console.log(error);
      });
  }

  getSmallCities(cityCode: string) {
    return this.http
      .post(
        `${this.baseUrl}/cities/`,
        { county_code: cityCode },
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getAnimalsRases() {
    return this.http
      .get(`${this.baseUrl}/race/`, auth.options)
      .subscribe((response: any) => {
        this.store.dispatch(new SetAnimalsRaseData(response));
      }, error => {
        console.log(error);
      });
  }

  getHoldingsNames() {
    return this.http
      .get(`${this.baseUrl}/companies/names/`, auth.options)
      .subscribe((response: any) => {
        this.store.dispatch(new SetHoldingsNameData(response));
      }, error => {
        console.log(error);
      });
  }

  getCAENCodes() {
    return this.http
      .get(
        `${this.baseUrl}/nace/`,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getNationality() {
    return this.http
      .get(
        `${this.baseUrl}/nationality/`,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  mapUploadedFiles(file) {
    return this.http
      .post(
        `${this.baseUrl}/files/file-map/`,
        file,
        auth.options
      ).pipe(map(response => {
        return response;
      }));
  }

  deleteCompanyDocument(documentId) {
    return this.http
      .post(
        `${this.baseUrl}/files/delete-company-file/`,
        { document_id: documentId },
        auth.options
      ).pipe(map(response => {
        return response;
      }));
  }

  getAnimals(obj) {
    return this.http
      .post(
        `${this.baseUrl}/animals/search/`,
        obj, auth.options
      ).pipe(map(response => {
        return response;
      }));
  }

  getAnimalInfo(matricol, sex?, companyid?) {
    return this.http
      .post(
        `${this.baseUrl}/animals/search/`,
        { numarmatricol: matricol, sex, companyid },
        auth.options
      ).pipe(map(response => {
        return response;
      }));
  }

  getCompanyAnimalInfo(matricol, companyId) {
    return this.http
      .post(
        `${this.baseUrl}/animals/search/`,
        { companyid: companyId, numarmatricol: matricol },
        auth.options
      ).pipe(map(response => {
        return response;
      }));
  }

  getCompanyAnimalInfoMoves(matricol) {
    return this.http
      .post(
        `${this.baseUrl}/animals/search/`,
        { numarmatricol: matricol },
        auth.options
      ).pipe(map(response => {
        return response;
      }));
  }

  getCompaniesList(obj) {
    return this.http.post(
      `${this.baseUrl}/companies/list/`,
      obj, auth.options
    ).pipe(map((response: any) => {
      if (!response || !Array.isArray(response)) {
        return []
      }

      response.map(
        (company) => company.viewLabel = `${company.code} - ${company.name}`
      );

      return response;
    }));
  }

  getSettings() {
    return this.http
      .get(
        `${this.baseUrl}/settings/`,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  setSettings(obj) {
    return this.http
      .post(
        `${this.baseUrl}/settings/`, obj,
        auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  getTemplate(obj) {
    return this.http
      .post(
        `${this.baseUrl}/template/`,
        obj, auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  salveazaTemplate(obj) {
    return this.http
      .post(
        `${this.baseUrl}/template/salveaza/`,
        obj, auth.options
      ).pipe(map((response: any) => {
        return response;
      }));
  }

  mapReproductionFile(montaId, fileId) {
    return this.http.post(
      `${this.baseUrl}/files/file-map/`,
      { montaid: montaId, file_id: fileId },
      auth.options
    ).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadFile(fileId) {
    return this.http
      .post(
        `${this.baseUrl}/files/download/`,
        { file_id: fileId },
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['access-token']}`,
          })
        }
      ).pipe((response: any) => {
        return response;
      });
  }

  deleteFile(fileId) {
    return this.http
      .post(
        `${this.baseUrl}/files/delete/`,
        { file_id: fileId },
        auth.options
      ).pipe(map(response => {
        return response;
      }));
  }

  simulateDownload(file: Blob, fileName: string) {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = fileName;
    a.href = window.URL.createObjectURL(file);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  getStoreCounties() {
    return this.store.pipe(select(getAppCities));
  }

}
