import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  HostListener
} from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Form
} from '@angular/forms';
import { BuletineTableService } from 'src/app/modules/acbcr/common/services/buletine-table.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-edit-buletin-control',
  templateUrl: './edit-buletin-control.component.html',
  styleUrls: ['./edit-buletin-control.component.scss']
})
export class EditBuletinControlComponent implements OnInit, OnChanges {
  @ViewChild('editBuletinModal') editBuletinModal: ElementRef;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();
  @Input() open = false;
  @Input() count;
  @Input() cantaririBuletin;
  @Input() cantaririDetails;
  @Input() loadingDataSpinner;

  modalReference: NgbModalRef;
  cantaririForm: UntypedFormGroup;
  submitted = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  operatori;
  showDeleteHint = false;

  uploadConfig = UIHelper.uploadConfig;

  constructor(
    public modalService: NgbModal,
    private buletinTabelService: BuletineTableService,
    private formBuilder: UntypedFormBuilder,
    private helperDataService: HelperDataService,
    private errorService: NotificationErrorService,
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
   }

  ngOnChanges(changes): void {
    if (changes.open && this.open) {
      this.showDeleteHint = false;
      this.modalReference = this.modalService.open(this.editBuletinModal, {
        windowClass: 'modal-full',
        scrollable: true,
        backdrop: 'static'
      });
    }

    if (this.cantaririBuletin) {
      this.buildForm(this.cantaririBuletin);
    }

    if (! this.operatori) {
      this.buletinTabelService.operatoriAPI().subscribe(
        (response) => {
          this.operatori = response;
        });
    }
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  buildForm(formData) {
    this.cantaririForm = this.formBuilder.group({
      cantariri: this.formBuilder.array([])
    });

    this.createItems(formData);
  }

  get f() {
    return this.cantaririForm.controls.cantariri as UntypedFormArray;
  }

  createItems(data) {
    if (data && data.length > 0) {
      this.cantaririBuletin.forEach(x => {
        x.read_only = false;

        if (! this.isSuperAdmin && x.are_evaluare_genetica && x.weight) {
          x.read_only = true;
        }

        this.f.push(this.createItem(x));
      });
    } else {
      this.f.push(this.createItem());
    }
  }

  createItem(cantarire?): UntypedFormGroup {
    return this.formBuilder.group({
      id: [cantarire ? cantarire.id : ''],
      animalid: [cantarire ? cantarire.animalid : ''],
      numarmatricol: [cantarire ? cantarire.numarmatricol : ''],
      sex_text: [cantarire ? cantarire.sex_text : ''],
      rasa_text: [cantarire ? cantarire.rasa_text : ''],
      greutate_nastere: [cantarire ? cantarire.greutate_nastere : ''],
      varsta_cantarire: [cantarire ? cantarire.varsta_cantarire : ''],
      weight: [cantarire ? cantarire.weight : null],
      gvr: [cantarire ? cantarire.gvr : null],
      smz: [cantarire ? cantarire.smz : null],
      destinationid: [cantarire ? cantarire.destinationid : 1],
      notes: [cantarire ? cantarire.notes : ''],
      are_evaluare_genetica: [cantarire ? cantarire.are_evaluare_genetica : null],
      read_only: [cantarire ? cantarire.read_only : false],
    });
  }

  onSubmitted() {
    this.submitted = true;
    this.open = false;
    this.showDeleteHint = false;
    let params = {
      weighting_id: this.cantaririDetails.id,
      operator_id: this.cantaririDetails.userid,
      file_id: this.cantaririDetails.file_id,
    };

    this.buletinTabelService
      .salveazaCantariri(params, this.cantaririForm.value)
      .subscribe(result => {
        this.sendCallbackResult();
      });
  }

  weightChanged(i) {
    let cantariri = this.cantaririForm.controls.cantariri as UntypedFormArray;
    let fg = cantariri.controls[i] as UntypedFormGroup;
    fg.controls.gvr.setValue(null);
    fg.controls.smz.setValue(null);
  }

  notifyCloseModal() {
    this.notifyClose.emit(false);
    this.submitted = false;
    this.open = false;
  }

  private sendCallbackResult() {
    this.modalReference.close();
    this.callbackResult.emit('');
    this.notifyClose.emit(false);
  }

  uploadServerResponse(event) {
    const file = event.body.files[0];
    this.cantaririDetails.file_id = file.file_id;
    this.cantaririDetails.file_name = file.file_uploaded_name;
  }

  downloadFile(event) {
    event.target.disabled = true;
    let fileName = this.cantaririDetails.file_name;
    let fileid = this.cantaririDetails.file_id;

    this.helperDataService.downloadFile(fileid).subscribe((response: any) => {
      const blob = new Blob([response]);
      this.helperDataService.simulateDownload(blob, fileName);
      event.target.disabled = false;

    }, errors => {
      let blobToTextPromise = errors.error.text();
      blobToTextPromise.then((text: string) => {
        let obj;
        try {
          obj = JSON.parse(text);
        } catch (e) {
          return;
        }

        this.errorService.processErrorMsg(obj.error);
      })
    });
  }

  deleteFile() {
    this.cantaririDetails.file_id = null;
    this.cantaririDetails.file_name = null;
    this.showDeleteHint = true;
  }
}
