<form [formGroup]="animalSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-12" *ngIf="!hideFields && isAdmin">
      <div class="form-group mb-3">
        <label for="exploatatieid">
          Exploatație
        </label>
        <ng-select
          [items]="exploatatii"
          bindLabel="viewLabel"
          bindValue="id"
          formControlName="exploatatieid"
          placeholder="Alege exploatația"
          (search)="searchExploatatii($event)"
          (clear)="clearHoldings()"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <div class="row">
              <div class="col-md-12 col-lg-auto">
                {{item.name}}
                <span *ngIf="item.code" class="small mr-1"> - {{ item.code }}</span>
                <span *ngIf="item.status != 1" class="badge bg-secondary">Inactiv</span>
              </div>
            </div>
          </ng-template>
        </ng-select>

        <small
          *ngIf="showHint"
          class="form-text text-muted ml-2"
        >
          <b>Introdu minim 5 caractere pentru a căuta</b>
        </small>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="numarmatricol">
          Număr matricol
        </label>

        <input
          type="text"
          id="numarmatricol"
          formControlName="numarmatricol"
          placeholder="Adaugă numărul matricol"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="codtaur">
          Cod taur
        </label>

        <input
          type="text"
          id="codtaur"
          formControlName="codtaur"
          placeholder="Caută dupa cod taur"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-4">
      <div class="form-group mb-3">
        <label for="status">
          Status
        </label>
        <ng-select formControlName="status" placeholder="Toate">
          <ng-option value='1'>Activ</ng-option>
          <ng-option value='0'>Inactiv</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-4">
      <div class="form-group mb-3">
        <label for="sex">
          Sex
        </label>
        <ng-select formControlName="sex" placeholder="Toate">
          <ng-option value='1'>Mascul</ng-option>
          <ng-option value='2'>Femelă</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-4">
      <div class="form-group mb-3">
        <label for="rasa">
          Rasă
        </label>
        <ng-select formControlName="rasa" placeholder="Toate">
          <ng-option value='Charolaise'>Charolaise</ng-option>
          <ng-option value='Limousine'>Limousine</ng-option>
          </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="sectiune">
          Secțiune
        </label>
        <ng-select formControlName="sectiune" placeholder="Toate">
          <ng-option value="0">Neîncadrat</ng-option>
          <ng-option value="1">Principală</ng-option>
          <ng-option value="2">Suplimentară A</ng-option>
          <ng-option value="3">Suplimentară B</ng-option>
          <ng-option value="4">Suplimentară C</ng-option>
          <ng-option value="5">Suplimentară D</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="categorie_femela">
          Categorie Femelă
        </label>
        <ng-select
          placeholder="Toate"
          formControlName="categorie_femela"
          (change)="selectCategorieFemela($event)"
        >
          <ng-option value="0">Nespecificat</ng-option>
          <ng-option value="1">Vițea</ng-option>
          <ng-option value="2">Primipară</ng-option>
          <ng-option value="3">Multipară</ng-option>
          <ng-option value="4">Junincă</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="are_avizare_taur">
          Are Avizare Tauri?
        </label>
        <ng-select
          formControlName="are_avizare_taur"
          placeholder="Toate"
          (change)="selectiePentruTaur($event)"
        >
          <ng-option value="1">Da</ng-option>
          <ng-option value="0">Nu</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="are_autorizatie_monta">
          Are Autorizație Montă?
        </label>
        <ng-select
          formControlName="are_autorizatie_monta"
          placeholder="Toate"
          (change)="selectiePentruTaur($event)"
        >
          <ng-option value="1">Da</ng-option>
          <ng-option value="0">Nu</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6" *ngIf="!hideFields && !isOjz">
      <div class="form-group mb-3">
        <label for="judetid">
          Județul
        </label>

        <ng-select
          tabindex="10"
          [items]="cities"
          bindLabel="name"
          placeholder="Selectează județul"
          formControlName="judetid"
          (change)="getSelectedCity($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!hideFields && !isOjz">
      <div class="form-group mb-3">
        <label for="localitateid">
          Localitatea
        </label>

        <ng-select
          tabindex="11"
          [items]="smallCities"
          bindLabel="name"
          placeholder="Selectează localitatea"
          formControlName="localitateid"
          notFoundText="Selectați un județ"
          #smallCitiesDropDown
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
