import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UsersTableService } from 'src/app/modules/acbcr/common/services/users-table.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { User, UserType } from 'src/app/modules/acbcr/common/models/common.models';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { getAppCities } from 'src/app/modules/acbcr/common/state/reducers/countries-cities.reducers';
import { City } from 'src/app/modules/acbcr/common/models/city.model';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('addEditUtilizatorModal') addEditUtilizatorModal: ElementRef;

  @Input() utilizator: User;
  @Input() open = false;
  @Output() notifyClose = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  modalReference: NgbModalRef;

  modalType: string;
  utilizatorForm: UntypedFormGroup;
  submitted = false;
  duplicatedUser = false;
  isAdmin = false;
  isSuperAdmin = false;

  cities: City[];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public modalService: NgbModal,
    private utilizatorTabelService: UsersTableService,
    private errorService: NotificationErrorService,
    private store: Store<ApplicationState>,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isSuperAdmin = UIHelper.isSuperAdmin();

    this.store.pipe(select(getAppCities))
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: any) => {
      this.cities = response;
    });
  }

  ngOnChanges(changes) {
    if (changes.open && this.open) {
      this.modalReference = this.modalService.open(this.addEditUtilizatorModal, {
        windowClass: 'modal-half-full',
        scrollable: true,
        backdrop: 'static'
      });
    }

    if (this.utilizator) {
      this.modalType = 'edit';
      this.buildForm(this.utilizator);
      this.duplicatedUser = false;
    } else {
      this.modalType = 'add';
      this.buildForm();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  @HostListener('document:keyup.escape')
  onKeydownHandler() {
    if (this.open) {
      this.notifyCloseModal();
    }
  }

  buildForm(formData?) {
    this.utilizatorForm = new UntypedFormGroup({
      id: new UntypedFormControl(formData ? formData.id : null),
      username: new UntypedFormControl(
        formData ? formData.username : '',
        Validators.required),
      last_name: new UntypedFormControl(formData ? formData.last_name : ''),
      first_name: new UntypedFormControl(formData ? formData.first_name : ''),
      email: new UntypedFormControl(
        formData ? formData.email : '',
        [Validators.required, Validators.email]),
      phone_number: new UntypedFormControl(formData ? formData.phone_number : ''),
      countyid: new UntypedFormControl(formData ? formData.countyid : null),
      is_active: new UntypedFormControl(
        formData ? formData.is_active : true,
        Validators.required),
      user_type: new UntypedFormControl(
        formData ? formData.user_type : null,
        Validators.required),
    });

    if (formData) {
      this.userTypeChanged(formData.user_type);
    }

    this.submitted = false;
  }

  onSubmitted() {
    this.submitted = true;
    if (!this.utilizatorForm.valid) {
      return;
    }

    if (this.modalType === 'add') {
      this.utilizatorTabelService.addUtilizator(this.utilizatorForm.value)
        .subscribe(result => {
          this.sendCallbackResult();
          this.duplicatedUser = false;
        }, error => {
          error.error.error = 'duplicate_username';
          this.errorService.processErrorMsg(error.error.error);
          if (error.status === 500) {
            this.duplicatedUser = true;
          }
        });
    } else {
      this.utilizatorTabelService.updateUtilizator(this.utilizatorForm.value)
        .subscribe(result => {
          this.duplicatedUser = false;
          this.sendCallbackResult();
        });
    }
  }

  userTypeChanged(event) {
    if (event === UserType.Ojz) {
      this.f.countyid.setValidators(Validators.required);
    } else {
      this.f.countyid.setValue(null);
      this.f.countyid.clearValidators();
    }

    this.f.countyid.updateValueAndValidity();
  }

  get f() { return this.utilizatorForm.controls; }

  notifyCloseModal() {
    this.notifyClose.emit(false);
  }

  private sendCallbackResult() {
    this.modalReference.close();
    this.callbackResult.emit('');
    this.notifyClose.emit(false);
  }

}
