import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { RapoarteFacturiEmiseTableService } from 'src/app/modules/acbcr/common/services/rapoarte-facturi-emise.service';
import { RapoarteFacturiNeachitateTableService } from 'src/app/modules/acbcr/common/services/rapoarte-facturi-neachitate.service';

@Component({
  selector: 'app-raport-facturi-neachitate',
  templateUrl: './raport-facturi-neachitate.component.html',
  styleUrls: ['./raport-facturi-neachitate.component.scss']
})
export class RaportFacturiNeachitateComponent implements OnInit {

  tables$: Observable<any[]>;
  total$: Observable<number>;
  loadingDataSpinner = false;

  objToAPI = {
    page_no: '1',
    page_size: '10'
  };

  constructor(
    public rapoarteFacturiNeachitate: RapoarteFacturiNeachitateTableService,
    public helperDataService: HelperDataService
  ) {
    this.tables$ = rapoarteFacturiNeachitate.tables$;
    this.total$ = rapoarteFacturiNeachitate.total$;
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.rapoarteFacturiNeachitate
      .getRapoarteFacturiNeachitateAPI(this.objToAPI).subscribe((response) => {
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  exportData(event) {
    this.rapoarteFacturiNeachitate
      .exportXLS(this.objToAPI)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload( blob, `Rapoarte-facturi-neachitate.xls`);
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

}
