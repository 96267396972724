<ng-template
  #repFatModal
  let-modal="close"
>
  <div
    class="modal-header"
    [ngSwitch]="modalType"
  >
    <div
      *ngIf="(currentRoute.includes('/exploatatie/') && !isAutoritateJudeteana && !isContabil)"
      [ngSwitch]="modalType"
      class="d-flex"
    >
      <div *ngIf="showAddButton">
        <button
          class="btn btn-lg btn-success ml-2"
          *ngSwitchCase="'reproductii'"
          (click)="addReproductie('add-reproductie')"
        >
          Adaugă reproducție nouă
        </button>
      </div>

      <div *ngIf="showAddButton">
        <button
          class="btn btn-lg btn-success ml-2"
          *ngSwitchCase="'fatari'"
          (click)="addFatare('add-fatare')"
        >
          Adaugă fătăre nouă
        </button>
      </div>

      <div *ngIf="showAddButton">
        <button
          class="btn btn-lg btn-success ml-2"
          *ngSwitchCase="'miscari'"
          (click)="addMiscare('add-miscare')"
        >
          Adaugă mișcare nouă
        </button>
      </div>

      <div *ngIf="isAdmin && creatDeFermier">
        <button
          type="submit"
          class="btn btn-blue ml-3"
          (click)="verificat(); modal('Cross click');"
        >
          Verificat
        </button>
      </div>
    </div>

    <div>
      <h3 class="modal-title" *ngSwitchCase="'reproductii'">
        Anexa 3 - Înregistrări I.A./M.N.
      </h3>
      <h3 class="modal-title" *ngSwitchCase="'fatari'">
        Anexa 8 - Înregistrare fătare
      </h3>
      <h3 class="modal-title" *ngSwitchCase="'miscari'">
        Anexa 10 - Înregistrări intrări/ieșiri
      </h3>
    </div>

    <div>
      <button
        type="button"
        class="close"
        aria-hidden="true"
        (click)="modal('Cross click')"
      >
        ×
      </button>
    </div>
  </div>

  <div class="modal-body d-lg-flex">

    <div class="left-side overflow-auto text-nowrap flex-shrink-0 pr-1 mr-1">
      <table class="table table-hover" [ngSwitch]="modalType">
        <tbody app-fatari-list
          *ngSwitchCase="'fatari'"
          [anexe]="anexaList"
          [selectedItem]="selectedItem"
          (selectedItemChanged)="onSelectedItemChanged($event)"
          (openEditFatare)="editFatare($event)"
        >
        </tbody>

        <tbody app-reproductii-list
          *ngSwitchCase="'reproductii'"
          [anexe]="anexaList"
          [selectedItem]="selectedItem"
          (selectedItemChanged)="onSelectedItemChanged($event)"
          (openEditReproductie)="editReproductie($event)"
        >
        </tbody>

        <tbody app-miscari-list
          *ngSwitchCase="'miscari'"
          [anexe]="anexaList"
          [selectedItem]="selectedItem"
          (selectedItemChanged)="onSelectedItemChanged($event)"
          (openEditMiscare)="editMiscare($event)"
        >
        </tbody>

      </table>
    </div>

    <div class="overflow-auto flex-fill pr-2">
      <app-add-edit-reproductie-form
        *ngIf="showAddRep"
        [anexa]="anexa"
        [anexaId]="anexaId"
        [reprModalType]="reprModalType"
        (closeForm)="makeCloseForm($event)"
        (callbackResult)="makeCallbackResult($event)"
      >
      </app-add-edit-reproductie-form>

      <app-add-edit-fatare-form
        *ngIf="showAddFat"
        [anexa]="anexa"
        [anexaId]="anexaId"
        [showAddButton]="showAddButton"
        [fatareModalType]="fatareModalType"
        (closeForm)="makeCloseForm($event)"
        (callbackResult)="makeCallbackResult($event)"
      >
      </app-add-edit-fatare-form>

      <app-add-edit-miscare-form
        *ngIf="showAddMove"
        [anexa]="anexa"
        [anexaId]="anexaId"
        [moveModalType]="moveModalType"
        [showAddButton]="showAddButton"
        (closeForm)="makeCloseForm($event)"
        (callbackResult)="makeCallbackResult($event)"
      >
      </app-add-edit-miscare-form>
    </div>

  </div>
</ng-template>
