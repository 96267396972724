import { Action } from '@ngrx/store';
import { AnimalRase } from '../../models/animal.models';

export enum AppAnimalsActionType {
  SET_ANIMALS_RASE_DATA = 'SET_ANIMALS_RASE_DATA'
}

export class SetAnimalsRaseData implements Action {
  readonly type = AppAnimalsActionType.SET_ANIMALS_RASE_DATA;
  constructor(public payload: AnimalRase) { }
}

export type AppAnimalsActions = SetAnimalsRaseData;
