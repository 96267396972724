<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="avizareTauriService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changePageSize($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="clickSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>

                <button
                  *ngIf="isAdmin || isOperator"
                  class="btn btn-blue btn-sm"
                  type="button"
                  (click)="openAddModal()"
                  [disabled]="loadingDataSpinner"
                >
                  <i class="fe-save"></i>
                  Crează Avizare Taur
                </button>

                <button
                  *ngIf="isAdmin || isOperator"
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="export($event)"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <div
            class="spinner-border text-info table-spinner avatar-lg"
            role="status"
            *ngIf="loadingDataSpinner"
          ></div>

          <!-- Table -->
          <div class="table-responsive">
            <table
              id="adeverinte-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th>
                    Data
                  </th>
                  <th>
                    Exploatație
                  </th>
                  <th>
                    Taur
                  </th>
                  <th>
                    Rasa
                  </th>
                  <th>
                    Data nașterii
                  </th>
                  <th>
                    Număr
                  </th>
                  <th>
                    Status
                  </th>
                  <th scope="col">
                    Acțiuni
                  </th>
                </tr>
              </thead>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let item of itemsList">
                  <td>{{ item.data_dmy }}</td>
                  <td>{{ item.nume_exploatatie }}</td>
                  <td>{{ item.matricol_taur }}</td>
                  <td>{{ item.rasa }}</td>
                  <td>{{ item.data_nasterii_dmy }}</td>
                  <td>{{ item.numar }}</td>
                  <td>{{ item.status_text }}</td>

                  <td class="action-buttons">
                    <div class="btn-group">

                      <button
                        class="btn btn-sm btn-success"
                        (click)="download($event, item)"
                      >
                        Descarcă
                      </button>

                      <ng-container *ngIf="isAdmin || isOperator">
                        <button
                          class="btn btn-sm btn-blue"
                          (click)="openEditModal(item)"
                        >
                          Editează
                        </button>

                        <button
                          *ngIf="item.status == 1"
                          class="btn btn-sm btn-primary"
                          [ngClass]="'finalizeaza-id-' + item.id"
                          (click)="openFinalizeazaModal(item.id)"
                        >
                          Finalizează
                        </button>

                        <button
                          *ngIf="item.status == 2"
                          class="btn btn-sm btn-danger"
                          [ngClass]="'anuleaza-id-' + item.id"
                          (click)="openAnuleazaModal(item.id)"
                        >
                          Anulează
                        </button>

                        <button
                          *ngIf="isSuperAdmin || (!isSuperAdmin && item.status == 1)"
                          class="btn btn-sm btn-danger"
                          [ngClass]="'sterge-id-' + item.id"
                          (click)="openDeleteModal(item.id)"
                        >
                          Șterge
                        </button>
                      </ng-container>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <ngb-alert
              type="danger"
              class="text-center search-error"
              *ngIf="!loadingDataSpinner && avizareTauriService.totalRecords === 0"
              [dismissible]="false"
            >
              Nu există înregistrări
            </ngb-alert>

          </div>
          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="avizareTauriService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ avizareTauriService.startIndex }} la
                {{ avizareTauriService.endIndex }} din
                {{ avizareTauriService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="totalCount"
                  [(page)]="avizareTauriService.page"
                  [pageSize]="avizareTauriService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changePageNumber($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-edit-avizare-tauri
  [addEditModalData]="addEditModalData"
  (notifyClose)="closeAddEditModal()"
>
</app-add-edit-avizare-tauri>

<app-confirm-action-modal
  [open]="openDelete"
  [text]="'Sunteți sigur că doriți să ștergeți avizarea?'"
  (confirm)="closeDeleteModal($event)"
></app-confirm-action-modal>

<app-confirm-action-modal
  [open]="openFinalizeaza"
  [text]="'Finalizați avizarea?'"
  (confirm)="closeFinalizeazaModal($event)"
></app-confirm-action-modal>

<app-confirm-action-modal
  [open]="openAnuleaza"
  [text]="'Anulați avizarea?'"
  (confirm)="closeAnuleazaModal($event)"
></app-confirm-action-modal>


<ng-template #searchModal let-modal="close">
  <div class="modal-header">
    <h3 class="modal-title">
      Caută Avizare Tauri
    </h3>

    <button
      type="button"
      class="close"
      aria-hidden="true"
      (click)="modal('Cross click')"
    >
      ×
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="avizareTauriForm">

        <div class="row">
          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="start_date">
                      Dată început
                  </label>
                  <app-date-picker
                    [selectedDate]="avizareTauriForm.controls.start_date.value"
                    (dateChanged)="avizareTauriForm.controls.start_date.setValue($event)">
                  </app-date-picker>
              </div>
          </div>

          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="end_date">
                      Dată sfârșit
                  </label>
                  <app-date-picker
                    [selectedDate]="avizareTauriForm.controls.end_date.value"
                    (dateChanged)="avizareTauriForm.controls.end_date.setValue($event)">
                  </app-date-picker>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="company_name">
                Denumirea exploatației
              </label>

              <input
                type="text"
                id="company_name"
                formControlName="company_name"
                placeholder="Adaugă denumirea exploatației"
                class="form-control" />
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="company_code">
                Codul exploatației
              </label>

              <input
                type="text"
                id="company_code"
                formControlName="company_code"
                placeholder="Adaugă codul exploatației"
                class="form-control" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3">
              <label for="numarmatricol">
                Număr matricol
              </label>

              <input
                type="text"
                id="numarmatricol"
                formControlName="numarmatricol"
                placeholder="Adaugă numărul matricol"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
              <div class="form-group mb-3">
                  <label for="rasa">
                      Rasă
                  </label>
                  <ng-select formControlName="rasa" placeholder="Toate">
                      <ng-option value='Charolaise'>Charolaise</ng-option>
                      <ng-option value='Limousine'>Limousine</ng-option>
                  </ng-select>
              </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
                <label for="status">
                    Status Avizare
                </label>
                <ng-select formControlName="status" placeholder="Toate">
                    <ng-option value='1'>Salvată</ng-option>
                    <ng-option value='2'>Finalizată</ng-option>
                    <ng-option value='3'>Anulată</ng-option>
                </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group mb-3">
              <label for="status_animal">
                Status animal
              </label>
              <ng-select formControlName="status_animal" placeholder="Toate">
                <ng-option value='1'>Activ</ng-option>
                <ng-option value='-1'>Inactiv</ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
            <div class="btn-group">
              <button class="btn btn-sm btn-blue" (click)="search(); modal('Cross click')">
                <i class="fe-search"></i>
                Caută
              </button>
              <button class="btn btn-sm btn-warning" (click)="resetFields()">
                <i class="fe-delete"></i>
                Resetează câmpurile
              </button>
            </div>
          </div>

          <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
            <button class="btn btn-sm btn-danger" (click)="resetSearch(); modal('Cross click')">
              Resetează căutarea
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>
</ng-template>
