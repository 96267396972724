<div class="card" id="registruGenealogic" [formGroup]="animalForm">
  <h3 class="card-header">Registru Genealogic</h3>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12"><h4>REGISTRU GENERAL</h4></div>

      <div class="col-sm-12 col-md-5">
        <div class="form-group mb-3">
          <label for="country">
            Țară origine
          </label>
          <ng-select
            [items]="countries"
            bindLabel="name"
            bindValue="id"
            placeholder="Alege țara de origine"
            formControlName="taraorigineid"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-sm-12 col-md-1"></div>

      <div class="col-sm-12 col-md-5">
        <div class="form-group mb-3">
          <label for="taraprovenientaid">
            Țară proveniență
          </label>
          <ng-select
            [items]="countries"
            bindLabel="name"
            bindValue="id"
            placeholder="Alege țara de proveniență"
            formControlName="taraprovenientaid"
            [ngClass]="{ disabled: isFermier || disableAllFields }"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="animalForm.value.id; else rgMessage"
    >
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div class="form-group mb-3">
            <label for="sectreggeneral">
              Secțiune registru general
            </label>
            <ng-select
              bindLabel="sectreggeneral"
              bindValue="sectreggeneral"
              placeholder="Alege registru general"
              formControlName="sectreggeneral"
              [clearable]="false"
              [ngClass]="{ disabled: isFermier || disableAllFields || sectionChanged}"
              (change)="rgGenSectionChanged($event)"
            >
              <ng-option value="0">Neîncadrat</ng-option>
              <ng-option value="1">A</ng-option>
              <ng-option value="2">B</ng-option>
              <ng-option value="3">C</ng-option>
              <ng-option value="4">D</ng-option>
            </ng-select>
          </div>
        </div>

        <ng-template #popContent>
          <div class="popover-content">
            <div [innerHtml]="fitMessage.message" class="text"></div>
          </div>

          <div class="separator mt-1 mb-2"></div>

          <div class="col-sm-12 mb-1">
            <button
              type="button"
              class="btn btn-sm btn-dark"
              ngbTooltip="Revenire la secțiunea anterioară"
              (click)="cancelUpdateDescendants()"
            >
              Anulează
            </button>
            <button
              type="button"
              ngbTooltip="Actualizează animalul și descendenții săi"
              class="btn btn-sm btn-success float-right"
              (click)="updateDescendants()"
            >
              Actualizează
            </button>
          </div>
        </ng-template>

        <ng-template #popTitle>
          <div class="text-center">
            <h3 class="popover-title">{{ fitMessage.AfectedChildrens }}</h3>
          </div>
        </ng-template>

        <div class="col-sm-6" *ngIf="generalSectionChanged">
          <div class="form-group">
            <button
              type="button"
              class="btn btn-info"
              [ngbPopover]="popContent"
              [popoverTitle]="popTitle"
              [placement]="'end'"
              [autoClose]="false"
              popoverClass="popover-style"
              triggers="manual"
              #popover="ngbPopover"
              (click)="viewAfectedDescendants()"
            >
              <span class="btn-label"><i class="fe-help-circle"></i></span
              >Verifică descendenți
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-auto"><h4>REGISTRU GENEALOGIC ROMÂN</h4></div>
        <div class="col-auto evaluare-incadrare ml-3">
          <button
            *ngIf="!isFermier && !disableAllFields && animalForm.value.id"
            type="button"
            class="btn btn-sm btn-info"
            (click)="viewReasons(animalForm.value.id)"
          >
            Evaluare încadrare
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div class="form-group mb-3">
            <label for="sectregro">
              Secțiune registru
            </label>
            <ng-select
              bindLabel="sectregro"
              formControlName="sectregro"
              [clearable]="false"
              (change)="rgSectionChanged($event)"
              [ngClass]="{
                'is-invalid': errorMessage,
                disabled: disableAllFields || generalSectionChanged
                  || !(isSuperAdmin || (isAdmin && animalForm.value.corespunde_standard == true))
              }"
            >
              <ng-option value="0">Neîncadrat</ng-option>
              <ng-option value="1">Principală</ng-option>
              <ng-option value="2">Suplimentară A</ng-option>
              <ng-option value="3">Suplimentară B</ng-option>
              <ng-option value="4">Suplimentară C</ng-option>
              <ng-option value="5">Suplimentară D</ng-option>
            </ng-select>
          </div>
          <div
            *ngIf="errorMessage"
            class="invalid-feedback mb-2 ml-1"
            [ngClass]="{
              'd-block': errorMessage
            }"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div class="col-sm-12 col-md-1"></div>

        <div class="col-sm-12 col-md-3">
          <div class="form-group mb-3">
            <label>
              Număr Registru
            </label>
            <div
              *ngIf="animalForm.value.sectregro && animalForm.value.sectregro !== '0'"
              class="pt-1"
            >
              {{ animalForm.value.numarmatricol }}
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-3">
          <div class="form-group mb-3">
            <label>
              Data intrării în RG
            </label>
            <div class="pt-1">
              {{ dataRG }}
            </div>
          </div>
        </div>

        <div class="col-sm-6" *ngIf="sectionChanged">
          <div class="form-group mt-3">
            <button
              type="button"
              class="btn btn-info"
              [ngbPopover]="popContent"
              [popoverTitle]="popTitle"
              [placement]="'top'"
              [autoClose]="false"
              popoverClass="popover-style"
              triggers="manual"
              #popover="ngbPopover"
              (click)="viewAfectedDescendants()"
            >
              <span class="btn-label"><i class="fe-help-circle"></i></span>Verifică descendenți
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #rgMessage>
      <div class="m-3 text-dark text-center">
        <h5>Celelalte câmpuri vor fi disponibile după ce noul animal va fi creat</h5>
      </div>
    </ng-template>

  </div>
</div>

<app-view-reason-modal
  [reasonModalData]="reasonModalData"
>
</app-view-reason-modal>
