import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  AdvancedSortableDirective,
  SortEvent,
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { FacturiTableService } from 'src/app/modules/acbcr/common/services/facturi-table.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { convertDateToBackendFormat } from '../../../../../common/services/services-table-helper';

@Component({
  selector: 'app-facturi-table-view',
  templateUrl: './facturi-table-view.component.html',
  styleUrls: ['./facturi-table-view.component.scss'],
})
export class FacturiTableViewComponent implements OnInit, OnChanges {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('facturiTable', { static: false }) facturiTable: ElementRef;
  @ViewChild('emailConfirmationModal') emailConfirmationModal: ElementRef;

  @Input() facturi: any[];
  @Input() facturiLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() filterData = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() tableCallback = new EventEmitter();

  openFacturaManualaModal = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  openSearchModal = new Subject<boolean>();
  isDataFiltered = false;
  filteredFacturi;
  openGenerareFacturiModal = false;
  modalType: string;
  facturaData: any;
  preserieFactura;
  allChecked = false;
  mySet;
  removeSet;
  filterObject: any = {};
  facturiIds;
  showConfirmationModal = false;

  constructor(
    public facturiTableService: FacturiTableService,
    public helperDataService: HelperDataService,
    public errorService: NotificationErrorService
  ) {
    this.mySet = new Set<string>();
    this.removeSet = new Set<string>();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.allChecked) {
      const removeFacturaIds = Array.from(this.removeSet);
      this.facturi.forEach((factura) => {
        if (!removeFacturaIds.includes(factura.id) && (factura.status === 1 || factura.status === 2 || factura.status === 4)) {
          factura.isChecked = true;
        }
      });
    }

    if (changes.facturi && this.mySet.size) {
      this.facturi.forEach(factura => {
        if (this.mySet.has(factura.id)) {
          factura.isChecked = true;
        }
      });
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.facturiTableService.sortColumn = column;
    this.facturiTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openTableSearchModal() {
    this.openSearchModal.next(true);
  }

  filterSearch(event) {
    this.mySet.clear();
    this.removeSet.clear();
    this.filterObject = event;
    this.filterData.emit(event);
    this.filteredFacturi = event;
    this.isDataFiltered = !Object.values(this.filteredFacturi).every(
      (o) => o === null
    );
  }

  getModalResult(event) {
    this.tableCallback.emit(event);
  }

  changeStatusFacturi(facturaId, status) {
    this.facturiTableService
      .changeStatusFacturi(facturaId, status)
      .subscribe((response) => {
        this.tableCallback.emit(null);
      });
  }

  anuleazaFactura(facturaId, status, factura?) {
    const dataFacturii = convertDateToBackendFormat(factura.data);

    if (this.checkDateInterval(dataFacturii) > 30) {
      this.errorService.processErrorMsg(
        'Anularea este posibilă în maxim 30 de zile de la emitere'
      );
    } else {
      this.facturiTableService.changeStatusFacturi(facturaId, status).subscribe(
        (response) => {},
        (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      );
      this.tableCallback.emit(null);
    }
  }

  private checkDateInterval(data) {
    const date1 = new Date();
    const date2 = new Date(data);
    const daysDiff = Math.floor(
      (Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) -
        Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    return daysDiff;
  }

  respingeSubventie(facturaId, subventie) {
    this.facturiTableService
      .respingeSubventie(facturaId, subventie)
      .subscribe();
    this.tableCallback.emit(null);
  }

  exportXls() {
    this.facturiTableService
      .exportXLS(this.filteredFacturi)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(blob, `Facturi.xls`);
      });
  }

  memoriuTehnic() {
    this.facturiTableService
      .memoriuTehnic(this.filteredFacturi)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        this.helperDataService.simulateDownload(blob, `Memoriu_tehnic.xls`);
      });
  }

  tiparesteFactura(id, status?, changeStatus?) {
    this.facturiTableService.tiparesteFactura(id).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      this.helperDataService.simulateDownload(blob, `Factură_tipărită.pdf`);
      if (!changeStatus) {
        this.facturiTableService.changeStatusFacturi(id, status).subscribe(
          (responseStatus) => {},
          (errors) => {
            this.errorService.processErrorMsg(errors.error.error);
          }
        );
      }
      this.tableCallback.emit(null);
    });
  }

  expediazaEmail(facturaId, changeStatus?) {
    this.loadingDataSpinner = true;
    this.facturiTableService.expediazaEmail([facturaId]).subscribe(
      (response) => {
        this.showConfirmationModal = true;
        if (!changeStatus) {
          this.facturiTableService.changeStatusFacturi(facturaId, 2).subscribe(
            (responseStatus) => {},
            (errors) => {
              this.loadingDataSpinner = false;
              this.errorService.processErrorMsg(errors.error.error);
            }
          );
        }
        this.tableCallback.emit(null);
        this.loadingDataSpinner = false;
      },
      (errors) => {
        this.showConfirmationModal = false;
        this.loadingDataSpinner = false;
        this.errorService.processErrorMsg(errors.error.error);
      }
    );
  }

  vizualizeazaFactura(id) {
    this.facturiTableService.tiparesteFactura(id).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      window.open(window.URL.createObjectURL(blob));
    });
  }

  closeFacturaManModal(event) {
    this.openFacturaManualaModal = event;
    this.facturaData = null;
  }

  openFacturaManModal(type: string, id?: number) {
    switch (type) {
      case 'add':
        this.facturiTableService
          .getFacturaSerieNumar()
          .subscribe((response) => {
            this.preserieFactura = response.result;
          });

        this.openFacturaManualaModal = true;
        this.facturaData = null;
        this.modalType = type;
        break;
      case 'edit':
        this.facturiTableService.getFacturaAPI(id).subscribe((response) => {
          this.facturaData = response.result[0];
          this.openFacturaManualaModal = true;
          this.modalType = type;
        });
        break;
    }
    this.openFacturaManualaModal = true;
  }

  openGenerareFacturi() {
    this.openGenerareFacturiModal = true;
  }

  closeGenerareFacturiModal(event) {
    this.openGenerareFacturiModal = event;
  }

  // adding factura to facturaList if is checked
  checkAllOptions(event) {
    if (this.allChecked) {
      this.facturi.forEach((factura) => {
        factura.isChecked = true;
      });
    } else {
      this.facturi.forEach((factura) => {
        factura.isChecked = false;
      });
      this.mySet.clear();
    }
    this.removeSet.clear();
  }

  statusFactura(factura) {
    factura.isChecked = !factura.isChecked;
    if (this.allChecked) {
      if (factura.isChecked) {
        this.removeSet.delete(factura.id);
      } else {
        this.removeSet.add(factura.id);
      }
      return;
    }

    if (factura.isChecked) {
      this.mySet.add(factura.id);
    } else {
      this.mySet.delete(factura.id);
    }
  }

  expediereEmailList() {
    this.loadingDataSpinner = true;
    if (this.allChecked) {
      this.filterObject.page_no = 1;
      this.filterObject.page_size = this.facturiLength;
      this.facturiTableService
        .getFacturiForEmail(this.filterObject)
        .subscribe((response) => {
          this.facturiIds = [];
          const removeFacturaIds = Array.from(this.removeSet);
          response.result.forEach((factura) => {
            if (!removeFacturaIds.includes(factura.id) && (factura.status === 1 || factura.status === 2 || factura.status === 4)) {
              this.facturiIds.push(factura.id);
            }
          });
          if (this.facturiIds.length === 0) {
            this.loadingDataSpinner = false;
            alert('Nu a fost selectată nici o factură');
            return;
          }
          this.facturiTableService
            .expediazaEmail(this.facturiIds)
            .subscribe((facturaResp) => {
              if (this.facturiIds.length === 1) {
                this.showConfirmationModal = true;
              }
              this.loadingDataSpinner = false;
              this.facturiIds = [];
              this.tableCallback.emit(null);
            });
        });
    } else {
      let facturiIds = Array.from(this.mySet);
      if (facturiIds.length === 0) {
        this.loadingDataSpinner = false;
        alert('Nu a fost selectată nici o factură');
        return;
      }
      this.facturiTableService
        .expediazaEmail(facturiIds)
        .subscribe((response) => {
          if (facturiIds.length === 1) {
            this.showConfirmationModal = true;
          }
          this.loadingDataSpinner = false;
          facturiIds = null;
          this.tableCallback.emit(null);
        });
    }
  }

  closeConfirmationEmailModal() {
    this.showConfirmationModal = false;
  }
}
