<ng-template #modalTemplate let-modal="close">
    <div class="modal-header">
      <h3 class="modal-title">
        {{ label }}
      </h3>

      <button
        type="button"
        class="close"
        aria-hidden="true"
        (click)="modal('Cross click')"
      >
        ×
      </button>
    </div>

    <div class="modal-body">
      <div class="container-fluid h-100 d-flex flex-column">
        <div class="h-100 overflow-auto mb-2">
          <ckeditor
            *ngIf="showEditor"
            [editor]="Editor"
            [(ngModel)]="html"
            [config]="editorConfig"
            (ready)="onReady($event)"
          ></ckeditor>

          <textarea
            *ngIf="!showEditor"
            [(ngModel)]="html"
            class="form-control"
          ></textarea>
        </div>

        <div class="row">
          <div class="col-6">
            <button
              *ngIf="showEditor"
              type="button"
              class="btn btn-dark"
              (click)="toggleShowEditor()"
            >
              Sursa HTML
            </button>

            <button
              *ngIf="!showEditor"
              type="button"
              class="btn btn-dark"
              (click)="toggleShowEditor()"
            >
              Editor
            </button>
          </div>
          <div class="col-6 text-right">
            <button
              type="button"
              class="btn btn-success"
              (click)="salveazaTemplate()"
            >
              Salvează
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
