import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registrul-monte-fatari',
  templateUrl: './registrul-monte-fatari.component.html',
  styleUrls: ['./registrul-monte-fatari.component.scss']
})
export class RegistrulMonteFatariComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
