<ng-template #reasonModal let-modal="close">

  <div
    *ngIf="loadingDataSpinner"
    class="modal-header vh-100"
  >
    <h3 class="modal-title">
      Evaluare animal pentru R.G.
    </h3>
    <div
      class="spinner-border text-info table-spinner avatar-lg"
      role="status"
    >
    </div>
  </div>

  <div
    *ngIf="!loadingDataSpinner"
    class="modal-header"
  >
    <h3 class="modal-title">
      Evaluare animal pentru R.G. <br/>
      <span class="reason-data">
        <div class="row" *ngIf="reasonData">
          <div class="col-12">
            <span class="animal-numar-matricol">{{ reasonData.numarmatricol }}</span>
            <i class="mdi mdi-gender-female" *ngIf="reasonData.sex === 1"></i>
            <i class="mdi mdi-gender-male" *ngIf="reasonData.sex === 2"></i>
            {{ reasonData.nume }}, {{ raseStr }}, varsta {{ reasonData.varsta ? reasonData.varsta : '?' }} zile
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            Proprietar: <span class="proprietar-name">{{ reasonData.proprietar }}</span>
          </div>
        </div>
      </span>
    </h3>
    <div class="btn-group custom-modal-btn">
      <button
        class="btn btn-sm btn-dark"
        (click)="modal('Cross click')"
        autofocus="false"
      >
        Închide
      </button>
    </div>
  </div>

  <div
    *ngIf="!loadingDataSpinner"
    class="modal-body"
  >
    <div class="row message-row" *ngFor="let generalMessage of generalMessages">
      <div class="col-12" *ngIf="generalMessage">
        <h4 class="evaluare-message" [style.color]="generalMessage.color">{{ generalMessage.message }}</h4>
      </div>
    </div>
    <div
      class="separator mt-3 mb-3">
    </div>
    <div class="col-sm-12 text-center">
      <h3 class="modal-title mb-2">Arborele genealogic</h3>

      <p-organizationChart
        [(value)]="animalFamilyTree"
        selectionMode="single"
        [preserveSpace]="false"
        styleClass="company"
      >
        <ng-template let-node pTemplate="animal">
          <div>
            <div class="node-header ui-corner-top">
              <span>{{ node.numarmatricol }}</span>
              <div><i>{{ node.nume }}</i></div>
              <div class="separator"></div>
              <div>{{ node.sex }}</div>
            </div>
            <div class="node-content">
              <div>{{ node.datanastere }}</div>
              <div class="text-muted">
                {{ node.sectiune_rg }}
              </div>
              <div>{{ node.rasa }}</div>
            </div>
          </div>
        </ng-template>
        <ng-template let-node pTemplate="parent">
          <div>
            <div class="node-header">
              <span>{{ node.numarmatricol }}</span>
              <div><i>{{ node.nume }}</i></div>
              <div class="separator"></div>
              <div>{{ node.sex }}</div>
            </div>
            <div class="node-content">
              <div>{{ node.datanastere }}</div>
              <div
                *ngIf="node.sectiune_rg"
                class="text-muted"
              >
                {{ node.sectiune_rg }}
              </div>
              {{ node.father }},
              {{ node.rasa }}
            </div>
          </div>
        </ng-template>
      </p-organizationChart>
    </div>
    <div
      class="separator mt-3 mb-3">
    </div>
    <h3 class="modal-tilte">Anexa 8</h3>
      <div class="row message-row" *ngFor="let anexaMessage of anexa8Messages">
        <div class="col-12" *ngIf="anexaMessage">
          <h4 class="evaluare-message" [style.color]="anexaMessage.color">{{ anexaMessage.message }}</h4>
          <span class="evaluare-message" *ngIf="anexaMessage.anexa8id">
            <a class="underline-link" (click)="viewAnexa8(anexaMessage.anexa8id)">Deschide</a>
            <a class="underline-link ml-2" (click)="downloadAnexa8(anexaMessage.anexa8id)">Descarcă</a>
          </span>
        </div>
      </div>
    <div
      class="separator mt-3 mb-3">
    </div>
    <h3 class="modal-tilte">Apreciere fenotipică</h3>
    <div class="row message-row" *ngFor="let apreciereFenotipicaMessage of apreciereFenotipicaMessages">
      <div class="col-12" *ngIf="apreciereFenotipicaMessage">
        <h4 class="evaluare-message" [style.color]="apreciereFenotipicaMessage.color">{{ apreciereFenotipicaMessage.message }}</h4>
      </div>
    </div>
    <div
      class="separator mt-3 mb-3">
    </div>
    <h3 class="modal-tilte">Descendenți</h3>
    <div class="row message-row" *ngFor="let fatareMessage of fatareMessages">
      <div class="col-12" *ngIf="fatareMessage">
        <h4 class="evaluare-message" [style.color]="fatareMessage.color">{{ fatareMessage.message }}</h4>
      </div>
    </div>
  </div>
</ng-template>
