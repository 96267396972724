<app-moves-table
  [moves]="tables$ | async"
  [movesLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
  (movesTableCallback)="getTableData()"
></app-moves-table>
