<form [formGroup]="moveForm" (ngSubmit)="onSubmitted()">
    <div class="container-fluid">
      <div class="row">

        <div class="col-sm-12">
          <div class="card">
            <h3 class="card-header" *ngIf="moveModalType === addMove; else editTitle">Mișcare nouă</h3>
            <ng-template #editTitle>
              <h3 class="card-header">{{isAutoritateJudeteana || isContabil ? 'Vizualizează mișcare' : 'Editează mișcare'}}</h3>
            </ng-template>

            <div class="card-body" [ngClass]="{'disabled': isAutoritateJudeteana || isContabil}">
              <div class="row">

                <div class="col-12 d-flex flex-wrap">
                  <div class="form-group mb-3 mr-5">
                    <label for="data">
                      Data
                    </label>
                    <app-date-picker
                      [required]="true"
                      [submitted]="submitted"
                      [selectedDate]="f.data.value"
                      [invalid]="dateError"
                      (dateChanged)="onDataChanged($event)"
                    >
                    </app-date-picker>
                    <div
                      *ngIf="dateError"
                      class="invalid-feedback"
                      [ngClass]="{ 'd-block': dateError }"
                    >
                      <div *ngIf="dateError">
                        Data introdusă nu poate fi mai mică decât data de naștere a animalului selectat.
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-3 mr-5">
                    <label></label>
                    <div class="radio mt-2 ml-2 custom-radio-group">
                      <input
                        type="radio"
                        id="iesit"
                        formControlName="intrat"
                        value="2"
                        (change)="onChangeIntrat()"
                      />
                      <label for="iesit" class="radio-label">
                        A ieșit
                      </label>

                      <input
                        type="radio"
                        id="intrat1"
                        formControlName="intrat"
                        value="1"
                        class="ml-3"
                        (change)="onChangeIntrat()"
                      />
                      <label for="intrat1" class="radio-label">
                        A intrat
                      </label>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="motiv">
                      Motiv
                    </label>
                    <ng-select
                      placeholder="Alege motivul"
                      formControlName="motiv"
                      bindLabel="motiv"
                      bindValue="value"
                      [clearable]="false"
                      class="motiv_anexa10"
                      [ngClass]="{ 'is-invalid': submitted && f.motiv.errors }"
                      [items]="motiveMiscare"
                      (change)="onChangeMotiv()"
                    >
                    </ng-select>
                    <div
                      *ngIf="submitted && f.motiv.errors"
                      class="invalid-feedback"
                      [ngClass]="{ 'd-block': submitted && f.motiv.errors }"
                    >
                      <div *ngIf="f.motiv.errors.required">
                        Selectarea unei opțiuni este obligatorie.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-xl-9">
                  <div class="form-group mb-3">
                    <label for="numarmatricol">
                      Număr matricol
                    </label>
                    <ng-select
                      formControlName="numarmatricol"
                      placeholder="Introduceți numarul matricol"
                      bindLabel="numarmatricol"
                      bindValue="numarmatricol"
                      [items]="matricoleAnimal"
                      [ngClass]="{ 'is-invalid': submitted && f.numarmatricol.errors }"
                      (search)="searchMatricoleAnimal($event)"
                      (change)="onChangeAnimal($event)"
                    >
                    </ng-select>
                    <small
                      *ngIf="!f.numarmatricol.value"
                      class="form-text text-muted"
                    >
                      <div class="mb-2">
                        <b>Introdu minim 5 caractere pentru a căuta</b>
                      </div>
                  </small>
                    <div
                      *ngIf="submitted && f.numarmatricol.errors"
                      class="invalid-feedback"
                      [ngClass]="{ 'd-block': submitted && f.numarmatricol.errors }"
                    >
                      <div *ngIf="f.numarmatricol.errors.required">
                        Selectarea unei opțiuni este obligatorie.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-xl-6">
                  <div class="form-group mb-3">
                    <label for="exploatatie_sursa_id">
                      Exploatație
                      <ng-container *ngIf="[2, 3, 4].includes(moveForm.value.motiv)">
                        sursă
                      </ng-container>
                    </label>
                    <ng-select
                      [items]="exploatatiiSursa"
                      bindLabel="viewLabel"
                      bindValue="id"
                      formControlName="exploatatie_sursa_id"
                      placeholder="Alege exploatație"
                      [ngClass]="{
                        'is-invalid': submitted && f.exploatatie_sursa_id.errors,
                        'disabled': (f.intrat.value == 2 || f.numarmatricol.value)
                          && !isSuperAdmin
                      }"
                      (search)="searchExploatatii($event, 'sursa')"
                    >
                    </ng-select>

                    <small
                      *ngIf="!f.exploatatie_sursa_id.value"
                      class="form-text text-muted"
                    >
                      <div class="mb-2">
                        <b>Introdu minim 5 caractere pentru a căuta</b>
                      </div>
                    </small>

                    <div
                      *ngIf="submitted && f.exploatatie_sursa_id.errors"
                      class="invalid-feedback"
                      [ngClass]="{ 'd-block': submitted && f.exploatatie_sursa_id.errors }"
                    >
                      <div *ngIf="f.exploatatie_sursa_id.errors.required">
                        Selectarea unei opțiuni este obligatorie.
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="[2, 3, 4].includes(moveForm.value.motiv)"
                  class="col-sm-12 col-xl-6"
                >
                  <div class="form-group mb-3">
                    <label for="exploatatie_destinatie_id">
                      Exploatație destinație
                    </label>
                    <ng-select
                      [items]="exploatatiiDestinatie"
                      bindLabel="viewLabel"
                      bindValue="id"
                      formControlName="exploatatie_destinatie_id"
                      placeholder="Alege exploatație"
                      [ngClass]="{
                        'is-invalid': submitted && f.exploatatie_destinatie_id.errors,
                        'disabled': f.intrat.value == 1 && !isSuperAdmin
                      }"
                      (search)="searchExploatatii($event, 'destinatie')"
                      (change)="selectExploatatie()"
                    >
                    </ng-select>

                    <small
                      *ngIf="!f.exploatatie_destinatie_id.value"
                      class="form-text text-muted"
                    >
                      <div class="mb-2">
                        <b>Introdu minim 5 caractere pentru a căuta</b>
                      </div>
                    </small>

                    <div
                      *ngIf="submitted && f.exploatatie_destinatie_id.errors"
                      class="invalid-feedback"
                      [ngClass]="{ 'd-block': submitted && f.exploatatie_destinatie_id.errors }"
                    >
                      <div *ngIf="f.exploatatie_destinatie_id.errors.required">
                        Selectarea unei opțiuni este obligatorie.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  *ngIf="arataDocument"
                  class="col-sm-12 col-xl-4"
                >
                  <div class="form-group mb-3">
                    <label>
                      Formular de Mișcare
                    </label>

                    <div class="col-12">
                      <div *ngIf="!fileName" class="d-flex">
                        <app-upload-fisier
                          class="flex-shrink-0"
                          (ApiResponse)="uploadServerResponse($event)"
                        >
                        </app-upload-fisier>
                      </div>

                      <div
                        *ngIf="submitted && moveForm.controls.file_id.errors?.required"
                        class="invalid-feedback d-block"
                      >
                        Fișierul nu a fost adăugat.
                      </div>

                      <div *ngIf="fileName">
                        <button
                          type="button"
                          class="btn p-0 text-blue font-weight-bold"
                          (click)="downloadFile($event)"
                        >
                          <i class="fe-download" ngbTooltip="Descarcă fișier"></i>
                          {{ fileName }}
                        </button>

                        <button
                          type="button"
                          class="btn btn-lg p-0 ml-2 mdi mdi-delete text-danger"
                          ngbTooltip="Șterge fișier"
                          placement="right"
                          (click)="deleteFile()"
                        >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-xl-8">
                  <div class="form-group mb-3">
                    <label for="obs">
                      Observații
                    </label>
                    <input
                      type="text"
                      id="obs"
                      formControlName="obs"
                      placeholder="Adaugă observații"
                      class="form-control" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 separator mb-3"></div>

                <div class="col-sm-12">
                  <div class="form-group mb-3">
                    <label for="status">
                      Starea înregistrării
                    </label>
                    <ng-select
                      formControlName="status"
                      class="starea_inregistrarii"
                      [ngClass]="{ 'disabled': isFermier }"
                      [clearable]="false"
                      [dropdownPosition]="'top'"
                    >
                      <ng-option value="0">În așteptare</ng-option>
                      <ng-option value="1">Verificare</ng-option>
                      <ng-option value="2">Operare</ng-option>
                      <ng-option value="3">Validat</ng-option>
                      <ng-option value="4">Refuzat</ng-option>
                    </ng-select>
                  </div>
                </div>
            </div>
          </div>

          <div class="col-sm-12">
            <button
              class="btn btn-info ml-2"
              type="button"
              (click)="closeFormMethod()"
            >
              {{ isAutoritateJudeteana || isContabil ? 'Închide' : 'Anulează'}}
            </button>
            <button
              *ngIf="moveModalType == editMove && (isAdmin || isOperator || (isFermier && f.status.value == '0'))"
              class="btn btn-danger"
              type="button"
              (click)="openConfirmActionModal()"
            >
              Șterge
            </button>

            <button
              *ngIf="isAdmin || isOperator || (isFermier && f.status.value == '0')"
              class="btn btn-success float-right"
              type="submit"
              [disabled]="dateError || !showAddButton"
            >
              <span *ngIf="moveModalType === addMove; else editBtn">
                Salvează
              </span>

              <ng-template #editBtn>
                <span>Actualizează</span>
              </ng-template>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</form>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="'delete-move'"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
