<app-operators-table
  [operatori]="tables$ | async"
  [operatoriLength]="total$ | async"
  [loadingDataSpinner]="loadingDataSpinner"
  (filterData)="filterSearchData($event)"
  (exportData)="exportData($event)"
  (tableCallback)="getTableCallback($event)"
  (pageSizeChanged)="changePageSize($event)"
  (pageNumberChanged)="changePageNumber($event)"
></app-operators-table>
