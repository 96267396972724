import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getLoadingState, getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { getCurrentRoute } from 'src/app/modules/acbcr/common/state/reducers/route.reducers';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { ReproductionsTableService } from 'src/app/modules/acbcr/common/services/reproductions-table.service';

@Component({
  selector: 'app-reproduction',
  templateUrl: './reproduction.component.html',
  styleUrls: ['./reproduction.component.scss'],
})
export class ReproductionComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  tables$: Observable<any[]>;
  total$: Observable<number>;
  currentRoute: string;
  loadingDataSpinner = false;

  objToAPI = {
    company_id: null,
    page_no: '1',
    page_size: '10'
  };

  constructor(
    private reproductionsTableService: ReproductionsTableService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
    private errorService: NotificationErrorService,
  ) {
    this.tables$ = reproductionsTableService.tables$;
    this.total$ = reproductionsTableService.total$;
  }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentRoute))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        this.currentRoute = response;
      });

    this.store.pipe(
      select(getLoadingState),
      filter(isLoading => !isLoading),
      switchMap(() => this.store.pipe(select(getSelectedHolding))),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
      takeUntil(this.destroy$),
    ).subscribe((response: any) => {
      if (this.currentRoute.includes('/exploatatie')) {
        this.objToAPI['company_id'] = response?.id
      } else {
        this.objToAPI['company_id'] = null;
      }

      this.changePageSize('10');
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getTableData() {
    this.loadingDataSpinner = true;
    this.reproductionsTableService
      .getReproductionsAPI(this.objToAPI)
      .subscribe((response) => {
        this.loadingDataSpinner = false;
      });
  }

  filterSearchData(event) {
    this.objToAPI = {
      ...this.objToAPI,
      ...event,
    };

    this.getTableData();
  }

  exportData(event) {
    this.loadingDataSpinner = true;
    let now = UIHelper.now();
    let fisier = `Reproductii_Anexa3_${now}.xls`;

    this.reproductionsTableService
      .exportXLS(this.objToAPI)
      .subscribe({
        next: (response) => {
          this.loadingDataSpinner = false;
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload( blob, fisier);
        },
        error: (errors) => {
          this.loadingDataSpinner = false;
          this.errorService.processErrorMsg('A apărut o eroare la export');
        }
      });
  }

  changePageSize(event) {
    this.objToAPI.page_size = event;
    this.objToAPI.page_no = '1';
    this.reproductionsTableService.page = 1;
    this.reproductionsTableService.pageSize = event;
    this.getTableData();
  }

  changePageNumber(event) {
    this.objToAPI.page_no = event;
    this.getTableData();
  }

  getCallbackResult(event) {
    this.getTableData();
  }
}
