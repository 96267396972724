import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { ContabilitateSettingsService } from 'src/app/modules/acbcr/common/services/contabilitate-settings.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';

@Component({
  selector: 'app-setari-activitati-page',
  templateUrl: './setari-activitati-page.component.html',
  styleUrls: ['./setari-activitati-page.component.scss'],
})
export class SetariActivitatiPageComponent implements OnInit {
  settingsForm;
  activities;
  submitted = false;
  editSuccess = false;
  activeFields = [];
  isChecked = false;
  tipFactura;

  constructor(
    private contabilitateSettingsService: ContabilitateSettingsService,
    private formBuilder: UntypedFormBuilder,
    private errorService: NotificationErrorService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  selectFactura(event) {
    if (event) {
      this.tipFactura = event;
    } else {
      this.tipFactura = null;
    }
    this.getSetttings(this.tipFactura);
  }

  getSetttings(tipFactura) {
    this.contabilitateSettingsService
      .getActivitiesSettings(tipFactura)
      .subscribe((settings) => {
        if (settings) {
          this.activities = settings;
          this.buildForm(settings);
        } else {
          this.buildForm();
        }
      });
    this.submitted = false;
  }

  buildForm(formData?) {
    this.settingsForm = this.formBuilder.group({
      activities: this.formBuilder.array([]),
    });

    formData?.push({
      id: null,
      descriere: '',
      descriere_scurta: '',
      proc_tva: '',
      proc_subventie: '',
      descriere_subventie: '',
      periodic: false,
    });

    this.createItems(formData);
  }

  get f() {
    return this.settingsForm.controls.activities as UntypedFormArray;
  }

  createItems(data) {
    if (data && data.length > 0) {
      this.activities.forEach((x) => {
        this.f.push(this.createItem(x));
      });
    } else {
      this.f.push(this.createItem());
    }
  }

  createItem(activitate?): UntypedFormGroup {
    return this.formBuilder.group({
      id: [activitate ? activitate.id : ''],
      descriere_scurta: [
        activitate ? activitate.descriere_scurta : '',
        Validators.required,
      ],
      descriere: [activitate ? activitate.descriere : '', Validators.required],
      proc_tva: [activitate ? activitate.proc_tva : '', Validators.required],
      proc_subventie: [
        activitate ? activitate.proc_subventie : '',
        Validators.required,
      ],
      descriere_subventie: [
        activitate ? activitate.descriere_subventie : '',
        Validators.required,
      ],
      periodic: [activitate ? activitate.periodic : '', Validators.required],
    });
  }

  makeActive(i) {
    this.activeFields[i] = true;
  }

  makePeriodic(i, event?) {
    this.f.value[i].periodic = event.target.checked;
  }

  saveLineItem(i) {
    this.settingsForm.value.activities[i].tip_factura = this.tipFactura;
    this.contabilitateSettingsService
      .addActivitate(this.settingsForm.value.activities[i])
      .subscribe(
        (response) => {
          this.getSetttings(this.tipFactura);
          this.activeFields[i] = false;
          this.createItems([]);
        },
        (errors) => {
          this.errorService.processErrorMsg('Toate câmpurile sunt obligatorii');
        }
      );
  }

  editLineItem(i) {
    this.settingsForm.value.activities[i].tip_factura = this.tipFactura;
    this.contabilitateSettingsService
      .updateActivitate(this.settingsForm.value.activities[i])
      .subscribe(
        (response) => {
          this.getSetttings(this.tipFactura);
          this.activeFields[i] = false;
        },
        (errors) => {
          this.errorService.processErrorMsg('Toate câmpurile sunt obligatorii');
        }
      );
  }

  deleteLineItem(i, id) {
    this.contabilitateSettingsService
      .deleteActivitate(id)
      .subscribe((response) => {
        this.getSetttings(this.tipFactura);
        this.activeFields.splice(i, 1);
      });
  }
}
