import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import {
  AdvancedSortableDirective,
  SortEvent
} from 'src/app/modules/acbcr/directives/advanced-sortable.directive';
import { Cantarire } from 'src/app/modules/acbcr/common/models/cantarire.model';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { BuletineTableService } from 'src/app/modules/acbcr/common/services/buletine-table.service';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { takeUntil } from 'rxjs/operators';
import { getAppAnimalsRases } from 'src/app/modules/acbcr/common/state/reducers/animals.reducers';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';

@Component({
  selector: 'app-buletine-control',
  templateUrl: './buletine-control.component.html',
  styleUrls: ['./buletine-control.component.scss']
})
export class BuletineControlComponent implements OnInit, OnChanges {
  @ViewChildren(AdvancedSortableDirective) headers: QueryList<
    AdvancedSortableDirective
  >;
  @ViewChild('buletineTable', { static: false }) buletineTable: ElementRef;

  @Input() cantariri: Cantarire[];
  @Input() cantaririLength: number;
  @Input() loadingDataSpinner: boolean;

  @Output() pageSizeChanged = new EventEmitter();
  @Output() pageNumberChanged = new EventEmitter();
  @Output() tableCallback = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();
  openAddBuletinControlModal = false;
  openEditBuletinControlModal = false;
  openConfirmModal = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  cantaririBuletin;
  cantaririDetails;
  buletinToDelete;
  animalRace;
  count;
  modalTypeAddBuletinControl;

  constructor(
    public buletinTableService: BuletineTableService,
    private store: Store<ApplicationState>,
    private helperDataService: HelperDataService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();
  }

  ngOnChanges(): void {
    this.store
      .pipe(select(getAppAnimalsRases))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.animalRace = response;
      });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.buletinTableService.sortColumn = column;
    this.buletinTableService.sortDirection = direction;
  }

  changeTableRows(event) {
    this.pageSizeChanged.emit(event.target.value);
  }

  changeTablePage(event) {
    this.pageNumberChanged.emit(event);
  }

  openAddBuletinModal(event) {
    this.openAddBuletinControlModal = true;
    this.modalTypeAddBuletinControl = event;
  }

  editBuletinControl(companyId, cantarireId) {
    this.buletinTableService.getCantarireFromBuletin(companyId, cantarireId)
      .subscribe(response => {
        this.count = response.count;
        this.cantaririDetails = response.weighting;
        this.cantaririBuletin = response.result;
        this.cantaririBuletin.map(cantarire => {
          if (this.animalRace) {
            let raseNames = '';
            cantarire.rase.map(rasa => {
              raseNames +=
              this.animalRace.find(element => rasa.raceid === element.id).shortname + rasa.val;
            });
            cantarire.sex_text = cantarire.sex == 1 ? 'Mascul' : 'Femelă';
            cantarire.rasa_text = raseNames;
          }
          if (cantarire.destinationid === null) {
            cantarire.destinationid = 1;
          }
        });
        this.openEditBuletinControlModal = true;
      });
  }

  openConfirmActionModal(id) {
    this.openConfirmModal = true;
    this.buletinToDelete = id;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.deleteBuletin(this.buletinToDelete);
    }
    this.buletinToDelete = null;
  }

  deleteBuletin(buletinId) {
    this.loadingDataSpinner = true;
    this.buletinTableService.deleteBuletin(buletinId)
      .subscribe(response => {
        // Hide spinner, after 1 second
        this.tableCallback.emit(response);
        setTimeout(() => {
          this.loadingDataSpinner = false;
        }, 500);
      });
  }

  downloadBuletin(cantarire) {
    this.buletinTableService.downloadBuletinControl(cantarire.id)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        this.helperDataService.simulateDownload(blob, `Buletin_de_control_${cantarire.id}.pdf`);
      });
  }

  exportxls(cantarireId) {
    this.buletinTableService.exportXls(cantarireId)
   .subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      this.helperDataService.simulateDownload(blob, `Buletin_de_control_${cantarireId}.xls`);
    });
  }

  closeAddOperatorModal(event) {
    this.openAddBuletinControlModal = event;
  }

  closeEditOperatorModal(event) {
    this.openEditBuletinControlModal = event;
  }

  getModalResult(event) {
    this.tableCallback.emit(event);
  }
}
