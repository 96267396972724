<form [formGroup]="contractSearchForm">
  <div class="row">
    <div class="col-sm-12 col-md-6" *ngIf="!isFermier">
      <div class="form-group mb-3">
        <label for="comp_name">
          Denumirea exploatației
        </label>

        <input
          type="text"
          id="comp_name"
          formControlName="comp_name"
          placeholder="Adaugă denumirea exploatației"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="!isFermier">
      <div class="form-group mb-3">
        <label for="comp_code">
          Codul exploatației
        </label>

        <input
          type="text"
          id="comp_code"
          formControlName="comp_code"
          placeholder="Adaugă codul exploatației"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="contr_date_start">
          Dată început
        </label>
        <app-date-picker
          [selectedDate]="contractSearchForm.value.contr_date_start"
          (dateChanged) ="onStartDateChanged($event)"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="contr_date_end">
          Dată sfârșit
        </label>
        <app-date-picker
          (dateChanged) ="onEndDateChanged($event)"
          [selectedDate]="contractSearchForm.value.contr_date_end"
        ></app-date-picker>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="contr_no">
          Numărul contractului
        </label>

        <input
          type="text"
          id="contr_no"
          formControlName="contr_no"
          placeholder="Adaugă numărul contractului"
          class="form-control" />
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="form-group mb-3">
        <label for="contr_type">
          Tip contract
        </label>
        <ng-select
          bindLabel="contr_type"
          bindValue="contr_type"
          formControlName="contr_type"
          placeholder="Adaugă tipul contractului"
        >
        <ng-option [value]="1">Registru Genealogic (RG)</ng-option>
        <ng-option [value]="2"
          >Controlul Performanțelor Producției (CPP)</ng-option
        >
        </ng-select>
      </div>
    </div>


    <div class="col-12">
      <div class="form-group mb-3">
        <label for="contr_status">
          Status contract
        </label>
        <ng-select
          bindLabel="contr_status"
          bindValue="contr_status"
          formControlName="contr_status"
          placeholder="Adaugă status"
        >
          <ng-option [value]="0">Inactiv</ng-option>
          <ng-option [value]="3">Activ</ng-option>
          <ng-option [value]="4">Reziliat</ng-option>
          <ng-option [value]="5">Suspendat</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-nowrap">
      <div class="btn-group">
        <button
          type="submit"
          class="btn btn-sm btn-blue"
          (click)="onSubmitted()"
        >
          <i class="fe-search"></i>
          Caută
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-warning"
        >
          <i class="fe-delete"></i>
          Resetează câmpurile
        </button>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-nowrap text-right">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="resetSearch()"
      >
        Resetează căutarea
      </button>
    </div>
  </div>
</form>
