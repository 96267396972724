import {
  Component, OnInit, ViewChild, ElementRef, Input,
  Output, EventEmitter
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';
import { CertificatSanitarService } from 'src/app/modules/acbcr/common/services/certificat-sanitar.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';


@Component({
  selector: 'app-add-edit-certificat-sanitar',
  templateUrl: './add-edit-certificat-sanitar.component.html',
  styleUrls: ['./add-edit-certificat-sanitar.component.scss']
})
export class AddEditCertificatSanitarComponent implements OnInit {
  @ViewChild('addEditTemplate') addEditTemplate: ElementRef;
  @Input() addEditModalData: any;
  @Output() notifyClose = new EventEmitter();

  modalReference: NgbModalRef;
  addEditModalType;
  submitted = false;
  disableDropDown = false;
  showHint = true;
  animale = [];
  compForm: UntypedFormGroup;
  fileName;
  isAdmin = false;
  isOperator = false;

  constructor(
    public modalService: NgbModal,
    private helperDataService: HelperDataService,
    public certificatSanitarService: CertificatSanitarService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
  }

  ngOnChanges(changes) {
    if (changes.addEditModalData && ! changes.addEditModalData.firstChange) {
      this.modalReference = this.modalService.open(this.addEditTemplate, {
        windowClass: 'modal-half-full',
        modalDialogClass: 'h-100',
        scrollable: true,
      });
    }

    let item_id = null;

    if (this.addEditModalData && this.addEditModalData.id) {
      item_id = this.addEditModalData.id;
    }

    if (item_id) {
      this.addEditModalType = 'edit';
      this.certificatSanitarService.getAPI({id: item_id})
        .subscribe({
          next: (response) => {
            this.buildForm(response);
          }
        });
    } else {
      this.addEditModalType = 'add';
      this.buildForm();
    }
  }

  buildForm(mData?) {
    this.compForm = new UntypedFormGroup({
      id: new UntypedFormControl(mData?.id),
      data: new UntypedFormControl(mData?.data_dmy, Validators.required),
      animal_id: new UntypedFormControl(mData?.animal_id, Validators.required),
      file_id: new UntypedFormControl(mData?.file_id, Validators.required),
    });

    this.animale = mData?.animale;
    this.fileName = mData?.file_name;
  }

  closeModal() {
    this.modalReference.close();
    this.notifyClose.emit(false);
  }

  onSubmitted() {
    this.submitted = true;

    if (!this.compForm.valid) {
      return;
    }

    if (this.addEditModalType == 'edit') {
      this.certificatSanitarService.salveazaAPI(this.compForm.value)
        .subscribe({
          next: (response) => {
            this.closeModal();
            this.compForm.reset();
            this.submitted = false;
          }
        });
    } else if (this.addEditModalType == 'add') {
      this.certificatSanitarService.creazaAPI(this.compForm.value)
        .subscribe({
          next: (response) => {
            this.closeModal();
            this.compForm.reset();
            this.submitted = false;
          }
        });
    }
  }

  onDateChanged(event) {
    this.compForm.controls.data.setValue(event);
  }

  findAnimals() {
    let expIid = this.compForm.controls.exploatatie_id.value;
    this.compForm.controls.animal_id.reset();

    if (! expIid) {
      this.showHint = true;
      return;
    }

    const req_params = {
      exploatatie_id: expIid,
    };

    this.certificatSanitarService.animaleAPI(req_params)
      .subscribe({
        next: (response) => {
          this.animale = response;
        }
      });
  }

  searchAnimal(event) {
    if (event.term.length < 5) {
      this.showHint = true;

      return;
    }

    this.showHint = false;
    const req_params = {
      numarmatricol: event.term,
    };

    this.certificatSanitarService.animaleAPI(req_params)
      .subscribe({
        next: (response) => {
          this.animale = response;
        }
      });
  }

  clearHoldings() {
    this.showHint = true;
  }

  uploadServerResponse(event) {
    const file = event.body.files[0];
    this.compForm.controls.file_id.setValue(file.file_id);
  }

  downloadFile(event) {
    event.target.disabled = true;
    let fileid = this.compForm.controls.file_id.value;

    this.helperDataService.downloadFile(fileid)
      .subscribe({
        next: (response: any) => {
          const blob = new Blob([response]);
          this.helperDataService.simulateDownload(blob, this.fileName);
          event.target.disabled = false;

        }
      });
  }

  deleteFile() {
    this.compForm.controls.file_id.setValue(null);
    this.fileName = null;
  }

}
