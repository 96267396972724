<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-lg-6 mb-1">
              <div class="dataTables_length" id="tickets-table_length">
                <label class="d-inline-flex align-items-center">
                  Afișează
                  <select
                    name="tickets-table_length"
                    aria-controls="tickets-table"
                    name="pageSize"
                    [(ngModel)]="activitatiService.pageSize"
                    [disabled]="loadingDataSpinner"
                    (change)="changeTableRows($event)"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2"
                  >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  intrări
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-lg-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter float-right text-md-right btn-group custom-table-btn-group"
              >
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  placement="left"
                  [disabled]="loadingDataSpinner"
                  (click)="openTableSearchModal()"
                >
                  <i class="fe-search"></i>
                  Căutare
                </button>
                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  (click)="exportTableXLSX()"
                  [disabled]="loadingDataSpinner"
                  ngbTooltip="Se vor exporta doar datele afișate"
                  placement="bottom"
                >
                  <i class="mdi mdi-file-excel"></i>
                  Exportă XLS
                </button>
              </div>
            </div>
            <!-- End search -->
          </div>

          <!-- Table -->
          <div class="table-responsive" #activitatiTable>
            <table
              id="activitati-datatable"
              class="table datatables dt-responsive nowrap table-hover"
            >
              <thead>
                <tr>
                  <th
                    style="width: 50%"
                    sortable="name"
                    (sort)="onSort($event)"
                  >
                    Exploatație
                  </th>
                  <th
                    style="width: 10%; text-align: center"
                    sortable="subventie"
                    (sort)="onSort($event)"
                  >
                    Subvenție
                  </th>
                  <th sortable="activitati" (sort)="onSort($event)">
                    Activități facturabile
                  </th>
                </tr>
              </thead>

              <div
                class="spinner-border text-info table-spinner avatar-lg"
                role="status"
                *ngIf="loadingDataSpinner"
              ></div>

              <ngb-alert
                type="danger"
                class="text-center search-error"
                *ngIf="activitatiService.totalRecords === 0"
                [dismissible]="false"
              >
                Nu a fost găsit nici o activitate în urma căutării dvs.
              </ngb-alert>

              <tbody [ngClass]="{ disabled: loadingDataSpinner }">
                <tr *ngFor="let activitate of activitatiList">
                  <td>{{ activitate.name }}</td>
                  <td>
                    <div class="select-subventie">
                      <input
                        type="checkbox"
                        class="form-control read-only-form"
                        name="subventionat"
                        (change)="
                          subventionatChange(
                            activitate.id,
                            $event
                          )
                        "
                        [checked]="activitate.subventionat"
                        id="subventionat"
                      />
                    </div>
                  </td>
                  <td>
                    <ul class="list-unstyled">
                      <li
                        *ngFor="
                          let item of activitate.activitati_facturabile;
                          let i = index
                        "
                        class="mt-1"
                      >
                        <span
                          [ngClass]="{
                            'action-buttons': item.name !== 'ACBCR'
                          }"
                          style="cursor: pointer; font-size: 13px"
                        >
                          <i
                            class="mr-1 fa"
                            [ngClass]="{
                              'fa-minus': item.isOpen,
                              'fa-plus': !item.isOpen
                            }"
                            (click)="toggleParent(item)"
                          ></i>
                          <div class="tip-factura">{{ item.name }}</div>
                          <ng-select
                            placeholder="Alege perioada de facturare"
                            class="select-perioada"
                            [ngModel]="
                              item.type === 1
                                ? activitate.perioadaRg
                                : activitate.perioadaCpp
                            "
                            *ngIf="item.name !== 'ACBCR'"
                            [appendTo]="'body'"
                            (change)="
                              perioadaChange(
                                activitate.id,
                                activitate.subventionat,
                                $event,
                                item.type
                              )
                            "
                          >
                            <ng-option [value]="0">Lunar</ng-option>
                            <ng-option [value]="1">Trimestrial</ng-option>
                            <ng-option [value]="2">Anual</ng-option>
                          </ng-select>
                          <button
                            type="button"
                            class="btn btn-link btn-xs add-btn"
                            (click)="
                              openAddActivitateModal(activitate.id, item.type)
                            "
                          >
                            Adaugă
                          </button>
                          <button
                            type="button"
                            class="btn btn-link btn-xs add-btn"
                            *ngIf="item.activities.length < 1"
                            (click)="
                              openAddActivitateModal(
                                activitate.id,
                                item.type,
                                true
                              )
                            "
                          >
                            Adaugă toate
                          </button>
                          <button
                            type="button"
                            class="btn btn-link btn-xs delete-btn"
                            *ngIf="item.activities.length > 0"
                            (click)="
                            openConfirmDeleteModal(
                                activitate.id,
                                item.type,
                                item.activities,
                                true
                              )
                            "
                          >
                            Șterge toate
                          </button>
                        </span>

                        <ul *ngIf="item.isOpen" class="list-unstyled">
                          <li
                            *ngFor="
                              let child of item.activities;
                              let i2 = index
                            "
                            class="ml-2 mt-1"
                          >
                            <span style="cursor: pointer; font-size: 13px">
                              <i
                                class="mr-1 fa"
                                [ngClass]="{
                                  'fa-minus': child.isOpen,
                                  'fa-plus': !child.isOpen
                                }"
                                (click)="toggleChild(child)"
                              ></i>
                              {{ child.name }}
                              <button
                                type="button"
                                class="btn btn-link btn-xs delete-btn"
                                (click)="
                                  openConfirmDeleteModal(
                                    activitate.id,
                                    item.type,
                                    child.id
                                  )
                                "
                              >
                                Sterge
                              </button>
                            </span>

                            <ul *ngIf="child.isOpen" class="list-unstyled">
                              <li
                                *ngFor="
                                  let service of child.servicesList;
                                  let i3 = index
                                "
                                class="ml-3 mt-1"
                              >
                                <span style="cursor: pointer; font-size: 13px">
                                  {{ service.descriere }}
                                </span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- End table -->
          <div
            class="row justify-content-lg-between align-items-lg-center mt-2"
            *ngIf="activitatiService.totalRecords > 0"
          >
            <div class="col-sm-12 col-lg-6">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Afișează de la
                {{ activitatiService.startIndex }} la
                {{ activitatiService.endIndex }} din
                {{ activitatiService.totalRecords }}
                intrări
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-lg-6">
              <div class="text-lg-right float-lg-right pagination-rounded">
                <ngb-pagination
                  [disabled]="loadingDataSpinner"
                  [collectionSize]="activitatiListLength"
                  [(page)]="activitatiService.page"
                  [pageSize]="activitatiService.pageSize"
                  [maxSize]="5"
                  (pageChange)="changeTablePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" *ngIf="openAddModal">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="text-left">Adaugă activitate</h3>
      <span class="close text-right" (click)="closeAddActivitateModal()">
        &times;</span
      >
    </div>

    <div class="modal-body">
      <form [formGroup]="addActivitateForm">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-3">
              <label for="name"> Alege activitatea </label>
              <ng-select
                placeholder="Selectează activitatea"
                formControlName="activitatea_id"
              >
                <ng-option
                  *ngFor="let option of activitiesList; let i = index"
                  [disabled]="option.disabled"
                  [value]="option.id"
                  >{{ option.descriere_scurta }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row p-2">
      <div class="col-sm-12 text-right">
        <button
          class="btn btn-dark mr-2"
          type="button"
          (click)="closeAddActivitateModal()"
        >
          Anulează
        </button>
        <button class="btn btn-success" type="button" (click)="onSubmitted()">
          Salvează activitate
        </button>
      </div>
    </div>
  </div>
</div>

<app-table-search-modal
  [open]="openSearchModal"
  [modalType]="modalType"
  (searchingData)="filterSearch($event)"
>
</app-table-search-modal>

<app-confirm-action-modal
  [open]="openConfirmModal"
  [type]="deleteAll ? 'delete-activities' : 'delete-activitate'"
  [deleteAll]="deleteAll"
  (confirm)="closeConfirmModal($event)"
></app-confirm-action-modal>
