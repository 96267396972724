import { Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { BirthsTableService } from 'src/app/modules/acbcr/common/services/births-table.service';
import { NotificationErrorService } from 'src/app/modules/acbcr/common/services/notification-error.service';
import { getSelectedHolding } from 'src/app/modules/acbcr/common/state/reducers/holdings.reducers';
import { ApplicationState } from 'src/app/modules/acbcr/common/state/app.state';
import { AnimalsTableService } from 'src/app/modules/acbcr/common/services/animals-table.service';
import { UIHelper } from 'src/app/modules/acbcr/components/common/helper/ui-helper';
import { DateFormatter } from '../../../date-picker/date-format';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { HelperDataService } from 'src/app/modules/acbcr/common/services/helper-data.service';

@Component({
  selector: 'app-add-edit-fatare-form',
  templateUrl: './add-edit-fatare-form.component.html',
  styleUrls: ['./add-edit-fatare-form.component.scss']
})
export class AddEditFatareFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() anexa = null;
  @Input() fatareModalType: string;
  @Input() anexaId: number;
  @Input() showAddButton;
  @Output() closeForm = new EventEmitter();
  @Output() callbackResult = new EventEmitter();

  readonly editFatare = 'edit-fatare';
  readonly addFatare = 'add-fatare';

  birthForm;
  submitted = false;
  loadingSpinner = false;
  femele = [];
  restrictii;
  monte;
  warningRangFatare = [];

  childInfoDisplay = false;
  isSuperAdmin = false;
  isAdmin = false;
  isOperator = false;
  isFermier = false;
  isAutoritateJudeteana = false;
  isContabil = false;
  openConfirmModal = false;
  showDateErrors = false;
  gemelara = false;

  mamaId = null;
  tataId = null;

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedHolding;
  isDataIntrariiRequired = false;

  constructor(
    private birthsTableService: BirthsTableService,
    private animalsService: AnimalsTableService,
    private store: Store<ApplicationState>,
    private errorService: NotificationErrorService,
    private helperDataService: HelperDataService,
  ) { }

  ngOnInit(): void {}

  init() {
    this.isSuperAdmin = UIHelper.isSuperAdmin();
    this.isAdmin = UIHelper.isAdmin();
    this.isOperator = UIHelper.isOperator();
    this.isFermier = UIHelper.isFermier();
    this.isAutoritateJudeteana = UIHelper.isAutoritateJudeteana();
    this.isContabil = UIHelper.isContabil();

    this.restrictii = [];
    if (this.fatareModalType === this.addFatare || this.anexa?.companyid === undefined) {
      this.store.pipe(select(getSelectedHolding))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.selectedHolding = response;
      });
    } else {
      this.selectedHolding = { id: this.anexa.companyid };
    }

    this.femele = this.anexa?.participanti?.filter(participant => {
      return participant.sex == 2;
    }).map(participant => {
      return {
        numarmatricol: participant.numarmatricol,
      }
    });
  }

  ngOnChanges(changes) {
    this.init();

    if (this.fatareModalType === this.editFatare) {
      this.buildForm(this.anexa);
      this.restrictii = [];
    } else {
      this.buildForm();
    }
  }

  ngOnDestroy() {
    this.destroy$.unsubscribe();
  }

  private buildForm(formData?) {
    let matricolFemela = formData?.participanti?.find(participant => {
      return participant.sex == 2;
    })?.numarmatricol;

    this.birthForm = new UntypedFormGroup({
      id: new UntypedFormControl(formData?.id),
      companyid: new UntypedFormControl(formData?.companyid),
      anexa8id: new UntypedFormControl(formData?.anexa8id),
      fatareid: new UntypedFormControl(formData?.fatareid),
      tipfatare: new UntypedFormControl(
        formData !== undefined ? formData?.tipfatare.toString() : '1',
        Validators.required),
      data_nasterii: new UntypedFormControl(
        formData !== undefined ? formData?.data_nasterii : '',
        Validators.required),
      data_intrarii_exploatatie: new UntypedFormControl(
        formData !== undefined ? formData?.data_intrarii_exploatatie : ''),
      rangfatare: new UntypedFormControl(formData?.rangfatare),
      usurintafatare: new UntypedFormControl(
        formData ? formData?.usurintafatare?.toString() : null,
        Validators.required),
      retentieplacentara: new UntypedFormControl(
        formData ? formData?.retentieplacentara?.toString() : '0'),
      montaid: new UntypedFormControl(formData?.montaid, Validators.required),
      matricolfemela: new UntypedFormControl(matricolFemela, Validators.required),
      matricolvitel: new UntypedFormControl(formData?.matricolvitel),
      sex: new UntypedFormControl(formData?.sex, Validators.required),
      nume: new UntypedFormControl(formData?.nume),
      culoare: new UntypedFormControl(formData?.culoare),
      greutatenastere: new UntypedFormControl(
        formData?.greutatenastere !== null ? formData?.greutatenastere : ''),
      status: new UntypedFormControl(
        formData ? formData?.status?.toString() : '0',
        Validators.required),
      are_evaluare_genetica: new UntypedFormControl(formData?.are_evaluare_genetica),
    });

    if (this.isAutoritateJudeteana || this.isContabil
      || (! this.isSuperAdmin
          && this.fatareModalType == this.addFatare
          && formData?.are_evaluare_genetica)) {

      this.birthForm.disable();
    }

    this.submitted = false;
    this.restrictii = [];
    this.childInfoDisplay = this.birthForm.value.tipfatare === '1' ? true : false;
    this.changeUsurintaFatareValidators(this.childInfoDisplay);
    this.changeMatricolVitelValidators(this.childInfoDisplay);
    this.changeGreutateNasterelValidators(this.childInfoDisplay);
    this.changeSexValidators(this.childInfoDisplay);
    this.monte = formData?.monte;

    if (this.fatareModalType === this.addFatare) {
      this.birthForm.controls.status.setValue('0');
      this.anexa = null;
      this.gemelara = false;
    } else {
      this.gemelara = false;
      if (this.birthForm.value?.tipfatare === '5') {
        this.birthForm.controls.tipfatare.setValue('1');
        this.gemelara = true;
      }
      this.mamaId = formData.mamaid;
      this.tataId = formData.participanti.find(participant => {
        return participant.sex === 1;
      })?.animalid;
    }
  }

  get form() { return this.birthForm?.controls; }

  closeFormMethod() {
    this.closeForm.emit(false);
  }

  selectTipFatare(event) {
    this.childInfoDisplay = event === '1' ? true : false;
    this.changeUsurintaFatareValidators(this.childInfoDisplay);
    this.changeMatricolVitelValidators(this.childInfoDisplay);
    this.changeGreutateNasterelValidators(this.childInfoDisplay);
    this.changeSexValidators(this.childInfoDisplay);

    const form = this.birthForm.controls;
    form.matricolvitel.reset(), form.sex.reset(), form.nume.reset(),
      form.culoare.reset();

    form.tipfatare.setValue(event);
    form.status.setValue('0');
    this.changeInfoMother();
  }

  onDataNasteriiChanged(event) {
    this.birthForm.controls.data_nasterii.setValue(event);

    if (!event) {
      return;
    }

    this.checkDateInterval();
    this.changeInfoMother();
  }

  onDataIntrareExploatatieChanged(event) {
    this.birthForm.controls.data_intrarii_exploatatie.setValue(event);
    this.checkDateInterval();
  }

  changeInfoMother(event?) {
    this.birthForm.controls.montaid.reset();

    if (event) {
      this.mamaId = event?.id;
    }

    const dataFatare = this.birthForm.value.data_nasterii;

    if (!this.mamaId || !dataFatare) {
      return;
    }

    let req_params = {
      id: this.mamaId,
      data_fatare: dataFatare,
      tip_fatare: this.birthForm.value.tipfatare,
    };

    this.birthsTableService.femelaAPI(req_params)
      .subscribe(response => {
        this.restrictii = response.restrictii;
        this.monte = response.monte;
        this.gemelara = response.este_fatare_gemelara;
        this.birthForm.controls.matricolfemela.setValue(response.numarmatricol);
        this.warningRangFatare = [];

        if (this.fatareModalType === this.addFatare && ! response.este_fatare_gemelara) {
          this.birthForm.controls.rangfatare.setValue(response.rangfatare + 1);
        } else {
          this.birthForm.controls.rangfatare.setValue(response.rangfatare);
        }
      });
  }

  searchMother (event) {
    let term = event.target.value;

    if (term.length < 4) {
      this.femele = [];
      return;
    }

    this.helperDataService
      .getAnimalInfo(term, 2, this.selectedHolding.id)
      .subscribe((response: any) => {
        this.femele = response.result;
      });
  }

  changeMonta(event, index) {
    let montaID = this.monte[index].id;
    this.birthForm.controls.montaid.setValue(montaID);
    event.target.checked = true;
  }

  rangFatareChange(rangFatare) {
    if (! rangFatare) {
      return;
    }

    this.warningRangFatare = [];
    let matricolFemela = this.birthForm.get('matricolfemela').value;
    if (! matricolFemela) {
      return;
    }


    let femelaObj = this.gasesteFemela(matricolFemela);
    if (! femelaObj) {
      return;
    }

    if (rangFatare < femelaObj.nr_descendenti) {
      this.warningRangFatare.push('Numarul de descendenti este ' + femelaObj.nr_descendenti);
      this.birthForm.controls.rangfatare.setValue(femelaObj.nr_descendenti);
      rangFatare = femelaObj.nr_descendenti
    }

    if (rangFatare > femelaObj.nr_descendenti) {
      this.warningRangFatare.push('Rangul fatarii alocat este mai mare decat numarul de descendenti ' + femelaObj.nr_descendenti);
    }

    if (femelaObj.zile_intre_fatari > 390) {
      this.warningRangFatare.push('Diferenta de zile fata de fatarea anterioara este de ' + femelaObj.zile_intre_fatari + ' zile');
    }
  }

  gasesteFemela(matricolFemela: string) {
    for (let i in this.femele) {
      let matricol = this.femele[i].numarmatricol;
      if (matricolFemela == matricol) {
        return this.femele[i];
      }
    }
  }

  anexaStatusChanged() {
    if (this.submitted && this.fatareModalType === this.editFatare &&
      this.form.status.value === '5') {
      this.submitted = false;
    }
  }

  openConfirmActionModal() {
    this.openConfirmModal = true;
  }

  closeConfirmModal(event) {
    this.openConfirmModal = false;
    if (event === true) {
      this.deleteFatare();
    }
  }

  deleteFatare() {
    this.loadingSpinner = true;
    this.birthsTableService.deleteFatare(this.anexa.id)
      .subscribe({
        next: (response) => {
          this.loadingSpinner = false;
          this.callbackResult.emit(response);
          this.closeForm.emit(false);
        },
        error: (errors) => {
          this.loadingSpinner = false;
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  onSubmitted() {
    this.birthForm.controls.anexa8id.setValue(this.anexaId);
    this.birthForm.controls.companyid.setValue(this.selectedHolding.id);
    if (this.fatareModalType === this.editFatare) {
      this.birthForm.controls.fatareid.setValue(this.anexa.fatareid);
      this.birthForm.controls.id.setValue(this.anexa.id);

      if (this.gemelara) {
        this.birthForm.value.tipfatare = '5';
      }
    }

    this.submitted = true;
    if (!this.birthForm.valid || this.showDateErrors) {
      return;
    }

    this.birthsTableService.updateAnexa8(this.birthForm.value)
      .subscribe({
        next: (response) => {
          this.callbackResult.emit(response);
          this.closeForm.emit(false);
        },
        error: (errors) => {
          this.errorService.processErrorMsg(errors.error.error);
        }
      });
  }

  private changeUsurintaFatareValidators(required: boolean) {
    if (required) {
      this.birthForm.controls.usurintafatare.setValidators([Validators.required])
    } else {
      this.birthForm.controls.usurintafatare.clearValidators();
    }

    this.birthForm.controls.usurintafatare.updateValueAndValidity();
  }

  private changeMatricolVitelValidators(required: boolean) {
    if (required) {
      this.birthForm.controls.matricolvitel.setValidators(
        [Validators.required, this.matricolVitelValidator]
      )
    } else {
      this.birthForm.controls.matricolvitel.clearValidators();
    }

    this.birthForm.controls.matricolvitel.updateValueAndValidity();
  }

  private changeGreutateNasterelValidators(required: boolean) {
    if (required) {
      this.birthForm.controls.greutatenastere.setValidators(
        [Validators.required, Validators.min(10), Validators.max(99)]
      )
    } else {
      this.birthForm.controls.greutatenastere.clearValidators();
    }

    this.birthForm.controls.greutatenastere.updateValueAndValidity();
  }

  private changeSexValidators(required: boolean) {
    if (required) {
      this.birthForm.controls.sex.setValidators([Validators.required])
    } else {
      this.birthForm.controls.sex.clearValidators();
    }

    this.birthForm.controls.sex.updateValueAndValidity();
  }

  private checkDateInterval() {
    this.showDateErrors = false;
    const data_nasterii = this.birthForm.value.data_nasterii;
    const data_intrarii_exploatatie = this.birthForm.value.data_intrarii_exploatatie;
    const parseData1 = new DateFormatter().parse(data_nasterii);
    const parseData2 = new DateFormatter().parse(data_intrarii_exploatatie);

    if (!parseData1 || !parseData2) {
      return;
    }

    const data1 = new NgbDate(parseData1.year, parseData1.month, parseData1.day);
    const data2 = new NgbDate(parseData2.year, parseData2.month, parseData2.day);

    if (!data1 || !data2) {
      return;
    }

    if (data1.after(data2)) {
      this.showDateErrors = true;
    }
  }

  matricolVitelValidator(control) {
    let value = String(control.value);

    if (value.match(/^RO\d{12}$/)) {
      return null;
    }

    return {'bad_format': true}
  }
}
